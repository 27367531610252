
import React, {useState} from 'react';

import ImageCropper from './ImageCropper';

function ImatgeDNI(props){
  const [imatge, setImatge] = useState(props.image);
  /*
  Exemples:
  <ImatgeDNI
    image={dni_image_1}
    title={props.t('pares.dni_image_1')}
    dataUrl={dni_image_1_dataUrl}
    setDataUrl={setDni_image_1_dataUrl}
    setVisor={setVisor}
  />

  <ImatgeDNI
    image={passaport_image_1}
    title={props.t('pares.passaport_image_1')}
    dataUrl={passaport_image_1_dataUrl}
    setDataUrl={setPassaport_image_1_dataUrl}
    setVisor={setVisor}
  />

  */

  return(
    <>
      { props.dataUrl === undefined
                ? (! props.image)
                  ? <img src={"https://via.placeholder.com/443x249?text=+++"+props.title+"+++"} style={{maxWidth:'100%', height: 'auto'}} alt={props.title} title={props.title}/>
                  : <a onClick={() => props.setVisor({visible:true, image:  props.image, title: props.title})}>
                        <img
                          src={imatge}
                          onError={() => setImatge("https://via.placeholder.com/443x249?text=URL+"+props.title+":+NOT+FOUND!!")}
                          style={{maxWidth:'100%', height: 'auto'}}
                          alt={props.title}
                          title={props.title}/>
                    </a>
                : <a onClick={() => props.setVisor({visible:true, image: props.dataUrl, title: props.title})}>
                    <img src={props.dataUrl} style={{maxWidth:'100%', height: 'auto'}} alt={props.title} title={props.title}/>
                  </a>
              }
      <ImageCropper
        aspectRatio={16/9}
        style={{height: 400, width: '100%'}}
        guides={true}
        retorna_dataUrl={props.setDataUrl}
        usarPixabay={false}
      />
    </>
  );
}
export default ImatgeDNI;
