import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Select, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const id = props.item.id;
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [nom, setNom] = useState(entities.decode(props.item.nom));
  const [ubicacio, setUbicacio] = useState(entities.decode(props.item.ubicacio));
  const [id_curs, setId_curs] = useState(props.item.id_curs);
  const [h_ini, setH_ini] = useState(props.item.h_ini);
  const [m_ini, setM_ini] = useState(props.item.m_ini);
  const [h_fin, setH_fin] = useState(props.item.h_fin);
  const [m_fin, setM_fin] = useState(props.item.m_fin);

  const [cursos, setCursos] = useState([]);


  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [errorNom, setErrorNom] = useState('');
  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');

  const nou = (props.item.id===0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  useEffect(() => {
    buscarDades();
  },[]);


  const buscarDades = async () => {
    setIsLoading(true);

    const cur = await Api.get('/cursos');
    if(cur.result.success===1){
      const llista_cur = cur.data.cursos.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setCursos(llista_cur);
    }
    setIsLoading(false);
  }




  const nomChange = (e) =>{
    const n = e.target.value.trim();
    setNom(n);
    if(n.length>0){
      setErrorNom('');
    }
  }

  const selectChange = (value) =>{
    setId_curs(value);
    if(value!==0){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }


  const submitForm = async (valors) => {

      let correcte = true;

      if(nom.length === 0){
        setErrorNom('error');
        correcte = false;
      }

      if(id_curs === 0){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        ordre,
        nom: entities.encode(nom),
        ubicacio: entities.encode(ubicacio),
        id_curs: id_curs,
        id_curs_escolar,
        h_ini,
        m_ini,
        h_fin,
        m_fin
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/classes' : '/classes/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormClasses: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Classes guardar: ', error);
      }

  }


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {
    return(
      <div style={{padding:'10px 24px 35px 0px'}}>
      <Form onFinish={submitForm} style={{marginTop:'35px'}}
        layout="horizontal"
        labelCol={{span:2}}
        wrapperCol={{span:9}}
        colon={true}
        autoComplete="off"
      >
          <Form.Item
            label={props.t('transport.ordre')}
            required={true}
          >
              <InputNumber name="ordre" onChange={setOrdre} defaultValue={ordre}/>
          </Form.Item>

          <Form.Item
            validateStatus={errorNom}
            label={props.t('classes.nom')}
          >
              <Input name="nom" placeholder={props.t('classes.nom')} onChange={nomChange} defaultValue={nom} autoFocus="focus"/>
          </Form.Item>
          <Form.Item label={props.t('classes.ubicacio')}>
              <Input name="ubicacio" placeholder={props.t('classes.ubicacio')} onChange={(e) => setUbicacio(e.target.value)} defaultValue={ubicacio}/>
          </Form.Item>
          <Form.Item
            validateStatus={errorSelect}
            help={errorSelectText}
            label={props.t('classes.curs')}
            style={{marginBottom: 0}}
          >
            <Select defaultValue={id_curs === 0 ? "" : id_curs} defaultActiveFirstOption={true} onChange={selectChange}>
              {cursos}
            </Select>
          </Form.Item>

          <Form.Item
            label={props.t('generic.hini')}
            required={true}
          >
              <InputNumber name="hora" onChange={setH_ini} defaultValue={h_ini} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
              <span>&nbsp;:&nbsp;</span>
              <InputNumber name="minut" onChange={setM_ini} defaultValue={m_ini} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
          </Form.Item>
          <Form.Item
            label={props.t('generic.hfin')}
            required={true}
          >
              <InputNumber name="hora" onChange={setH_fin} defaultValue={h_fin} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
              <span>&nbsp;:&nbsp;</span>
              <InputNumber name="minut" onChange={setM_fin} defaultValue={m_fin} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
          </Form.Item>


          <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
            <Row type="flex" justify="start">
              <Col>
                <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export default translate(Edit);
