import React from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../components/ContainerPagina';
import TitolPagina from '../components/TitolPagina';
import LlistaEditable from '../components/LlistaEditable';
import Edit from '../components/Formularis/Assignatures';
import { Api, InitDades } from '../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Assignatures(props) {

  const buscarDades = async () => {
    const e = await Api.get('/assignatures');
    if(e.result.success===1){
      return e.data.assignatures;
    }

  }

  const marcarEliminat = async (id) => {

    const fam = await Api.myFetch('/assignatures/'+id, 'DELETE');
    if(fam.result.success===1){

    }

  }

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.Assignatures')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          txt_creats={props.t('extraescolars.creados')}
          txt_nou={props.t('extraescolars.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          duplica={true}
          itemBuit={InitDades.extraescolars()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />

          <Column
          title={props.t('extraescolars.nom')+' CA'}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom['ca'])
          )}
          />
          <Column
          title={props.t('extraescolars.nom')+' EN'}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom['en'])
          )}
          />
          <Column
          title={props.t('extraescolars.nom')+' ES'}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom['es'])
          )}
          />



        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Assignatures);
