import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, Space, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function ComandesAcollidaEdit(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [id, setId] = useState(props.item.id);
  const [enviar_email, setEnviar_email] = useState(false);
  const [id_acollida, setId_acollida] = useState(props.item.id_acollida);
  const [id_propietat, setId_propietat] = useState(props.item.id_propietat);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [tipus, setTipus] = useState(props.item.tipus);
  const [puntual_data, setPuntual_data ] = useState(props.item.puntual_data);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [total, setTotal] = useState(props.item.total);
  const [fcrea, setFcrea ] = useState(props.item.creat);

  const [guardant, setGuardant] = useState(false);

  const [acollidaOptions, setAcollidaOptions] = useState([]);
  const [acollidaPropietatsOptions, setAcollidaPropietatsOptions] = useState([]);
  const [propietatsLlistaIndexada, setPropietatsLlistaIndexada] = useState([]);

  const [errorrFcrea, setErrorFcrea] = useState('');
  const [errorId_acollida, setErrorId_acollida] = useState('');
  const [errorId_acollidaText, setErrorId_acollidaText] = useState('');
  const [errorId_propietat, setErrorId_propietat] = useState('');
  const [errorId_propietatText, setErrorId_propietatText] = useState('');



  useEffect(() => {
    buscarDadesAcollida();
  },[]);

  useEffect(() => {
    buscarDadesAcollidaPropietats();
  },[id_acollida]);

  useEffect(() => {
    calcularTotal(subtotal, descompte);
  },[subtotal, descompte]);

  const buscarDadesAcollida = async () =>{
    const resp = await Api.get(`/acollida/curs/${id_curs_escolar}`);
    if(resp.result.success===1){

      const llista_aco = resp.data.acollides.map((item)=>{
        if(item.tipus == tipus){
          return (
            <Select.Option key={item.id} value={item.id}>
              {entities.decode(item.nom[props.t('idioma')])}
            </Select.Option>
          );
        }
      });
      setAcollidaOptions(llista_aco);
    }
  }
  const buscarDadesAcollidaPropietats = async () =>{
    if(id_acollida > 0){
      const resp = await Api.get(`/acollida/propietats/${id_acollida}`);
      if(resp.result.success===1){
        const llista_prop = resp.data.propietats.map((item)=>{
          return (
            <Select.Option key={item.id} value={item.id}>
              {entities.decode(item.text[props.t('idioma')])}
            </Select.Option>
          );
        });
        setAcollidaPropietatsOptions(llista_prop);

        const llistaIndexada = resp.data.propietats.reduce((acumulat, item) => ({
          ...acumulat,
          [item.id]: item,
        }),{});
        setPropietatsLlistaIndexada(llistaIndexada);
      }
    }
  }


  const triaAcollida = (value) =>{
    setId_acollida(value);
    setId_propietat(0);
    setSubtotal(0);

    if(value>0){
      setErrorId_acollida('');
      setErrorId_acollidaText('');
    }
  }
  const triaPropietat = (value) =>{
    setId_propietat(value);
    setSubtotal(propietatsLlistaIndexada[value].preu);
    if(value>0){
      setErrorId_propietat('');
      setErrorId_propietatText('');
    }
  }


  const calcularTotal = (sub, desc) =>{
    if(sub !== 0.0){
      if(desc!==0.0){
        const tot =  sub - (sub * desc / 100);
        setTotal(tot);
      }else{
        setTotal(sub);
      }
    }else{
      setTotal(0);
    }
  }
  const onChangeSubtotal = (sub) =>{
    setSubtotal(sub);
    //calcularTotal(sub, descompte);
  }

  const onChangeDescompte = (desc) =>{
    setDescompte(desc);
    //calcularTotal(subtotal, desc);
  }

  const changePuntual_data = (date, dateString) => {
    if(date){
      //Trec l'hora
      setPuntual_data(date.format(moment.HTML5_FMT.DATE));
    }else{
      setPuntual_data(null);
    }
  }
  const changeFcrea = (date, dateString) => {
    if(date){
      setFcrea(date.toISOString());
    }else{
      setFcrea(null);
    }
  }


  const submitForm = async (values) => {

    let correcte = true;

    if(!fcrea){
      setErrorFcrea('error');
      correcte = false;
    }

    if(id_acollida===0){
      setErrorId_acollida('error');
      setErrorId_acollidaText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_propietat===0){
      setErrorId_propietat('error');
      setErrorId_propietatText(props.t('generic.tria'));
      correcte = false;
    }

    if(!correcte){
      return false;
    }

    setGuardant(true);
    let dades = {
      id_curs_escolar,
      id,
      id_acollida,
      id_propietat,
      id_alumne,
      id_pare,
      tipus,
      puntual_data,
      subtotal,
      descompte,
      total,
      fcrea,
      enviar_email
    }

    try{
      const resposta = await Api.myFetch(`/acollida/comanda/${id}`, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: submit ComandesAcollidaEdit: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ComandesAcollidaEdit: ', error);
    }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return(
    <Form onFinish={submitForm}
      layout="horizontal"
      autoComplete="off"
    >
    <Form.Item label={props.t('generic.created_at')} required={true} validateStatus={errorrFcrea}>
      <DatePicker
        onChange={changeFcrea}
        defaultValue={fcrea != null ? moment(fcrea) : ''}
        format={'DD/MM/YYYY HH:mm'}
        locale={localeDatePicker}
        showTime
        />
    </Form.Item>
    <Form.Item label={props.t('generic.responsable')}>
      <Input name="pare_nom" defaultValue={entities.decode(props.item.pare_nom)} disabled/>
    </Form.Item>
    <Form.Item label={props.t('menu.Acollida')} required={true} validateStatus={errorId_acollida} help={errorId_acollidaText} style={{marginBottom: 0}}>
      <Select value={id_acollida===0 ? '' : id_acollida} onChange={(value) => triaAcollida(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {acollidaOptions}
      </Select>
    </Form.Item>
    <Form.Item label={props.t('acollida.titol')} required={true} validateStatus={errorId_propietat} help={errorId_propietatText} style={{marginBottom: 0}}>
      <Select value={id_propietat===0 ? '' : id_propietat} onChange={(value) => triaPropietat(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {acollidaPropietatsOptions}
      </Select>
    </Form.Item>

    { tipus==='mensual' ? null :
    <Form.Item label={props.t('generic.data')} required={true}>
      <DatePicker
        onChange={changePuntual_data}
        value={puntual_data ? moment(new Date(puntual_data), dateFormat) : ''}
        format={dateFormat}
        locale={localeDatePicker}
        picker="date"
        />
    </Form.Item>
  }

    <Form.Item label={props.t('generic.subtotal')}>
        <InputNumber name="subtotal" onChange={onChangeSubtotal} value={subtotal}
        step={1.0}
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.descompte')}>
        <InputNumber name="descompte" onChange={onChangeDescompte} value={descompte}
        min={-100} max={100} step={0.1}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.total')}>
        <InputNumber name="total" value={total} disabled
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Row>
      <Space>
        <span>{props.t('generic.enviarEmail')}</span>
        <Switch onChange={setEnviar_email} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
      </Space>
    </Row>
    <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
      <Col>
        <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
      </Col>
      <Col style={{marginLeft:'10px'}}>
        <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
      </Col>
    </Row>
    </Form>
  );

}
export default translate(ComandesAcollidaEdit);
