import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import {Card, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Table, Tooltip, Popconfirm, Modal, Space, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';
import sha512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings, InitDades } from '../../../../helpers';
import ImatgeDNI from '../../../ImatgeDNI';
import VisorImatges from "../../../visorImatges";
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import TriaCursEscolar from '../../../TriaCursEscolar';


import FormProfessorsAssignar from './ProfessorsAssignar';
const entities = new AllHtmlEntities();


function Edit(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);

  const [id, setId] = useState(props.item.id);
  const [users_id, setUsers_id] = useState(props.item.users_id);
  const [nom, setNom] = useState(entities.decode(props.item.nom));
  const [cognom1, setCognom1] = useState(entities.decode(props.item.cognom1));
  const [cognom2, setCognom2] = useState(entities.decode(props.item.cognom2));
  const [tel1, setTel1] = useState(props.item.tel1);
  const [tel2, setTel2] = useState(props.item.tel2);
  const [email, setEmail] = useState(props.item.email);
  const [dni, setDni] = useState(props.item.dni);
  const [dni_image_1, setDni_image_1] = useState();
  const [dni_image_2, setDni_image_2] = useState();
  const [passaport, setPassaport] = useState(props.item.passaport);
  const [passaport_image_1, setPassaport_image_1] = useState(props.item.passaport_image_1);
  const [passaport_image_2, setPassaport_image_2] = useState(props.item.passaport_image_2);
  const [app_username_txt, setApp_username_txt] = useState(props.item.app_username_txt);
  const [app_password, setApp_password] = useState(null);
  const [app_locked, setApp_locked] = useState(props.item.app_locked);
  const [obligar_modificar_password, setObligar_modificar_password] = useState(props.item.obligar_modificar_password);
  const [idioma, setIdioma] = useState(entities.decode(props.item.idioma));
  const [observacions, setObservacions] = useState(entities.decode(props.item.observacions));


/* Auxiliars */
  const [dni_image_1_dataUrl, setDni_image_1_dataUrl ] = useState(undefined);
  const [dni_image_2_dataUrl, setDni_image_2_dataUrl ] = useState(undefined);
  const [passaport_image_1_dataUrl, setPassaport_image_1_dataUrl ] = useState(undefined);
  const [passaport_image_2_dataUrl, setPassaport_image_2_dataUrl ] = useState(undefined);

  const [professors_relacions, setProfessors_relacions] = useState([]);
  const [assignTriat, setAssignTriat] = useState([]);
  const [modalVisibleAssignar, setModalVisibleAssignar] = useState(false);
  const [actualizarAssignar, setActualizarAssignar] = useState(0);

  const [errorNom, setErrorNom] = useState('');
  const [errorCognom1, setErrorCognom1] = useState('');
  const [errorApp_username_txt, setErrorApp_username_txt] = useState('');
  const [errorApp_username_txtText, setErrorApp_username_txtText] = useState('');
  const [errorApp_password, setErrorApp_password] = useState('');

  const [visor, setVisor] = useState({visible: false});

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nou, setNou] = useState(props.item.id===0);

  let assignarBuit = InitDades.professor_relacio();

  const contenidorRef = React.useRef();


  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setActualizarAssignar(actualizarAssignar+1);
  }

  const buscarDades = async () => {
    setIsLoading(true);

    const dni_pare = await Api.get('/professor/dni/'+id);
    if(dni_pare.result.success===1){
      setDni_image_1(dni_pare.data.dni_image_1);
      setDni_image_2(dni_pare.data.dni_image_2);
      setPassaport_image_1(dni_pare.data.passaport_image_1);
      setPassaport_image_2(dni_pare.data.passaport_image_2);
    }

    setIsLoading(false);
  }


  const buscarDadesAssignar = async () => {
    if(id > 0){
      const ad = await Api.get(`/professors/relacions/${id}/${cursEscolarTriat}`);
      if(ad.result.success===1){
        setProfessors_relacions(ad.data.relacions);
      }
    }
  }

  useEffect(() => {
    buscarDades();
  },[]);

  useEffect(() => {
    buscarDadesAssignar();
  },[actualizarAssignar]);


    const submitForm = async (valors) => {

      let correcte = true;
      if(!nom){
        setErrorNom('error');
        correcte = false;
      }
      if(!cognom1){
        setErrorCognom1('error');
        correcte = false;
      }

      if(errorApp_username_txt === 'error'){
        correcte = false;
      }

      if(app_username_txt !== props.item.app_username_txt && !props.item.app_username_txt){
        //Si no tenia usuari i ara se li asigna un, se li ha de posar contrasenya
        if(!app_password){
          setErrorApp_password('error');
          correcte = false;
        }
      }

      if(!correcte){
        contenidorRef.current.scrollIntoView({
          behavior: "smooth",
        });
        return false;
      }

      setGuardant(true);

      let password = null;
      if(app_password){
         password = Base64.stringify(sha512(app_password));
      }

      let dades = {
        id,
        users_id,
        nom: entities.encode(nom),
        cognom1: entities.encode(cognom1),
        cognom2: entities.encode(cognom2),
        tel1,
        tel2,
        email,
        dni,
        passaport,
        app_username_txt,
        app_password: password,
        app_locked,
        obligar_modificar_password,
        idioma,
        observacions: entities.encode(observacions)
      }

      let method = nou?'POST':'PUT';

      let url_api = nou? '/professors' : '/professors/'+id;

      //console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){

          if(nou){
            setNou(false);
            setId(resposta.data.id);
          }

          if(dni_image_1_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/professor/dni/'+resposta.data.id+'/1', 'POST', form_imatges);
              if(resp.result.success === 1){
                //console.log(resp);
                setDni_image_1(resp.data.dni_image_1);
              }else{
                console.error('Error POST file: Professor dni_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(dni_image_2_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/professor/dni/'+resposta.data.id+'/2', 'POST', form_imatges);
              if(resp.result.success === 1){
                //console.log(resp);
                setDni_image_2(resp.data.dni_image_2);
              }else{
                console.error('Error POST file: Professor dni_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_1_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/professor/dni/'+resposta.data.id+'/3', 'POST', form_imatges);
              if(resp.result.success === 1){
                //console.log(resp);
                setPassaport_image_1(resp.data.passaport_image_1);
              }else{
                console.error('Error POST file: Professor passaport_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_2_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/professor/dni/'+resposta.data.id+'/4', 'POST', form_imatges);
              if(resp.result.success === 1){
                //console.log(resp);
                setPassaport_image_2(resp.data.passaport_image_2);
              }else{
                console.error('Error POST file: Professor passaport_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }


          setGuardant(false);
          //console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormProfessor: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Professor guardar: ', error);
      }

  }


  let posicio_botons = "start";
  /*if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }*/

  const onChangeNom = (value) =>{
    setNom(value);
    if(value.length>0){
      setErrorNom('');
    }
  }
  const onChangeCognom1 = (value) =>{
    setCognom1(value);
    if(value.length>0){
      setErrorCognom1('');
    }
  }

  const changeApp_username_txt = async (valor) => {
    const username = HtmlStrings.filtrarNumText(valor);
    setApp_username_txt(username);

    if(username.length > 2){
      const disp = await Api.get(`/professors/username/disponible/${id}/${username}`);
      if(disp.result.success==1){
        if(disp.data.disponible){
          setErrorApp_username_txt('');
          setErrorApp_username_txtText('');
        }else{
          setErrorApp_username_txt('error');
          setErrorApp_username_txtText('No disponible');
        }
      }else{
        setErrorApp_username_txt('error');
        setErrorApp_username_txtText('Error comprobant disponibilitat');
      }
    }else{
      setErrorApp_username_txt('error');
      setErrorApp_username_txtText('Minim 3 lletres');
    }
  }

  const changeApp_password = (valor) =>{
    setApp_password(valor);

    if(valor.length > 0){
      setErrorApp_password('');
    }

  }
  const obrirModalAssignar = item =>{
    setAssignTriat(item);
    setModalVisibleAssignar(true);
  }
  const tancarModalAssignar = e =>{
    setActualizarAssignar(actualizarAssignar+1);
    setModalVisibleAssignar(false);
  }

  const marcarEliminat = async (id) =>{
    const fam = await Api.myFetch('/professors/relacions/'+id, 'DELETE');
    if(fam.result.success===1){
      setActualizarAssignar(actualizarAssignar+1);
    }
  }

  const columnsAssignar = [
  {
    title: props.t('professors_relacions.carrec'),
    dataIndex: 'carrec',
    key: 'carrec',
    render:(text,record) => (
      <span>{entities.decode(record.carrec[props.t('idioma')])}</span>
    )
  },
  /*{
    title:  props.t('professors_relacions.ambit'),
    dataIndex: 'ambit',
    key: 'ambit',
    render: (text, record) =>(
      <span>{record.ambit}&nbsp;<b>{entities.decode(record.ambitTxt)}</b></span>
    )
  },*/
  {
    title:  props.t('professors_relacions.ambit'),
    dataIndex: 'ambit',
    key: 'ambit'
  },
  {
    title:  '',
    dataIndex: 'ambitTxt',
    key: 'ambitTxt'
  },
  {
    title:  props.t('professors_relacions.assignatura'),
    dataIndex: 'assignatura',
    key: 'assignatura',
    render: (text,record)=>(
      <span>{record.id_assignatura > 0 ? entities.decode(record.assignatura[props.t('idioma')]) : entities.decode(record.extraescolar[props.t('idioma')]) }</span>
    )
  },
  {
    key: "actions",
    align: "right",
    width: "95px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id} key={"actions_"+record.id}>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EditOutlined />} onClick={(e) => obrirModalAssignar(record)} ghost="true" className="BotoTaula"/>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.delete')}>
            <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>marcarEliminat(record.id)}>
              <Button icon={<DeleteOutlined />} data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
  )
  }
  ];


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <>
    {props.popup!==undefined ? null :
      <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
        <LeftOutlined className="BotoBack"  onClick={props.callback}/>
      </div>
    }
    <div style={{padding:'10px 24px 10px 0px'}} ref={contenidorRef}>

      <Form onFinish={submitForm}
        layout="vertical"
        colon={true}
        autoComplete="new-password"
      >
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} lg={12} xl={8}>
        <Form.Item label={props.t('professors.nom')} validateStatus={errorNom} required={true}>
          <Input name="nom" placeholder={props.t('professors.nom')} onChange={(e) => onChangeNom(e.target.value)} defaultValue={nom}/>
        </Form.Item>
        <Form.Item label={props.t('professors.cognom1')} validateStatus={errorCognom1} required={true}>
          <Input name="cognom1" placeholder={props.t('professors.cognom1')} onChange={(e) => onChangeCognom1(e.target.value)} defaultValue={cognom1}/>
        </Form.Item>
        <Form.Item  label={props.t('professors.cognom2')}>
          <Input name="cognom2" placeholder={props.t('professors.cognom2')} onChange={(e) => setCognom2(e.target.value)} defaultValue={cognom2}/>
        </Form.Item>

        <Divider plain>{props.t('generic.dades_contacte')}</Divider>
        <Form.Item label={props.t('professors.tel1')}>
          <Input name="tel1" placeholder={props.t('professors.tel1')} onChange={(e) => setTel1(e.target.value)} defaultValue={tel1}/>
        </Form.Item>
        <Form.Item label={props.t('professors.tel2')}>
          <Input name="tel2" placeholder={props.t('professors.tel2')} onChange={(e) => setTel1(e.target.value)} defaultValue={tel2}/>
        </Form.Item>
        <Form.Item label={props.t('professors.email')}>
          <Input name="email" placeholder={props.t('professors.email')} onChange={(e) => setEmail(e.target.value)} defaultValue={email}/>
        </Form.Item>
      <Divider plain>{props.t('generic.dades_acces_app')}</Divider>
        <Form.Item label={props.t('pares.app_username')} validateStatus={errorApp_username_txt} help={errorApp_username_txtText}>
          <Input name="app_username_txt" placeholder={props.t('pares.app_username')} onChange={(e) => changeApp_username_txt(e.target.value)} defaultValue={app_username_txt} value={app_username_txt} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item label={props.t('pares.app_password')} validateStatus={errorApp_password}>
          <Input.Password name="app_password" placeholder={props.t('users.form_edit_password')} onChange={(e) => changeApp_password(e.target.value)} defaultValue={app_password} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item>
          <Row justify="start" align="middle">
            <Col style={{marginRight: '0.5rem'}}><span style={{color:'black'}}>{props.t('pares.obligar_modificar_password')}</span></Col>
            <Col>
              <Switch
              checkedChildren={props.t('generic.si')}
              unCheckedChildren={props.t('generic.no')}
              onChange={setObligar_modificar_password} defaultChecked={obligar_modificar_password}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row justify="start" align="middle">
            <Col style={{marginRight: '0.5rem'}}><span style={{color:'black'}}>{props.t('pares.app_locked')}</span></Col>
            <Col>
              <Switch
              checkedChildren={props.t('generic.si')}
              unCheckedChildren={props.t('generic.no')}
              onChange={setApp_locked} defaultChecked={app_locked}
              />
            </Col>
          </Row>
        </Form.Item>

      <Divider plain />
        <Form.Item label={props.t('pares.observacions')}>
          <Input.TextArea  name="observacions" placeholder={props.t('pares.observacions')} onChange={(e) => setObservacions(e.target.value)} defaultValue={observacions}/>
        </Form.Item>
        <Form.Item label={props.t('generic.idioma')}>
          <Select defaultValue={idioma} onChange={setIdioma} style={{ width: 110}} size="small">
            <Select.Option value="es">Castellano</Select.Option>
            <Select.Option value="ca">Català</Select.Option>
            <Select.Option value="en">English</Select.Option>
          </Select>
        </Form.Item>

      </Col>

      <Col xs={24} lg={12} xl={16}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {id == 0 ? null :
          <Col span={24}>
            <Card
              headStyle={{backgroundColor: "#e0f2f1"}}
              style={{borderColor: "#e0f2f1"}}
              hoverable={true}
              title={props.t('menu.AssignarProfessors')}
              extra={
                <Space>
                  <TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>
                  <Button type="primary" onClick={() => obrirModalAssignar(assignarBuit)}>{props.t('generic.add')}</Button>
                </Space>}>
            <Table
              rowKey="id"
              dataSource={professors_relacions}
              columns={columnsAssignar}
              pagination={false}
              size='small'
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {},
                  onDoubleClick: (e) => obrirModalAssignar(record),
                  onContextMenu: (e) => {e.preventDefault(); obrirModalAssignar(record);},
                };
              }}
            />
            </Card>
            <br/>
          </Col>
        }
          <Col span={24}>
            <Form.Item
              label={props.t('pares.dni')}
              labelCol={{span:2}}
              wrapperCol={{span:10}}
            >
              <Input name="dni" placeholder={props.t('pares.dni')} onChange={(e) => setDni(e.target.value)} defaultValue={dni}/>
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
              <ImatgeDNI
                image={dni_image_1}
                title={props.t('pares.dni_image_1')}
                dataUrl={dni_image_1_dataUrl}
                setDataUrl={setDni_image_1_dataUrl}
                setVisor={setVisor}
              />
          </Col>
          <Col  xs={24} xl={12}>
              <ImatgeDNI
                image={dni_image_2}
                title={props.t('pares.dni_image_2')}
                dataUrl={dni_image_2_dataUrl}
                setDataUrl={setDni_image_2_dataUrl}
                setVisor={setVisor}
              />
          </Col>
          <Col span={24} style={{paddingTop: '3rem'}}>
              <Form.Item
                label={props.t('pares.passaport')}
                wrapperCol={{span:12}}
              >
                <Input name="passaport" placeholder={props.t('pares.passaport')} onChange={(e) => setPassaport(e.target.value)} defaultValue={passaport}/>
              </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <ImatgeDNI
              image={passaport_image_1}
              title={props.t('pares.passaport_image_1')}
              dataUrl={passaport_image_1_dataUrl}
              setDataUrl={setPassaport_image_1_dataUrl}
              setVisor={setVisor}
            />
          </Col>
          <Col xs={24} xl={12}>
            <ImatgeDNI
              image={passaport_image_2}
              title={props.t('pares.passaport_image_2')}
              dataUrl={passaport_image_2_dataUrl}
              setDataUrl={setPassaport_image_2_dataUrl}
              setVisor={setVisor}
            />
          </Col>
          </Row>

        </Col>

        <Col span={24}>
          <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
            <Col>
              <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Col>
</Row>
      </Form>
      { !visor.visible
        ? null
        : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
      }
      <Modal
          key="assignar"
          title={nom+' '+cognom1}
          visible={modalVisibleAssignar}
          footer={null}
          onCancel={tancarModalAssignar}
          width={750}
          style={{top:20}}
          //bodyStyle={{ height: "88vh", overflow: "auto" }}
          destroyOnClose={true}
          forceRender={true}
        >
          <FormProfessorsAssignar id_curs_escolar={cursEscolarTriat} id_profe={id} item={assignTriat} callback={tancarModalAssignar} popup={true}/>
        </Modal>
    </div>
  </>
  );
}
export default translate(Edit);
