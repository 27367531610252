import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

function Logout(props){

  useEffect(() => {
    sessionStorage.removeItem('jwtToken');
    sessionStorage.removeItem('jwtRefreshToken');
    sessionStorage.removeItem('perfil');
    props.callback();
  },[]);


  return(<Redirect to='/login' />);

}

export default Logout;
