import React, { useState, useEffect, useContext } from 'react';
import { translate, setLanguage, getLanguage } from 'react-multi-lang';
import { Menu, Select, Row, Col } from 'antd';
import {
  ApartmentOutlined,
  ContactsOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  PoweroffOutlined,
  SettingOutlined,
  SkinOutlined,
  TeamOutlined,
  UserOutlined,
  MobileOutlined,
} from '@ant-design/icons';

import { MenuTriatContext } from "../context/menu-triat";

import {ReactComponent as Botiga} from '../icons/icones_app_BSCD-01.svg';
import {ReactComponent as Serveis} from '../icons/icones_app_BSCD-02.svg';
import {ReactComponent as Dades} from '../icons/icones_app_BSCD-03.svg';
import {ReactComponent as Metge} from '../icons/icones_app_BSCD-07.svg';
import {ReactComponent as Documents} from '../icons/icones_app_BSCD-05.svg';
import {ReactComponent as Notificar} from '../icons/icones_app_BSCD-04.svg';
import {ReactComponent as Expedient} from '../icons/icones_app_BSCD-06.svg';
import {ReactComponent as Rebudes} from '../icons/icones_app_BSCD-08.svg';
import {ReactComponent as Menjador} from '../icons/ic_menjador.svg';


const { SubMenu } = Menu;

function MenuLateral(props) {
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [openKeys, setOpenKeys] = useState(['Dashboard']);

  // submenu keys of first level
  const rootSubmenuKeys = ['Dashboard','Pares_Botiga', 'Pares_Serveis', 'Pares_MevesDades', 'Pares_ServeiMedicArrel', 'Pares_Documents', 'Pares_Notificar', 'Pares_Expedient', 'Pares_NotificacionsRebudes', 'Pares_Configuracio'];

  useEffect(() => {
    if(menuTriat==='Dashboard'){
      setOpenKeys(['Dashboard']);
      document.getElementById("Dashboard").classList.add("ant-menu-item-selected");
      //Array.from(document.getElementsByClassName('MenuLateral ant-menu-submenu-open ant-menu-submenu-selected')).forEach((el) => el.classList.remove('ant-menu-submenu-selected'));
      //Array.from(document.getElementsByClassName('MenuLateral ant-menu-submenu-open')).forEach((el) => el.classList.remove('ant-menu-submenu-open'));
      Array.from(document.getElementsByClassName('ant-menu-item-only-child ant-menu-item-selected')).forEach((el) => el.classList.remove('ant-menu-item-selected'));
    }else{
      document.getElementById("Dashboard").classList.remove("ant-menu-item-selected");
    }
  },[menuTriat]);


  const changeLang = (lang) =>{
      setLanguage(lang);
      sessionStorage.setItem('Language',lang);
    }

    const onOpenChange = openKeys_list => {
      //console.log('openKeys', openKeys);
      //console.log('openKeys_list', openKeys_list);
      const latestOpenKey = openKeys_list.find(key => openKeys.indexOf(key) === -1);
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        setOpenKeys(openKeys_list);
      } else {
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);

      }
    };
    const triarMenu = (menuTriat) =>{
      //setPerfilUsuari(perfilUsuari => ({...perfilUsuari, menuTriat: key}));
      setMenuTriat(menuTriat);
    }


    return (
      <Menu
        theme="light"
        className="MenuLateral"
        mode="inline"
        onClick={({key}) => triarMenu(key)}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{ width: '100%', height: '100%' }}
        defaultSelectedKeys="Dashboard"
      >
        <Menu.Item key="Dashboard" className="MenuLateral" id="Dashboard">
          <span>
            <HomeOutlined />
            <span>{props.t('menu.inicio')}</span>
          </span>
        </Menu.Item>


          <Menu.Item key="Pares_Botiga" className="MenuLateral">
            <Row >
              <Col span={5}><Botiga width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
              <Col span={12}><span>{props.t('menu.Botiga')}</span></Col>
            </Row>
          </Menu.Item>

          <SubMenu
           className="MenuLateral"
            key="Pares_Serveis"
            title={
              <Row>
                <Col span={5}><Serveis width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
                <Col span={12}><span>&nbsp;{props.t('menu.Serveis')}</span></Col>
              </Row>
            }
          >

            <Menu.Item key="Pares_Transport" className="MenuLateral">
              <span>{props.t('menu.Transport')}</span>
            </Menu.Item>
            <Menu.Item key="Pares_Extraescolars" className="MenuLateral">
              <span>{props.t('menu.Extraescolars')}</span>
            </Menu.Item>
            <Menu.Item key="Pares_Menjador" className="MenuLateral">
              <span>{props.t('menu.Menjador')}</span>
            </Menu.Item>
            {/*<Menu.Item key="Pares_Acollida" className="MenuLateral">
              <span>{props.t('menu.Acollida')}</span>
            </Menu.Item>*/}
          </SubMenu>


          <SubMenu
           className="MenuLateral"
           key="Pares_MevesDades"
           title={
              <Row >
              <Col span={5}><Dades width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
              <Col span={12}><span>{props.t('menu.MevesDades')}</span></Col>
            </Row>
            }
          >
            <SubMenu
              className="MenuLateral"
              key="Pares_DadesGenerals"
             title={<span>{props.t('menu.DadesGenerals')}</span>}
            >

                <Menu.Item key="Pares_DadesPersonals" className="MenuLateral">
                  <span>{props.t('menu.DadesPersonals')}</span>
                </Menu.Item>
                <Menu.Item key="Pares_DadesFills" className="MenuLateral">
                  <span>{props.t('menu.DadesFills')}</span>
                </Menu.Item>
                <Menu.Item key="Pares_DadesFamilia" className="MenuLateral">
                  <span>{props.t('menu.DadesFamilia')}</span>
                </Menu.Item>

            </SubMenu>
            {/*<Menu.Item key="Pares_ServeiMedic" className="MenuLateral">
              <span>{props.t('menu.ServeiMedic')}</span>
            </Menu.Item>*/}
            <Menu.Item key="Pares_DadesAutoritzats" className="MenuLateral">
              <span>{props.t('menu.DadesAutoritzats')}</span>
            </Menu.Item>
            <Menu.Item key="Pares_ElsRebuts" className="MenuLateral">
              <span>{props.t('menu.ElsMeusRebuts')}</span>
            </Menu.Item>
            <Menu.Item key="Pares_DadesFacturacio" className="MenuLateral">
              <span>{props.t('menu.DadesFacturacio')}</span>
            </Menu.Item>

          </SubMenu>

          <Menu.Item key="Pares_ServeiMedicArrel" className="MenuLateral">
            <Row >
              <Col span={5}><Metge width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
              <Col span={12}><span>{props.t('menu.ServeiMedic')}</span></Col>
            </Row>
          </Menu.Item>

          <Menu.Item key="Pares_Documents" className="MenuLateral">
            <Row >
              <Col span={5}><Documents width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
              <Col span={12}><span>{props.t('menu.Documents')}</span></Col>
            </Row>
          </Menu.Item>
          <Menu.Item key="Pares_Notificar" className="MenuLateral" >
            <Row >
              <Col span={5}><Notificar width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
              <Col span={12}><span>{props.t('menu.Notificacions')}</span></Col>
            </Row>
          </Menu.Item>
          <Menu.Item key="Pares_MealTimeReport" className="MenuLateral" >
            <Row >
              <Col span={5}><Menjador width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
              <Col span={12}><span>{props.t('menjador.report.titol')}</span></Col>
            </Row>
          </Menu.Item>
        {/*
          <Menu.Item key="Pares_Expedient" className="MenuLateral" disabled>
            <Row >
              <Col span={5}><Expedient width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
              <Col span={12}><span>Expedient acadèmic</span></Col>
            </Row>
          </Menu.Item>
          <Menu.Item key="Pares_NotificacionsRebudes" className="MenuLateral" disabled>
            <Row >
              <Col span={5}><Rebudes width={40} height={40} style={{padding: 0, margin: 0}}/></Col>
              <Col span={12}><span>Notificacions Rebudes</span></Col>
            </Row>
          </Menu.Item>
*/}


      {/*  <SubMenu
         className="MenuLateral"
          key="Pares_Configuracio"
          title={
            <span>
              <SettingOutlined />
              <span>{props.t('menu.configuracion')}</span>
            </span>
          }
        >


          <Menu.Item key="Usuaris" className="MenuLateral" disabled>
            <span>
              <UserOutlined />
              <span>{props.t('menu.usuarios')}</span>
            </span>
          </Menu.Item>*/}

          {/*<div style={{paddingLeft:'48px'}}>*/}
          <div style={{paddingLeft:'24px'}}>
            <span>{props.t('generic.idioma')}:&nbsp;&nbsp;</span>
            <Select defaultValue={getLanguage()} onChange={changeLang} style={{ width: 110}} size="small">
              <Select.Option value="es">Castellano</Select.Option>
              <Select.Option value="ca">Català</Select.Option>
              <Select.Option value="en">English</Select.Option>
              {/*idiomes*/}
            </Select>
          </div>

      {/*  </SubMenu>*/}
        <Menu.Item key="Logout" className="MenuLateral">
          <span>
            <PoweroffOutlined />
            <span>{props.t('menu.cerrar_sesion')}</span>
          </span>
        </Menu.Item>
      </Menu>
    );
}

export default translate(MenuLateral);
