import React, { useContext, useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Button, List, Card, Tabs, Modal } from 'antd';
import { AlertFilled } from '@ant-design/icons';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import { AllHtmlEntities } from 'html-entities';
import { MenuTriatContext } from "../context/menu-triat";
import { PerfilUsuariContext } from "../context/perfil-usuari";
import {ReactComponent as Carrito} from '../icons/icones_app_BSCD-01.svg';
import DashboardClasse from '../components/Formularis/perfils/profe/DashboardClasse';
import NewPassword from '../components/PantallesObligadesInici/NewPassword';
import {useInterval} from '../helpers/UseInterval';

const entities = new AllHtmlEntities();

function DashboardProfe(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [tabs, setTabs] = useState([]);
  const [refrescar, setRefrescar] = useState(0);
  const [mostrarNewPassword, setMostrarNewPassword] = useState(false);
/*
  useInterval(() => {
      const r = (refrescar+1)%100;
      console.log(r, new Date());
      setRefrescar(r)
    }, 1000 * 60 * 5); //5 minuts
*/
  useEffect(() => {
    setMostrarNewPassword(perfilUsuari.account.obligar_modificar_password === 1);
    montarTabs();
  },[perfilUsuari]);


  const montarTabs = () =>{

    if(perfilUsuari.dades_profe !== undefined){
      if(perfilUsuari.dades_profe.classes){
      //  console.log('DashboardProfe: ', perfilUsuari.dades_profe)
      /*
        const llista_tabs = perfilUsuari.dades_profe.classes.map((item) =>{
          return(
            <Tabs.TabPane tab={entities.decode(item.nom)} key={item.id} forceRender={true}>
              <DashboardClasse id_classe={item.id} refrescarNotificacions={refrescarNotificacions}/>
            </Tabs.TabPane>
          );
        });
        setTabs(llista_tabs);
        */
        setTabs(perfilUsuari.dades_profe.classes);
      }
    }
  }

  return(
    <>

    { tabs.length > 0
      ?
        <Tabs defaultActiveKey="1" style={{padding: 10}} onChange={(activeKey) =>setRefrescar(refrescar+1)}>
          {tabs.map((item) =>{
            return(
              <Tabs.TabPane tab={entities.decode(item.nom)} key={item.id}>
                <DashboardClasse id_classe={item.id} refrescar={refrescar}/>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      :
      <Alert
      style={{margin: 10}}
      message="Error"
      description={props.t('professors.sense_classe')}
      type="error"
      showIcon
    />

    }

    {/*<Modal
    key="NewPassword"
    visible={mostrarNewPassword}
    footer={null}
    width={550}
    style={{top:20}}
    bodyStyle={{ height: "350px"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={false}
    >
      <NewPassword/>
    </Modal>*/}


    </>
  );
}

export default translate(DashboardProfe);
