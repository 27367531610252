import React, {useState, useEffect, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Card, Empty, Radio, Modal, Button } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import AcollidaMensual from '../../../components/Formularis/perfils/pares/AcollidaMensual';
import AcollidaPuntual from '../../../components/Formularis/perfils/pares/AcollidaPuntual';

import { Api } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const entities = new AllHtmlEntities();

const mides_botonets = {width: '100%',height: '65px'};
const color = [
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#5AB7B2', borderColor: '#5AB7B2', color: 'white', borderRadius: '15px'},
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#B21E28', borderColor: '#B21E28', color: 'white', borderRadius: '15px'},
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#F7B334', borderColor: '#F7B334', color: 'white', borderRadius: '15px'},
];

//la funció de Acollida esta al final del fitxer
function AcollidaLlista(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [esPuntual, setEsPuntual] = useState([]);


  const respostaProducte = () =>{
    setModalVisible(false);
  }
  const obrirDetall = (puntual) =>{
    setEsPuntual(puntual);
    setModalVisible(true);
  }

  return(
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginTop:'1rem', marginLeft:'0.5rem'}}>
        <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{marginTop:'15px'}} key="anual">
          <Button type="primary" size="large" style={color[0]} onClick={() => obrirDetall(false)}>
            <Row>
              <Col span={24} style={{fontSize: 20}}>{props.t('generic.anual')}</Col>
            </Row>
          </Button>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{marginTop:'15px'}} key="puntual">
          <Button type="primary" size="large" style={color[1]} onClick={() => obrirDetall(true)}>
            <Row>
              <Col span={24} style={{fontSize: 20}}>{props.t('generic.puntual')}</Col>
            </Row>
          </Button>
        </Col>
      </Row>
      <Modal
        key="acollida"
        visible={modalVisible}
        footer={null}
        width={500}
        style={{top:10}}
        bodyStyle={{ overflow: "auto"}}
        destroyOnClose={true}
        forceRender={true}
        maskClosable={true}
        closable={true}
        onCancel={respostaProducte}
      >
        {esPuntual===true
          ? <AcollidaPuntual tancarModal={respostaProducte} id_alumne={props.id_alumne} ids_fills={props.ids_fills}/>
          : <AcollidaMensual tancarModal={respostaProducte} id_alumne={props.id_alumne} ids_fills={props.ids_fills}/>
        }
      </Modal>
    </>
  );

}


function Acollida(props){
  const [isLoading, setIsLoading] = useState(true);
  const [fillActual, setFillActual] = useState(1);
  const [llistaFills, setLlistaFills] = useState([]);
  const [idsFills, setIdsFills] = useState([]);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const buscarDades = async () => {
    setIsLoading(true);
    let llista_f = [];
    const fills = await Api.get(`/alumnes/familia/${perfilUsuari.account.id_familia}`);
    //console.log('dins: buscarDadesFills',fills)
    if(fills.result.success===1){
      setFillActual(fills.data.alumnes[0].id);
      let array_id_fills = [];
      llista_f = fills.data.alumnes.map((item) =>{
        //console.log(item)
        array_id_fills.push(item.id);
        return(
          <Radio.Button key={item.id} value={item.id}>{entities.decode(item.nom)}</Radio.Button>
        );
      });
      setLlistaFills(llista_f);
      setIdsFills(array_id_fills);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    buscarDades();
  },[]);

  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menu.Acollida')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :
        <>
          <Radio.Group defaultValue={fillActual} buttonStyle="solid" onChange={(e) => setFillActual(e.target.value)}>
            {llistaFills}
          </Radio.Group>
          <AcollidaLlista id_alumne={fillActual} ids_fills={idsFills} t={props.t} idioma={props.t('idioma')}/>

        </>
      }
      </div>
    </ContainerPagina>
  );
}

export default translate(Acollida);
