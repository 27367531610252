import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Tabs } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
//import UsersTaula from '../../../components/Users/Taula';
import UsersTaula from '../../../components/Formularis/perfils/admin/UsersTaula';
import { Api } from '../../../helpers';

const entities = new AllHtmlEntities();

function Usuarios(props){
  const [perfil, setPerfil] = useState('Administrador');
  const [perfils, setPerfils] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const buscarDades = async () => {
    setIsLoading(true);
    const p = await Api.get('/perfils/users');
    console.log(p)
    if(p.result.success===1){
      const llista_p = p.data.perfils.map((item) =>{
            return(
              <Tabs.TabPane tab={entities.decode(item.nom[props.t('idioma')])} key={item.id}>
                <UsersTaula perfil={item.id}/>
              </Tabs.TabPane>
            );
      });
      setPerfils(llista_p);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[props.t('idioma')]);


    return(
      <ContainerPagina xl={24}>
        <TitolPagina txt={props.t('menu.usuarios')}/>
        <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
          <Tabs defaultActiveKey={perfil} onChange={setPerfil}>
            {perfils}
          </Tabs>
        </div>
      </ContainerPagina>
    );

}
export default translate(Usuarios);
