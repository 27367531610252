import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { LeftOutlined, SyncOutlined, FolderOutlined, FolderTwoTone, FolderFilled } from '@ant-design/icons';
import { Row, Col, Card, Empty, Badge } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../context/menu-triat";
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Fitxers from '../../../components/Formularis/perfils/profe/Documents';
import { Api } from '../../../helpers';

const entities = new AllHtmlEntities();

function Banc(props) {

  const [perfilUsuari ] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

  const [carpetes, setCarpetes] = useState([]);
  const [carpetaTriada, setCarpetaTriada] = useState();
  const [isLoading, setIsLoading] = useState(true);


  const buscarDades = async () => {
    setIsLoading(true);

    let carp = [];
    let dades = {
      ids_classes: perfilUsuari.dades_profe.ids_classes
    }

    if(perfilUsuari.nomes_bus){
      carp = await Api.get('/documents/carpetes/usuari/'+perfilUsuari.account.id);
    }else{
      carp = await Api.myFetch('/documents/profe/classes/carpetes','POST', JSON.stringify(dades));
    }

  
    if(carp.result.success===1){
      //setCarpetes(carp.data.carpetes);
      const llista_c = carp.data.carpetes.map((item) =>{
        //<Card.Meta avatar={<FolderTwoTone twoToneColor="#F7B334" style={{fontSize: '3rem'}} />} title={entities.decode(item.titol)} description={entities.decode(item.subtitol)} />
        console.log(item)
          return(
            <Col xs={24} sm={12} md={12} lg={8} xl={8} key={item.id}>
              <Card
                hoverable
                onClick={(e) => triarCarpeta(item)}
              >
                <Card.Meta avatar={<Badge count={item.num_fitxers_nous} offset={[0,5]} style={{ backgroundColor: '#52c41a' }}><FolderFilled style={{fontSize: '3rem', color: '#F7B334'}} /></Badge>} title={entities.decode(item.titol)} description={entities.decode(item.subtitol)} />
              </Card>
            </Col>
          );
      });
      if(llista_c.length>0){
        setCarpetes(llista_c);
      }else{
        setCarpetes(<Empty/>);
      }
    }
    setIsLoading(false);
  }
  useEffect(() => {
    buscarDades();
  },[]);

const triarCarpeta = (item) =>{
  setCarpetaTriada({id: item.id, titol: item.titol, subtitol: item.subtitol});
}

const tancarCarpeta = async () =>{
  setCarpetaTriada(undefined);
  await buscarDades();
}

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.Documents')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        {/*<div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
          <LeftOutlined className="BotoBack" onClick={(e) => setMenuTriat("Dashboard")}/>
        </div>*/}
        {isLoading
          ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />
          :

            carpetaTriada!==undefined
                ? <Fitxers id_carpeta={carpetaTriada.id} titol={carpetaTriada.titol} subtitol={carpetaTriada.subtitol} callback={() => tancarCarpeta()}/>
                :
                  <Row gutter={[16,16]} style={{marginTop:'2rem', marginLeft:'0rem'}}>
                    {carpetes}
                  </Row>



        }


      </div>
    </ContainerPagina>
  );

}

export default translate(Banc);
