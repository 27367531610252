import React, { useEffect, useState } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Button, Typography, Form, Select, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';
import { Api, HtmlStrings } from '../../../../helpers';

import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
import DocumentUploadDelete from "../../../DocumentUploadDelete";
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function MedicAddOne(props){

  const [id, setId] = useState(0);
  const [id_alumne, setId_alumne] = useState(0);
  const [id_categoria, setId_categoria] = useState(0);
  const [text, setText] = useState('');
  const [fitxers, setFitxers] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [guardant, setGuardant] = useState(false);

  const [alumnesOptions, setAlumnesOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  const [errorId_alumne, setErrorId_alumne] = useState('');
  const [errorId_alumneText, setErrorId_alumneText] = useState('');
  const [errorId_categoria, setErrorId_categoria] = useState('');
  const [errorId_categoriaText, setErrorId_categoriaText] = useState('');


  useEffect(() =>{
    buscarDades();
  },[])

  const buscarDades = async()  =>{
    setIsLoading(true);

    const fills = await Api.get('/alumnes');
    if(fills.result.success===1){
      const llista_fills =fills.data.alumnes.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2) + ' ('+entities.decode(item.classe)+')'}
              </Select.Option>
            );
      });
      setAlumnesOptions(llista_fills);
    }


    const categories = await Api.get('/medic/categories/alumne');
    if(categories.result.success===1){

      categories.data.medic.sort((a,b) => a.ordre - b.ordre);
      const llista_categories =categories.data.medic.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom[props.t('idioma')])}
              </Select.Option>
            );
      });
      setCategoriesOptions(llista_categories);
    }

    setIsLoading(false);
  }

  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setErrorId_alumne('');
      setErrorId_alumneText('');
    }
  }

  const triaCategoria = (value) =>{
    setId_categoria(value);
    if(value>0){
      setErrorId_categoria('');
      setErrorId_categoriaText('');
    }
  }

  const guardar = async() =>{

    let correcte = true;

    if(id_alumne===0){
      setErrorId_alumne('error');
      setErrorId_alumneText(props.t('generic.tria'));
      correcte = false;
    }

    if(id_categoria===0){
      setErrorId_categoria('error');
      setErrorId_categoriaText(props.t('generic.tria'));
      correcte = false;
    }

    if(!correcte){
      return false;
    }


    setGuardant(true);

    let dades = {
      id_alumne,
      id_categoria,
      text: entities.encode(text)
    }
    try{
      const resposta = await Api.myFetch('/medic/alumne', 'POST', JSON.stringify(dades));

      if(resposta.result.success === 1){
        setId(resposta.data.id_medic);
        console.log(resposta);
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        //props.callback();

      }else{
        setGuardant(false);
        console.error('Error: submit Form BotigaTalles: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: BotigaTalles guardar: ', error);
    }
  }

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
  <>
    <Typography.Title level={3}>{props.t('generic.add')}</Typography.Title>

    <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_alumne} help={errorId_alumneText} style={{marginBottom:0}}>
      <Select
        defaultValue={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {alumnesOptions}
      </Select>
    </Form.Item>

    <Form.Item label={props.t('medic.categoria')} required={true} validateStatus={errorId_categoria} help={errorId_categoriaText} style={{marginBottom:0}}>
      <Select
        defaultValue={id_categoria===0 ? '' : id_categoria} onChange={(value) => triaCategoria(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {categoriesOptions}
      </Select>
    </Form.Item>


    <TextAreaCount
      marginTopCount='-0.1rem'
      autoSize={{ minRows: 4, maxRows: 25 }}
      maxLength={500}
      placeholder={props.t('medic.text_lliure')}
      onChange={(valor) => setText(valor)}
      defaultValue={text}/>
{id_alumne === 0 || id_categoria === 0 || id === 0 ? null :
    <DocumentUploadDelete
      padding={{padding:'10px'}}
      fileList={fitxers}
      url_upload={`/medic/alumne/${id_alumne}/categoria/${id_categoria}`}
      url_delete={`/medic/document/${id}`}
      titol={props.t('medic.pujar_doc1')}
      subtitol={props.t('medic.pujar_doc2')}
      />
}
      <Row type="flex" justify="space-between" style={{marginTop: '1rem'}}>
        <Col>
          <Button onClick={props.callback}>{props.t('generic.back')}</Button>
        </Col>
        <Col style={{marginLeft:'10px'}}>
          <Button type="primary" onClick={guardar} loading={guardant}>{props.t('generic.save')}</Button>
        </Col>
      </Row>
    </>

  );
}

export default translate(MedicAddOne);
