
import React from 'react';
import { translate } from 'react-multi-lang';
import { Form, Input, Row, Col, Tooltip, Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import TextAreaCount from './TextAreaCount';

function NomDescripcioIdiomes(props){

  const maxLength = props.maxLength ? props.maxLength : 1024;

  const populate_nom = (id) =>{
    let x = { target: { value: ''}};
    x.target.value =  eval('props.nom_'+id);

    if(id !=='ca') props.nomChange_ca(x);
    if(id !=='en') props.nomChange_en(x);
    if(id !=='es') props.nomChange_es(x);
  }

  const populate_desc = (id) =>{
    const x = eval('props.descripcio_'+id);

    if(id !=='ca') props.setDescripcio_ca(x);
    if(id !=='en') props.setDescripcio_en(x);
    if(id !=='es') props.setDescripcio_es(x);
  }

    return(
      <Row style={{marginLeft: '12px'}} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} key='nom_ca'>
          <Form.Item
            validateStatus={props.errorNom_ca}
            label={props.t('botiga.nom')+' CA'}
            labelCol={{span:5}}
            wrapperCol={{span:19}}
            required={true}
          >
            <Row>
              <Col span={23}>
                  <Input name="nom_ca" placeholder={props.t('botiga.nom')} onChange={props.nomChange_ca} value={props.nom_ca}/>
              </Col>
              <Col span={1}>
                  <Tooltip title={props.t('generic.copiar_als_altres')}>
                    <Button shape="circle" icon={<CopyOutlined />} onClick={(e)=>populate_nom('ca')}/>
                  </Tooltip>
              </Col>
            </Row>
          </Form.Item>
{!props.visibleDescripcio ? null :
          <Form.Item
            label={props.t('botiga.descripcio')+' CA'}
            labelCol={{span:5}}
            wrapperCol={{span:19}}
          >
            <Row>
              <Col span={23}>
                <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={maxLength} name="descripcio_ca" placeholder={props.t('botiga.descripcio')} onChange={props.setDescripcio_ca} value={props.descripcio_ca}/>
              </Col>
              <Col span={1}>
                  <Tooltip title={props.t('generic.copiar_als_altres')}>
                    <Button shape="circle" icon={<CopyOutlined />} onClick={(e)=>populate_desc('ca')}/>
                  </Tooltip>
              </Col>
            </Row>
          </Form.Item>
}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} key='nom_en'>
          <Form.Item
            validateStatus={props.errorNom_en}
            label={props.t('botiga.nom')+' EN'}
            labelCol={{span:5}}
            wrapperCol={{span:19}}
            required={true}
          >
            <Row>
              <Col span={23}>
                <Input name="nom_en" placeholder={props.t('botiga.nom')} onChange={props.nomChange_en} value={props.nom_en}/>
              </Col>
              <Col span={1}>
                  <Tooltip title={props.t('generic.copiar_als_altres')}>
                    <Button shape="circle" icon={<CopyOutlined />} onClick={(e)=>populate_nom('en')}/>
                  </Tooltip>
              </Col>
            </Row>

          </Form.Item>
{!props.visibleDescripcio ? null :
          <Form.Item
            label={props.t('botiga.descripcio')+' EN'}
            labelCol={{span:5}}
            wrapperCol={{span:19}}
          >
            <Row>
              <Col span={23}>
                <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={maxLength} name="descripcio_en" placeholder={props.t('botiga.descripcio')} onChange={props.setDescripcio_en} value={props.descripcio_en}/>
              </Col>
              <Col span={1}>
                  <Tooltip title={props.t('generic.copiar_als_altres')}>
                    <Button shape="circle" icon={<CopyOutlined />} onClick={(e)=>populate_desc('en')}/>
                  </Tooltip>
              </Col>
            </Row>
          </Form.Item>
}
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} key='nom_es'>
          <Form.Item
            validateStatus={props.errorNom_es}
            label={props.t('botiga.nom')+' ES'}
            labelCol={{span:5}}
            wrapperCol={{span:19}}
            required={true}
          >
          <Row>
            <Col span={23}>
              <Input name="nom_es" placeholder={props.t('botiga.nom')} onChange={props.nomChange_es} value={props.nom_es}/>
            </Col>
            <Col span={1}>
                <Tooltip title={props.t('generic.copiar_als_altres')}>
                  <Button shape="circle" icon={<CopyOutlined />} onClick={(e)=>populate_nom('es')}/>
                </Tooltip>
            </Col>
          </Row>
          </Form.Item>
{!props.visibleDescripcio ? null :
          <Form.Item
            label={props.t('botiga.descripcio')+' ES'}
            labelCol={{span:5}}
            wrapperCol={{span:19}}
          >
            <Row>
              <Col span={23}>
                <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={maxLength} name="descripcio_es" placeholder={props.t('botiga.descripcio')} onChange={props.setDescripcio_es} value={props.descripcio_es}/>
              </Col>
              <Col span={1}>
                  <Tooltip title={props.t('generic.copiar_als_altres')}>
                    <Button shape="circle" icon={<CopyOutlined />} onClick={(e)=>populate_desc('es')}/>
                  </Tooltip>
              </Col>
            </Row>
          </Form.Item>
}
        </Col>
      </Row>

    );
}
export default translate(NomDescripcioIdiomes);
