import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, InputNumber, Popconfirm, Form, Typography, Button, Tooltip, Space, Select, message } from 'antd';
import { PlusOutlined, PlusCircleOutlined, PlusCircleTwoTone, DeleteOutlined } from '@ant-design/icons';
import TriaTipusIngres from '../../../TriaTipusIngres';
import { AllHtmlEntities } from 'html-entities';
import { Api, InitDades } from '../../../../helpers';

const entities = new AllHtmlEntities();

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  if(dataIndex=='subtotal'){
    inputNode = null;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditableTable = (props) => {
  const estat = props.estat;
  const [form] = Form.useForm();
  const [data, setData] = useState(
    props.linies
    ? props.linies.map(item => {item.key = item.id_linia; return item;})
    : []
  );
  const [taulaTriada, setTaulaTriada] = useState('');
  const [editingKey, setEditingKey] = useState('');
  const [guardant, setGuardant] = useState(false);

  const isEditing = (record) => record.key === editingKey;

  const afegir = () =>{
    let nou = InitDades.rebut_linia();
    nou.taula = 'altres';
    nou.id_rebut = props.id_rebut;
    nou.key = new Date().getTime()
    setData([...data, nou]);

  }

  const edit = (record) => {
    form.setFieldsValue({
      taula: '',
      tipus: '',
      concepte: '',
      preu: '',
      unitats: '',
      descompte: '',
      participacio: '',
      subtotal: '',
      ...record,
    });
    setEditingKey(record.key);
  };


  const cancel = () => {
    setEditingKey('');
  };

  const calcularTotal = (item) =>{
    //console.log('Ans: ', item)
    const sub = (item.preu !== 0.0 && item.unitats!==0.0) ? (item.preu * item.unitats) : 0;
    if(sub !== 0.0){
      if(item.descompte!==0.0){
        const tot =  sub - (sub * item.descompte / 100);
        item.subtotal = tot;
      }else{
        item.subtotal = sub;
      }
      if(item.participacio!==100.0){
        if(item.participacio!==0.0){
          //console.log(item.subtotal)
          const part =  item.subtotal - (item.subtotal * (100 - item.participacio) / 100);
          //console.log(part)
          item.subtotal = part;
        }else{
          //console.log(item.participacio)
          item.subtotal = 0;
        }
      }
    }else{
      item.subtotal =0;
    }
    //console.log('Des: ', item)
    return item;
  }

  const save = async (key) => {
    setGuardant(true);
    try {
      let row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        let item = newData[index];
        row = calcularTotal(row);
        const dades = { ...item, ...row };
/*
        if(taulaTriada != dades.taula){
          dades.taula = taulaTriada;
          item.taula = taulaTriada;
        }
*/
        if(taulaTriada != dades.tipus && taulaTriada !== ''){
          dades.tipus = taulaTriada;
          item.tipus = taulaTriada;
        }
        console.log('guadar: ', dades)
        const nou = (item.id_linia === 0);
        const method = nou?'POST':'PUT';
        const url_api = nou? `/rebuts/linia/${item.id_rebut}` : `/rebuts/linia/${item.id_linia}`;
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
        if(resposta.result.success === 1){
          setGuardant(false);
          //message.success(props.t('generic.OK_saved'), 5);
          item.id_linia = resposta.data.id;
          newData.splice(index, 1, { ...item, ...row });
          setData(newData);
          setEditingKey('');
        }else{
          setGuardant(false);
          console.error('Error: submit RebutsEdit: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }

      } else {
        row = calcularTotal(row);
        newData.push(row);
        setData(newData);
        setEditingKey('');
        setGuardant(false);
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      setGuardant(false);
    }
  };

  const submitDelete = async (item) => {
    setGuardant(true);
    console.log(item)
    if(item.id_linia > 0){
      const dades = {
        id_rebut: item.id_rebut
      }
      try{
        const resposta = await Api.myFetch(`/rebuts/linia/${item.id_linia}`, 'DELETE', JSON.stringify(dades));
        if(resposta.result.success === 1){

          const newData = data.filter((element, index, arr) => element.id_linia != item.id_linia);
          setData(newData);
          setGuardant(false);

        }else{
          setGuardant(false);
          console.error('Error: submit RebutsEdit: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }

      } catch (errInfo) {
        console.log('Delete line Failed:', errInfo);
        setGuardant(false);
      }

    }else{
      //Si es una linia que encara no s'ha guardat que elimini només de la taula per key
      const newData = data.filter((element, index, arr) => element.key != item.key);
      setData(newData);
      setGuardant(false);
    }

  }


  const columns = [
    {
      title: 'id',
      dataIndex: "id_linia",
      editable: false,
    },{
      title: 'Tipus',
      width: '17%',
      dataIndex: "tipus",
      render: (text,record) => {

        if(isEditing(record)){
          return (
            <TriaTipusIngres defaultValue={record.tipus ? record.tipus : 'altres'} onChange={setTaulaTriada} />
          )
        }else{

          return(!record.tipus ? '' : <span>{props.t(`rebuts.tipus.${record.tipus}`)}</span>)
        }

      },
      editable: false,
    },{
      title: props.t('generic.concepte'),
      dataIndex: "concepte",
      render: (text,record) => <span>{entities.decode(record.concepte)}</span>,
      editable: true,
      inputType: 'text'
    },
    {
      title: props.t('generic.preu'),
      dataIndex: "preu",
      render: (text,record) => <span>{parseFloat(record.preu).toFixed(2)}€</span>,
      //width: '15%',
      editable: true,
      inputType: 'number'
    },
    {
      title: props.t('generic.unitats'),
      dataIndex: "unitats",
      //width: '5%',
      editable: true,
      inputType: 'number'
    },
    {
      title: props.t('generic.descompte'),
      dataIndex: "descompte",
      render: (text,record) => <span>{parseFloat(record.descompte).toFixed(2)}%</span>,
      //width: '15%',
      editable: true,
      inputType: 'number'
    },
    {
      title: props.t('banc.iban_percentatge_curt'),
      dataIndex: "participacio",
      render: (text,record) => <span>{parseFloat(record.participacio).toFixed(2)}%</span>,
      //width: '10%',
      editable: true,
      inputType: 'number'
    },
    {
      title: props.t('generic.subtotal'),
      dataIndex: "subtotal",
      render: (text,record) => <span>{parseFloat(record.subtotal).toFixed(2)}€</span>,
      //width: '10%',
      editable: true,
      inputType: 'number'
    },
    {
      title: (
        <Button type="primary" size="small" icon={<PlusOutlined/>} onClick={() => afegir()} disabled={(editingKey !=='') || (estat === 'cobrat')} >
          {props.t('generic.add')}
        </Button>
      ),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="primary" size="small" loading={guardant} onClick={() => save(record.key)} style={{marginRight: 8}}>
              {props.t('generic.save')}
            </Button>
            <Popconfirm title={props.t('generic.confirm_cancel')} onConfirm={cancel} okText={props.t('generic.si')} cancelText={props.t('generic.no')}>
              <Button type="default" size="small">{props.t('generic.cancel')}</Button>
            </Popconfirm>
          </span>
        ) : (
          <Space align="center">
            <Button type="primary" size="small" disabled={(editingKey !=='') || (estat === 'cobrat')} onClick={() => edit(record)}>
              {props.t('generic.modify')}
            </Button>
            <Tooltip placement="top" title={props.t('generic.delete')}>
              <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitDelete(record)}>
                <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
              </Popconfirm>
            </Tooltip>
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        size="small"
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        summary={(row) =>{
          let sumaSubtotals = 0;
          row.forEach(({ subtotal}) => {
            sumaSubtotals += subtotal;
          });
          props.setTotal(sumaSubtotals);
          return(
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={5}></Table.Summary.Cell>
              <Table.Summary.Cell><b>Total:</b></Table.Summary.Cell>
              <Table.Summary.Cell><b>{parseFloat(sumaSubtotals).toFixed(2)}€</b></Table.Summary.Cell>
            </Table.Summary.Row>
          )}}
      />
    </Form>
  );
};

export default translate(EditableTable);
