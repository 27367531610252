import React, {useState, useEffect} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Divider, Tooltip, Button, message, notification } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import LlistaEditableTalles from '../../components/LlistaEditableTalles';
import EditTalles from '../../components/Formularis/BotigaPropietatsTalles';
import { Api, InitDades } from '../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Propietats(props) {
  const [refrescar, setRefrescar] = useState(0)

  const buscarDades = async () => {
    const trans = await Api.get(`/botiga/propietats/${props.id_producte}`);
    if(trans.result.success!==1){return [];}
    console.log(trans.data)
    return trans.data.propietats;
  }


  const setPropietatVisible = async (id, visible) => {

    //console.log(id)
    const dades = {
      visible
    }

    try{

      const fam = await Api.myFetch(`/botiga/propietat/visible/${id}`, 'PUT', JSON.stringify(dades));
      //console.log(fam)
      if(fam.result.success===1){
        setRefrescar(refrescar+1)
        return true
      }else{
        notification.error({
          message: 'Error',
          description: 'Visible no modificat',
          placement: 'bottomRight',
          duration: 20,
        });
      }
    }catch(error){

      message.error({content: "Error visible", duration:10});
      console.error('Error: Talles visible: ', error);
    }

    return false;
  }

  const eliminarDelTot = async (id) => {

    try{
      const fam = await Api.myFetch('/botiga/propietat/'+id, 'DELETE');
      if(fam.result.success===1){
        return true
      }else{
        notification.error({
          message: 'Error',
          description: props.t('botiga_propietats.error_eliminar_usada'),
          placement: 'bottomRight',
          duration: 20,
        });
      }
    }catch(error){

      message.error({content: "Error deleting", duration:10});
      console.error('Error: Talles eliminar: ', error);
    }

    return false;
  }

  let itemBuit = InitDades.botigaPropietat();
  itemBuit.id_producte = props.id_producte;

  return(
    <div style={{marginTop:'2rem'}}>
      <Divider orientation="left">
          {props.t('botiga_propietats.titol')}
      </Divider>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditableTalles
          rowKey="id"
          txt_creats={props.t('botiga_propietats.creados')}
          txt_nou={props.t('botiga_propietats.nuevo')}
          buscarDades={buscarDades}
          edit={EditTalles}
          delete={eliminarDelTot}
          itemBuit={itemBuit}
          refrescar={refrescar}>
          <Column
          title={props.t('botiga_propietats.ordre')}
          key="ordre"
          render={(text,record) => (
            <span>{record.ordre}</span>
          )}
          width={40}
          />
          <Column
          title={props.t('generic.visible')}
          key="visible"
          sorter={ (a, b) =>(a.visible - b.visible)}
          render={(text,record) =>(
              record.visible
                ? <Button type="link" onClick={ e => setPropietatVisible(record.id, 0)}><Tooltip placement="top" title={props.t('generic.visible')}><EyeOutlined style={{color: 'green', fontSize: 24}}/></Tooltip></Button>
                : <Button type="link" onClick={ e => setPropietatVisible(record.id, 1)}><Tooltip placement="top" title={props.t('generic.no_visible')}><EyeInvisibleOutlined style={{color: 'red', fontSize: 24}}/></Tooltip></Button>
          )}
          width={50}
          />
          <Column
          title={props.t('botiga_propietats.text')}
          key="text"
          render={(text,record) =>(
            entities.decode(record.text[props.t('idioma')])
          )}/>
          <Column
          title={props.t('botiga_propietats.preu')}
          key="preu"
          render={(text,record) =>(
            <span>{record.preu}€</span>
          )}/>

        </LlistaEditableTalles>
      </div>
    </div>
  );

}

export default translate(Propietats);
