import React, { useState, useEffect } from 'react';
import { Upload, Button, Modal, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { translate } from 'react-multi-lang';
import rabbit from 'crypto-js/rabbit';
import encUtf8 from 'crypto-js/enc-utf8';
import { Api } from '../helpers';

function  DocumentUploadDelete(props){
    const [fileList, setFileList] = useState(props.fileList ? props.fileList : []);
    const actionUploadUrl = `${global.api_url}${props.url_upload}`;
    const token = rabbit.decrypt(sessionStorage.getItem('jwtToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);


    useEffect(() => {
      setFileList(props.fileList);
    },[props.fileList]);


    //console.log('props.filelist', props.fileList)

    const handleChangeFile = info => {
        if (info.file.status === 'removed') {
          return;
          //Entra aqui com a removed, però encara estem demanant confirmació en la finestra modal.
        }
        if(info.file.status === 'done' && props.changeCallback && info.file.response.result.success === 1){
          props.changeCallback(info);
        }
        let ffileList = info.fileList;
        ffileList = ffileList.map(file => {
          if (file.response) {
            //console.log("file.response: ", file.response)
            // Component will show file.url as link
            file.url = file.response.file.url;
            file.path = file.response.file.path;
            file.s3key = file.response.file.s3key;
            //file.name = 'Mandat Actual';
            file.name = file.response.file.name;
            //console.log("file: ", file)
          }
          return file;
        });
        setFileList(ffileList);
      };

    const onClickRemoveFile = file => {

        Modal.confirm({
            title: props.t('generic.upload_doc_remove'),
            content: file.name,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
              const ok = await removeFile(file);
              if(ok){
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList)
              }
              return ok;
            },
            onCancel() {
              return false;
            },
          });
    }

    const removeFile = async file =>{
      if(!props.url_delete){
        return false;
      }
      try{

        const dades = {
          s3key: file.s3key
        }
        const resposta = await Api.myFetch(props.url_delete, 'DELETE', JSON.stringify(dades));
        /*
        let response = await fetch(props.url_delete, {
          method: 'DELETE',
          headers:{
            'Authorization': token
          }
        });

        let resposta = await response.json();
        */

        if(resposta.result.success === 1){
          return true;
        }else{
          console.error(`Error1: delete :${file.s3key}`,resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:15});
          return false;
        }
      }catch(error){
        console.error(`Error2: delete :${file.s3key}:${error}`);
        message.config({top: 50});
        message.open({content: "Conection error", duration:5});
        return false;
      }
    }

    const uploadConfig = {
      multiple: false,
      name: 'fitxer',
      method: 'POST',
      action: actionUploadUrl,
      headers: {'Authorization': token},
      accept: 'application/pdf,image/png,image/jpeg,image/jpg,image/gif',
      onChange: handleChangeFile,
      onRemove: props.url_delete ? onClickRemoveFile : null,
    };

    return (
      <Upload.Dragger {...uploadConfig} fileList={fileList} style={props.padding ? props.padding : null}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{props.titol}</p>
        <p className="ant-upload-hint">{props.subtitol}</p>
      </Upload.Dragger>
    );

}

export default translate(DocumentUploadDelete);
