import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, Checkbox, Space, Switch, Select, Form, DatePicker, Alert, message } from 'antd';
import { MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

const radioStyle = {
      width:'100%',
      marginTop: '8px',
    };
function Transport(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [radios, setRadios] = useState();
  const [triat, setTriat] = useState(null);
  const [enviar_email, setEnviar_email] = useState(false);

  const [diesTriats, setDiesTriats] = useState([]);
  const [numDies, setNumDies] = useState(0);

  const [id_pare, setId_pare] = useState(0);
  const [id_alumne, setId_alumne] = useState(0);
  const [ids_fills, setIds_fills] = useState([]);
  const [id_familia, setId_familia] = useState(0);
  const [familiesOptions, setFamiliesOptions] = useState([]);
  const [paresOptions, setParesOptions] = useState([]);
  const [alumnesOptions, setAlumnesOptions] = useState([]);

  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [comprat, setComprat] = useState(false);
  const [llistaCompratsPuntual, setLlistaCompratsPuntual] = useState([]);
  const [llistaCompratsMensual, setLlistaCompratsMensual] = useState([]);
  const [idsFillsAmbCompres, setIdsFillsAmbCompres] = useState([]);

  const [error, setError] = useState('');
  const [errorId_Familia, setErrorId_Familia] = useState('');
  const [errorId_FamiliaText, setErrorId_FamiliaText] = useState('');
  const [errorId_Pare, setErrorId_Pare] = useState('');
  const [errorId_PareText, setErrorId_PareText] = useState('');
  const [errorId_Alumne, setErrorId_Alumne] = useState('');
  const [errorId_AlumneText, setErrorId_AlumneText] = useState('');

  const [hiHaDescompte, setHiHaDescompte] = useState(false);
  const [descompte, setDescompte] = useState(0);
  const [total, setTotal] = useState(0);
  const [aplicarDescompte, setAplicarDescompte] = useState(false);
  const [numFills, setNumFills] = useState(0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  useEffect(() => {
    buscarDades();
  },[id_alumne, id_familia]);

  useEffect(() => {
    calcularTotal();
  },[descompte, aplicarDescompte, triat, numFills, numDies]);

  useEffect(() => {
    if(hiHaDescompte){
      const d = (triat ? triat.descompte : 0);
      setDescompte(d);
      setAplicarDescompte(triat);
    }
  },[triat]);

  useEffect(() => {
    buscarDadesFamilia();
  },[]);

  useEffect(() => {
    setId_pare(0);
    buscarDadesPares();
    setId_alumne(0);
    buscarDadesFills();
  },[id_familia]);

  const buscarDades = async () => {
    setIsLoading(true);

    const tipus = props.puntual===false ? 'mensual' : 'puntual';
    const resp = await Api.get(`/menjador/tipus/${tipus}/${id_familia}/${id_curs_escolar}`);
    if(resp.result.success===1){
      //console.log(resp.data)

      const ra = resp.data.menjadors.map((item) =>{

        if(item.descompte>0){
            setHiHaDescompte(true);
            setDescompte(item.descompte);
          }

          if(item.comprat.length>0){
            const registre = item.comprat.find((element, index) => element.id_alumne === id_alumne);
            //console.log('ids_fills: ', ids_fills)
            if(registre){
              setTriat(item);
              setComprat(true)
            }else{
              setTriat(null);
              setComprat(false)
            }

            if(item.tipus=='mensual'){
              let trobats = [];
              let _idsFillsAmbCompres = [];
              let comprats_mensual = [];
              ids_fills.forEach((idfill, i) => {
                const trobat = item.comprat.find((element, index) => element.id_alumne === idfill);
                if(trobat){
                  _idsFillsAmbCompres.push(trobat.id_alumne)
                  trobats.push(trobat);
                  comprats_mensual.push(<span style={{color: 'red'}}>{trobat.nom}</span>)
                  setIdsFillsAmbCompres(_idsFillsAmbCompres)
                }
              });
              if(trobats.length>0){
                setNumFills(trobats.length)
                setLlistaCompratsMensual(comprats_mensual);
              }
            }else{
              const comprats_puntual = item.comprat.map((i) =>{
                var d = new Date(i.puntual_data);
                return(
                  <Row justify="space-between" key={i.puntual_data}>
                    <Col>{entities.decode(i.nom)}</Col>
                    <Col>{d.toLocaleDateString()}</Col>
                  </Row>
                );
              });
              setLlistaCompratsPuntual(comprats_puntual);
            }
          }

        return(
          <Radio.Button style={radioStyle} value={item} key={item.id}>
            <table width="100%">
              <tbody>
                <tr>
                  <td style={{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                    {entities.decode(item.nom[props.t('idioma')])}
                  </td>
                  <td width="30" align="right">
                    {item.preu}€
                  </td>
                </tr>
              </tbody>
            </table>
          </Radio.Button>
        );
      });
      setRadios(ra);
    }
    setIsLoading(false);
  }

const calcularTotal = () => {
  if(props.puntual){
    calcularPuntual();
  }else{
    calcularMensual();
  }
}

const calcularPuntual = () =>{
  let subtotal = 0;
  if(triat){
    subtotal = subtotal + (triat.preu * numFills * numDies);
  }
  setTotal(subtotal.toFixed(2));
}


const calcularMensual = () =>{
  let subtotal = 0;

  const _numFills = idsFillsAmbCompres.includes(id_alumne) ? numFills : 1;

  if(triat){
    subtotal = subtotal + (triat.preu * _numFills);
  }

  if(aplicarDescompte && descompte > 0 ){
    subtotal = (subtotal - (subtotal * descompte / 100));
  }
  setTotal(subtotal.toFixed(2));
}


const buscarDadesFamilia = async () => {
  const fam = await Api.get('/families');
  if(fam.result.success===1){
    const llista_fam = fam.data.families.map((item) =>{
          return(
            <Select.Option key={item.id} value={item.id}>
              {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
            </Select.Option>
          );
    });
    setFamiliesOptions(llista_fam);
  }
}
const buscarDadesPares = async () => {
  if(id_familia > 0){
    const pares = await Api.get(`/pares/familia/${id_familia}`);
    if(pares.result.success===1){
      const llista_pares = pares.data.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setParesOptions(llista_pares);
    }
  }
}
const buscarDadesFills = async () => {
  if(id_familia > 0){
    const fills = await Api.get(`/alumnes/familia/${id_familia}`);
    if(fills.result.success===1){
      let array_id_fills = [];
      const llista_fills =fills.data.alumnes.map((item) =>{
            array_id_fills.push(item.id);
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setAlumnesOptions(llista_fills);
      setIds_fills(array_id_fills);
    }
  }
}


  const submitForm = async (valors) => {

    if(!triat){
      message.config({top: 50});
      message.error({content: 'Error: '+ props.t('generic.tria'), duration:3});
      return false;
    }

    if(props.puntual === true){
      if(diesTriats.length <= 0){
        message.config({top: 50});
        message.error({content: 'Error: '+ props.t('generic.tria_dies'), duration:3});
        return false;
      }
    }

    let correcte = true;

    if(id_familia===0){
      setErrorId_Familia('error');
      setErrorId_FamiliaText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_pare===0){
      setErrorId_Pare('error');
      setErrorId_PareText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_alumne===0 && numFills===0){
      setErrorId_Alumne('error');
      setErrorId_AlumneText(props.t('generic.tria'));
      correcte = false;
    }

    if(!correcte){
      return false;
    }

  setGuardant(true);
    let dades = {};

   if(props.puntual === true){
     dades = {
       id_curs_escolar,
       id_menjador: triat.id,
       id_alumnes: numFills === 1 ? [id_alumne] : ids_fills,
       id_pare,
       tipus: 'puntual',
       puntual_dates: diesTriats.map((item) =>item.format(moment.HTML5_FMT.DATE)),
       enviar_email
     }
   }else{
     dades = {
       id_curs_escolar,
       id_menjador: triat.id,
       id_alumnes:  numFills === 1 ? [id_alumne] : ids_fills,
       id_pare,
       tipus: 'mensual',
       enviar_email
     }
   }


  //console.log(JSON.stringify(dades));

    try{
      const resposta = await Api.myFetch('/menjador/comanda/admin', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Menjador: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Menjador guardar: ', error);
    }

  }

  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_Familia('');
      setErrorId_FamiliaText('');
    }
  }
  const triaPare = (value) =>{
    setId_pare(value);
    if(value>0){
      setErrorId_Pare('');
      setErrorId_PareText('');
    }
  }
  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setNumFills(numFills===0 ? 1 : numFills);
      setErrorId_Alumne('');
      setErrorId_AlumneText('');
    }
  }

  const onChangeRadio = (item) => {
    setTriat(item);
  }

  const onChangeTotsFills = (tots) =>{
    setNumFills(tots ? ids_fills.length : 1);
  }

  const onDiaTriat = (date, dateString) =>{
    const trobat = diesTriats.filter((element, index, arr) => element.format(moment.HTML5_FMT.DATE) === date.format(moment.HTML5_FMT.DATE));
    if(trobat.length === 0){
      const dies = [...diesTriats, date];
      setDiesTriats(dies);
      setNumDies(dies.length);
    }

    //console.log(dateString)
  }
  const onEliminaDia = (item) => {


    const filtrat = diesTriats.filter((element, index, arr) => element !== item);
    setDiesTriats(filtrat);
    setNumDies(filtrat.length);
  }



  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';


  return(
    <>

    <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_Familia} help={errorId_FamiliaText} style={{marginBottom: 0}}>
      <Select defaultValue={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {familiesOptions}
      </Select>
    </Form.Item>

  {id_familia <=0 ? null :
    <>
      <Form.Item label={props.t('generic.responsable')} required={true} validateStatus={errorId_Pare} help={errorId_PareText} style={{marginBottom: 0}}>
        <Select value={id_pare===0 ? '' : id_pare} onChange={(value) => triaPare(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {paresOptions}
        </Select>
      </Form.Item>
      <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_Alumne} help={errorId_AlumneText} style={{marginBottom: 0}}>
        <Select value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {alumnesOptions}
        </Select>
      </Form.Item>
    </>
  }

    { !radios ? null :
    <>
      <Row justify="center" style={{marginTop:'10px'}}>
        <Typography.Title level={4}>{props.t('menu.Menjador')}</Typography.Title>
      </Row>

      <Row justify="left">
        <Form.Item required={true} validateStatus={error} style={{marginTop: 0, paddingRight: '20px', width: '100%'}}>
          <Radio.Group style={{margin: '10px', width: '100%'}} onChange={(e) => onChangeRadio(e.target.value)} optionType="button" buttonStyle="solid" value={triat}>
            {radios}
          </Radio.Group>
        </Form.Item>
      </Row>


    </>
    }


{props.puntual === false ? null :
  <>
    <Row justify="center" style={{marginTop:'10px'}}>
      <DatePicker
        style={{width: '200px'}}
        format={dateFormat}
        locale={localeDatePicker}
        placeholder={props.t('generic.tria_dies')}
        value={null}
        onChange={onDiaTriat}/>
    </Row>
    {!comprat ? null :
    <Row justify="center" style={{marginTop:'10px'}}>
      <Alert
        message={props.t('generic.serveiJaContractat_L1')}
        description={
          <>
            {llistaCompratsPuntual}
            {<span style={{color:'red'}}>*{props.t('generic.serveiJaContractat_L2ADMIN')}</span>}
          </>
        }
        type="success"
        showIcon
      />
    </Row>
    }
    <Row justify="center" style={{marginTop:'10px'}}>
    <Col span={8}>
    {diesTriats.map((item) =>{
      return(
        <Row justify="space-between" key={item.format(dateFormat)}>
          <Col>{item.format(dateFormat)}</Col>
          <Col><DeleteOutlined onClick={()=>onEliminaDia(item)}/></Col>
        </Row>
      );
    })}
    </Col>
    </Row>
  </>
}
    <Row justify="center" style={{marginTop:'10px'}}>
      <Space direction="vertical" align="center">
        <Space><b style={{fontSize: '18px'}}>Total:</b><span style={{color: 'red', fontSize: '18px'}}>{total}€</span></Space>
        {!aplicarDescompte ? null : <span  style={{color: 'red'}}>({props.t('SummerCamp.desc_inclos1')}{descompte}%{props.t('SummerCamp.desc_inclos2')})</span>}
      </Space>
    </Row>


    {(ids_fills.length <= 1) || (llistaCompratsMensual.length > 0) ? null :
      <Row justify="center" style={{marginTop:'10px'}}>
        <Checkbox onChange={(e) => onChangeTotsFills(e.target.checked)} checked={ids_fills.length===numFills} disabled={comprat && props.puntual===false}>{props.t('transport.tots_fills')}</Checkbox>
      </Row>
    }
    {!comprat || props.puntual===true
      ?
        <>
          <Row>
            <Space>
              <span>{props.t('generic.enviarEmail')}</span>
              <Switch onChange={setEnviar_email} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
            </Space>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() => submitForm()} loading={guardant}>
              {props.t('generic.save')}
            </Button>
          </Row>
        </>
      :
        <>
          <Row justify="center" style={{margin: '20px 0px 0px 0px'}}>
            <span style={{color: 'red'}}>*{props.t('generic.serveiJaContractatADMIN')}</span>
          </Row>
          <Row justify="center">
            <Space>
              {llistaCompratsMensual}
            </Space>
          </Row>
          <Row justify="center" style={{marginTop: '20px'}}>
            <Button type="primary" onClick={() =>props.tancarModal()}>
              {props.t('generic.back')}
            </Button>
          </Row>
        </>
      }
    </>
  );
}
export default translate(Transport);
