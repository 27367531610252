import React, {useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/Menjador';
import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function Menjador(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);
  const [refrescar, setRefrescar] = useState(0);

  const buscarDades = async () => {
    const e = await Api.get(`/menjador/curs/${cursEscolarTriat}`);
    if(e.result.success===1){
      return e.data.menjadors;
    }

  }

  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/menjador/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.Menjador')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          id_curs_escolar={cursEscolarTriat}
          btn_extra={<TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>}
          txt_creats={props.t('menjador.creados')}
          txt_nou={props.t('menjador.nuevo')}
          buscarDades={buscarDades}
          refrescar={refrescar}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.menjador()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />

          <Column
          title={props.t('menjador.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom[props.t('idioma')])
          )}
          />
          <Column
          title={props.t('menjador.preu')}
          key="preu"
          render={(text,record) =>(
            <span>{record.preu}€</span>
          )}
          />
          <Column
          title={props.t('menjador.descompte')}
          key="descompte"
          render={(text,record) =>(
            <span>{record.descompte}%</span>
          )}
          />

        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Menjador);
