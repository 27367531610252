import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function RebutsExportaExcel(props){

  const [dades, setDades] = useState([]);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Rebuts';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'Rebuts';

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={props.dades} name={nom_fulla}>
            <ExcelColumn label="Referencia" value={(col) => col.id_pressupost.toString()+'-'+col.id.toString()}/>
            <ExcelColumn label="Tipus" value="tipus_pagament"/>
            <ExcelColumn label="id_familia" value="id_familia"/>
            <ExcelColumn label="id_alumne" value="id_alumne"/>
            <ExcelColumn label="Alumne" value={(col) => entities.decode(col.nom) + ' ' + entities.decode(col.cognom1)}/>
            <ExcelColumn label="Import" value="import"/>
            <ExcelColumn label="Concepte" value={(col) => entities.decode(col.nom) +': '+entities.decode(col.concepte)}/>
            <ExcelColumn label="Responsable1" value={(col) => {
                const res = JSON.parse(col.responsable1);
                return res ? entities.decode(res.nom) +' '+entities.decode(res.cognom1) : '';
              }}
              />
            <ExcelColumn label="Tel1" value={(col) => {
                const res = JSON.parse(col.responsable1);
                return res ? res.tel1 : '';
              }}
              />
            <ExcelColumn label="Tel2" value={(col) => {
                const res = JSON.parse(col.responsable1);
                return res ? res.tel2 : '';
              }}
              />
            <ExcelColumn label="Email" value={(col) => {
                const res = JSON.parse(col.responsable1);
                return res ? res.email : '';
              }}
              />
            <ExcelColumn label="Responsable2" value={(col) => {
                const res = JSON.parse(col.responsable2);
                return res ? entities.decode(res.nom) +' '+entities.decode(res.cognom1) : '';
              }}
              />
            <ExcelColumn label="Tel1" value={(col) => {
                const res = JSON.parse(col.responsable2);
                return res ? res.tel1 : '';
              }}
              />
            <ExcelColumn label="Tel2" value={(col) => {
                const res = JSON.parse(col.responsable2);
                return res ? res.tel2 : '';
              }}
              />
            <ExcelColumn label="Email" value={(col) => {
                const res = JSON.parse(col.responsable2);
                return res ? res.email : '';
              }}
              />
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(RebutsExportaExcel);
