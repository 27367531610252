import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined, ClearOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, InitDades, HtmlStrings } from '../../../../helpers';
import TitolPagina from '../../../../components/TitolPagina';
import FacturacioConcepteEdit from '../../../../components/Formularis/perfils/admin/FacturacioConcepteEdit';
import FacturacioExportaExcel from '../../../../components/Formularis/perfils/admin/FacturacioExportaExcel';
import FacturacioToRebuts from '../../../../components/Formularis/perfils/admin/FacturacioToRebuts';
//import RebutsBancExportaExcel from '../../../../components/Formularis/perfils/admin/RebutsBancExportaExcel';
//import RebutsAltresExportaExcel from '../../../../components/Formularis/perfils/admin/RebutsAltresExportaExcel';

const entities = new AllHtmlEntities();


function FacturacioEdit(props){

  const [linies, setLinies]=useState([]);
  const [itemActual, setItemActual] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleGenerarRebuts, setModalVisibleGenerarRebuts] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [refrescar, setRefrescar] = useState(0);

  const [filtresClasses, setFiltresClasses] = useState([]);
  //const [dadesRebutsBanc, setDadesRebutsBanc] = useState([]);
  //const [dadesRebutsAltres, setDadesRebutsAltres] = useState([]);
  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA
  //filtres i ordenacio
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const tableHandleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  }
  const clearAllFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  }
  //FI filtres i ordenacio

  const filtreTipusPagament = [
    {text: props.t('families.pagament_banc'), value:'BANC'},
    {text: props.t('families.pagament_transferencia'), value:'TRANSFERENCIA'},
    {text: props.t('families.pagament_efectiu'), value:'EFECTIU'},
    {text: props.t('families.pagament_targeta'), value:'TARGETA'},
    {text: props.t('families.pagament_xec_guarderia'), value:'XEC_GUARDERIA'}
  ];

  useEffect(() => {
    buscarDades();
  },[refrescar]);

  useEffect(() => {
    llistatClasses();
  },[]);

  useEffect(() => {
    console.log('FacturacioEdit props', props)
  },[props]);


  const buscarDades = async () =>{
    setIsLoading(true);
    const p = await Api.get('/pressupost/detall/'+props.item.id);
    if(p.result.success===1){

      const pressupostos_detall = p.data.pressuposts_detall.map((item)=>{
        item.curs_nom_t = entities.decode(item.curs_nom);
        item.classe_nom_t = entities.decode(item.classe_nom);
        item.nom_t = entities.decode(item.nom);
        item.cognom1_t = entities.decode(item.cognom1);
        item.cognom2_t = entities.decode(item.cognom2);

        return item;
      })

      setLinies(pressupostos_detall);
    }

/*

/pressupost/botiga


    const rebuts = await Api.myFetch('/pressupost/genera/rebuts/'+props.item.id, 'POST');
    if(rebuts.result.success===1){
      setDadesRebutsBanc(rebuts.data.rebuts.banc);
      setDadesRebutsAltres(rebuts.data.rebuts.altres);
      //console.log('Altres: ',rebuts.data.rebuts.altres)
    }*/


    setIsLoading(false);
  }

  const generarRebuts = () => {
    setConfirmVisible(false);
    setModalVisibleGenerarRebuts(true);
  }

  const generarRebuts_OLD = async() => {
    setConfirmLoading(true);
    try{

      //Insertar compres de la botiga al pressupost
      const compres = await Api.myFetch('/pressupost/botiga', 'POST', JSON.stringify({id_pressupost: props.item.id}));
      if(compres.result.success===1){
        //Passar el pressupost a rebuts, marcar pressupost com a facturat i marcar compres com a facturades.
        const rebuts = await Api.myFetch('/pressupost/genera/rebuts/'+props.item.id, 'POST');
        if(rebuts.result.success===1){
          message.success(props.t('generic.OK_saved'), 5);
          setConfirmLoading(false);
          setConfirmVisible(false);
          props.item.facturat = true;
          //props.callback();
          setRefrescar(refrescar+1);
        }else{
          setConfirmVisible(false);
          setConfirmLoading(false);
          console.error('Error: FacturacioEdit GeneraRebuts: ',rebuts);
          message.config({top: 50});
          message.open({content: rebuts.data.message, duration:10});
        }
      }else{
        setConfirmVisible(false);
        setConfirmLoading(false);
        console.error('Error: FacturacioEdit factura botiga: ',compres);
        message.config({top: 50});
        message.open({content: compres.data.message, duration:10});
      }

    }catch(error){
      setConfirmVisible(false);
      setConfirmLoading(false);
      message.config({top: 50});
      message.open({content: "Error generant", duration:10});
      console.error('Error: FacturacioEdit: ', error);
    }
  }


  const llistatClasses = async() =>{
    //const cla = await Api.get('/classes');
    const cla = await Api.get(`/classes/all/${props.item.id_curs_escolar}`);

    if(cla.result.success===1){
      const filtreCl = cla.data.classes.map((item)=>{
        return {text: item.nom, value: item.id}
      });
      setFiltresClasses(filtreCl);
    }
  }

  const afegirConcepte = (row) =>{
    let itemBuit = InitDades.fac_pressupostos_linies();
    itemBuit.id_pressupost = row.id_pressupost;
    itemBuit.id_alumne = row.id_alumne;
    setItemActual(itemBuit);
    setModalVisible(true);
  }

  const editarConcepte = (item) =>{
    setItemActual(item);
    setModalVisible(true);
  }

  const eliminarConcepte = async (id_concepte) =>{
      //console.log('eliminarConcepte')
      try{
        const resposta = await Api.myFetch(`/pressupost/concepte/${id_concepte}`, 'DELETE');
        if(resposta.result.success === 1){
          message.success(props.t('generic.OK_saved'), 5);
          setRefrescar(refrescar+1);
        }else{
          console.error('Error: delete FacturacioEdit: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: delete FacturacioEdit: ', error);
      }
  }

  const tancarModal = e =>{
    setRefrescar(refrescar+1);
    setModalVisible(false);
  }

  const tancarModalGenerarRebuts = e =>{
    setModalVisibleGenerarRebuts(false);
  }


  const calcularImport = (preu, unitats, desc) =>{
    let tot = 0;
    if(preu !== 0.0){
      if(desc!==0.0){
        const sub = preu * unitats;
        tot =  sub - (sub * desc / 100);
      }else{
        tot = preu * unitats;
      }
    }
    return tot;
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA


  const columns = [
    {
      title:"Id",
      key:"id_alumne",
      dataIndex: "id_alumne",
      width: 80,
      sorter: (a, b) =>  a.id_alumne - b.id_alumne,
      sortOrder: sortedInfo.columnKey === 'id_alumne' && sortedInfo.order,
      render: (text,record) => (<span>{record.id_alumne}</span>)
    },{
      title:"Classe",
      key:"classe_nom",
      dataIndex: "classe_nom",
      filters: filtresClasses,
      filteredValue: filteredInfo.classe_nom || null,
      width: 180,
      onFilter: (value, record) => record.id_classe === value,
      sorter: (a, b) => {return entities.decode(a.classe_nom).localeCompare(entities.decode(b.classe_nom))},
      sortOrder: sortedInfo.columnKey === 'classe_nom' && sortedInfo.order,
      render: (text,record) => (<span>{entities.decode(record.classe_nom)}</span>)
    },{
      title:"Nom",
      key:"nom",
      dataIndex: "nom",
      sorter: (a, b) => {return entities.decode(a.nom).localeCompare(entities.decode(b.nom))},
      sortOrder: sortedInfo.columnKey === 'nom' && sortedInfo.order,
      render: (text,record) => (<span>{entities.decode(record.nom)}</span>),
      ...getColumnSearchProps('nom', props.t('alumnes.nom')),
      filteredValue: filteredInfo.nom || null
    },{
      title:"Cognom1",
      key:"cognom1",
      dataIndex: "cognom1",
      sorter: (a, b) => {return entities.decode(a.cognom1).localeCompare(entities.decode(b.cognom1))},
      sortOrder: sortedInfo.columnKey === 'cognom1' && sortedInfo.order,
      render: (text,record) => (<span>{entities.decode(record.cognom1)}</span>),
      ...getColumnSearchProps('cognom1', props.t('alumnes.cognom1')),
      filteredValue: filteredInfo.cognom1 || null
    },{
      title:"Cognom2",
      key:"cognom2",
      dataIndex: "cognom2",
      sorter: (a, b) => {return entities.decode(a.cognom2).localeCompare(entities.decode(b.cognom2))},
      sortOrder: sortedInfo.columnKey === 'cognom2' && sortedInfo.order,
      render: (text,record) => (<span>{entities.decode(record.cognom2)}</span>),
      ...getColumnSearchProps('cognom2', props.t('alumnes.cognom2')),
      filteredValue: filteredInfo.cognom2 || null
    },
    {
      title: props.t('families.tipus_pagament'),
      dataIndex: "tipus_pagament",
      key: "tipus_pagament",
      filters: filtreTipusPagament,
      filteredValue: filteredInfo.tipus_pagament || null,
      onFilter: (value, record) => record.tipus_pagament === value,
      sorter: (a, b) => {return entities.decode(a.tipus_pagament).localeCompare(entities.decode(b.tipus_pagament))},
      sortOrder: sortedInfo.columnKey === 'tipus_pagament' && sortedInfo.order,
      render: (text,record) => (<span>{entities.decode(record.tipus_pagament)}</span>)
    },
    { title: 'Total', dataIndex: "total", key: "total",
      sorter: (a, b) =>  a.total - b.total,
      sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
      render: (text,record) =><span>{record.total}€</span>
    },{
      title: (
        <Tooltip placement="top" title="Clear filters">
          <Button icon={<ClearOutlined />} onClick={(e) => clearAllFilters()} ghost="true" className="BotoTaula"/>
        </Tooltip>
      ),
      key: "actions",
      align: "right",
      width: "10px",
    }
  ];
  const columnesTaulaDetall = [
      /*{
        title: props.t('facturacio.origen'),
        dataIndex: "taula",
        key: "taula",
        render: (text,record) => {
          let taula = record.taula ? props.t(`facturacio.taula.${record.taula}`) : 'Manual';
          return <span>{taula}</span>
        }

      },*/
      {
        title: props.t('generic.tipus_ingres'),
        dataIndex: "tipus",
        key: "tipus",
        render: (text,record) => {
          let taula = record.tipus ? props.t(`rebuts.tipus.${record.tipus}`) : '';
          return <span>{taula}</span>
        }

      },
      { title: props.t('generic.concepte'), dataIndex: "concepte", key: "concepte",
        render: (text,record) =><span>{entities.decode(record.concepte)}</span>
      },
      { title: props.t('generic.preu'), dataIndex: "preu", key: "preu",
        render: (text,record) =><span>{parseFloat(record.preu).toFixed(2)}€</span>
      },
      { title: props.t('generic.unitats'), dataIndex: "unitats", key: "unitats",
        render: (text,record) =><span>{record.unitats}</span>
      },
      { title: props.t('generic.descompte'), dataIndex: "descompte", key: "descompte",
        render: (text,record) =><span>{parseFloat(record.descompte).toFixed(2)}%</span>
      },
      { title: props.t('generic.subtotal'), key: "subtotal",
        render: (text,record) =><span>{record.subtotal}€</span>
      },{
        key: "actions",
        align: "right",
        width: "95px",
        render: (text,record) => {
          if(props.item.facturat){
            return null;
          }
          /* Finalment deixo que ho puguin modificar i eliminar 03/05/2021
          if(record.manual==false){
            return null;
          }*/

          return (
          <Row type="flex" justify="end" id={"actions_"+record.id}>
            <Col span={12}>
              <Tooltip placement="top" title={props.t('generic.modify')}>
                <Button icon={<EditOutlined />} onClick={(e) => editarConcepte(record)} ghost="true" className="BotoTaula"/>
              </Tooltip>
            </Col>
            <Col span={12}>
              <Tooltip placement="top" title={props.t('generic.delete')}>
                <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>eliminarConcepte(record.id)}>
                  <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
                </Popconfirm>
              </Tooltip>
            </Col>
          </Row>
        );
      }
    }
    ];

  const subTaulaDetall = row => {
    return (
      <div>
      {props.item.facturat ? null :
        <Row justify="end">
          <Col>
            <Button type="primary" onClick={() => afegirConcepte(row)}>{props.t('generic.add')} {props.t('generic.concepte')}</Button>
          </Col>
        </Row>
      }
      <Row style={{margin: '1rem'}}>
        <Col span={22}>
          <Table rowKey="id_linia" columns={columnesTaulaDetall} dataSource={row.detall} pagination={false} size={'small'} bordered={false} />
          </Col>
      </Row>
      </div>
    );
  };


  return(
    <>

    <Row type="flex" justify="space-between" style={{marginTop:'1rem', marginLeft:0, paddingLeft:0}}>
      <Col>
        <Row >
          <Col>
            <Tooltip placement="top" title={props.t('generic.back')}>
              <LeftOutlined className="BotoBack" onClick={props.callback}/>
            </Tooltip>
          </Col>
          <Col>
            <TitolPagina txt={props.item.descripcio_t} level={4} style={{marginTop:'-0.2rem', marginLeft:0, paddingTop: 0, addingLeft:'0.5rem'}}/>
          </Col>
        </Row>
      </Col>
      <Col>
        <FacturacioExportaExcel dades={linies} nom_fulla={props.item.descripcio_t} nom_fitxer={'Pressupostos_' + props.t(`mesos.${props.item.mes_a_calcular}`) +'_'+ props.item.curs_escolar}/>
      </Col>
      <Col>
        <Popconfirm
        title={props.t('facturacio.facturar_pressupost_pregunta')}
        placement="left"
        visible={confirmVisible}
        onConfirm={()=>generarRebuts()}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={()=>setConfirmVisible(false)}
        okText={props.t('generic.si')}
        cancelText={props.t('generic.no')}>
          <Button type="primary" onClick={()=>setConfirmVisible(true)} disabled={props.item.facturat}>{props.t('facturacio.facturar_pressupost')}</Button>
        </Popconfirm>
      </Col>

      {/*<Col>
          <RebutsBancExportaExcel dades={dadesRebutsBanc} btn_txt="Rebuts BANC" nom_fulla="Rebuts" nom_fitxer={'Rebuts_BANC_' + props.t(`mesos.${props.item.mes_a_calcular}`) +'_'+ props.item.curs_escolar}/>
          <RebutsAltresExportaExcel dades={dadesRebutsAltres} btn_txt="Rebuts ALTRES" nom_fulla="Rebuts" nom_fitxer={'Rebuts_ALTRES_' + props.t(`mesos.${props.item.mes_a_calcular}`) +'_'+ props.item.curs_escolar}/>
      </Col>*/}
    </Row>


      <Table
        locale={{filterConfirm: 'OK', filterReset: 'Reset'}}
        rowKey="id_alumne"
        pagination={false}
        loading={isLoading}
        dataSource={linies}
        expandedRowRender={subTaulaDetall}
        defaultExpandAllRows={false}
        columns={columns}
        onChange={tableHandleChange}
        size="small"
      />
      <Modal
          key="concepte"
          title={`${props.t('generic.add')} ${props.t('generic.concepte')}`}
          visible={modalVisible}
          footer={null}
          onCancel={tancarModal}
          width={600}
          style={{top:20}}
          destroyOnClose={true}
          forceRender={true}
        >
          <FacturacioConcepteEdit item={itemActual} callback={tancarModal}/>
      </Modal>
      <Modal
          key="generarRebuts"
          title={props.t('facturacio.facturar_pressupost')}
          visible={modalVisibleGenerarRebuts}
          footer={null}
          onCancel={tancarModalGenerarRebuts}
          width={600}
          style={{top:20}}
          destroyOnClose={true}
          forceRender={true}
        >
          <FacturacioToRebuts id_curs_escolar={props.item.id_curs_escolar} id_pressupost={props.item.id} callback={tancarModalGenerarRebuts}/>
      </Modal>
    </>
  );
}
export default translate(FacturacioEdit);
