import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';
import { Api } from '../../../../helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function RebutsExportaExcel(props){

  const [dades, setDades] = useState(props.dades);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Rebuts';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'Rebuts';

  console.log('dins excel: ', dades)

/*
  useEffect(() => {
    generarDades();
  },[props.id_pressupost]);


  const generarDades = async () =>{
    const rebuts = await Api.myFetch('/pressupost/genera/rebuts/'+props.id_pressupost, 'POST');


    if(rebuts.result.success===1){
      setDades(rebuts.data.rebuts);
    }
  }
*//*
  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={props.dades} name={nom_fulla}>
            <ExcelColumn label="Referencia" value={(col) => col.id_pressupost.toString()+'-'+col.id.toString()}/>
            <ExcelColumn label="id_alumne" value="id_alumne"/>
            <ExcelColumn label="nom" value={(col) => entities.decode(col.nom)}/>
            <ExcelColumn label="cognom1" value={(col) => entities.decode(col.cognom1)}/>
            <ExcelColumn label="iban" value="iban"/>
            <ExcelColumn label="titular" value={(col) => entities.decode(col.titular)}/>
            <ExcelColumn label="import" value="import"/>
            <ExcelColumn label="concepte" value={(col) => entities.decode(col.nom) +': '+entities.decode(col.concepte)}/>
          </ExcelSheet>
      </ExcelFile>
  );
  */
  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="Nombre Deudor" value={(col) => entities.decode(col.titular)}/>
            <ExcelColumn label="Cuenta Deudor" value="iban"/>
            <ExcelColumn label="Ref. Mandato" value="mandat"/>
            <ExcelColumn label="Fecha Mandato" value={(col) => moment(col.mandat_data).format('DD/MM/YYYY')}/>
            <ExcelColumn label="Secuencia Adeudo" value={(col) => ''}/>
            <ExcelColumn label="Referencia Adeudo" value={(col) => col.id_pressupost.toString()+'-'+col.id.toString()}/>
            <ExcelColumn label="Importe" value={(col) => {
                /* afegir un format per a que els decimals siguin amb , i no amb . */
                //let imp = parseFloat(col.import).toFixed(2);
                //return imp.replace('.',',');
                //return col.import.toLocaleString('es-ES', {minimumFractionDigits: 2});
                return parseFloat(col.import);
              }}/>
            <ExcelColumn label="Concepto" value={(col) => entities.decode(col.nom) +': '+entities.decode(col.concepte)}/>
            <ExcelColumn label=" " value={(col) => ' '}/>
            <ExcelColumn label="id_alumne" value={(col) => parseInt(col.id_alumne)}/>
            <ExcelColumn label="nom" value={(col) => entities.decode(col.nom)}/>
            <ExcelColumn label="cognom1" value={(col) => entities.decode(col.cognom1)}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(RebutsExportaExcel);
