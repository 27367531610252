import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import {Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../../context/menu-triat";
import { Api, InitDades, HtmlStrings } from '../../../../helpers';
import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
import ImatgeDNI from '../../../ImatgeDNI';
import VisorImatges from "../../../visorImatges";

const entities = new AllHtmlEntities();


function Edit(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

  const buit = InitDades.pare();

  //const [id, setId] = useState(perfilUsuari.account.id);
  const [users_id, setUsers_id] = useState(perfilUsuari.account.id);


  const [id, setId] = useState(props.item.id);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [nom, setNom] = useState(entities.decode(props.item.nom));
  const [cognom1, setCognom1] = useState(entities.decode(props.item.cognom1));
  const [cognom2, setCognom2] = useState(entities.decode(props.item.cognom2));
  const [tel1, setTel1] = useState(props.item.tel1);
  const [dni, setDni] = useState(props.item.dni);
  const [dni_image_1, setDni_image_1] = useState();
  const [dni_image_2, setDni_image_2] = useState();
  const [passaport, setPassaport] = useState(props.item.passaport);
  const [passaport_image_1, setPassaport_image_1] = useState(props.item.passaport_image_1);
  const [passaport_image_2, setPassaport_image_2] = useState(props.item.passaport_image_2);


/* Auxiliars */
  const [dni_image_1_dataUrl, setDni_image_1_dataUrl ] = useState(undefined);
  const [dni_image_2_dataUrl, setDni_image_2_dataUrl ] = useState(undefined);
  const [passaport_image_1_dataUrl, setPassaport_image_1_dataUrl ] = useState(undefined);
  const [passaport_image_2_dataUrl, setPassaport_image_2_dataUrl ] = useState(undefined);

  const [visor, setVisor] = useState({visible: false});

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nou, setNou] = useState(props.item.id===0);

  const buscarDades = async () => {
    setIsLoading(true);

    const dni_pare = await Api.get('/autoritzats/dni/'+id);
    if(dni_pare.result.success===1){
      setDni_image_1(dni_pare.data.dni_image_1);
      setDni_image_2(dni_pare.data.dni_image_2);
      setPassaport_image_1(dni_pare.data.passaport_image_1);
      setPassaport_image_2(dni_pare.data.passaport_image_2);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);


    const submitForm = async (valors) => {

      setGuardant(true);

      let dades = {
        id: users_id,
        id_familia,
        nom: entities.encode(nom),
        cognom1: entities.encode(cognom1),
        cognom2: entities.encode(cognom2),
        tel1,
        dni,
        passaport
      }


      console.log(JSON.stringify(dades));
      try{
        let method = nou?'POST':'PUT';
        let url_api = nou? '/autoritzats' : '/autoritzats/'+id;

        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){

          if(nou){
            setNou(false);
            setId(resposta.data.id);
          }

          if(dni_image_1_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/autoritzats/dni/'+resposta.data.id+'/1', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_1(resp.data.dni_image_1);
              }else{
                console.error('Error POST file: autoritzats dni_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(dni_image_2_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/autoritzats/dni/'+resposta.data.id+'/2', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_2(resp.data.dni_image_2);
              }else{
                console.error('Error POST file: autoritzats dni_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_1_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/autoritzats/dni/'+resposta.data.id+'/3', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_1(resp.data.passaport_image_1);
              }else{
                console.error('Error POST file: autoritzats passaport_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_2_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/autoritzats/dni/'+resposta.data.id+'/4', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_2(resp.data.passaport_image_2);
              }else{
                console.error('Error POST file: autoritzats passaport_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }


          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormAutoritzats: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Autoritzats guardar: ', error);
      }

  }


  let posicio_botons = "start";
  if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <>
        {props.popup!==undefined ? null :
          <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
            <LeftOutlined className="BotoBack" onClick={props.callback}/>
          </div>
        }
        <div style={{padding:'10px 24px 10px 0px', height:'650px'}}>
          <Form onFinish={submitForm}
            layout="vertical"
            colon={true}
            autoComplete="off"
          >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} lg={12} xl={8}>
            <Form.Item label={props.t('pares.nom')}>
              <Input name="nom" placeholder={props.t('pares.nom')} onChange={(e) => setNom(e.target.value)} value={nom}/>
            </Form.Item>
            <Form.Item label={props.t('pares.cognom1')}>
              <Input name="cognom1" placeholder={props.t('pares.cognom1')} onChange={(e) => setCognom1(e.target.value)} value={cognom1}/>
            </Form.Item>
            <Form.Item  label={props.t('pares.cognom2')}>
              <Input name="cognom2" placeholder={props.t('pares.cognom2')} onChange={(e) => setCognom2(e.target.value)} value={cognom2}/>
            </Form.Item>
            <Form.Item label={props.t('pares.tel1')}>
              <Input name="tel1" placeholder={props.t('pares.tel1')} onChange={(e) => setTel1(e.target.value)} value={tel1}/>
            </Form.Item>

          </Col>

          <Col xs={24} lg={12} xl={16}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Form.Item
                  label={props.t('pares.dni')}
                  labelCol={{span:2}}
                  wrapperCol={{span:10}}
                >
                  <Input name="dni" placeholder={props.t('pares.dni')} onChange={(e) => setDni(e.target.value)} value={dni}/>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                  <ImatgeDNI
                    image={dni_image_1}
                    title={props.t('pares.dni_image_1')}
                    dataUrl={dni_image_1_dataUrl}
                    setDataUrl={setDni_image_1_dataUrl}
                    setVisor={setVisor}
                  />
              </Col>
              <Col  xs={24} xl={12}>
                  <ImatgeDNI
                    image={dni_image_2}
                    title={props.t('pares.dni_image_2')}
                    dataUrl={dni_image_2_dataUrl}
                    setDataUrl={setDni_image_2_dataUrl}
                    setVisor={setVisor}
                  />
              </Col>
              <Col span={24} style={{paddingTop: '3rem'}}>
                  <Form.Item
                    label={props.t('pares.passaport')}
                    wrapperCol={{span:12}}
                  >
                    <Input name="passaport" placeholder={props.t('pares.passaport')} onChange={(e) => setPassaport(e.target.value)} value={passaport}/>
                  </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={passaport_image_1}
                  title={props.t('pares.passaport_image_1')}
                  dataUrl={passaport_image_1_dataUrl}
                  setDataUrl={setPassaport_image_1_dataUrl}
                  setVisor={setVisor}
                />
              </Col>
              <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={passaport_image_2}
                  title={props.t('pares.passaport_image_2')}
                  dataUrl={passaport_image_2_dataUrl}
                  setDataUrl={setPassaport_image_2_dataUrl}
                  setVisor={setVisor}
                />
              </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
                <Col>
                  <Button onClick={(e) => setMenuTriat("Dashboard")}>{props.t('generic.cancel')}</Button>
                </Col>
                <Col style={{marginLeft:'10px'}}>
                  <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
                </Col>
              </Row>
            </Col>
    </Row>
          </Form>
          { !visor.visible
            ? null
            : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
          }
        </div>
      </>
  );
}
export default translate(Edit);
