import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, Checkbox, Space, Select, Switch, Form, DatePicker, message } from 'antd';
import {SyncOutlined, MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

const radioStyle = {
      width:'100%',
      marginTop: '8px',
    };

function AcollidaMensual(props){

  const id_curs_escolar = props.id_curs_escolar;
  const [acollides, setAcollides] = useState([]);
  const [triat, setTriat] = useState([]);
  const [enviar_email, setEnviar_email] = useState(false);

  const [id_pare, setId_pare] = useState(0);
  const [id_alumne, setId_alumne] = useState(0);
  const [ids_fills, setIds_fills] = useState([]);
  const [id_familia, setId_familia] = useState(0);
  const [familiesOptions, setFamiliesOptions] = useState([]);
  const [paresOptions, setParesOptions] = useState([]);
  const [alumnesOptions, setAlumnesOptions] = useState([]);

  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [comprat, setComprat] = useState(false);

  const [error, setError] = useState('');
  const [errorId_Familia, setErrorId_Familia] = useState('');
  const [errorId_FamiliaText, setErrorId_FamiliaText] = useState('');
  const [errorId_Pare, setErrorId_Pare] = useState('');
  const [errorId_PareText, setErrorId_PareText] = useState('');
  const [errorId_Alumne, setErrorId_Alumne] = useState('');
  const [errorId_AlumneText, setErrorId_AlumneText] = useState('');

  const [total, setTotal] = useState(0);
  const [numFills, setNumFills] = useState(1);


  useEffect(() => {
    buscarDades();
  },[id_alumne, id_familia]);

  useEffect(() => {
    calcularTotal();
  },[triat, numFills]);

  useEffect(() => {
    buscarDadesFamilia();
  },[]);

  useEffect(() => {
    setId_pare(0);
    buscarDadesPares();
    setId_alumne(0);
    buscarDadesFills();
  },[id_familia]);


  const buscarDades = async () => {
    setIsLoading(true);
    if(id_alumne>0 && id_familia>0){

    const resp = await Api.get(`/acollida/tipus/mensual/familia/${id_familia}`);
    if(resp.result.success===1){
      await setTriat([]);
      await setComprat(false);

      const as = resp.data.acollides.map((acollida) =>{

        let id_propietat_comprada = 0;
        if(acollida.comprat.length>0){
          const registre = acollida.comprat.find((element, index) => element.id_alumne === id_alumne);
          if(registre){
            id_propietat_comprada = registre.id_propietat;
            setComprat(true);
          }
        }

        acollida.propietats = acollida.propietats.map((item) =>{
          if(acollida.descompte>0){
            item.preu = (item.preu - (item.preu * acollida.descompte / 100));
          }
          if(item.id_propietat === id_propietat_comprada){
            let t = triat;
            t.push(item)
            setTriat(t)
          }
          return item;
        });
        return acollida;
      });
      setAcollides(as);
      calcularTotal();
    }

    }
    setIsLoading(false);
  }

const calcularTotal = () => {
//console.log('calcularTotal', triat)
  let subtotal = 0;
  if(triat.length>0){

    triat.forEach((item, i) => {
      //console.log('calcularTotal', item)
      subtotal = subtotal + (item.preu * numFills);
    });
  }
  setTotal(subtotal.toFixed(2));
}


const buscarDadesFamilia = async () => {
  const fam = await Api.get('/families');
  if(fam.result.success===1){
    const llista_fam = fam.data.families.map((item) =>{
          return(
            <Select.Option key={item.id} value={item.id}>
              {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
            </Select.Option>
          );
    });
    setFamiliesOptions(llista_fam);
  }
}
const buscarDadesPares = async () => {
  if(id_familia > 0){
    const pares = await Api.get(`/pares/familia/${id_familia}`);
    if(pares.result.success===1){
      const llista_pares = pares.data.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setParesOptions(llista_pares);
    }
  }
}
const buscarDadesFills = async () => {
  if(id_familia > 0){
    const fills = await Api.get(`/alumnes/familia/${id_familia}`);
    if(fills.result.success===1){
      let array_id_fills = [];
      const llista_fills =fills.data.alumnes.map((item) =>{
            array_id_fills.push(item.id);
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setAlumnesOptions(llista_fills);
      setIds_fills(array_id_fills);
    }
  }
}

  const submitForm = async (valors) => {

    if(triat.length<=0){
      message.config({top: 50});
      message.error({content: 'Error: '+ props.t('generic.tria'), duration:3});
      return false;
    }

    let correcte = true;

    if(id_familia===0){
      setErrorId_Familia('error');
      setErrorId_FamiliaText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_pare===0){
      setErrorId_Pare('error');
      setErrorId_PareText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_alumne===0 && numFills===0){
      setErrorId_Alumne('error');
      setErrorId_AlumneText(props.t('generic.tria'));
      correcte = false;
    }

    if(!correcte){
      return false;
    }

  setGuardant(true);

  const dades = {
    id_curs_escolar,
    id_alumnes: numFills === 1 ? [id_alumne] : ids_fills,
    id_pare,
    tipus: 'mensual',
    ids_propietats: triat.map((item) => item.id_propietat),
    enviar_email
  }


    try{
      const resposta = await Api.myFetch('/acollida/comanda/admin', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Acollida: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Acollida guardar: ', error);
    }

  }

  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_Familia('');
      setErrorId_FamiliaText('');
    }
  }
  const triaPare = (value) =>{
    setId_pare(value);
    if(value>0){
      setErrorId_Pare('');
      setErrorId_PareText('');
    }
  }
  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setNumFills(numFills===0 ? 1 : numFills);
      setErrorId_Alumne('');
      setErrorId_AlumneText('');
    }
  }

  const onChangeProps = (item) => {

    const filtrat = triat.filter((element, index, arr) => element.id_acollida !== item.id_acollida);
    const triats = [...filtrat, item];
    setTriat(triats);

  }

  const onChangeTotsFills = (tots) =>{
    setNumFills(tots ? ids_fills.length : 1);
  }


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {


  return(
    <>
    <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_Familia} help={errorId_FamiliaText} style={{marginBottom: 0}}>
      <Select defaultValue={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {familiesOptions}
      </Select>
    </Form.Item>

    {id_familia <=0 ? null :
    <>
      <Form.Item label={props.t('generic.responsable')} required={true} validateStatus={errorId_Pare} help={errorId_PareText} style={{marginBottom: 0}}>
        <Select value={id_pare===0 ? '' : id_pare} onChange={(value) => triaPare(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {paresOptions}
        </Select>
      </Form.Item>
      <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_Alumne} help={errorId_AlumneText} style={{marginBottom: 0}}>
        <Select value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {alumnesOptions}
        </Select>
      </Form.Item>
    </>
    }

    { acollides.map((acollida) =>{

        return(
          <>
          <Row justify="center" style={{marginTop:'10px'}}>
            <Typography.Title level={4}>{entities.decode(acollida.nom[props.t('idioma')])}</Typography.Title>
          </Row>

          <Row justify="left">
            <Form.Item required={true} validateStatus={error} style={{marginTop: 0, paddingRight: '20px', width: '100%'}}>
              <Radio.Group style={{margin: '10px', width: '100%'}} onChange={(e) => onChangeProps(e.target.value)} optionType="button" buttonStyle="solid" value={triat.find((element, index) => element.id_acollida === acollida.id)}>
                {
                  acollida.propietats.map((item) =>{

                    const text = entities.decode(item.text[props.t('idioma')]);

                    return(
                        <Radio.Button style={radioStyle} value={item} key={item.id_propietat}>
                          <table width="100%">
                            <tr>
                              <td style={{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {text}
                              </td>
                              <td width="30" align="right">
                                {item.preu.toFixed(2)}€
                              </td>
                            </tr>
                          </table>
                        </Radio.Button>
                    )
                  })
                }
              </Radio.Group>
            </Form.Item>
          </Row>
        </>
      )
    })
  }



{acollides.length===0 ? null :
<>
    <Row justify="center" style={{marginTop:'10px'}}>
      <Space direction="vertical" align="center">
        <Space><b style={{fontSize: '18px'}}>Total:</b><span style={{color: 'red', fontSize: '18px'}}>{total}€</span></Space>
      </Space>
    </Row>


    {ids_fills.length <= 1 || comprat ? null :
      <Row justify="center" style={{marginTop:'10px'}}>
        <Checkbox onChange={(e) => onChangeTotsFills(e.target.checked)}>{props.t('transport.tots_fills')}</Checkbox>
      </Row>
    }
    {!comprat
      ?
        <>
          <Row>
            <Space>
              <span>{props.t('generic.enviarEmail')}</span>
              <Switch onChange={setEnviar_email} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
            </Space>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() => submitForm()} loading={guardant}>
              {props.t('generic.save')}
            </Button>
          </Row>
        </>
      :
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <span style={{color: 'red'}}>*{props.t('generic.serveiJaContractatADMIN')}</span>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() =>props.tancarModal()}>
              {props.t('generic.back')}
            </Button>
          </Row>
        </>
      }
</>
  }
    </>
  );
}
}
export default translate(AcollidaMensual);
