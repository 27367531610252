import React, {useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm, Tabs, Modal } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditableModalNouEditComandes from '../../../components/LlistaEditableModalNouEditComandes';
import Edit from '../../../components/Formularis/perfils/admin/ComandesMenjadorEdit';
import Add from '../../../components/Formularis/perfils/admin/ComandesMenjadorAdd';
import { Api, InitDades } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();
moment.locale('es');

function ComandesMenjador(props){
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  return (<ComandesMenjadorClass t={props.t} id_curs_escolar={perfilUsuari.curs_escolar_actual.id}/>);
}

class ComandesMenjadorClass extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      filtresClasses: [],
      searchText: '',
      searchedColumn: '',
      modalVisible: false,
      tipus: 'anual',
      esPuntual: false,
      refrescar_anual: 0,
      refrescar_puntual: 0,
      cursEscolarTriat: props.id_curs_escolar,
    };
  }

  async componentDidMount(){
    this.creaFiltreClasses(this.state.cursEscolarTriat);
  }

  creaFiltreClasses = async (curs) => {
    const cla = await Api.get(`/classes/all/${curs}`);
    if(cla.result.success!==1){return [];}

    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    this.setState({filtresClasses: filtreCl});
  }

  buscarDades_anual = async () => {
    const c = await Api.get(`/menjador/comandes/all/${this.state.cursEscolarTriat}/mensual`);
    if(c.result.success===1){
      const com = c.data.comandes.map((item)=>{
        item.alumne_nom_t = entities.decode(item.alumne_nom);
        item.pare_nom_t = entities.decode(item.pare_nom);
        item.menjador_t = entities.decode(item.menjador[this.props.t('idioma')]);
        return item;
      });
      return com;
    }
  }
  buscarDades_puntual = async () => {
    const c = await Api.get(`/menjador/comandes/all/${this.state.cursEscolarTriat}/puntual`);
    if(c.result.success===1){
      const com = c.data.comandes.map((item)=>{
        item.alumne_nom_t = entities.decode(item.alumne_nom);
        item.pare_nom_t = entities.decode(item.pare_nom);
        item.menjador_t = entities.decode(item.menjador[this.props.t('idioma')]);
        return item;
      });
      return com;
    }
  }

  eliminar = async (id) => {
    const del = await Api.myFetch(`/menjador/comanda/${id}`, 'DELETE');
    if(del.result.success===1){

    }
  }

  triaCursChange = (curs) => {
    this.creaFiltreClasses(curs);
    const refrescarPuntual = this.state.refrescar_puntual+1;
    const refrescarAnual = this.state.refrescar_anual+1;
    this.setState({ cursEscolarTriat: curs,
                    refrescar_puntual: refrescarPuntual,
                    refrescar_anual: refrescarAnual});
  }

  getColumnSearchProps = (dataIndex, nom) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${this.props.t('generic.cercar')} ${nom}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('generic.cercar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      //record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      record[dataIndex] ? this.arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(this.arreglarTxt4Search(value)) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text => (entities.decode(text)),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

  triaTab = (key) =>{
    let esPuntual = false;
    //setRefrescar(refrescar+1);
    if(key==='puntual'){
      esPuntual = true;
    }
    this.setState({ tipus: key, esPuntual });
  }
  tancarModal_anual = e =>{
    //setRefrescar(refrescar+1);
    const refrescar = this.state.refrescar_anual+1;
    this.setState({ modalVisible: false, refrescar_anual: refrescar });
  }
  tancarModal_puntual = e =>{
    //setRefrescar(refrescar+1);
    const refrescar = this.state.refrescar_puntual+1;
    this.setState({ modalVisible: false, refrescar_puntual: refrescar });
  }
  showModal = e =>{
    //setRefrescar(refrescar+1);
    this.setState({ modalVisible: true });
  }

render(){

const { filtresClasses, modalVisible, tipus, esPuntual, refrescar_anual, refrescar_puntual, cursEscolarTriat } = this.state;
const btnAfegir =
  <Space>
    <TriaCursEscolar onChange={(c) => this.triaCursChange(c)}/>
    <Button type="primary" onClick={this.showModal}>
    {
      esPuntual
      ? this.props.t('generic.add')+ ' '+ this.props.t('generic.puntual')
      : this.props.t('generic.add')+ ' '+ this.props.t('generic.anual')
    }
    </Button>
  </Space>;

const columns_anual = [
{
  title:"Id",
  key:"id",
  sorter: (a, b) =>  a.id - b.id,
  render: (text,record) => (<span>{record.id}</span>)
},{
  title: this.props.t('alumnes.classe'),
  key:"classe",
  dataIndex: 'classe',
  filters: filtresClasses,
  onFilter: (value, record) => record.id_classe === value,
  render: (text,record) =>(entities.decode(record.classe)),
  sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
},{
  title: this.props.t('alumnes.nom'),
  key:"alumne_nom_t",
  dataIndex: 'alumne_nom_t',
  sorter: (a, b) => { return a.alumne_nom_t.localeCompare(b.alumne_nom_t)},
  ...this.getColumnSearchProps('alumne_nom_t',this.props.t('alumnes.nom'))
},{
  title:this.props.t('menu.Menjador'),
  key:"menjador_t",
  dataIndex: 'menjador_t',
  sorter: (a, b) => { return a.menjador_t.localeCompare(b.menjador_t)},
  ...this.getColumnSearchProps('menjador_t',this.props.t('menu.Menjador'))
},{
  title:this.props.t('generic.total'),
  key:"total",
  sorter: (a, b) =>  a.total - b.total,
  render:(text,record) => (<span>{record.total}€</span>)
},{
  title:this.props.t('generic.created_at'),
  key:"creat",
  sorter: (a, b) => moment(a.creat).diff(moment(b.creat)),
  responsive:['md'],
  render:(text,record) => (record.creat ? <Moment date={moment(record.creat)} format="DD/MM/YYYY HH:mm"/> : "" )
}];

const columns_puntual = [
{
  title:"Id",
  key:"id",
  sorter: (a, b) =>  a.id - b.id,
  render: (text,record) => (<span>{record.id}</span>)
},{
  title: this.props.t('alumnes.classe'),
  key:"classe",
  dataIndex: 'classe',
  filters: filtresClasses,
  onFilter: (value, record) => record.id_classe === value,
  render: (text,record) =>(entities.decode(record.classe)),
  sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
},{
  title: this.props.t('alumnes.nom'),
  key:"alumne_nom_t",
  dataIndex: 'alumne_nom_t',
  sorter: (a, b) => { return a.alumne_nom_t.localeCompare(b.alumne_nom_t)},
  ...this.getColumnSearchProps('alumne_nom_t',this.props.t('alumnes.nom'))
},{
  title:this.props.t('menu.Menjador'),
  key:"menjador_t",
  dataIndex: 'menjador_t',
  sorter: (a, b) => { return a.menjador_t.localeCompare(b.menjador_t)},
  ...this.getColumnSearchProps('menjador_t',this.props.t('menu.Menjador'))
},{
  title:this.props.t('generic.total'),
  key:"total",
  sorter: (a, b) =>  a.total - b.total,
  render:(text,record) => (<span>{record.total}€</span>)
},{
  title:this.props.t('generic.data'),
  key:"puntual_data",
  sorter: (a, b) => moment(a.puntual_data).diff(moment(b.puntual_data)),
  responsive:['md'],
  render:(text,record) => (record.puntual_data ? <Moment date={new Date(record.puntual_data)} format="DD/MM/YYYY"/> : "" )
}];


  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={this.props.t('menu.Comandes')+' '+this.props.t('menu.Menjador')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      <Tabs defaultActiveKey="anual" tabBarExtraContent={btnAfegir} onChange={this.triaTab}>
        <Tabs.TabPane tab={this.props.t('generic.anual')} key="anual">
          <LlistaEditableModalNouEditComandes
            scroll={{ x: 1500}}
            txt_creats={this.props.t('menu.Comandes')}
            txt_nou={this.props.t('generic.add')}
            buscarDades={this.buscarDades_anual}
            edit={Edit}
            id_curs_escolar={cursEscolarTriat}
            add={null}
            delete={this.eliminar}
            itemBuit={InitDades.comandesExtraescolars()}
            columns={columns_anual}
            refrescar={refrescar_anual}
            />
        </Tabs.TabPane>
        <Tabs.TabPane tab={this.props.t('generic.puntual')} key="puntual">
          <LlistaEditableModalNouEditComandes
            scroll={{ x: 1500}}
            txt_creats={this.props.t('menu.Comandes')}
            txt_nou={this.props.t('generic.add')}
            buscarDades={this.buscarDades_puntual}
            edit={Edit}
            id_curs_escolar={cursEscolarTriat}
            add={null}
            delete={this.eliminar}
            itemBuit={InitDades.comandesExtraescolars()}
            columns={columns_puntual}
            refrescar={refrescar_puntual}
            />
        </Tabs.TabPane>
      </Tabs>
      </div>
      <Modal
          key="afegirComanda"
          title={
            esPuntual
            ? this.props.t('generic.add')+ ' '+ this.props.t('generic.puntual')
            : this.props.t('generic.add')+ ' '+ this.props.t('generic.anual')
          }
          visible={modalVisible}
          footer={null}
          onCancel={(e) => this.setState({modalVisible: false})}
          width={600}
          style={{top:10}}
          bodyStyle={{ overflow: "auto"}}
          destroyOnClose={true}
          forceRender={true}
          maskClosable={true}
          closable={true}
        >
        <Add id_curs_escolar={cursEscolarTriat} puntual={esPuntual} tancarModal={esPuntual ? this.tancarModal_puntual : this.tancarModal_anual}/>
      </Modal>

    </ContainerPagina>
  );
}
}

export default translate(ComandesMenjador);
