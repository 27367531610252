import React from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../components/ContainerPagina';
import TitolPagina from '../components/TitolPagina';
import LlistaEditable from '../components/LlistaEditable';
import Edit from '../components/Formularis/Cicles';
import { Api, InitDades } from '../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Cicles(props) {


  const buscarDades = async () => {
    const ci = await Api.get('/cicles');
    if(ci.result.success!==1){return [];}

    const et = await Api.get('/etapes');
    if(et.result.success!==1){return [];}

    let llista_et = [];
    llista_et[0] = "";
    et.data.etapes.forEach((item) =>{
        llista_et[item.id] = item.nom;
    });
    const llista_etapes = ci.data.cicles.map((item) =>{
      item.etapa = llista_et[item.id_etapa];
      return item;
    });

    return llista_etapes;

  }

  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/cicles/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  return(
    <ContainerPagina>
      <TitolPagina txt={props.t('menu.Cicles')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          txt_creats={props.t('cicles.creados')}
          txt_nou={props.t('cicles.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.cicle()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />

          <Column
          title={props.t('cicles.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom)
          )}
          />
          <Column
          title={props.t('cicles.etapa')}
          key="etapa"
          render={(text,record) =>(
            record.etapa
          )}
          />


        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Cicles);
