import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Space, Switch, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function ComandesTransportEdit(props){

  const [id, setId] = useState(props.item.id);
  const [enviar_email, setEnviar_email] = useState(false);
  const [id_transport, setId_transport] = useState(props.item.id_transport);
  const [id_parada, setId_parada] = useState(props.item.id_parada);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [tipus, setTipus] = useState(props.item.tipus);
  const [puntual_data, setPuntual_data ] = useState(props.item.puntual_data);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);
  const [descompte, setDescompte] = useState(props.item.descompte_x_anada_i_tornada);
  const [total, setTotal] = useState(props.item.total);
  const [fcrea, setFcrea ] = useState(props.item.creat);



  const [guardant, setGuardant] = useState(false);

  const [transportOptions, setTransportOptions] = useState([]);
  const [paradesOptions, setParadesOptions] = useState([]);

  const [errorrFcrea, setErrorFcrea] = useState('');
  const [errorId_transport, setErrorId_transport] = useState('');
  const [errorId_transportText, setErrorId_transportText] = useState('');
  const [errorId_parada, setErrorId_parada] = useState('');
  const [errorId_paradaText, setErrorId_paradaText] = useState('');

  useEffect(() => {
    buscarDadesTransport();
  },[]);

  useEffect(() => {
    buscarDadesParades();
  },[id_transport]);

  const buscarDadesTransport = async () => {
    const tra = await Api.get('/transport');
    if(tra.result.success===1){
      const llista_tra = tra.data.transport.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom[props.t('idioma')])}
              </Select.Option>
            );
      });
      setTransportOptions(llista_tra);
    }
  }
  const buscarDadesParades = async () => {
    if(id_transport > 0){
      const par = await Api.get(`/transport/parades/${id_transport}`);
      if(par.result.success===1){
        const llista_par = par.data.parades.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {item.hora + ':' + item.minut + ' - ' + entities.decode(item.nom[props.t('idioma')])}
                </Select.Option>
              );
        });
        setParadesOptions(llista_par);
      }
    }
  }


  const triaTransport = (value) =>{
    setId_transport(value);
    setId_parada(0);
    if(value>0){
      setErrorId_transport('');
      setErrorId_transportText('');
    }
  }
  const triaParada = (value) =>{
    setId_parada(value);
    if(value>0){
      setErrorId_parada('');
      setErrorId_paradaText('');
    }
  }

  const calcularTotal = (sub, desc) =>{
    if(sub !== 0.0){
      if(desc!==0.0){
        const tot =  sub - (sub * desc / 100);
        setTotal(tot);
      }else{
        setTotal(sub);
      }
    }else{
      setTotal(0);
    }
  }

  const onChangeSubtotal = (sub) =>{
    setSubtotal(sub);
    calcularTotal(sub, descompte);
  }

  const onChangeDescompte = (desc) =>{
    setDescompte(desc);
    calcularTotal(subtotal, desc);
  }

  const changePuntual_data = (date, dateString) => {
    if(date){
      //Trec l'hora
      setPuntual_data(date.format(moment.HTML5_FMT.DATE));
    }else{
      setPuntual_data(null);
    }
  }
  const changeFcrea = (date, dateString) => {
    if(date){
      setFcrea(date.toISOString());
    }else{
      setFcrea(null);
    }
  }


  const submitForm = async (values) => {

    let correcte = true;

    if(!fcrea){
      setErrorFcrea('error');
      correcte = false;
    }

    if(id_transport===0){
      setErrorId_transport('error');
      setErrorId_transportText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_parada===0){
      setErrorId_parada('error');
      setErrorId_paradaText(props.t('generic.tria'));
      correcte = false;
    }

    if(!correcte){
      return false;
    }

    setGuardant(true);
    let dades = {
      id,
      id_transport,
      id_parada,
      id_alumne,
      id_pare,
      tipus,
      puntual_data,
      subtotal,
      descompte_x_anada_i_tornada: descompte,
      total,
      fcrea,
      enviar_email
    }

    try{
      const resposta = await Api.myFetch(`/transport/comanda/${id}`, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: submit ComandesTransportEdit: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ComandesTransportEdit: ', error);
    }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return(
    <Form onFinish={submitForm}
      layout="horizontal"
      autoComplete="off"
    >
    <Form.Item label={props.t('generic.created_at')} required={true} validateStatus={errorrFcrea}>
      <DatePicker
        onChange={changeFcrea}
        defaultValue={fcrea != null ? moment(fcrea) : ''}
        format={'DD/MM/YYYY HH:mm'}
        locale={localeDatePicker}
        showTime
        />
    </Form.Item>
    <Form.Item label={props.t('generic.responsable')}>
      <Input name="pare_nom" defaultValue={entities.decode(props.item.pare_nom)} disabled/>
    </Form.Item>
    <Form.Item label={props.t('menu.Transport')} required={true} validateStatus={errorId_transport} help={errorId_transportText} style={{marginBottom: 0}}>
      <Select value={id_transport===0 ? '' : id_transport} onChange={(value) => triaTransport(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {transportOptions}
      </Select>
    </Form.Item>

    <Form.Item label={props.t('parades.titol')} required={true} validateStatus={errorId_parada} help={errorId_paradaText} style={{marginBottom: 0}}>
      <Select value={id_parada===0 ? '' : id_parada} onChange={(value) => triaParada(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {paradesOptions}
      </Select>
    </Form.Item>

    { tipus==='anual' ? null :
    <Form.Item label={props.t('generic.data')} required={true}>
      <DatePicker
        onChange={changePuntual_data}
        value={puntual_data ? moment(new Date(puntual_data), dateFormat) : ''}
        format={dateFormat}
        locale={localeDatePicker}
        picker="date"
        />
    </Form.Item>
  }

    <Form.Item label={props.t('generic.subtotal')}>
        <InputNumber name="subtotal" onChange={onChangeSubtotal} defaultValue={subtotal}
        step={1.0}
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.descompte')}>
        <InputNumber name="descompte" onChange={onChangeDescompte} defaultValue={descompte}
        min={-100} max={100} step={0.1}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.total')}>
        <InputNumber name="total" value={total} disabled
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Row>
      <Space>
        <span>{props.t('generic.enviarEmail')}</span>
        <Switch onChange={setEnviar_email} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
      </Space>
    </Row>
    <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
      <Col>
        <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
      </Col>
      <Col style={{marginLeft:'10px'}}>
        <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
      </Col>
    </Row>
    </Form>
  );

}
export default translate(ComandesTransportEdit);
