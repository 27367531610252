import React, { useContext } from 'react';
import { translate } from 'react-multi-lang';
import { LeftOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../context/menu-triat";
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/pares/Banc';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Banc(props) {

  const [perfilUsuari ] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);


  const buscarDades = async () => {
    const banc = await Api.get('/banc/responsable/'+perfilUsuari.account.id);
    if(banc.result.success===1){
      const llistaBanc = banc.data.bancs.map((item)=>{
        item.id = item.id_banc;
        return item;
      })
      return llistaBanc;
    }
  }


  return(
    <ContainerPagina xl={12}>
      <TitolPagina txt={props.t('menu.DadesFacturacio')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
          <LeftOutlined className="BotoBack" onClick={(e) => setMenuTriat("Dashboard")}/>
        </div>
        <LlistaEditable
          txt_creats={props.t('banc.creados')}
          txt_nou={props.t('banc.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={null}
          itemBuit={InitDades.banc()}>

          <Column
          title={props.t('banc.titular')}
          key="titular"
          render={(text,record) =>(
            entities.decode(record.titular)
          )}
          />
          <Column
          title={props.t('banc.iban')}
          key="iban"
          render={(text,record) =>(
            entities.decode(record.iban)
          )}
          />
          <Column
          title={props.t('banc.bic')}
          key="bic"
          render={(text,record) =>(
            entities.decode(record.bic)
          )}
          />

        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Banc);
