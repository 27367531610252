import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, ThunderboltOutlined } from '@ant-design/icons';

import {Form, Row, Col, Select, Button, Input, InputNumber, DatePicker, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';


import { Api, HtmlStrings } from '../../helpers';

import VisorImatges from "../visorImatges";
import DocumentUpload from "../DocumentUpload";

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY';


function Edit(props){

  const [id, setId] = useState(props.item.id);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [banc_iban, setBanc_iban] = useState(props.item.iban);
  const [banc_iban_percentatge, setBanc_iban_percentatge] = useState(props.item.iban_percentatge);
  const [utilitzar_en, setUtilitzar_en] = useState(!props.item.utilitzar_en && !props.mode_separar_despeses ? 'tot' : props.item.utilitzar_en);
  const [banc_bic, setBanc_bic] = useState(props.item.bic);
  //const [pendent_validar, setPendent_validar] = useState(props.item.pendent_validar);
  const [banc_mandat, setBanc_mandat] = useState(props.item.mandat || "");
  const [banc_mandat_data, setBanc_mandat_data] = useState(props.item.mandat_data);
  const [banc_mandat_url, setBanc_mandat_url] = useState(props.item.mandat_url);
  const [banc_mandat_ext, setBanc_mandat_ext] = useState(props.item.mandat_ext);
  const [banc_titular, setBanc_titular] = useState(entities.decode(props.item.titular));
  const [observacions, setObservacions] = useState(entities.decode(props.item.observacions));

  const [llistat_pares, setLlistat_pares] = useState([]);
  const [visor, setVisor] = useState({visible: false});

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nou, setNou] = useState(props.item.id===0);

  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');
  const [errorBanc_mandat, setErrorBanc_mandat] = useState('');
  const [errorBanc_mandatText, setErrorBanc_mandatText] = useState('');
  const [errorBanc_mandat_data, setErrorBanc_mandat_data] = useState('');
  const [errorBanc_titular, setErrorBanc_titular] = useState('');
  const [errorBanc_iban, setErrorBanc_iban] = useState('');
  const [errorBanc_ibanText, setErrorBanc_ibanText] = useState('');



  const buscarDades = async () => {
    setIsLoading(true);

    if(props.mode_separar_despeses){
      const p = await Api.get(`/pares/familia/${id_familia}`);
      if(p.result.success===1){
        const llista_p = p.data.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {item.nom + ' ' + item.cognom1 + ' ' + item.cognom2}
                </Select.Option>
              );
        });
        setLlistat_pares(llista_p);
      }
    }


    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);


  const generaMandat = () =>{
      const tmp_mandat = moment(new Date()).format('YYYYMMDD')+HtmlStrings.keyGen(35);
      setBanc_mandat(tmp_mandat.slice(0,35));
      setErrorBanc_mandat('');
      setErrorBanc_mandatText('');
  }


  const submitForm = async (valors) => {

    let tmp_id_pare = id_pare;
    let tmp_iban_percentatge = banc_iban_percentatge;
    let tmp_mandat = banc_mandat;
    let tmp_mandat_data = banc_mandat_data;


    let correcte = true;
    if(props.mode_separar_despeses){
      if(id_pare === 0){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }
    }

    if(!banc_mandat_data){
      setErrorBanc_mandat_data('error');
      correcte = false;
    }

    if(!banc_titular){
      setErrorBanc_titular('error');
      correcte = false;
    }

    /*if(banc_iban.length!==24)
    {
      setErrorBanc_iban('error');
      setErrorBanc_ibanText(props.t('banc.iban_long'));
    }*/

    if(banc_mandat.length!==35){
      //tmp_mandat =  HtmlStrings.keyGen(27)+moment(new Date()).format('YYYYMMDD');
      setErrorBanc_mandat('error');
      setErrorBanc_mandatText(props.t('banc.generar_mandat'));
      correcte = false;
    }

    if(!correcte){return false}

      setGuardant(true);

      if(!props.mode_separar_despeses){
        tmp_iban_percentatge = 0;
        tmp_id_pare = 0;
      }

      let dades = {
        id,
        id_familia,
        id_pare: tmp_id_pare,
        iban: banc_iban,
        iban_percentatge: tmp_iban_percentatge,
        utilitzar_en,
        bic: banc_bic,
        pendent_validar: 0,
        mandat: tmp_mandat,
        mandat_data: banc_mandat_data,
        titular: entities.encode(banc_titular),
        observacions: entities.encode(observacions)
      }

      let method = nou?'POST':'PUT';

      let url_api = nou? '/bancs' : '/bancs/'+id;

      console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){

          if(nou){
            setNou(false);
            setId(resposta.data.id);
          }
          /*

          if(dni_image_1_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/pares/dni/'+resposta.data.id+'/1', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_1(resp.data.dni_image_1);
              }else{
                console.error('Error POST file: Pares dni_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          */


          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          //props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit Bancs: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Familia guardar: ', error);
      }

  }

  const selectChange = (value) =>{
    setId_pare(value);
    if(value!==0){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }
  const modificaIban = (e) =>{
    let temp = e.target.value;
    temp = temp.replace(/\s/g, '')
    setBanc_iban(temp);
    if(temp.length===24){
      setErrorBanc_iban('');
      setErrorBanc_ibanText('');
    }
  }


  let posicio_botons = "start";
  if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }
//<div style={{padding:'10px 24px 10px 0px', height:'650px'}}>
  return(
    <div style={{padding:'10px 24px 10px 0px'}, id === 0 ? {height:'500px'} : {height:'675px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:6}}
        wrapperCol={{span:18}}
        colon={true}
        autoComplete="off"
      >
    <Row>
      <Col span={24}>

      {!props.mode_separar_despeses
        ?
          <Form.Item
            label={props.t('banc.utilitzar_en.titol')}
          >
            <Select defaultValue={utilitzar_en ? utilitzar_en : "tot"} onChange={(value) => setUtilitzar_en(value)}>
              <Select.Option key="tot" value="tot">{props.t('banc.utilitzar_en.tot')}</Select.Option>
              <Select.Option key="quota" value="quota">{props.t('banc.utilitzar_en.quota')}</Select.Option>
              <Select.Option key="compres" value="compres">{props.t('banc.utilitzar_en.compres')}</Select.Option>
            </Select>
          </Form.Item>
        :
          <Form.Item
            validateStatus={errorSelect}
            help={errorSelectText}
            label={props.t('banc.responsable')}
            required={true}
            style={{marginBottom: 0}}
          >
            <Select defaultValue={id_pare === 0 ? "" : id_pare} onChange={selectChange}>
              {llistat_pares}
            </Select>
          </Form.Item>
      }

        <Form.Item
          label={props.t('banc.titular')}
          validateStatus={errorBanc_titular}
          required={true}>
          <Input name="banc_titular" placeholder={props.t('banc.titular')} onChange={(e) => setBanc_titular(e.target.value.trim())} defaultValue={banc_titular}/>
        </Form.Item>
        <Form.Item
          style={{marginBottom: 0}}
          label={props.t('banc.iban')}
          validateStatus={errorBanc_iban}
          help={errorBanc_ibanText}
          required={true}>
          <Input maxLength={34} name="banc_iban" placeholder={props.t('banc.iban')} onChange={(e) => modificaIban(e)} defaultValue={banc_iban} value={banc_iban}/>
        </Form.Item>
        <Form.Item label={props.t('banc.bic')}>
          <Input name="banc_bic" placeholder={props.t('banc.bic')} onChange={(e) => setBanc_bic(e.target.value.trim())} defaultValue={banc_bic}/>
        </Form.Item>
        <Form.Item
          style={{marginBottom: 0}}
          label={props.t('banc.mandat')}
          validateStatus={errorBanc_mandat}
          help={errorBanc_mandatText}
          required={true}
        >
          <Input.Search
            disabled={banc_mandat.length===35}
            maxLength={35}
            name="banc_mandat"
            placeholder={props.t('banc.generar_mandat')}
            enterButton={<ThunderboltOutlined />} onSearch={(e) => generaMandat()}
            defaultValue={banc_mandat}
            value={banc_mandat}
            />
        </Form.Item>
        <Form.Item
          label={props.t('banc.mandat_data')}
          validateStatus={errorBanc_mandat_data}
          required={true}>
          <DatePicker onChange={(date, dateString) => setBanc_mandat_data(date.toISOString())} defaultValue={banc_mandat_data ? moment(banc_mandat_data) : null} locale={localeDatePicker} format={dateFormat}/>
        </Form.Item>

        {id === 0 ? null :
          <Col span={24} style={{marginLeft: '25px', marginBottom: '25px', paddingLeft: '5px'}}>
            <DocumentUpload
              fileList={banc_mandat_url ? [{uid: -1, status: 'done', url: banc_mandat_url, name: 'Mandat Actual', path: banc_mandat_url}] : []}
              url={global.api_url+'/bancs/mandat/'+id}
              url_delete={global.api_url+'/bancs/mandat/'+id}
              titol={props.t('banc.pujar_mandat1')}
              subtitol={props.t('banc.pujar_mandat2')}
              />
          </Col>
        }
        {!props.mode_separar_despeses ? null :
          <Form.Item label={props.t('banc.iban_percentatge')}
          labelCol={{span:16}}
          wrapperCol={{span:8}}
          >
            <InputNumber name="banc_iban_percentatge" onChange={setBanc_iban_percentatge} defaultValue={banc_iban_percentatge}
              min={-100} max={100} step={0.1} precision={2}
            /><PercentageOutlined />
          </Form.Item>
        }


        <Form.Item label={props.t('banc.observacions')}>
          <Input.TextArea  name="observacions" placeholder={props.t('banc.observacions')} onChange={(e) => setObservacions(e.target.value)} defaultValue={observacions}/>
        </Form.Item>

      </Col>
    </Row>

        <Col span={24}>
          <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
            <Col>
              <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Col>

      </Form>
      { !visor.visible
        ? null
        : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
      }
    </div>
  );
}
export default translate(Edit);
