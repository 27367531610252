import React, { useState, useEffect, useContext } from 'react';
import { translate, setLanguage, getLanguage } from 'react-multi-lang';
import { Link } from "react-router-dom";
import sha512 from 'crypto-js/sha512';
import rabbit from 'crypto-js/rabbit';
import Base64 from 'crypto-js/enc-base64';
import encUtf8 from 'crypto-js/enc-utf8';
import jwt from 'jsonwebtoken';

import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Layout, Row, Col, Input, Button, Affix, Select, message } from 'antd';
import { PerfilUsuariContext } from "../context/perfil-usuari";
import { MenuTriatContext } from "../context/menu-triat";

//import logo from '../images/netagencias.png';
//import logo from '../images/logo_h_t_ajustat.png';

const {
  Header, Footer, Content,
} = Layout;
const Option = Select.Option;



function Login(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [forgotVisible, setForgotVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const inicialitzacions = () => {
    const lang = sessionStorage.getItem('Language');
    if(lang){
        changeLang(lang);
    }

    const perfil_encrypted = sessionStorage.getItem('perfil');
    if(perfil_encrypted){
      const perfil =JSON.parse(rabbit.decrypt(perfil_encrypted, process.env.REACT_APP_SECRET_KEY).toString(encUtf8));
      setPerfilUsuari(perfil);
      //console.log('perfil',perfil);
    }

    const enc_token = sessionStorage.getItem('jwtToken');
    if(enc_token){
      const token = rabbit.decrypt(enc_token,process.env.REACT_APP_SECRET_KEY).toString(encUtf8);

      var decoded = jwt.decode(token);
/*
      const exp = new Date(decoded.exp * 1000);
      console.log('Token_exp:',exp);
      const ara = new Date();
      if(ara.getTime() > exp){
          props.callback();
      }*/
      const exp = new Date(decoded.exp);
      //console.log('Token_exp:',exp);
      const ara = new Date();
      const ara_int = Date.parse(ara)/1000;
      if(exp>ara_int){
          props.callback();
      }
    }
  }

  useEffect(() => {
    inicialitzacions();
  },[]);


  const changeLang = (lang) =>{
    setLanguage(lang);
    sessionStorage.setItem('Language',lang);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    props.form.validateFields( async (err, values) => {
      if (!err) {

        const url = global.api_url+'/admin/login';
        const pass_512 = sha512(values.password);
        const password_hash = Base64.stringify(pass_512);
        //console.log(password_hash);

        const data = {
          username: values.userName,
          password: password_hash
        };
        //console.log('Amb el Hash: ', data);
        //console.log(JSON.stringify(data));

        fetch(url,{
          method:'POST',
          headers:{
            //'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then((response)=>{
          //console.log(response);
          return response.json();
        })
        .then((responseJson)=>{
          //console.log(responseJson);

          //setPerfilUsuari(perfilUsuari => ({...perfilUsuari, account: responseJson.data.account}));


          //if(responseJson.result.success === 1 && responseJson.data.account.username === values.userName && responseJson.data.account.perfil !== 'Pare'){
          if(responseJson.result.success === 1 && responseJson.data.account.username === values.userName){

            const verificar_enc = rabbit.encrypt(password_hash, process.env.REACT_APP_SECRET_KEY).toString();
            const perfil = {account: responseJson.data.account, verificar: verificar_enc, curs_escolar_actual: responseJson.data.curs_escolar_actual};
            setPerfilUsuari(perfil);
            //console.log('LOGIN perfil: ', perfil)
            setMenuTriat('Dashboard');
            const perfil_encrypted = rabbit.encrypt(JSON.stringify(perfil), process.env.REACT_APP_SECRET_KEY).toString();
            sessionStorage.setItem('perfil', perfil_encrypted);

            let token_enc = rabbit.encrypt(responseJson.data.token,process.env.REACT_APP_SECRET_KEY).toString();
            sessionStorage.setItem('jwtToken', token_enc);
            let refresh_token_enc = rabbit.encrypt(responseJson.data.refresh_token,process.env.REACT_APP_SECRET_KEY).toString();
            sessionStorage.setItem('jwtRefreshToken', refresh_token_enc);

            getConfig();
            //props.callback();
          }else{
            message.config({top: 50});
            message.open({content: props.t('login.Error_login'), duration:3});

            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error('Error: login: '+error);
          message.config({top: 50});
          message.open({content: "Conection error", duration:3});
        });


      }else{
        message.config({top: 50});
        message.open({content: props.t('login.Error_login'), duration:3});
      }
    });
  }


  const getConfig = () => {

    const url = global.api_url+'/config';
    const token = rabbit.decrypt(sessionStorage.getItem('jwtToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);

    fetch(url,{
      method:'GET',
      headers:{
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
    .then((response)=>response.json())
    .then((responseJson)=>{
      //console.log("responseJson: ",responseJson);
      if(responseJson.result.success === 1){
        sessionStorage.setItem('config', JSON.stringify(responseJson.data));
        //setPerfilUsuari({...perfilUsuari, curs_escolar_actual: responseJson.data.curs_escolar_actual})
      }else{
        console.error('Error: getConfig: ',responseJson);
        message.config({top: 50});
        message.open({content: responseJson.data.message, duration:3});
      }
      props.callback();
    })
    .catch((error) => {
      console.error('Error: fetch getConfig: '+error);
      message.config({top: 50});
      message.open({content: "Conection error", duration:3});
    });

  }



    let logo = "";

    switch (global.app_id) {
      case "british":
          logo=require('../images/logo_h_t_ajustat.png');
        break;
      case "erp":
          logo=require('../images/logo_black.png');
        break;
      default:
        logo =require('../images/netagencias.png');
    }

    const { getFieldDecorator } = props.form;
    let container;
    return (
      <div ref={node => {
          container = node;
        }}>
      <Layout style={{backgroundColor: 'white', minHeight: '100vh'}}>
      <Header style={{backgroundColor: 'white', height:'15vh'}}></Header>
      <Content  style={{backgroundColor: 'white'}}>
        <Row type="flex" justify="center">
        <img src={logo} alt="Logo" width="300"/>
        </Row>
        <Row type="flex" justify="center" style={{marginTop: `50px`}}>
        <Form onSubmit={handleSubmit} className="login-form">

          <Form.Item>
            {getFieldDecorator('userName', {
              rules: [{ required: true, message: props.t('login.User_required') }],
            })(
              <Input name="user" prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={props.t('login.User')} autoComplete="user" autoFocus="focus"/>
            )}
          </Form.Item>

          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: props.t('login.Password_required') }],
            })(
              <Input name="password" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder={props.t('login.Password')} autoComplete="password"/>
            )}

          </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} onClick={() => setLoading(true)} style={{width:'100%'}}>
                {props.t('login.Log_in')}
              </Button>
            </Form.Item>

            <Row type="flex" justify="center" style={{marginTop: `30px`}}>
              <Link to={"/email"}>{props.t('login.Forgot_pass')}</Link>
            </Row>


        </Form>
        </Row>
      </Content>
      <Footer style={{ textAlign: 'center', backgroundColor: 'white', padding: 12}}>
      <Affix offsetBottom={10} target={() => container}>
        <Row type="flex">
          <Col xs={{span:24,order:2}} sm={{span:24, order:2}} md={{span:14, order:1}} lg={{span:8, order:1}}>Copyright © {new Date().getFullYear()} BlackData Software. All rights reserved.</Col>
          <Col xs={{span:24,order:1}} sm={{span:24, order:1}} md={{span:2, order:2, offset: 7}} lg={{span:2, order:2, offset: 12}}>
          <Select defaultValue={getLanguage()} onChange={changeLang} style={{ width: 120}}>
            <Option value="en">English</Option>
            <Option value="ca">Català</Option>
            <Option value="es">Castellano</Option>

          </Select>
          </Col>
        </Row>
      </Affix>
    </Footer>
  </Layout>
  </div>
    );

}


export default translate(Login);
