import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';
import TriaTipusIngres from '../../../TriaTipusIngres';
import { AllHtmlEntities } from 'html-entities';

import { Api, InitDades } from '../../../../helpers';

const entities = new AllHtmlEntities();

function FacturacioConcepteEdit(props){

  const id = props.item.id;
  const [id_pressupost, setId_pressupost] = useState(props.item.id_pressupost);
  const [tipus, setTipus] = useState(props.item.tipus || '');
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [taula, setTaula] = useState(props.item.taula);
  const [id_taula, setId_taula] = useState(props.item.id_taula);
  const [concepte, setConcepte] = useState(entities.decode(props.item.concepte));
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [preu, setPreu] = useState(props.item.preu);
  const [unitats, setUnitats ] = useState(props.item.unitats);
  const [manual, setManual ] = useState(props.item.manual);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);

  const [errorTipus, setErrorTipus] = useState('');
  const [errorTipusText, setErrorTipusText] = useState('');


  const [guardant, setGuardant] = useState(false);

  const nou = (props.item.id===0);


  useEffect(() => {
    calcularSubtotal(props.item.preu,props.item.unitats,props.item.descompte);
  },[]);

  const calcularSubtotal = (_preu, _unitats, _descompte) =>{
    let tot = 0;
    if(_preu !== 0.0){
      if(_descompte!==0.0){
        const sub = _preu * _unitats;
        tot =  sub - (sub * _descompte / 100);
      }else{
        tot = _preu * _unitats;
      }
    }
    return tot;
  }


const onChangePreu = (_preu) =>{
  setPreu(_preu);
  setSubtotal(calcularSubtotal(_preu, unitats, descompte));
}
const onChangeUnitas = (_unitats) =>{
  setUnitats(_unitats);
  setSubtotal(calcularSubtotal(preu, _unitats, descompte));
}

const onChangeDescompte = (desc) =>{
  setDescompte(desc);
  setSubtotal(calcularSubtotal(preu, unitats, desc));
}


const submitForm = async (values) => {

  let correcte = true;

  if(tipus === ''){
    setErrorTipus('error');
    setErrorTipusText(props.t('generic.tria'));
    correcte = false;
  }

  if(!correcte){
    return false;
  }


  setGuardant(true);
  let dades = {
    id,
    id_pressupost,
    tipus,
    id_alumne,
    taula,
    id_taula,
    concepte: entities.encode(concepte),
    descompte,
    preu,
    unitats,
    subtotal,
    manual
  }

  let method = nou ? 'POST' : 'PUT';
  let url_api = nou ? '/pressupost/concepte' : `/pressupost/concepte/${id}`;

  try{
    const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
    if(resposta.result.success === 1){
      setGuardant(false);
      message.success(props.t('generic.OK_saved'), 5);
      props.callback();
    }else{
      setGuardant(false);
      console.error('Error: submit FacturacioConcepteEdit: ',resposta);
      message.config({top: 50});
      message.open({content: resposta.data.message, duration:10});
    }
  }catch(error){
    setGuardant(false);
    message.config({top: 50});
    message.open({content: "Error saving", duration:10});
    console.error('Error: FacturacioConcepteEdit: ', error);
  }

}

/*
let taules = [];

let tipus_temp = InitDades.tipus_ingressos_llista();

tipus_temp.sort();

tipus_temp.map((item) =>{
  taules.push(
    <Select.Option key={item} value={item}>
      {props.t(`rebuts.tipus.${item}`)}
    </Select.Option>
  );
})
*/

return(
  <Form onFinish={submitForm}
    layout="horizontal"
    autoComplete="off"
  >
    <Form.Item label={props.t('generic.tipus_ingres')} required={true} validateStatus={errorTipus} help={errorTipusText} style={{marginBottom: 0}}>
      <TriaTipusIngres defaultValue={tipus} onChange={setTipus} />
    </Form.Item>

    <Form.Item label={props.t('generic.concepte')}>
      <Input name="concepte" placeholder={props.t('generic.concepte')} onChange={(e) => setConcepte(e.target.value)} defaultValue={concepte} autoFocus="focus"/>
    </Form.Item>
    <Form.Item label={props.t('generic.preu')}>
        <InputNumber name="preu" onChange={onChangePreu} defaultValue={preu}
        step={0.1}
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.unitats')}>
        <InputNumber name="unitats" onChange={onChangeUnitas} defaultValue={unitats} step={1.0}/>
    </Form.Item>
    <Form.Item label={props.t('generic.descompte')}>
        <InputNumber name="descompte" onChange={onChangeDescompte} defaultValue={descompte}
        min={-100} max={100} step={0.1}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.subtotal')}>
        <InputNumber name="subtotal" value={subtotal} disabled
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
      <Col>
        <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
      </Col>
      <Col style={{marginLeft:'10px'}}>
        <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
      </Col>
    </Row>
  </Form>
);

}
export default translate(FacturacioConcepteEdit);
