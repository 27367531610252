import React, { useState } from 'react';

const MenuTriatContext = React.createContext([{}, () => {}]);

const MenuTriatProvider = (props) => {
  const [state, setState] = useState('Dashboard');
  return (
    <MenuTriatContext.Provider value={[state, setState]}>
      {props.children}
    </MenuTriatContext.Provider>
  );
}

export { MenuTriatContext, MenuTriatProvider };
