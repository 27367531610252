import React, { useEffect, useState } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Button, Typography , message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';
import { Api, InitDades } from '../../../../helpers';

import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
import DocumentUploadDelete from "../../../DocumentUploadDelete";
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function MedicEditOne(props){
  const [id, setId] = useState(props.item.id ? props.item.id : 0);
  const [id_alumne, setId_alumne] = useState(0);
  const [id_categoria, setId_categoria] = useState(0);
  const [categoria, setCategoria] = useState('');
  const [text, setText] = useState('');
  const [fitxers, setFitxers] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [guardant, setGuardant] = useState(false);


  useEffect(() =>{
    setId(props.item.id ? props.item.id : 0);
    buscarMedic();
  },[props.item.id])

  const buscarMedic = async()  =>{
    setIsLoading(true);
    if(id > 0){
      const m = await Api.get(`/medic/${id}`);
      if(m.result.success===1){
        setId_alumne(m.data.medic.id_alumne);
        setId_categoria(m.data.medic.id_categoria);
        setText(entities.decode(m.data.medic.text));
        setCategoria(entities.decode(m.data.medic.categoria[props.t('idioma')]));

        const llista_fitxers = m.data.fitxers.map( (doc, index) =>{
          return({uid: index, status: 'done', url: doc.url, name: doc.nom, path: doc.url, s3key: doc.s3key});
        });
        setFitxers(llista_fitxers);
      }
    }
    setIsLoading(false);
  }
  const guardar = async() =>{
    setGuardant(true);

    let dades = {
      id_alumne,
      id_categoria,
      text: entities.encode(text)
    }
    try{
      const resposta = await Api.myFetch('/medic/alumne', 'POST', JSON.stringify(dades));

      if(resposta.result.success === 1){
        console.log(resposta);
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();

      }else{
        setGuardant(false);
        console.error('Error: submit Form BotigaTalles: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: BotigaTalles guardar: ', error);
    }
  }

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
  <>
    <Typography.Title level={3}>{categoria}</Typography.Title>
    <TextAreaCount
      marginTopCount='-0.1rem'
      autoSize={{ minRows: 4, maxRows: 25 }}
      maxLength={500}
      placeholder={props.t('medic.text_lliure')}
      onChange={(valor) => setText(valor)}
      defaultValue={text}/>

    <DocumentUploadDelete
      padding={{padding:'10px'}}
      fileList={fitxers}
      url_upload={`/medic/alumne/${id_alumne}/categoria/${id_categoria}`}
      url_delete={`/medic/document/${id}`}
      titol={props.t('medic.pujar_doc1')}
      subtitol={props.t('medic.pujar_doc2')}
      />
      <Row type="flex" justify="space-between" style={{marginTop: '1rem'}}>
        <Col>
          <Button onClick={props.callback}>{props.t('generic.back')}</Button>
        </Col>
        <Col style={{marginLeft:'10px'}}>
          <Button type="primary" onClick={guardar} loading={guardant}>{props.t('generic.save')}</Button>
        </Col>
      </Row>
    </>

  );
}

export default translate(MedicEditOne);
