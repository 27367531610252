import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Space, Tooltip, Radio, Row, Col, Button, Modal, message } from 'antd';
import { ClearOutlined, SearchOutlined, SyncOutlined, MailOutlined, MailFilled, ReadOutlined, UploadOutlined, DownloadOutlined, RollbackOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';
import Moment from 'react-moment';
import moment from 'moment';

import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import NotificacioEnviar from '../../../components/Formularis/perfils/profe/NotificacioEnviar';
import NotificacioRespondre from '../../../components/Formularis/perfils/profe/NotificacioRespondre';
import NotificacioRead from '../../../components/Formularis/perfils/profe/NotificacioRead';

import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function NotificacionsLlista(props) {

  const [dades, setDades] = useState(props.dades)
  const [modalReadVisible, setModalReadVisible] = useState(false);
  const [modalRespondreVisible, setModalRespondreVisible] = useState(false);
  const [ itemActual, setItemActual ] = useState(null);
  const [filtreTipus, setFiltreTipus] = useState([]);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA
  //filtres i ordenacio
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const tableHandleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  }
  const clearAllFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  }
  //FI filtres i ordenacio


  useEffect(() => {
    setDades(props.dades);
  },[props.dades]);

  useEffect(() => {
    muntarFiltreTipus(props.dades);
  },[props.dades, props.t('idioma')]);


  const muntarFiltreTipus = (dades) =>{
    let temp_list = [];
    let tipusList = [];
    dades.map((item) => {
      if(temp_list.indexOf(item.id_notificacions_tipus) === -1){
        temp_list.push(item.id_notificacions_tipus)
        if(item.id_notificacions_tipus > 0){
          tipusList.push({text: entities.decode(item.notificacions_tipus[props.t('idioma')]), value: item.id_notificacions_tipus});
        }else{
          tipusList.push({text: '', value: 0});
        }
      }
    });

    tipusList.sort((a, b) => a.value - b.value);
    setFiltreTipus(tipusList);
  }

  const triaNotificiacio = (item) =>{
    setItemActual(item);
    setModalReadVisible(true);
  }

  const tancarModalRead = () =>{
    setAsReaded(itemActual.id);
    setModalReadVisible(false);
  }

  const respondreNotificiacio = (item) =>{
    setItemActual(item);
    setModalRespondreVisible(true);
  }

  const tancarModalRespondre = () =>{
    setAsReaded(itemActual.id);
    setModalRespondreVisible(false);
  }




  const setAsReaded = (id) =>{
    const newDades = dades.map((item) => {
      if(item.id === id){
        item.readed_at = new Date();
      }
      return item;
    });
    setDades(newDades);
  }

  const setAsNotReaded = (id) =>{
    const newDades = dades.map((item) => {
      if(item.id === id){
        item.readed_at = null;
      }
      return item;
    });
    setDades(newDades);
  }

  const marcarLlegitBD = async(id) =>{
    try{
      const resposta = await Api.myFetch(`/notificacions/llegida/${id}`, 'PUT', null);
      if(resposta.result.success === 1){
        setAsReaded(id);
        //console.log('llegit')
        //setGuardant(false);
        //message.success(props.t('generic.OK_saved'), 5);
      }else{
        //setGuardant(false);
        console.error('Error: notificacio llegida: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      //setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio Llegida: ', error);
    }
  }
  const marcarNoLlegitBD = async(id) =>{
    try{
      const resposta = await Api.myFetch(`/notificacions/nollegida/${id}`, 'PUT', null);
      if(resposta.result.success === 1){
        setAsNotReaded(id)
        //setGuardant(false);
        //message.success(props.t('generic.OK_saved'), 5);
      }else{
        //setGuardant(false);
        console.error('Error: notificacio nollegida: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      //setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio NoLlegida: ', error);
    }
  }


  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

  const columnes = [
      {
        render: (text, record) => {

          if(perfilUsuari.account.id == record.user_origen){
            return <UploadOutlined style={{fontSize: '32px', color: '#5AB7B2' }}/>;
          }else{
            return <DownloadOutlined style={{fontSize: '32px', color: '#B21E28'}}/>;
          }

        },
        width: 48,
      },
      {
        title: props.t('notificacions.tipus'),
        dataIndex: "notificacions_tipus",
        key: "notificacions_tipus",
        render: (text,record) =><span>{entities.decode(record.notificacions_tipus[props.t('idioma')])}</span>,
        sorter: (a, b) => { return entities.decode(a.notificacions_tipus[props.t('idioma')]).localeCompare(entities.decode(b.notificacions_tipus[props.t('idioma')]))},
        sortOrder: sortedInfo.columnKey === 'notificacions_tipus' && sortedInfo.order,
        //sorter: (a, b) =>  a.id_notificacions_tipus - b.id_notificacions_tipus,
        filters: filtreTipus,
        filteredValue: filteredInfo.notificacions_tipus || null,
        onFilter: (value, record) => record.id_notificacions_tipus === value,
      },
      {
        title: props.t('notificacions.origen'),
        dataIndex: "origen",
        key: "origen",
        render: (text,record) =><span>{entities.decode(record.origen)}</span>,
        sorter: (a, b) => { return entities.decode(a.origen).localeCompare(entities.decode(b.origen))},
        sortOrder: sortedInfo.columnKey === 'origen' && sortedInfo.order,
        ...getColumnSearchProps('origen',props.t('notificacions.origen')),
        filteredValue: filteredInfo.origen || null,
      },
      {
        title: props.t('notificacions.desti'),
        dataIndex: "desti",
        key: "desti",
        render: (text,record) =><span>{entities.decode(record.desti)}</span>,
        sorter: (a, b) => { return entities.decode(a.desti).localeCompare(entities.decode(b.desti))},
        sortOrder: sortedInfo.columnKey === 'desti' && sortedInfo.order,
        ...getColumnSearchProps('desti',props.t('notificacions.desti')),
        filteredValue: filteredInfo.desti || null,
      },
      {
        title: props.t('notificacions.alumne'), dataIndex: "alumne", key: "alumne",
        dataIndex: "alumne",
        key: "alumne",
        render: (text,record) =><span>{entities.decode(record.alumne)}</span>,
        sorter: (a, b) => { return entities.decode(a.alumne).localeCompare(entities.decode(b.alumne))},
        sortOrder: sortedInfo.columnKey === 'alumne' && sortedInfo.order,
        ...getColumnSearchProps('alumne',props.t('notificacions.alumne')),
        filteredValue: filteredInfo.alumne || null,
      },
      { title: props.t('notificacions.missatge'), dataIndex: "missatge", key: "missatge",
        dataIndex: "missatge",
        key: "missatge",
        ellipsis: true,
        render: (text,record) =><span>{entities.decode(record.missatge)}</span>,
        ...getColumnSearchProps('missatge',props.t('notificacions.missatge')),
        filteredValue: filteredInfo.missatge || null,
      },
      {
        title:props.t('generic.created_at'),
        key:"creat",
        sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at)),
        sortOrder: sortedInfo.columnKey === 'creat' && sortedInfo.order,
        render:(text,record) => (record.created_at ? <Moment date={moment(record.created_at).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" )
      },
      {
        title: (
          <Tooltip placement="top" title="Clear filters">
            <Button icon={<ClearOutlined />} onClick={(e) => clearAllFilters()} ghost="true" className="BotoTaula"/>
          </Tooltip>
        ),
        key: "actions",
        align: "right",
        width: "125px",
        render: (text,record) => (
          <Row>
            <Col>
              <Tooltip placement="top" title={props.t("generic.llegir")}>
                <Button icon={<ReadOutlined/>} onClick={(e) => {e.stopPropagation(); triaNotificiacio(record);}} ghost="true" className="BotoTaula"/>
              </Tooltip>
            </Col>
            <Col>
            {record.readed_at ?
                <Tooltip placement="top" title={props.t("generic.marcarNoLlegit")}>
                  {/*<Button icon={<MailOutlined style={{color: 'black'}}/>} onClick={(e) => marcarNoLlegitBD(record.id)} ghost="true" className="BotoTaula"/>*/}
                  <Button icon={<MailFilled/>} onClick={(e) => {e.stopPropagation(); marcarNoLlegitBD(record.id);}} ghost="true" className="BotoTaula"/>
                </Tooltip>
              :
                <Tooltip placement="top" title={props.t("generic.marcarLlegit")}>
                  <Button icon={<MailOutlined/>} onClick={(e) => {e.stopPropagation(); marcarLlegitBD(record.id);}} ghost="true" className="BotoTaula"/>
                </Tooltip>
              }
            </Col>
            {
            perfilUsuari.account.id == record.user_origen ? null :
              <Col>
                <Tooltip placement="top" title={props.t("generic.contestar")}>
                  <Button icon={<RollbackOutlined />} onClick={(e) => {e.stopPropagation(); respondreNotificiacio(record);}} ghost="true" className="BotoTaula"/>
                </Tooltip>
              </Col>
            }
          </Row>
      )
    }
    ];


  return(
    <ContainerPagina xl={24}>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <Table
          rowKey="id"
          rowClassName={(record, index) => (record.readed_at ? 'txt-normal' : 'txt-negreta')}
          pagination={false}
          loading={props.isLoading}
          dataSource={dades}
          size="small"
          locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
          columns={columnes}
          onChange={tableHandleChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: (e) => triaNotificiacio(record),
              onDoubleClick: (e) => triaNotificiacio(record)
            };
          }}
          />
      </div>
      <Modal
        title={props.t('menu.Notificacions')}
        destroyOnClose={true}
        visible={modalReadVisible}
        //onCancel={()=>setModalReadVisible(false)}
        onCancel={()=>tancarModalRead()}
        footer={null}
        width={800}
      >
        <>
          <NotificacioRead item={itemActual} tancarModal={()=>tancarModalRead()}/>
          {
            itemActual && (perfilUsuari.account.id == itemActual.user_origen) ? null :
            <Row>
                <Button onClick={(e) => {setModalRespondreVisible(true);}}>
                {props.t("generic.contestar")}
              </Button>
            </Row>
          }
        </>
      </Modal>

      <Modal
        title={props.t('generic.contestar')}
        destroyOnClose={true}
        visible={modalRespondreVisible}
        onCancel={()=>tancarModalRespondre()}
        footer={null}
        width={800}
      >
          <NotificacioRespondre item={itemActual} tancarModal={()=>tancarModalRespondre()}/>
      </Modal>
    </ContainerPagina>
  );
}

function Notificacions(props){
  const [isLoading, setIsLoading] = useState(true);
  const [classeActual, setClasseActual] = useState(0);
  const [llistaClasses, setLlistaClasses] = useState([]);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [dades, setDades] = useState([]);
  const [dadesFiltrades, setDadesFiltrades] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);
  const [refrescar, setRefrescar] = useState(0);

  useEffect(() => {
    buscarDades();
  },[refrescar]);

  useEffect(() => {
    filtrarPerClasse();
  },[classeActual]);

  const buscarDades = async () => {
    setIsLoading(true);
    let llista_c = [];
    let array_id_classe = [];

    const not = await Api.get(`/notificacions/totes/usuari/${perfilUsuari.account.id}`);

    if(not.result.success===1){
      //console.log(not.data.notificacions)
      setDades(not.data.notificacions);
      setDadesFiltrades(not.data.notificacions);

      not.data.notificacions.map((item) =>{
        item.classes.map((cl)=>{
          array_id_classe[cl.id_classe] = cl;
          //{id_classe: item.id_classe, nom: item.nom_classe, ordre: item.ordre_classe}
        });
      });

      llista_c.push(<Radio.Button key={0} value={0}>{props.t('notificacions.totes')}</Radio.Button>)
        array_id_classe.sort((a, b) => a.ordre_classe - b.ordre_classe);
        //console.log(array_id_classe)
        array_id_classe.map((item) =>{
          llista_c.push(<Radio.Button key={item.id_classe} value={item.id_classe}>{entities.decode(item.nom_classe)}</Radio.Button>);
        });
      setLlistaClasses(llista_c);
    }
    setIsLoading(false);
  }

  const filtrarPerClasse = () => {
    if(classeActual > 0){
      const filtrat = dades.filter((element, index, arr) => element.classes.some(cl => cl.id_classe === classeActual));
      setDadesFiltrades(filtrat);
    }else{
      setDadesFiltrades(dades);
    }

  }

  const tancarModalEnviar = () =>{
    setModalVisible(false);
    setRefrescar(refrescar+1);
  }

  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menu.Notificacions')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :
        <>
          <Row justify="space-between" gutter={[16,16]}>
            <Col>
              <Radio.Group defaultValue={0} optionType="button" onChange={(e) => setClasseActual(e.target.value)}>
                {llistaClasses}
              </Radio.Group>
            </Col>
            <Col>
              <Button type="primary" style={{marginRight: '1rem'}} onClick={()=>setModalVisible(true)}>{props.t('notificacions.enviar_notificacio')}</Button>
            </Col>
          </Row>
          <NotificacionsLlista isLoading={isLoading} dades={dadesFiltrades} t={props.t}/>

        </>
      }
      </div>

      <Modal
        title={props.t('menu.Notificacions')}
        destroyOnClose={true}
        visible={modalVisible}
        onCancel={()=>setModalVisible(false)}
        footer={null}
      >
        <NotificacioEnviar id_alumne={0} tancarModal={()=>tancarModalEnviar()}/>
      </Modal>

    </ContainerPagina>
  );
}
export default translate(Notificacions);
