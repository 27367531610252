import React, { useContext, useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Button, List, Card, Tabs } from 'antd';
import { AlertFilled, CarOutlined, ExperimentOutlined } from '@ant-design/icons';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import { AllHtmlEntities } from 'html-entities';
import { MenuTriatContext } from "../../../context/menu-triat";
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import { Api } from '../../../helpers';
import AutoritzatsLlista from '../../../components/Formularis/perfils/profe/Autoritzats';

const entities = new AllHtmlEntities();

function Autoritzats(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    montarTabs();
  },[perfilUsuari]);

  const montarTabs = async() =>{

    if(perfilUsuari.dades_profe !== undefined){
      let llista_tabs = [];
      if(perfilUsuari.dades_profe.classes){
        llista_tabs = perfilUsuari.dades_profe.classes.map((item) =>{
              return(
                <Tabs.TabPane tab={entities.decode(item.nom)} key={`class_${item.id}`}>
                  <AutoritzatsLlista tipus='classe' id_taula={item.id}/>
                </Tabs.TabPane>
              );
        });
      }

      if(perfilUsuari.dades_profe.extraescolars){
        perfilUsuari.dades_profe.extraescolars.map((item) =>{
              llista_tabs.push(
                <Tabs.TabPane tab={<span><ExperimentOutlined />{entities.decode(eval("item.nom_"+props.t('idioma')))}</span>} key={`extraescolars_${item.id}`}>
                  <AutoritzatsLlista tipus='extraescolar' id_taula={item.id}/>
                </Tabs.TabPane>
              );
        });
      }

      if(perfilUsuari.dades_profe.hi_ha_transport){

        let transport = [];

        const anar = await Api.get('/transport/sentit/anada');
        if(anar.result.success===1){
          anar.data.transport.map((item) =>{
              if(item.id == perfilUsuari.dades_profe.transport_defecte.id_anada){
                transport.push(item);
              }
          });
        }
        const tornar = await Api.get('/transport/sentit/tornada');
        if(tornar.result.success===1){
          tornar.data.transport.map((item) =>{
              if(item.id == perfilUsuari.dades_profe.transport_defecte.id_tornada){
                transport.push(item);
              }
          });
        }
        if(anar.result.success===1){
          anar.data.transport.map((item) =>{
              if(item.id != perfilUsuari.dades_profe.transport_defecte.id_anada){
                transport.push(item);
              }
          });
        }
        if(tornar.result.success===1){
          tornar.data.transport.map((item) =>{
              if(item.id != perfilUsuari.dades_profe.transport_defecte.id_tornada){
                transport.push(item);
              }
          });
        }

        transport.map((item) =>{
          let nom = entities.decode(item.nom[props.t('idioma')]).replace('The British School Costa Daurada', 'School')
              llista_tabs.push(
                <Tabs.TabPane tab={<span><CarOutlined />{nom}</span>} key={`bus_${item.id}`}>
                  <AutoritzatsLlista tipus='bus' id_taula={item.id}/>
                </Tabs.TabPane>
              );
        });

      }


      setTabs(llista_tabs);
    }
  }

  return(
    <>
      <Tabs style={{padding: 10}}>
          {tabs}
      </Tabs>
    </>
  );
}

export default translate(Autoritzats);
