import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ComandesExcursionsExportaExcel(props){

  const [nursery, setNursery] = useState([]);
  const [reception, setReception] = useState([]);
  const [year1, setYear1] = useState([]);
  const [year23, setYear23] = useState([]);
  const [year45, setYear45] = useState([]);
  const [year67, setYear67] = useState([]);

  const nom_fulla = props.nom_fulla ? props.nom_fulla : props.t('menu.ComandesSummerCamp');
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : props.t('menu.ComandesSummerCamp');

  useEffect(() => {


    const fnursery_temp = props.dades.filter((element, index, arr) => element.id_producte === 108);
    const fnursery = fnursery_temp.map(item =>{
      for(let i=397; i<422; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setNursery(fnursery)

    const freception_temp = props.dades.filter((element, index, arr) => element.id_producte === 110);
    const freception = freception_temp.map(item =>{
      for(let i=733; i<758; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setReception(freception)



    const fyear1_temp = props.dades.filter((element, index, arr) => element.id_producte === 111);
    const fyear1 = fyear1_temp.map(item =>{
      for(let i=764; i<789; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear1(fyear1)



    const fyear23_temp = props.dades.filter((element, index, arr) => element.id_producte === 112);
    const fyear23 = fyear23_temp.map(item =>{
      for(let i=639; i<664; i++ ){
        item[`w${i}`] = 0;
      }
      item['w732'] = 0;
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear23(fyear23)


    const fyear45_temp = props.dades.filter((element, index, arr) => element.id_producte === 113);
    const fyear45 = fyear45_temp.map(item =>{
      for(let i=795; i<821; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear45(fyear45)

    const fyear67_temp = props.dades.filter((element, index, arr) => element.id_producte === 114);
    const fyear67 = fyear67_temp.map(item =>{
      for(let i=703; i<845; i++ ){
        item[`w${i}`] = 0;
      }
      item.propietats.map(p =>{
        item[`w${p.id_propietat}`] = p.unitats;
      })
      return item;
    });
    setYear67(fyear67)





  },[props.dades]);

  //console.log("dins exporta: ", props.dades)
  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={nursery} name={'Nursery'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w397"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w398"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w399"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w400"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w401"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w402"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w403"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w404"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w405"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w406"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w407"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w408"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w409"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w410"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w411"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w412"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w413"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w414"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w415"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w416"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w417"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w418"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w419"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w420"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w421"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={reception} name={'Reception'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w733"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w734"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w735"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w736"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w737"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w738"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w739"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w740"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w741"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w742"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w743"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w744"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w745"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w746"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w747"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w748"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w749"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w750"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w751"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w752"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w753"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w754"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w755"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w756"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w757"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={year1} name={'Year 1'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w764"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w765"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w766"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w767"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w768"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w769"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w770"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w771"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w772"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w773"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w774"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w775"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w776"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w777"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w778"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w779"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w780"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w781"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w782"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w783"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w784"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w785"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w786"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w787"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w788"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={year23} name={'Year 2,3'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w639"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w640"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w641"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w642"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w643"/>

            <ExcelColumn label={'Setmana 2 OPCIÓ 1: Sailing 9h-16h'} value="w645"/>
            <ExcelColumn label={'Setmana 2 OPCIÓ 2: 9h a 13h'} value="w644"/>
            <ExcelColumn label={'Setmana 2 OPCIÓ 2: 9h-16h (dinar)'} value="w732"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w646"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w647"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w648"/>

            <ExcelColumn label={'Setmana 3: 9h a 13h'} value="w649"/>
            <ExcelColumn label={'Setmana 3: 9h-16h (dinar)'} value="w650"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w651"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w652"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w653"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w654"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w655"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w656"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w657"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w658"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w659"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w660"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w661"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w662"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w663"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={year45} name={'Year 4,5'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1: 9h a 13h'} value="w795"/>
            <ExcelColumn label={'Setmana 1: 9h-16h (dinar)'} value="w796"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w797"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w798"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w799"/>

            <ExcelColumn label={'Setmana 2: 9h a 13h'} value="w805"/>
            <ExcelColumn label={'Setmana 2: 9h-16h (dinar)'} value="w806"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w802"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w803"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w804"/>

            <ExcelColumn label={'Setmana 3 OPCIÓ 1: Sailing 9h-16h'} value="w801"/>
            <ExcelColumn label={'Setmana 3 OPCIÓ 2: 9h a 13h'} value="w800"/>
            <ExcelColumn label={'Setmana 3 OPCIÓ 2: 9h-16h (dinar)'} value="w820"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w807"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w808"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w809"/>

            <ExcelColumn label={'Setmana 4: 9h a 13h'} value="w810"/>
            <ExcelColumn label={'Setmana 4: 9h-16h (dinar)'} value="w811"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w812"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w813"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w814"/>

            <ExcelColumn label={'Setmana 5: 9h a 13h'} value="w815"/>
            <ExcelColumn label={'Setmana 5: 9h-16h (dinar)'} value="w816"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w817"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w818"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w819"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>

          <ExcelSheet data={year67} name={'Year 6,7'}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>

            <ExcelColumn label={'Setmana 1 OPCIÓ 1: 9h a 13h'} value="w721"/>
            <ExcelColumn label={'Setmana 1 OPCIÓ 1: 9h a 16h (Dinar)'} value="w827"/>
            <ExcelColumn label={'Setmana 1 OPCIÓ 2: 9h a 13h'} value="w828"/>
            <ExcelColumn label={'Setmana 1 OPCIÓ 2: 9h a 16h (Dinar)'} value="w829"/>
            <ExcelColumn label={'Setmana 1: bus anada'} value="w703"/>
            <ExcelColumn label={'Setmana 1: bus tornada'} value="w704"/>
            <ExcelColumn label={'Setmana 1: bus anada i tornada'} value="w705"/>

            <ExcelColumn label={'Setmana 2 OPCIÓ 1: 9h a 13h'} value="w830"/>
            <ExcelColumn label={'Setmana 2 OPCIÓ 1: 9h a 16h (Dinar)'} value="w831"/>
            <ExcelColumn label={'Setmana 2 OPCIÓ 2: 9h a 13h'} value="w832"/>
            <ExcelColumn label={'Setmana 2 OPCIÓ 2: 9h a 16h (Dinar)'} value="w833"/>
            <ExcelColumn label={'Setmana 2: bus anada'} value="w708"/>
            <ExcelColumn label={'Setmana 2: bus tornada'} value="w709"/>
            <ExcelColumn label={'Setmana 2: bus anada i tornada'} value="w710"/>

            <ExcelColumn label={'Setmana 3 OPCIÓ 1: 9h a 13h'} value="w834"/>
            <ExcelColumn label={'Setmana 3 OPCIÓ 1: 9h a 16h (Dinar)'} value="w835"/>
            <ExcelColumn label={'Setmana 3 OPCIÓ 2: 9h a 13h'} value="w836"/>
            <ExcelColumn label={'Setmana 3 OPCIÓ 2: 9h-16h (dinar)'} value="w837"/>
            <ExcelColumn label={'Setmana 3: bus anada'} value="w713"/>
            <ExcelColumn label={'Setmana 3: bus tornada'} value="w714"/>
            <ExcelColumn label={'Setmana 3: bus anada i tornada'} value="w715"/>

            <ExcelColumn label={'Setmana 4 OPCIÓ 1: 9h a 13h'} value="w838"/>
            <ExcelColumn label={'Setmana 4 OPCIÓ 1: 9h a 16h (Dinar)'} value="w839"/>
            <ExcelColumn label={'Setmana 4 OPCIÓ 2: 9h a 13h'} value="w840"/>
            <ExcelColumn label={'Setmana 4 OPCIÓ 2: 9h-16h (dinar)'} value="w841"/>
            <ExcelColumn label={'Setmana 4: bus anada'} value="w718"/>
            <ExcelColumn label={'Setmana 4: bus tornada'} value="w719"/>
            <ExcelColumn label={'Setmana 4: bus anada i tornada'} value="w826"/>

            <ExcelColumn label={'Setmana 5 OPCIÓ 1: Sailing 9h a 16h'} value="w842"/>
            <ExcelColumn label={'Setmana 5 OPCIÓ 2: 9h a 13h'} value="w843"/>
            <ExcelColumn label={'Setmana 5 OPCIÓ 2: 9h-16h (dinar)'} value="w844"/>
            <ExcelColumn label={'Setmana 5: bus anada'} value="w723"/>
            <ExcelColumn label={'Setmana 5: bus tornada'} value="w724"/>
            <ExcelColumn label={'Setmana 5: bus anada i tornada'} value="w725"/>

            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(ComandesExcursionsExportaExcel);
