import React, {useState, useEffect, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Card, Empty, Radio, Modal, Button, Tooltip } from 'antd';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import ExtraescolarDetall from '../../../components/Formularis/perfils/pares/Extraescolar';

import { Api } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const entities = new AllHtmlEntities();

const mides_botonets = {width: '100%',height: '65px'};
const color = [
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#5AB7B2', borderColor: '#5AB7B2', color: 'white', borderRadius: '15px'},
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#B21E28', borderColor: '#B21E28', color: 'white', borderRadius: '15px'},
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#F7B334', borderColor: '#F7B334', color: 'black', borderRadius: '15px'},
];
const color_comprat = {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#bdbdbd', borderColor: '#bdbdbd', color: 'black', borderRadius: '15px'};

//la funció de Extraescolars esta al final del fitxer
function ExtraescolarsLlista(props) {

  const [llistaProductes, setLlistaProductes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [producteTriat, setProducteTriat] = useState([]);

  const buscarDadesProductes = async () => {
    setIsLoading(true);
    const extra = await Api.get(`/extraescolars/alumne/${props.id_alumne}`);
    //console.log(extra)
    if(extra.result.success===1){
      const llista_p = extra.data.extraescolars.map((item, index) =>{




              if(!item.comprat){
                return(
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{marginTop:'15px'}} key={item.id_extraescolar}>
                    <Button type="primary" size="large" style={color[index%3]} onClick={() => obrirDetall(item)}>
                      <Row>
                        <Col span={24} style={{fontSize: 14}}>{entities.decode(item.nom[props.idioma])}</Col>
                        <Col span={24} style={{fontSize: 14}}>{entities.decode(item.horari[props.idioma])}</Col>
                      </Row>
                    </Button>
                  </Col>
                );
              }else{
                return(
                <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{marginTop:'15px'}} key={item.id_extraescolar}>
                  <Tooltip placement="top" title={props.t('generic.serveiJaContractat')}>
                    <Button type="primary" size="large" style={color_comprat} onClick={() => obrirDetall(item)}>
                        <Row>
                          <Col span={2} style={{fontSize: 24, color: '#00e676 '}}>
                            <CheckOutlined />
                          </Col>
                          <Col span={22} style={{fontSize: 14}}>
                            <Row>
                              <Col span={24} style={{fontSize: 14}}>{entities.decode(item.nom[props.idioma])}</Col>
                              <Col span={24} style={{fontSize: 14}}>{entities.decode(item.horari[props.idioma])}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Button>
                    </Tooltip>
                  </Col>
                );
            }
      });
      if(llista_p.length>0){
        setLlistaProductes(llista_p);
      }else{
      setLlistaProductes(<Empty/>);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDadesProductes();
  },[props.id_alumne, props.idioma]);

  const respostaProducte = () =>{
    setModalVisible(false);
  }
  const obrirDetall = (producte) =>{
    setProducteTriat(producte);
    setModalVisible(true);
  }

  return(
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginTop:'1rem', marginLeft:'0.5rem'}}>
        {llistaProductes}
      </Row>
      <Modal
        key="extraescolar"
        visible={modalVisible}
        footer={null}
        width={450}
        style={{top:10}}
        bodyStyle={{ overflow: "auto"}}
        destroyOnClose={true}
        forceRender={true}
        maskClosable={true}
        closable={true}
        onCancel={respostaProducte}
      >
        <ExtraescolarDetall item={producteTriat} tancarModal={respostaProducte} id_alumne={props.id_alumne}/>
      </Modal>
    </>
  );

}


function Extraescolars(props){
  const [isLoading, setIsLoading] = useState(true);
  const [fillActual, setFillActual] = useState(1);
  const [llistaFills, setLlistaFills] = useState([]);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const buscarDades = async () => {
    setIsLoading(true);
    let llista_f = [];
    const fills = await Api.get(`/alumnes/familia/${perfilUsuari.account.id_familia}`);
    console.log('dins: buscarDadesFills',fills)
    if(fills.result.success===1){
      setFillActual(fills.data.alumnes[0].id);
      llista_f = fills.data.alumnes.map((item) =>{
        console.log(item)
        return(
          <Radio.Button key={item.id} value={item.id}>{entities.decode(item.nom)}</Radio.Button>
        );
      });
      setLlistaFills(llista_f);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    buscarDades();
  },[]);

  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menu.Extraescolars')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :
        <>
          <Radio.Group defaultValue={fillActual} buttonStyle="solid" onChange={(e) => setFillActual(e.target.value)}>
            {llistaFills}
          </Radio.Group>
          <ExtraescolarsLlista id_alumne={fillActual} t={props.t} idioma={props.t('idioma')}/>

        </>
      }
      </div>
    </ContainerPagina>
  );
}

export default translate(Extraescolars);
