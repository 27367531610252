import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';

import {Typography, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, message } from 'antd';
import { SyncOutlined, SendOutlined } from '@ant-design/icons';

import { AllHtmlEntities } from 'html-entities';
import { Api } from '../../../../helpers';


const entities = new AllHtmlEntities();

function ComandesSummerExterns(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar || 2);
  const [productesOptions, setProductesOptions] = useState([]);
  const [productesLlistaIndexada, setProductesLlistaIndexada] = useState([]);
  const [propietatsText, setPropietatsText] = useState([]);
  const [propietatsPreu, setPropietatsPreu] = useState([]);
  const [comprat, setComprat] = useState([]);

  const [enviandoEmail, setEnviandoEmail] = useState(false);




    useEffect(() => {
      //console.log('id_curs_escolar', id_curs_escolar)
      buscarDadesProductes();
    },[id_curs_escolar]);

    useEffect(() => {
      buscarPropietas();
    },[]);


    useEffect(() => {
      if(productesLlistaIndexada.length > 0){
        if(propietatsText){
          if(props.item){
            pintarComprat();
          }
        }
      }
    },[productesLlistaIndexada, propietatsText, props.item]);


    const buscarDadesProductes = async () => {
      if(id_curs_escolar > 0 ){
        //const prods = await Api.get(`/botiga/admin/categoria/4/alumne/${id_alumne}/${id_familia}`);
        const prods = await Api.get(`/botiga/categoria/4/${id_curs_escolar}`);
        if(prods.result.success===1){
          //console.log('productes: ',prods)
          let llistaIndexada = [];
          prods.data.productes.forEach((item, i) => {
            llistaIndexada[item.id] = item;
          });

          //console.log('llistaIndexada', llistaIndexada)
          setProductesLlistaIndexada(llistaIndexada);
        }
      }
    }

    const buscarPropietas = async () => {
      const p = await Api.get(`/summer/propietats`);
      if(p.result.success===1){
        //console.log('/summer/propietats', p)

        let ptext = [];
        let ppreu = [];
        p.propietats.map((item) => {
          ptext[parseInt(item.id)] = entities.decode(item.text_ca);
          ppreu[parseInt(item.id)] = item.preu;
        });


        setPropietatsText(ptext)
        setPropietatsPreu(ppreu)

        //console.log('ptext', ptext)
      }
    }

    const pintarLinea = (id_propietat) =>{

      return (<Row key={id_propietat}><span>{propietatsText[id_propietat]}&nbsp;<b>{propietatsPreu[id_propietat]}€</b></span></Row>);
    }


    const pintarComprat = () =>{

      let comprat_tmp = [];

      props.item.json.nens.forEach((nen, i) => {

        
        //console.log('nen.comprat.id_producte: ', nen.comprat.id_producte)
        //console.log('productesLlistaIndexada: ', productesLlistaIndexada)
        const producte_nom = productesLlistaIndexada[nen.comprat.id_producte]["nom"]["ca"];
        //console.log('nen: ', nen, propietats[nen.comprat.propietats.weeks.w1])
        comprat_tmp.push(<Row key={i} align="middle" style={{marginTop: '10px'}}>
                            <Col span={24}><span style={{fontSize: '1.1rem'}}><b>{nen.nom+' '+nen.cognoms}</b>&nbsp;({nen.naixement})</span></Col>
                            <Col span={24}><b>{producte_nom}</b>&nbsp;&nbsp;(Import: <b>{nen.comprat.import}€</b>&nbsp;Descompte: <b>{nen.comprat.descompte}%</b>&nbsp;Subtotal: <b>{nen.comprat.subtotal}€</b>)</Col>
                         </Row>);

      //WEEK 1
        if(nen.comprat.propietats.weeks.w1 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.weeks.w1));
        }
        if(nen.comprat.propietats.bus.b1 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.bus.b1));
        }
        if(nen.comprat.propietats.acollida_mati.m1 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_mati.m1));
        }
        if(nen.comprat.propietats.acollida_tarda.t1 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_tarda.t1));
        }

      //WEEK 2
        if(nen.comprat.propietats.weeks.w2 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.weeks.w2));
        }
        if(nen.comprat.propietats.bus.b2 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.bus.b2));
        }
        if(nen.comprat.propietats.acollida_mati.m2 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_mati.m2));
        }
        if(nen.comprat.propietats.acollida_tarda.t2 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_tarda.t2));
        }

      //WEEK 3
        if(nen.comprat.propietats.weeks.w3 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.weeks.w3));
        }
        if(nen.comprat.propietats.bus.b3 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.bus.b3));
        }
        if(nen.comprat.propietats.acollida_mati.m3 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_mati.m3));
        }
        if(nen.comprat.propietats.acollida_tarda.t3 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_tarda.t3));
        }

      //WEEK 4
        if(nen.comprat.propietats.weeks.w4 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.weeks.w4));
        }
        if(nen.comprat.propietats.bus.b4 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.bus.b4));
        }
        if(nen.comprat.propietats.acollida_mati.m4 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_mati.m4));
        }
        if(nen.comprat.propietats.acollida_tarda.t4 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_tarda.t4));
        }

      //WEEK 5
        if(nen.comprat.propietats.weeks.w5 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.weeks.w5));
        }
        if(nen.comprat.propietats.bus.b5 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.bus.b5));
        }
        if(nen.comprat.propietats.acollida_mati.m5 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_mati.m5));
        }
        if(nen.comprat.propietats.acollida_tarda.t5 > 0){
          comprat_tmp.push(pintarLinea(nen.comprat.propietats.acollida_tarda.t5));
        }



      });


      comprat_tmp.push(<Row  key={1000} align="middle" style={{marginTop: '10px'}}>
                          <Col><span style={{fontSize: '1.1rem'}}><b>Total:&nbsp;</b><font color="red">{props.item.json.total}€</font></span></Col>
                       </Row>);


      setComprat(comprat_tmp)

    }


    const sendEmail = async () => {
      if(props.item.id > 0 ){
        setEnviandoEmail(true);

        const resposta = await Api.myFetch(`/summer/${props.item.id}`, 'PUT');

        if(resposta.result.success === 1){
          setEnviandoEmail(false);
          message.success(props.t('generic.OK_saved'), 5);

        }else{
          setEnviandoEmail(false);
          console.error('Error: Enviat email',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }

      }
    }

  return (
      <>

        <Row justify="center">
    <Form
        colon={true}
        autoComplete="new-password"
      >

      {/*PARE 1*/}
      <Typography.Title level={3}>{props.t('pares.dades_responsable')}</Typography.Title>
        <Row>
          <Col span={10}>
            <Form.Item label={props.t('pares.nom')} required={true}>
              <Input name="nom" placeholder={props.t('pares.nom')} value={props.item.json.pare.nom} disabled/>
            </Form.Item>
          </Col>
          <Col span={14} style={{paddingLeft: '5px'}}>
            <Form.Item label={props.t('pares.cognoms')} required={true}>
              <Input name="cognoms" placeholder={props.t('pares.cognoms')} value={props.item.json.pare.cognoms} disabled/>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{paddingRight: '2.5px'}}>
            <Form.Item label={props.t('pares.tel1')} required={true}>
              <Input name="tel1" placeholder={props.t('pares.tel1')} value={props.item.json.pare.tel1} disabled/>
            </Form.Item>
          </Col>
          <Col span={12} style={{paddingLeft: '2.5px'}}>
            <Form.Item label={props.t('pares.tel2')}>
              <Input name="tel2" placeholder={props.t('pares.tel2')} value={props.item.json.pare.tel2} disabled/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={props.t('families.adress')} required={true}>
          <Input name="adresses" placeholder={props.t('families.adress')} value={props.item.json.pare.adress} disabled/>
        </Form.Item>
        <Form.Item label={props.t('pares.email')} required={true}>
          <Input type="email" name="email" placeholder={props.t('pares.email')} value={props.item.json.pare.email} disabled/>
        </Form.Item>


        {/*PARE 2*/}

        <Typography.Title level={3}>{props.t('pares.dades_segon_contacte')}</Typography.Title>
        <Row>
          <Col span={10}>
            <Form.Item label={props.t('pares.nom')} required={true}>
              <Input name="nom" placeholder={props.t('pares.nom')} value={props.item.json.pare2.nom} disabled/>
            </Form.Item>
          </Col>
          <Col span={14} style={{paddingLeft: '5px'}}>
            <Form.Item label={props.t('pares.cognoms')} required={true}>
              <Input name="cognoms" placeholder={props.t('pares.cognoms')} value={props.item.json.pare2.cognoms} disabled/>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{paddingRight: '2.5px'}}>
            <Form.Item label={props.t('pares.tel1')} required={true}>
              <Input name="tel1" placeholder={props.t('pares.tel1')} value={props.item.json.pare2.tel1} disabled/>
            </Form.Item>
          </Col>
          <Col span={12} style={{paddingLeft: '2.5px'}}>
            <Form.Item label={props.t('pares.tel2')}>
              <Input name="tel2" placeholder={props.t('pares.tel2')} value={props.item.json.pare2.tel2} disabled/>
            </Form.Item>
          </Col>
        </Row>

        {comprat.length > 0 ? comprat : <Row align="center"><SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /></Row>}



    </Form>
    </Row>
    <Row>
      <Button loading={enviandoEmail} type="primary" icon={<SendOutlined />} onClick={(e)=>sendEmail()}>Send Email</Button>
    </Row>


      </>
  );

}

export default translate(ComandesSummerExterns);
