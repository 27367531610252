import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Select, Button, Input, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../helpers';

const entities = new AllHtmlEntities();


function Edit(props){

  const id = props.item.id;
  const [nom, setNom] = useState(entities.decode(props.item.nom));
  const [id_etapa, setId_etapa] = useState(props.item.id_etapa);

  const [etapes, setEtapes] = useState([]);

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [errorNom, setErrorNom] = useState('');
  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');

  const nou = (props.item.id===0);

  const buscarDades = async () => {
    setIsLoading(true);

    const et = await Api.get('/etapes');
    if(et.result.success===1){
      const llista_et = et.data.etapes.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setEtapes(llista_et);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);

  const nomChange = (e) =>{
    const n = e.target.value.trim();
    setNom(n);
    if(n.length>0){
      setErrorNom('');
    }
  }

  const selectChange = (value) =>{
    setId_etapa(value);
    if(value!==0){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }

  const submitForm = async (values) => {      

      let correcte = true;

      if(nom.length === 0){
        setErrorNom('error');
        correcte = false;
      }

      if(id_etapa === 0){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        nom: entities.encode(nom),
        id_etapa: id_etapa,
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/cicles' : '/cicles/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormCicles: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Cicles guardar: ', error);
      }

  }


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {

    return(
      <div style={{padding:'10px 24px 35px 0px'}}>
      <Form onFinish={submitForm} style={{marginTop:'35px'}}
        layout="horizontal"
        labelCol={{span:2}}
        wrapperCol={{span:9}}
        colon={true}
        autoComplete="off"
      >

          <Form.Item
            validateStatus={errorNom}
            label={props.t('cicles.nom')}
            required={true}
          >
              <Input name="nom" placeholder={props.t('cicles.nom')} onChange={nomChange} defaultValue={nom} autoFocus="focus"/>
          </Form.Item>

          <Form.Item
            validateStatus={errorSelect}
            help={errorSelectText}
            label={props.t('cicles.etapa')}
            required={true}
          >
            <Select defaultValue={id_etapa === 0 ? "" : id_etapa} onChange={selectChange}>
              {etapes}
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 2, span: 16 }} name="botonets">
            <Row type="flex" justify="start">
              <Col>
                <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export default translate(Edit);
