import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Button, Select, Input, InputNumber, Modal, Tooltip, Switch, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../helpers';
import Talles from '../../pages/botiga/Talles';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id] = useState(props.item.id);
  const [id_producte] = useState(props.item.id_producte);
  const [visible, setVisible] = useState(props.item.visible);
  const [id_talla, setId_talla] = useState(props.item.id_talla || 0);
  const [ref, setRef] = useState(props.item.ref);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [text_ca, setText_ca] = useState(entities.decode(props.item.text['ca']));
  const [text_en, setText_en] = useState(entities.decode(props.item.text['en']));
  const [text_es, setText_es] = useState(entities.decode(props.item.text['es']));
  const [preu, setPreu] = useState(props.item.preu);

  const [llistaTalles, setLlistaTalles] = useState([]);
  const [selectTalles, setSelectTalles] = useState([]);
  const [modalVisibleTalles, setModalVisibleTalles] = useState(false);
  const [actualizarTalles, setActualizarTalles] = useState(0);

  const [guardant, setGuardant] = useState(false);

  const [errorOrdre, setErrorOrdre] = useState('');
  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');

  /*const [errorText_ca, setErrorText_ca] = useState('');
  const [errorText_en, setErrorText_en] = useState('');
  const [errorText_es, setErrorText_es] = useState('');*/


  const nou = (id===0);

  const buscarDades = async () => {
    const tall = await Api.get('/botiga/talles');
    if(tall.result.success===1){
      const llista_tall = tall.data.talles.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.text[props.t('idioma')])}
              </Select.Option>
            );
      });
      setSelectTalles(llista_tall);

      //Creo un array nou amb el id com a index
      const indexed = tall.data.talles.reduce((acumulat, item) => ({
        ...acumulat,
        [item.id]: item,
      }),{});

      setLlistaTalles(indexed);

    }
  }

  useEffect(() => {
    buscarDades();
  },[actualizarTalles]);

  const selectTallaChange = (value) =>{
    const item = llistaTalles[value];
    setId_talla(value);
    //console.log(llistaTalles, value, item);
    setText_ca(entities.decode(item.text['ca']));
    setText_en(entities.decode(item.text['en']));
    setText_es(entities.decode(item.text['es']));
    if(value!==0){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }


  const ordreChange = (value) =>{
    setOrdre(value);
    if(Number.isInteger(value)){
      setErrorOrdre('');
    }
  }
  const refChange = (e) =>{
    const n = e.target.value.trim();
    setRef(n);
  }

  const tancarModalTalles = e =>{
    setActualizarTalles(actualizarTalles+1);
    setModalVisibleTalles(false);
  }
  /*
  const text_caChange = (e) =>{
    const n = e.target.value.trim();
    setText_ca(n);
    if(n.length>0){
      setErrorText_ca('');
    }
  }
  const text_enChange = (e) =>{
    const n = e.target.value.trim();
    setText_en(n);
    if(n.length>0){
      setErrorText_en('');
    }
  }
  const text_esChange = (e) =>{
    const n = e.target.value.trim();
    setText_es(n);
    if(n.length>0){
      setErrorText_es('');
    }
  }
*/

  const submitForm = async (values) => {

      let correcte = true;

      if(!Number.isInteger(ordre)){
        setErrorOrdre('error');
        correcte = false;
      }
      if(id_talla === 0){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }
      /*
      if(text_ca.length === 0){
        setErrorText_ca('error');
        correcte = false;
      }
      if(text_en.length === 0){
        setErrorText_en('error');
        correcte = false;
      }
      if(text_es.length === 0){
        setErrorText_es('error');
        correcte = false;
      }
      */

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        visible: visible ? 1 : 0,
        id_producte,
        id_talla,
        ref,
        ordre,
        text_ca: entities.encode(text_ca),
        text_en: entities.encode(text_en),
        text_es: entities.encode(text_es),
        preu
      }
      //console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/botiga/propietat' : '/botiga/propietat/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();

        }else{
          setGuardant(false);
          console.error('Error: submit Form BotigaPropietats: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: BotigaPropietats guardar: ', error);
      }

  }


  return (
    <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="horizontal"
      labelCol={{span:2}}
      wrapperCol={{span:7}}
      colon={true}
      autoComplete="off"
    >
    <Form.Item style={{marginBottom: 0}}
      label={props.t('generic.visible')}
    >
      <Switch checkedChildren={props.t('generic.visible')} unCheckedChildren={props.t('generic.no_visible')} onChange={setVisible} defaultChecked={visible} style={{marginLeft: '5px'}}/>
    </Form.Item>
      <Form.Item
        validateStatus={errorOrdre}
        label={props.t('transport.ordre')}
        required={true}
      >
          <InputNumber size="small" name="ordre" onChange={ordreChange} defaultValue={ordre} min={0} step={1} />
      </Form.Item>

      <Form.Item
        label={props.t('botiga.ref')}
      >
        <Input size="small" name="ref" placeholder={props.t('botiga.ref')} onChange={(e) => setRef(e.target.value.trim())} defaultValue={ref}/>
      </Form.Item>

      <Form.Item
        required={true}
        validateStatus={errorSelect}
        help={errorSelectText}
        label={props.t('botiga_propietats.talla')}
      >
      <Row gutter={10}>
      <Col span={19}>
        <Select defaultValue={id_talla===0 ? "" : id_talla} onChange={(value) => selectTallaChange(value)}>
          {selectTalles}
        </Select>
      </Col>
      <Col span={5}>
        <Tooltip placement="right" title={props.t('botiga_propietats.editar_talles_desc')}>
          <Button type="link" onClick={() => setModalVisibleTalles(true)}>{props.t('botiga_propietats.editar_talles')}</Button>
        </Tooltip>
      </Col>
      </Row>
      </Form.Item>


      {/*<Form.Item
        validateStatus={errorText_ca}
        label={props.t('botiga_propietats.text')+' CA'}
        required={true}
      >
        <Input size="small" name="text_ca" placeholder={props.t('botiga_propietats.text')} onChange={text_caChange} value={text_ca}/>
      </Form.Item>
      <Form.Item
        validateStatus={errorText_en}
        label={props.t('botiga_propietats.text')+' EN'}
        required={true}
      >
        <Input size="small" name="text_en" placeholder={props.t('botiga_propietats.text')} onChange={text_enChange} value={text_en}/>
      </Form.Item>
      <Form.Item
        validateStatus={errorText_es}
        label={props.t('botiga_propietats.text')+' ES'}
        required={true}
      >
        <Input size="small" name="text_es" placeholder={props.t('botiga_propietats.text')} onChange={text_esChange} value={text_es}/>
      </Form.Item>*/}

      <Form.Item label={props.t('botiga.preu')}>
          <InputNumber size="small" name="preu" onChange={setPreu} defaultValue={preu}
          min={0.0} step={1.0}
          formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/€\s?|(,*)/g, '')}
          />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form.Item>
      </Form>
      <Modal
          key="talles"
          //title={props.t('botiga.BotigaTalles')}
          visible={modalVisibleTalles}
          footer={null}
          onCancel={tancarModalTalles}
          width={700}
          style={{top:20}}
          //bodyStyle={{ height: "70vh", overflow: "auto" }}
          destroyOnClose={true}
          forceRender={true}
        >
          <Talles modal={true}/>
        </Modal>
    </div>
  );
}
export default translate(Edit);
