import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, Checkbox, Space, Select, Form, DatePicker, message } from 'antd';
import { MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

const radioStyle = {
      width:'100%',
      marginTop: '8px',
    };
function Transport(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);

  const [radiosAnada, setRadiosAnada] = useState();
  const [radiosTornada, setRadiosTornada] = useState();
  const [anadaTriat, setAnadaTriat] = useState(null);
  const [tornadaTriat, setTornadaTriat] = useState(null);
  const [anadaParada, setAnadaParada] = useState(null);
  const [tornadaParada, setTornadaParada] = useState(null);

  const [diesTriats, setDiesTriats] = useState([]);
  const [numDies, setNumDies] = useState(0);

  const ids_fills = props.ids_fills ? props.ids_fills : [props.id_alumne];

  const [llistaParades, setLlistaParades] = useState([]);

  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [errorAnadaParada, setErrorAnadaParada] = useState('');
  const [errorTornadaParada, setErrorTornadaParada] = useState('');

  const [hiHaDescompte, setHiHaDescompte] = useState(false);
  const [descompte, setDescompte] = useState(0);
  const [descompte_2on, setDescompte_2on] = useState(0);
  const [descompte_3rs, setDescompte_3rs] = useState(0);
  const [descompte_missatge, setDescompte_missatge] = useState('');

  const [total, setTotal] = useState(0);
  const [aplicarDescompte, setAplicarDescompte] = useState(false);
  const [numFills, setNumFills] = useState(1);


  useEffect(() => {
    buscarDades();
  },[]);

  useEffect(() => {
    calcularTotal();
  },[descompte, aplicarDescompte, anadaTriat, tornadaTriat, numFills, numDies]);

  useEffect(() => {
    if(hiHaDescompte){
      const da = (anadaTriat ? anadaTriat.descompte_x_anada_i_tornada : 0);
      const dt = (tornadaTriat ? tornadaTriat.descompte_x_anada_i_tornada : 0);
      setDescompte(Math.max(da,dt));
      setAplicarDescompte(anadaTriat && tornadaTriat);
    }
  },[anadaTriat, tornadaTriat]);

const buscarDades = async () =>{
  setIsLoading(true);

  const transp = await Api.get('/transport/parades');
  if(transp.result.success===1){
    let ra = [];
    let rt = [];
    transp.data.transport.map((item) =>{
      //<Radio style={radioStyle} value={item}>{entities.decode(item.nom[props.t('idioma')])}</Radio>
      if(props.puntual===false){
        if(item.descompte_x_anada_i_tornada>0){
          setHiHaDescompte(true);
          setDescompte(item.descompte_x_anada_i_tornada);
        }
        setDescompte_2on(item.descompte_2on);
        setDescompte_3rs(item.descompte_3rs);
        setDescompte_missatge(item.descompte_missatge);
      }

      let parada = (
        <Radio.Button style={radioStyle} value={item} key={item.id}>
          <table width="100%">
            <tr>
              <td style={{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                {entities.decode(item.nom[props.t('idioma')])}
              </td>
              <td width="30" align="right">
                {props.puntual ? item.preu_puntual : item.preu_mensual}€
              </td>
            </tr>
          </table>
        </Radio.Button>
      );

      if(item.sentit === 'anada'){
        ra.push(parada);
      }else{
        rt.push(parada);
      }
    });
    setRadiosAnada(ra);
    setRadiosTornada(rt);
  }


setIsLoading(false);
}



  /*const buscarDadesOLD = async () => {
    setIsLoading(true);

    const transp_anada = await Api.get('/transport/sentit/anada/parades');
    if(transp_anada.result.success===1){
      const ra = transp_anada.data.transport.map((item) =>{
        //<Radio style={radioStyle} value={item}>{entities.decode(item.nom[props.t('idioma')])}</Radio>
        if(props.puntual===false){
          if(item.descompte_x_anada_i_tornada>0){
            setHiHaDescompte(true);
            setDescompte(item.descompte_x_anada_i_tornada);
          }
        }

        //<Col flex="auto"><Typography.Paragraph ellipsis>{entities.decode(item.nom[props.t('idioma')])}</Typography.Paragraph></Col>
        //<Col flex="35px">{props.puntual ? item.preu_puntual : item.preu_mensual}€</Col>

        return(
          <Radio.Button style={radioStyle} value={item} key={item.id}>
            <table width="100%">
              <tr>
                <td style={{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                  {entities.decode(item.nom[props.t('idioma')])}
                </td>
                <td width="30" align="right">
                  {props.puntual ? item.preu_puntual : item.preu_mensual}€
                </td>
              </tr>
            </table>
          </Radio.Button>
        );
      });
      setRadiosAnada(ra);

    }
    const transp_tornada = await Api.get('/transport/sentit/tornada/parades');
    if(transp_tornada.result.success===1){
      const rt = transp_tornada.data.transport.map((item) =>{
        if(props.puntual===false){
          if(item.descompte_x_anada_i_tornada>0){
            setHiHaDescompte(true);
            setDescompte(item.descompte_x_anada_i_tornada);
          }
        }
        return(
          <Radio.Button style={radioStyle} value={item} key={item.id}>
            <table width="100%">
              <tr>
                <td style={{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                  {entities.decode(item.nom[props.t('idioma')])}
                </td>
                <td width="30" align="right">
                  {props.puntual ? item.preu_puntual : item.preu_mensual}€
                </td>
              </tr>
            </table>
          </Radio.Button>
        );
      });
      setRadiosTornada(rt);
    }

    setIsLoading(false);
  }*/

const calcularTotal = () => {
  if(props.puntual){
    calcularPuntual();
  }else{
    calcularMensual();
  }
}

const calcularPuntual = () =>{
  let subtotal = 0;
  if(anadaTriat){
    subtotal = subtotal + (anadaTriat.preu_puntual * numFills * numDies);
  }
  if(tornadaTriat){
    subtotal = subtotal + (tornadaTriat.preu_puntual * numFills * numDies);
  }
  setTotal(subtotal.toFixed(2));
}



/*const calcularMensual = () =>{
  let subtotal = 0;
  if(anadaTriat){
    subtotal = subtotal + (anadaTriat.preu_mensual * numFills);
  }
  if(tornadaTriat){
    subtotal = subtotal + (tornadaTriat.preu_mensual * numFills);
  }
  if(aplicarDescompte && descompte > 0 ){
    subtotal = (subtotal - (subtotal * descompte / 100));
  }
  setTotal(subtotal.toFixed(2));
}*/
const calcularMensual = () =>{
  let totalPrice = 0;
  let price1rst = 0;
  let importDescompte = 0;

  if(aplicarDescompte ){
    //Anada i tornada
    if(descompte > 0){
      importDescompte = (anadaTriat.preu_mensual + tornadaTriat.preu_mensual ) * descompte / 100;
    }
    price1rst = (anadaTriat.preu_mensual + tornadaTriat.preu_mensual ) - importDescompte;
    totalPrice = price1rst;

    if(numFills > 1){
      const price2nd = price1rst - ((price1rst * descompte_2on) / 100);
      totalPrice = totalPrice + price2nd;
    }
    if(numFills > 2) {
      const price3rs = price1rst - ((price1rst * descompte_3rs) / 100);
      totalPrice = totalPrice + (price3rs * (numFills - 2));
    }

  }else{
    //Nomes anada o tornada
    if(anadaTriat){
      price1rst = anadaTriat.preu_mensual;
    }
    if(tornadaTriat){
      price1rst = tornadaTriat.preu_mensual;
    }
    totalPrice = price1rst;
    if(numFills > 1){
      const price2nd = price1rst - ((price1rst * descompte_2on) / 100);
      totalPrice = totalPrice + price2nd;
    }
    if(numFills > 2) {
      const price3rs = price1rst - ((price1rst * descompte_3rs) / 100);
      totalPrice = totalPrice + (price3rs * (numFills - 2));
    }

  }





  setTotal(totalPrice.toFixed(2));
}



  const submitForm = async (valors) => {



    if(!validat){
      return false;
    }

    let correcte = true;

    if(anadaTriat && !anadaParada){
      setErrorAnadaParada('error');
      correcte = false;
    }
    if(tornadaTriat && !tornadaParada){
      setErrorTornadaParada('error');
      correcte = false;
    }

    let comanda = [];
    if(anadaTriat){
      comanda.push({id_transport: anadaTriat.id, id_parada: anadaParada});
    }
    if(tornadaTriat){
      comanda.push({id_transport: tornadaTriat.id, id_parada: tornadaParada});
    }

    if(props.puntual === true){
      if(diesTriats.length <= 0){
        message.config({top: 50});
        message.error({content: 'Error: '+ props.t('generic.tria_dies'), duration:3});
        correcte = false;
      }
    }

    if(!correcte){
      return false;
    }


  setGuardant(true);
    let dades = {};

   if(props.puntual === true){
     dades = {
       id_alumnes: numFills === 1 ? [props.id_alumne] : ids_fills,
       id_pare: perfilUsuari.account.id,
       tipus: 'puntual',
       puntual_dates: diesTriats.map((item) =>item.format(moment.HTML5_FMT.DATE)),
       comanda
     }
   }else{
     dades = {
       id_alumnes:  numFills === 1 ? [props.id_alumne] : ids_fills,
       id_pare: perfilUsuari.account.id,
       tipus: 'mensual',
       comanda
     }
   }


    //console.log(JSON.stringify(dades));

    try{
      const resposta = await Api.myFetch('/transport/comanda', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Transport: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Transport guardar: ', error);
    }

  }


  const onChangeRadioAnada = (item) => {
    setAnadaParada(null);
    setAnadaTriat(item);
  }
  const onChangeRadioTornada = (item) => {
    setTornadaParada(null);
    setTornadaTriat(item);
  }

  const onChangeTotsFills = (tots) =>{
    setNumFills(tots ? ids_fills.length : 1);
  }

  const onChangeAnadaParada = (value) =>{
    setAnadaParada(value);
    if(value){
      setErrorAnadaParada('');
    }
  }
  const onChangeTornadaParada = (value) =>{
    setTornadaParada(value);
    if(value){
      setErrorTornadaParada('');
    }
  }

  const onDiaTriat = (date, dateString) =>{
    const trobat = diesTriats.filter((element, index, arr) => element.format(moment.HTML5_FMT.DATE) === date.format(moment.HTML5_FMT.DATE));
    if(trobat.length === 0){
      const dies = [...diesTriats, date];
      setDiesTriats(dies);
      setNumDies(dies.length);
    }

    //console.log(dateString)
  }
  const onEliminaDia = (item) => {


    const filtrat = diesTriats.filter((element, index, arr) => element !== item);
    setDiesTriats(filtrat);
    setNumDies(filtrat.length);
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';


  return(
    <>
    { !radiosAnada ? null :
    <>
      <Row justify="center" style={{marginTop:'10px'}}>
        <Typography.Title level={4}>{props.t('transport.titol_anada')}</Typography.Title>
      </Row>

      <Row justify="left">
        <Radio.Group style={{margin: '10px', width: '100%'}} onChange={(e) => onChangeRadioAnada(e.target.value)} optionType="button" buttonStyle="solid">
          {radiosAnada}
        </Radio.Group>
      </Row>

      <Row justify="center">
        {!anadaTriat ? null :
          <Form.Item required={true} validateStatus={errorAnadaParada} style={{marginTop: 0, paddingRight: '20px', width: '100%'}}>
            <Select placeholder={props.t('transport.tria_parada')} style={{margin: '10px', width: '100%'}} onChange={(value) => onChangeAnadaParada(value)} value={anadaParada}>
            {
              anadaTriat.parades.map((item) =>{
                    return(
                      <Select.Option key={item.id} value={item.id}>
                        {item.hora+':'+String(item.minut).padStart(2,'0')+' - '+entities.decode(item.nom[props.t('idioma')])}
                      </Select.Option>
                    );
              })
            }
            </Select>
          </Form.Item>
        }
      </Row>
    </>
    }
    { !radiosTornada ? null :
    <>
      <Row justify="center" style={{marginTop:'10px'}}>
        <Typography.Title level={4}>{props.t('transport.titol_tornada')}</Typography.Title>
      </Row>

      <Row justify="left">
        <Radio.Group style={{margin: '10px', width: '100%'}} onChange={(e) => onChangeRadioTornada(e.target.value)} optionType="button" buttonStyle="solid">
          {radiosTornada}
        </Radio.Group>
      </Row>

      <Row justify="center">
        {!tornadaTriat ? null :
          <Form.Item required={true} validateStatus={errorTornadaParada} style={{marginTop: 0, paddingRight: '20px', width: '100%'}}>
            <Select placeholder={props.t('transport.tria_parada')} style={{margin: '10px', width: '100%'}} onChange={(value) => onChangeTornadaParada(value)} value={tornadaParada}>
            {
              tornadaTriat.parades.map((item) =>{
                    return(
                      <Select.Option key={item.id} value={item.id}>
                        {item.hora+':'+String(item.minut).padStart(2,'0')+' - '+entities.decode(item.nom[props.t('idioma')])}
                      </Select.Option>
                    );
              })
            }
            </Select>
          </Form.Item>
        }
      </Row>
    </>
  }

{props.puntual === false ? null :
  <>
    <Row justify="center" style={{marginTop:'10px'}}>
      <DatePicker
        format={dateFormat}
        locale={localeDatePicker}
        placeholder={'Tria dia o dies'}
        value={null}
        onChange={onDiaTriat}/>
    </Row>
    <Row justify="center" style={{marginTop:'10px'}}>
    <Col span={8}>
    {diesTriats.map((item) =>{
      return(
        <Row justify="space-between" key={item.format(dateFormat)}>
          <Col>{item.format(dateFormat)}</Col>
          <Col><DeleteOutlined onClick={()=>onEliminaDia(item)}/></Col>
        </Row>
      );
    })}
    </Col>
    </Row>
  </>
}
    {/*<Row justify="center" style={{marginTop:'10px'}}>
      <Space direction="vertical" align="center">
        {!hiHaDescompte ? null : <span  style={{color: 'red'}}>*{props.t('transport.descompte')}&nbsp;{descompte}%</span>}
        <Space><b style={{fontSize: '18px'}}>Total:</b><span style={{color: 'red', fontSize: '18px'}}>{total}€</span></Space>
        {!aplicarDescompte ? null : <span  style={{color: 'red'}}>({props.t('SummerCamp.desc_inclos1')}{descompte}%{props.t('SummerCamp.desc_inclos2')})</span>}
      </Space>
    </Row>*/}
    <Row justify="center" style={{marginTop:'10px'}}>
      <Space direction="vertical" align="center">
        {!hiHaDescompte ? null : <span  style={{color: 'red'}}>*{descompte_missatge[props.t('idioma')]}</span>}
        <Space><b style={{fontSize: '18px'}}>Total:</b><span style={{color: 'red', fontSize: '18px'}}>{total}€</span></Space>
      </Space>
    </Row>


    {ids_fills.length <= 1 ? null :
      <Row justify="center" style={{marginTop:'10px'}}>
        <Checkbox onChange={(e) => onChangeTotsFills(e.target.checked)}>{props.t('transport.tots_fills')}</Checkbox>
      </Row>
    }
    <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
      <AutoritzarContrasenya resposta={setValidat}/>
    </Row>
    <Row justify="center" style={{marginTop: '10px'}}>
      <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
        {props.t('botiga.pagar_autoritzar')}
      </Button>
    </Row>
    </>
  );
}
export default translate(Transport);
