import React, { useContext } from 'react';
import { PoweroffOutlined, BellOutlined, BellFilled, MenuUnfoldOutlined, MenuFoldOutlined, MenuOutlined} from '@ant-design/icons';
import { Tooltip, Button, Row, Col, Badge } from 'antd';
import { translate } from 'react-multi-lang';

import { PerfilUsuariContext } from "../context/perfil-usuari";
import { MenuTriatContext } from "../context/menu-triat";

function MenuSuperior(props){
    const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
    const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

    let logo_menu = "";

    switch (global.app_id) {
      case "british":
          logo_menu=require('../images/logo_h_transparent.png');
        break;
      case "aquamora":
          logo_menu=require('../images/logo_aqua.png');
        break;
      case "erp":
          //logo_menu=require('../images/logo.png');
          logo_menu=require('../images/logo_h_t_ajustat.png');
        break;
      default:
        logo_menu =require('../images/BackData.png');
    }



    return (

      <Row justify="space-between">
        <Col style={{textAlign:'left', paddingLeft:'1rem'}}>
          <div className="BotoBack" onClick={(e) => setMenuTriat("Dashboard")}>
            <img src={logo_menu} alt="Logo" width="210"/>
          </div>
        </Col>
        <Col onClick={()=>props.toggleCollapsed()} style={{fontSize: '1.5rem', color: 'white', paddingRight: '1rem', paddingBottom: 16}}>
          <MenuOutlined />
        </Col>
      </Row>
    );

}

export default translate(MenuSuperior);
