import React from 'react';
import { translate } from 'react-multi-lang';
import { Table, Popover } from 'antd';
import { BulbFilled } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/MealTimeReportFrases';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

const limitTextMostrat = 50;

function MealTimeReportFrases(props) {

  const buscarDades = async () => {
    const tmp = await Api.get('/menjador/report/frases');
    if(tmp.result.success===1){
      return tmp.data.frases;
    }
  }
  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch(`/menjador/report/frases/${id}`, 'DELETE');
    if(fam.result.success===1){

    }
  }


  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menjador.report.titol') + " - " + props.t('menjador.report.frases')} style={{marginTop:'1rem', marginLeft:'3rem'}}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          icon_creats={BulbFilled}
          txt_creats={props.t('menjador.creados')}
          txt_nou={props.t('menjador.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          duplica={true}
          itemBuit={InitDades.menjador_report_frases()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          sorter={(a, b) =>  a.id - b.id}
          />
          <Column
          title={props.t('botiga_propietats.ordre')}
          key="ordre"
          render={(text,record) =>(
            <span>{record.ordre}</span>
          )}
          sorter={(a, b) =>  a.ordre - b.ordre}
          />
          <Column
          title={props.t('menjador.report.semafor_titol')}
          key="semafor"
          render={(text,record) =>(
            <BulbFilled style={{color: `${props.t(`menjador.report.semafor.${record.semafor}`)}`}}/>
          )}
          sorter={(a, b) =>  a.semafor.localeCompare(b.semafor)}
          />
          <Column
          title={props.t('menjador.tipus')}
          key="tipus"
          render={(text,record) =>(
            <span>{props.t(`menjador.report.tipus.${record.tipus}`)}</span>
          )}
          sorter={(a, b) =>  a.tipus.localeCompare(b.tipus)}
          />

          <Column
          title={props.t('botiga_propietats.text')+' CA'}
          key="text_ca"
          render={(text,record) =>{
            const puntets = record.text['ca'].length > limitTextMostrat ? "..." : "";
            return (
            <Popover style={{width: '800px'}} content={entities.decode(record.text['ca'])} title={props.t('botiga_propietats.text')+' CA'}>
              {entities.decode(record.text['ca']).slice(0,limitTextMostrat)+puntets}
            </Popover>
            )}
          }
          />
          <Column
          title={props.t('botiga_propietats.text')+' EN'}
          key="text_en"
          render={(text,record) =>{
            const puntets = record.text['en'].length > limitTextMostrat ? "..." : "";
            return (
            <Popover content={entities.decode(record.text['en'])} title={props.t('botiga_propietats.text')+' EN'}>
              {entities.decode(record.text['en']).slice(0,limitTextMostrat)+"..."}
            </Popover>
            )}
          }
          />
          <Column
          title={props.t('botiga_propietats.text')+' ES'}
          key="text_es"
          render={(text,record) =>{
            const puntets = record.text['es'].length > limitTextMostrat ? "..." : "";
            return (
            <Popover content={entities.decode(record.text['es'])} title={props.t('botiga_propietats.text')+' ES'}>
              {entities.decode(record.text['es']).slice(0,limitTextMostrat)+"..."}
            </Popover>
            )}
          }
          />
        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(MealTimeReportFrases);
