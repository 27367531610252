import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined, CloseOutlined, PauseOutlined, MinusOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, Checkbox, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';
import TriaTipusIngres from '../../../TriaTipusIngres';
import TextAreaCount from '../../../TextAreaCount';
import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function FacturacioImportsRecurrentsEdit(props){

  const id = props.item.id;
  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  //id_curs_escolar
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [mesos_facturacio, setMesos_facturacio] = useState(props.item.mesos_facturacio);
  const [tipus, setTipus] = useState(props.item.tipus || '');
  const [concepte, setConcepte] = useState(entities.decode(props.item.concepte ?? ''));
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [preu, setPreu] = useState(props.item.preu);
  const [unitats, setUnitats ] = useState(props.item.unitats);
  const [subtotalTemp, setSubtotalTemp] = useState(props.item.preu * props.item.unitats);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);
  const [observacions, setObservacions ] = useState(entities.decode(props.item.observacions ?? ''));

  const [familiesOptions, setFamiliesOptions] = useState([]);
  const [alumnesOptions, setAlumnesOptions] = useState([]);


  const [errorTipus, setErrorTipus] = useState('');
  const [errorTipusText, setErrorTipusText] = useState('');
  const [errorConcepte, setErrorConcepte] = useState('');
  const [errorId_Familia, setErrorId_Familia] = useState('');
  const [errorId_FamiliaText, setErrorId_FamiliaText] = useState('');
  const [errorId_Alumne, setErrorId_Alumne] = useState('');
  const [errorId_AlumneText, setErrorId_AlumneText] = useState('');
  const [errorMesos_facturacio, setErrorMesos_facturacio] = useState('');
  const [errorMesos_facturacioText, setErrorMesos_facturacioText] = useState('');

  const [guardant, setGuardant] = useState(false);

  const nou = (props.item.id===0);


  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  useEffect(() => {
    if(id_familia > 0){
      buscarDadesFills();
    }
    buscarDadesFamilia();
  },[]);

  useEffect(() => {
    buscarDadesFills();
  },[id_familia]);

  useEffect(() => {
    if(concepte.length > 0){
      setErrorConcepte('');
    }
  },[concepte]);


  const buscarDadesFills = async () => {
    if(id_familia > 0){
      const fills = await Api.get(`/alumnes/tots/familia/${id_familia}`);
      if(fills.result.success===1){
        const llista_fills =fills.data.alumnes.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setAlumnesOptions(llista_fills);
      }
    }
  }

  const buscarDadesFamilia = async () => {
    const fam = await Api.get('/families');
    if(fam.result.success===1){
      const llista_fam = fam.data.families.map((item) =>{

          if(item.estat === 'baixa'){
            return(
              <Select.Option key={item.id} value={item.id} style={{color:'red'}}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2) + ' (Baixa)'}
              </Select.Option>
            );

          }else{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );


          }

      });
      setFamiliesOptions(llista_fam);
    }
  }

  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setErrorId_Alumne('');
      setErrorId_AlumneText('');
    }
  }

  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_Familia('');
      setErrorId_FamiliaText('');
      setId_alumne(0);
    }
  }

  const calcularSubtotal = (_preu, _unitats, _descompte) =>{
    let tot = 0;
    if(_preu !== 0.0){
      if(_descompte!==0.0){
        const sub = _preu * _unitats;
        tot =  sub - (sub * _descompte / 100);
      }else{
        tot = _preu * _unitats;
      }
    }
    return tot;
  }


const onChangePreu = (_preu) =>{
  setPreu(_preu);
  setSubtotalTemp(calcularSubtotal(_preu, unitats, 0.0));
  setSubtotal(calcularSubtotal(_preu, unitats, descompte));
}
const onChangeUnitats = (_unitats) =>{
  setUnitats(_unitats);
  setSubtotalTemp(calcularSubtotal(preu, _unitats, 0.0));
  setSubtotal(calcularSubtotal(preu, _unitats, descompte));
}

const onChangeDescompte = (desc) =>{
  setDescompte(desc);
  setSubtotal(calcularSubtotal(preu, unitats, desc));
}


const submitForm = async (values) => {

  let correcte = true;

  if(id_familia===0){
    setErrorId_Familia('error');
    setErrorId_FamiliaText(props.t('generic.tria'));
    correcte = false;
  }
  if(id_alumne===0){
    setErrorId_Alumne('error');
    setErrorId_AlumneText(props.t('generic.tria'));
    correcte = false;
  }
  if(tipus === ''){
    setErrorTipus('error');
    setErrorTipusText(props.t('generic.tria'));
    correcte = false;
  }
  if(!concepte){
    setErrorConcepte('error');
    correcte = false;
  }
  if(mesos_facturacio.length < 1){
    setErrorMesos_facturacio('error');
    setErrorMesos_facturacioText(props.t('generic.tria_varis'));
    correcte = false;
  }

  if(!correcte){
    return false;
  }


  setGuardant(true);
  let dades = {
    id,
    id_curs_escolar,
    id_alumne,
    mesos_facturacio,
    tipus,
    concepte: entities.encode(concepte),
    descompte,
    preu,
    unitats,
    subtotal,
    observacions: entities.encode(observacions)
  }

  let method = nou ? 'POST' : 'PUT';
  let url_api = nou ? '/pressupost/recurrents' : `/pressupost/recurrents/${id}`;

  try{
    const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
    if(resposta.result.success === 1){
      setGuardant(false);
      message.success(props.t('generic.OK_saved'), 5);
      props.callback();
    }else{
      setGuardant(false);
      console.error('Error: submit FacturacioImportsRecurrentsEdit: ',resposta);
      message.config({top: 50});
      message.open({content: resposta.data.message, duration:10});
    }
  }catch(error){
    setGuardant(false);
    message.config({top: 50});
    message.open({content: "Error saving", duration:10});
    console.error('Error: FacturacioImportsRecurrentsEdit: ', error);
  }

}

const optionsMesos = [
  { label: props.t('mesos.8'), value: 8 },
  { label: props.t('mesos.9'), value: 9 },
  { label: props.t('mesos.10'), value: 10 },
  { label: props.t('mesos.11'), value: 11 },
  { label: props.t('mesos.12'), value: 12 },
  { label: props.t('mesos.1'), value: 1 },
  { label: props.t('mesos.2'), value: 2 },
  { label: props.t('mesos.3'), value: 3 },
  { label: props.t('mesos.4'), value: 4 },
  { label: props.t('mesos.5'), value: 5 },
  { label: props.t('mesos.6'), value: 6 },
  { label: props.t('mesos.7'), value: 7 },
];

const onChangeMesos = (checkedValues) => {
  console.log('mesos checkedValues: ', checkedValues);
  setMesos_facturacio(checkedValues);
  if(checkedValues){
    setErrorMesos_facturacio('');
    setErrorMesos_facturacioText('');
  }
}

return(
  <Form onFinish={submitForm}
    layout="vertical"
    autoComplete="off"
  >


  <Row>
    <Col span={12} style={{paddingRight: '0.5rem'}}>
      <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_Familia} help={errorId_FamiliaText} style={{marginBottom: 0}}>
        <Select
          value={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {familiesOptions}
        </Select>
      </Form.Item>
  </Col>
  <Col span={12} style={{paddingLeft: '0.5rem'}}>
      {id_familia <=0 ? null :
          <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_Alumne} help={errorId_AlumneText} style={{marginBottom: 0}}>
            <Select
              value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
              showSearch
              filterOption={(input, option) =>
                HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
              }
            >
              {alumnesOptions}
            </Select>
          </Form.Item>
      }
    </Col>
  </Row>

    <Form.Item label={props.t('generic.tipus_ingres')} required={true} validateStatus={errorTipus} help={errorTipusText} style={{marginBottom: 0}}>
      <TriaTipusIngres defaultValue={tipus} onChange={setTipus} />
    </Form.Item>

    <Form.Item label={props.t('generic.concepte')} required={true} validateStatus={errorConcepte}>
      <Input name="concepte" placeholder={props.t('generic.concepte')}
        maxLength={254}
        onChange={(e) => setConcepte(e.target.value)}
        defaultValue={concepte}
        autoFocus="focus"/>
    </Form.Item>


    {/*<Form.Item label={props.t('generic.preu')}>
        <InputNumber name="preu" onChange={onChangePreu} defaultValue={preu}
        step={0.1}
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.unitats')}>
        <InputNumber name="unitats" onChange={onChangeUnitas} defaultValue={unitats} step={1.0}/>
    </Form.Item>
    <Form.Item label={props.t('generic.descompte')}>
        <InputNumber name="descompte" onChange={onChangeDescompte} defaultValue={descompte}
        min={-100} max={100} step={0.1}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.subtotal')}>
        <InputNumber name="subtotal" value={subtotal} disabled
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>*/}

    <Form.Item label={props.t('generic.mesos_facturacio')+':'} required={true} validateStatus={errorMesos_facturacio} help={errorMesos_facturacioText}>
      <Button size="small" style={{marginRight: 5}} onClick={()=>setMesos_facturacio([1,2,3,4,5,6,7,8,9,10,11,12])}>{props.t('generic.all')}</Button>
      <Button size="small" style={{marginBottom: 5}}onClick={()=>setMesos_facturacio([])}>{props.t('generic.none')}</Button>
      <br/>
      <Checkbox.Group options={optionsMesos} value={mesos_facturacio} onChange={onChangeMesos} />
    </Form.Item>

    <Row align="middle" justify="space-around">
      <Col>
        <Form.Item label={props.t('quotes.import')}>
            <InputNumber name="preu" onChange={onChangePreu} value={preu}
            step={1.0}
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <CloseOutlined />
      </Col>
      <Col>
        <Form.Item label={props.t('generic.unitats')}>
            <InputNumber name="unitats" onChange={onChangeUnitats} value={unitats} precision={0} min={0}/>
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <PauseOutlined rotate={90}/>
      </Col>

      <Col>
        <Form.Item label={props.t('generic.subtotal')}>
            <InputNumber name="subtotal" value={subtotalTemp} disabled
            step={1.0}
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <MinusOutlined />
      </Col>
      <Col>
        <Form.Item label={props.t('generic.descompte')}>
            <InputNumber name="descompte" onChange={onChangeDescompte} value={descompte}
            min={-100} max={100} step={0.1}
            formatter={value => `${value}%`}
            parser={value => value.replace('%', '')}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: 8}}>
        <PauseOutlined rotate={90}/>
      </Col>
      <Col>
        <Form.Item label={props.t('generic.total')}>
            <InputNumber name="total" value={subtotal} disabled
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>
      </Col>
    </Row>


      <Form.Item label={props.t('banc.observacions')}>
        <TextAreaCount autoSize={{ minRows: 2, maxRows: 25 }} maxLength={1024} name="observacions" placeholder={props.t('banc.observacions')} onChange={setObservacions} defaultValue={observacions}/>
      </Form.Item>

    <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
      <Col>
        <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
      </Col>
      <Col style={{marginLeft:'10px'}}>
        <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
      </Col>
    </Row>
  </Form>
);

}
export default translate(FacturacioImportsRecurrentsEdit);
