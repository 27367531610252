import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { DeleteOutlined, EditOutlined, CopyOutlined, SyncOutlined, TeamOutlined, FileAddOutlined } from '@ant-design/icons';
//import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Table, Button, Tooltip, Row, Col, Popconfirm } from 'antd';
import { Api } from '../helpers';
const { Column } = Table;


function LlistaEditableTalles(props){

  const [llista, setLlista] = useState([]);
  const [talles, setTalles] = useState([]);
  const [nextTalla, setNextTalla] = useState([]);
  const [itemActual, setItemActual] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [accio, setAccio] = useState('taula');
  const [refrescar, setRefrescar] = useState(0)

  useEffect(() => {
    buscarDadesRefresc();
  },[refrescar, props.refrescar]);

  const buscarDadesRefresc  = async () => {
    let l = await props.buscarDades();
    setLlista(l);
  }

  const buscarDades = async () => {
    setIsLoading(true);
    let l = await props.buscarDades();
    setLlista(l);

    const tall = await Api.get('/botiga/talles');
    if(tall.result.success===1){

      const indexed = tall.data.talles.reduce((acumulat, item) => ({
        ...acumulat,
        [item.id]: item,
      }),{});

      setTalles(indexed);
      let ntall = tall.data.talles.map((t) => {
        return t.id
      });

      const itall = [...ntall];
      ntall.push(0);
      ntall.shift();
      let nextt = [];
      itall.forEach((item, i) => {
        nextt[item] = ntall[i];
      });
      setNextTalla(nextt);

    }

    setIsLoading(false);
  }


  useEffect(() => {
    buscarDades();
  },[]);

  const showTaula = () =>{
    setLlista([]);
    buscarDades();
    setAccio('taula');
  }



  const showAdd = () =>{
    let item_buit = {...props.itemBuit};
    if(item_buit.hasOwnProperty('ordre')){
      const max_ordre = Math.max(...llista.map(item=>item.ordre));
      item_buit.ordre = max_ordre + 1;
    }
    setItemActual(item_buit);
    setAccio('afegir');
  }
  const showEdit = (item) =>{
    setItemActual(item);
    setAccio('editar');
  }

  const showClone = (item) =>{
    if(item.hasOwnProperty('id')){
      let item_clonar = {...item};
      item_clonar.id = 0;
      if(item_clonar.hasOwnProperty('ordre')){
        const max_ordre = Math.max(...llista.map(item=>item.ordre));
        item_clonar.ordre = max_ordre + 1;
      }

      setItemActual(item_clonar);
      setAccio('editar');
    }else{
      console.error('No puc clonar aquest objecte, no te camp id:', item);
    }
  }

  const showCloneNextSize = (item) =>{
    if(item.hasOwnProperty('id')){
      let item_clonar = {...item};
      item_clonar.id = 0;
      if(item_clonar.hasOwnProperty('ordre')){
        const max_ordre = Math.max(...llista.map(item=>item.ordre));
        item_clonar.ordre = max_ordre + 1;
      }

      item_clonar.id_talla = nextTalla[item_clonar.id_talla];
      item_clonar.text = talles[item_clonar.id_talla].text;

      setItemActual(item_clonar);
      setAccio('editar');
    }else{
      console.error('No puc clonar aquest objecte, no te camp id:', item);
    }
  }




  const submitDelete = async (id) => {
    const eliminat = await props.delete(id);
    if(eliminat){
      await buscarDades();
    }

  }





  if (isLoading) {
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }else{

    if(accio==='taula'){
      return <>
        <div style={{textAlign:'right', marginBottom:'25px'}}>
          <Row type="flex" justify="space-between">
            <Col>
              <div>
                { props.icon_creats
                  ? <props.icon_creats style={{color:'#C2C2C2'}}/>
                  : <TeamOutlined style={{color:'#C2C2C2'}}/>
                }
                <span style={{color:'#C2C2C2'}}>&nbsp;{typeof llista !== "undefined" ? llista.length : 0}&nbsp;{props.txt_creats}</span>
              </div>
            </Col>
            <Col>
              <Button type="primary" onClick={showAdd}>{props.txt_nou}</Button>
            </Col>
          </Row>
        </div>
        <Table
        scroll={props.scroll || null}  //scroll={{ x: 1500}}
        dataSource={llista}
        expandedRowRender={props.expandedRowRender || null}
        pagination={false}
        rowKey={props.rowKey || "id"}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {}, //onClick: (e) => showEdit(record), // click row
            onDoubleClick: (e) => showEdit(record), // double click row
            onContextMenu: (e) => {e.preventDefault(); showEdit(record);}, //event => {}, // right button click row
          };
        }}
        size={props.size || "small"}
        tableLayout="undefined"
        >
          {props.children}
          <Column
            key="actions"
            align="right"
            //width={props.duplica ? "110px" : "95px"}
            width="115px"
            render={(text,record) => (
              <Row type="flex" justify="end" id={"actions_"+record.id}>

                <Col span={6}>
                  <Tooltip placement="top" title={props.t('generic.clone')}>
                    <Button icon={<CopyOutlined />} onClick={(e) => showClone(record)} ghost="true" className="BotoTaula"/>
                  </Tooltip>
                </Col>
                {nextTalla[record.id_talla] === 0 ? null :
                <Col span={6}>
                  <Tooltip placement="top" title={props.t('botiga_propietats.clona_suma')}>
                    <Button icon={<FileAddOutlined />} onClick={(e) => showCloneNextSize(record)} ghost="true" className="BotoTaula"/>
                  </Tooltip>
                </Col>
                }
                <Col span={6}>
                  <Tooltip placement="top" title={props.t('generic.modify')}>
                    <Button icon={<EditOutlined />} onClick={(e) => showEdit(record)} ghost="true" className="BotoTaula"/>
                  </Tooltip>
                </Col>
                <Col span={6}>
                  <Tooltip placement="top" title={props.t('generic.delete')}>
                    <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>submitDelete(record.id)}>
                      <Button icon={<DeleteOutlined />} data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
                    </Popconfirm>
                  </Tooltip>
                </Col>
              </Row>
          )}
          />
        </Table>
      </>;
    } else if(accio==='editar') {
      return(
        <props.edit item={itemActual} callback={showTaula} modal={props.modal ? props.modal : false}/>
      );
    } else if(accio==='afegir') {
        return(
            <props.edit item={itemActual} callback={showTaula} modal={props.modal ? props.modal : false}/>
        );
    }

  }
}
export default translate(LlistaEditableTalles);
