import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Alert, InputNumber, message } from 'antd';
import { Api } from '../../../../helpers';

import { AllHtmlEntities } from 'html-entities';
const entities = new AllHtmlEntities();

function QuotesTraspas({t, old_id_curs_escolar, new_id_curs_escolar, callback_ok, callback_ko}){

  const [multiplicador, setMultiplicador] = useState(1);
  const [guardant, setGuardant] = useState(false);


  const submitForm = async (valors) => {

    setGuardant(true);

    let dades = {
      old_id_curs_escolar,
      new_id_curs_escolar,
      multiplicador
    }

    let method = 'POST';
    let url_api = '/cursescolar/traspassar/quotes';

    try{

      console.log(url_api, JSON.stringify(dades));

      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

      if(resposta.result.success === 1){
        setGuardant(false);
        console.log('resposta', resposta);
        message.success(t('generic.OK_saved'), 5);
        callback_ok();
      }else{
        setGuardant(false);
        console.error('Error: submit FormQuotesTraspas: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
        callback_ko();
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: QuotesTraspas guardar: ', error);
      callback_ko();
    }
  }


    return(
      <div style={{padding:'10px 24px 35px 0px'}}>
        <Form onFinish={submitForm} style={{marginTop:'35px'}}
        layout="horizontal"
        labelCol={{span:0}}
        wrapperCol={{span:24}}
        colon={true}
        autoComplete="off"
        >
        <Alert
          style={{marginTop: 15, marginBottom: 15}}
          message={"Traspassar Quotes"}
          description={"Es procedirà a clonar les quotes del curs escolar anterior. Ha de tenir en compte que si ha creat un curs nou no estarà inclós en les quotes clonades. Haurà de modificar o afegir les quotes pertinents. També haurà d'assignar les quotes especifiques als alumnes, ja que aquests han canviat de curs i es necessari reasignar-les."}
          type="info"
          showIcon
        />

        <Form.Item>
          <span className="ant-form-item-label">
            <label>Multiplicador de preu&nbsp;</label>
            <InputNumber
              style={{
                width: 90,
              }}
              defaultValue="1"
              min="0"
              step="0.01"
              onChange={setMultiplicador}
            />
          </span>
        </Form.Item>

          <Form.Item wrapperCol={{span: 24 }} name="botonets" style={{marginTop: 20}}>
            <Row type="flex" justify="center">
              <Col>
                <Button onClick={callback_ko}>{t('generic.cancel')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{t('generic.seguent')}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );

}
export default translate(QuotesTraspas);
