import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { translate, setLanguage, getLanguage } from 'react-multi-lang';
import { Form, Layout, Row, Col, Input, Button, Affix, Select, Alert, message } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import { Api } from '../helpers';

const { Header, Footer, Content } = Layout;
const Option = Select.Option;


function LoginRecoverEmail(props){

  const [email, setEmail] = useState('');
  const [alerta, setAlerta] = useState({type:'', message:'ss', description:'ee'})
  const [loading, setLoading] = useState(false);
  const logo=require('../images/logo_h_t_ajustat.png');

/*
  useEffect(() => {

  },[props.t('idioma')]);
*/
  const changeLang = (lang) =>{
    setLanguage(lang);
    sessionStorage.setItem('Language',lang);
  }

  const handleSubmit = async() => {
    setLoading(true);
    let dades = {
      email: email.trim()
    }
    console.log(dades)

    try{

      const response = await fetch(global.api_url+'/users/recover', {
        method: 'PUT',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dades)
      });
      const resposta_txt = await response.text();
      console.log(resposta_txt)
      const resposta = JSON.parse(resposta_txt);

      if(resposta.result.success === 1){
        console.log(resposta);
        setLoading(false);
        //message.success(props.t('login.recover_missatge_ok'), 5);
        setAlerta({type:'success', message: props.t('login.recover_enviar'), description: props.t('login.recover_missatge_ok')});

      }else{
        setLoading(false);
        console.error('Error: submit Form LoginRecoverEmail: ',resposta);
        //message.config({top: 50});
        //message.error({content: resposta.result.message, duration:10});
        setAlerta({type:'error', message: props.t('login.recover_enviar'), description: props.t(`login.${resposta.result.message}`)});
      }

    }catch(error){
      setLoading(false);
      message.config({top: 50});
      message.error({content: props.t('generic.KO_saved'), duration:20});
      console.error('Error: LoginRecoverEmail guardar: ', error);
    }

  }

  let container;
  return (
    <div ref={node => {container = node;}}>
      <Layout style={{backgroundColor: 'white', minHeight: '100vh'}}>
        <Header style={{backgroundColor: 'white', height:'15vh'}}></Header>
        <Content  style={{backgroundColor: 'white'}}>
          <Row type="flex" justify="center">
              <img src={logo} alt="Logo" width="300"/>
          </Row>
          {alerta.type !== '' ? null :
            <Row type="flex" justify="center" style={{marginTop: `50px`}}>
              <span>{props.t('login.recover_email')}</span>
            </Row>
          }
          <Row type="flex" justify="center" style={{marginTop: `15px`}}>

            {alerta.type === '' ?
              <Form onFinish={handleSubmit} className="login-form" autoComplete="off">
                <Form.Item
                  name={'email'}
                  rules={[
                      {
                        type: 'email',
                        message: props.t('login.recover_error_email')
                      },
                    ]}>
                  <Input prefix={<MailOutlined  style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={props.t('login.User')+' / '+ props.t('pares.email')} autoFocus="focus" onChange={(e) => setEmail(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} style={{width:'100%'}}>
                    {props.t('login.recover_enviar')}
                  </Button>
                </Form.Item>
              </Form>
            :
            <Alert {...alerta}  showIcon/>
            }

          </Row>
        </Content>
        <Footer style={{ textAlign: 'center', backgroundColor: 'white', padding: 12}}>
          <Affix offsetBottom={10} target={() => container}>
            <Row type="flex">
              <Col xs={{span:24,order:2}} sm={{span:24, order:2}} md={{span:14, order:1}} lg={{span:8, order:1}}>Copyright © {new Date().getFullYear()} BlackData Software. All rights reserved.</Col>
              <Col xs={{span:24,order:1}} sm={{span:24, order:1}} md={{span:2, order:2, offset: 7}} lg={{span:2, order:2, offset: 12}}>
              <Select defaultValue={getLanguage()} onChange={changeLang} style={{ width: 120}}>
                <Option value="en">English</Option>
                <Option value="ca">Català</Option>
                <Option value="es">Castellano</Option>
              </Select>
              </Col>
            </Row>
          </Affix>
      </Footer>
    </Layout>
  </div>
  );

}

export default translate(LoginRecoverEmail);
