import React from 'react';
import { translate } from 'react-multi-lang';
import { Table, Divider } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import LlistaEditable from '../../components/LlistaEditable';
import Edit from '../../components/Formularis/BotigaPropietats';
import EditTalles from '../../components/Formularis/BotigaPropietatsTalles';
import { Api, InitDades } from '../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Propietats(props) {


  const buscarDades = async () => {
    const trans = await Api.get(`/botiga/propietats/${props.id_producte}`);
    if(trans.result.success!==1){return [];}
    return trans.data.propietats;
  }


  const eliminarDelTot = async (id) => {

    const fam = await Api.myFetch('/botiga/propietat/'+id, 'DELETE');
    if(fam.result.success===1){

    }

  }

  let itemBuit = InitDades.botigaPropietat();
  itemBuit.id_producte = props.id_producte;

  return(
    <div style={{marginTop:'2rem'}}>
      <Divider orientation="left">
          {props.t('botiga_propietats.titol')}
      </Divider>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          rowKey="id"
          txt_creats={props.t('botiga_propietats.creados')}
          txt_nou={props.t('botiga_propietats.nuevo')}
          buscarDades={buscarDades}
          edit={props.id_categoria===1 ? EditTalles : Edit}
          delete={eliminarDelTot}
          duplica={true}
          itemBuit={itemBuit}>
          <Column
          title={props.t('botiga_propietats.ordre')}
          key="ordre"
          render={(text,record) => (
            <span>{record.ordre}</span>
          )}
          />
          <Column
          title={props.t('botiga_propietats.text')}
          key="text"
          render={(text,record) =>(
            entities.decode(record.text[props.t('idioma')])
          )}/>
          <Column
          title={props.t('botiga_propietats.preu')}
          key="preu"
          render={(text,record) =>(
            <span>{record.preu}€</span>
          )}/>

        </LlistaEditable>
      </div>
    </div>
  );

}

export default translate(Propietats);
