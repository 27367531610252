import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Form, Input, Button, Modal, Typography, message } from 'antd';

import { PerfilUsuariContext } from "../../context/perfil-usuari";
import { Api, Encriptar } from '../../helpers';
import AutoritzarContrasenya from '../AutoritzarContrasenya';



function ProteccioDades(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [validat, setValidat] = useState(false);
  const [mostrarFinestra, setMostrarFinestra] = useState('INICI');
  const [visible, setVisible] = useState(props.visible);
  const [guardant, setGuardant] = useState(false);


  const obrirFinestra = (valor) =>{
    setValidat(false);
    setMostrarFinestra(valor);
  }


  const enviarResposta = async (resposta) => {
    if(!validat){
      return false;
    }
    if(!resposta){
      return false;
    }

    setGuardant(true);

    let dades = {
      pantalla: "obligar_respondre_vacunacio",
      resposta,
    }

    try{
      const resposta = await Api.myFetch('/resposta/obligada/pantalla/'+perfilUsuari.account.id, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: guardant ProteccioDades: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ProteccioDades guardar: ', error);
    }

  }

  return(
  <>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={2}>{props.t('posar_vacunes.titol')}</Typography.Title>
    </div>

    {mostrarFinestra !== 'INICI' ? null :
      <>
        <Typography.Paragraph>{props.t('posar_vacunes.info_previa_1')}</Typography.Paragraph>
        <Typography.Paragraph>{props.t('posar_vacunes.info_previa_2')}</Typography.Paragraph>

        <Row type="flex" justify="space-around" style={{marginTop: 25, marginBottom: 25}}>
          <Button style={{marginTop: 10}} type="primary" onClick={() => obrirFinestra('SI')} className="btn-success">{props.t('posar_vacunes.boto_si')}</Button>
          <Button style={{marginTop: 10}} type="primary" onClick={() => obrirFinestra('NO')} danger>{props.t('posar_vacunes.boto_no')}</Button>
        </Row>
      </>
    }
    {mostrarFinestra !== 'SI' ? null :
      <>
        <Typography.Title level={4}>{props.t('posar_vacunes.subtitol_resposata')}</Typography.Title>
        <Typography.Paragraph>{props.t('posar_vacunes.text_si')}</Typography.Paragraph>

        <AutoritzarContrasenya resposta={setValidat}/>

        <Row type="flex" justify="space-around" style={{marginTop: 25, marginBottom: 25}}>
          <Button style={{marginTop: 10}} onClick={() => obrirFinestra('INICI')}>{props.t('generic.back')}</Button>
          <Button style={{marginTop: 10}} disabled={!validat} type="primary" onClick={() => enviarResposta('SI')} loading={guardant} className="btn-success">{props.t('posar_vacunes.boto_si')}</Button>
        </Row>
      </>
    }
    {mostrarFinestra !== 'NO' ? null :
      <>
        <Typography.Title level={4}>{props.t('posar_vacunes.subtitol_resposata')}</Typography.Title>
        <Typography.Paragraph>{props.t('posar_vacunes.text_no_1')}</Typography.Paragraph>
        <Typography.Paragraph>{props.t('posar_vacunes.text_no_2')}</Typography.Paragraph>

        <AutoritzarContrasenya resposta={setValidat}/>

        <Row type="flex" justify="space-around" style={{marginTop: 25, marginBottom: 25}}>
          <Button style={{marginTop: 10}} onClick={() => obrirFinestra('INICI')}>{props.t('generic.back')}</Button>
          <Button style={{marginTop: 10}} disabled={!validat} type="primary" onClick={() => enviarResposta('NO')} loading={guardant} danger>{props.t('posar_vacunes.boto_no')}</Button>
        </Row>
      </>
    }

  </>
  );
}


export default translate(ProteccioDades);
