import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, Checkbox, Space, Select, Form, DatePicker, message } from 'antd';
import {SyncOutlined, MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

const radioStyle = {
      width:'100%',
      marginTop: '8px',
    };
function AcollidaMensual(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);

  const [acollides, setAcollides] = useState([]);
  const [triat, setTriat] = useState([]);

  const ids_fills = props.ids_fills ? props.ids_fills : [props.id_alumne];

  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [comprat, setComprat] = useState(false);

  const [error, setError] = useState('');

  const [total, setTotal] = useState(0);
  const [numFills, setNumFills] = useState(1);


  useEffect(() => {
    buscarDades();
  },[]);

  useEffect(() => {
    calcularTotal();
  },[triat, numFills]);


  const buscarDades = async () => {
    setIsLoading(true);
    const resp = await Api.get('/acollida/tipus/mensual');
    if(resp.result.success===1){


      const as = resp.data.acollides.map((acollida) =>{

        let id_propietat_comprada = 0;
        if(acollida.comprat.length>0){
          const registre = acollida.comprat.find((element, index) => element.id_alumne === props.id_alumne);
          if(registre){
            id_propietat_comprada = registre.id_propietat;
            setComprat(true)
          }
        }

        acollida.propietats = acollida.propietats.map((item) =>{
          if(acollida.descompte>0){
            item.preu = (item.preu - (item.preu * acollida.descompte / 100));
          }
          if(item.id_propietat === id_propietat_comprada){
            let t = triat;
            t.push(item)
            setTriat(t)
          }
          return item;
        });
        return acollida;
      });
      //console.log(as);
      setAcollides(as);
      calcularTotal();
    }
    setIsLoading(false);
  }

const calcularTotal = () => {
//console.log('calcularTotal', triat)
  let subtotal = 0;
  if(triat.length>0){

    triat.forEach((item, i) => {
      //console.log('calcularTotal', item)
      subtotal = subtotal + (item.preu * numFills);
    });
  }
  setTotal(subtotal.toFixed(2));
}


  const submitForm = async (valors) => {


    if(!validat){
      return false;
    }

    if(triat.length<=0){
      message.config({top: 50});
      message.error({content: 'Error: '+ props.t('generic.tria'), duration:3});
      return false;
    }


  setGuardant(true);

  const dades = {
    id_alumnes: numFills === 1 ? [props.id_alumne] : ids_fills,
    id_pare: perfilUsuari.account.id,
    tipus: 'mensual',
    ids_propietats: triat.map((item) => item.id_propietat)
  }

  //console.log(JSON.stringify(dades));

    try{
      const resposta = await Api.myFetch('/acollida/comanda', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Acollida: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Acollida guardar: ', error);
    }

  }


  const onChangeProps = (item) => {

    const filtrat = triat.filter((element, index, arr) => element.id_acollida !== item.id_acollida);
    const triats = [...filtrat, item];
    setTriat(triats);

  }

  const onChangeTotsFills = (tots) =>{
    setNumFills(tots ? ids_fills.length : 1);
  }


  if(isLoading || acollides.length===0){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {

  console.log('acollides',acollides)
  console.log('triat',triat)

  return(
    <>
    { acollides.map((acollida) =>{

        return(
          <>
          <Row justify="center" style={{marginTop:'10px'}}>
            <Typography.Title level={4}>{entities.decode(acollida.nom[props.t('idioma')])}</Typography.Title>
          </Row>

          <Row justify="left">
            <Form.Item required={true} validateStatus={error} style={{marginTop: 0, paddingRight: '20px', width: '100%'}}>
              <Radio.Group style={{margin: '10px', width: '100%'}} onChange={(e) => onChangeProps(e.target.value)} optionType="button" buttonStyle="solid" value={triat.find((element, index) => element.id_acollida === acollida.id)}>
                {
                  acollida.propietats.map((item) =>{

                    const text = entities.decode(item.text[props.t('idioma')]);

                    return(
                        <Radio.Button style={radioStyle} value={item} key={item.id_propietat}>
                          <table width="100%">
                            <tr>
                              <td style={{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                {text}
                              </td>
                              <td width="30" align="right">
                                {item.preu.toFixed(2)}€
                              </td>
                            </tr>
                          </table>
                        </Radio.Button>
                    )
                  })
                }
              </Radio.Group>
            </Form.Item>
          </Row>
        </>
      )
    })
  }





    <Row justify="center" style={{marginTop:'10px'}}>
      <Space direction="vertical" align="center">
        <Space><b style={{fontSize: '18px'}}>Total:</b><span style={{color: 'red', fontSize: '18px'}}>{total}€</span></Space>
      </Space>
    </Row>


    {ids_fills.length <= 1 || comprat ? null :
      <Row justify="center" style={{marginTop:'10px'}}>
        <Checkbox onChange={(e) => onChangeTotsFills(e.target.checked)}>{props.t('transport.tots_fills')}</Checkbox>
      </Row>
    }
    {!comprat
      ?
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <AutoritzarContrasenya resposta={setValidat}/>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
              {props.t('botiga.pagar_autoritzar')}
            </Button>
          </Row>
        </>
      :
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <span style={{color: 'red'}}>*{props.t('generic.serveiJaContractat')}</span>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() =>props.tancarModal()}>
              {props.t('generic.back')}
            </Button>
          </Row>
        </>
      }
    </>
  );
}
}
export default translate(AcollidaMensual);
