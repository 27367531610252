import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, CloseOutlined, CheckOutlined, PercentageOutlined, DeleteOutlined, UndoOutlined, EditOutlined, LeftOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Typography, Form, Row, Col, Button, Input, InputNumber, Modal, Divider, Switch, Select, Table, Popconfirm, Tooltip, Alert, Card, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { Api, InitDades } from '../../../../helpers';
//import FormAlumnes from './Alumnes';
//import FormPares from './Pares';
import BotonsPares from '../../../PopUps/perfils/admin/BotonsPares';
import BotonsAlumnes from '../../../PopUps/perfils/admin/BotonsFills';

import FormXec from '../../XecGuarderia';
import FormBanc from '../../Banc';
import FormAdress from '../../Adress';
import FormPares from './Pares';
import TriaCursEscolar from '../../../TriaCursEscolar';

const entities = new AllHtmlEntities();


function UnitatFamiliar(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar || perfilUsuari.curs_escolar_actual.id);

  const [id, setId] = useState(props.item.id);
  const [nou, setNou] = useState(props.item.id===0);
  const [cognom1, setCognom1] = useState(entities.decode(props.item.cognom1));
  const [cognom2, setCognom2] = useState(entities.decode(props.item.cognom2));
  const [tel1, setTel1] = useState(props.item.tel1);
  const [tel2, setTel2] = useState(props.item.tel2);
  const [tel3, setTel3] = useState(props.item.tel3);
  const [observacions, setObservacions] = useState(entities.decode(props.item.observacions));
  const [tipus_pagament, setTipus_pagament] = useState(props.item.tipus_pagament);
  const [mode_separar_despeses, setMode_separar_despeses] = useState(props.item.mode_separar_despeses);
  const [pagament_unic_totes_quotes_any, setPagament_unic_totes_quotes_any] = useState(props.item.pagament_unic_totes_quotes_any);
  const [pare_treballador_centre, setPare_treballador_centre] = useState(props.item.pare_treballador_centre);
  //const [descompte_quota, setDescompte_quota] = useState(props.item.descompte_quota);
  //const [descompte_transport, setDescompte_transport] = useState(props.item.descompte_transport);
  //const [descompte_menjador, setDescompte_menjador] = useState(props.item.descompte_menjador);
  const [descompte_botiga, setDescompte_botiga] = useState(props.item.descompte_botiga);
  const [descompte_extraescolars, setDescompte_extraescolars] = useState(props.item.descompte_extraescolars);
  const [estat, setEstat] = useState(props.item.estat);

  const [bancs, setBancs] = useState([]);
  const [bancTriat, setBancTriat] = useState([]);
  const [modalVisibleBanc, setModalVisibleBanc] = useState(false);
  const [actualizarBancs, setActualizarBancs] = useState(0);
  const [mostrarBancsEliminats, setMostrarBancsEliminats] = useState(false);

  const [xecs, setXecs] = useState([]);
  const [xecTriat, setXecTriat] = useState([]);
  const [modalVisibleXec, setModalVisibleXec] = useState(false);
  const [actualizarXecs, setActualizarXecs] = useState(0);

  const [adresses, setAdresses] = useState([]);
  const [adressTriat, setAdressTriat] = useState([]);
  const [modalVisibleAdress, setModalVisibleAdress] = useState(false);
  const [actualizarAdress, setActualizarAdress] = useState(0);

  const [autoritzats, setAutoritzats] = useState([]);
  const [autoritzatsTriat, setAutoritzatsTriat] = useState([]);
  const [modalVisibleAutoritzats, setModalVisibleAutoritzats] = useState(false);
  const [actualizarAutoritzats, setActualizarAutoritzats] = useState(0);

/* Auxiliars */
  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errorCognom1, setErrorCognom1] = useState('');
  const [errorTel1, setErrorTel1] = useState('');
  const [errorPercetatgeSepararDespeses, setErrorPercetatgeSepararDespeses] = useState(false);

  const [errorTipusPagament, setErrorTipusPagament] = useState('');
  const [errorTipusPagamentText, setErrorTipusPagamentText] = useState('');

  let xecBuit = InitDades.alumnes_xec();
  xecBuit.id_familia = id;
  xecBuit.id_curs_escolar = id_curs_escolar;

  let bancBuit = InitDades.banc();
  bancBuit.id_familia = id;
  let adressBuit = InitDades.familia_adress();
  adressBuit.id_familia = id;

  let autoritzatBuit = InitDades.pare();
  autoritzatBuit.id_familia = id;

  const socAdmin = perfilUsuari.account.perfil === 'Administrador';

//console.log(props.item)

  const contenidorRef = React.useRef();

  useEffect(() => {
    buscarDadesFamilia();
  },[]);

  useEffect(() => {
    buscarDadesBancs();
  },[actualizarBancs,mode_separar_despeses,mostrarBancsEliminats]);

  useEffect(() => {
    buscarDadesXecs();
  },[actualizarXecs]);

  useEffect(() => {
    buscarDadesAdress();
  },[actualizarAdress]);

  useEffect(() => {
    buscarDadesAutoritzats();
  },[actualizarAutoritzats]);


  const buscarDadesFamilia = async () => {
    if(id > 0){
      setIsLoading(true);
      const familia = await Api.get(`/families/${id}`);
      if(familia.result.success===1){
        setCognom1(entities.decode(familia.data.cognom1));
        setCognom2(entities.decode(familia.data.cognom2));
        setTel1(familia.data.tel1);
        setTel2(familia.data.tel2);
        setTel3(familia.data.tel3);
        setObservacions(entities.decode(familia.data.observacions));
        setTipus_pagament(familia.data.tipus_pagament);
        setMode_separar_despeses(familia.data.mode_separar_despeses);
        setPagament_unic_totes_quotes_any(familia.data.pagament_unic_totes_quotes_any);
        setPare_treballador_centre(familia.data.pare_treballador_centre);
        setDescompte_botiga(familia.data.descompte_botiga);
        setDescompte_extraescolars(familia.data.descompte_extraescolars);
        setEstat(familia.data.estat);
      }
      setIsLoading(false);
    }
  }

  const buscarDadesAutoritzats = async () => {
    if(id > 0){
      const au = await Api.get(`/admin/autoritzats/familia/${id}`);
      if(au.result.success===1){
        setAutoritzats(au.data);
      }
    }
  }
  const buscarDadesXecs = async () => {
    if(id > 0){
      const ad = await Api.get(`/xec/${id}/${id_curs_escolar}`);
      if(ad.result.success===1){
        setXecs(ad.data.xecs);
      }
    }
  }
  const buscarDadesAdress = async () => {
    if(id > 0){
      const ad = await Api.get('/adress/familia/'+id);
      if(ad.result.success===1){
        setAdresses(ad.data.adress);
      }
    }
  }
  const buscarDadesBancs = async () => {
    if(id > 0){
      //const banc = await Api.get('/bancs/familia/'+id);
      const banc = await Api.get('/bancs/tots/familia/'+id);
      if(banc.result.success===1){

        console.log(banc.data.bancs)
        if(mostrarBancsEliminats){
          setBancs(banc.data.bancs);
        }else{
          const tempBancs = banc.data.bancs.filter((element, index, arr) => element.felim === null);
          setBancs(tempBancs);
        }

        console.log('mode_separar_despeses',mode_separar_despeses)
        if(mode_separar_despeses){
          let suma = 0.0;
          banc.data.bancs.forEach(banc => {
            if(!banc.felim){
              suma = suma + banc.iban_percentatge;
            }
          });
            setErrorPercetatgeSepararDespeses(suma!==100);
            if(suma!==100){
              Modal.error({title:'Error', content:props.t('banc.iban_percentatge')})
            }
        }else{
          let contador_tots = 0;
          banc.data.bancs.forEach(banc => {
            if(!banc.felim){
              if(banc.utilitzar_en === 'tot'){
                contador_tots++;
              }
            }
          });

          if(contador_tots > 1){
            Modal.error({title:'Error', content:props.t('banc.error_tot')})
            setErrorPercetatgeSepararDespeses(true);
          }else{
            setErrorPercetatgeSepararDespeses(false);
          }

        }
      }
    }
  }


  const marcarXecEliminat = async (id) => {
    const b = await Api.myFetch('/xec/'+id, 'DELETE');
    if(b.result.success===1){
      setActualizarXecs(actualizarXecs+1);
    }
  }
  const marcarBancEliminat = async (id) => {
    const b = await Api.myFetch('/bancs/'+id, 'DELETE');
    if(b.result.success===1){
      setActualizarBancs(actualizarBancs+1);
    }
  }
  const marcarBancNoEliminat = async (id) => {
    const b = await Api.myFetch('/bancs/restore/'+id, 'PUT');
    if(b.result.success===1){
      setActualizarBancs(actualizarBancs+1);
    }
  }
  const marcarAdressEliminat = async (id) => {
    const b = await Api.myFetch('/adress/'+id, 'DELETE');
    if(b.result.success===1){
      setActualizarAdress(actualizarAdress+1);
    }
  }
  const marcarAutoritzatsEliminat = async (id) => {
    const b = await Api.myFetch('/pares/'+id, 'DELETE');
    if(b.result.success===1){
      setActualizarAutoritzats(actualizarAutoritzats+1);
    }
  }

    const submitForm = async (values) => {

      let correcte = true;

      if(!cognom1){
        setErrorCognom1('error');
        correcte = false;
      }

if(perfilUsuari.account.username!=='admin'){
/*
        if(!tel1){
          setErrorTel1('error');
          correcte = false;
        }
*/

        if(tipus_pagament === null){
          setErrorTipusPagament('error');
          setErrorTipusPagamentText(props.t('generic.tria'));
          correcte = false;
        }
}
      if(!correcte){
        contenidorRef.current.scrollIntoView({
          behavior: "smooth",
        });
        return false;
      }

      setGuardant(true);

      let dades = {
        id,
        cognom1: entities.encode(cognom1),
        cognom2: entities.encode(cognom2),
        tel1,
        tel2,
        tel3,
        observacions: entities.encode(observacions),
        tipus_pagament,
        mode_separar_despeses,
        pagament_unic_totes_quotes_any,
        pare_treballador_centre,
        //descompte_quota,
        //descompte_transport,
        //descompte_menjador,
        descompte_botiga,
        descompte_extraescolars,
        estat
      }

      let method = nou?'POST':'PUT';

      let url_api = nou? '/families' : '/families/'+id;

      //console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setId(resposta.data.id);
          setNou(false);
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
        //  props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormFamilies: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Familia guardar: ', error);
      }

  }

  const obrirModalBanc = item =>{
    setBancTriat(item);
    setModalVisibleBanc(true);
  }
  const tancarModalBanc = e =>{
    setActualizarBancs(actualizarBancs+1);
    setModalVisibleBanc(false);
  }
  const obrirModalXec = item =>{
    setXecTriat(item);
    setModalVisibleXec(true);
  }
  const tancarModalXec = e =>{
    setActualizarXecs(actualizarXecs+1);
    setModalVisibleXec(false);
  }
  const obrirModalAdress = item =>{
    setAdressTriat(item);
    setModalVisibleAdress(true);
  }
  const tancarModalAdress = e =>{
    setActualizarAdress(actualizarAdress+1);
    setModalVisibleAdress(false);
  }
  const obrirModalAutoritzats = item =>{
    setAutoritzatsTriat(item);
    setModalVisibleAutoritzats(true);
  }
  const tancarModalAautoritzats = e =>{
    setActualizarAutoritzats(actualizarAutoritzats+1);
    setModalVisibleAutoritzats(false);
  }



  const triaTipusPagament = (value) =>{
    setTipus_pagament(value);
    if(value.length>0){
      setErrorTipusPagament('');
      setErrorTipusPagamentText('');
    }
  }

  const xecTriaCursChange = (curs) => {
    setId_curs_escolar(curs);
    xecBuit.id_curs_escolar = curs;
    setActualizarXecs(actualizarXecs+1);
  }


  const missatge_info = (
    <div>
      <ul>
        <li><b>{props.t('generic.si')}:&nbsp;</b>{props.t('families.mode_separar_despeses_activat')}</li>
        <li><b>{props.t('generic.no')}:&nbsp;</b>{props.t('families.mode_separar_despeses_desactivat')}</li>
      </ul>
    </div>
  );

  const columnsBanc = [
  {
    title: props.t('banc.titular'),
    dataIndex: 'titular',
    key: 'titular',
    render:(text,record) => {
      if(record.felim){
        return (<span><span style={{color: 'red'}}>ELIMINAT - </span>{entities.decode(record.titular)}</span>)
      }else{
        return (<span>{entities.decode(record.titular)}</span>)
      }
    }
  },
  {
    title:  props.t('banc.iban'),
    dataIndex: 'iban',
    key: 'iban',
    render:(text,record) => {
      let error_txt = [];
      if(!record.mandat){
        error_txt.push(<p style={{margin: 0, padding: 0}}>{props.t('banc.errors.mandat')}</p>);
      }
      if(!record.mandat_data){
        error_txt.push(<p style={{margin: 0, padding: 0}}>{props.t('banc.errors.mandat_data')}</p>);
      }
      if(record.pendent_validar == 1){
        error_txt.push(<p style={{margin: 0, padding: 0}}>{props.t('banc.errors.validar')}</p>);
      }
      if(error_txt.length > 0){
        return (
          <Tooltip placement="top" title={error_txt}>
            <span style={{color: 'red'}}>{record.iban}</span>
          </Tooltip>
        )
      }else{
        return (<span>{record.iban}</span>)
      }
    }
  },
  {
    title: (mode_separar_despeses ? props.t('banc.iban_percentatge_curt') : props.t('banc.utilitzar_en.titol')),
    dataIndex: (mode_separar_despeses ? 'iban_percentatge' : 'utilitzar_en'),
    key: (mode_separar_despeses ? 'iban_percentatge' : 'utilitzar_en'),
    className : (errorPercetatgeSepararDespeses ? 'bg-error-color' : null),
    render: (text,record) =>{
       if(errorPercetatgeSepararDespeses && !record.felim){
          return (
            <span style={{color: 'red'}}>
            {(mode_separar_despeses ? record.iban_percentatge+"%" : props.t(`banc.utilitzar_en.${record.utilitzar_en}`) )}
            </span>
          )
      }else{
        return (
          <span>
            {(mode_separar_despeses ? record.iban_percentatge+"%" : props.t(`banc.utilitzar_en.${record.utilitzar_en}`) )}
          </span>
        )
      }
    }
  },
  ,{
    key: "actions",
    align: "right",
    width: "95px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id} key={"actions_"+record.id}>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EditOutlined />} onClick={(e) => obrirModalBanc(record)} ghost="true" className="BotoTaula"/>
          </Tooltip>
        </Col>
        <Col span={12}>
        { record.felim === null
          ?
            <Tooltip placement="top" title={props.t('generic.delete')}>
              <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>marcarBancEliminat(record.id)}>
                <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
              </Popconfirm>
            </Tooltip>
          :
            <Tooltip placement="top" title={props.t('generic.undelete')}>
              <Popconfirm placement="bottom" title={props.t('generic.confirm_undelete')} okText="Si" cancelText="No" onConfirm={()=>marcarBancNoEliminat(record.id)}>
                <Button icon={<UndoOutlined />} ghost="true" className="BotoTaula"/>
              </Popconfirm>
            </Tooltip>

        }
        </Col>
      </Row>
  )
}
];
  const columnsXecs = [
  {
    title: props.t('generic.alumne'),
    dataIndex: 'alumne',
    key: 'alumne',
    render:(text,record) => (
      <span>{entities.decode(record.alumne)}</span>
    )
  },{
    title: props.t('quotes.import'),
    dataIndex: 'import',
    key:"import",
    render:(text,record) => (<span>{record.import}€</span>)
  },
  {
    key: "actions",
    align: "right",
    width: "95px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id} key={"actions_"+record.id}>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EditOutlined />} onClick={(e) => obrirModalXec(record)} ghost="true" className="BotoTaula"/>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.delete')}>
            <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>marcarXecEliminat(record.id)}>
              <Button icon={<DeleteOutlined />} data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
  )
}
];
  const columnsAdress = [
  {
    title: props.t('families.adress'),
    dataIndex: 'adress',
    key: 'adress',
    render:(text,record) => (
      <span>{entities.decode(record.adress)}</span>
    )
  },
  {
    title:  props.t('families.ciutat'),
    dataIndex: 'ciutat',
    key: 'ciutat',
    render:(text,record) => (
      <span>{entities.decode(record.ciutat)}</span>
    )
  },
  {
    title:  props.t('families.cp'),
    dataIndex: 'cp',
    key: 'cp',
    responsive: ['md']
  },
  {
    title:  props.t('families.provincia'),
    dataIndex: 'provincia',
    key: 'provincia',
    responsive: ['md'],
    render:(text,record) => (
      <span>{entities.decode(record.provincia)}</span>
    )
  },
  {
    key: "actions",
    align: "right",
    width: "95px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id} key={"actions_"+record.id}>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EditOutlined />} onClick={(e) => obrirModalAdress(record)} ghost="true" className="BotoTaula"/>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.delete')}>
            <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>marcarAdressEliminat(record.id)}>
              <Button icon={<DeleteOutlined />} data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
  )
}
];



  const columnsAutoritzats = [
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title: props.t('pares.nom'),
    key:"nom",
    dataIndex: 'nom',
    render: (text,record) =>(entities.decode(record.nom)),
    sorter: (a, b) => { return entities.decode(a.nom).localeCompare(entities.decode(b.nom))}
  },{
    title: props.t('pares.cognom1'),
    key: "cognom1",
    dataIndex: 'cognom1',
    render: (text,record) =>(entities.decode(record.cognom1)),
    sorter: (a, b) => { return entities.decode(a.cognom1).localeCompare(entities.decode(b.cognom1))}
  },{
    title:props.t('pares.cognom2'),
    key:"cognom2",
    dataIndex: 'cognom2',
    render:(text,record) =>(entities.decode(record.cognom2)),
    sorter: (a, b) => { return entities.decode(a.cognom2).localeCompare(entities.decode(b.cognom2))}
  },{
    title:props.t('pares.tel1'),
    key:"tel1",
    responsive:['md'],
    render:(text,record) => (record.tel1 ? record.tel1 : '')
  },{
    title: props.t('pares.dni'),
    key:"dni",
    dataIndex: 'dni',
    width: '10px',
    render: (text,record) =>(entities.decode(record.dni))
  },{
    key: "actions",
    align: "right",
    width: "95px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id} key={"actions_"+record.id}>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EditOutlined />} onClick={(e) => obrirModalAutoritzats(record)} ghost="true" className="BotoTaula"/>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.delete')}>
            <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>marcarAutoritzatsEliminat(record.id)}>
              <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
  )
}
];

  /*let taulaBancVisible = false;
  if(tipus_pagament === 'BANC'){
    if(!nou){
    taulaBancVisible = true;
    }
  }*/

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }
  return(
    <>
    <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
      <LeftOutlined className="BotoBack" onClick={props.callback}/>
    </div>
    <div style={{padding:'10px 24px 35px 0px'}} ref={contenidorRef}>
    {id<=0 || props.popup!==undefined ? null :
      <>
        <BotonsPares id_familia={id}/>
        <BotonsAlumnes id_familia={id}/>
        <Divider />
      </>
    }
      <Form onFinish={submitForm}
        layout="vertical"
        autoComplete="off"
      >
      {nou == true ? null :
        <Row>
          <Typography.Title level={4}>{`ID ${props.t('menu.UnitatFamiliar')}: ${id}`}</Typography.Title>
        </Row>
      }
      {nou == true ? null :
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{props.t('alumnes.estat.titol')}&nbsp;&nbsp;</label>
            <Select defaultValue={estat} onChange={(value) => setEstat(value)} style={{ width: 100}}>
              <Select.Option value="actiu">{props.t('alumnes.estat.actiu')}</Select.Option>
              <Select.Option value="baixa">{props.t('alumnes.estat.baixa')}</Select.Option>
            </Select>
          </span>
        </Form.Item>
      }
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} lg={12} xl={8}>
        <Form.Item label={props.t('families.cognom1')} validateStatus={errorCognom1} required={true}>
            <Input name="cognom1" placeholder={props.t('families.cognom1')} onChange={(e) => setCognom1(e.target.value)} defaultValue={cognom1}/>
        </Form.Item>
        <Form.Item label={props.t('families.cognom2')}>
          <Input name="cognom2" placeholder={props.t('families.cognom2')} onChange={(e) => setCognom2(e.target.value)} defaultValue={cognom2}/>
        </Form.Item>
      {/*<Divider plain>{props.t('generic.dades_contacte')}</Divider>
        <Form.Item label={props.t('families.tel1')} validateStatus={errorTel1} required={true}>
          <Input name="tel1" placeholder={props.t('families.tel1')} onChange={(e) => setTel1(e.target.value)} defaultValue={tel1}/>
        </Form.Item>
        <Form.Item label={props.t('families.tel2')}>
          <Input name="tel2" placeholder={props.t('families.tel2')} onChange={(e) => setTel2(e.target.value)} defaultValue={tel2}/>
        </Form.Item>
        <Form.Item label={props.t('families.tel3')}>
          <Input name="tel3" placeholder={props.t('families.tel3')} onChange={(e) => setTel3(e.target.value)} defaultValue={tel3}/>
        </Form.Item>*/}

      <Divider plain>{props.t('families.descomptes')}</Divider>
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{props.t('families.pare_treballador_centre')}&nbsp;</label>
            <Switch
             disabled={!socAdmin}
             checkedChildren={<CheckOutlined />}
             unCheckedChildren={<CloseOutlined />}
             onChange={setPare_treballador_centre} defaultChecked={pare_treballador_centre}
            />
          </span>
        </Form.Item>
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{props.t('families.pagament_unic_totes_quotes_any')}&nbsp;</label>
            <Switch
             disabled={!socAdmin}
             checkedChildren={<CheckOutlined />}
             unCheckedChildren={<CloseOutlined />}
             onChange={setPagament_unic_totes_quotes_any} defaultChecked={pagament_unic_totes_quotes_any}
            />
          </span>
        </Form.Item>

        {/*<Form.Item label={props.t('families.descompte_quota')}
          labelCol={{span:10}}
          wrapperCol={{span:6}}
        >
            <InputNumber name="descompte_quota" onChange={setDescompte_quota} defaultValue={descompte_quota}
            min={-100} max={100} step={0.1}
            /><PercentageOutlined />
        </Form.Item>
        <Form.Item label={props.t('families.descompte_transport')}
          labelCol={{span:10}}
          wrapperCol={{span:6}}
        >
            <InputNumber name="descompte_transport" onChange={setDescompte_transport} defaultValue={descompte_transport}
            min={-100} max={100} step={0.1}
            /><PercentageOutlined />
        </Form.Item>
        <Form.Item label={props.t('families.descompte_menjador')}
          labelCol={{span:10}}
          wrapperCol={{span:6}}
        >
            <InputNumber name="descompte_menjador" onChange={setDescompte_menjador} defaultValue={descompte_menjador}
            min={-100} max={100} step={0.1}
            /><PercentageOutlined />
        </Form.Item>*/}
        <Form.Item label={props.t('families.descompte_botiga')}
          labelCol={{span:10}}
          wrapperCol={{span:7}}
        >
            <InputNumber name="descompte_botiga" onChange={setDescompte_botiga} defaultValue={descompte_botiga}
              min={-100} max={100} step={0.1}
              disabled={!socAdmin}
            /><PercentageOutlined />
        </Form.Item>
        <Form.Item label={props.t('families.descompte_extraescolars')}
          labelCol={{span:10}}
          wrapperCol={{span:7}}
        >
            <InputNumber name="descompte_extraescolars" onChange={setDescompte_extraescolars} defaultValue={descompte_extraescolars}
              min={-100} max={100} step={0.1}
              disabled={!socAdmin}
            /><PercentageOutlined />
        </Form.Item>
      <Divider/>
        <Form.Item label={props.t('families.observacions')}>
          <Input.TextArea name="observacions" placeholder={props.t('families.observacions')} onChange={(e) => setObservacions(e.target.value)} defaultValue={observacions}/>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 3, span: 16 }} name="botonets">
          <Row type="flex" justify="start">
            <Col>
              <Button onClick={props.callback}>{props.t('generic.back')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              {errorPercetatgeSepararDespeses
                ?
                  <Tooltip title={"Error: "+props.t('banc.iban_percentatge')} color='#ef9a9a' >
                    <Button type="primary" htmlType="submit" loading={guardant} disabled={errorPercetatgeSepararDespeses}>{props.t('generic.save')}</Button>
                  </Tooltip>
                :
                  <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
              }
            </Col>
          </Row>
        </Form.Item>
      </Col>
      <Col xs={24} lg={12} xl={16}>
        <Form.Item label={props.t('families.tipus_pagament')} required={true} validateStatus={errorTipusPagament} help={errorTipusPagamentText}>
          <Select defaultValue={tipus_pagament} onChange={triaTipusPagament} style={{ width: 160}}>
            <Select.Option key="BANC" value="BANC">{props.t('families.pagament_banc')}</Select.Option>
            <Select.Option key="TRANSFERENCIA" value="TRANSFERENCIA">{props.t('families.pagament_transferencia')}</Select.Option>
            <Select.Option key="EFECTIU" value="EFECTIU">{props.t('families.pagament_efectiu')}</Select.Option>
            <Select.Option key="TARGETA" value="TARGETA">{props.t('families.pagament_targeta')}</Select.Option>
            <Select.Option key="XEC_GUARDERIA" value="XEC_GUARDERIA">{props.t('families.pagament_xec_guarderia')}</Select.Option>
          </Select>
        </Form.Item>

          <Form.Item>
            <span className="ant-form-item-label">
              <label>{props.t('families.mode_separar_despeses')}&nbsp;</label>
              <Switch
                checkedChildren={props.t('generic.si')}
                unCheckedChildren={props.t('generic.no')}
                onChange={setMode_separar_despeses} defaultChecked={mode_separar_despeses}
                disabled={!socAdmin}
              />
            </span>
          </Form.Item>
          <Alert
            message={props.t('families.mode_separar_despeses')}
            description={missatge_info}
            type="info"
            showIcon
            style={{marginBottom: '25px'}}
            />

{ nou ? null :
        <Card
          headStyle={{backgroundColor: "#efebe9"}}
          style={{borderColor: "#efebe9"}}
          hoverable={true}
          title={props.t('families.pagament_xec_guarderia')}
          extra={
            <Space align="center">
            <TriaCursEscolar defaultValue={id_curs_escolar} onChange={(c) => xecTriaCursChange(c)}/>
            <Button type="primary" onClick={() => obrirModalXec(xecBuit)}>{props.t('generic.add')}</Button>
            </Space>
          }
          >
          <Table
            key='taula_xec_guarderia'
            dataSource={xecs}
            columns={columnsXecs}
            pagination={false}
            size={'small'}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {},
                onDoubleClick: (e) => obrirModalXec(record),
                onContextMenu: (e) => {e.preventDefault(); obrirModalXec(record);},
              };
            }}
          />
        </Card>
  }
          <br/>
{nou ? null :
        <Card
          headStyle={{backgroundColor: "#e0f2f1"}}
          style={{borderColor: "#e0f2f1"}}
          hoverable={true}
          title={props.t('banc.titol')}
          extra={<Space>{props.t('generic.mostrar_eliminats')}<Switch align="baseline" checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')} checked={mostrarBancsEliminats} onChange={setMostrarBancsEliminats}/><Button type="primary" onClick={() => obrirModalBanc(bancBuit)}>{props.t('generic.add')}</Button></Space>}>
          {/*<Row type="flex" justify="end">
            <Col>
              <Button type="primary" onClick={() => obrirModalBanc(bancBuit)}>{props.t('generic.add')}</Button>
            </Col>
          </Row>*/}
          <Table
            key='taula_bancs'
            dataSource={bancs}
            columns={columnsBanc}
            pagination={false}
            size={'small'}
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {},
                onDoubleClick: (e) => obrirModalBanc(record),
                onContextMenu: (e) => {e.preventDefault(); obrirModalBanc(record);},
              };
            }}
          />
        </Card>
  }
          <br/>

  {nou ? null :
          <Card
            headStyle={{backgroundColor: "#fff8e1"}}
            style={{borderColor: "#fff8e1"}}
            hoverable={true} title={props.t('families.adress')}
            extra={<Button type="primary" onClick={() => obrirModalAdress(adressBuit)}>{props.t('generic.add')}</Button>}>
            {/*<Row type="flex" justify="end" style={{marginTop: '1rem'}}>
              <Col>
                <Button type="primary" onClick={() => obrirModalAdress(adressBuit)}>{props.t('generic.add')}</Button>
              </Col>
            </Row>*/}
            <Table
              key='taula_adress'
              dataSource={adresses}
              columns={columnsAdress}
              pagination={false}
              size={'small'}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {},
                  onDoubleClick: (e) => obrirModalAdress(record),
                  onContextMenu: (e) => {e.preventDefault(); obrirModalAdress(record);},
                };
              }}
            />
          </Card>
  }
          <br/>
          {/*<Divider plain><b>{props.t('menu.DadesAutoritzats')}</b></Divider>*/}


  {nou ? null :
            <Card
            headStyle={{backgroundColor: "#fbe9e7"}}
            style={{borderColor: "#fbe9e7"}}
            hoverable={true} title={props.t('menu.DadesAutoritzats')}
            extra={<Button type="primary" onClick={() => obrirModalAutoritzats(autoritzatBuit)}>{props.t('generic.add')}</Button>}>
            {/*<Row type="flex" justify="end" style={{marginTop: '1rem'}}>
              <Col>
                <Button type="primary" onClick={() => obrirModalAutoritzats(autoritzatBuit)}>{props.t('generic.add')}</Button>
              </Col>
            </Row>*/}
            <Table
              key='taula_autoritzats'
              dataSource={autoritzats}
              columns={columnsAutoritzats}
              pagination={false}
              size={'small'}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {},
                  onDoubleClick: (e) => obrirModalAutoritzats(record),
                  onContextMenu: (e) => {e.preventDefault(); obrirModalAutoritzats(record);},
                };
              }}
            />
          </Card>
  }

      </Col>
      </Row>
      </Form>
      <Modal
          key="xec_guarderia"
          title={props.t('families.pagament_xec_guarderia')}
          visible={modalVisibleXec}
          footer={null}
          onCancel={tancarModalXec}
          width={550}
          style={{top:20}}
          destroyOnClose={true}
          forceRender={true}
        >
          <FormXec item={xecTriat} callback={tancarModalXec} popup={true}/>
        </Modal>
      <Modal
          key="bancs"
          title={props.t('banc.titol')}
          visible={modalVisibleBanc}
          footer={null}
          onCancel={tancarModalBanc}
          width={550}
          style={{top:20}}
          //bodyStyle={mode_separar_despeses ? {height:"800px"} : {height:"725px"}}
          //bodyStyle={{ height: "88vh", overflow: "auto" }}
          //bodyStyle={{ height: "800px", overflow: "auto" }}
          bodyStyle={{overflow: "auto" }}
          destroyOnClose={true}
          forceRender={true}
        >
          <FormBanc item={bancTriat} callback={tancarModalBanc} popup={true} mode_separar_despeses={mode_separar_despeses}/>
        </Modal>
      <Modal
          key="adresses"
          title={props.t('families.adress')}
          visible={modalVisibleAdress}
          footer={null}
          onCancel={tancarModalAdress}
          width={550}
          style={{top:20}}
          //bodyStyle={{ height: "70vh", overflow: "auto" }}
          destroyOnClose={true}
          forceRender={true}
        >
          <FormAdress item={adressTriat} callback={tancarModalAdress} popup={true}/>
        </Modal>
      <Modal
          key="autoritzats"
          title={props.t('menu.DadesAutoritzats')}
          visible={modalVisibleAutoritzats}
          footer={null}
          onCancel={tancarModalAautoritzats}
          width={1350}
          style={{top:20}}
          bodyStyle={{ height: "90vh", overflow: "auto" }}
          destroyOnClose={true}
          forceRender={true}
        >
          <FormPares item={autoritzatsTriat} callback={tancarModalAautoritzats} popup={true}/>
        </Modal>
    </div>
  </>
  );
}
export default translate(UnitatFamiliar);
