import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Table, Form, Select, Switch, Space, Row, Col, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import NomDescripcioIdiomes from '../../../NomDescripcioIdiomes';
import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id] = useState(props.item.id);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [perfil, setPerfil] = useState(props.item.perfil);
  const [text_ca, setText_ca] = useState(entities.decode(props.item.nom['ca']));
  const [text_en, setText_en] = useState(entities.decode(props.item.nom['en']));
  const [text_es, setText_es] = useState(entities.decode(props.item.nom['es']));
  const [ids_carrecs, setIds_carrecs] = useState(props.item.ids_carrecs ? props.item.ids_carrecs : []);
  const [notificar_admin, setNotificar_admin] = useState(props.item.notificar_admin);
  const [notificar_pares, setNotificar_pares] = useState(props.item.notificar_pares);

  const modal = props.modal ? props.modal : false;

  const [isLoadingCarrecs, setIsLoadingCarrecs] = useState(false);
  const [llistaCarrecs, setLlistaCarrecs] = useState([]);
  const [optionsPerfils, setOptionsPerfils] = useState([]);

  const [guardant, setGuardant] = useState(false);

  const [errorOrdre, setErrorOrdre] = useState('');
  const [errorText_ca, setErrorText_ca] = useState('');
  const [errorText_en, setErrorText_en] = useState('');
  const [errorText_es, setErrorText_es] = useState('');
  const [errorIds_carrecs, setErrorIds_carrecs] = useState('');
  const [errorIds_carrecsText, setErrorIds_carrecsText] = useState('');
  const [errorPerfil, setErrorPerfil] = useState('');
  const [errorPerfilText, setErrorPerfilText] = useState('');

  const nou = (id===0);

  useEffect(() => {
    buscarDadesCarrecs();
  },[]);

  useEffect(() => {
    buscarDadesPerfils();
  },[props.t('idioma')]);

  const buscarDadesCarrecs = async () => {
    setIsLoadingCarrecs(true);
    const c = await Api.get('/professors/carrecs/existents');
    if(c.result.success===1){
      setLlistaCarrecs(c.data.carrecs);
    }else{
      setLlistaCarrecs([]);
    }
    setIsLoadingCarrecs(false);
  }

  const buscarDadesPerfils = async () => {
    const p = await Api.get('/perfils/users');
    if(p.result.success===1){
      const llista_p = p.data.perfils.map((item) =>{
        if(item.id !== 'Auxiliar'){
          return(
              <Select.Option key={item.id} value={item.id}>{entities.decode(item.nom[props.t('idioma')])}</Select.Option>
            );
        }
      });
      setOptionsPerfils(llista_p);
    }else{
      setOptionsPerfils([]);
    }
  }



  const ordreChange = (value) =>{
    setOrdre(value);
    if(Number.isInteger(value)){
      setErrorOrdre('');
    }
  }

  const perfilChange = (value) =>{
    setPerfil(value);
    if(value !== ''){
      setErrorPerfil('');
      setErrorPerfilText('');
    }
  }

  const text_caChange = (e) =>{
    const n = e.target.value.trim();
    setText_ca(n);
    if(n.length>0){
      setErrorText_ca('');
    }
  }
  const text_enChange = (e) =>{
    const n = e.target.value.trim();
    setText_en(n);
    if(n.length>0){
      setErrorText_en('');
    }
  }
  const text_esChange = (e) =>{
    const n = e.target.value.trim();
    setText_es(n);
    if(n.length>0){
      setErrorText_es('');
    }
  }


  const submitForm = async (values) => {

      let correcte = true;

      if(!Number.isInteger(ordre)){
        setErrorOrdre('error');
        correcte = false;
      }
      if(text_ca.length === 0){
        setErrorText_ca('error');
        correcte = false;
      }
      if(text_en.length === 0){
        setErrorText_en('error');
        correcte = false;
      }
      if(text_es.length === 0){
        setErrorText_es('error');
        correcte = false;
      }
      if(ids_carrecs.length < 1 && !notificar_admin && !notificar_pares){
        setErrorIds_carrecs('error');
        setErrorIds_carrecsText(props.t('generic.tria'));
        correcte = false;
      }
      if(!perfil){
        setErrorPerfil('error');
        setErrorPerfilText(props.t('generic.tria'));
        correcte = false;
      }


      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        ordre,
        perfil,
        ids_carrecs,
        nom_ca: entities.encode(text_ca),
        nom_en: entities.encode(text_en),
        nom_es: entities.encode(text_es),
        notificar_admin,
        notificar_pares
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/notificacions/tipus' : '/notificacions/tipus/'+id;
console.log(url_api, method)
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          console.log(resposta);
          setGuardant(false);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();

        }else{
          setGuardant(false);
          console.error('Error: submit Form BotigaTalles: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: BotigaTalles guardar: ', error);
      }

  }

  return (
    <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="vertical"
      //labelCol={{span:2}}
      //wrapperCol={modal ? {span:16} : {span:7}}
      //colon={true}
      autoComplete="off"
    >
    <Row style={{marginLeft: '12px'}} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={8} key='nom_ca'>
        <Form.Item
          validateStatus={errorOrdre}
          label={props.t('transport.ordre')}
          required={true}
        >
            <InputNumber name="ordre" onChange={ordreChange} defaultValue={ordre} min={0} step={1} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={8} key='nom_ca'>
        <Form.Item
          validateStatus={errorPerfil}
          help={errorPerfilText}
          label={props.t('generic.perfil')}
          required={true}
          style={{marginBottom: 0}}
        >
          <Select
            defaultValue={perfil}
            onChange={perfilChange}>
            {optionsPerfils}
          </Select>
        </Form.Item>
      </Col>
    </Row>

      <NomDescripcioIdiomes
        errorNom_ca = {errorText_ca}
        errorNom_en = {errorText_en}
        errorNom_es = {errorText_es}
        nomChange_ca = {text_caChange}
        nomChange_en = {text_enChange}
        nomChange_es = {text_esChange}
        nom_ca = {text_ca}
        nom_en = {text_en}
        nom_es = {text_es}
        visibleDescripcio = {false}
      />
      {/*<Form.Item
        validateStatus={errorText_ca}
        label={props.t('botiga_propietats.text')+' CA'}
        required={true}
      >
        <Input name="text_ca" placeholder={props.t('botiga_propietats.text')} onChange={text_caChange} defaultValue={text_ca}/>
      </Form.Item>
      <Form.Item
        validateStatus={errorText_en}
        label={props.t('botiga_propietats.text')+' EN'}
        required={true}
      >
        <Input name="text_en" placeholder={props.t('botiga_propietats.text')} onChange={text_enChange} defaultValue={text_en}/>
      </Form.Item>
      <Form.Item
        validateStatus={errorText_es}
        label={props.t('botiga_propietats.text')+' ES'}
        required={true}
      >
        <Input name="text_es" placeholder={props.t('botiga_propietats.text')} onChange={text_esChange} defaultValue={text_es}/>
      </Form.Item>*/}

      <Form.Item
        validateStatus={errorIds_carrecs}
        help={errorIds_carrecsText}
        label={props.t('menu.CarrecsProfessors')}
        required={true}
        style={{marginBottom: 0}}

      >
      <div style={errorIds_carrecs ==='' ? null : {border: '1px solid red'}}>
        <Table
          style={{marginBottom: '1rem'}}
          size="small"
          loading={isLoadingCarrecs}
          rowKey="id"
          dataSource={llistaCarrecs}
          columns={[
            {
              title: props.t('menu.CarrecsProfessors'),
              key: "nom",
              dataIndex: "nom",
              render: (text,record) => (entities.decode(record.nom[props.t('idioma')]))
            }
          ]}
          pagination={false}
          rowSelection={{
          selectedRowKeys: ids_carrecs,
          hideSelectAll: false,
          type: 'checkbox',
          onChange: (selectedRowKeys, selectedRows) => {
            if(selectedRowKeys.length > 0){
              setErrorIds_carrecs('');
              setErrorIds_carrecsText('');
            }
            setIds_carrecs(selectedRowKeys);
          }
        }}/>
      </div>
      </Form.Item>

      <Row style={{marginBottom: '1rem'}} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} key='nom_ca'>
        <Space>
          <span>{props.t('notificacions.notificar_admin')}</span>
          <Switch defaultChecked={notificar_admin} onChange={setNotificar_admin} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
        </Space>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={8} key='nom_ca'>
        <Space>
          <span>{props.t('notificacions.notificar_pares')}</span>
          <Switch defaultChecked={notificar_pares} onChange={setNotificar_pares} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
        </Space>
        </Col>
      </Row>


      <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form.Item>
      </Form>
    </div>
  );
}
export default translate(Edit);
