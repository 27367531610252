import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';
import { Tabs, Button, Radio } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import Producte from './Producte';
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import Alumne from '../../../components/Formularis/perfils/pares/Alumne';
import { Api } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../context/menu-triat";
const entities = new AllHtmlEntities();

function Botiga(props){
  const [isLoading, setIsLoading] = useState(true);
  const [fillActual, setFillActual] = useState();
  const [llistaFills, setLlistaFills] = useState([]);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

  const buscarDades = async () => {
    setIsLoading(true);
    let llista_f = [];
    const fills = await Api.get(`/alumnes/familia/${perfilUsuari.account.id_familia}`);
    if(fills.result.success===1){
      setFillActual(fills.data.alumnes[0].id);
      setLlistaFills(fills.data.alumnes);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);



const operations = <Button onClick={() => buscarDades()} type="dashed" shape="circle" icon={<SyncOutlined />} />;

  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menu.DadesFills')}/>
      <div style={{marginTop:'-2.8rem', marginLeft:'1rem'}}>
        <LeftOutlined className="BotoBack" onClick={(e) => setMenuTriat("Dashboard")}/>
      </div>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :

          <Tabs defaultActiveKey={fillActual} onChange={setFillActual}>
            {
              llistaFills.map((item) =>{
                  return(
                    <Tabs.TabPane tab={entities.decode(item.nom)} key={item.id}>
                      <Alumne item={item}/>
                    </Tabs.TabPane>
                  )
                })
            }
          </Tabs>
      }
      </div>
    </ContainerPagina>
  );
}

export default translate(Botiga);
