import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function AlumnesExportaExcelNacionalitat(props){

  const [dades, setDades] = useState([]);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Rebuts';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'Rebuts';

  const [isLoading, setIsLoading] = useState(true);

  const buscarDades = async () => {
    setIsLoading(true);
    const alum = await Api.get('/llistat/alumnes/nacionalitat');
    console.log(alum)
    if(alum.result.success===1){
      console.log(alum.data.alumnes)

      setDades(alum.data.alumnes);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="id_alumne" value="id_alumne"/>
            <ExcelColumn label="id_familia" value="id_familia"/>
            <ExcelColumn label="Nacionalitat" value={(col) => entities.decode(col.nacionalitat)}/>
            <ExcelColumn label="Naixement Ciutat" value={(col) => entities.decode(col.naixement_ciutat)}/>
            <ExcelColumn label="Data ingres" value="data_ingres"/>
            <ExcelColumn label="Adreça" value={(col) => entities.decode(col.adress)}/>
            <ExcelColumn label="Ciutat" value={(col) => entities.decode(col.ciutat)}/>
            <ExcelColumn label="Codi Postal" value={(col) => entities.decode(col.cp)}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(AlumnesExportaExcelNacionalitat);
