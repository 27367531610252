import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Select, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();


function Edit(props){

  const id = props.item.id;
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [ambit, setAmbit] = useState(props.item.ambit);

  const [guardant, setGuardant] = useState(false);

  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');
  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');

  const nou = (props.item.id===0);

  const nomChange_ca = (e) =>{
    const n = e.target.value.trim();
    setNom_ca(n);
    if(n.length>0){ setErrorNom_ca('');}
  }
  const nomChange_en = (e) =>{
    const n = e.target.value.trim();
    setNom_en(n);
    if(n.length>0){ setErrorNom_en('');}
  }
  const nomChange_es = (e) =>{
    const n = e.target.value.trim();
    setNom_es(n);
    if(n.length>0){ setErrorNom_es('');}
  }

  const selectChange = (value) =>{
    setAmbit(value);
    if(value!==0){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }


  const submitForm = async (valors) => {

      let correcte = true;

      if(nom_ca.length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(nom_en.length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(nom_es.length === 0){
        setErrorNom_es('error');
        correcte = false;
      }

      if(!ambit){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        nom_ca: entities.encode(nom_ca),
        nom_en: entities.encode(nom_en),
        nom_es: entities.encode(nom_es),
        ambit: ambit
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/professors/carrecs/existents' : '/professors/carrecs/existents/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormProfessorCarrecs: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: ProfessorCarrecs guardar: ', error);
      }

  }



    return(
    <div style={{padding:'10px 24px 35px 0px'}}>
      <Form onFinish={submitForm} style={{marginTop:'35px'}}
        layout="horizontal"
        labelCol={{span:2}}
        wrapperCol={{span:9}}
        colon={true}
        autoComplete="off"
      >

          <Form.Item validateStatus={errorNom_ca} label={props.t('generic.nom')+' CA'} required={true}>
            <Input name="nom_ca" placeholder={props.t('generic.nom')} onChange={nomChange_ca} defaultValue={nom_ca}/>
          </Form.Item>
          <Form.Item validateStatus={errorNom_en} label={props.t('generic.nom')+' EN'} required={true}>
            <Input name="nom_en" placeholder={props.t('generic.nom')} onChange={nomChange_en} defaultValue={nom_en}/>
          </Form.Item>
          <Form.Item validateStatus={errorNom_es} label={props.t('generic.nom')+' ES'} required={true}>
            <Input name="nom_es" placeholder={props.t('generic.nom')} onChange={nomChange_es} defaultValue={nom_es}/>
          </Form.Item>

          <Form.Item
            validateStatus={errorSelect}
            help={errorSelectText}
            label={props.t('professors_relacions.ambit')}
          >
            <Select defaultValue={ambit} defaultActiveFirstOption={true} onChange={selectChange}>
              <Select.Option key="classes" value="classes">{props.t('menu.Classes')}</Select.Option>
              <Select.Option key="cursos" value="cursos">{props.t('menu.Cursos')}</Select.Option>
              <Select.Option key="cicles" value="cicles">{props.t('menu.Cicles')}</Select.Option>
              <Select.Option key="etapes" value="etapes">{props.t('menu.Etapes')}</Select.Option>
              <Select.Option key="assignatures" value="assignatures">{props.t('menu.Assignatures')}</Select.Option>
              <Select.Option key="extraescolars" value="extraescolars">{props.t('menu.Extraescolars')}</Select.Option>
              <Select.Option key="transport" value="transport">{props.t('menu.Transport')}</Select.Option>
              <Select.Option key="acollida" value="acollida">{props.t('menu.Acollida')}</Select.Option>
              <Select.Option key="menjador" value="menjador">{props.t('menu.Menjador')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
            <Row type="flex" justify="start">
              <Col>
                <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );

}
export default translate(Edit);
