import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { DeleteOutlined, EditOutlined, CopyOutlined, SyncOutlined, TeamOutlined } from '@ant-design/icons';
//import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Modal, Table, Button, Tooltip, Row, Col, Space, Popconfirm } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function LlistaEditableModalNouEdit(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar || perfilUsuari.curs_escolar_actual.id);
  const [llista, setLlista] = useState([]);
  const [itemActual, setItemActual] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [accio, setAccio] = useState('taula');
  const [modalVisible, setModalVisible] = useState(false);
  const [refrescar, setRefrescar] = useState(0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  useEffect(() => {
    buscarDades();
  },[refrescar, props.refrescar]);


  const tancarModal = e =>{
    setRefrescar(refrescar+1);
    setModalVisible(false);
  }


  const buscarDades = async () => {
    setIsLoading(true);
    let l = await props.buscarDades();
    setLlista(l);
    setIsLoading(false);
  }


  const showAdd = () =>{
    let item_buit = {...props.itemBuit};
    if(item_buit.hasOwnProperty('ordre')){
      const max_ordre = Math.max(...llista.map(item=>item.ordre));
      item_buit.ordre = max_ordre + 1;
    }
    setItemActual(item_buit);
    setAccio('afegir');
    setModalVisible(true);
  }

  const showEdit = (item) =>{
    setItemActual(item);
    setAccio('editar');
    setModalVisible(true);
  }

  const showClone = (item) =>{
    if(item.hasOwnProperty('id')){
      let item_clonar = {...item};
      item_clonar.id = 0;
      if(item_clonar.hasOwnProperty('ordre')){
        const max_ordre = Math.max(...llista.map(item=>item.ordre));
        item_clonar.ordre = max_ordre + 1;
      }

      setItemActual(item_clonar);
      setAccio('editar');
      setModalVisible(true);
    }else{
      console.error('No puc clonar aquest objecte, no te camp id:', item);
    }
  }

  const submitDelete = async (id) => {
    await props.delete(id);
    await buscarDades();
  }

  const columns_botons = [
    {
      key: "actions",
      align: "right",
      width: "95px",
      render: (text,record) => (
        <Row type="flex" justify="end" id={"actions_"+record.id}>
        {!props.duplica ? null :
          <Col span={8}>
            <Tooltip placement="top" title={props.t('generic.clone')}>
              <Button icon={<CopyOutlined />} onClick={(e) => showClone(record)} ghost="true" className="BotoTaula"/>
            </Tooltip>
          </Col>
          }
          {!props.edit ? null :
          <Col span={props.duplica ? 8 : 12 }>
            <Tooltip placement="top" title={props.t('generic.modify')}>
              <Button icon={<EditOutlined />} onClick={(e) => showEdit(record)} ghost="true" className="BotoTaula"/>
            </Tooltip>
          </Col>
          }
          {!props.delete ? null :
          <Col span={props.duplica ? 8 : 12 }>
            <Tooltip placement="top" title={props.t('generic.delete')}>
              <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitDelete(record.id)}>
                <Button icon={<DeleteOutlined />} data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
              </Popconfirm>
            </Tooltip>
          </Col>
          }
        </Row>
    )
    }
  ];

  let columns = null;
  if(props.columns){
    if(props.duplica || props.edit || props.delete){
      columns = [...props.columns, ...columns_botons];
    }else{
      columns = [...props.columns];
    }
  }

  return (
    <>
      <div style={{textAlign:'right', marginBottom:'25px'}}>
        <Row type="flex" justify="space-between">
          <Col>
            <div>
              { props.icon_creats
                ? <props.icon_creats style={{color:'#C2C2C2'}}/>
                : <TeamOutlined style={{color:'#C2C2C2'}}/>
              }
              <span style={{color:'#C2C2C2'}}>&nbsp;{typeof llista !== "undefined" ? llista.length : 0}&nbsp;{props.txt_creats}</span>
            </div>
          </Col>
          <Col>
            <Space>
            { props.btn_extra ? props.btn_extra : null}
            { !props.add ? null :
              <Button type="primary" onClick={showAdd}>{props.txt_nou}</Button>
            }
            </Space>
          </Col>
        </Row>
      </div>
      <Table
      scroll={props.scroll || null}  //scroll={{ x: 1500}}
      summary={props.dadesMostradesCallback ? props.dadesMostradesCallback : null}
      loading={isLoading}
      locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
      columns={columns}
      dataSource={llista}
      expandedRowRender={props.expandedRowRender || null}
      rowSelection={props.rowSelection || null}
      pagination={false}
      rowKey={props.rowKey || "id"}
      onRow={(record, rowIndex) => {
        if(!props.edit){
          return null;
        }else{
          return {
            onClick: event => {}, //onClick: (e) => showEdit(record), // click row
            onDoubleClick: (e) => showEdit(record), // double click row
            onContextMenu: (e) => {e.preventDefault(); showEdit(record);}, //event => {}, // right button click row
          };
        }
      }}
      size={props.size || "small"}
      tableLayout="undefined"
      >
        {props.children}
        {/*<Column
          key="actions"
          align="right"
          //width={props.duplica ? "110px" : "95px"}
          width="95px"
          render={(text,record) => (
            <Row type="flex" justify="end" id={"actions_"+record.id}>
            {!props.duplica ? null :
              <Col span={8}>
                <Tooltip placement="top" title={props.t('generic.clone')}>
                  <Button icon={<CopyOutlined />} onClick={(e) => showClone(record)} ghost="true" className="BotoTaula"/>
                </Tooltip>
              </Col>
              }
              <Col span={props.duplica ? 8 : 12 }>
                <Tooltip placement="top" title={props.t('generic.modify')}>
                  <Button icon={<EditOutlined />} onClick={(e) => showEdit(record)} ghost="true" className="BotoTaula"/>
                </Tooltip>
              </Col>
              {!props.delete ? null :
              <Col span={props.duplica ? 8 : 12 }>
                <Tooltip placement="top" title={props.t('generic.delete')}>
                  <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>submitDelete(record.id)}>
                    <Button icon={<DeleteOutlined />} data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
                  </Popconfirm>
                </Tooltip>
              </Col>
              }
            </Row>
        )}
        />*/}
      </Table>

    <Modal
        key="pares"
        title={accio==='editar' ? entities.decode(itemActual.alumne_nom) : props.t('generic.add')}
        visible={modalVisible}
        footer={null}
        onCancel={tancarModal}
        width={props.modal_width ? props.modal_width : 600}
        style={{top:20}}
        //bodyStyle={{ height: "90vh", overflow: "auto" }}
        destroyOnClose={true}
        forceRender={true}
      >

      {accio==='editar'
        ? <props.edit item={itemActual} id_curs_escolar={id_curs_escolar} callback={tancarModal} modal={true}/>
        : <props.add item={itemActual} id_curs_escolar={id_curs_escolar} callback={tancarModal} modal={true}/>
      }
    </Modal>
  </>
);

}
export default translate(LlistaEditableModalNouEdit);
