import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Divider, Form, Input, Button, Typography, Space, Select, Switch, message } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function DocumentsAfegir(props){

  const [id, setId] = useState(props.carpeta.id);

  const [titol, setTitol] = useState(entities.decode(props.carpeta.titol));
  const [subtitol, setSubtitol] = useState(entities.decode(props.carpeta.subtitol));
  const [carpeta_personal, setCarpeta_personal] = useState(props.carpeta.carpeta_personal);
  const [permisos, setPermisos] = useState(props.carpeta.permisos);
  const [visible, setVisible] = useState(props.carpeta.visible == 1 ? true : false);

  const [guardant, setGuardant] = useState(false);
  const [nou, setNou] = useState(props.carpeta.id===0);

  const [usuaris_read, setUsuaris_read] = useState(props.carpeta.permisos.read.usuaris);
  const [usuaris_write, setUsuaris_write] = useState(props.carpeta.permisos.write.usuaris);
  const [classes_read, setClasses_read] = useState(props.carpeta.permisos.read.classes);
  const [classes_write, setClasses_write] = useState(props.carpeta.permisos.write.classes);
  const [cursos_read, setCursos_read] = useState(props.carpeta.permisos.read.cursos);
  const [cursos_write, setCursos_write] = useState(props.carpeta.permisos.write.cursos);
  const [cicles_read, setCicles_read] = useState(props.carpeta.permisos.read.cicles);
  const [cicles_write, setCicles_write] = useState(props.carpeta.permisos.write.cicles);
  const [etapes_read, setEtapes_read] = useState(props.carpeta.permisos.read.etapes);
  const [etapes_write, setEtapes_write] = useState(props.carpeta.permisos.write.etapes);


  const submitForm = async (valors) => {

    let correcte = true;


    if(!correcte){
      return false;
    }

    setGuardant(true);

    let dades = {
      id,
      titol: entities.encode(titol),
      subtitol: entities.encode(subtitol),
      carpeta_personal,
      permisos: {
        read: {
          alumnes: props.carpeta.permisos.read.alumnes,
          usuaris: usuaris_read,
          classes: classes_read,
          cursos: cursos_read,
          cicles: cicles_read,
          etapes: etapes_read
        },
        write: {
          alumnes: props.carpeta.permisos.write.alumnes,
          usuaris: usuaris_write,
          classes: classes_write,
          cursos: cursos_write,
          cicles: cicles_write,
          etapes: etapes_write

        }
      },
      visible
    }

    let method = nou?'POST':'PUT';
    let url_api = nou? '/documents/carpeta' : '/documents/carpeta/'+id;

    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: guardant DocumentsAfegir: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: DocumentsAfegir guardar: ', error);
    }

  }

  return(
    <>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={3}>{nou ? props.t('documents.crear_carpeta') : props.t('documents.carpeta')}</Typography.Title>
    </div>
    <Form onFinish={submitForm}
        layout="vertical"
        colon={true}
        autoComplete="new-password"
      >
        <Form.Item label={props.t('documents.label_titol')}>
          <Input placeholder={props.t('documents.label_titol')} onChange={(e) => setTitol(e.target.value)} value={titol} autoComplete="çlayskdfjçalkdfjç"/>
        </Form.Item>
        <Form.Item label={props.t('documents.label_subtitol')}>
          <Input placeholder={props.t('documents.label_subtitol')} onChange={(e) => setSubtitol(e.target.value)} value={subtitol} autoComplete="ñlasbkdfjñalkdfj"/>
        </Form.Item>

        <Row>
          <Col span={12}>
            <Divider plain>Lectura</Divider>
          </Col>
          <Col span={12}>
            <Divider plain>Escriptura</Divider>
          </Col>
        </Row>
        {!props.optUsuaris ? null :
          <Row>
            <Col span={12} style={{paddingRight: '0.5rem'}}>
              <Form.Item label={carpeta_personal === true ? props.t('menu.Pares') : props.t('menu.Professors')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setUsuaris_read}
                    value={usuaris_read}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optUsuaris}
                  </Select>
                  <Button onClick={()=>setUsuaris_read(props.usuarisIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setUsuaris_read([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '0.5rem'}}>
              <Form.Item label={carpeta_personal === true ? props.t('menu.Pares') : props.t('menu.Professors')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setUsuaris_write}
                    value={usuaris_write}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optUsuaris}
                  </Select>
                  <Button onClick={()=>setUsuaris_write(props.usuarisIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setUsuaris_write([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
          </Row>
        }
        {!props.optClasses ? null :
          <Row>
            <Col span={12} style={{paddingRight: '0.5rem'}}>
              <Form.Item label={props.t('menu.Classes')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setClasses_read}
                    value={classes_read}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optClasses}
                  </Select>
                  <Button onClick={()=>setClasses_read(props.classesIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setClasses_read([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '0.5rem'}}>
              <Form.Item label={props.t('menu.Classes')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setClasses_write}
                    value={classes_write}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optClasses}
                  </Select>
                  <Button onClick={()=>setClasses_write(props.classesIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setClasses_write([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
          </Row>
        }
        {!props.optCursos ? null :
          <Row>
            <Col span={12} style={{paddingRight: '0.5rem'}}>
              <Form.Item label={props.t('menu.Cursos')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setCursos_read}
                    value={cursos_read}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optCursos}
                  </Select>
                  <Button onClick={()=>setCursos_read(props.cursosIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setCursos_read([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '0.5rem'}}>
              <Form.Item label={props.t('menu.Cursos')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setCursos_write}
                    value={cursos_write}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optCursos}
                  </Select>
                  <Button onClick={()=>setCursos_write(props.cursosIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setCursos_write([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
          </Row>
        }
        {!props.optCicles ? null :
          <Row>
            <Col span={12} style={{paddingRight: '0.5rem'}}>
              <Form.Item label={props.t('menu.Cicles')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setCicles_read}
                    value={cicles_read}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optCicles}
                  </Select>
                  <Button onClick={()=>setCicles_read(props.ciclesIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setCicles_read([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '0.5rem'}}>
              <Form.Item label={props.t('menu.Cicles')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setCicles_write}
                    value={cicles_write}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optCicles}
                  </Select>
                  <Button onClick={()=>setCicles_write(props.ciclesIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setCicles_write([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
          </Row>
        }
        {!props.optEtapes ? null :
          <Row>
            <Col span={12} style={{paddingRight: '0.5rem'}}>
              <Form.Item label={props.t('menu.Etapes')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setEtapes_read}
                    value={etapes_read}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optEtapes}
                  </Select>
                  <Button onClick={()=>setEtapes_read(props.etapesIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setEtapes_read([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '0.5rem'}}>
              <Form.Item label={props.t('menu.Etapes')}>
                <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={props.t('generic.tria_varis')}
                    showArrow={true}
                    onChange={setEtapes_write}
                    value={etapes_write}
                    showSearch
                    filterOption={(input, option) =>
                      HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
                    }
                  >
                    {props.optEtapes}
                  </Select>
                  <Button onClick={()=>setEtapes_write(props.etapesIds)} size="small">{props.t('generic.all')}</Button>
                  <Button onClick={()=>setEtapes_write([])} size="small">{props.t('generic.none')}</Button>
              </Form.Item>
            </Col>
          </Row>
        }



        <Row style={{marginTop: 25, marginBottom: 25}}>
          <Space>
            <span>{props.t('generic.visible_pregunta')}</span>
            <Switch checked={visible} onChange={setVisible} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
        <Row type="flex" justify="center" style={{marginTop: 25, marginBottom: 25}}>
          <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
        </Row>
      </Form>
    </>
  );
}


export default translate(DocumentsAfegir);
