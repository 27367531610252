import React, { useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/pares/Autoritzats';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Pares(props) {

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const buscarDades = async () => {
    const fam = await Api.get('/autoritzats/familia/'+perfilUsuari.account.id_familia);
    if(fam.result.success===1){
      return fam.data.autoritzats;
    }
  }
  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/autoritzats/desautoritza/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  let autoritzatBuit = InitDades.pare();
  autoritzatBuit.id_familia = perfilUsuari.account.id_familia;

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.DadesAutoritzats')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          txt_creats={props.t('autoritzats.creados')}
          txt_nou={props.t('autoritzats.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={autoritzatBuit}>
          <Column
          title={props.t('pares.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom)
          )}
          />
          <Column
          title={props.t('pares.cognom1')}
          key="cognom1"
          render={(text,record) =>(
            entities.decode(record.cognom1)
          )}
          />
          <Column
          title={props.t('pares.cognom2')}
          key="cognom2"
          render={(text,record) =>(
            entities.decode(record.cognom2)
          )}
          />
          <Column
          title={props.t('pares.tel1')}
          key="tel1"
          render={(text,record) => (
            record.tel1
          )}
          responsive={['md']}
          />

        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Pares);
