import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Layout, Drawer, Row, Col, BackTop, Tabs } from 'antd'; //, Drawer
import { BrowserView, MobileView } from 'react-device-detect';
import {
  FundProjectionScreenOutlined,
  HomeOutlined,
  SkinOutlined,
  TeamOutlined,
  UserOutlined,
  FontSizeOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  InboxOutlined,
  FolderOutlined,
  ReconciliationOutlined,
  EuroCircleOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  PartitionOutlined,
  BellOutlined,
  CheckSquareOutlined,
  MedicineBoxOutlined,
  MailOutlined,
  IdcardOutlined,
  AlertOutlined,
  CoffeeOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import { PerfilUsuariContext } from "../context/perfil-usuari";
import { MenuTriatContext } from "../context/menu-triat";

import MenuSuperior from '../components/MenuSuperior';
import MenuSuperiorMobile from '../components/MenuSuperiorMobile';

// Perfil Administrador
import MenuLateral from '../components/MenuLateral';
import MenuLateralAuxiliar from '../components/MenuLateralAuxiliar';
import Dashboard from './Dashboard';
import Users from './perfils/admin/Users';
import UnitatFamiliar from './perfils/admin/UnitatFamiliar';
import Pares from './perfils/admin/Pares';
import Alumnes from './perfils/admin/Alumnes';
//import Tutors from './Tutors';
import Professors from './perfils/admin/Professors';
import CarrecsProfessors from './perfils/admin/ProfessorsCarrecs';
import Classes from './perfils/admin/Classes';
import Cursos from './perfils/admin/Cursos';
import Cicles from './Cicles';
import Etapes from './Etapes';
import Botiga from './botiga/Botiga';
import BotigaTalles from './botiga/Talles';
import Transport from './perfils/admin/Transport';
import Menjador from './perfils/admin/Menjador';
import Extraescolars from './perfils/admin/Extraescolars';
import Assignatures from './Assignatures';
import Acollida from './perfils/admin/Acollida';
import AlumnesTelefons from './LlistatsPDF/AlumnesTelefons';
import AlumnesTotsTelefons from './LlistatsPDF/AlumnesTotsTelefons';
import AlumnesEmails from './LlistatsPDF/AlumnesEmails';
import LlistatsExcel from './LlistatsPDF/LlistatsExcel';
import Documents from './perfils/admin/Documents';
import ComandesRobaNEW from './perfils/admin/ComandesRoba_NEW';
import ComandesRoba from './perfils/admin/ComandesRoba';
import ComandesExcursions from './perfils/admin/ComandesExcursions';
import ComandesEsdeveniments from './perfils/admin/ComandesEsdeveniments';
import ComandesSummerCamp from './perfils/admin/ComandesSummer';
import ComandesSummerCampExterns from './perfils/admin/ComandesSummerExterns';
import ComandesExtraescolars from './perfils/admin/ComandesExtraescolars';
import ComandesTransport from './perfils/admin/ComandesTransport';
import ComandesMenjador from './perfils/admin/ComandesMenjador';
import ComandesAcollida from './perfils/admin/ComandesAcollida';
import QuotesPreus from './perfils/admin/QuotesPreus';
import TipusQuotesPreus from './perfils/admin/TipusQuotesPreus';
import QuotesAlumnes from './perfils/admin/QuotesAlumnes';
import Pressupostos from './perfils/admin/Facturacio';
import Rebuts from './perfils/admin/Rebuts';
import InformeIngressos from './perfils/admin/InformeIngressos';
import InformeIngressosDetall from './perfils/admin/InformeIngressosDetall';
import InformeResumRebutsAnualPerAlumne from './perfils/admin/InformeResumRebutsAnualPerAlumne';
import MotiusAbsencia from './perfils/admin/MotiusAbsencia';
import AdminPassarLlista from './perfils/admin/RollCall';
import ServeiMedic from './perfils/admin/Medic';
import TipusNotificacions from './perfils/admin/NotificacionsTipus';
import NotificacionsEmails from './perfils/admin/NotificacionsEmails';
import NotificacionsAdmin from './perfils/admin/Notificacions';

import CursEscolar from './perfils/admin/CursEscolar';
import MealTimeReport from './perfils/profe/MealTimeReport';
import MealTimeReportFrases from './perfils/admin/MealTimeReportFrases';

import FacturacioImportsRecurrents from './perfils/admin/FacturacioImportsRecurrents';


/*
// Perfil Pares / Responsables
import MenuLateralPares from '../components/MenuLateralPares';
import ParesBotiga from './perfils/pares/Botiga';
import ParesPare from '../components/Formularis/perfils/pares/Pares';
import ParesAlumnes from './perfils/pares/Alumnes';
import ParesFamilia from '../components/Formularis/perfils/pares/UnitatFamiliar';
import ParesBancs from './perfils/pares/Banc';
import ParesMedic from './perfils/pares/Medic';
import ParesAutoritzats from './perfils/pares/Autoritzats';
import ParesDocuments from './perfils/pares/Documents';
import ParesExtraescolars from './perfils/pares/Extraescolars';
import ParesTransport from './perfils/pares/Transport';
import ParesMenjador from './perfils/pares/Menjador';
import ParesAcollida from './perfils/pares/Acollida';
import ParesRebuts from './perfils/pares/Rebuts';
import ParesNotificar from './perfils/pares/Notificacions';
*/

// Perfil Professor
import MenuLateralProfessor from '../components/MenuLateralProfessor';
import ProfeDocuments from './perfils/profe/Documents';
import PassarLlista from './perfils/profe/PassarLlista';
import Notificacions from './perfils/profe/Notificacions';
import ProfeAutoritzats from './perfils/profe/Autoritzats';
import ProfeAcollida from './perfils/profe/Acollida';
import MealTimeReportProfe from './perfils/profe/MealTimeReport';


const { Header, Sider, Content } = Layout;

function Principal(props){

  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [collapsed, setCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState("0");
  const [pestanyes, setPestanyes] = useState([{key: "0", title: <span><HomeOutlined />{props.t('menu.inicio')}</span>, closable: false, contingut: <Dashboard/>}]);
  const [newTabIndex, setNewTabIndex] = useState(1);

  useEffect(() => {
    refrescaContingut(menuTriat);
  },[menuTriat]);


  const anarMenu = ({key}) =>{
    //setPerfilUsuari(perfilUsuari => ({...perfilUsuari, menuTriat: key}));
    setMenuTriat(key);
  };

  const canviPestanyaActiva = (titol, contingut, icona, menu) =>{
    const title = !icona ? titol : <span>{icona}{titol}</span>
    const pest = pestanyes.map(item => item.key === activeTab ? {...item, title, contingut} : item);
    setPestanyes(pest);
  }


  const refrescaContingut = (menu) => {
    let contingut = '';
    let titol = 'titol';
    let icona = null;

    switch (menu) {
      case 'Dashboard':
        titol = props.t('menu.inicio');
        icona = <HomeOutlined />;
        contingut = <Dashboard/>;
        break;
      case 'Usuaris':
        titol = props.t('menu.usuarios');
        icona = <UserOutlined />;
        contingut = <Users/>;
        break;
      case 'UnitatFamiliar':
        titol = props.t('menu.UnitatFamiliar');
        icona = <TeamOutlined />;
        contingut = <UnitatFamiliar/>;
        break;
      case 'Pares':
        titol = props.t('menu.Pares');
        icona = <UserOutlined />;
        contingut = <Pares/>;
        break;
      case 'Alumnes':
        titol = props.t('menu.Alumnes');
        icona = <SkinOutlined />;
        contingut = <Alumnes/>;
        break;
      case 'ServeiMedic':
        titol = props.t('menu.ServeiMedic');
        icona = <MedicineBoxOutlined />;
        contingut = <ServeiMedic/>;
        break;
      case 'Professors':
        titol = props.t('menu.Professors');
        icona = <UserOutlined />;
        contingut = <Professors/>;
        break;
      case 'CarrecsProfessors':
        titol = props.t('menu.CarrecsProfessors');
        icona = <PartitionOutlined />;
        contingut = <CarrecsProfessors/>;
        break;
      case 'Classes':
        titol = props.t('menu.Classes');
        icona = <FundProjectionScreenOutlined />;
        contingut = <Classes/>;
        break;
      case 'Cursos':
        titol = props.t('menu.Cursos');
        icona = <FundProjectionScreenOutlined />;
        contingut = <Cursos/>;
        break;
      case 'Cicles':
        titol = props.t('menu.Cicles');
        icona = <FundProjectionScreenOutlined />;
        contingut = <Cicles/>;
        break;
      case 'Etapes':
        titol = props.t('menu.Etapes');
        icona = <FundProjectionScreenOutlined />;
        contingut = <Etapes/>;
        break;
      case 'Botiga':
        titol = props.t('menu.Botiga');
        icona = <ShoppingCartOutlined />;
        contingut = <Botiga/>;
        break;
      case 'BotigaTalles':
        titol = props.t('menu.BotigaTalles');
        icona = <FontSizeOutlined />;
        contingut = <BotigaTalles/>;
        break;
      case 'Transport':
        titol = props.t('menu.Transport');
        icona = <ShoppingCartOutlined />;
        contingut = <Transport/>;
        break;
      case 'Menjador':
        titol = props.t('menu.Menjador');
        icona = <ShoppingCartOutlined />;
        contingut = <Menjador/>;
        break;
      case 'Extraescolars':
        titol = props.t('menu.Extraescolars');
        icona = <ShoppingCartOutlined />;
        contingut = <Extraescolars/>;
        break;
      case 'Assignatures':
        titol = props.t('menu.Assignatures');
        icona = <BookOutlined />;
        contingut = <Assignatures/>;
        break;
      case 'Acollida':
        titol = props.t('menu.Acollida');
        icona = <ShoppingCartOutlined />;
        contingut = <Acollida/>;
        break;
      case 'AlumnesTelefons':
        titol = props.t('menu.AlumnesTelefons');
        icona = <FilePdfOutlined />;
        contingut = <AlumnesTelefons />;
        break;
      case 'AlumnesTotsTelefons':
        titol = props.t('menu.AlumnesTotsTelefons');
        icona = <FilePdfOutlined />;
        contingut = <AlumnesTotsTelefons />;
        break;
      case 'AlumnesEmails':
        titol = props.t('menu.AlumnesEmails');
        icona = <FilePdfOutlined />;
        contingut = <AlumnesEmails />;
        break;
      case 'LlistatsExcel':
        titol = props.t('menu.LlistatsExcel');
        icona = <FileExcelOutlined />;
        contingut = <LlistatsExcel />;
        break;
      case 'Documents':
        titol = props.t('menu.Documents');
        icona = <FolderOutlined />;
        contingut = <Documents />;
        break;
      case 'ComandesRobaNEW':
        titol = props.t('menu.ComandesRoba');
        icona = <InboxOutlined />;
        contingut = <ComandesRobaNEW />;
        break;
      case 'ComandesRoba':
        titol = props.t('menu.ComandesRoba');
        icona = <InboxOutlined />;
        contingut = <ComandesRoba />;
        break;
      case 'ComandesExcursions':
        titol = props.t('menu.ComandesExcursions');
        icona = <InboxOutlined />;
        contingut = <ComandesExcursions/>;
        break;
      case 'ComandesEsdeveniments':
        titol = props.t('menu.ComandesEsdeveniments');
        icona = <InboxOutlined />;
        contingut = <ComandesEsdeveniments/>;
        break;
      case 'ComandesSummerCamp':
        titol = props.t('menu.ComandesSummerCamp');
        icona = <InboxOutlined />;
        contingut = <ComandesSummerCamp/>;
        break;
      case 'ComandesSummerCampEXT':
        titol = props.t('menu.ComandesSummerCamp')+' EXT';
        icona = <InboxOutlined />;
        contingut = <ComandesSummerCampExterns/>;
        break;
      case 'ComandesTransport':
        titol = props.t('menu.Transport');
        icona = <InboxOutlined />;
        contingut = <ComandesTransport/>;
        break;
      case 'ComandesExtraescolars':
        titol = props.t('menu.Extraescolars');
        icona = <InboxOutlined />;
        contingut = <ComandesExtraescolars />;
        break;
      case 'ComandesMenjador':
        titol = props.t('menu.Menjador');
        icona = <InboxOutlined />;
        contingut = <ComandesMenjador/>;
        break;
      case 'ComandesAcollida':
        titol = props.t('menu.Acollida');
        icona = <InboxOutlined />;
        contingut = <ComandesAcollida/>;
        break;
      case 'QuotesPreus':
        titol = props.t('menu.QuotesPreus');
        icona = <ReconciliationOutlined />;
        contingut = <QuotesPreus/>;
        break;
      case 'TipusQuotesPreus':
        titol = props.t('menu.TipusQuotesPreus');
        icona = <ReconciliationOutlined />;
        contingut = <TipusQuotesPreus/>;
        break;
      case 'CursEscolar':
        titol = props.t('generic.curs_escolar');
        icona = <ReconciliationOutlined />;
        contingut = <CursEscolar/>;
        break;
      case 'QuotesAlumnes':
        titol = props.t('menu.QuotesAlumnes');
        icona = <ReconciliationOutlined />;
        contingut = <QuotesAlumnes/>;
        break;
      case 'Pressupost':
        titol = props.t('menu.Pressupost');
        icona = <EuroCircleOutlined />;
        contingut = <Pressupostos/>;
        break;
      case 'Rebuts':
        titol = props.t('menu.Rebuts');
        icona = <EuroCircleOutlined />;
        contingut = <Rebuts/>;
        break;
      case 'InformeIngressos':
        titol = props.t('menu.InformeIngressos');
        icona = <EuroCircleOutlined />;
        contingut = <InformeIngressos/>;
        break;
      case 'InformeIngressosDetall':
        titol = props.t('menu.InformeIngressosDetall');
        icona = <EuroCircleOutlined />;
        contingut = <InformeIngressosDetall/>;
        break;
      case 'InformeResumRebutsAnualPerAlumne':
        titol = props.t('menu.InformeResumRebutsAnualPerAlumne');
        icona = <EuroCircleOutlined />;
        contingut = <InformeResumRebutsAnualPerAlumne/>;
        break;
      case 'MotiusAbsencia':
        titol = props.t('menu.MotiusAbsencia');
        icona = <BellOutlined />;
        contingut = <MotiusAbsencia/>;
        break;
      case 'AdminPassarLlista':
        titol = props.t('professors_relacions.passarLlista');
        icona = <CheckSquareOutlined />;
        contingut = <AdminPassarLlista/>;
        break;
      case 'TipusNotificacions':
        titol = props.t('menu.TipusNotificacions');
        icona = <MailOutlined />;
        contingut = <TipusNotificacions/>;
        break;
      case 'NotificacionsEmails':
        titol = props.t('menu.NotificacionsEmails');
        icona = <MailOutlined />;
        contingut = <NotificacionsEmails/>;
        break;
      case 'Notificacions_admin':
        titol = props.t('menu.Notificacions');
        icona = <MailOutlined />;
        contingut = <NotificacionsAdmin/>;
        break;
      case 'MealTimeReport':
        titol = props.t('menjador.report.titol') + " - " + props.t('menjador.report.report');
        icona = <AlertOutlined />;
        contingut = <MealTimeReport/>;
        break;
      case 'MealTimeReportFrases':
        titol = props.t('menjador.report.titol') + " - " + props.t('menjador.report.frases');
        icona = <AlertOutlined />;
        contingut = <MealTimeReportFrases/>;
        break;
      case 'ImportsRecurrents':
        titol = props.t('menu.ImportsRecurrents_curt');
        icona = <DollarCircleOutlined />;
        contingut = <FacturacioImportsRecurrents/>;
        break;


//Perfil Pares / Responsables
/*
      case 'Pares_Botiga':
        titol = props.t('menu.Botiga');
        contingut = <ParesBotiga/>;
        break;
      case 'Pares_DadesPersonals':
        titol = props.t('menu.DadesPersonals');
        contingut = <ParesPare/>;
        break;
      case 'Pares_DadesFills':
        titol = props.t('menu.DadesFills');
        contingut = <ParesAlumnes/>;
        break;
      case 'Pares_DadesFamilia':
        titol = props.t('menu.DadesFamilia');
        contingut = <ParesFamilia/>;
        break;
      case 'Pares_ServeiMedic':
      case 'Pares_ServeiMedicArrel':
        titol = props.t('menu.ServeiMedic');
        contingut = <ParesMedic/>;
        break;
      case 'Pares_DadesAutoritzats':
        titol = props.t('menu.DadesAutoritzats');
        contingut = <ParesAutoritzats/>;
        break;
      case 'Pares_Documents':
        titol = props.t('menu.Documents');
        contingut = <ParesDocuments/>;
        break;
      case 'Pares_ElsRebuts':
        titol = props.t('menu.ElsMeusRebuts');
        contingut = <ParesRebuts/>;
        break;
      case 'Pares_DadesFacturacio':
        titol = props.t('menu.DadesFacturacio');
        contingut = <ParesBancs/>;
        break;

      case 'Pares_Transport':
        titol = props.t('menu.Transport');
        contingut = <ParesTransport/>;
        break;
      case 'Pares_Extraescolars':
        titol = props.t('menu.Extraescolars');
        contingut = <ParesExtraescolars/>;
        break;
      case 'Pares_Menjador':
        titol = props.t('menu.Menjador');
        contingut = <ParesMenjador/>;
        break;
      case 'Pares_Acollida':
        titol = props.t('menu.Acollida');
        contingut = <ParesAcollida/>;
        break;
      case 'Pares_Notificar':
        titol = props.t('menu.Notificacions');
        contingut = <ParesNotificar/>;
        break;
*/
//Profe
      case 'Profe_Documents':
        titol = props.t('menu.Documents');
        icona = <FolderOutlined />;
        contingut = <ProfeDocuments/>;
        break;
      case 'Profe_PassarLlista':
        titol = props.t('professors_relacions.passarLlista');
        icona = <CheckSquareOutlined />;
        contingut = <PassarLlista/>;
        break;
      case 'Profe_Notificacions':
        titol = props.t('professors_relacions.notificacions');
        icona = <MailOutlined />;
        contingut = <Notificacions/>;
        break;
      case 'Profe_Autoritzats':
      case 'Profe_Autoritzats2':
        titol = props.t('menu.DadesAutoritzats');
        icona = <IdcardOutlined />;
        contingut = <ProfeAutoritzats/>;
        break;
      case 'Profe_Acollida':
        titol = props.t('acollida.titol');
        icona = <IdcardOutlined />;
        contingut = <ProfeAcollida/>;
        break;
      case 'Profe_MealTimeReport':
        titol = props.t('menjador.report.titol');
        icona = <CoffeeOutlined />;
        contingut = <MealTimeReportProfe/>;
        break;


//logout
      case 'Logout':
        contingut = props.t('menu.cerrar_sesion');
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtRefreshToken');
        sessionStorage.removeItem('perfil');
        props.logout();
        break;
      default:
        contingut = props.t('menu.cerrar_sesion');
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtRefreshToken');
        sessionStorage.removeItem('perfil');
        props.logout();
    }
      canviPestanyaActiva(titol, contingut, icona, menu);
    }


    let menuSegonsPerfil = null;
    let colorHeader = '#fdd998';
    switch (perfilUsuari.account.perfil) {
      case 'Administrador':
        menuSegonsPerfil = <MenuLateral/>;
        break;
      case 'Auxiliar':
        menuSegonsPerfil = <MenuLateralAuxiliar/>;
        //colorHeader = '#bcaaa4';
        colorHeader = '#5AB7B2';
        break;
      /*case 'Pare':
        menuSegonsPerfil = <MenuLateralPares/>;
        break;
      */
      case 'Professor':
        menuSegonsPerfil = <MenuLateralProfessor/>;
        break;
    }

    const toggleCollapsed = () =>{
      setCollapsed(!collapsed);
    }

    const onEdit = (targetKey, action) => {
      //this[action](targetKey);
      if(action === 'remove'){
        remove(targetKey);
      }
      if(action === 'add'){
        add();
      }

    };

    const remove = targetKey => {

    let newActiveKey = activeTab;
    let lastIndex;
    pestanyes.forEach((pane, i) => {
      if (pane.key === targetKey) {
        lastIndex = i - 1;
      }
    });


    const newPanes = pestanyes.filter(pane => pane.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }

    setPestanyes(newPanes);
    setActiveTab(newActiveKey);

  };

  const add = () => {

    let activeKey = newTabIndex.toString();
    setNewTabIndex(newTabIndex + 1);
    const newPanes = [...pestanyes];
    newPanes.push({ title: <span><HomeOutlined />{props.t('menu.inicio')}</span>, contingut: <Dashboard/>, key: activeKey, closable: true });
    setPestanyes(newPanes);
    setActiveTab(activeKey);
  };

    return(
      <div>
        <Layout style={{backgroundColor: 'white', height: '100vh'}}>
          <Header className="menu_superior" style={{backgroundColor: colorHeader}}> {/* #9fa8da*/}
            <BrowserView>
              <MenuSuperior toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
            </BrowserView>
            <MobileView>
              <MenuSuperiorMobile toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
            </MobileView>
          </Header>
          <Layout>
            <BrowserView>
              <Sider trigger={null} width="240" breakpoint="lg" collapsible collapsed={collapsed} className="MenuLateral">
                {menuSegonsPerfil}
              </Sider>
            </BrowserView>
            <MobileView>
              <Drawer
              width="325"
              title={<span style={{ fontSize: '16px', color: 'black' }}>{props.t('login.User')}: {perfilUsuari.account.username}</span>}
              placement="left"
              closable={true}
              onClose={toggleCollapsed}
              visible={collapsed}
              >
                {menuSegonsPerfil}
              </Drawer>
            </MobileView>
            <Content id="contenidor" style={{backgroundColor: 'white', borderTop: '1px solid #F5F5F5'}}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Tabs
                  defaultActiveKey="0"
                  type="editable-card"
                  onChange={(activeTab) => setActiveTab(activeTab)}
                  activeKey={activeTab}
                  onEdit={onEdit}
                >
                {pestanyes.map(pane => (
                  <Tabs.TabPane tab={pane.title} key={pane.key} closable={pane.closable}>
                    <BackTop visibilityHeight={250} target={() => document.getElementById('contenidor')}/>
                    {pane.contingut}
                  </Tabs.TabPane>
                ))}
                </Tabs>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }


export default translate(Principal);
