import React, { useState, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Form, Input, Button, Typography, message } from 'antd';
import sha512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

import { PerfilUsuariContext } from "../../context/perfil-usuari";
import { Api, Encriptar } from '../../helpers';



function NewPassword(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [guardant, setGuardant] = useState(false);
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();

  const [errorPassword1, setErrorPassword1] = useState('');
  const [errorPassword2, setErrorPassword2] = useState('');
  const [errorPassword2Text, setErrorPassword2Text] = useState('');


  const onChangePassword1 = (valor) => {
    if(valor.length>0){
      setErrorPassword1('');
    }
    setPassword1(valor);
  }

  const onChangePassword2 = (valor) => {
    if(valor.length>0){
      setErrorPassword2('');
      setErrorPassword2Text('')
    }
    setPassword2(valor);
  }


  const submitForm = async (valors) => {

    let correcte = true;

    if(!password1){
      setErrorPassword1('error');
      correcte = false;
    }
    if(!password2){
      setErrorPassword2('error');
      correcte = false;
    }

    if(password1!==password2){
      setErrorPassword2('error');
      setErrorPassword2Text(props.t('generic.contrasenya_diferent'));
      correcte = false;
    }

    if(!correcte){
      return false;
    }

    setGuardant(true);

    const password_hash = Base64.stringify(sha512(password1));

    let dades = {
      password: password_hash
    }

    try{
      const resposta = await Api.myFetch('/users/contrasenya/'+perfilUsuari.account.id, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        const verificar_enc = Encriptar.perGuardarDesHash(password_hash);
        //setPerfilUsuari({...perfilUsuari, verificar: verificar_enc});
        setPerfilUsuari({...perfilUsuari, verificar: verificar_enc, account:{ ...perfilUsuari.account, obligar_modificar_password: 0}});
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        
        if(props.callback){
          props.callback();
        }
      }else{
        setGuardant(false);
        //console.error('Error: guardant NewPassword: ',resposta);
        message.config({top: 50});
        //message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: NewPassword guardar: ', error);
    }

  }

  return(
    <>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={3}>{props.t('generic.obligatori_nova_contrasenya')}</Typography.Title>
    </div>
    <Form onFinish={submitForm}
        layout="vertical"
        colon={true}
        autoComplete="new-password"
      >
        <Form.Item label={props.t('generic.nova_contrasenya')} validateStatus={errorPassword1}>
          <Input.Password name="qualsevol_cosa1" placeholder={props.t('generic.nova_contrasenya')} onChange={(e) => onChangePassword1(e.target.value)} defaultValue={password1} autoComplete="çlaskdfjçalkdfjç"/>
        </Form.Item>
        <Form.Item label={props.t('generic.repetir_contrasenya')} validateStatus={errorPassword2} help={errorPassword2Text}>
          <Input.Password name="qualsevol_cosa2" placeholder={props.t('generic.repetir_contrasenya')} onChange={(e) => onChangePassword2(e.target.value)} defaultValue={password2} autoComplete="ñlaskdfjñalkdfj"/>
        </Form.Item>
        <Row type="flex" justify="center" style={{marginTop: 25, marginBottom: 25}}>
          <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
        </Row>
      </Form>
    </>
  );
}


export default translate(NewPassword);
