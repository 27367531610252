import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import { Tabs, Button, Radio } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import Producte from './Producte';
import MevesCompres from './MevesCompres';
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import { Api } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
const entities = new AllHtmlEntities();

function Botiga(props){
  const [categoria, setCategoria] = useState('1');
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fillActual, setFillActual] = useState(1);
  const [llistaFills, setLlistaFills] = useState([]);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const buscarDades = async () => {
    setIsLoading(true);
    let llista_f = [];
    const fills = await Api.get(`/alumnes/familia/${perfilUsuari.account.id_familia}`);
    console.log('dins: buscarDadesFills',fills)
    if(fills.result.success===1){
      setFillActual(fills.data.alumnes[0].id);
      llista_f = fills.data.alumnes.map((item) =>{
        console.log(item)
        return(
          <Radio.Button key={item.id} value={item.id}>{entities.decode(item.nom)}</Radio.Button>
        );
      });
      setLlistaFills(llista_f);
    }

    const c = await Api.get('/botiga/categories');
    if(c.result.success===1){
      /*const llista_c = c.data.categories.map((item) =>{
            return(
              <Tabs.TabPane tab={entities.decode(item.text[props.t('idioma')])} key={item.id_categoria}>
                <Producte id_categoria={item.id_categoria} id_alumne={fillActual} titol={item.text} idioma={props.t('idioma')}/>
              </Tabs.TabPane>
            );
      });*/
      //setCategories(llista_c);
      setCategories(c.data.categories);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[props.t('idioma')]);
  //},[getLanguage()]);


//const operations = <Button onClick={() => buscarDades()} type="dashed" shape="circle" icon={<SyncOutlined />} />;

  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menu.Botiga')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :
        <>
          <Radio.Group defaultValue={fillActual} buttonStyle="solid" onChange={(e) => setFillActual(e.target.value)}>
            {llistaFills}
          </Radio.Group>
          <Tabs defaultActiveKey={categoria} onChange={setCategoria}>
            {
              categories.map((item) =>{
                  return(
                    <Tabs.TabPane tab={entities.decode(item.text[props.t('idioma')])} key={item.id_categoria}>
                      <Producte id_categoria={item.id_categoria} id_alumne={fillActual} titol={item.text} idioma={props.t('idioma')}/>
                    </Tabs.TabPane>
                  )
                })
            }

            <Tabs.TabPane tab={props.t('botiga.lesMevesCompres')} key={99999}>
              <MevesCompres id_alumne={fillActual}/>
            </Tabs.TabPane>
          </Tabs>
        </>
      }
      </div>
    </ContainerPagina>
  );
}

export default translate(Botiga);
