
import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Select, Button } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { Api, InitDades, HtmlStrings } from '../helpers';

const entities = new AllHtmlEntities();

function TriaTipusIngres(props){
  const [triat, setTriat] = useState(props.defaultValue || '');
  const [ingressos, setIngressos] = useState([]);

  const buscarDades = async () => {

    let ingressos_temp = [];
    let tipus_temp = InitDades.tipus_ingressos_llista();

    tipus_temp.sort();

    tipus_temp.map((item) =>{
      ingressos_temp.push(
        <Select.Option key={item} value={item}>
          {props.t(`rebuts.tipus.${item}`)}
        </Select.Option>
      );
    })
    setIngressos(ingressos_temp)

  }

  useEffect(() => {
    buscarDades();
  },[]);


  const handleChange = (value) => {
    setTriat(value);
    if(props.onChange){
      props.onChange(value);
    }
  }


    return(
      <Select
          placeholder={props.t('generic.tria')}
          showArrow={true}
          onChange={handleChange}
          value={triat}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {ingressos}
        </Select>

    );
}
export default translate(TriaTipusIngres);
