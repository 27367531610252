import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Layout, Row, Col, BackTop, Drawer, Modal } from 'antd'; //, Drawer

import { MenuTriatContext } from "../context/menu-triat";
import { PerfilUsuariContext } from "../context/perfil-usuari";

import MenuSuperiorMobile from '../components/MenuSuperiorMobile';

// Perfil Administrador
import Dashboard from './Dashboard';

// Perfil Prefe nomes Acollida
import MenuLateralAcollida from '../components/MenuLateralAcollida';
import DashboardAcollida from './perfils/profe/Acollida';
import NewPassword from '../components/PantallesObligadesInici/NewPassword';

const { Header, Sider, Content } = Layout;

function Principal(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [collapsed, setCollapsed] = useState(false);
  const [mostrarNewPassword, setMostrarNewPassword] = useState(0);


  useEffect(() => {
    setMostrarNewPassword(perfilUsuari.account.obligar_modificar_password === 1);
  },[perfilUsuari]);


  const anarMenu = ({key}) =>{
    setMenuTriat(key);
  };

    let contingut = '';

    switch (menuTriat) {
      case 'Dashboard':
        contingut = <DashboardAcollida/>;
        break;


//logout
      case 'Logout':
        contingut = props.t('menu.cerrar_sesion');
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtRefreshToken');
        sessionStorage.removeItem('perfil');
        props.logout();
        break;
      default:
        contingut = props.t('menu.cerrar_sesion');
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtRefreshToken');
        sessionStorage.removeItem('perfil');
        props.logout();
    }


    let colorHeader = '#0091ea'; //'#fdd998';

    const toggleCollapsed = () =>{
      setCollapsed(!collapsed);
    }

    return(
      <div>
      <Modal
      key="NewPassword"
      visible={mostrarNewPassword}
      footer={null}
      width={550}
      style={{top:20}}
      bodyStyle={{ height: "350px"}}
      destroyOnClose={true}
      //forceRender={true}
      maskClosable={false}
      closable={false}
      >
        <NewPassword/>
      </Modal>

        <Layout style={{backgroundColor: 'white', height: '100vh'}}>
          <Header className="menu_superior" style={{backgroundColor: colorHeader}}>
            <MenuSuperiorMobile toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
          </Header>
          <Layout>
            <Drawer
            title={<span style={{ fontSize: '16px', color: 'black' }}>{props.t('login.User')}: {perfilUsuari.account.username}</span>}
            placement="left"
            closable={true}
            onClose={toggleCollapsed}
            visible={collapsed}
          >
            <MenuLateralAcollida/>
          </Drawer>

            <Content id="contenidor" style={{backgroundColor: 'white', borderTop: '1px solid #F5F5F5'}}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                 <BackTop visibilityHeight={250} target={() => document.getElementById('contenidor')}/>
                {contingut}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }


export default translate(Principal);
