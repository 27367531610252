import React from 'react';
import { translate } from 'react-multi-lang';
import { Table, Divider } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/TransportParades';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function TransportParades(props) {


  const buscarDades = async () => {
    const trans = await Api.get(`/transport/parades/${props.id_transport}`);
    if(trans.result.success!==1){return [];}
    return trans.data.parades;
  }

  const eliminarDelTot = async (id) => {
    const fam = await Api.myFetch('/transport/parada/'+id, 'DELETE');
    if(fam.result.success===1){

    }

  }

  let itemBuit = InitDades.transport_parades();
  itemBuit.id_transport = props.id_transport;

  return(
    <div style={{marginTop:'2rem'}}>
      <Divider orientation="left">
          {props.t('parades.titol')}
      </Divider>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          rowKey="id"
          txt_creats={props.t('parades.creados')}
          txt_nou={props.t('parades.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={eliminarDelTot}
          itemBuit={itemBuit}>
          <Column
          title={props.t('parades.ordre')}
          key="ordre"
          render={(text,record) => (
            <span>{record.ordre}</span>
          )}
          />
          <Column
          title={props.t('parades.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom[props.t('idioma')])
          )}/>
          <Column
          title={props.t('parades.hora')}
          key="hora"
          render={(text,record) =>(
            <span>{record.hora}:{String(record.minut).padStart(2,'0')}</span>
          )}/>


        </LlistaEditable>
      </div>
    </div>
  );

}

export default translate(TransportParades);
