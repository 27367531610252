import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import {Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, message } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../helpers';


const entities = new AllHtmlEntities();

function Edit(props){

  const [id, setId] = useState(props.item.id);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [adress, setAdress] = useState(entities.decode(props.item.adress));
  const [ciutat, setCiutat] = useState(entities.decode(props.item.ciutat));
  const [cp, setCp] = useState(props.item.cp);
  const [provincia, setProvincia] = useState(entities.decode(props.item.provincia));
  const [pais, setPais] = useState(entities.decode(props.item.pais));
  const [observacions, setObservacions] = useState(entities.decode(props.item.observacions));

  const [llistat_pares, setLlistat_pares] = useState([]);

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nou, setNou] = useState(props.item.id===0);

  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');
  const [errorAdress, setErrorAdress] = useState('');
  const [errorCiutat, setErrorCiutat] = useState('');
  const [errorCp, setErrorCp] = useState('');
  const [errorProvincia, setErrorProvincia] = useState('');


  const buscarDades = async () => {
    setIsLoading(true);
    const p = await Api.get(`/pares/familia/${id_familia}`);
    if(p.result.success===1){
      console.log(p)
      const llista_p = p.data.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.nom + ' ' + item.cognom1 + ' ' + item.cognom2}
              </Select.Option>
            );
      });
      setLlistat_pares(llista_p);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);


  const submitForm = async (valors) => {


    let correcte = true;
    if(!adress){
      setErrorAdress('error');
      correcte = false;
    }
    if(!ciutat){
      setErrorCiutat('error');
      correcte = false;
    }
    if(!cp){
      setErrorCp('error');
      correcte = false;
    }
    if(!provincia){
      setErrorProvincia('error');
      correcte = false;
    }

    if(!correcte){return false}

    setGuardant(true);

    let dades = {
        id,
        id_familia,
        id_pare,
        adress: entities.encode(adress),
        ciutat: entities.encode(ciutat),
        cp,
        provincia: entities.encode(provincia),
        pais: entities.encode(pais),
        observacions: entities.encode(observacions)
      }

      let method = nou?'POST':'PUT';

      let url_api = nou? '/adress' : '/adress/'+id;

      console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit Adress: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Adress guardar: ', error);
      }

  }

  const selectChange = (value) =>{
    setId_pare(value);
    if(value!==0){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }


  let posicio_botons = "start";
  if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }



  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <div style={{padding:'10px 24px 10px 0px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:6}}
        wrapperCol={{span:18}}
        colon={true}
        autoComplete="off"
      >
    <Row>
      <Col span={24}>

        <Form.Item label={props.t('banc.responsable')}>
          <Select defaultValue={id_pare === 0 ? "" : id_pare} onChange={selectChange}>
            {llistat_pares}
          </Select>
        </Form.Item>

        <Form.Item label={props.t('families.adress')} validateStatus={errorAdress} required={true}>
          <Input name="adress" placeholder={props.t('families.adress')} onChange={(e) => setAdress(e.target.value)} defaultValue={adress}/>
        </Form.Item>
        <Form.Item label={props.t('families.ciutat')} validateStatus={errorCiutat} required={true}>
          <Input name="ciutat" placeholder={props.t('families.ciutat')} onChange={(e) => setCiutat(e.target.value)} defaultValue={ciutat}/>
        </Form.Item>
        <Form.Item label={props.t('families.cp')} validateStatus={errorCp} required={true}>
          <Input name="cp" placeholder={props.t('families.cp')} onChange={(e) => setCp(e.target.value)} defaultValue={cp}/>
        </Form.Item>
        <Form.Item label={props.t('families.provincia')} validateStatus={errorProvincia} required={true}>
          <Input name="cp" placeholder={props.t('families.provincia')} onChange={(e) => setProvincia(e.target.value)} defaultValue={provincia}/>
        </Form.Item>
        <Form.Item label={props.t('families.pais')}>
          <Input name="pais" placeholder={props.t('families.pais')} onChange={(e) => setPais(e.target.value)} defaultValue={pais}/>
        </Form.Item>

        <Form.Item label={props.t('banc.observacions')}>
          <Input.TextArea  name="observacions" placeholder={props.t('banc.observacions')} onChange={(e) => setObservacions(e.target.value)} defaultValue={observacions}/>
        </Form.Item>

      </Col>
    </Row>

        <Col span={24}>
          <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
            <Col>
              <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Col>

      </Form>
    </div>
  );
}
export default translate(Edit);
