import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import {Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../../context/menu-triat";
import { Api, InitDades, HtmlStrings } from '../../../../helpers';
import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
import ImatgeDNI from '../../../ImatgeDNI';
import VisorImatges from "../../../visorImatges";

const entities = new AllHtmlEntities();


function Edit(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

  const buit = InitDades.pare();

  //const [id, setId] = useState(perfilUsuari.account.id);
  const [users_id, setUsers_id] = useState(perfilUsuari.account.id);


  const [nom, setNom] = useState(entities.decode(buit.nom));
  const [cognom1, setCognom1] = useState(entities.decode(buit.cognom1));
  const [cognom2, setCognom2] = useState(entities.decode(buit.cognom2));
  const [tel1, setTel1] = useState(buit.tel1);
  const [tel2, setTel2] = useState(buit.tel2);
  const [email, setEmail] = useState(buit.email);
  const [dni, setDni] = useState(buit.dni);
  const [dni_image_1, setDni_image_1] = useState();
  const [dni_image_2, setDni_image_2] = useState();
  const [passaport, setPassaport] = useState(buit.passaport);
  const [passaport_image_1, setPassaport_image_1] = useState(buit.passaport_image_1);
  const [passaport_image_2, setPassaport_image_2] = useState(buit.passaport_image_2);
  const [idioma, setIdioma] = useState(entities.decode(buit.idioma));
  const [naixement, setNaixement] = useState(buit.naixement);
  const [naixement_ciutat, setNaixement_ciutat] = useState(entities.decode(buit.naixement_ciutat));
  const [nacionalitat, setNacionalitat] = useState(entities.decode(buit.nacionalitat));
  //const [sexe, setSexe] = useState(buit.sexe);
  const [professio, setProfessio] = useState(entities.decode(buit.professio));
  const [empresa, setEmpresa] = useState(entities.decode(buit.empresa));



/* Auxiliars */
  const [dni_image_1_dataUrl, setDni_image_1_dataUrl ] = useState(undefined);
  const [dni_image_2_dataUrl, setDni_image_2_dataUrl ] = useState(undefined);
  const [passaport_image_1_dataUrl, setPassaport_image_1_dataUrl ] = useState(undefined);
  const [passaport_image_2_dataUrl, setPassaport_image_2_dataUrl ] = useState(undefined);

  const [visor, setVisor] = useState({visible: false});

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const buscarDades = async () => {
    setIsLoading(true);


    const pare = await Api.get('/responsable/'+users_id);

    if(pare.result.success===1){
      setNom(entities.decode(pare.data.nom));
      setCognom1(entities.decode(pare.data.cognom1));
      setCognom2(entities.decode(pare.data.cognom2));
      setTel1(pare.data.tel1);
      setTel2(pare.data.tel2);
      setEmail(pare.data.email);
      setDni(pare.data.dni);
      setPassaport(pare.data.passaport);
      setNaixement(pare.data.naixement);
      setNaixement_ciutat(entities.decode(pare.data.naixement_ciutat));
      setNacionalitat(entities.decode(pare.data.nacionalitat));
      //setSexe(pare.data.sexe);
      setProfessio(entities.decode(pare.data.professio));
      setEmpresa(entities.decode(pare.data.empresa));
    }

    const dni_pare = await Api.get('/responsable/dni/'+users_id);
    if(dni_pare.result.success===1){
      setDni_image_1(dni_pare.data.dni_image_1);
      setDni_image_2(dni_pare.data.dni_image_2);
      setPassaport_image_1(dni_pare.data.passaport_image_1);
      setPassaport_image_2(dni_pare.data.passaport_image_2);
    }


    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);


    const submitForm = async (valors) => {

      setGuardant(true);

      let dades = {
        id: users_id,
        nom: entities.encode(nom),
        cognom1: entities.encode(cognom1),
        cognom2: entities.encode(cognom2),
        tel1,
        tel2,
        email,
        dni,
        passaport,
        idioma,
        naixement,
        naixement_ciutat: entities.encode(naixement_ciutat),
        nacionalitat: entities.encode(nacionalitat),
        professio: entities.encode(professio),
        empresa: entities.encode(empresa)
      }


      console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch('/responsable','PUT', JSON.stringify(dades));

        if(resposta.result.success === 1){

          if(dni_image_1_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/responsable/dni/'+resposta.data.id+'/1', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_1(resp.data.dni_image_1);
              }else{
                console.error('Error POST file: Pares dni_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(dni_image_2_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/responsable/dni/'+resposta.data.id+'/2', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_2(resp.data.dni_image_2);
              }else{
                console.error('Error POST file: Pares dni_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_1_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/responsable/dni/'+resposta.data.id+'/3', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_1(resp.data.passaport_image_1);
              }else{
                console.error('Error POST file: Pares passaport_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_2_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/responsable/dni/'+resposta.data.id+'/4', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_2(resp.data.passaport_image_2);
              }else{
                console.error('Error POST file: Pares passaport_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }


          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          //setMenuTriat("Dashboard");
        }else{
          setGuardant(false);
          console.error('Error: submit FormFamilies: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Familia guardar: ', error);
      }

  }


  let posicio_botons = "start";
  if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }
/*
  const triaSexe = (value) =>{
    setSexe(value);
    //if(value.length>0){
      //setErrorSexe('');
      //setErrorSexeText('');
    //}
  }
*/
  const changeNaixement = (date, dateString) => {
    if(date){
      //Trec l'hora
      setNaixement(date.format(moment.HTML5_FMT.DATE));
    }else{
      setNaixement(null);
    }
  }


  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';
  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.DadesPersonals')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        {props.popup!==undefined ? null :
          <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
            <LeftOutlined className="BotoBack" onClick={(e) => setMenuTriat("Dashboard")}/>
          </div>
        }
        <div style={{padding:'10px 24px 10px 0px', height:'650px'}}>
          <Form onFinish={submitForm}
            layout="vertical"
            colon={true}
            autoComplete="off"
          >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} lg={12} xl={8}>
            <Form.Item label={props.t('pares.nom')}>
              <Input name="nom" placeholder={props.t('pares.nom')} onChange={(e) => setNom(e.target.value)} value={nom}/>
            </Form.Item>
            <Form.Item label={props.t('pares.cognom1')}>
              <Input name="cognom1" placeholder={props.t('pares.cognom1')} onChange={(e) => setCognom1(e.target.value)} value={cognom1}/>
            </Form.Item>
            <Form.Item  label={props.t('pares.cognom2')}>
              <Input name="cognom2" placeholder={props.t('pares.cognom2')} onChange={(e) => setCognom2(e.target.value)} value={cognom2}/>
            </Form.Item>
            {/*<Form.Item label={props.t('pares.sexe')}>
              <Select defaultValue={sexe} onChange={triaSexe} style={{ width: 100}}>
                <Select.Option value="DONA">{props.t('pares.sexe_dona')}</Select.Option>
                <Select.Option value="HOME">{props.t('pares.sexe_home')}</Select.Option>
                <Select.Option value="ALTRES">{props.t('pares.sexe_altres')}</Select.Option>
              </Select>
            </Form.Item>*/}
            <Form.Item label={props.t('alumnes.naixement')}>
              <DatePicker
                onChange={changeNaixement}
                value={naixement ? moment(new Date(naixement), dateFormat) : ''}
                format={dateFormat}
                locale={localeDatePicker}
                picker="date"
                />
            </Form.Item>
            <Form.Item label={props.t('alumnes.naixement_ciutat')}>
              <Input name="naixement_ciutat" placeholder={props.t('alumnes.naixement_ciutat')} onChange={(e) => setNaixement_ciutat(e.target.value)} value={naixement_ciutat}/>
            </Form.Item>
            <Form.Item label={props.t('alumnes.nacionalitat')}>
              <Input name="nacionalitat" placeholder={props.t('alumnes.nacionalitat')} onChange={(e) => setNacionalitat(e.target.value)} value={nacionalitat}/>
            </Form.Item>
            <Form.Item label={props.t('generic.idioma')}>
              <Select defaultValue={idioma} onChange={setIdioma} style={{ width: 110}} size="small">
                <Select.Option value="es">Castellano</Select.Option>
                <Select.Option value="ca">Català</Select.Option>
                <Select.Option value="en">English</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={props.t('pares.professio')}>
              <Input name="professio" placeholder={props.t('pares.professio')} onChange={(e) => setProfessio(e.target.value)} value={professio}/>
            </Form.Item>
            <Form.Item label={props.t('pares.empresa')}>
              <Input name="empresa" placeholder={props.t('pares.empresa')} onChange={(e) => setEmpresa(e.target.value)} value={empresa}/>
            </Form.Item>


            <Divider plain>{props.t('generic.dades_contacte')}</Divider>
            <Form.Item label={props.t('pares.tel1')}>
              <Input name="tel1" placeholder={props.t('pares.tel1')} onChange={(e) => setTel1(e.target.value)} value={tel1}/>
            </Form.Item>
            <Form.Item label={props.t('pares.tel2')}>
              <Input name="tel2" placeholder={props.t('pares.tel2')} onChange={(e) => setTel2(e.target.value)} value={tel2}/>
            </Form.Item>
            <Form.Item label={props.t('pares.email')}>
              <Input name="email" placeholder={props.t('pares.email')} onChange={(e) => setEmail(e.target.value)} value={email}/>
            </Form.Item>



          </Col>

          <Col xs={24} lg={12} xl={16}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Form.Item
                  label={props.t('pares.dni')}
                  labelCol={{span:2}}
                  wrapperCol={{span:10}}
                >
                  <Input name="dni" placeholder={props.t('pares.dni')} onChange={(e) => setDni(e.target.value)} value={dni}/>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                  <ImatgeDNI
                    image={dni_image_1}
                    title={props.t('pares.dni_image_1')}
                    dataUrl={dni_image_1_dataUrl}
                    setDataUrl={setDni_image_1_dataUrl}
                    setVisor={setVisor}
                  />
              </Col>
              <Col  xs={24} xl={12}>
                  <ImatgeDNI
                    image={dni_image_2}
                    title={props.t('pares.dni_image_2')}
                    dataUrl={dni_image_2_dataUrl}
                    setDataUrl={setDni_image_2_dataUrl}
                    setVisor={setVisor}
                  />
              </Col>
              <Col span={24} style={{paddingTop: '3rem'}}>
                  <Form.Item
                    label={props.t('pares.passaport')}
                    wrapperCol={{span:12}}
                  >
                    <Input name="passaport" placeholder={props.t('pares.passaport')} onChange={(e) => setPassaport(e.target.value)} value={passaport}/>
                  </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={passaport_image_1}
                  title={props.t('pares.passaport_image_1')}
                  dataUrl={passaport_image_1_dataUrl}
                  setDataUrl={setPassaport_image_1_dataUrl}
                  setVisor={setVisor}
                />
              </Col>
              <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={passaport_image_2}
                  title={props.t('pares.passaport_image_2')}
                  dataUrl={passaport_image_2_dataUrl}
                  setDataUrl={setPassaport_image_2_dataUrl}
                  setVisor={setVisor}
                />
              </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
                <Col>
                  <Button onClick={(e) => setMenuTriat("Dashboard")}>{props.t('generic.cancel')}</Button>
                </Col>
                <Col style={{marginLeft:'10px'}}>
                  <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
                </Col>
              </Row>
            </Col>
    </Row>
          </Form>
          { !visor.visible
            ? null
            : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
          }
        </div>
    </div>
  </ContainerPagina>
  );
}
export default translate(Edit);
