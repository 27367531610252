import React, {useContext, useEffect, useState } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Button, Typography, Form, Select, Tooltip, DatePicker, message } from 'antd';
import { SyncOutlined, BulbFilled } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';
import { Api, HtmlStrings } from '../../../../helpers';

import Moment from 'react-moment';
import moment from 'moment';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';

import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
import DocumentUploadDelete from "../../../DocumentUploadDelete";
import TextAreaCount from '../../../TextAreaCount';

import {PerfilUsuariContext} from "../../../../context/perfil-usuari";

const entities = new AllHtmlEntities();
moment.locale('es');

function MedicAddOne(props){

  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  const [id, setId] = useState(props.item.id);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_frase, setId_frase] = useState(props.item.id_frase);
  const [week, setWeek] = useState(() => {
    if(props.item.week !== 0){
      const w = parseInt(props.item.week.toString().substring(5));
      const y = parseInt(props.item.week.toString().substring(0,4));
      return  moment().year(y).isoWeek(w);
    }else{
      return moment();
    }
  }
  )

  const [tipus, setTipus] = useState(props.item.tipus ? props.item.tipus : 'menjar');

  const [isLoading, setIsLoading] = useState(true);
  const [guardant, setGuardant] = useState(false);

  const [alumnesOptions, setAlumnesOptions] = useState([]);
  const [frasesOptions, setFrasesOptions] = useState([]);

  const [errorId_alumne, setErrorId_alumne] = useState('');
  const [errorId_alumneText, setErrorId_alumneText] = useState('');
  const [errorId_frase, setErrorId_frase] = useState('');
  const [errorId_fraseText, setErrorId_fraseText] = useState('');

  const nou = (id===0);

  useEffect(() =>{
    buscarDades();
  },[])

  useEffect(() =>{
    buscarFrases();
  },[tipus, props.t('idioma')])


  const buscarDades = async()  =>{
    setIsLoading(true);

    const fills = await Api.get('/alumnes');
    if(fills.result.success===1){
      const llista_fills =fills.data.alumnes.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2) + ' ('+entities.decode(item.classe)+')'}
              </Select.Option>
            );
      });
      setAlumnesOptions(llista_fills);
    }


    setIsLoading(false);
  }

  const buscarFrases = async()  =>{
    const frases = await Api.get(`/menjador/report/frases/tipus/${tipus}`);
    if(frases.result.success===1){
      const llista_frases =frases.data.frases.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                <Tooltip placement="bottomLeft" title={entities.decode(item.text[props.t('idioma')])}>
                  <BulbFilled style={{color: `${props.t(`menjador.report.semafor.${item.semafor}`)}`}}/> {entities.decode(item.text[props.t('idioma')])}
                </Tooltip>
              </Select.Option>
            );
      });
      setFrasesOptions(llista_frases);
    }
}

  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setErrorId_alumne('');
      setErrorId_alumneText('');
    }
  }

  const triaTipus = (value) =>{
    setTipus(value);
    setId_frase(0);
  }



  const triaFrase = (value) =>{
    setId_frase(value);
    if(value>0){
      setErrorId_frase('');
      setErrorId_fraseText('');
    }
  }

  const onWeekChange = (date, dateString) =>{
    setWeek(date);
  }



  const guardar = async() =>{

    let correcte = true;

    if(id_alumne===0){
      setErrorId_alumne('error');
      setErrorId_alumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_frase===0){
      setErrorId_frase('error');
      setErrorId_fraseText(props.t('generic.tria'));
      correcte = false;
    }



    if(!correcte){
      return false;
    }


    setGuardant(true);

    let dades = {
      id,
      id_curs_escolar: props.item.id_curs_escolar,
      id_alumne,
      id_frase,
      week: week.year()*1000+week.isoWeek(),
      created_by: perfilUsuari.account.id
    }

    let method = nou?'POST':'PUT';
    let url_api = nou? '/mtr' : '/mtr/'+id;

    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

      if(resposta.result.success === 1){
        setId(resposta.data.id_medic);
        console.log(resposta);
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        //props.callback();

      }else{
        setGuardant(false);
        console.error('Error: submit Form MealTimeReport: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: MealTimeReport guardar: ', error);
    }
  }

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
  <>
    <Form.Item label={props.t('menjador.report.week')} required={true} validateStatus={errorId_alumne} help={errorId_alumneText} style={{marginBottom:0}}>
      <DatePicker picker="week" value={week} onChange={onWeekChange}/>
    </Form.Item>
    <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_alumne} help={errorId_alumneText} style={{marginBottom:0}}>
      <Select
        defaultValue={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {alumnesOptions}
      </Select>
    </Form.Item>

    <Form.Item label={props.t('menjador.report.tipus.titol')} required={true}>
      <Select
        defaultValue={tipus}
        onChange={(value) => triaTipus(value)}
      >
        <Select.Option key={'menjar'} value={'menjar'}>
          {props.t('menjador.report.tipus.menjar')}
        </Select.Option>
        <Select.Option key={'comportament'} value={'comportament'}>
          {props.t('menjador.report.tipus.comportament')}
        </Select.Option>
      </Select>
    </Form.Item>

    <Form.Item label={props.t('menjador.report.frases')} required={true} validateStatus={errorId_frase} help={errorId_fraseText} style={{marginBottom:0}}>
      <Select
        value={id_frase===0 ? '' : id_frase} onChange={(value) => triaFrase(value)}
        showSearch
        filterOption={(input, option) =>
          HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
        }
      >
        {frasesOptions}
      </Select>
    </Form.Item>


      <Row type="flex" justify="space-between" style={{marginTop: '1rem'}}>
        <Col>
          <Button onClick={props.callback}>{props.t('generic.back')}</Button>
        </Col>
        <Col style={{marginLeft:'10px'}}>
          <Button type="primary" onClick={guardar} loading={guardant}>{props.t('generic.save')}</Button>
        </Col>
      </Row>
    </>

  );
}

export default translate(MedicAddOne);
