import React, {useState, useEffect} from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Card, Skeleton, Empty, Radio, Modal, Button, Tooltip } from 'antd';
import { SyncOutlined, CheckOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import ProducteDetall from '../../../components/Formularis/perfils/pares/Producte';
import ExcursioDetall from '../../../components/Formularis/perfils/pares/Excursio';
import EsdevenimentDetall from '../../../components/Formularis/perfils/pares/Esdeveniment';
import SummerCampDetall from '../../../components/Formularis/perfils/pares/SummerCamp';

import { Api, InitDades } from '../../../helpers';

const entities = new AllHtmlEntities();

const mides_botonets = {width: '100%',height: '65px'};
const color = [
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#5AB7B2', borderColor: '#5AB7B2', color: 'white', borderRadius: '15px'},
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#B21E28', borderColor: '#B21E28', color: 'white', borderRadius: '15px'},
  {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#F7B334', borderColor: '#F7B334', color: 'white', borderRadius: '15px'},
];
const color_comprat = {width: mides_botonets.width, height: mides_botonets.height, backgroundColor: '#bdbdbd', borderColor: '#bdbdbd', color: 'black', borderRadius: '15px'};


function Producte(props) {

const skelet = ([
  <Col span={6} key="a">
    <Card
      loading={true}
      hoverable
      cover={<Skeleton.Image style={{width:350, height: 200}}/>}
    >
      <Card.Meta title="" description="" />
    </Card>
  </Col>,
  <Col span={6} key="b">
    <Card
      loading={true}
      hoverable
      cover={<Skeleton.Image style={{width:350, height: 200}}/>}
    >
      <Card.Meta title="" description="" />
    </Card>
  </Col>
  ]);

  const [llistaProductes, setLlistaProductes] = useState(skelet);
  const [isLoading, setIsLoading] = useState(true);
  const [refrescar, setRefrescar] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [producteTriat, setProducteTriat] = useState([]);

  const buscarDadesProductes = async () => {
    setIsLoading(true);
    const prod = await Api.get(`/botiga/categoria/${props.id_categoria}/alumne/${props.id_alumne}`);
    //console.log('buscarDadesProductes', prod)
    if(prod.result.success===1){
      const llista_p = prod.data.productes.map((item, index) =>{

        const url_image = item.url_image ? item.url_image : "https://via.placeholder.com/443x249?text=NO+IMAGE+ASSIGNED!!";

        switch (props.id_categoria) {
          case 1: //Roba
            return(
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={props.id_categoria+'_'+index+'_'+item.id}>
                <Card
                  hoverable
                  style={{ marginBottom: 16, padding: 10}}
                  cover={<img alt="example" src={url_image} />}
                  onClick={() => obrirDetall(item)}
                  >
                    <Card.Meta title={<div style={{textAlign:'center'}}>{entities.decode(item.nom[props.t('idioma')])}</div>}/>
                  </Card>
              </Col>
            );
            break;
          case 2: //Excursions
          case 3: //Esdeveniments
            var d = new Date(item.data_hora_esdeveniment);
            if(!item.comprat){
              return(
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{marginTop:'15px'}} key={props.id_categoria+'_'+index+'_'+item.id}>
                    <Button type="primary" size="large" style={color[index%3]} onClick={() => obrirDetall(item)}>
                      <Row>
                        <Col span={24} style={{fontSize: 14}}>{entities.decode(item.nom[props.t('idioma')])}</Col>
                        <Col span={24} style={{fontSize: 14}}>{d.toLocaleDateString()}</Col>
                      </Row>
                    </Button>
                  </Col>
              );
            }else{
              return(
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{marginTop:'15px'}} key={props.id_categoria+'_'+index+'_'+item.id}>
                    <Tooltip placement="top" title={props.t('generic.serveiJaContractat')}>
                      <Button type="primary" size="large" style={color_comprat} onClick={() => obrirDetall(item)}>
                        <Row>
                          <Col span={2} style={{fontSize: 24, color: '#00e676 '}}>
                            <CheckOutlined />
                          </Col>
                          <Col span={22} style={{fontSize: 14}}>
                            <Row>
                              <Col span={24} style={{fontSize: 14}}>{entities.decode(item.nom[props.t('idioma')])}</Col>
                              <Col span={24} style={{fontSize: 14}}>{d.toLocaleDateString()}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Button>
                    </Tooltip>
                  </Col>
              );
            }
            break;
          case 4: //Summer Camp
            if(!item.comprat){
              return(
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{marginTop:'15px'}} key={props.id_categoria+'_'+index+'_'+item.id}>
                    <Button type="primary" size="large" style={color[index%3]} onClick={() => obrirDetall(item)}>
                      <Row>
                        <Col span={24} style={{fontSize: 14}}>{entities.decode(item.nom[props.t('idioma')])}</Col>
                        <Col span={24} style={{fontSize: 14}}>{entities.decode(item.descripcio[props.t('idioma')])}</Col>
                      </Row>
                    </Button>
                  </Col>
              );
            }else{
              return(
                  <Col xs={24} sm={24} md={12} lg={12} xl={6} style={{marginTop:'15px'}} key={props.id_categoria+'_'+index+'_'+item.id}>
                    <Tooltip placement="top" title={props.t('generic.serveiJaContractat')}>
                      <Button type="primary" size="large" style={color_comprat} onClick={() => obrirDetall(item)}>
                        <Row>
                          <Col span={2} style={{fontSize: 24, color: '#00e676 '}}>
                            <CheckOutlined />
                          </Col>
                          <Col span={22} style={{fontSize: 14}}>
                            <Row>
                              <Col span={24} style={{fontSize: 14}}>{entities.decode(item.nom[props.t('idioma')])}</Col>
                              <Col span={24} style={{fontSize: 14}}>{entities.decode(item.descripcio[props.t('idioma')])}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Button>
                    </Tooltip>
                  </Col>
              );
            }
            break;


          default:
            return(
              <Col xs={24} sm={12} md={8} lg={6} xl={6} key={item.id} key={props.id_categoria+'_'+index+'_'+item.id}>
                <Card
                  hoverable
                  style={{ marginBottom: 16, padding: 10}}
                  cover={<img alt="example" src={url_image} />}
                >
                  <Card.Meta title={entities.decode(item.nom[props.t('idioma')])} description={entities.decode(item.descripcio[props.t('idioma')])} />
                </Card>
              </Col>
            );
        }

      });
      if(llista_p.length>0){
        setLlistaProductes(llista_p);
      }else{
      setLlistaProductes(<Empty/>);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDadesProductes();
  },[props.id_alumne, refrescar]);

  const respostaProducte = () =>{
    setRefrescar(refrescar+1);
    setModalVisible(false);
  }
  const obrirDetall = (producte) =>{
    setProducteTriat(producte);
    setModalVisible(true);
  }


  /*if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }*/

  let popUP = null;
  switch (props.id_categoria) {
    case 1: //Roba
      popUP = <ProducteDetall item={producteTriat} tancarModal={respostaProducte} id_alumne={props.id_alumne}/>
      break;
    case 2: //Excursions
      popUP = <ExcursioDetall item={producteTriat} tancarModal={respostaProducte} id_alumne={props.id_alumne}/>
      break;
    case 3: //Esdeveniments
      popUP = <EsdevenimentDetall item={producteTriat} tancarModal={respostaProducte} id_alumne={props.id_alumne}/>
      break;
    case 4: //SummerCamp
      popUP = <SummerCampDetall item={producteTriat} tancarModal={respostaProducte} id_alumne={props.id_alumne}/>
      break;
    default:

  }


  return(
    <>
      {/*<TitolPagina txt={entities.decode(props.titol[props.idioma])} level={4} style={{marginTop:'1rem', marginLeft:'0.5rem'}}/>*/}
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginTop:'1rem', marginLeft:'0.5rem'}}>
        {llistaProductes}
      </Row>
      <Modal
        key="roba"
        visible={modalVisible}
        footer={null}
        width={450}
        style={{top:10}}
        bodyStyle={{ overflow: "auto"}}
        destroyOnClose={true}
        forceRender={true}
        maskClosable={true}
        closable={true}
        onCancel={respostaProducte}
      >
        {popUP}
      </Modal>
    </>
  );

}

export default translate(Producte);
