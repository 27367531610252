import React, { useState, useEffect } from "react";
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY';

const exercici_anterior = new Date().getFullYear() - 1;
const fn_ini = moment(exercici_anterior - 3+'-01-01');
const fn_fin = moment(exercici_anterior+'-12-31');

function AlumnesModelo233ExportaExcel(props){

  moment.locale('es');

  const [dades, setDades] = useState([]);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Model233';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'Model233';
  const [fini, setFini] = useState(fn_ini.toISOString(true));
  const [ffin, setFfin] = useState(fn_fin.toISOString(true));
  const [any, setAny] = useState(exercici_anterior);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [rangActual, setRangActual] = useState([fn_ini, fn_fin])

  const [isLoading, setIsLoading] = useState(true);

  const construirSelectAnys = () =>{
    let any_actual =  new Date().getFullYear();
    let years = [];
    for (var i = 2020; i < any_actual+1; i++) {
      years.push({label: i, value: i});
    }
    setYearsOptions(years)
  }

  const buscarDades = async () => {
    setIsLoading(true);
    if(fini !== null && ffin !== null && any !== null){
      console.log('modelo233 consulta', `/llistat/modelo233/${any}/${fini}/${ffin}`)
      const alum = await Api.get(`/llistat/modelo233/${any}/${fini}/${ffin}`);
      console.log(alum)
      if(alum.result.success===1){
        console.log('modelo233', alum.data.alumnes)

        setDades(alum.data.alumnes);
      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    construirSelectAnys();
  },[]);

  useEffect(() => {
    buscarDades();
  },[any, fini, ffin]);


  const canviAny = (value) =>{

    const tfn_ini = moment(value - 3+'-01-01');
    const tfn_fin = moment(value+'-12-31');
    setFini(tfn_ini.toISOString(true))
    setFfin(tfn_fin.toISOString(true))
    setRangActual([tfn_ini, tfn_fin])

    setAny(value);
  }

  const calendarChange = (rang_moments, rang_strings) => {
      setFini(rang_moments[0].toISOString(true));
      setFfin(rang_moments[1].toISOString(true));
      setRangActual(rang_moments)
    }

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }


  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }


  return (

    <Space direction="vertical">
      <Space direction="vertical">
        <span><b>Exercici:</b></span>
        <Select value={any} options={yearsOptions} onChange={canviAny}/>
      </Space>
      <Space direction="vertical">
        <span><b>Nascuts entre:</b></span>
        <DatePicker.RangePicker
          //defaultValue={fini && ffin ? [moment(fini, dateFormat), moment(ffin, dateFormat)] : ''}
          value={rangActual}
          placeholder={[props.t('generic.fini'), props.t('generic.ffin')]}
          format={dateFormat}
          locale={localeDatePicker}
          onChange={calendarChange}
          />
      </Space>
      <ExcelFile
        filename={nom_fitxer}
        element={
            <Button disabled={fini && ffin && any ? false : true} type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>
        }>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="Tipo de Registro" value={(col) => 2}/>
            <ExcelColumn label="Modelo" value={(col) => 233}/>
            <ExcelColumn label="Ejercicio" value={(col) => any}/>
            <ExcelColumn label="Identificador de Registro" value="id_registro"/>

            <ExcelColumn label="NIF M" value="dni_pare1"/>
            <ExcelColumn label="Primer apellido M" value={(col) => entities.decode(col.cognom1_pare1)}/>
            <ExcelColumn label="Segundo apellido M" value={(col) => entities.decode(col.cognom2_pare1)}/>
            <ExcelColumn label="Nombre M" value={(col) => entities.decode(col.nom_pare1)}/>

            <ExcelColumn label="NIF O" value="dni_pare2"/>
            <ExcelColumn label="Primer apellido O" value={(col) => entities.decode(col.cognom1_pare2)}/>
            <ExcelColumn label="Segundo apellido O" value={(col) => entities.decode(col.cognom2_pare2)}/>
            <ExcelColumn label="Nombre O" value={(col) => entities.decode(col.nom_pare2)}/>

            <ExcelColumn label="NIF m" value="dni_alumne"/>
            <ExcelColumn label="Primer apellido m" value={(col) => entities.decode(col.cognom1_alumne)}/>
            <ExcelColumn label="Segundo apellido m" value={(col) => entities.decode(col.cognom2_alumne)}/>
            <ExcelColumn label="Nombre m" value={(col) => entities.decode(col.nom_alumne)}/>
            <ExcelColumn label="Fecha nacimiento m" value={"naixement"}/>
            <ExcelColumn label="Enero" value={(col) => col.import_m1 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Febrero" value={(col) => col.import_m2 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Marzo" value={(col) => col.import_m3 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Abril" value={(col) => col.import_m4 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Mayo" value={(col) => col.import_m5 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Junio" value={(col) => col.import_m6 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Julio" value={(col) => col.import_m7 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Agosto" value={(col) => 'N'}/>
            <ExcelColumn label="Septiembre" value={(col) => col.import_m9 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Octubre" value={(col) => col.import_m10 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Noviembre" value={(col) => col.import_m11 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Diciembre" value={(col) => col.import_m12 > 0 ? 'S' : 'N'}/>
            <ExcelColumn label="Gastos anuales" value={"import_pressupost"}/>
            <ExcelColumn label="Importe Subvencionado" value={(col) => 0}/>
            <ExcelColumn label="Autorización" value={(col) => '43012745'}/>
            <ExcelColumn label="Codigo CCAA" value={(col) => '09'}/>
            <ExcelColumn label="" value={(col) => ''}/>
            <ExcelColumn label="" value={(col) => ''}/>




{/*
            <ExcelColumn label="Tel1" value="tel_pare1"/>
            <ExcelColumn label="Email1" value="email_pare1"/>

            <ExcelColumn label="Responsable2 Nom" value={(col) => entities.decode(col.nom_pare2)}/>
            <ExcelColumn label="Responsable2 Cognom" value={(col) => entities.decode(col.cognom1_pare2)}/>
            <ExcelColumn label="Tel2" value="tel_pare2"/>
            <ExcelColumn label="Email2" value="email_pare2"/>

            <ExcelColumn label="Nom" value={(col) => entities.decode(col.nom)}/>
            <ExcelColumn label="Cognom1" value={(col) => entities.decode(col.cognom1)}/>
            <ExcelColumn label="Cognom2" value={(col) => entities.decode(col.cognom2)}/>
            <ExcelColumn label="Curs" value={(col) => entities.decode(col.curs)}/>
            <ExcelColumn label="Classe" value={(col) => entities.decode(col.classe)}/>


            <ExcelColumn label="Estat" value={(col) => entities.decode(col.estat)}/>
  */}
            <ExcelColumn label="id_familia" value="id_familia"/>
            <ExcelColumn label="id_alumne" value="id_alumne"/>
            <ExcelColumn label="Anual" value="pagament_unic_totes_quotes_any"/>
            <ExcelColumn label="Treballador centre" value="pare_treballador_centre"/>
            <ExcelColumn label="Import per dates" value={"import"}/>
            <ExcelColumn label="Import per pressupost" value={"import_pressupost"}/>


          </ExcelSheet>
      </ExcelFile>
    </Space>
  );

}

export default translate(AlumnesModelo233ExportaExcel);
