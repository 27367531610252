import React, { useState, useEffect, useContext } from 'react';

import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, Form, Select, Table, message } from 'antd';
import { MinusOutlined, PlusOutlined, CarOutlined, ExperimentOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api, HtmlStrings } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function NotificacioEnviar(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const id_profe = props.id_profe ? props.id_profe : perfilUsuari.account.id_profe;

  const [missatge_txt, setMissatge_txt] = useState('\r\r---- Original message ----- \r'+entities.decode(props.item.missatge));

  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);

  const [errorMissatge_txt, setErrorMissatge_txt] = useState('');


  useEffect(() => {
    console.log("Notificacions respondre item: ",props.item);
    document.querySelector("textarea").focus(); //set the focus - cursor at end
    document.querySelector("textarea").setSelectionRange(0,0); // place cursor at star
  },[]);




  const submitForm = async (valors) => {

    let correcte = true;

    if(!missatge_txt){
      setErrorMissatge_txt('error');
      correcte = false;
    }

    if(!correcte){
      return false;
    }

    setGuardant(true);

    let dades = {
      id_usuari_respon: perfilUsuari.account.id,
      id_usuari_original: props.item.user_origen,
      id_notificacio_original: props.item.id,
      missatge_txt: entities.encode(missatge_txt)
    }

    //console.log(dades);

    try{
      const resposta = await Api.myFetch('/notificacions/profe/respon', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: enviant notificacio: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio: ', error);
    }

  }

  return(
    <>
    <Row>
      <Col span={4} style={{textAlign: 'right'}}><b>{props.t('notificacions.tipus')}:&nbsp;</b></Col>
      <Col span={20}>{entities.decode(entities.decode(props.item.notificacions_tipus[props.t('idioma')]))}</Col>
      { props.item.motiu < 1 ? null :
        <>
        <Col span={4} style={{textAlign: 'right'}}><b>{props.t('menu.MotiusAbsencia')}:&nbsp;</b></Col>
        <Col span={20}>{entities.decode(entities.decode(props.item.motiu_txt[props.t('idioma')]))}</Col>
        </>
      }

      <Col span={4} style={{textAlign: 'right'}}><b>{props.t('notificacions.alumne')}:&nbsp;</b></Col>
      <Col span={20}>{entities.decode(props.item.alumne)}</Col>


    </Row>
      <Form onFinish={submitForm} layout="vertical" autoComplete="off">


        <Form.Item label={props.t('notificacions.missatge')} validateStatus={errorMissatge_txt} required={true}>
          <TextAreaCount autoSize={{ minRows: 3, maxRows: 25 }} maxLength={500} name="missatge_txt" defaultValue={missatge_txt} placeholder={props.t('notificacions.missatge')} onChange={setMissatge_txt}/>
        </Form.Item>

        <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
          <Col>
            <Button onClick={()=>props.tancarModal()}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default translate(NotificacioEnviar);
