import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, Space, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function ComandesExtraescolarsAdd(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [id, setId] = useState(props.item.id);
  const [enviar_email, setEnviar_email] = useState(false);
  const [id_pare, setId_pare] = useState(props.item.id_pare);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_extraescolar, setId_extraescolar] = useState(props.item.id_extraescolar);
  const [nom, setNom] = useState(props.item.nom);
  const [horari, setHorari] = useState(props.item.horari);
  const [subtotal, setSubtotal] = useState(props.item.subtotal);
  const [descompte, setDescompte] = useState(props.item.descompte);
  const [total, setTotal] = useState(props.item.total);
  const [fcrea, setFcrea ] = useState(props.item.date);

  const [id_familia, setId_familia] = useState(0);
  const [familiesOptions, setFamiliesOptions] = useState([]);
  const [paresOptions, setParesOptions] = useState([]);
  const [alumnesOptions, setAlumnesOptions] = useState([]);
  const [extraescolarsOptions, setExtraescolarsOptions] = useState([]);
  const [extraescolarsLlistaIndexada, setExtraescolarsLlistaIndexada] = useState([]);

  const [guardant, setGuardant] = useState(false);
  const [errorId_Familia, setErrorId_Familia] = useState('');
  const [errorId_FamiliaText, setErrorId_FamiliaText] = useState('');
  const [errorId_Pare, setErrorId_Pare] = useState('');
  const [errorId_PareText, setErrorId_PareText] = useState('');
  const [errorId_Alumne, setErrorId_Alumne] = useState('');
  const [errorId_AlumneText, setErrorId_AlumneText] = useState('');
  const [errorId_Extraescolar, setErrorId_Extraescolar] = useState('');
  const [errorId_ExtraescolarText, setErrorId_ExtraescolarText] = useState('');
  const [errorFcrea, setErrorFcrea] = useState('');

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  useEffect(() => {
    buscarDadesFamilia();
  },[]);

  useEffect(() => {
    setId_pare(0);
    buscarDadesPares();
    setId_alumne(0);
    buscarDadesFills();
  },[id_familia]);

  useEffect(() => {
    setId_extraescolar(0);
    buscarDadesExtraescolars();
  },[id_alumne]);

  useEffect(() => {
    if(id_extraescolar>0){
      const extra = extraescolarsLlistaIndexada[id_extraescolar];
      setSubtotal(extra.preu);
      setDescompte(extra.descompte);
      calcularTotal(extra.preu, extra.descompte);
    }
  },[id_extraescolar]);




  const buscarDadesFamilia = async () => {
    const fam = await Api.get('/families');
    if(fam.result.success===1){
      const llista_fam = fam.data.families.map((item) =>{
        const color = (item.estat === 'baixa')? {color:'red'} : {};
            return(
              <Select.Option key={item.id} value={item.id}>
                  <span style={color}>{item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}{item.estat === 'baixa' ? <span> - (Baixa)</span> : null }</span>
              </Select.Option>
            );

      });
      setFamiliesOptions(llista_fam);
    }
  }
  const buscarDadesPares = async () => {
    if(id_familia > 0){
      const pares = await Api.get(`/pares/familia/${id_familia}`);
      if(pares.result.success===1){
        const llista_pares = pares.data.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setParesOptions(llista_pares);
      }
    }
  }
  const buscarDadesFills = async () => {
    if(id_familia > 0){
      const fills = await Api.get(`/alumnes/familia/${id_familia}`);
      if(fills.result.success===1){
        const llista_fills =fills.data.alumnes.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setAlumnesOptions(llista_fills);
      }
    }
  }

  const buscarDadesExtraescolars = async () => {
    if(id_alumne > 0){
      //const ext = await Api.get(`/extraescolars/alumne/${id_alumne}`);
      const ext = await Api.get(`/extraescolars/admin/alumne/${id_alumne}/${id_curs_escolar}`);
      if(ext.result.success===1){
        console.log(ext.data.extraescolars)
        const llista_ext = ext.data.extraescolars.map((item) =>{
              const color = (item.caducat && !item.comprat)? {color:'red'} : {};
              return(
                <Select.Option key={item.id_extraescolar} value={item.id_extraescolar} disabled={item.comprat}>
                  <span style={color}><b>{entities.decode(item.nom[props.t('idioma')])}</b> ({entities.decode(item.horari[props.t('idioma')])}){item.caducat ? <span style={{color:'red'}}> - (Caducat)</span> : null }</span>
                </Select.Option>
              );
        });
        setExtraescolarsOptions(llista_ext);
        const llistaIndexada = ext.data.extraescolars.reduce((acumulat, item) => ({
          ...acumulat,
          [item.id_extraescolar]: item,
        }),{});
        setExtraescolarsLlistaIndexada(llistaIndexada);
      }
    }
  }




  const calcularTotal = (sub, desc) =>{
    if(sub !== 0.0){
      if(desc!==0.0){
        const tot =  sub - (sub * desc / 100);
        setTotal(tot);
      }else{
        setTotal(sub);
      }
    }else{
      setTotal(0);
    }
  }

  const onChangeSubtotal = (sub) =>{
    setSubtotal(sub);
    calcularTotal(sub, descompte);
  }

  const onChangeDescompte = (desc) =>{
    setDescompte(desc);
    calcularTotal(subtotal, desc);
  }

  const changeFcrea = (date, dateString) => {
    if(date){
      setFcrea(date.toISOString());
      //Trec l'hora
      //setFcrea(date.format(moment.HTML5_FMT.DATE));
      setErrorFcrea('');
    }else{
      setFcrea(null);
    }
  }

  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_Familia('');
      setErrorId_FamiliaText('');
    }
  }
  const triaPare = (value) =>{
    setId_pare(value);
    if(value>0){
      setErrorId_Pare('');
      setErrorId_PareText('');
    }
  }
  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setErrorId_Alumne('');
      setErrorId_AlumneText('');
    }
  }
  const triaExtraescolar = (value) =>{
    setId_extraescolar(value);
    if(value>0){
      setErrorId_Extraescolar('');
      setErrorId_ExtraescolarText('');
    }
  }

  const submitForm = async (values) => {

    let correcte = true;

    if(id_familia===0){
      setErrorId_Familia('error');
      setErrorId_FamiliaText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_pare===0){
      setErrorId_Pare('error');
      setErrorId_PareText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_alumne===0){
      setErrorId_Alumne('error');
      setErrorId_AlumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_extraescolar===0){
      setErrorId_Extraescolar('error');
      setErrorId_ExtraescolarText(props.t('generic.tria'));
      correcte = false;
    }
    if(!fcrea){
      setErrorFcrea('error');
      correcte = false;
    }

    if(!correcte){
      return false;
    }
    setGuardant(true);
    let dades = {
      id_curs_escolar,
      id,
      id_extraescolar,
      id_pare,
      id_alumne,
      subtotal ,
      descompte,
      total,
      fcrea,
      enviar_email
    }

    try{
      const resposta = await Api.myFetch('/extraescolars/comanda', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: submit ComandesExtraescolarsEdit: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ComandesExtraescolarsEdit: ', error);
    }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return(
    <Form onFinish={submitForm}
      layout="horizontal"
      autoComplete="off"
    >

    <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_Familia} help={errorId_FamiliaText}>
      <Select defaultValue={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
        showSearch
        filterOption={(input, option) =>{
          return HtmlStrings.normalize(option.children.props.children[0]).includes(HtmlStrings.normalize(input))
        }}
      >
        {familiesOptions}
      </Select>
    </Form.Item>

  {id_familia <=0 ? null :
    <>
      <Form.Item label={props.t('generic.responsable')} required={true} validateStatus={errorId_Pare} help={errorId_PareText}>
        <Select value={id_pare===0 ? '' : id_pare} onChange={(value) => triaPare(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {paresOptions}
        </Select>
      </Form.Item>
      <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_Alumne} help={errorId_AlumneText}>
        <Select value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {alumnesOptions}
        </Select>
      </Form.Item>
    </>
  }

  {id_alumne <=0 ? null :
    <Form.Item label={props.t('menu.Extraescolars')} required={true} validateStatus={errorId_Extraescolar} help={errorId_ExtraescolarText}>
      <Select value={id_extraescolar===0 ? '' : id_extraescolar} onChange={(value) => triaExtraescolar(value)}
        showSearch
        filterOption={(input, option) =>{
          //return option.children.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          return HtmlStrings.normalize(option.children.props.children[0].props.children).includes(HtmlStrings.normalize(input));
        }}
      >
        {extraescolarsOptions}
      </Select>
    </Form.Item>
  }
    <Form.Item label={props.t('generic.created_at')} required={true} validateStatus={errorFcrea}>
      <DatePicker
        onChange={changeFcrea}
        value={fcrea ? moment(new Date(fcrea), dateFormat) : ''}
        format={dateFormat}
        locale={localeDatePicker}
        />
    </Form.Item>

    <Form.Item label={props.t('generic.subtotal')}>
        <InputNumber name="subtotal" onChange={onChangeSubtotal} value={subtotal}
        step={1.0}
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.descompte')}>
        <InputNumber name="descompte" onChange={onChangeDescompte} value={descompte}
        min={-100} max={100} step={0.1}
        formatter={value => `${value}%`}
        parser={value => value.replace('%', '')}
        />
    </Form.Item>
    <Form.Item label={props.t('generic.total')}>
        <InputNumber name="total" value={total} disabled
        formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/€\s?|(,*)/g, '')}
        />
    </Form.Item>
    <Row>
      <Space>
        <span>{props.t('generic.enviarEmail')}</span>
        <Switch onChange={setEnviar_email} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
      </Space>
    </Row>
    <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
      <Col>
        <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
      </Col>
      <Col style={{marginLeft:'10px'}}>
        <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
      </Col>
    </Row>
    </Form>
  );

}
export default translate(ComandesExtraescolarsAdd);
