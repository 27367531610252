
import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Select, Button } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { Api } from '../helpers';
import { PerfilUsuariContext } from "../context/perfil-usuari";

const entities = new AllHtmlEntities();

function TriaCursEscolar({defaultValue, onChange, t}){
  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [selectCursosEscolars, setSelectCursosEscolars] = useState([]);
  const [triat, setTriat] = useState(()=>{
    if(defaultValue){
      return [defaultValue];
    }
    return [perfilUsuari.curs_escolar_actual.id];
  });

  useEffect(() => {
    if(defaultValue){
      setTriat([defaultValue]);
    }
  },[defaultValue]);

  useEffect(() => {
    buscarDades();
  },[perfilUsuari.curs_escolar_actual.id]);

  const buscarDades = async () =>{
    const cursos = await Api.get('/cursosescolars');
    if(cursos.result.success===1){
      const select = cursos.data.cursos.map((item)=>{
        return(
          <Select.Option key={item.id} value={item.id}>
            <span style={item.id !== perfilUsuari.curs_escolar_actual.id ? {color: 'red'} : null}>{t('generic.curs_escolar')}: <b>{entities.decode(item.nom)}</b></span>
          </Select.Option>
        );
      });
      setSelectCursosEscolars(select);
    }
  }

  const handleChange = (value) => {
    setTriat(value);
    if(onChange){
      onChange(value);
    }
  }

    return(
      <>
      <Select
          mode="multiple"
          allowClear
          placeholder={t('generic.curs_escolar_filtra')}
          onChange={handleChange}
          value={triat}
          style={{ minWidth: '200px' }}
        >
          {selectCursosEscolars}
        </Select>
      </>
    );
}
export default translate(TriaCursEscolar);
