import React, {useEffect} from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, Form, Select, Table, Tag, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import Moment from 'react-moment';
import moment from 'moment';

import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();

function NotificacioRead(props){

  useEffect(() => {
    posarLlegida();
    console.log(props.item)
  },[]);


  const posarLlegida = async() =>{

    try{
      const resposta = await Api.myFetch(`/notificacions/llegida/${props.item.id}`, 'PUT');
      if(resposta.result.success === 1){
        //setGuardant(false);
        //message.success(props.t('generic.OK_saved'), 5);
      }else{
        //setGuardant(false);
        console.error('Error: notificacio llegida: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      //setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio Llegida: ', error);
    }
  }

  return(
      <>
        <Row>
          <Col span={4} style={{textAlign: 'right'}}><b>{props.t('notificacions.tipus')}:&nbsp;</b></Col>
          <Col span={20}>{entities.decode(entities.decode(props.item.notificacions_tipus[props.t('idioma')]))}</Col>
          { props.item.motiu < 1 ? null :
            <>
            <Col span={4} style={{textAlign: 'right'}}><b>{props.t('menu.MotiusAbsencia')}:&nbsp;</b></Col>
            <Col span={20}>{entities.decode(entities.decode(props.item.motiu_txt[props.t('idioma')]))}</Col>
            </>
          }
          { !props.item.puntual_dates ? null :
            <>
              <Col span={4} style={{textAlign: 'right'}}><b>{props.t('generic.data')}:&nbsp;</b></Col>
              <Col span={20}>
                { props.item.puntual_dates.map(d =>(
                    <Tag color="red"><Moment date={moment(d).tz('Europe/Madrid')} format="DD/MM/YYYY"/></Tag>
                  ))
                }
              </Col>
            </>
          }

          <Col span={4} style={{textAlign: 'right'}}><b>{props.t('notificacions.origen')}:&nbsp;</b></Col>
          <Col span={20}>{entities.decode(props.item.origen)}</Col>

          <Col span={4} style={{textAlign: 'right'}}><b>{props.t('notificacions.desti')}:&nbsp;</b></Col>
          <Col span={20}>{entities.decode(props.item.desti)}</Col>

          <Col span={4} style={{textAlign: 'right'}}><b>{props.t('notificacions.alumne')}:&nbsp;</b></Col>
          <Col span={20}>{entities.decode(props.item.alumne)}</Col>

          <Col span={4} style={{textAlign: 'right'}}><b>{props.t('generic.created_at')}:&nbsp;</b></Col>
          <Col span={20}>{props.item.created_at ? <Moment date={moment(props.item.created_at).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" }</Col>

          <Col span={4} style={{textAlign: 'right'}}><b>{props.t('notificacions.missatge')}:&nbsp;</b></Col>
          <Col span={20}><p>{entities.decode(props.item.missatge)}</p></Col>

        </Row>
      </>
  );

}
export default translate(NotificacioRead);
