import React, { useState, useEffect }  from 'react';
import rabbit from 'crypto-js/rabbit';
import encUtf8 from 'crypto-js/enc-utf8';
import sha512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

import { translate } from 'react-multi-lang';
import { CheckOutlined, UserOutlined, WarningOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Form, Divider, Button, Input, Row, Col, Select, Switch, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();

function UsersEdit(props){

  const [id, setId] = useState(props.item.id);
  const [name, setName] = useState(entities.decode(props.item.name));
  const [username, setUsername] = useState(props.item.username);
  const [password, setPassword] = useState();
  const [perfil, setPerfil] = useState(props.item.perfil);
  const [language, setLanguage] = useState(props.item.language);
  const [locked, setLocked] = useState(props.item.locked);
  const [obligar_modificar_password, setObligar_modificar_password] = useState(props.item.obligar_modificar_password);

  const [perfilOptions, setPerfilOptions] = useState([]);

  const [loading, setLoading] = useState(false)
  const [nou, setNou] = useState(props.item.id===0);

  useEffect(() => {
    buscarDades();
  },[props.t('idioma')]);

  const buscarDades = async () => {
    const p = await Api.get('/perfils/users');
    console.log(p)
    if(p.result.success===1){
      const llista_p = p.data.perfils.map((item) =>{
          let disabled = true;
          if(['Administrador', 'Auxiliar'].indexOf(props.item.perfil) == -1 ){
            disabled = true;
          }else{
            disabled = ['Administrador', 'Auxiliar'].indexOf(item.id) == -1 ? true : false;
          }
            return(
              <Select.Option value={item.id} disabled={disabled}>{item.nom[props.t('idioma')]}</Select.Option>
            );
      });
      /*
      <Select.Option value="Pare">Pare</Select.Option>
      <Select.Option value="Administrador">Administrador</Select.Option>
      <Select.Option value="Auxiliar">Auxiliar administratiu</Select.Option>
      <Select.Option value="Professor">Professor</Select.Option>
      */
      setPerfilOptions(llista_p);
    }
  }



  const usernameChange = value => {
    const uname = value.replace(/[^\w]/gi, "");
    setUsername(uname);
  };

  const passwordChange = value => {
    const pass = Base64.stringify(sha512(value));
    setPassword(pass);
  };

  const perfilChange = (value) =>{
    setPerfil(value);
    /*if(value.length>0){
      setErrorTipusPagament('');
      setErrorTipusPagamentText('');
    }*/
  }
  const languageChange = (value) =>{
    setLanguage(value);
    /*if(value.length>0){
      setErrorTipusPagament('');
      setErrorTipusPagamentText('');
    }*/
  }



  const handleSubmit = async (e) => {

    setLoading(true);


    let dades = {
      name: entities.encode(name),
      username,
      password,
      perfil,
      language,
      locked,
      obligar_modificar_password
    }

    let method = nou?'POST':'PUT';
    let url_api = nou? '/users' : '/users/'+id;

    try{

      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
      if(resposta.result.success === 1){
        if(nou){
          setId(resposta.data.id);
          setNou(false);
        }
        setLoading(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setLoading(false);
        console.error('Error: submit Users: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setLoading(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Users.: ', error);
    }

  }


    return (
      <div>
        {/*<h2 style={{marginLeft:'24px'}}>{props.t('users.form_edit')}</h2>*/}
        <Divider style={{marginBottom:'-50px'}}>
          <div style={{backgroundColor:'white', padding:'25px', borderStyle:'solid', borderWidth:'1px', borderRadius:'50%', borderColor:'#e8e8e8'}}>
            <UserOutlined style={{fontSize:'3rem', color:'#e8e8e8'}} />
          </div>
        </Divider>
        <div style={{padding:'30px 24px 35px 24px', backgroundColor:'#F8F8F8'}}>
          <Form
            onFinish={handleSubmit}
            style={{marginTop:'24px'}}
            layout="vertical"
            autoComplete="off">
            <br/>
            <Form.Item>
              <Col span={4}>
              {props.t('users.table_name')}:
              </Col>
              <Col span={20}>
                <Input placeholder={props.t('users.table_name')} value={name} onChange={(e) => setName(e.target.value)}/>
              </Col>
            </Form.Item>
            <Form.Item>
              <Col span={4}>
              {props.t('users.table_user')}:
              </Col>
              <Col span={20}>
                <Input placeholder={props.t('users.table_user')} value={username} onChange={(e) => setUsername(e.target.value)}/>
              </Col>
            </Form.Item>
            <Form.Item>
              <Col span={4}>
              Password:
              </Col>
              <Col span={20}>
                <Input.Password onChange={(e) => passwordChange(e.target.value)} placeholder={props.t('users.form_edit_password')}/>
              </Col>
            </Form.Item>
            <Form.Item>
              <Row justify="start" align="middle">
                <Col style={{marginRight: '0.5rem'}}><span style={{color:'black'}}>{props.t('pares.obligar_modificar_password')}</span></Col>
                <Col>
                  <Switch
                  checkedChildren={props.t('generic.si')}
                  unCheckedChildren={props.t('generic.no')}
                  onChange={setObligar_modificar_password} defaultChecked={obligar_modificar_password}
                  />
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Col span={4}>
              {props.t('generic.perfil')}:
              </Col>
              <Col span={12}>
                <Select defaultValue={perfil} onChange={perfilChange}>
                  {perfilOptions}
                </Select>
              </Col>
            </Form.Item>
            <Form.Item>
              <Col span={4}>
              {props.t('generic.idioma')}:
              </Col>
              <Col span={6}>
                <Select defaultValue={language} onChange={languageChange}>
                  <Select.Option value="es">Castellano</Select.Option>
                  <Select.Option value="ca">Català</Select.Option>
                  <Select.Option value="en">English</Select.Option>
                </Select>
              </Col>
            </Form.Item>
            <Form.Item>
              <Col span={4}>
              {props.t('users.table_estado')}:
              </Col>
              <Col span={6}>
                <Select name="estat_triat" defaultValue={locked} onChange={setLocked}>
                  <Select.Option key="activat" value={0}>
                    <span style={{color:'green'}}>{props.t('users.activo')}&nbsp;<CheckOutlined /></span>
                  </Select.Option>
                  <Select.Option key="desactivat" value={1}>
                    <span style={{color:'red'}}>{props.t('users.bloqueado')}&nbsp;<WarningOutlined /></span>
                  </Select.Option>
                </Select>
              </Col>
            </Form.Item>

            <Row type="flex" justify="space-between">
              <Col>
                <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={loading}>{props.t('generic.accept')}</Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );

}

export default translate(UsersEdit);
