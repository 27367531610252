import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { LeftOutlined } from '@ant-design/icons';
import { Form, Row, Col, DatePicker, Switch, Button, InputNumber, Select, message } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import NomDescripcioIdiomes from '../../../NomDescripcioIdiomes';

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';

function Edit(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const id = props.item.id;
  const [tipus, setTipus] = useState(props.item.tipus);
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [descripcio_ca, setDescripcio_ca] = useState(entities.decode(props.item.descripcio['ca']));
  const [descripcio_en, setDescripcio_en] = useState(entities.decode(props.item.descripcio['en']));
  const [descripcio_es, setDescripcio_es] = useState(entities.decode(props.item.descripcio['es']));
  const [preu, setPreu] = useState(props.item.preu);
  const [descompte, setDescompte] = useState(props.item.descompte);

  const [guardant, setGuardant] = useState(false);

  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');
  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');


  const nou = (props.item.id===0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);


  const selectChange = (value) =>{
    setTipus(value);
    if(value!==0){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }

  const nomChange_ca = (e) =>{
    const n = e.target.value.trim();
    setNom_ca(n);
    if(n.length>0){ setErrorNom_ca('');}
  }
  const nomChange_en = (e) =>{
    const n = e.target.value.trim();
    setNom_en(n);
    if(n.length>0){ setErrorNom_en('');}
  }
  const nomChange_es = (e) =>{
    const n = e.target.value.trim();
    setNom_es(n);
    if(n.length>0){ setErrorNom_es('');}
  }

  const submitForm = async (values) => {

      let correcte = true;

      if(nom_ca.length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(nom_en.length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(nom_es.length === 0){
        setErrorNom_es('error');
        correcte = false;
      }

      if(tipus === null){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        id_curs_escolar,
        tipus,
        nom_ca: entities.encode(nom_ca),
        nom_en: entities.encode(nom_en),
        nom_es: entities.encode(nom_es),
        descripcio_ca: entities.encode(descripcio_ca),
        descripcio_en: entities.encode(descripcio_en),
        descripcio_es: entities.encode(descripcio_es),
        preu,
        descompte
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/menjador' : '/menjador/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormMenjador: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Menjador guardar: ', error);
      }

  }


  return (
  <>
    <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
      <LeftOutlined className="BotoBack" onClick={props.callback}/>
    </div>
    <div style={{padding:'10px 24px 35px 0px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:2}}
        wrapperCol={{span:22}}
        colon={true}
        autoComplete="off"
      >

          <NomDescripcioIdiomes
            errorNom_ca = {errorNom_ca}
            errorNom_en = {errorNom_en}
            errorNom_es = {errorNom_es}
            nomChange_ca = {nomChange_ca}
            nomChange_en = {nomChange_en}
            nomChange_es = {nomChange_es}
            nom_ca = {nom_ca}
            nom_en = {nom_en}
            nom_es = {nom_es}
            visibleDescripcio = {true}
            setDescripcio_ca = {setDescripcio_ca}
            setDescripcio_en = {setDescripcio_en}
            setDescripcio_es = {setDescripcio_es}
            descripcio_ca = {descripcio_ca}
            descripcio_en = {descripcio_en}
            descripcio_es= {descripcio_es}
          />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

        <Col span={5} key='tipus'>
          <Form.Item style={{marginBottom: 10}}
            validateStatus={errorSelect}
            help={errorSelectText}
            colon={true}
            label={props.t('menjador.tipus')}
            labelCol={{span:7}}
            wrapperCol={{span:17}}
            required={true}
          >
            <Select defaultValue={tipus == null ? "" : tipus} defaultActiveFirstOption={true} onChange={selectChange}>
              <Select.Option key='mensual' value='mensual'>
                {props.t('menjador.mensual')}
              </Select.Option>
              <Select.Option key='puntual' value='puntual'>
                {props.t('menjador.puntual')}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={3} key='preu'>
          <Form.Item style={{marginBottom: 10}}
            colon={true}
            label={props.t('menjador.preu')}
            labelCol={{span:8}}
            wrapperCol={{span:16}}
          >
              <InputNumber name="preu" onChange={setPreu} defaultValue={preu}
              min={0.0} step={1.0}
              formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/€\s?|(,*)/g, '')}
              />
          </Form.Item>
        </Col>
        <Col span={4} key='descompte'>
          <Form.Item style={{marginBottom: 10}}
            colon={true}
            label={props.t('menjador.descompte')}
            labelCol={{span:9}}
            wrapperCol={{span:14}}
          >
              <InputNumber name="descompte" onChange={setDescompte} defaultValue={descompte}
              min={-100} max={100} step={0.1}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
              />
          </Form.Item>
        </Col>
        </Row>

        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  </>
  );
}
export default translate(Edit);
