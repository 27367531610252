import React, { useState, useEffect} from 'react';
import { translate } from 'react-multi-lang';
import { Form, Select, Alert, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../../TitolPagina';

import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();

function QuotesDefault(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const id_tipus_quota = props.tipus_quota.id;
  const [llistatQuotes, setLlistatQuotes] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    buscarDades();
  },[props.forzarActualizar]);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  const buscarDades = async () => {
    setIsLoading(true);
    const q = await Api.get(`/quotes/default/tipus/${id_tipus_quota}/${id_curs_escolar}`);
    if(q.result.success===1){
      const llista = q.data.quotes_default.filter((element, index, arr) => element.quotes_triables.length > 0);
      setLlistatQuotes(llista);
    }
    setIsLoading(false);
  }

  const handleChange = async (id_quota, id_curs) => {
    const dades = {
      id_curs_escolar,
      id_tipus_quota,
      id_curs,
      id_quota
    }
    const resp = await Api.myFetch(`/quotes/default/tipus/${id_tipus_quota}/curs/${id_curs}`,'PUT', JSON.stringify(dades));
    if(resp.result.success === 1){
      message.success({content: props.t('generic.OK_saved'), duration:5, top: 50});
    }else{
      console.error('Error PUT: QuotesDefault: ',resp);
      message.open({content: resp.data.message, duration:5, top: 50});
    }

  }

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {

    if(llistatQuotes.length<=0) {return null;}

      const selects = llistatQuotes.map((item) =>{
        const options = item.quotes_triables.map((triable) =>{
          return <Select.Option key={triable.id_quota} value={triable.id_quota}>{triable.quota_text[props.t('idioma')]}</Select.Option>
        });
        return(
        <Form.Item label={entities.decode(item.curs_nom)} key={item.id_curs} required={true}>
          <Select defaultValue={item.id_quota!==0 ? item.id_quota : ''} onChange={(value) => handleChange(value, item.id_curs)}>
            {options}
          </Select>
        </Form.Item>
        );
      });
      return(
      <>
        <TitolPagina txt={props.t('quotes.per_defecte')} level={4} style={{marginTop:'1rem', marginLeft:'0.5rem', paddingLeft:'0.5rem'}}/>
        <Form
          layout="horizontal"
          labelCol={{span:3}}
          wrapperCol={{span:10}}
          colon={true}
          autoComplete="off"
        >
          {selects}
        </Form>
      </>
    );
  }
}
export default translate(QuotesDefault);
