import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';
import { MenuTriatContext } from "../../../../context/menu-triat";
import { Api, InitDades } from '../../../../helpers';
import ImageCropper from '../../../ImageCropper';
import ImatgeDNI from '../../../ImatgeDNI';
import VisorImatges from "../../../visorImatges";
import TextAreaCount from '../../../TextAreaCount';


const entities = new AllHtmlEntities();


function Edit(props){

  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

  const [id, setId] = useState(props.item.id);
  const [nom, setNom] = useState(entities.decode(props.item.nom));
  const [cognom1, setCognom1] = useState(entities.decode(props.item.cognom1));
  const [cognom2, setCognom2] = useState(entities.decode(props.item.cognom2));
  const [naixement, setNaixement] = useState(props.item.naixement || '');
  const [naixement_ciutat, setNaixement_ciutat] = useState(entities.decode(props.item.naixement_ciutat));
  const [nacionalitat, setNacionalitat] = useState(entities.decode(props.item.nacionalitat));
  const [dni, setDni] = useState(props.item.dni);
  const [dni_image_1, setDni_image_1] = useState();
  const [dni_image_2, setDni_image_2] = useState();
  const [passaport, setPassaport] = useState(props.item.passaport);
  const [passaport_image_1, setPassaport_image_1] = useState(props.item.passaport_image_1);
  const [passaport_image_2, setPassaport_image_2] = useState(props.item.passaport_image_2);
  const [lloc_entre_germans, setLloc_entre_germans] = useState(props.item.lloc_entre_germans);
  const [num_germans, setNum_germans] = useState(props.item.num_germans);
  const [llengua_materna, setLlengua_materna] = useState(entities.decode(props.item.llengua_materna));
  const [escoles_anteriors, setEscoles_anteriors] = useState(entities.decode(props.item.escoles_anteriors));



/* Auxiliars */
  const [dni_image_1_dataUrl, setDni_image_1_dataUrl ] = useState(undefined);
  const [dni_image_2_dataUrl, setDni_image_2_dataUrl ] = useState(undefined);
  const [passaport_image_1_dataUrl, setPassaport_image_1_dataUrl ] = useState(undefined);
  const [passaport_image_2_dataUrl, setPassaport_image_2_dataUrl ] = useState(undefined);

  const [visor, setVisor] = useState({visible: false});

  const [errorNom, setErrorNom] = useState('');
  const [errorCognom1, setErrorCognom1] = useState('');
  const [errorCognom2, setErrorCognom2] = useState('');
  /*
  const [errorSexe, setErrorSexe] = useState('');
  const [errorSexeText, setErrorSexeText] = useState('');
  */

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const contenidorRef = React.useRef();

  const buscarDades = async () => {
    setIsLoading(true);

    const alum = await Api.get('/alumne/'+id);
    if(alum.result.success===1){
      setNom(entities.decode(alum.data.nom));
      setCognom1(entities.decode(alum.data.cognom1));
      setCognom2(entities.decode(alum.data.cognom2));
      setNaixement(alum.data.naixement || '');
      setNaixement_ciutat(entities.decode(alum.data.naixement_ciutat));
      setNacionalitat(entities.decode(alum.data.nacionalitat));
      setDni(alum.data.dni);
      setPassaport(alum.data.passaport);
      setLloc_entre_germans(alum.data.lloc_entre_germans);
      setNum_germans(alum.data.num_germans);
      setLlengua_materna(entities.decode(alum.data.llengua_materna));
      setEscoles_anteriors(entities.decode(alum.data.escoles_anteriors));
    }

    const dni_alumne = await Api.get('/alumnes/dni/'+id);
    if(dni_alumne.result.success===1){
      setDni_image_1(dni_alumne.data.dni_image_1);
      setDni_image_2(dni_alumne.data.dni_image_2);
      setPassaport_image_1(dni_alumne.data.passaport_image_1);
      setPassaport_image_2(dni_alumne.data.passaport_image_2);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);




  const submitForm = async (values) => {


    let correcte = true;
    if(!nom){
      setErrorNom('error');
      correcte = false;
    }
    if(!cognom1){
      setErrorCognom1('error');
      correcte = false;
    }
    if(!cognom2){
      setErrorCognom2('error');
      correcte = false;
    }

    /*if(!sexe){
      setErrorSexe('error');
      setErrorSexeText(props.t('generic.tria'));
      correcte = false;
    }*/


    if(!correcte){
      contenidorRef.current.scrollIntoView({
        behavior: "smooth",
      });
      return false;
    }
      setGuardant(true);

      let dades = {
        id,
        nom: entities.encode(nom),
        cognom1: entities.encode(cognom1),
        cognom2: entities.encode(cognom2),
        naixement,
        naixement_ciutat: entities.encode(naixement_ciutat),
        nacionalitat: entities.encode(nacionalitat),
        dni,
        passaport,
        lloc_entre_germans,
        num_germans,
        llengua_materna: entities.encode(llengua_materna),
        escoles_anteriors: entities.encode(escoles_anteriors)
      }


      //console.log('Alumnes ans fetch: ',JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch('/alumne', 'PUT', JSON.stringify(dades));

        if(resposta.result.success === 1){

          if(dni_image_1_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/alumnes/dni/'+resposta.data.id+'/1', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_1(resp.data.dni_image_1);
              }else{
                console.error('Error POST file: Alumnes dni_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(dni_image_2_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/alumnes/dni/'+resposta.data.id+'/2', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_2(resp.data.dni_image_2);
              }else{
                console.error('Error POST file: Alumnes dni_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_1_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/alumnes/dni/'+resposta.data.id+'/3', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_1(resp.data.passaport_image_1);
              }else{
                console.error('Error POST file: Alumnes passaport_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_2_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/alumnes/dni/'+resposta.data.id+'/4', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_2(resp.data.passaport_image_2);
              }else{
                console.error('Error POST file: Alumnes passaport_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }


          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          //setMenuTriat("Dashboard");
        }else{
          setGuardant(false);
          console.error('Error: submit FormAlumnes: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Alumne guardar: ', error);
      }

  }

/*
  const triaSexe = (value) =>{
    setSexe(value);
    if(value.length>0){
      setErrorSexe('');
      setErrorSexeText('');
    }
  }
  */

  const changeNaixement = (date, dateString) => {
    if(date){
      //Trec l'hora
      setNaixement(date.format(moment.HTML5_FMT.DATE));
    }else{
      setNaixement(null);
    }
  }


  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';
  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {
    return(
    <div style={{marginTop: '1rem'}}>
      <div style={{padding:'10px 24px 35px 0px'}} ref={contenidorRef}>
        <Form onFinish={submitForm}
          layout="vertical"
          autoComplete="off"
        >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} lg={12} xl={8}>
          <Form.Item label={props.t('alumnes.nom')} validateStatus={errorNom} required={true}>
            <Input name="nom" placeholder={props.t('alumnes.nom')} onChange={(e) => setNom(e.target.value)} defaultValue={nom}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.cognom1')} validateStatus={errorCognom1} required={true}>
            <Input name="cognom1" placeholder={props.t('alumnes.cognom1')} onChange={(e) => setCognom1(e.target.value)} defaultValue={cognom1}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.cognom2')} validateStatus={errorCognom2} required={true}>
            <Input name="cognom2" placeholder={props.t('alumnes.cognom2')} onChange={(e) => setCognom2(e.target.value)} defaultValue={cognom2}/>
          </Form.Item>
          {/*<Form.Item label={props.t('alumnes.sexe')} required={true} validateStatus={errorSexe} help={errorSexeText} style={{marginBottom: 0}}>
            <Select defaultValue={sexe} onChange={triaSexe} style={{ width: 100}}>
              <Select.Option value="NENA">{props.t('alumnes.sexe_nena')}</Select.Option>
              <Select.Option value="NEN">{props.t('alumnes.sexe_nen')}</Select.Option>
              <Select.Option value="ALTRES">{props.t('alumnes.sexe_altres')}</Select.Option>
            </Select>
          </Form.Item>*/}
          <Form.Item label={props.t('alumnes.naixement')} required={true}>
            <DatePicker
              onChange={changeNaixement}
              value={naixement ? moment(new Date(naixement), dateFormat) : ''}
              format={dateFormat}
              locale={localeDatePicker}
              picker="date"
              />
          </Form.Item>
          <Form.Item label={props.t('alumnes.naixement_ciutat')}>
            <Input name="naixement_ciutat" placeholder={props.t('alumnes.naixement_ciutat')} onChange={(e) => setNaixement_ciutat(e.target.value)} defaultValue={naixement_ciutat}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.nacionalitat')}>
            <Input name="nacionalitat" placeholder={props.t('alumnes.nacionalitat')} onChange={(e) => setNacionalitat(e.target.value)} defaultValue={nacionalitat}/>
          </Form.Item>


          <Form.Item label={props.t('alumnes.lloc_entre_germans')}>
            <InputNumber name="lloc_entre_germans" onChange={setLloc_entre_germans} defaultValue={lloc_entre_germans}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.num_germans')}>
            <InputNumber name="num_germans" onChange={setNum_germans} defaultValue={num_germans}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.llengua_materna')}>
            <Input name="llengua_materna" placeholder={props.t('alumnes.llengua_materna')} onChange={(e) => setLlengua_materna(e.target.value)} defaultValue={llengua_materna}/>
          </Form.Item>
          <Divider />

          <Form.Item label={props.t('alumnes.escoles_anteriors')}>
            <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={1024} name="escoles_anteriors" placeholder={props.t('alumnes.escoles_anteriors')} onChange={setEscoles_anteriors} defaultValue={escoles_anteriors}/>
          </Form.Item>



        </Col>
        <Col xs={24} lg={12} xl={16}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item
                label={props.t('pares.dni')}
                labelCol={{span:2}}
                wrapperCol={{span:10}}
              >
                <Input name="dni" placeholder={props.t('pares.dni')} onChange={(e) => setDni(e.target.value)} defaultValue={dni}/>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={dni_image_1}
                  title={props.t('pares.dni_image_1')}
                  dataUrl={dni_image_1_dataUrl}
                  setDataUrl={setDni_image_1_dataUrl}
                  setVisor={setVisor}
                />
            </Col>
            <Col  xs={24} xl={12}>
                <ImatgeDNI
                  image={dni_image_2}
                  title={props.t('pares.dni_image_2')}
                  dataUrl={dni_image_2_dataUrl}
                  setDataUrl={setDni_image_2_dataUrl}
                  setVisor={setVisor}
                />
            </Col>
              <Col span={24} style={{paddingTop: '3rem'}}>
                <Form.Item
                  label={props.t('pares.passaport')}
                  wrapperCol={{span:12}}
                >
                  <Input name="passaport" placeholder={props.t('pares.passaport')} onChange={(e) => setPassaport(e.target.value)} defaultValue={passaport}/>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={passaport_image_1}
                  title={props.t('pares.passaport_image_1')}
                  dataUrl={passaport_image_1_dataUrl}
                  setDataUrl={setPassaport_image_1_dataUrl}
                  setVisor={setVisor}
                />
              </Col>
              <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={passaport_image_2}
                  title={props.t('pares.passaport_image_2')}
                  dataUrl={passaport_image_2_dataUrl}
                  setDataUrl={setPassaport_image_2_dataUrl}
                  setVisor={setVisor}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
              <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
                <Col>
                  <Button onClick={(e) => setMenuTriat("Dashboard")}>{props.t('generic.cancel')}</Button>
                </Col>
                <Col style={{marginLeft:'10px'}}>
                  <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
                </Col>
              </Row>
          </Col>
        </Row>
        </Form>
        { !visor.visible
          ? null
          : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
        }
      </div>
    </div>
    );
  }
}
export default translate(Edit);
