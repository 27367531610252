import React, { useState, useContext } from 'react';
import { translate, setLanguage, getLanguage } from 'react-multi-lang';
import { Menu, Select, Row, Col } from 'antd';
import {
  ApartmentOutlined,
  ContactsOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  PoweroffOutlined,
  SettingOutlined,
  SkinOutlined,
  TeamOutlined,
  UserOutlined,
  FontSizeOutlined,
  FilePdfOutlined,
  DatabaseOutlined,
  InboxOutlined,
  FolderOutlined,
  ReconciliationOutlined,
  EuroCircleOutlined,
  TableOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  PartitionOutlined,
  BellOutlined,
  CheckSquareOutlined,
  MedicineBoxOutlined,
  MailOutlined,
} from '@ant-design/icons';

import { MenuTriatContext } from "../context/menu-triat";
import {ReactComponent as Carrito} from '../icons/icones_app_BSCD-01.svg';
import { Api } from '../helpers';

const { SubMenu } = Menu;

function MenuLateral(props){
    const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
    const [openKeys, setOpenKeys] = useState(['Dashboard']);
  // submenu keys of first level
    const rootSubmenuKeys = ['Dashboard','families', 'TaulesAuxiliars', 'Serveis', 'Gestio', 'Llistats', 'configuracio'];



    const changeLang = (lang) =>{
      setLanguage(lang);
      sessionStorage.setItem('Language',lang);
    }

  const onOpenChange = openKeys_list => {
    const latestOpenKey = openKeys_list.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeys_list);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);

    }
  };
  const triarMenu = (menuTriat) =>{
    if(Api.refreshTokenCorrecte()){
      setMenuTriat(menuTriat);
    }else{
      //refresh_token caducat, per tant el token està caducat no es
      //pot renovar automaticament i s'ha de fer logout
      setMenuTriat("Logout");
    }

  }

    return (
      <Menu
        theme="light"
        className="MenuLateral"
        mode="inline"
        inlineIndent={12}
        onClick={({key}) => triarMenu(key)}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{ width: '100%', height: '100%' }}
        defaultSelectedKeys="Dashboard"
      >
        <Menu.Item key="Dashboard" className="MenuLateral">
          <span>
            <HomeOutlined />
            <span>{props.t('menu.inicio')}</span>
          </span>
        </Menu.Item>

        <SubMenu
         className="MenuLateral"
          key="families"
          title={
            <span>
              <ContactsOutlined />
              <span>{props.t('menu.Families')}</span>
            </span>
          }
        >
        <Menu.Item key="UnitatFamiliar" className="MenuLateral">
          <span>
            <TeamOutlined />
            <span>{props.t('menu.UnitatFamiliar')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="Pares" className="MenuLateral">
          <span>
            <UserOutlined />
            <span>{props.t('menu.Pares')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="Alumnes" className="MenuLateral">
          <span>
            <SkinOutlined />
            <span>{props.t('menu.Alumnes')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="ServeiMedic" className="MenuLateral">
          <span>
            <MedicineBoxOutlined />
            <span>{props.t('menu.ServeiMedic')}</span>
          </span>
        </Menu.Item>
        </SubMenu>

        {/*DOCÈNCIA*/}
        <SubMenu
         className="MenuLateral"
          key="Docencia"
          title={
            <span>
              <ApartmentOutlined />
              <span>{props.t('menu.Docencia')}</span>
            </span>
          }
        >
        <Menu.Item key="Professors" className="MenuLateral">
          <span>
            <UserOutlined />
            <span>{props.t('menu.Professors')}</span>
          </span>
        </Menu.Item>
        <Menu.Item key="AdminPassarLlista" className="MenuLateral">
          <span>
            <CheckSquareOutlined />
            <span>{props.t('professors_relacions.passarLlista')}</span>
          </span>
        </Menu.Item>
        </SubMenu>





        <SubMenu
         className="MenuLateral"
          key="Llistats"
          title={
            <span>
              <FilePdfOutlined />
              <span>{props.t('menu.Llistats')}</span>
            </span>
          }
        >
          <Menu.Item key="AlumnesTelefons" className="MenuLateral">
            <span>{props.t('menu.AlumnesTelefons')}</span>
          </Menu.Item>
          <Menu.Item key="AlumnesTotsTelefons" className="MenuLateral">
            <span>{props.t('menu.AlumnesTotsTelefons')}</span>
          </Menu.Item>
          <Menu.Item key="AlumnesEmails" className="MenuLateral">
            <span>{props.t('menu.AlumnesEmails')}</span>
          </Menu.Item>
          <Menu.Item key="LlistatsExcel" className="MenuLateral">
            <span>{props.t('menu.LlistatsExcel')}</span>
          </Menu.Item>
        </SubMenu>

        <SubMenu
         className="MenuLateral"
          key="Gestio"
          title={
            <span>
              <DatabaseOutlined />
              <span>{props.t('menu.Gestio')}</span>
            </span>
          }
        >
          {/*<Menu.Item key="Documents" className="MenuLateral">
            <span>
              <FolderOutlined />
              <span>{props.t('menu.Documents')}</span>
            </span>
          </Menu.Item>*/}
          <SubMenu
           className="MenuLateral"
            key="Comandes"
            title={
              <span>
                <InboxOutlined />
                <span>{props.t('menu.Comandes')}</span>
              </span>
            }
          >
            <Menu.Item key="ComandesRoba" className="MenuLateral">
              <span>{props.t('menu.ComandesRoba')}</span>
            </Menu.Item>
            <Menu.Item key="ComandesExcursions" className="MenuLateral">
              <span>{props.t('menu.ComandesExcursions')}</span>
            </Menu.Item>
            <Menu.Item key="ComandesEsdeveniments" className="MenuLateral">
              <span>{props.t('menu.ComandesEsdeveniments')}</span>
            </Menu.Item>
            <Menu.Item key="ComandesSummerCamp" className="MenuLateral">
              <span>{props.t('menu.ComandesSummerCamp')}</span>
            </Menu.Item>
            <Menu.Item key="ComandesTransport" className="MenuLateral">
              <span>{props.t('menu.Transport')}</span>
            </Menu.Item>
            <Menu.Item key="ComandesExtraescolars" className="MenuLateral">
              <span>{props.t('menu.Extraescolars')}</span>
            </Menu.Item>
            <Menu.Item key="ComandesMenjador" className="MenuLateral">
              <span>{props.t('menu.Menjador')}</span>
            </Menu.Item>
            <Menu.Item key="ComandesAcollida" className="MenuLateral">
              <span>{props.t('menu.Acollida')}</span>
            </Menu.Item>

          </SubMenu>


    </SubMenu>


    <Menu.Item key="Notificacions_admin" className="MenuLateral">
      <span>
        <MailOutlined />
        <span>{props.t('menu.Notificacions')}</span>
      </span>
    </Menu.Item>

        <SubMenu
         className="MenuLateral"
          key="configuracio"
          title={
            <span>
              <SettingOutlined />
              <span>{props.t('menu.configuracion')}</span>
            </span>
          }
        >



          <div style={{paddingLeft:'24px', paddingBottom:'1rem', marginRight: '20px'}}>
            <span>{props.t('generic.idioma')}:&nbsp;&nbsp;</span>
            <Select defaultValue={getLanguage()} onChange={changeLang} style={{ width: 110}} size="small">
              <Select.Option value="es">Castellano</Select.Option>
              <Select.Option value="ca">Català</Select.Option>
              <Select.Option value="en">English</Select.Option>
              {/*idiomes*/}
            </Select>
          </div>

        </SubMenu>
        <Menu.Item key="Logout" className="MenuLateral">
          <span>
            <PoweroffOutlined />
            <span>{props.t('menu.cerrar_sesion')}</span>
          </span>
        </Menu.Item>
      </Menu>
    );

}

export default translate(MenuLateral);
