import React from 'react';
import { translate } from 'react-multi-lang';
import { PictureOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, Button, Divider, Tooltip, Row, Col, Modal, message } from 'antd';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Pixabay from './Pixabay';

import { Api } from '../helpers';
//const cropper = React.createRef(null);

class ImageCropper extends React.Component {
 constructor(props){
   super(props);
   let usPix = true;
   if(this.props.usarPixabay!==undefined){
     usPix = this.props.usarPixabay;
   }
   this.state = {
     img_src: null,
     desti: this.props.desti,
     guardant: false,
     usarPixabay: usPix,
     modalPixabayVisible: false,
   }
 }

 setModalPixabayVisible = (modalPixabayVisible) => {
   this.setState({modalPixabayVisible});
 }
 tancaModal = () => {
   this.setModalPixabayVisible(false);
 }

  async _crop(){

      await this.setState({guardant: true});
      const dataUrl = this.cropper.getCroppedCanvas({fillColor: '#fff'}).toDataURL('image/jpeg');
      //const dataUrl = this.cropper.getCroppedCanvas().toDataURL();

      console.log("ImageCropper dataUrl: ", dataUrl);

      if(this.props.retorna_dataUrl !== undefined){
        this.setState({
          img_src: null,
          desti: null,
          previewVisible: false,
          guardant: false
        }, () =>{
          this.props.retorna_dataUrl(dataUrl);
        })
      }else{

        const url =  '/logos';
        const blob = await (await fetch(dataUrl)).blob();
        var fd = new FormData();
        fd.append("fileField", blob, "img.jpg");
        fd.append("desti", this.state.desti);

        try{

          let resposta = await Api.myFetch(url, 'POST', fd);

          if(resposta.result.success===1){
            message.success(this.props.t('generic.OK_saved'), 5);
          }else{
            message.error(resposta.data.message, 10);
          }
          await this.setState({guardant: false});
        }catch(err){
          await this.setState({guardant: false});
          console.log("Error upload: ", err);
          message.error(err, 10);
        }
      }

      if(this.props.refrescar !== undefined){
        this.props.refrescar();
      }
    }

    setAspectRatio = (ratio) => {
      this.cropper.setAspectRatio(ratio);
    }

    previewImage = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.setState({
            previewImage: reader.result,
            previewVisible: true,
            }, () =>{
              console.log(this.state);
              resolve(reader.result);
          });

        };
        reader.onerror = error => reject(error);
      });
    }

    previewImage_url = (url) => {
      this.setState({
        previewImage: url,
        previewVisible: true,
      });
    }

render(){

  return (
    <div>
    <Row justify="start">
      <Col>
        <Upload
          action={this.previewImage}
          fileList=""
          beforeUpload={(file) => {
            console.log(file.type);
            let ok = false;
            if (file.type === 'image/png') {ok = true;}
            if (file.type === 'image/jpg') {ok = true;}
            if (file.type === 'image/jpeg') {ok = true;}
            if (file.type === 'image/gif') {ok = true;}
            if (file.type === 'image/webp') {ok = true;}
            if(!ok){
              message.error(`${file.name} is not a valid picture`);
            }
            return ok;
          }}
        >
          <Tooltip placement="bottom" title={this.props.t('generic.btn_upload_file_tooltip')} arrowPointAtCenter>
            <Button>
              <UploadOutlined /> {this.props.t('generic.btn_upload_file')}
            </Button>
          </Tooltip>
        </Upload>
      </Col>
      {!this.state.usarPixabay ? null :
      <>
      <Col><div align="center" style={{paddingTop: '3px', marginLeft: '10px', marginRight: '10px'}}>{this.props.t('generic.or')}</div></Col>
      <Col>
        <Tooltip placement="bottom" title={this.props.t('generic.btn_pixabay_tooltip')} arrowPointAtCenter>
          <Button onClick={() => this.setModalPixabayVisible(true)}>
            <PictureOutlined /> {this.props.t('generic.btn_pixabay')}
          </Button>
        </Tooltip>
      </Col>
      </>
      }
    </Row>
    {!this.state.previewVisible ? null :
      <div>
      <Divider/>
        <Cropper
          aspectRatio={1/3}
          style={{height: 400, width: '100%'}}
          guides={true}
          {...this.props}
          ref={cropper => { this.cropper = cropper; }}
          src={this.state.previewImage}
        />
        <Divider/>
        <Tooltip placement="bottom" title={this.props.t('imageCropper.btn_imageCropper')} arrowPointAtCenter>
          <Button onClick={this._crop.bind(this)} loading={this.state.guardant}>
            {this.props.t('generic.modify')}
          </Button>
        </Tooltip>
      </div>
      }

      <Modal
          title="Pixabay"
          style={{ top: 20 }}
          visible={this.state.modalPixabayVisible}
          onOk={() => this.setModalPixabayVisible(false)}
          onCancel={() => this.setModalPixabayVisible(false)}
          footer={null}
          width={720}
        >
          <Pixabay modalVisible={this.tancaModal} returnImage={this.previewImage_url}/>
        </Modal>

    </div>
  );
}

}
export default translate(ImageCropper);
