import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Button, Modal, Popover, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { Api, InitDades } from '../../../../helpers';
import FormAlumnes from '../../../Formularis/perfils/admin/Alumnes';

const entities = new AllHtmlEntities();

function BotonsFills(props){

  const id_familia = props.id_familia ? props.id_familia : 0;
  const id_alumne_actual = props.id_alumne_actual ? props.id_alumne_actual : 0;
  const label = props.label ? props.label : props.t('menu.DadesFills');

  const [botonsFills, setBotonsFills] = useState([]);
  const [fillTriat, setFillTriat] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [refrescar, setRefrescar] = useState(0);


  useEffect(() => {
    buscarDades();
  },[props.t('idioma'),refrescar]);


  const buscarDades = async () => {
    if(id_familia > 0){

      const fills = await Api.get('/alumnes/tots/familia/'+id_familia);

      if(fills.result.success===1){
        const filtrat = fills.data.alumnes.filter((element, index, arr) => element.id !== id_alumne_actual);
        const llista_fills = filtrat.map((item) =>{
          if(item.id!==id_alumne_actual){
            const content = (
              <div>
                <p>{props.t('alumnes.classe')}: {item.nom_classe}</p>
                <p>{props.t('alumnes.estat.titol')}: {props.t(`alumnes.estat.${item.estat}`)}</p>
              </div>
            );

            return(
              <Popover placement="bottom" key={item.id} content={content} title={entities.decode(item.nom)+' '+entities.decode(item.cognom1)+' '+entities.decode(item.cognom2)}>
                <Button key={item.id} type="dashed" onClick={(e) => obrirModal(item)} style={{marginLeft: 10}}>{entities.decode(item.nom)+' '+entities.decode(item.cognom1)}</Button>
              </Popover>
            );
            }
        });

        let item_blanc = InitDades.alumne();
        item_blanc.id_familia = id_familia;

        llista_fills.push(
          <Tooltip placement="bottom" title={props.t('generic.add')}>
            <Button key={999} type="dashed" onClick={(e) => obrirModal(item_blanc)} style={{marginLeft: 10}} icon={<PlusOutlined style={{color: '#C2C2C2'}}/>}/>
          </Tooltip>
        );

        if(llista_fills.length>0){
          const b = (
            <span style={{margin:'0.5rem'}}>
              <span style={{fontSize:'0.9rem', fontWeight:'bold'}}>{label} : </span>{llista_fills}&nbsp;&nbsp;
            </span>
          );
          setBotonsFills(b);
        }else{
          setBotonsFills([]);
        }
      }
    }
  }



  const obrirModal = item =>{
    setFillTriat(item);
    setModalVisible(true);
  }
  const tancarModal = e =>{
    setModalVisible(false);
    setRefrescar(refrescar+1);
  }

  return(
    <>
    {botonsFills}
    <Modal
        key="fills"
        title={props.t('menu.Alumnes')}
        visible={modalVisible}
        footer={null}
        onCancel={tancarModal}
        width={1350}
        style={{top:20}}
        //bodyStyle={{height:"1300px"}}
        bodyStyle={{ height: "90vh", overflow: "auto" }}
        destroyOnClose={true}
        forceRender={true}
      >
        <FormAlumnes item={fillTriat} callback={tancarModal} popup={true}/>
      </Modal>
    </>
  );
}
export default translate(BotonsFills);
