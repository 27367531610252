import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Button, Modal, Popover, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';
import { Api, InitDades } from '../../../../helpers';
import FormPares from '../../../Formularis/perfils/admin/Pares';

const entities = new AllHtmlEntities();

function BotonsPares(props){

  const id_familia = props.id_familia ? props.id_familia : 0;
  const id_pare_actual = props.id_pare_actual ? props.id_pare_actual : 0;
  const [botonsPares, setBotonsPares] = useState([]);
  const [pareTriat, setPareTriat] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [refrescar, setRefrescar] = useState(0);


  useEffect(() => {
    buscarDades();
  },[props.t('idioma'), refrescar]);


  const buscarDades = async () => {
    if(id_familia > 0){

      const pares = await Api.get('/pares/familia/'+id_familia);

      if(pares.result.success===1){
        const filtrat = pares.data.filter((element, index, arr) => element.id !== id_pare_actual);
        const llista_pares =filtrat.map((item) =>{
            const content = (
              <div>
                <p>{props.t('pares.tel1')}: <a href={`tel:${item.tel1}`}>{item.tel1}</a></p>
                <p>{props.t('pares.tel2')}: <a href={`tel:${item.tel2}`}>{item.tel2}</a></p>
                <p>{props.t('pares.email')}: <a href={`mailto:${item.email}`}>{item.email}</a></p>
              </div>
            );

            return(
              <Popover placement="bottom" key={item.id} content={content} title={entities.decode(item.nom)+' '+entities.decode(item.cognom1)+' '+entities.decode(item.cognom2)}>
                <Button key={item.id} type="dashed" onClick={(e) => obrirModal(item)} style={{marginLeft: 10}}>{entities.decode(item.nom)+' '+entities.decode(item.cognom1)}</Button>
              </Popover>
            );
        });

        let item_blanc = InitDades.pare();
        item_blanc.id_familia = id_familia;

        llista_pares.push(
          <Tooltip placement="bottom" title={props.t('generic.add')}>
            <Button key={999} type="dashed" onClick={(e) => obrirModal(item_blanc)} style={{marginLeft: 10}} icon={<PlusOutlined style={{color: '#C2C2C2'}}/>}/>
          </Tooltip>
        );


        if(llista_pares.length>0){
          const b = (
            <span style={{margin:'0.5rem'}}>
              <span style={{fontSize:'0.9rem', fontWeight:'bold'}}>{props.t('menu.Pares')} : </span>{llista_pares}&nbsp;&nbsp;
            </span>
          );
          setBotonsPares(b);
        }else{
          setBotonsPares([]);
        }
      }
    }
  }



  const obrirModal = item =>{
    setPareTriat(item);
    setModalVisible(true);
  }
  const tancarModal = e =>{
    setModalVisible(false);
    setRefrescar(refrescar+1);
  }

  return(
    <>
    {botonsPares}
    <Modal
        key="pares"
        title={props.t('menu.Pares')}
        visible={modalVisible}
        footer={null}
        onCancel={tancarModal}
        width={1350}
        style={{top:20}}
        bodyStyle={{ height: "90vh", overflow: "auto" }}
        destroyOnClose={true}
        forceRender={true}
      >
        <FormPares item={pareTriat} callback={tancarModal} popup={true}/>
      </Modal>
    </>
  );
}
export default translate(BotonsPares);
