import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import { Tabs, Button, Row } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import QuotesPreusCurs from './QuotesPreusCurs';
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import { Api } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const entities = new AllHtmlEntities();

function QuotesPreus(props){
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  const [categoria, setCategoria] = useState('1');
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);

  const buscarDades = async () => {
    setIsLoading(true);

    const c = await Api.get(`/quotes/tipus/${cursEscolarTriat}`);
    if(c.result.success===1){
      const llista_c = c.data.quotes_tipus.map((item) =>{
            return(
              <Tabs.TabPane tab={entities.decode(item.nom[props.t('idioma')])} key={item.id}>
                <QuotesPreusCurs tipus_quota={item} idioma={props.t('idioma')} id_curs_escolar={cursEscolarTriat}/>
              </Tabs.TabPane>
            );
      });
      setCategories(llista_c);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[cursEscolarTriat, props.t('idioma')]);
  //},[getLanguage()]);


//const operations = <Button onClick={() => buscarDades()} type="dashed" shape="circle" icon={<SyncOutlined />} />;
//<Tabs defaultActiveKey={categoria} onChange={setCategoria} tabBarExtraContent={operations}>


  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menu.QuotesPreus')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :
        <>
          <Row style={{marginBottom: 10}}>
            <TriaCursEscolar onChange={(c) => setCursEscolarTriat(c)} defaultValue={cursEscolarTriat}/>
          </Row>
          <Tabs defaultActiveKey={categoria} onChange={setCategoria} tabPosition='left'>
            {categories}
          </Tabs>
        </>
      }
      </div>
    </ContainerPagina>
  );
}

export default translate(QuotesPreus);
