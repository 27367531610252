
import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

function TextAreaCount(props){
  const [count, setCount] = useState(props.defaultValue !== undefined ? props.defaultValue.length || 0 : props.value.length || 0);
  const [countLimit] = useState(props.maxLength);
  const [countColor, setCountcolor] = useState('#c9c9c9')

  useEffect(() => {
    if(props.value){
      setCount(props.value.length || 0)
    }
  },[props]);

  const textChange = (e) =>{
    const n = e.target.value.length;

    if(countLimit > 0){
      let color = '#c9c9c9'; //Griset
      if(n >= countLimit - Math.round(countLimit/100*10)){color = 'orange';} //Quan quedi el 10% es pinta de taronja
      if(n >= countLimit - 5){color = 'red';} //Quan quedin 5 es pinta de vermell
      setCountcolor(color);
    }
    setCount(n);
    if(props.onChange){
      props.onChange(e.target.value);
    }
  }
  /*
            onChange={textChange}
            rows={props.rows || null}
            maxLength={props.maxLength || null}
            name={props.name || null}
            placeholder={props.placeholder || null}
            defaultValue={props.defaultValue || null}
            autoSize={props.autoSize || null}
  */

    return(
      <div>

        <Input.TextArea
          {...props}
          onChange={textChange}
        />
        <div style={{textAlign: 'right', marginTop: `${props.marginTopCount ? props.marginTopCount : '-0.5rem'}`, color: `${countColor}`}}> {count}{countLimit > 0 && `/${countLimit}`}</div>

      </div>
    );
}
export default TextAreaCount;
