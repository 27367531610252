import React, {useEffect, useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Select } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import InformeIngressosExportaExcel from '../../../components/Formularis/perfils/admin/InformeIngressosExportaExcel';
import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function InformeIngressos(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [id_pressupost, setId_pressupost] = useState(57);
  const [selectPressupostos, setSelectPressupostos] = useState([]);
  const [pressupostosDesc, setPressupostosDesc] = useState([]);
  const [llista, setLlista] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPressupost, setLoadingPressupost] = useState(true);


  useEffect(() => {
    buscarDadesPressupost();
  },[]);


  useEffect(() => {
    buscarDades();
  },[id_pressupost]);


  const buscarDadesPressupost = async () =>{
    setLoadingPressupost(true);
    const c = await Api.get('/pressupost/'+perfilUsuari.curs_escolar_actual.id);
    if(c.result.success===1){
      let pressus = [];
      const select = c.data.pressuposts.map((item)=>{
        pressus[item.id] = item.descripcio;
        return(
          <Select.Option key={item.id} value={item.id}>
            <span>{entities.decode(item.descripcio)}</span>
          </Select.Option>
        );
      });
      setSelectPressupostos(select);
      setLoadingPressupost(false);
      setPressupostosDesc(pressus);
    }
  }

  const buscarDades = async () => {
    setIsLoading(true);
    const e = await Api.get(`/llistat/ingresos/${id_pressupost}`);
    if(e.result.success===1){
      setLlista(e.data.ingresos);
    }
    setIsLoading(false);
  }


  const triaPressupostChange = (value) => {
    setId_pressupost(value);
  }

/*
  const selectPressupostos = <>
    <Select.Option key={57} value={57}>
      <span>Gener </span>
    </Select.Option>
    <Select.Option key={60} value={60}>
      <span>Febrer </span>
    </Select.Option>
  </>
*/

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.InformeIngressos')}/>

      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      { !selectPressupostos ? null :
        <Select
            loading={loadingPressupost}
            style={{width: 300}}
            placeholder={props.t('generic.tria')}
            onChange={triaPressupostChange}
            defaultValue={""}
            value={id_pressupost}
          >
            {selectPressupostos}
        </Select>
        }
        <Table
          style={{marginTop: '0.5rem'}}
          rowKey={"id"}
          loading={isLoading}
          locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
          dataSource={llista}
          expandedRowRender={null}
          pagination={false}
          summary={(dadesMostrades) => {
            let sumaSubtotals = 0.0;
            dadesMostrades.forEach((item) => {
              sumaSubtotals += parseFloat(item.import);
            });
            return(
              <>
              <Table.Summary.Row>
                <Table.Summary.Cell><b>Total:</b></Table.Summary.Cell>
                <Table.Summary.Cell><b>{parseFloat(sumaSubtotals).toFixed(2)}€</b></Table.Summary.Cell>
              </Table.Summary.Row>
              <div style={{marginTop: '1rem'}}>
                <InformeIngressosExportaExcel dades={dadesMostrades} nom_fitxer={'InformeIngressos_'+pressupostosDesc[id_pressupost]} nom_fulla={pressupostosDesc[id_pressupost]} btn_txt="Export"/>
              </div>
              </>
            )
          }}
          >
          <Table.Column
          title={props.t('generic.concepte')}
          key="concepte"
          render={(text,record) => (
            <span>{entities.decode(record.concepte)}</span>
          )}
          />

          <Table.Column
          title={props.t('quotes.import')}
          key="import"
          render={(text,record) =>(
            <span>{parseFloat(record.import).toFixed(2)}€</span>
          )}
          />

          <Table.Column
          title={props.t('families.pagament_banc')}
          key="banc"
          render={(text,record) =>(
            <span>{parseFloat(record.banc).toFixed(2)}€</span>
          )}
          />
          <Table.Column
          title={props.t('families.pagament_efectiu')}
          key="efectiu"
          render={(text,record) =>(
            <span>{parseFloat(record.efectiu).toFixed(2)}€</span>
          )}
          />
          <Table.Column
          title={props.t('families.pagament_transferencia')}
          key="transferencia"
          render={(text,record) =>(
            <span>{parseFloat(record.transferencia).toFixed(2)}€</span>
          )}
          />
          <Table.Column
          title={props.t('families.pagament_xec_guarderia')}
          key="xec_guarderia"
          render={(text,record) =>(
            <span>{parseFloat(record.xec_guarderia).toFixed(2)}€</span>
          )}
          />
          <Table.Column
          title={props.t('families.pagament_targeta')}
          key="targeta"
          render={(text,record) =>(
            <span>{parseFloat(record.targeta).toFixed(2)}€</span>
          )}
          />

//'BANC', 'EFECTIU', 'TRANSFERENCIA', 'XEC_GUARDERIA', 'TARGETA'

        </Table>
      </div>
    </ContainerPagina>
  );

}

export default translate(InformeIngressos);
