import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';

import {Typography, Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Card, Table, Space, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';
import ImatgeDNI from '../../../ImatgeDNI';
import VisorImatges from "../../../visorImatges";
import TextAreaCount from '../../../TextAreaCount';
import BotoFamilia from '../../../PopUps/perfils/admin/BotoUnitatFamiliar';
import BotonsPares from '../../../PopUps/perfils/admin/BotonsPares';
import BotonsGermans from '../../../PopUps/perfils/admin/BotonsFills';


const entities = new AllHtmlEntities();


function Edit(props){

  const [id, setId] = useState(props.item.id);
  const [nom, setNom] = useState(entities.decode(props.item.nom));
  const [cognom1, setCognom1] = useState(entities.decode(props.item.cognom1));
  const [cognom2, setCognom2] = useState(entities.decode(props.item.cognom2));
  const [email, setEmail] = useState(props.item.email);
  const [naixement, setNaixement] = useState(props.item.naixement || null);
  const [naixement_ciutat, setNaixement_ciutat] = useState(entities.decode(props.item.naixement_ciutat));
  const [nacionalitat, setNacionalitat] = useState(entities.decode(props.item.nacionalitat));
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [id_classe, setId_classe] = useState(props.item.id_classe);
  const [sexe, setSexe] = useState(props.item.sexe);
  const [nss, setNss] = useState(props.item.nss);
  const [dni, setDni] = useState(props.item.dni);
  const [dni_image_1, setDni_image_1] = useState();
  const [dni_image_2, setDni_image_2] = useState();
  const [passaport, setPassaport] = useState(props.item.passaport);
  const [passaport_image_1, setPassaport_image_1] = useState(props.item.passaport_image_1);
  const [passaport_image_2, setPassaport_image_2] = useState(props.item.passaport_image_2);
  const [data_ingres, setData_ingres] = useState(props.item.data_ingres || null);
  const [lloc_entre_germans, setLloc_entre_germans] = useState(props.item.lloc_entre_germans);
  const [num_germans, setNum_germans] = useState(props.item.num_germans);
  const [llengua_materna, setLlengua_materna] = useState(entities.decode(props.item.llengua_materna));
  const [escoles_anteriors, setEscoles_anteriors] = useState(entities.decode(props.item.escoles_anteriors));
  const [observacions, setObservacions] = useState(entities.decode(props.item.observacions));
  const [estat, setEstat] = useState(props.item.estat);


/* Auxiliars */
  const [dni_image_1_dataUrl, setDni_image_1_dataUrl ] = useState(undefined);
  const [dni_image_2_dataUrl, setDni_image_2_dataUrl ] = useState(undefined);
  const [passaport_image_1_dataUrl, setPassaport_image_1_dataUrl ] = useState(undefined);
  const [passaport_image_2_dataUrl, setPassaport_image_2_dataUrl ] = useState(undefined);

  const [classesCursEscolar, setClassesCursEscolar] = useState([]);
  const [catSalut, setCatSalut] = useState();
  const [mutua, setMutua] = useState();


  const [visor, setVisor] = useState({visible: false});
  const [families, setFamilies] = useState([]);
  const [classes, setClasses] = useState([]);

  const [errorEstat, setErrorEstat] = useState('');
  const [errorEstatText, setErrorEstatText] = useState('');
  const [errorNom, setErrorNom] = useState('');
  const [errorCognom1, setErrorCognom1] = useState('');
  const [errorSexe, setErrorSexe] = useState('');
  const [errorSexeText, setErrorSexeText] = useState('');
  const [errorId_Familia, setErrorId_Familia] = useState('');
  const [errorId_FamiliaText, setErrorId_FamiliaText] = useState('');
  const [errorId_classe, setErrorId_classe] = useState('');
  const [errorId_classeText, setErrorId_classeText] = useState('');

  const [refrescar, setRefrescar] = useState(0);
  const [guardant, setGuardant] = useState(false);
  const [guardantCatSalut, setGuardantCatSalut] = useState(false);
  const [guardantMutua, setGuardantMutua] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nou, setNou] = useState(props.item.id===0);

  const contenidorRef = React.useRef();

  useEffect(() => {
    buscarDades();
  },[id, refrescar]);

  const buscarDadesAlumne = async () => {

    if(id > 0){
      const alumne = await Api.get('/alumnes/'+id);
      if(alumne.result.success===1){
        setNom(entities.decode(alumne.data.nom));
        setCognom1(entities.decode(alumne.data.cognom1));
        setCognom2(entities.decode(alumne.data.cognom2));
        setEmail(alumne.data.email);
        setNaixement(alumne.data.naixement);
        setNaixement_ciutat(entities.decode(alumne.data.naixement_ciutat));
        setNacionalitat(entities.decode(alumne.data.nacionalitat));
        setId_familia(alumne.data.id_familia);
        setId_classe(alumne.data.id_classe);
        setSexe(alumne.data.sexe);
        setNss(alumne.data.nss);
        setDni(alumne.data.dni);
        setPassaport(alumne.data.passaport);
        setData_ingres(alumne.data.data_ingres);
        setLloc_entre_germans(alumne.data.lloc_entre_germans);
        setNum_germans(alumne.data.num_germans);
        setLlengua_materna(entities.decode(alumne.data.llengua_materna));
        setEscoles_anteriors(entities.decode(alumne.data.escoles_anteriors));
        setObservacions(entities.decode(alumne.data.observacions));
        setEstat(alumne.data.estat);
      }

      const dni_alumne = await Api.get('/alumnes/dni/'+id);
      if(dni_alumne.result.success===1){
        setDni_image_1(dni_alumne.data.dni_image_1);
        setDni_image_2(dni_alumne.data.dni_image_2);
        setPassaport_image_1(dni_alumne.data.passaport_image_1);
        setPassaport_image_2(dni_alumne.data.passaport_image_2);
      }

      const cce = await Api.get(`/cursescolar/historic/${id}`);
      if(cce.result.success===1){
        setClassesCursEscolar(cce.data);
      }

      const serveiMedic = await Api.get(`/medic/alumne/${id}`);
      console.log("servei medic Alumne: ", serveiMedic)

      if(serveiMedic.result.success===1){

        const cs = serveiMedic.data.medic.find(s => s.categoria.id_categoria === 2);
        if(cs){
          setCatSalut(cs.text);
        }
        const mut = serveiMedic.data.medic.find(s => s.categoria.id_categoria === 8);
        if(mut){
          setMutua(mut.text);
        }
      }

    }
  }

  const buscarDades = async () => {
    setIsLoading(true);

    buscarDadesAlumne();

    const fam = await Api.get('/families');
    if(fam.result.success===1){
      const llista_fam = fam.data.families.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setFamilies(llista_fam);
    }

    const clas = await Api.get('/classes');
    if(clas.result.success===1){
      const llista_clas = clas.data.classes.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setClasses(llista_clas);
    }
    setIsLoading(false);
  }


  const guardarMedic = async (id_categoria) =>{
    let dades = {
      id_alumne: id,
      id_categoria,
      text: id_categoria === 2 ? catSalut : mutua,
    }

    if(id_categoria === 2){
      setGuardantCatSalut(true);
    }else{
      setGuardantMutua(true);
    }

    const resposta = await Api.myFetch("/medic/alumne", "POST", JSON.stringify(dades));

    if(resposta.result.success === 1){
      message.success(props.t('generic.OK_saved'), 5);
    }else{
      console.error('Error: submit Guardant Medic: ',resposta);
      message.config({top: 50});
      message.open({content: resposta.data.message, duration:10});
    }

    if(id_categoria === 2){
      setGuardantCatSalut(false);
    }else{
      setGuardantMutua(false);
    }

  }


  const submitForm = async (values) => {

    let correcte = true;
    if(!estat){
      setErrorEstat('error');
      setErrorEstatText(props.t('generic.tria'));
      correcte = false;
    }
    if(!nom){
      setErrorNom('error');
      correcte = false;
    }
    if(!cognom1){
      setErrorCognom1('error');
      correcte = false;
    }

    if(!sexe){
      setErrorSexe('error');
      setErrorSexeText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_familia===0){
      setErrorId_Familia('error');
      setErrorId_FamiliaText(props.t('generic.tria'));
      correcte = false;
    }
    if(estat==="actiu"){
      if(!id_classe){
        setErrorId_classe('error');
        setErrorId_classeText(props.t('generic.tria'));
        correcte = false;
      }
    }


    if(!correcte){
      contenidorRef.current.scrollIntoView({
        behavior: "smooth",
      });
      return false;
    }
      setGuardant(true);

      let dades = {
        id,
        nom: entities.encode(nom),
        cognom1: entities.encode(cognom1),
        cognom2: entities.encode(cognom2),
        email,
        naixement,
        naixement_ciutat: entities.encode(naixement_ciutat),
        nacionalitat: entities.encode(nacionalitat),
        id_familia,
        id_classe,
        sexe,
        nss,
        dni,
        passaport,
        data_ingres,
        lloc_entre_germans,
        num_germans,
        llengua_materna: entities.encode(llengua_materna),
        escoles_anteriors: entities.encode(escoles_anteriors),
        observacions: entities.encode(observacions),
        estat
      }


      let method = nou?'POST':'PUT';

      let url_api = nou? '/alumnes' : '/alumnes/'+id;

      //console.log('Alumnes data ans fetch: ',JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          if(nou){
            setNou(false);
            setId(resposta.data.id);
          }

          if(dni_image_1_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/alumnes/dni/'+resposta.data.id+'/1', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_1(resp.data.dni_image_1);
              }else{
                console.error('Error POST file: Alumnes dni_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resp.data.message, duration:5});
              }
          }
          if(dni_image_2_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/alumnes/dni/'+resposta.data.id+'/2', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_2(resp.data.dni_image_2);
              }else{
                console.error('Error POST file: Alumnes dni_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resp.data.message, duration:5});
              }
          }
          if(passaport_image_1_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/alumnes/dni/'+resposta.data.id+'/3', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_1(resp.data.passaport_image_1);
              }else{
                console.error('Error POST file: Alumnes passaport_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resp.data.message, duration:5});
              }
          }
          if(passaport_image_2_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/alumnes/dni/'+resposta.data.id+'/4', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_2(resp.data.passaport_image_2);
              }else{
                console.error('Error POST file: Alumnes passaport_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resp.data.message, duration:5});
              }
          }


          setGuardant(false);
          //console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          //props.callback();
          setRefrescar(refrescar+1);
        }else{
          setGuardant(false);
          console.error('Error: submit FormAlumnes: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Alumne guardar: ', error);
      }

  }


  const triaEstat = (value) =>{
    setEstat(value);

    if(value==="baixa"){
      setId_classe(0);
    }

    if(value.length>0){
      setErrorEstat('');
      setErrorEstatText('');
    }
  }
  const onChangeNom = (value) =>{
    setNom(value);
    if(value.length>0){
      setErrorNom('');
    }
  }
  const onChangeCognom1 = (value) =>{
    setCognom1(value);
    if(value.length>0){
      setErrorCognom1('');
    }
  }
  const triaSexe = (value) =>{
    setSexe(value);
    if(value.length>0){
      setErrorSexe('');
      setErrorSexeText('');
    }
  }
  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_Familia('');
      setErrorId_FamiliaText('');
    }
  }
  const triaClasse = (value) =>{
    setId_classe(value);
    if(value>0){
      setErrorId_classe('');
      setErrorId_classeText('');
    }
  }

  const changeData_ingres = (date, dateString) => {
    if(date){
      //Trec l'hora
      setData_ingres(date.format(moment.HTML5_FMT.DATE));
    }else{
      setData_ingres(null);
    }

  }
  const changeNaixement = (date, dateString) => {
    if(date){
      //Trec l'hora
      setNaixement(date.format(moment.HTML5_FMT.DATE));
    }else{
      setNaixement(null);
    }
  }



const columnsClassesCursEscolar = [
  {
    title: props.t('generic.curs_escolar'),
    key:"curs_escolar",
    dataIndex: 'curs_escolar',
    render: (text,record) =>(entities.decode(record.curs_escolar)),
    sorter: (a, b) => { return entities.decode(a.curs_escolar).localeCompare(entities.decode(b.curs_escolar))}
  },{
    title: props.t('menu.Cursos'),
    key: "curs",
    dataIndex: 'curs',
    render: (text,record) =>(entities.decode(record.curs)),
    sorter: (a, b) => { return entities.decode(a.curs).localeCompare(entities.decode(b.curs))}
  },{
    title:props.t('menu.Classes'),
    key:"classe",
    dataIndex: 'classe',
    render:(text,record) =>(entities.decode(record.classe)),
    sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))}
  }
];

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';
  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {
    return(
    <>
    {/*props.popup!==undefined ? null :
      <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
        <LeftOutlined className="BotoBack" onClick={props.callback}/>
      </div>
    */}
      <div style={{padding:'10px 24px 35px 0px'}} ref={contenidorRef}>
      {/*id_familia<=0 || props.popup!==undefined ? null :*/}
      {id_familia<=0 ? null :
        <>
          <BotoFamilia id_familia={id_familia}/>
          <BotonsPares id_familia={id_familia}/>
          <BotonsGermans id_familia={id_familia} label={props.t('alumnes.germans')} id_alumne_actual={id}/>
          <Divider />
        </>
      }

        <Form onFinish={submitForm}
          layout="vertical"
          autoComplete="off"
        >
        {nou == true ? null :
          <Row>
            <Typography.Title level={4}>{`ID ${props.t('generic.alumne')}: ${id}`}</Typography.Title>
          </Row>
        }
        <Form.Item label={props.t('alumnes.estat.titol')} required={true} validateStatus={errorEstat} help={errorEstatText} style={{marginBottom: 0}}>
          <Select defaultValue={estat} onChange={triaEstat} style={{ width: 100}}>
            <Select.Option value="actiu">{props.t('alumnes.estat.actiu')}</Select.Option>
            <Select.Option value="reserva">{props.t('alumnes.estat.reserva')}</Select.Option>
            <Select.Option value="baixa">{props.t('alumnes.estat.baixa')}</Select.Option>
          </Select>
        </Form.Item>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} lg={12} xl={8}>
          <Form.Item label={props.t('alumnes.nom')} validateStatus={errorNom} required={true}>
            <Input name="nom" placeholder={props.t('alumnes.nom')} onChange={(e) => onChangeNom(e.target.value)} defaultValue={nom}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.cognom1')} validateStatus={errorCognom1} required={true}>
            <Input name="cognom1" placeholder={props.t('alumnes.cognom1')} onChange={(e) => onChangeCognom1(e.target.value)} defaultValue={cognom1}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.cognom2')}>
            <Input name="cognom2" placeholder={props.t('alumnes.cognom2')} onChange={(e) => setCognom2(e.target.value)} defaultValue={cognom2}/>
          </Form.Item>
          <Form.Item label={props.t('pares.email')}>
            <Input name="email" placeholder={props.t('pares.email')} onChange={(e) => setEmail(e.target.value)} defaultValue={email}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.sexe')} required={true} validateStatus={errorSexe} help={errorSexeText} style={{marginBottom: 0}}>
            <Select defaultValue={sexe} onChange={triaSexe} style={{ width: 100}}>
              <Select.Option value="NENA">{props.t('alumnes.sexe_nena')}</Select.Option>
              <Select.Option value="NEN">{props.t('alumnes.sexe_nen')}</Select.Option>
              <Select.Option value="ALTRE">{props.t('alumnes.sexe_altres')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={props.t('alumnes.naixement')}>
            <DatePicker
              onChange={changeNaixement}
              value={naixement ? moment(new Date(naixement), dateFormat) : ''}
              format={dateFormat}
              locale={localeDatePicker}
              picker="date"
              />
          </Form.Item>
          <Form.Item label={props.t('alumnes.naixement_ciutat')}>
            <Input name="naixement_ciutat" placeholder={props.t('alumnes.naixement_ciutat')} onChange={(e) => setNaixement_ciutat(e.target.value)} defaultValue={naixement_ciutat}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.nacionalitat')}>
            <Input name="nacionalitat" placeholder={props.t('alumnes.nacionalitat')} onChange={(e) => setNacionalitat(e.target.value)} defaultValue={nacionalitat}/>
          </Form.Item>
          {/*<Form.Item label={props.t('alumnes.dni')}>
            <Input name="dni" placeholder={props.t('alumnes.dni')} onChange={(e) => setDni(e.target.value)} defaultValue={dni}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.nss')}>
            <Input name="nss" placeholder={props.t('alumnes.nss')} onChange={(e) => setNss(e.target.value)} defaultValue={nss}/>
          </Form.Item>*/}
          <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_Familia} help={errorId_FamiliaText}>
            <Select defaultValue={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
              showSearch
              filterOption={(input, option) =>
                HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
              }
            >
              {families}
            </Select>
          </Form.Item>
          <Form.Item label={props.t('alumnes.lloc_entre_germans')}>
            <InputNumber name="lloc_entre_germans" onChange={setLloc_entre_germans} defaultValue={lloc_entre_germans}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.num_germans')}>
            <InputNumber name="num_germans" onChange={setNum_germans} defaultValue={num_germans}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.llengua_materna')}>
            <Input name="llengua_materna" placeholder={props.t('alumnes.llengua_materna')} onChange={(e) => setLlengua_materna(e.target.value)} defaultValue={llengua_materna}/>
          </Form.Item>
          <Divider />
          <Form.Item label={props.t('alumnes.data_ingres')}>
            <DatePicker
              onChange={changeData_ingres}
              value={data_ingres ? moment(new Date(data_ingres), dateFormat) : (nou ? moment(new Date(), dateFormat) : '')}
              format={dateFormat}
              locale={localeDatePicker}
              picker="date"
            />
          </Form.Item>
          <Form.Item label={props.t('alumnes.classe')} required={true} validateStatus={errorId_classe} help={errorId_classeText} style={{marginBottom: 0}}>
            <Select  disabled={estat==="baixa"} value={id_classe === 0 ? '' : id_classe} onChange={(value) => triaClasse(value)}
              showSearch
              filterOption={(input, option) =>
                HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
              }
            >
              {estat!=='reserva' ? null :
              <Select.Option key={0} value={0}>----------------------</Select.Option>
              }
              {classes}
            </Select>
          </Form.Item>
          <Form.Item label={props.t('alumnes.escoles_anteriors')}>
            <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={1024} name="escoles_anteriors" placeholder={props.t('alumnes.escoles_anteriors')} onChange={setEscoles_anteriors} defaultValue={escoles_anteriors}/>
          </Form.Item>
          <Form.Item label={props.t('alumnes.observacions')}>
            <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={1024} name="observacions" placeholder={props.t('alumnes.observacions')} onChange={setObservacions} defaultValue={observacions}/>
          </Form.Item>
          <Divider />

        </Col>
        <Col xs={24} lg={12} xl={16}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          { id === 0 ? null :
            <Col span={24}>
              <Card
                headStyle={{backgroundColor: "#e0f2f1"}}
                style={{borderColor: "#e0f2f1"}}
                hoverable={true}
                title={props.t('menu.Classes')}
                >
                <Table
                  key='taula_historic_classes'
                  dataSource={classesCursEscolar}
                  columns={columnsClassesCursEscolar}
                  pagination={false}
                  size={'small'}
                />
              </Card>
              <br/>
            </Col>
          }

            <Col span={24}>
              <Form.Item
                label={props.t('pares.dni')}
                labelCol={{span:2}}
                wrapperCol={{span:10}}
              >
                <Input name="dni" placeholder={props.t('pares.dni')} onChange={(e) => setDni(e.target.value)} defaultValue={dni}/>
              </Form.Item>
            </Col>
            <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={dni_image_1}
                  title={props.t('pares.dni_image_1')}
                  dataUrl={dni_image_1_dataUrl}
                  setDataUrl={setDni_image_1_dataUrl}
                  setVisor={setVisor}
                />
            </Col>
            <Col  xs={24} xl={12}>
                <ImatgeDNI
                  image={dni_image_2}
                  title={props.t('pares.dni_image_2')}
                  dataUrl={dni_image_2_dataUrl}
                  setDataUrl={setDni_image_2_dataUrl}
                  setVisor={setVisor}
                />
            </Col>
              <Col span={24} style={{paddingTop: '3rem'}}>
                <Form.Item
                  label={props.t('pares.passaport')}
                  wrapperCol={{span:12}}
                >
                  <Input name="passaport" placeholder={props.t('pares.passaport')} onChange={(e) => setPassaport(e.target.value)} defaultValue={passaport}/>
                </Form.Item>
              </Col>
              <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={passaport_image_1}
                  title={props.t('pares.passaport_image_1')}
                  dataUrl={passaport_image_1_dataUrl}
                  setDataUrl={setPassaport_image_1_dataUrl}
                  setVisor={setVisor}
                />
              </Col>
              <Col xs={24} xl={12}>
                <ImatgeDNI
                  image={passaport_image_2}
                  title={props.t('pares.passaport_image_2')}
                  dataUrl={passaport_image_2_dataUrl}
                  setDataUrl={setPassaport_image_2_dataUrl}
                  setVisor={setVisor}
                />
              </Col>

              { id === 0 ? null :
                <Col span={12} style={{paddingTop: '3rem'}}>
                <Card
                  headStyle={{backgroundColor: "#fbe9e7"}}
                  style={{borderColor: "#fbe9e7"}}
                  hoverable={true} title={props.t('menu.ServeiMedic')}
                  >
                  <Form.Item label={props.t('medic.catSalut')}>
                    <Space>
                      <Input name="catSalut" placeholder={props.t('medic.catSalut')} onChange={(e) => setCatSalut(e.target.value)} value={catSalut}/>
                      <Button type="primary" onClick={() => guardarMedic(2)} loading={guardantCatSalut}>{props.t('generic.save')}</Button>
                    </Space>
                  </Form.Item>
                  <Form.Item label={props.t('medic.mutua')}>
                    <Space>
                      <Input name="mutua" placeholder={props.t('medic.mutua')} onChange={(e) => setMutua(e.target.value)} value={mutua}/>
                      <Button type="primary" onClick={() => guardarMedic(8)} loading={guardantMutua}>{props.t('generic.save')}</Button>
                    </Space>
                  </Form.Item>

                </Card>
              </Col>
            }
            </Row>
          </Col>

          <Col span={24}>
              <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
                <Col>
                  <Button onClick={props.callback}>{props.t('generic.back')}</Button>
                </Col>
                <Col style={{marginLeft:'10px'}}>
                  <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
                </Col>
              </Row>
          </Col>
        </Row>
        </Form>
        { !visor.visible
          ? null
          : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
        }
      </div>
    </>
    );
  }
}
export default translate(Edit);
