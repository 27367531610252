import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Table, Form, Select, Switch, Space, Row, Col, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id] = useState(props.item.id);
  const [nom, setNom] = useState(entities.decode(props.item.nom));
  const [email, setEmail] = useState(props.item.email);
  const [transport, setTransport] = useState(props.item.transport);
  const [menjador, setMenjador] = useState(props.item.menjador);
  const [acollida, setAcollida] = useState(props.item.acollida);
  const [botiga, setBotiga] = useState(props.item.botiga);
  const [extraescolars, setExtraescolars] = useState(props.item.extraescolars);
  const [notificar_admin, setNotificar_admin] = useState(props.item.notificar_admin);

  const modal = props.modal ? props.modal : false;

  const [guardant, setGuardant] = useState(false);

  const [errorNom, setErrorNom] = useState('');
  const [errorEmail, setErrorEmail] = useState('');


  const nou = (id===0);


  const nom_onChange = (value) =>{
    const n = value.trim();
    setNom(n);
    if(n.length > 0){
      setErrorNom('');
    }
  }

  const email_onChange = (value) =>{
    const em = value.trim();
    setEmail(em);
    if(em.length > 0){
      setErrorEmail('');
    }
  }



  const submitForm = async (values) => {

      let correcte = true;


      if(email.length === 0){
        setErrorEmail('error');
        correcte = false;
      }
      if(nom.length === 0){
        setErrorNom('error');
        correcte = false;
      }


      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        nom: entities.encode(nom),
        email,
        transport,
        menjador,
        acollida,
        botiga,
        extraescolars,
        notificar_admin
      }
      //console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/notificacions/emails' : '/notificacions/emails/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();

        }else{
          setGuardant(false);
          console.error('Error: submit Form BotigaTalles: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: BotigaTalles guardar: ', error);
      }

  }

  return (
    <div style={{padding:'10px 24px 0px 24px'}}>
      <Form onFinish={submitForm}
        layout="vertical"
        //labelCol={{span:2}}
        //wrapperCol={modal ? {span:16} : {span:7}}
        //colon={true}
        autoComplete="off"
      >

        <Form.Item label={props.t('pares.nom')} validateStatus={errorNom} required={true} >
          <Input onChange={(e) => nom_onChange(e.target.value)} defaultValue={nom}/>
        </Form.Item>

        <Form.Item label={props.t('pares.email')} validateStatus={errorEmail} required={true}>
          <Input onChange={(e) => email_onChange(e.target.value)} defaultValue={email}/>
        </Form.Item>

        <Row style={{marginTop: '1rem'}}>
          <Space>
            <span>{props.t('menu.Transport')}</span>
            <Switch defaultChecked={transport} onChange={setTransport} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <Space>
            <span>{props.t('menu.Menjador')}</span>
            <Switch defaultChecked={menjador} onChange={setMenjador} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <Space>
            <span>{props.t('menu.Acollida')}</span>
            <Switch defaultChecked={acollida} onChange={setAcollida} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <Space>
            <span>{props.t('menu.Botiga')}</span>
            <Switch defaultChecked={botiga} onChange={setBotiga} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <Space>
            <span>{props.t('menu.Extraescolars')}</span>
            <Switch defaultChecked={extraescolars} onChange={setExtraescolars} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
        <Row style={{marginTop: '1rem'}}>
          <Space>
            <span>{props.t('notificacions.notificar_admin')}</span>
            <Switch defaultChecked={notificar_admin} onChange={setNotificar_admin} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>



        <Row type="flex" justify="start" style={{marginTop: '2rem', marginBottom: '1rem'}}>
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default translate(Edit);
