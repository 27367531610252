import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Button, List, Card, Tabs, Modal, Popover, Space } from 'antd';
import { AlertFilled, BulbFilled } from '@ant-design/icons';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../../context/menu-triat";
import { Api } from '../../../../helpers';
import NewPassword from '../../../PantallesObligadesInici/NewPassword';

import styles from './DashboardClasse.module.css';

const entities = new AllHtmlEntities();

function DashboardClasse(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [obligar_modificar_password, setObligar_modificar_password] = useState(false);
  const [alumnes, setAlumnes] = useState([]);
  const [acollida, setAcollida] = useState([]);
  const [menjador, setMenjador] = useState([]);
  const [transport, setTransport] = useState([]);
  const [extraescolars, setExtraescolars] = useState([]);
  const [medic, setMedic] = useState([]);
  const [absencies, setAbsencies] = useState([]);
  const [notificacions, setNotificacions] = useState([]);
  const [mealTimeReport, setMealTimeReport] = useState([]);

  const buscarDades = async () => {
    const resp = await Api.get('/resposta/pendents/pantalla/'+perfilUsuari.account.id);
    if(resp.result.success===1){

      //console.log(resp.data.pantalles_inici)
      setObligar_modificar_password(resp.data.pantalles_inici.obligar_modificar_password);
    }
  }

  useEffect(() => {
    buscarDades();
  },[]);

  useEffect(() => {
    buscarAlumnes();
    //buscarAcollida();
    buscarMenjador();
    buscarTransport();
    buscarExtraescolar();
    buscarMedic();
    buscarAbsencies();
    buscarNotificacions();
    buscarMealTimeReport();
    //console.log('Refresco tot')
  },[props.t('idioma'), props.refrescar]);



  const newPasswordOK = () =>{
    setObligar_modificar_password(false);
  }

  const buscarAlumnes = async () => {
    if(props.id_classe) {
      const q = await Api.get(`/profe/alumnes/classe/${props.id_classe}`);
      if(q.result.success===1 && q.data.alumnes){
        setAlumnes(q.data.alumnes);
      }
    }
  }
/*  const buscarAcollida = async () => {
    if(props.id_classe) {
      const q = await Api.get(`/profe/acollida/${props.id_classe}`);
      if(q.result.success===1 && q.data.acollida){
        const acoll = q.data.acollida.map((item) =>{
          return   {nom: entities.decode(item.alumne_nom), content: entities.decode(item.acollida[props.t('idioma')]), bgcolor: item.tipus=='mensual' ? 'white' : '#ffebee'}
        })
        setAcollida(acoll);
      }
    }
  }*/
  const buscarMenjador = async () => {
    if(props.id_classe) {
      let menj = [];
      const q1 = await Api.get(`/profe/no/menjador/${props.id_classe}`);
      if(q1.result.success===1 && q1.data.nomenjador){
        q1.data.nomenjador.map((item) =>{
          menj.push({nom: entities.decode(item.alumne_nom), content: props.t('menjador.sense'), bgcolor: 'white'});
        })
      }
      const q2 = await Api.get(`/profe/menjador/puntual/${props.id_classe}`);
      if(q2.result.success===1 && q2.data.menjador){
        q2.data.menjador.map((item) =>{
          menj.push({nom: entities.decode(item.alumne_nom), content: entities.decode(item.menjador[props.t('idioma')]), bgcolor: '#ffebee'});
        })
      }
      setMenjador(menj);
    }
  }
  const buscarTransport = async () => {
    if(props.id_classe) {
      const q = await Api.get(`/profe/transport/${props.id_classe}`);
      if(q.result.success===1 && q.data.transport){
        const filtrat = q.data.transport.filter((element, index, arr) => element.sentit == 'tornada');
        const trans = filtrat.map((item) =>{
          let content = entities.decode(item.transport[props.t('idioma')]).replace('The British School Costa Daurada', 'School');
          return {nom: entities.decode(item.alumne_nom), content: content, bgcolor: item.tipus=='anual' ? 'white' : '#ffebee'}
        })
        setTransport(trans);
      }
    }
  }
  const buscarExtraescolar = async () => {

    //console.log('perfilUsuari.account.id', perfilUsuari.account.id)

    //if(perfilUsuari.account.id === 408){  //TEMPORAL EXTRAESCOLARS COMENCEN EL DIA 3 OCTUBRE
      if(props.id_classe) {
        const numDia = new Date().getDay();
        const q = await Api.get(`/profe/extraescolars/classe/${props.id_classe}/numdia/${numDia}`);
        if(q.result.success===1 && q.data.extraescolar){
          const ext = q.data.extraescolar.map((item) =>{
            return {nom: entities.decode(item.alumne_nom), content: entities.decode(item.nom[props.t('idioma')]), content2: entities.decode(item.horari[props.t('idioma')]), bgcolor: 'white'}
          })
          setExtraescolars(ext);
        }
      }
    //}



  }
  const buscarMedic = async () => {
    if(props.id_classe) {
      const q = await Api.get(`/profe/medic/${props.id_classe}`);
      if(q.result.success===1 && q.data.medic){
        const med = q.data.medic.map((item) =>{
          return {nom: entities.decode(item.alumne_nom), content: entities.decode(item.categoria[props.t('idioma')]), content2: entities.decode(item.text), bgcolor: 'white'}
        })
        setMedic(med);
      }
    }
  }
  const buscarAbsencies = async () => {
    if(props.id_classe) {
      const q = await Api.get(`/profe/absencies/${props.id_classe}`);
      if(q.result.success===1 && q.data.absencies){
        let abs = [];
        q.data.absencies.map((item) =>{
          if(item.tipus === 'classe'){
            const motiu = item.motiu[props.t('idioma')] ? item.motiu[props.t('idioma')] : '';
            abs.push({nom: entities.decode(item.alumne_nom), content: entities.decode(motiu), bgcolor: 'white'});
          }
        })
        setAbsencies(abs);
      }
    }
  }

  const buscarNotificacions = async () => {
    if(props.id_classe) {
      const q = await Api.get(`/notificacions/rebudes/avui/usuari/${perfilUsuari.account.id}`);
      if(q.result.success===1 && q.data.notificacions){
        let nots = [];
        q.data.notificacions.map((item) =>{
          if(item.id_classe === props.id_classe){
            const notificacions_tipus = item.notificacions_tipus[props.t('idioma')] ? item.notificacions_tipus[props.t('idioma')] : '';
            const motiu_txt = item.motiu_txt[props.t('idioma')] ? item.motiu_txt[props.t('idioma')] : '';
            nots.push({nom: entities.decode(item.alumne), content: entities.decode(notificacions_tipus), content2: entities.decode(motiu_txt), bgcolor: 'white'});
          }
        })
        setNotificacions(nots);
      }
    }
  }

  const buscarMealTimeReport = async () => {
    if(props.id_classe) {
      const q = await Api.get(`/mtr/dashboard/curs/${perfilUsuari.curs_escolar_actual.id}`);
      if(q.result.success===1){
        console.log(q.data.reports)
        let reports = [];
        q.data.reports.map((item) =>{
          if(item.id_classe === props.id_classe && item.report[0].semafor !== 'verd'){

            const limitTextMostrat = 50;
            const puntets = item.report[0].frase[props.t('idioma')].length > limitTextMostrat ? "..." : "";

            let content = <Space>
                            <BulbFilled style={{color: `${props.t(`menjador.report.semafor.${item.report[0].semafor}`)}`}}/>
                            <Popover style={{width: '800px'}} content={entities.decode(item.report[0].frase[props.t('idioma')])} title={props.t('botiga_propietats.text')+' '+props.t('idioma').toString().toUpperCase()}>
                              {entities.decode(item.report[0].frase[props.t('idioma')]).slice(0,limitTextMostrat)+puntets}
                            </Popover>
                          </Space>;

            reports.push({nom: entities.decode(item.alumne), content: content, content2: '', bgcolor: 'white'});
          }
        })
        setMealTimeReport(reports);
      }
    }
  }

  const data = [
    {
      title: props.t('menu.ServeiMedic'),
      titleBGColor: '#ff5252',
      fontColor: 'white',
      list: medic,
      tipus: 'medic',
    },
    {
      title: 'Absencies',
      titleBGColor: '#b0bec5',
      fontColor: 'white',
      list: absencies,
      tipus: 'absencies',
    },
   {
      title: props.t('menu.Notificacions'),
      titleBGColor: '#69f0ae',
      fontColor: 'white',
      list: notificacions,
      tipus: 'notificacions',
    },
  /*  {
      title: props.t('menu.Acollida'),
      titleBGColor: '#69f0ae',
      list: acollida,
      tipus: 'acollida',
    },*/
    {
      title: props.t('menu.Menjador'),
      titleBGColor: '#c5cae9',
      fontColor: 'white',
      list: menjador,
      tipus: 'menjador',
    },
    {
      title: props.t('menjador.report.titol'),
      titleBGColor: '#c5cae9',
      fontColor: 'white',
      list: mealTimeReport,
      tipus: 'mtr',
    },
    {
      title: props.t('menu.Transport'),
      titleBGColor: '#b2ebf2',
      fontColor: 'white',
      list: transport,
      tipus: 'transport',
    },
    {
      title: props.t('menu.Extraescolars'),
      titleBGColor: '#448aff',
      fontColor: 'white',
      list: extraescolars,
      tipus: 'extraescolars',
    },
  ];

  return(
    <div style={{padding: 10}}>

    <Modal
    key="NewPassword"
    visible={obligar_modificar_password}
    footer={null}
    width={550}
    style={{top:20}}
    bodyStyle={{ height: "350px"}}
    destroyOnClose={true}
    forceRender={true}
    maskClosable={false}
    closable={false}
    >
      <NewPassword callback={newPasswordOK}/>
    </Modal>

      <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 1,
        lg: 2,
        xl: 2,
        xxl: 3,
      }}
      dataSource={data}
      renderItem={item => (
        <List.Item style={{borderRadius: 10, borderColor: item.titleBGColor}}>
          <Card title={<span style={{color: item.fontColor, fontSize: '1.5rem'}}>{item.title}</span>} size="small" style={{backgroundColor: item.titleBGColor, borderColor: item.titleBGColor}} bodyStyle={{backgroundColor: item.titleBGColor, borderColor: item.titleBGColor , borderRadius: 10}} className={styles.CardNova}>
          {item.tipus === 'medic'
            ?
                <List
                  style={{borderRadius: 10, borderColor: item.titleBGColor}}
                  size="small"
                  itemLayout="vertical"
                  dataSource={item.list}
                  renderItem={element => (
                    <List.Item style={{backgroundColor: element.bgcolor}}>
                          <Row>
                            <b>{element.nom}</b>&nbsp;-&nbsp;{element.content}
                          </Row>
                          <Row>
                            <p>{element.content2}</p>
                          </Row>

                    </List.Item>
                  )}
              />
            :
              <List
                  size="small"
                  itemLayout="horizontal"
                  dataSource={item.list}
                  renderItem={element => (
                    <List.Item style={{backgroundColor: element.bgcolor}} onClick={item.tipus === 'notificacions' ? (e) => setMenuTriat('Profe_Notificacions'): null}>
                        <List.Item.Meta title={element.nom}/>
                        {!element.content2
                          ?
                            <div>{element.content}</div>
                          :
                          <span>
                            <p>{element.content}</p>
                            <p>{element.content2}</p>
                          </span>
                        }
                    </List.Item>
                  )}
                />
          }
          </Card>
        </List.Item>
      )}
    />

    </div>
  );
}
export default translate(DashboardClasse);
