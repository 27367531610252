import React from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/MotiusAbsencia';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function MotiusAbsencia(props) {

  const buscarDades = async () => {
    const tmp = await Api.get('/motius/absencia');
    if(tmp.result.success===1){
      return tmp.data.motius;
    }
  }
  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch(`/motius/absencia/${id}`, 'DELETE');
    if(fam.result.success===1){

    }
  }

  const modal = props.modal ? props.modal : false;

  return(
    <ContainerPagina xl={modal ? 24 : 16}>
      <TitolPagina txt={props.t('menu.MotiusAbsencia')} style={modal ? {marginTop:'0rem', marginLeft:'1rem'} : {marginTop:'1rem', marginLeft:'3rem'}}/>
      <div style={modal ? {marginTop:'0rem', marginLeft:'1rem'} : {marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          modal={modal}
          txt_creats={props.t('menjador.creados')}
          txt_nou={props.t('menjador.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          duplica={true}
          itemBuit={InitDades.motiusAbsencia()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          sorter={(a, b) =>  a.id - b.id}
          />
          <Column
          title={props.t('botiga_propietats.ordre')}
          key="ordre"
          render={(text,record) =>(
            <span>{record.ordre}</span>
          )}
          sorter={(a, b) =>  a.id - b.id}
          />
          <Column
          title={props.t('botiga_propietats.text')+' CA'}
          key="text_ca"
          render={(text,record) =>(
            entities.decode(record.nom['ca'])
          )}
          />
          <Column
          title={props.t('botiga_propietats.text')+' EN'}
          key="text_en"
          render={(text,record) =>(
            entities.decode(record.nom['en'])
          )}
          />
          <Column
          title={props.t('botiga_propietats.text')+' ES'}
          key="text_es"
          render={(text,record) =>(
            entities.decode(record.nom['es'])
          )}
          />
        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(MotiusAbsencia);
