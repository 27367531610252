import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function InformeResumRebutsAnualPerAlumneExportaExcel(props){

  const [dades, setDades] = useState(props.dades);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Informe';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'InformeResumRebutsAnualPerAlumne';

  useEffect(() => {
    setDades(props.dades)
  },[props.dades]);

  console.log("dins exporta: ", props.dades)

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label={'id_alumne'} value={"id_alumne"}/>
            <ExcelColumn label={'id_familia'} value={"id_familia"}/>
            <ExcelColumn label={props.t('generic.alumne')} value={"alumne_nom_t"}/>
            <ExcelColumn label={props.t('alumnes.nom')} value={(col) => entities.decode(col.nom)}/>
            <ExcelColumn label={props.t('alumnes.cognom1')} value={(col) => entities.decode(col.cognom1)}/>
            <ExcelColumn label={props.t('alumnes.cognom2')} value={(col) => entities.decode(col.cognom2)}/>
            <ExcelColumn label={'id_curs'} value={"id_curs"}/>
            <ExcelColumn label={props.t('classes.curs')} value={(col) => entities.decode(col.curs)}/>
            <ExcelColumn label={'id_classe'} value={"id_classe"}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('alumnes.estat.titol')} value={(col) => props.t(`alumnes.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('mesos.8')} value={(col) => parseFloat(col.agost)}/>
            <ExcelColumn label={props.t('mesos.9')} value={(col) => parseFloat(col.setembre)}/>
            <ExcelColumn label={props.t('mesos.10')} value={(col) => parseFloat(col.octubre)}/>
            <ExcelColumn label={props.t('mesos.11')} value={(col) => parseFloat(col.novembre)}/>
            <ExcelColumn label={props.t('mesos.12')} value={(col) => parseFloat(col.desembre)}/>
            <ExcelColumn label={props.t('mesos.1')} value={(col) => parseFloat(col.gener)}/>
            <ExcelColumn label={props.t('mesos.2')} value={(col) => parseFloat(col.febrer)}/>
            <ExcelColumn label={props.t('mesos.3')} value={(col) => parseFloat(col.mar)}/>
            <ExcelColumn label={props.t('mesos.4')} value={(col) => parseFloat(col.abril)}/>
            <ExcelColumn label={props.t('mesos.5')} value={(col) => parseFloat(col.maig)}/>
            <ExcelColumn label={props.t('mesos.6')} value={(col) => parseFloat(col.juny)}/>
            <ExcelColumn label={props.t('mesos.7')} value={(col) => parseFloat(col.juliol)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.import_total)}/>
            <ExcelColumn label={'Pendent'} value={(col) => {
              const pendent = parseFloat(col.import_pendent)-parseFloat(col.import_entregat);
              return(pendent.toFixed(2));
            }}/>
            <ExcelColumn label={'xec_guarderia'} value={"xec_guarderia"}/>
            <ExcelColumn label={'tipus_pagament'} value={"tipus_pagament"}/>
            <ExcelColumn label={'pagament_unic_totes_quotes_any'} value={"pagament_unic_totes_quotes_any"}/>
            <ExcelColumn label={'pare_treballador_centre'} value={"pare_treballador_centre"}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(InformeResumRebutsAnualPerAlumneExportaExcel);
