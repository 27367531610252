import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Form, Input, Button, Typography, Alert, message } from 'antd';

import { PerfilUsuariContext } from "../../context/perfil-usuari";
import { Api, Encriptar } from '../../helpers';
import AutoritzarContrasenya from '../AutoritzarContrasenya';
import Medic from '../../pages/perfils/pares/Medic';


function ServeiMedic(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [visible, setVisible] = useState(props.visible);
  const [guardant, setGuardant] = useState(false);


  const submitForm = async (valors) => {


    setGuardant(true);

    let dades = {
      pantalla: "recordar_omplir_medics",
      resposta: "LLEGIT"
    }

    try{
      const resposta = await Api.myFetch('/resposta/obligada/pantalla/'+perfilUsuari.account.id, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        console.log(dades);
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: guardant ProteccioDades: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ProteccioDades guardar: ', error);
    }

  }

const missatge_info = (
  <>
    <Typography.Paragraph>{props.t('medic.recordar_text_1')}</Typography.Paragraph>
    <ul>
    <li>{props.t('medic.recordar_llista_1')}</li>
    <li>{props.t('medic.recordar_llista_2')}</li>
    <li>{props.t('medic.recordar_llista_3')}</li>
    <li>{props.t('medic.recordar_llista_4')}</li>
    <li>{props.t('medic.recordar_llista_5')}</li>
    </ul>
    <Typography.Paragraph><span style={{color: 'red'}}>*</span>{props.t('medic.recordar_text_2')}</Typography.Paragraph>
  </>
);


  return(
  <>

  <Alert
    //message={props.t('generic.info')}
    description={missatge_info}
    type="info"
    showIcon
    closable={false}
    style={{margin: '25px'}}
    />

    <Medic modal={true} tancar={submitForm} categories={[]}/>

    <Row type="flex" justify="center" style={{marginTop: 25, marginBottom: 25}}>
      <Button type="primary" onClick={() => submitForm()} loading={guardant}>{props.t('generic.accept')}</Button>
    </Row>
  </>
  );
}


export default translate(ServeiMedic);
