import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm, Modal, Form, Select, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EditOutlined, DeleteOutlined, InboxOutlined, AlertFilled, ClearOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';


import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/NotificacionsEmails';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function NotificacionsEmails(props) {
  moment.locale('es');
  const [refrescar, setRefrescar] = useState(0);

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  const buscarDades = async () => {
    const tmp = await Api.get('/notificacions/emails');
    if(tmp.result.success===1){
      return tmp.data.notificacions_emails;
    }
  }
  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/notificacions/emails/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA


  const columns = [
  {
      title:"Id",
      key:"id",
      sorter: (a, b) =>  a.id - b.id,
      render: (text,record) => (<span>{record.id}</span>)
    },{
      title: props.t('pares.nom'),
      key:"nom",
      dataIndex: 'nom',
      render: (text,record) =>(entities.decode(record.alumne_complet)),
      sorter: (a, b) => { return entities.decode(a.alumne_complet).localeCompare(entities.decode(b.alumne_complet))},
      ...getColumnSearchProps('alumne_complet',props.t('pares.nom'))
    },{
      title: props.t('pares.email'),
      key:"email",
      dataIndex: 'email',
      render: (text,record) =>(entities.decode(record.email)),
      sorter: (a, b) => { return entities.decode(a.email).localeCompare(entities.decode(b.email))},
      ...getColumnSearchProps('email',props.t('pares.email'))
    },{
      title:props.t('menu.Transport'),
      key:"transport",
      dataIndex: 'transport',
      align: 'center',
      render: (text,record) => {
        if(record.transport){
          return <span style={{color: 'green'}}>{props.t('generic.si')}</span>
        }else{
          return <span style={{color: 'red'}}>{props.t('generic.no')}</span>
        }
      },
      sorter: (a, b) =>  a.transport - b.transport
    },{
      title:props.t('menu.Menjador'),
      key:"menjador",
      dataIndex: 'menjador',
      align: 'center',
      render: (text,record) => {
        if(record.menjador){
          return <span style={{color: 'green'}}>{props.t('generic.si')}</span>
        }else{
          return <span style={{color: 'red'}}>{props.t('generic.no')}</span>
        }
      },
      sorter: (a, b) =>  a.menjador - b.menjador
    },{
      title:props.t('menu.Acollida'),
      key:"acollida",
      dataIndex: 'acollida',
      align: 'center',
      render: (text,record) => {
        if(record.acollida){
          return <span style={{color: 'green'}}>{props.t('generic.si')}</span>
        }else{
          return <span style={{color: 'red'}}>{props.t('generic.no')}</span>
        }
      },
      sorter: (a, b) =>  a.acollida - b.acollida
    },{
      title:props.t('menu.Botiga'),
      key:"botiga",
      dataIndex: 'botiga',
      align: 'center',
      render: (text,record) => {
        if(record.botiga){
          return <span style={{color: 'green'}}>{props.t('generic.si')}</span>
        }else{
          return <span style={{color: 'red'}}>{props.t('generic.no')}</span>
        }
      },
      sorter: (a, b) =>  a.botiga - b.botiga
    },{
      title:props.t('menu.Extraescolars'),
      key:"extraescolars",
      dataIndex: 'extraescolars',
      align: 'center',
      render: (text,record) => {
        if(record.extraescolars){
          return <span style={{color: 'green'}}>{props.t('generic.si')}</span>
        }else{
          return <span style={{color: 'red'}}>{props.t('generic.no')}</span>
        }
      },
      sorter: (a, b) =>  a.extraescolars - b.extraescolars
    },{
      title:props.t('notificacions.notificar_admin'),
      key:"notificar_admin",
      dataIndex: 'notificar_admin',
      align: 'center',
      render: (text,record) => {
        if(record.notificar_admin){
          return <span style={{color: 'green'}}>{props.t('generic.si')}</span>
        }else{
          return <span style={{color: 'red'}}>{props.t('generic.no')}</span>
        }
      },
      sorter: (a, b) =>  a.notificar_admin - b.notificar_admin
    }];



  return(
    <ContainerPagina xl={16}>
      <TitolPagina txt={props.t('menu.NotificacionsEmails')} style={{marginTop:'1rem', marginLeft:'3rem'}}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditableModalNouEdit
          txt_creats={props.t('botiga_propietats.creados')}
          txt_nou={props.t('botiga_propietats.nuevo')}
          modal_width={600}
          buscarDades={buscarDades}
          refrescar={refrescar}
          edit={Edit}
          add={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.notificacions_emails()}
          columns={columns}
          icon_creats={InboxOutlined}
          />
      </div>
    </ContainerPagina>
  );

}

export default translate(NotificacionsEmails);
