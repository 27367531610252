import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ComandesExcursionsExportaExcel(props){

  const [dades, setDades] = useState(props.dades);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : props.t('menu.ComandesExcursions');
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : props.t('menu.ComandesExcursions');

  useEffect(() => {

    setDades(props.dades)
  },[props.dades]);


  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label={props.t('generic.concepte')} value={(col) => props.t(`rebuts.tipus.${col.tipus}`)}/>
            <ExcelColumn label={props.t('quotes.import')} value={(col) => parseFloat(col.import).toFixed(2)}/>
            <ExcelColumn label={props.t('families.pagament_banc')} value={(col) => parseFloat(col.banc).toFixed(2)}/>
            <ExcelColumn label={props.t('families.pagament_efectiu')} value={(col) => parseFloat(col.efectiu).toFixed(2)}/>
            <ExcelColumn label={props.t('families.pagament_transferencia')} value={(col) => parseFloat(col.transferencia).toFixed(2)}/>
            <ExcelColumn label={props.t('families.pagament_xec_guarderia')} value={(col) => parseFloat(col.xec_guarderia).toFixed(2)}/>
            <ExcelColumn label={props.t('families.pagament_targeta')} value={(col) => parseFloat(col.targeta).toFixed(2)}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(ComandesExcursionsExportaExcel);
