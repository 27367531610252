import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Table, Form, Row, Col, Select, Button, Input, InputNumber, message } from 'antd';
import { Api } from '../../../../helpers';

import { AllHtmlEntities } from 'html-entities';
const entities = new AllHtmlEntities();

function ClassesTraspas({t, old_id_curs_escolar, new_id_curs_escolar, callback_ok, callback_ko}){

  const [cursosNom, setCursosNom] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [newClasses, setNewClasses] = useState([]);

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    buscarDades();
  },[]);

  const buscarDades = async () => {
    setIsLoading(true);
    let _cursos = [];
    const cur = await Api.get('/cursos');
    if(cur.result.success===1){
      setCursos(cur.data.cursos);
      cur.data.cursos.map((item) =>{
        _cursos[item.id] = item.nom
      });
      setCursosNom(_cursos);
    }

    let clas = [{old_id_classe: 0, old_class_name: '', old_id_curs: 0, old_name_curs: '', new_id_classe: 0, new_class_name: _cursos[1], new_id_curs: 1, new_name_curs: _cursos[1]}]; //Inicialitzo amb la classe nova buida de Babies
    const cla = await Api.get(`/classes/all/${old_id_curs_escolar}`);
    if(cla.result.success===1){
      cla.data.classes.forEach((item, i) => {
        if(_cursos[item.id_curs+1]){
          let c = {};
          c.old_id_classe = item.id;
          c.old_class_name = item.nom;
          c.old_id_curs = item.id_curs;
          c.old_name_curs = _cursos[item.id_curs];

          c.new_id_classe = 0;
          //c.new_class_name = "";
          c.new_class_name = _cursos[item.id_curs+1];
          c.new_id_curs = item.id_curs+1;
          c.new_name_curs = _cursos[item.id_curs+1];
          c.error = "";
          clas.push(c);
        }
      });
      setNewClasses(clas);
    }
    setIsLoading(false);
  }



  const crearClasse = async (nom, id_curs, id_curs_escolar, ordre) => {

    let id_classe = 0;

    let dades = {
      nom,
      id_curs,
      id_curs_escolar,
      h_ini: 7,
      m_ini: 0,
      h_fin: 16,
      m_fin: 0,
      ordre
    }

    let method = 'POST';
    let url_api = '/classes';

    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
      if(resposta.result.success === 1){
        id_classe = resposta.data.id;
      }else{
        console.error('Error: submit FormClasses: ',resposta);
      }
    }catch(error){
      console.error('Error: Classes guardar: ', error);
    }
    return id_classe;
  }



  const submitForm = async (valors) => {

    let correcte = true;

    const clas = newClasses.map(item => {
      if(item.new_class_name == "") {
        correcte = false;
      }
      return item.new_class_name == "" ? {...item, error: "error"} : item}
    );

    if(!correcte){
      setNewClasses(clas);
      return false;
    }

    setGuardant(true);


    const classes = await Promise.all(newClasses.map( async (item, index) => {
      let classe = {};
      classe.old_id_classe = item.old_id_classe;
      classe.old_id_curs = item.old_id_curs;
      classe.new_id_curs = item.new_id_curs;
      classe.new_class_name = item.new_class_name;
      classe.new_id_classe = await crearClasse(item.new_class_name, item.new_id_curs, new_id_curs_escolar, index);
      if(classe.new_id_classe == 0){correcte = false;}
      //console.log(classe)
      return classe;
    }));

    //console.log("despres de crear les classes: ", classes)

    if(!correcte){
      console.log("Error: creant classes noves")
      setGuardant(false);
      return false;
    }




    let dades = {
      old_id_curs_escolar,
      new_id_curs_escolar,
      classes
    }
    //console.log("ANS enviar a traspassar", JSON.stringify(dades));

    let method = 'POST';
    let url_api = '/cursescolar/traspassar/alumnes';

    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

      if(resposta.result.success === 1){
        setGuardant(false);
        console.log('resposta', resposta);
        message.success(t('generic.OK_saved'), 5);
        callback_ok();
      }else{
        setGuardant(false);
        console.error('Error: submit FormClasses: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
        callback_ko();
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Classes guardar: ', error);
      callback_ko();
    }
  }


  const modificaNewClassName = (id_classe, nom_classe) =>{
    const clas = newClasses.map(item => item.old_id_classe == id_classe ? {...item, new_class_name: nom_classe, error: ""} : item);
    setNewClasses(clas);
    console.log(clas)

  }


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {
    return(
      <div style={{padding:'10px 24px 35px 0px'}}>
        <Form onFinish={submitForm} style={{marginTop:'35px'}}
        layout="horizontal"
        labelCol={{span:0}}
        wrapperCol={{span:24}}
        colon={true}
        autoComplete="off"
        >
          {/*<Row>
            <Col span={12}>
              <Row justify="end"><span style={{marginRight: 10}}>OLD</span></Row>
            </Col>
            <Col span={12}>
              <Row justify="start"><span style={{marginLeft: 10}}>NEW</span></Row>
            </Col>
          </Row>*/}
          {/*
            newClasses.map((item)=>{
              return(
                <Row key={item.old_id_classe} align="middle" style={{marginTop: 10}}>
                  <Col span={12}>
                    <Row justify="end" align="middle" style={{marginRight: 10}}><b>Curs:&nbsp;</b>{item.old_name_curs}<b>&nbsp;Classe:&nbsp;</b>{item.old_class_name}</Row>
                  </Col>
                  <Col span={12}>
                    <Row justify="start" align="middle" style={{marginLeft: 10}}><b>Curs:&nbsp;</b>{item.new_name_curs}<b>&nbsp;Classe:&nbsp;</b>
                      <Form.Item validateStatus={item.error} style={{margin:"0 0 0 0"}}>
                        <Input style={{width: 200}} name={`class_${item.old_id_classe}`} placeholder={t('alumnes.classe')} onChange={(e) => modificaNewClassName(item.old_id_classe, e.target.value)} defaultValue={item.new_class_name}/>
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              );
            })

          */}
          <Table dataSource={newClasses} rowKey={"old_id_classe"} pagination={false} size="small" bordered >
          <Table.ColumnGroup title="OLD">
            <Table.Column title={t('generic.curs')} key="old_name_curs" dataIndex="old_name_curs"/>
            <Table.Column title={t('generic.classe')} key="old_class_name" dataIndex="old_class_name"/>
          </Table.ColumnGroup>
            <Table.Column
              align="center"
              title=""
              key="old_id_classe"
              render ={(text,item) => (
                item.old_id_classe > 0 &&(<ArrowRightOutlined style={{fontSize: 22}}/>)
              )}
            />
            <Table.ColumnGroup title="NEW">
            <Table.Column title={t('generic.curs')} key="new_name_curs" dataIndex="new_name_curs"/>
            <Table.Column
              title={t('generic.classe')}
              key="new_class_name"
              dataIndex="new_class_name"
              render={(text,item) => (
                <Form.Item validateStatus={item.error} style={{margin:"0 0 0 0"}}>
                  <Input name={`class_${item.old_id_classe}`} placeholder={t('alumnes.classe')} onChange={(e) => modificaNewClassName(item.old_id_classe, e.target.value)} defaultValue={item.new_class_name}/>
                </Form.Item>
              )}
              />
            </Table.ColumnGroup>
          </Table>
          <Form.Item wrapperCol={{span: 24 }} name="botonets" style={{marginTop: 20}}>
            <Row type="flex" justify="center">
              <Col>
                <Button onClick={callback_ko}>{t('generic.cancel')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{t('generic.save')}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export default translate(ClassesTraspas);
