import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, Form, Select, DatePicker, message } from 'antd';
import { MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function NotificacioEnviar(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [ids_alumnes, setIds_alumnes] = useState([]);
  const [id_notificacions_tipus, setId_notificacions_tipus] = useState(0);
  const [motiu, setMotiu] = useState(0);
  const [missatge_txt, setMissatge_txt] = useState('');

  const [diesTriats, setDiesTriats] = useState(() =>{
    let dies = [];
    dies.push(new moment())
    return dies;
  });

  const [notificacions_tipusOptions, setNotficacions_tipusOptions] = useState([]);
  const [motiusAbsenciaOptions, setMotiusAbsenciaOptions] = useState([]);
  const [fillsOptions, setFillsOptions] = useState([]);
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);

  const [errorMissatge_txt, setErrorMissatge_txt] = useState('');
  const [errorId_notificacions_tipus, setErrorId_notificacions_tipus] = useState('');
  const [errorId_notificacions_tipusText, setErrorId_notificacions_tipusText] = useState('');
  const [errorMotiu, setErrorMotiu] = useState('');
  const [errorMotiuText, setErrorMotiuText] = useState('');
  const [errorId_alumne, setErrorId_alumne] = useState('');
  const [errorId_alumneText, setErrorId_alumneText] = useState('');


  useEffect(() => {
    buscarDadesTipus();
  },[props.t('idioma')]);

  useEffect(() => {
    buscarDadesFills();
  },[]);


  const buscarDadesTipus = async () => {
    const n = await Api.get('/notificacions/enviar/tipus/Pare');
    if(n.result.success===1){
      const llista_n = n.data.notificacions_tipus.map((item) =>{
        return(
            <Select.Option value={item.id}>{entities.decode(item.nom[props.t('idioma')])}</Select.Option>
          );
      });
      setNotficacions_tipusOptions(llista_n);
    }
    const m = await Api.get('/motius/absencia');
    if(m.result.success===1){
      const llista_m = m.data.motius.map((item) =>{
        return(
            <Select.Option value={item.id}>{entities.decode(item.nom[props.t('idioma')])}</Select.Option>
          );
      });
      setMotiusAbsenciaOptions(llista_m);
    }

  }
  const buscarDadesFills = async () => {
    const f = await Api.get(`/alumnes/familia/${perfilUsuari.account.id_familia}`);
    if(f.result.success===1){
      const llista_f = f.data.alumnes.map((item) =>{
        return(
            <Select.Option value={item.id}>{entities.decode(item.nom)}</Select.Option>
          );
      });
      setFillsOptions(llista_f);
    }
  }




  const submitForm = async (valors) => {

    let correcte = true;

    if(!missatge_txt){
      setErrorMissatge_txt('error');
      correcte = false;
    }
    if(ids_alumnes.length === 0){
      setErrorId_alumne('error');
      setErrorId_alumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_notificacions_tipus === 0){
      setErrorId_notificacions_tipus('error');
      setErrorId_notificacions_tipusText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_notificacions_tipus === 1 && motiu === 0){
      setErrorMotiu('error');
      setErrorMotiuText(props.t('generic.tria'));
      correcte = false;
    }
/*
    if(diesTriats.length <= 0){
      message.config({top: 50});
      message.error({content: 'Error: '+ props.t('generic.tria_dies'), duration:3});
      correcte = false;
    }
*/
    if(!correcte){
      return false;
    }

    setGuardant(true);

    let dades = {
      id_usuari: perfilUsuari.account.id,
      ids_alumnes,
      id_notificacions_tipus,
      motiu,
      missatge_txt: entities.encode(missatge_txt)
    }
/*
    ,
      puntual_dates: diesTriats.map((item) =>item.format(moment.HTML5_FMT.DATE))
    }
*/

    console.log(dades);

    try{
      const resposta = await Api.myFetch('/notificacions/enviar', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: enviant notificacio: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio: ', error);
    }

  }

  const id_alumneChange = (value) =>{
    setIds_alumnes(value);
    if(value.length > 0){
      setErrorId_alumne('');
      setErrorId_alumneText('');
    }
  }
  const id_notificacions_tipusChange = (value) =>{
    setId_notificacions_tipus(value);
    if(value > 0){
      setErrorId_notificacions_tipus('');
      setErrorId_notificacions_tipusText('');
    }
  }
  const motiuChange = (value) =>{
    setMotiu(value);
    if(value > 0){
      setErrorMotiu('');
      setErrorMotiuText('');
    }
  }

  const onDiaTriat = (date, dateString) =>{
    const trobat = diesTriats.filter((element, index, arr) => element.format(moment.HTML5_FMT.DATE) === date.format(moment.HTML5_FMT.DATE));
    if(trobat.length === 0){
      const dies = [...diesTriats, date];
      setDiesTriats(dies);
    }
    //console.log(dateString)
  }

  const onEliminaDia = (item) => {
    const filtrat = diesTriats.filter((element, index, arr) => element !== item);
    setDiesTriats(filtrat);
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';


  return(
    <>
      <Form onFinish={submitForm} layout="vertical" autoComplete="off">

        <Form.Item
          validateStatus={errorId_alumne}
          help={errorId_alumneText}
          label={props.t('notificacions.alumne')}
          required={true}
          style={{marginBottom: 0}}
        >
          <Select
            mode="multiple"
            placeholder={props.t('generic.tria_varis')}
            allowClear
            onChange={id_alumneChange}>
            {fillsOptions}
          </Select>
        </Form.Item>
        <Form.Item
          validateStatus={errorId_notificacions_tipus}
          help={errorId_notificacions_tipusText}
          label={props.t('notificacions.tipus')}
          required={true}
          style={{marginBottom: 0}}
        >
          <Select
            defaultValue={id_notificacions_tipus === 0 ? "" : id_notificacions_tipus}
            onChange={id_notificacions_tipusChange}>
            {notificacions_tipusOptions}
          </Select>
        </Form.Item>
{id_notificacions_tipus !== 1 ? null :
        <Form.Item
          validateStatus={errorMotiu}
          help={errorMotiuText}
          label={props.t('menu.MotiusAbsencia')}
          required={true}
          style={{marginBottom: 0}}
        >
          <Select
            defaultValue={motiu === 0 ? "" : motiu}
            onChange={motiuChange}>
            {motiusAbsenciaOptions}
          </Select>
        </Form.Item>
}

        <Row justify="center" style={{marginTop:'10px'}}>
          <DatePicker
            format={dateFormat}
            locale={localeDatePicker}
            placeholder={'Tria dia o dies'}
            value={null}
            onChange={onDiaTriat}/>
        </Row>
        <Row justify="center" style={{marginTop:'10px'}}>
        <Col span={8}>
        {diesTriats.map((item) =>{
          return(
            <Row justify="space-between" key={item.format(dateFormat)}>
              <Col>{item.format(dateFormat)}</Col>
              <Col><DeleteOutlined onClick={()=>onEliminaDia(item)}/></Col>
            </Row>
          );
        })}
        </Col>
        </Row>
        <Form.Item label={props.t('notificacions.missatge')} validateStatus={errorMissatge_txt} required={true}>
          <TextAreaCount autoSize={{ minRows: 3, maxRows: 25 }} maxLength={500} name="missatge_txt" defaultValue={missatge_txt} placeholder={props.t('notificacions.missatge')} onChange={setMissatge_txt}/>
        </Form.Item>

        <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
          <Col>
            <Button onClick={()=>props.tancarModal()}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default translate(NotificacioEnviar);
