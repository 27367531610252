import React, {useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/TipusQuotesPreus';
import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import TriaCursEscolar from '../../../components/TriaCursEscolar';

const { Column } = Table;
const entities = new AllHtmlEntities();

function TipusQuotesPreus(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);
  const [refrescar, setRefrescar] = useState(0);

  const buscarDades = async () => {
    const q = await Api.get(`/quotes/tipus/${cursEscolarTriat}`);
    if(q.result.success===1){
      console.log(q.data.quotes_tipus)
      return q.data.quotes_tipus;
    }
  }
  const marcarEliminat = async (id) => {
    const q = await Api.myFetch('/quotes/tipus/'+id, 'DELETE');
    if(q.result.success===1){

    }
  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.TipusQuotesPreus')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          id_curs_escolar={cursEscolarTriat}
          btn_extra={<TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>}
          icon_creats={ProfileOutlined}
          txt_creats={props.t('quotes.creados')}
          txt_nou={props.t('quotes.nuevo')}
          buscarDades={buscarDades}
          refrescar={refrescar}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.quotes_tipus()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />
          <Column
          title={props.t('quotes.ordre')}
          key="ordre"
          render={(text,record) =>(
            record.ordre
          )}
          />
          <Column
          title={props.t('quotes.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom[props.t('idioma')])
          )}
          />

          {/*<Column
          title={props.t('quotes.pagament_titol')}
          key="pagament"
          render={(text,record) =>(
            props.t(`quotes.tipus_pagament.${record.pagament}`)
          )}
          />*/}
          <Column
          title={props.t('generic.mesos_facturacio')}
          key="mesos_facturacio"
          render={(text,record) =>{
            const llista_mesos = record.mesos_facturacio.map(item =>(
              props.t(`mesos.${item}`)
            ));

            return llista_mesos.join(", ");
          }}
          />

          <Column
          title={props.t('quotes.proporcional')}
          key="proporcional"
          render={(text,record) => (
            record.proporcional ===0 ? props.t('generic.no') : props.t('generic.si')
          )}
          responsive={['md']}
          />

        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(TipusQuotesPreus);
