import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Button, Input, InputNumber, Select, Switch, message } from 'antd';
import { BulbFilled } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id] = useState(props.item.id);
  const [tipus, setTipus] = useState(props.item.tipus);
  const [semafor, setSemafor] = useState(props.item.semafor);
  const [text_ca, setText_ca] = useState(entities.decode(props.item.text['ca']));
  const [text_en, setText_en] = useState(entities.decode(props.item.text['en']));
  const [text_es, setText_es] = useState(entities.decode(props.item.text['es']));
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [visible, setVisible] = useState(props.item.visible);

  const [guardant, setGuardant] = useState(false);

  const [errorOrdre, setErrorOrdre] = useState('');
  const [errorText_ca, setErrorText_ca] = useState('');
  const [errorText_en, setErrorText_en] = useState('');
  const [errorText_es, setErrorText_es] = useState('');
  const [errorTipus, setErrorTipus] = useState('');
  const [errorTipusText, setErrorTipusText] = useState('');
  const [errorSemafor, setErrorSemafor] = useState('');
  const [errorSemaforText, setErrorSemaforText] = useState('');


  const nou = (id===0);

  const ordreChange = (value) =>{
    setOrdre(value);
    if(Number.isInteger(value)){
      setErrorOrdre('');
    }
  }
  const text_caChange = (n) =>{
    //const n = e.target.value.trim();
    setText_ca(n);
    if(n.length>0){
      setErrorText_ca('');
    }
  }
  const text_enChange = (n) =>{
    //const n = e.target.value.trim();
    setText_en(n);
    if(n.length>0){
      setErrorText_en('');
    }
  }
  const text_esChange = (n) =>{
    //const n = e.target.value.trim();
    setText_es(n);
    if(n.length>0){
      setErrorText_es('');
    }
  }
  const triaTipus = (value) =>{
    setTipus(value);
    if(value.length>0){
      setErrorTipus('');
      setErrorTipusText('');
    }
  }

  const triaSemafor = (value) =>{
    setSemafor(value);
    if(value.length>0){
      setErrorSemafor('');
      setErrorSemaforText('');
    }
  }



  const submitForm = async (values) => {

      let correcte = true;

      if(!Number.isInteger(ordre)){
        setErrorOrdre('error');
        correcte = false;
      }
      if(text_ca.length === 0){
        setErrorText_ca('error');
        correcte = false;
      }
      if(text_en.length === 0){
        setErrorText_en('error');
        correcte = false;
      }
      if(text_es.length === 0){
        setErrorText_es('error');
        correcte = false;
      }
      if(!tipus){
        setErrorTipus('error');
        setErrorTipusText(props.t('generic.tria'));
        correcte = false;
      }
      if(!semafor){
        setErrorSemafor('error');
        setErrorSemaforText(props.t('generic.tria'));
        correcte = false;
      }


      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        tipus,
        semafor,
        text_ca: entities.encode(text_ca),
        text_en: entities.encode(text_en),
        text_es: entities.encode(text_es),
        ordre,
        visible
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/menjador/report/frases' : '/menjador/report/frases/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          //console.log(resposta);
          setGuardant(false);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();

        }else{
          setGuardant(false);
          console.error('Error: submit MealTimeReportFrases: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: MealTimeReportFrases guardar: ', error);
      }

  }


  return (
    <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="horizontal"
      labelCol={{span:2}}
      wrapperCol={{span:22}}
      //colon={true}
      autoComplete="off"
    >

    <Form.Item
      label={props.t('generic.visible')}
    >
        <Switch defaultChecked={visible} onChange={setVisible} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
    </Form.Item>

      <Form.Item
        validateStatus={errorOrdre}
        label={props.t('transport.ordre')}
        required={true}
      >
          <InputNumber name="ordre" onChange={ordreChange} defaultValue={ordre} min={0} step={1} />
      </Form.Item>

      <Form.Item label={props.t('menjador.tipus')} required={true} validateStatus={errorTipus} help={errorTipusText} style={{marginBottom: 0}}>
        <Select defaultValue={tipus} onChange={triaTipus} style={{ width: 200}}>
          <Select.Option value="comportament">{props.t('menjador.report.tipus.comportament')}</Select.Option>
          <Select.Option value="menjar">{props.t('menjador.report.tipus.menjar')}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item label={props.t('menjador.report.semafor_titol')} required={true} validateStatus={errorSemafor} help={errorSemaforText} style={{marginBottom: 0}}>
        <Select defaultValue={semafor} onChange={triaSemafor} style={{ width: 55}}>
          <Select.Option value="verd"><BulbFilled style={{color: `${props.t('menjador.report.semafor.verd')}`}} twoToneColor={props.t('menjador.report.semafor.verd')}/></Select.Option>
          <Select.Option value="groc"><BulbFilled style={{color: `${props.t('menjador.report.semafor.groc')}`}} twoToneColor={props.t('menjador.report.semafor.groc')}/></Select.Option>
          <Select.Option value="vermell"><BulbFilled style={{color: `${props.t('menjador.report.semafor.vermell')}`}} twoToneColor={props.t('menjador.report.semafor.vermell')}/></Select.Option>
        </Select>
      </Form.Item>


      <Form.Item
        validateStatus={errorText_ca}
        label={props.t('botiga_propietats.text')+' CA'}
        required={true}
      >
        {/*<Input name="text_ca" placeholder={props.t('botiga_propietats.text')} onChange={text_caChange} defaultValue={text_ca}/>*/}
        <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={500} name="text_ca" placeholder={props.t('botiga_propietats.text')} onChange={text_caChange} defaultValue={text_ca}/>
      </Form.Item>
      <Form.Item
        validateStatus={errorText_en}
        label={props.t('botiga_propietats.text')+' EN'}
        required={true}
      >
        {/*<Input name="text_en" placeholder={props.t('botiga_propietats.text')} onChange={text_enChange} defaultValue={text_en}/>*/}
        <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={500} name="text_en" placeholder={props.t('botiga_propietats.text')} onChange={text_enChange} defaultValue={text_en}/>
      </Form.Item>
      <Form.Item
        validateStatus={errorText_es}
        label={props.t('botiga_propietats.text')+' ES'}
        required={true}
      >
        {/*<Input name="text_es" placeholder={props.t('botiga_propietats.text')} onChange={text_esChange} defaultValue={text_es}/>*/}
        <TextAreaCount autoSize={{ minRows: 4, maxRows: 25 }} maxLength={500} name="text_es" placeholder={props.t('botiga_propietats.text')} onChange={text_esChange} defaultValue={text_es}/>
      </Form.Item>





      <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form.Item>
      </Form>
    </div>
  );
}
export default translate(Edit);
