import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import { Tabs, Button } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import Producte from './Producte';
import ContainerPagina from '../../components/ContainerPagina';
import TitolPagina from '../../components/TitolPagina';
import TriaCursEscolar from '../../components/TriaCursEscolar';
import { Api } from '../../helpers';
import { PerfilUsuariContext } from "../../context/perfil-usuari";

const entities = new AllHtmlEntities();

function Botiga(props){
  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [categoria, setCategoria] = useState('1');
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);

  const buscarDades = async () => {
    setIsLoading(true);

    const c = await Api.get('/botiga/categories/admin');
    if(c.result.success===1){
      const llista_c = c.data.categories.map((item) =>{
            return(
              <Tabs.TabPane tab={entities.decode(item.text[props.t('idioma')])} key={item.id_categoria}>
                <Producte id_curs_escolar={cursEscolarTriat} id_categoria={item.id_categoria} titol={item.text} idioma={props.t('idioma')}/>
              </Tabs.TabPane>
            );
      });
      setCategories(llista_c);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[props.t('idioma'), cursEscolarTriat]);
  //},[getLanguage()]);


//const operations = <Button onClick={() => buscarDades()} type="dashed" shape="circle" icon={<SyncOutlined />} />;
//<Tabs defaultActiveKey={categoria} onChange={setCategoria} tabBarExtraContent={operations}>

  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menu.Botiga')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :
        <Tabs
          defaultActiveKey={categoria}
          onChange={setCategoria}
          tabBarExtraContent={<TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => setCursEscolarTriat(c)}/>}
        >
          {categories}
        </Tabs>
      }
      </div>
    </ContainerPagina>
  );
}

export default translate(Botiga);
