import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Form, Input, Button, Modal, Typography, message } from 'antd';

import { PerfilUsuariContext } from "../../context/perfil-usuari";
import { Api, Encriptar } from '../../helpers';
import AutoritzarContrasenya from '../AutoritzarContrasenya';



function PermetrePublicitat(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [validat, setValidat] = useState(false);

  const [visible, setVisible] = useState(props.visible);
  const [guardant, setGuardant] = useState(false);


  const enviarResposta = async (resposta) => {
    if(!validat){
      return false;
    }
    if(!resposta){
      return false;
    }

    setGuardant(true);

    let dades = {
      pantalla: "obligar_respondre_permetre_publicitat",
      resposta,
    }
    console.log(JSON.stringify(dades))

    try{
      const resposta = await Api.myFetch('/resposta/obligada/pantalla/'+perfilUsuari.account.id, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: guardant PermetrePublicitat: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: PermetrePublicitat guardar: ', error);
    }

  }

  return(
  <>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={3}>{props.t('permetre_publicitat.titol')}</Typography.Title>
    </div>
    <Typography.Paragraph>{props.t('permetre_publicitat.text1')}</Typography.Paragraph>
    <br/>

    <AutoritzarContrasenya resposta={setValidat}/>

    <Row type="flex" justify="space-around" style={{marginTop: 25, marginBottom: 25}}>
      <Button disabled={!validat} type="primary" onClick={() => enviarResposta('SI')} loading={guardant} className="btn-success">{props.t('generic.btn_accepto')}</Button>
      <Button disabled={!validat} type="primary" onClick={() => enviarResposta('NO')} loading={guardant} danger>{props.t('generic.btn_no_accepto')}</Button>
    </Row>
  </>
  );
}


export default translate(PermetrePublicitat);
