import React, {useState, useEffect} from 'react';
import { translate, getLanguage } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';
import { PDFViewer, Page, Text, View, Document, StyleSheet, Canvas, Font, Image } from '@react-pdf/renderer';

import { Api } from '../../helpers';
//import AlumnesTelefonsPDF from '../../components/LlistatsPDF/AlumnesTelefons'
const entities = new AllHtmlEntities();

function AlumnesTelefons(props){

const [llista, setLlista] = useState(null);
const [isLoading, setIsLoading] = useState(true);

const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    marginTop: 0,
    paddingBottom: 35,
    paddingHorizontal: 0,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  header: {
    fontSize: 18,
    marginBottom: 15,
    color: 'black',
    height: '130px',
    paddingTop: '30px',
    //backgroundColor: 'yellow',
  },
  pageNumber: {
    position: 'absolute',
    bottom: 15,
    fontSize: 12,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  documentDate: {
    position: 'absolute',
    top: 10,
    fontSize: 12,
    left: 0,
    right: 0,
    marginRight: 15,
    textAlign: 'right',
    color: 'grey',
  },
  table_old: { display: "table", width: "auto", borderColor:'grey', borderStyle: "solid", borderWidth: 1, borderLeftWidth: 0, borderRightWidth: 0, borderBottomWidth: 0 },
  table: { display: "table", width: "auto"},
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol1: { width: "40%"},
  tableCol2: { width: "30%"},
  tableCol3: { width: "30%"},
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
  tableCellHeader: {marginTop: 0, fontSize: 12, textAlign:'left', fontWeight: 'bold', padding:'5px'},
  tableCellLeft: {padding:'2px', fontSize: 10, textAlign:'left'},
});


const buscarDades = async () => {
  setIsLoading(true);
  const alum = await Api.get('/llistat/telefons/alumnes');
  console.log(alum)
  if(alum.result.success===1){
    console.log(alum.data.alumnes)
    const alumnes = alum.data.alumnes.map((item)=>{
      return(
        <>
        <Canvas style={{backgroundColor:'grey', height:'1px'}}></Canvas>
        <View style={styles.tableRow}>
          <View style={styles.tableCol1}>
            <Text style={styles.tableCellLeft}>{entities.decode(item.nom+' '+item.cognom1+' '+item.cognom2)}</Text>
          </View>
          <View style={styles.tableCol2}>
            <Text style={styles.tableCellLeft}>{entities.decode(item.nom_pare1 || '')+' '+(item.tel_pare1 || '')}</Text>
          </View>
          <View style={styles.tableCol3}>
            <Text style={styles.tableCellLeft}>{entities.decode(item.nom_pare2 || '')+' '+(item.tel_pare2 || '')}</Text>
          </View>
        </View>
        </>
      );
    });
    setLlista(alumnes);
  }
  setIsLoading(false);
}

useEffect(() => {
  buscarDades();
},[]);


const logo=require('../../images/logo_h_t_ajustat.png');

const language = getLanguage();
let columnes = {};
switch (language) {
  case "ca":
    columnes = {
      titol: 'Telèfons de contacte dels alumnes',
      date: new Date().toLocaleString(),
      nom: 'Nom',
      responsable1: 'Responsable1',
      responsable2: 'Responsable2'
    }
    break;
  case "es":
    columnes = {
      titol: 'Teléfonos de contacto de los alumnos',
      date: new Date().toLocaleString(),
      nom: 'Nombre',
      responsable1: 'Responsable1',
      responsable2: 'Responsable2'
    }
    break;
  case "en":
    columnes = {
      titol: 'Contact telephone numbers of the students',
      date: new Date().toLocaleString(),
      nom: 'Name',
      responsable1: 'Responsible1',
      responsable2: 'Responsible2'
    }
    break;
  default:
    columnes = {
      titol: 'Telèfons de contacte dels alumnes',
      date: new Date().toLocaleString(),
      nom: 'Nom',
      responsable1: 'Responsable1',
      responsable2: 'Responsable2'
    }
}



// Create Document Component
const AlumnesTelefonsPDF = () => (

  <Document>
    <Page wrap style={styles.body}>
      <View style={styles.header} fixed>
        <Image src={logo} style={{marginTop:'0', marginLeft:'10px', width:'202px', height:'37px'}}/>
        <Text style={styles.documentDate}>
          {columnes.date}
        </Text>
        <Text style={{textAlign:'center', paddingTop: 15}}>
            {columnes.titol}
        </Text>
      </View>
      <View style={{top: '-25', marginHorizontal:'20px'}}>
        <View style={styles.table}>
        {/* TableHeader */}
          <View style={{...styles.tableRow, backgroundColor:'lightgrey'}} fixed>
            <View style={styles.tableCol1}>
              <Text style={styles.tableCellHeader}>{columnes.nom}</Text>
            </View>
            <View style={styles.tableCol2}>
              <Text style={styles.tableCellHeader}>{columnes.responsable1}</Text>
            </View>
            <View style={styles.tableCol3}>
              <Text style={styles.tableCellHeader}>{columnes.responsable2}</Text>
            </View>            
          </View>
          {llista}
          <Canvas style={{backgroundColor:'grey', height:'1px'}}></Canvas>
        </View>
      </View>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
);


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

return(
  <>
    { !llista
      ? null
      :
      <PDFViewer width="100%" style={{height:'100vh'}}>
        {AlumnesTelefonsPDF()}
      </PDFViewer>
    }
  </>
);

}

export default translate(AlumnesTelefons);
