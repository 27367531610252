import React from 'react';
import { translate } from 'react-multi-lang';
import { CheckOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
//import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/CursEscolarEdit';
import Add from '../../../components/Formularis/perfils/admin/CursEscolarAdd';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function CursEscolar(props) {
  moment.locale('es');

  const buscarDades = async () => {
    const tmp = await Api.get('/cursosescolars');
    if(tmp.result.success===1){
      return tmp.data.cursos;
    }
  }


  const modal = props.modal ? props.modal : false;

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('generic.curs_escolar')} style={modal ? {marginTop:'0rem', marginLeft:'1rem'} : {marginTop:'1rem', marginLeft:'3rem'}}/>
      <div style={modal ? {marginTop:'0rem', marginLeft:'1rem'} : {marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          modal={modal}
          txt_creats={props.t('generic.curs_escolar')}
          txt_nou={props.t('generic.nou')}
          buscarDades={buscarDades}
          itemBuit={InitDades.curs_escolar()}
          edit={Edit}
          add={Add}
          >
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />
          <Column
          title={props.t('generic.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom)
          )}
          />
          <Column
          title={props.t('generic.fini')}
          key="fini"
          render={(text,record) =>(record.fini ? <Moment date={moment(record.fini).tz('Europe/Madrid')} format="DD/MM/YYYY"/> : "")}
          />
          <Column
          title={props.t('generic.ffin')}
          key="fini"
          render={(text,record) =>(record.ffin ? <Moment date={moment(record.ffin).tz('Europe/Madrid')} format="DD/MM/YYYY"/> : "")}
          />
          <Column
          title={props.t('generic.actual')}
          key="actual"
          render={(text,record) =>(record.actual === 1 ? <CheckOutlined style={{color: 'green'}}/> : "")}
          />

        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(CursEscolar);
