import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import {Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, message } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import TriaCursEscolar from '../TriaCursEscolar';

import { Api, HtmlStrings } from '../../helpers';


const entities = new AllHtmlEntities();

function Edit(props){

  const [id, setId] = useState(props.item.id);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [importe, setImporte] = useState(props.item.import);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.item.id_curs_escolar);

  const [alumnesOptions, setAlumnesOptions] = useState([]);

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nou, setNou] = useState(props.item.id===0);

  const [errorId_Alumne, setErrorId_Alumne] = useState('');
  const [errorId_AlumneText, setErrorId_AlumneText] = useState('');
  const [errorImport, setErrorImport] = useState('');
  const [errorCursEscolar, setErrorCursEscolar] = useState('');


  useEffect(() => {
    buscarDadesFills();
  },[]);

  const buscarDadesFills = async () => {
    if(id_familia > 0){
      const fills = await Api.get(`/alumnes/familia/${id_familia}`);
      if(fills.result.success===1){
        let array_id_fills = [];
        const llista_fills =fills.data.alumnes.map((item) =>{
              array_id_fills.push(item.id);
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setAlumnesOptions(llista_fills);
      }
    }
  }


  const submitForm = async (valors) => {


    let correcte = true;

    if(id_alumne === 0){
      setErrorId_Alumne('error');
      setErrorId_AlumneText(props.t('generic.tria'))
      correcte = false;
    }

    if(importe < 0){
      setErrorImport('error');
      correcte = false;
    }
    if(id_curs_escolar === 0){
      setErrorCursEscolar('error');
      correcte = false;
    }

    if(!correcte){return false}

    setGuardant(true);

    let dades = {
        id,
        id_alumne,
        id_familia,
        import: importe,
        id_curs_escolar
      }

      let method = nou?'POST':'PUT';

      let url_api = nou? '/xec' : '/xec/'+id;

      console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit Adress: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Adress guardar: ', error);
      }

  }


  const triaCursChange = (curs) => {
    setId_curs_escolar(curs);
    if(curs > 0 ){
      setErrorCursEscolar('');
    }
  }

  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setErrorId_Alumne('');
      setErrorId_AlumneText('');
    }
  }

  let posicio_botons = "start";
  if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }



  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <div style={{padding:'10px 24px 10px 0px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:6}}
        wrapperCol={{span:18}}
        colon={true}
        autoComplete="off"
      >
    <Row>
      <Col span={24}>

        <Form.Item label={props.t('generic.curs_escolar')}>
          <TriaCursEscolar defaultValue={id_curs_escolar} onChange={(c) => triaCursChange(c)}/>
        </Form.Item>


        <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_Alumne} help={errorId_AlumneText} style={{marginBottom: 0}}>
          <Select value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
            showSearch
            filterOption={(input, option) =>
              HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
            }
          >
            {alumnesOptions}
          </Select>
        </Form.Item>

        <Form.Item label={props.t('quotes.import')}>
            <InputNumber onChange={setImporte} value={importe}
            step={1.0}
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>

      </Col>
    </Row>

        <Col span={24}>
          <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
            <Col>
              <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Col>

      </Form>
    </div>
  );
}
export default translate(Edit);
