import React, {useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm} from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditableFacturacio from '../../../components/LlistaEditableFacturacio';
import Edit from '../../../components/Formularis/perfils/admin/FacturacioEdit';
import Add from '../../../components/Formularis/perfils/admin/FacturacioCrea';
import { Api, InitDades } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function Facturacio(props){
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  return (<FacturacioClass t={props.t} id_curs_escolar={perfilUsuari.curs_escolar_actual.id}/>);
}


class FacturacioClass extends React.Component{

  constructor(props){
    super(props);    
    this.state = {
      filtresClasses: [],
      searchText: '',
      searchedColumn: '',
      curs_escolar_actual: props.id_curs_escolar,
    };
  }

  buscarDades = async () => {

    const c = await Api.get('/pressupost/'+this.state.curs_escolar_actual);
    if(c.result.success===1){
      const com = c.data.pressuposts.map((item)=>{
        item.curs_escolar_t = entities.decode(item.curs_escolar);
        item.descripcio_t = entities.decode(item.descripcio);
        item.observacions_t = entities.decode(item.observacions);
        return item;
      });
      return com;
    }
  }

  eliminar = async (id_pressupost) => {
    const del = await Api.myFetch(`/pressupost/${id_pressupost}`, 'DELETE');
    if(del.result.success===1){

    }
  }

  getColumnSearchProps = (dataIndex, nom) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${this.props.t('generic.cercar')} ${nom}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('generic.cercar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(),100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? entities.decode(text.toString()) : ''}
        />
      ) : (
        entities.decode(text)
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  triaCursChange = (curs) => {
    this.setState({curs_escolar_actual: curs});
  }

render(){

const { filtresClasses, curs_escolar_actual } = this.state;

const columns = [
{
  title:"Id",
  key:"id",
  width: 50,
  sorter: (a, b) =>  a.id - b.id,
  render: (text,record) => (<span>{record.id}</span>)
},{
  title: this.props.t('generic.curs_escolar'),
  key:"curs_escolar_t",
  dataIndex: 'curs_escolar_t',
  width: 100,
  sorter: (a, b) => { return a.curs_escolar_t.localeCompare(b.curs_escolar_t)},
  ...this.getColumnSearchProps('curs_escolar_t',this.props.t('generic.curs_escolar'))
},{
  title: this.props.t('generic.mes'),
  key:"mes_a_calcular",
  dataIndex: 'mes_a_calcular',
  sorter: (a, b) =>  a.id - b.id,
  render:(text,record) => (this.props.t(`mesos.${record.mes_a_calcular}`))
},{
  title: this.props.t('extraescolars.descripcio'),
  key:"descripcio_t",
  dataIndex: 'descripcio_t',
  sorter: (a, b) => { return a.descripcio_t.localeCompare(b.descripcio_t)},
  ...this.getColumnSearchProps('descripcio_t',this.props.t('extraescolars.descripcio'))
},{
  title:this.props.t('alumnes.observacions'),
  key:"observacions_t",
  dataIndex: 'observacions_t',
  sorter: (a, b) => { return a.observacions_t.localeCompare(b.observacions_t)},
  ...this.getColumnSearchProps('observacions_t',this.props.t('alumnes.observacions'))
},{
  title:this.props.t('generic.total'),
  key:'total',
  dataIndex: 'total',
  width: 100,
  render: (text, record) => (<span>{record.total}€</span>)
}];


  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={this.props.t('menu.Facturacio')+' - '+this.props.t('menu.Pressupost')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditableFacturacio
          txt_creats={this.props.t('facturacio.pressupostos')}
          txt_nou={this.props.t('facturacio.generar_pressupost')}
          btn_extra={<TriaCursEscolar onChange={(c) => this.triaCursChange(c)}/>}
          buscarDades={this.buscarDades}
          curs_escolar={curs_escolar_actual}
          add={Add}
          edit={Edit}
          delete={this.eliminar}
          itemBuit={InitDades.comandesExtraescolars()}
          columns={columns}
          />

      </div>
    </ContainerPagina>
  );
}
}

export default translate(Facturacio);
