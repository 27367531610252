import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ComandesRobaExportaExcel(props){

  const [dades, setDades] = useState(props.dades);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : props.t('menu.ComandesExcursions');
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : props.t('menu.ComandesExcursions');

  useEffect(() => {
    setDades(props.dades)
  },[props.dades]);

  //console.log("dins exporta: ", props.dades)

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="Id" value="id"/>
            <ExcelColumn label={props.t('botiga.ref')} value={"ref"}/>
            <ExcelColumn label={props.t('botiga.titolEstat')} value={(col) => props.t(`botiga.estat.${col.estat}`)}/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={props.t('generic.alumne')} value={(col) => entities.decode(col.alumne_complet)}/>

            <ExcelColumn label={props.t('botiga.producte')} value={(col) => entities.decode(col.nom[props.t('idioma')])}/>
            <ExcelColumn label={props.t('botiga_propietats.talla')} value={(col) => entities.decode(col.talla)}/>

            <ExcelColumn label={props.t('generic.unitats')} value={"unitats"}/>
            <ExcelColumn label={props.t('generic.preu')} value={(col) => parseFloat(col.preu)}/>
            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>
            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.creat).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(ComandesRobaExportaExcel);
