import React, {useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/ComandesExtraescolarsEdit';
import Add from '../../../components/Formularis/perfils/admin/ComandesExtraescolarsAdd';
import InformeResumRebutsAnualPerAlumneExportaExcel from '../../../components/Formularis/perfils/admin/InformeResumRebutsAnualPerAlumneExportaExcel';
import { Api, InitDades } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();
moment.locale('es');

function InformeResumRebutsAnualPerAlumne(props){
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  return (<InformeResumRebutsAnualPerAlumneClass t={props.t} id_curs_escolar={perfilUsuari.curs_escolar_actual.id}/>);
}

class InformeResumRebutsAnualPerAlumneClass extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      filtresClasses: [],
      searchText: '',
      searchedColumn: '',
      refrescar: 0,
      cursEscolarTriat: props.id_curs_escolar,
    };
  }


  async componentDidMount(){
    this.creaFiltreClasses(this.state.cursEscolarTriat);
  }

  creaFiltreClasses = async (curs) => {
    const cla = await Api.get(`/classes/all/${curs}`);
    if(cla.result.success!==1){return [];}

    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    this.setState({filtresClasses: filtreCl});
  }

  buscarDades = async () => {

    const c = await Api.get(`/llistat/resum/rebuts/alumnes/${this.state.cursEscolarTriat}`);
    if(c.result.success===1){
      const com = c.data.resum.map((item)=>{
        item.alumne_nom_t = entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2);
        return item;
      });
      return com;
    }
  }

  eliminar = async (id) => {
    const del = await Api.myFetch(`/extraescolars/comanda/${id}`, 'DELETE');
    if(del.result.success===1){

    }
  }

  triaCursChange = (curs) => {
    this.creaFiltreClasses(curs);
    const refrescar = this.state.refrescar+1;
    this.setState({
        cursEscolarTriat: curs,
        refrescar: refrescar,
    });
  }

  getColumnSearchProps = (dataIndex, nom) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${this.props.t('generic.cercar')} ${nom}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('generic.cercar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      //record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      record[dataIndex] ? this.arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(this.arreglarTxt4Search(value)) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text => (entities.decode(text)),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

   tradueixTipusPagament = (value) =>{
    switch (value) {
      case 'BANC':
        return this.props.t('families.pagament_banc');
        break;
      case 'TRANSFERENCIA':
        return this.props.t('families.pagament_transferencia');
        break;
      case 'EFECTIU':
        return this.props.t('families.pagament_efectiu');
        break;
      case 'TARGETA':
        return this.props.t('families.pagament_targeta');
        break;
      case 'XEC_GUARDERIA':
        return this.props.t('families.pagament_xec_guarderia');
        break;
      default:
        return '--';

    }
  }


render(){

const { filtresClasses, refrescar, cursEscolarTriat } = this.state;

const filtreEstat = [
  {text: this.props.t('alumnes.estat.actiu'), value: 'actiu'},
  {text: this.props.t('alumnes.estat.reserva'), value: 'reserva'},
  {text: this.props.t('alumnes.estat.baixa'), value: 'baixa'}
];

const columns = [
{
  title:"Id_alumne",
  key:"id_alumne",
  sorter: (a, b) =>  a.id_alumne - b.id_alumne,
  render: (text,record) => (<span>{record.id_alumne}</span>)
},{
  title:"Id_familia",
  key:"id_familia",
  sorter: (a, b) =>  a.id_familia - b.id_familia,
  render: (text,record) => (<span>{record.id_familia}</span>)
  /*
},{
  title: this.props.t('alumnes.nom'),
  key:"alumne_nom_t",
  dataIndex: 'alumne_nom_t',
  sorter: (a, b) => { return a.alumne_nom_t.localeCompare(b.alumne_nom_t)},
  ...this.getColumnSearchProps('alumne_nom_t',this.props.t('alumnes.nom'))
  */
},{
  title: this.props.t('alumnes.nom'),
  key:"nom",
  dataIndex: 'nom',
  sorter: (a, b) => { return a.nom.localeCompare(b.nom)},
  ...this.getColumnSearchProps('nom',this.props.t('alumnes.nom'))
},{
  title: this.props.t('alumnes.cognom1'),
  key:"cognom1",
  dataIndex: 'cognom1',
  sorter: (a, b) => { return a.cognom1.localeCompare(b.cognom1)},
  ...this.getColumnSearchProps('cognom1',this.props.t('alumnes.cognom1'))
},{
  title: this.props.t('alumnes.cognom2'),
  key:"cognom2",
  dataIndex: 'cognom2',
  sorter: (a, b) => { return a.cognom2.localeCompare(b.cognom2)},
  ...this.getColumnSearchProps('cognom2',this.props.t('alumnes.cognom2'))
},{
  title: this.props.t('alumnes.estat.titol'),
  key:"estat",
  dataIndex: 'estat',
  width: '10px',
  filters: filtreEstat,
  onFilter: (value, record) => record.estat === value,
  render: (text,record) =>(this.props.t(`alumnes.estat.${record.estat}`)),
  sorter: (a, b) => { return a.estat.localeCompare(b.estat)},
},{
  title: this.props.t('alumnes.classe'),
  key:"classe",
  dataIndex: 'classe',
  filters: filtresClasses,
  onFilter: (value, record) => record.id_classe === value,
  render: (text,record) =>(entities.decode(record.classe)),
  sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
},{
  title:this.props.t('mesos.8'),
  key:"agost",
  sorter: (a, b) =>  a.agost - b.agost,
  render:(text,record) => (<span>{record.agost}€</span>)
},{
  title:this.props.t('mesos.9'),
  key:"setembre",
  sorter: (a, b) =>  a.setembre - b.setembre,
  render:(text,record) => (<span>{record.setembre}€</span>)
},{
  title:this.props.t('mesos.10'),
  key:"octubre",
  sorter: (a, b) =>  a.octubre - b.octubre,
  render:(text,record) => (<span>{record.octubre}€</span>)
},{
  title:this.props.t('mesos.11'),
  key:"novembre",
  sorter: (a, b) =>  a.novembre - b.novembre,
  render:(text,record) => (<span>{record.novembre}€</span>)
},{
  title:this.props.t('mesos.12'),
  key:"desembre",
  sorter: (a, b) =>  a.desembre - b.desembre,
  render:(text,record) => (<span>{record.desembre}€</span>)
},{
  title:this.props.t('mesos.1'),
  key:"gener",
  sorter: (a, b) =>  a.gener - b.gener,
  render:(text,record) => (<span>{record.gener}€</span>)
},{
  title:this.props.t('mesos.2'),
  key:"febrer",
  sorter: (a, b) =>  a.febrer - b.febrer,
  render:(text,record) => (<span>{record.febrer}€</span>)
},{
  title:this.props.t('mesos.3'),
  key:"mar",
  sorter: (a, b) =>  a.mar - b.mar,
  render:(text,record) => (<span>{record.mar}€</span>)
},{
  title:this.props.t('mesos.4'),
  key:"abril",
  sorter: (a, b) =>  a.abril - b.abril,
  render:(text,record) => (<span>{record.abril}€</span>)
},{
  title:this.props.t('mesos.5'),
  key:"maig",
  sorter: (a, b) =>  a.maig - b.maig,
  render:(text,record) => (<span>{record.maig}€</span>)
},{
  title:this.props.t('mesos.6'),
  key:"juny",
  sorter: (a, b) =>  a.juny - b.juny,
  render:(text,record) => (<span>{record.juny}€</span>)
},{
  title:this.props.t('mesos.7'),
  key:"juliol",
  sorter: (a, b) =>  a.juliol - b.juliol,
  render:(text,record) => (<span>{record.juliol}€</span>)
},{
  title:this.props.t('generic.total'),
  key:"import_total",
  sorter: (a, b) =>  a.import_total - b.import_total,
  render:(text,record) => (<span>{record.import_total}€</span>)
},{
  title:"Pendent",
  key:"import_pendent",
  sorter: (a, b) =>  (parseFloat(a.import_pendent)-parseFloat(a.import_entregat)) - (parseFloat(b.import_pendent)-parseFloat(b.import_entregat)),
  render:(text,record) => {
    const pendent = parseFloat(record.import_pendent)-parseFloat(record.import_entregat);
    if(pendent > 0){
      return(<span style={{color:'red'}}>{pendent.toFixed(2)}€</span>)
    }else {
      return(<span>0€</span>)
    }

    }
},{
  title:this.props.t('families.pagament_xec_guarderia'),
  key:"xec_guarderia",
  sorter: (a, b) =>  a.xec_guarderia - b.xec_guarderia,
  render: (text,record) => (<span>{record.xec_guarderia}</span>)
},{
  title: this.props.t('families.tipus_pagament'),
  key: "tipus_pagament",
  width: "175px",
  dataIndex: "tipus_pagament",
  render: (text,record) => (this.tradueixTipusPagament(record.tipus_pagament)),
  sorter: (a, b) => { return entities.decode(this.tradueixTipusPagament(a.tipus_pagament)).localeCompare(entities.decode(this.tradueixTipusPagament(b.tipus_pagament)))},
},{
  title:this.props.t('families.pagament_unic_totes_quotes_any'),
  key:"pagament_unic_totes_quotes_any",
  sorter: (a, b) =>  a.pagament_unic_totes_quotes_any - b.pagament_unic_totes_quotes_any,
  render: (text,record) => (<span>{record.pagament_unic_totes_quotes_any}</span>)
},{
  title:this.props.t('families.pare_treballador_centre'),
  key:"pare_treballador_centre",
  sorter: (a, b) =>  a.pare_treballador_centre - b.pare_treballador_centre,
  render: (text,record) => (<span>{record.pare_treballador_centre}</span>)

}];

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={this.props.t('menu.InformeResumRebutsAnualPerAlumne')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditableModalNouEdit
          scroll={{ x: 1500}}
          txt_creats={this.props.t('menu.Alumnes')}
          buscarDades={this.buscarDades}
          dadesMostradesCallback={(dadesMostrades) => {
            return(
              <div style={{marginTop: '1rem'}}>
                <InformeResumRebutsAnualPerAlumneExportaExcel dades={dadesMostrades} btn_txt="Export Filtered"/>
              </div>
            )
          }}
          columns={columns}
          id_curs_escolar={cursEscolarTriat}
          refrescar={refrescar}
          btn_extra={<TriaCursEscolar onChange={(c) => this.triaCursChange(c)}/>}
          />

      </div>
    </ContainerPagina>
  );
}
}

export default translate(InformeResumRebutsAnualPerAlumne);
