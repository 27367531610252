import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/ca';
//import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { LeftOutlined } from '@ant-design/icons';
import { Form, Row, Col, DatePicker, Switch, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../helpers';
import NomDescripcioIdiomes from '../NomDescripcioIdiomes';
import TriaCursos from '../TriaCursos';

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';

function Edit(props){

  const id = props.item.id;
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [descripcio_ca, setDescripcio_ca] = useState(entities.decode(props.item.descripcio["ca"]));
  const [descripcio_en, setDescripcio_en] = useState(entities.decode(props.item.descripcio["en"]));
  const [descripcio_es, setDescripcio_es] = useState(entities.decode(props.item.descripcio["es"]));
/*
  const [horari_ca, setHorari_ca] = useState(entities.decode(props.item.horari['ca']));
  const [horari_en, setHorari_en] = useState(entities.decode(props.item.horari['en']));
  const [horari_es, setHorari_es] = useState(entities.decode(props.item.horari['es']));
  const [fini, setFini] = useState(props.item.fini);
  const [ffin, setFfin] = useState(props.item.ffin);
  const [cursos, setCursos] = useState(props.item.cursos || []);

  const [noCaduca, setNoCaduca] = useState((fini!==null && ffin!=null) ? false : true);
*/
  const [guardant, setGuardant] = useState(false);

  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');
  /*
  const [errorHorari_ca, setErrorHorari_ca] = useState('');
  const [errorHorari_en, setErrorHorari_en] = useState('');
  const [errorHorari_es, setErrorHorari_es] = useState('');
  const [errorTriaCursos, setErrorTriaCursos] = useState('');
  const [errorTriaCursosText, setErrorTriaCursosText] = useState('');
*/

  const nou = (props.item.id===0);
/*
  const calendarChange = (rang_moments, rang_strings) => {
      setFini(rang_moments[0].toDate());
      setFfin(rang_moments[1].toDate());
    }
*/
  const nomChange_ca = (e) =>{
    const n = e.target.value.trim();
    setNom_ca(n);
    if(n.length>0){ setErrorNom_ca('');}
  }
  const nomChange_en = (e) =>{
    const n = e.target.value.trim();
    setNom_en(n);
    if(n.length>0){ setErrorNom_en('');}
  }
  const nomChange_es = (e) =>{
    const n = e.target.value.trim();
    setNom_es(n);
    if(n.length>0){ setErrorNom_es('');}
  }
/*  const horariChange_ca = (e) =>{
    const n = e.target.value.trim();
    setHorari_ca(n);
    if(n.length>0){ setErrorHorari_ca('');}
  }
  const horariChange_en = (e) =>{
    const n = e.target.value.trim();
    setHorari_en(n);
    if(n.length>0){ setErrorHorari_en('');}
  }
  const horariChange_es = (e) =>{
    const n = e.target.value.trim();
    setHorari_es(n);
    if(n.length>0){ setErrorHorari_es('');}
  }
  const triaCursosChange = (value) =>{
    setCursos(value);
    if(value.length>0){
      setErrorTriaCursos('');
      setErrorTriaCursosText('');
    }
  }

*/
  const submitForm = async (values) => {

      let correcte = true;

      if(nom_ca.length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(nom_en.length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(nom_es.length === 0){
        setErrorNom_es('error');
        correcte = false;
      }
/*      if(horari_ca.length === 0){
        setErrorHorari_ca('error');
        correcte = false;
      }

      if(horari_en.length === 0){
        setErrorHorari_en('error');
        correcte = false;
      }
      if(horari_es.length === 0){
        setErrorHorari_es('error');
        correcte = false;
      }
      if(cursos.length === 0){
        setErrorTriaCursos('error');
        setErrorTriaCursosText(props.t('generic.tria_varis'));
        correcte = false;
      }
*/

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        nom_ca: entities.encode(nom_ca),
        nom_en: entities.encode(nom_en),
        nom_es: entities.encode(nom_es),
        descripcio_ca: entities.encode(descripcio_ca),
        descripcio_en: entities.encode(descripcio_en),
        descripcio_es: entities.encode(descripcio_es),
      //  horari_ca: entities.encode(horari_ca),
      //  horari_en: entities.encode(horari_en),
      //  horari_es: entities.encode(horari_es),
        //fini,
        //ffin,
        //cursos
      }

      //console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/assignatures' : '/assignatures/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          //console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit Form Assignatures: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Assignatures guardar: ', error);
      }

  }

  /*
  let defaultDataRange = '';

  if (fini!=null && ffin!=null){
    defaultDataRange=[
      moment(new Date(fini), dateFormat),
      moment(new Date(ffin), dateFormat)
    ];
  }

  if(nou){
      defaultDataRange=[
        moment(new Date(), dateFormat),
        moment(new Date(), dateFormat)
      ];
    }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

*/
return (
<>
  <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
    <LeftOutlined className="BotoBack" onClick={props.callback}/>
  </div>
  <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="horizontal"
      labelCol={{span:2}}
      wrapperCol={{span:22}}
      colon={true}
      autoComplete="off"
    >
      {/*<Form.Item style={{marginBottom: 0}}
        validateStatus={errorTriaCursos}
        help={errorTriaCursosText}
        label={props.t('generic.tria_curs')}
        required={true}
      >
        <TriaCursos defaultValue={cursos} onChange={triaCursosChange}/>
      </Form.Item>*/}

      {/*<Form.Item
        label={props.t('generic.fini_fin')}
        required={true}
      >
        <DatePicker.RangePicker
          defaultValue={defaultDataRange}
          showTime={{ format: 'HH:mm' }}
          placeholder={[props.t('generic.fini'), props.t('generic.ffin')]}
          format={dateFormat}
          locale={localeDatePicker}
          disabled={noCaduca}
          onChange={calendarChange}
          />
          <Switch checkedChildren={props.t('generic.nocaduca')} unCheckedChildren={props.t('generic.caduca')} onChange={setNoCaduca} defaultChecked={noCaduca} style={{marginLeft: '5px'}}/>
        </Form.Item>*/}

        <NomDescripcioIdiomes
          errorNom_ca = {errorNom_ca}
          errorNom_en = {errorNom_en}
          errorNom_es = {errorNom_es}
          nomChange_ca = {nomChange_ca}
          nomChange_en = {nomChange_en}
          nomChange_es = {nomChange_es}
          nom_ca = {nom_ca}
          nom_en = {nom_en}
          nom_es = {nom_es}
          visibleDescripcio = {true}
          setDescripcio_ca = {setDescripcio_ca}
          setDescripcio_en = {setDescripcio_en}
          setDescripcio_es = {setDescripcio_es}
          descripcio_ca = {descripcio_ca}
          descripcio_en = {descripcio_en}
          descripcio_es= {descripcio_es}
        />

        {/*<Row style={{marginLeft: '12px'}} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} key='horari_ca'>
            <Form.Item
                validateStatus={errorHorari_ca}
                label={props.t('extraescolars.horari')+' CA'}
                required={true}
                labelCol={{span:5}}
                wrapperCol={{span:19}}
              >
                <Input name="horari_ca" placeholder={props.t('extraescolars.horari')} onChange={horariChange_ca} defaultValue={horari_ca}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} key='horari_en'>
            <Form.Item
                validateStatus={errorHorari_en}
                label={props.t('extraescolars.horari')+' EN'}
                required={true}
                labelCol={{span:5}}
                wrapperCol={{span:19}}
              >
                <Input name="horari_en" placeholder={props.t('extraescolars.horari')} onChange={horariChange_en} defaultValue={horari_en}/>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} key='horari_es'>
            <Form.Item
                validateStatus={errorHorari_es}
                label={props.t('extraescolars.horari')+' ES'}
                required={true}
                labelCol={{span:5}}
                wrapperCol={{span:19}}
              >
                <Input name="horari_es" placeholder={props.t('extraescolars.horari')} onChange={horariChange_es} defaultValue={horari_es}/>
            </Form.Item>
          </Col>
        </Row>*/}

        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  </>
  );
}
export default translate(Edit);
