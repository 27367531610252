import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/NotificacionsTipus';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function NotificacionsTipus(props) {

  const [filtresPerfil, setFiltresPerfil] = useState([]);

  const buscarDades = async () => {
    const tmp = await Api.get('/notificacions/tipus');
    if(tmp.result.success===1){
      muntarFiltrePerfil(tmp.data.notificacions_tipus);
      return tmp.data.notificacions_tipus;
    }
  }
  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/notificacions/tipus/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  const muntarFiltrePerfil = (dades) =>{
    let temp_list = [];
    let perfilList = [];
    dades.map((item) => {
      if(temp_list.indexOf(item.perfil) === -1){
        temp_list.push(item.perfil)
        perfilList.push({text: entities.decode(item.perfil), value: item.perfil});
      }
    });

    perfilList.sort((a, b) => entities.decode(a.value).localeCompare(entities.decode(b.value)));
    setFiltresPerfil(perfilList);
  }

  return(
    <ContainerPagina xl={16}>
      <TitolPagina txt={props.t('menu.TipusNotificacions')} style={{marginTop:'1rem', marginLeft:'3rem'}}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          txt_creats={props.t('botiga_propietats.creados')}
          txt_nou={props.t('botiga_propietats.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          duplica={true}
          itemBuit={InitDades.notificacions_tipus()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />
          <Column
          title={props.t('botiga_propietats.ordre')}
          key="ordre"
          render={(text,record) =>(
            <span>{record.ordre}</span>
          )}
          />
          <Column
          title={props.t('generic.perfil')}
          key="perfil"
          render={(text,record) =>(
            entities.decode(record.perfil)
          )}
          filters = {filtresPerfil}
          onFilter = {(value, record) => record.perfil === value}
          sorter = {(a, b) => entities.decode(a.perfil).localeCompare(entities.decode(b.perfil))}
          />
          <Column
          title={props.t('botiga_propietats.text')+' CA'}
          key="nom_ca"
          render={(text,record) =>(
            entities.decode(record.nom['ca'])
          )}
          />
          <Column
          title={props.t('botiga_propietats.text')+' EN'}
          key="nom_en"
          render={(text,record) =>(
            entities.decode(record.nom['en'])
          )}
          />
          <Column
          title={props.t('botiga_propietats.text')+' ES'}
          key="nom_es"
          render={(text,record) =>(
            entities.decode(record.nom['es'])
          )}
          />
        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(NotificacionsTipus);
