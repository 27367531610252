import React from 'react';
import moment from 'moment';
import 'moment/locale/ca';

import { translate } from 'react-multi-lang';
import { Table, Col, Tag } from 'antd';
import { EuroCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../../components/TitolPagina';
import LlistaEditableQuotesPreusCurs from '../../../components/LlistaEditableQuotesPreusCurs';
import Edit from '../../../components/Formularis/perfils/admin/QuotesPreus';

import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';

function Producte(props) {


  const tipus_quota = props.tipus_quota;

  const buscarDades = async () => {

    const c = await Api.get('/cursos');
    if(c.result.success!==1){return [];}

    const cursos = c.data.cursos.reduce((acumulat, item) => ({
      ...acumulat,
      [item.id]: item.nom,
    }),{});

//console.log('cursos',cursos)
    const q = await Api.get(`/quotes/${tipus_quota.id}/${props.id_curs_escolar}`);
    if(q.result.success===1){
      const quotes = q.data.quotes.map((item) =>{
        const tags = item.cursos.map((curs) => {
          return <Tag>{cursos[curs]}</Tag>;
        });
        item.cursos_tags = tags;
        item.tipus_quota = tipus_quota;
        return item;
      });
      return quotes;
    }
    //return [InitDades.quota()];
  }


  const marcarEliminat = async (id) => {
    const q = await Api.myFetch('/quotes/'+id, 'DELETE');
    if(q.result.success===1){

    }
  }



let itemBuit = InitDades.quota();
itemBuit.id_tipus_quota = tipus_quota.id;
itemBuit.tipus_quota = tipus_quota;

return(
    <>
      <TitolPagina txt={entities.decode(tipus_quota.nom[props.idioma])} level={4} style={{marginTop:'1rem', marginLeft:'0.5rem', paddingLeft:'0.5rem'}}/>
      <div style={{marginTop:'1rem', marginLeft:'0.5rem'}}>
        <LlistaEditableQuotesPreusCurs
          id_curs_escolar={props.id_curs_escolar}
          tipus_quota={tipus_quota}
          rowKey="id"
          txt_creats={props.t('quotes.creados')}
          icon_creats={EuroCircleOutlined}
          txt_nou={props.t('quotes.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={itemBuit}
          duplica={true}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />

          <Column
          title={props.t('quotes.text')}
          ellipsis={true}
          key="text"
          render={(text,record) => (
            <span>{entities.decode(record.text[props.idioma]+' ')}</span>
          )}
          />
          <Column
          title={props.t('quotes.cursos')}
          ellipsis={true}
          key="cursos"
          render={(text,record) =>(
            <span>{record.cursos_tags}</span>
          )}
          />

          <Column
          title={props.t('quotes.import')}
          key="preu"
          render={(text,record) =>(
            <span>{record.preu}€</span>
          )}
          />
          {/*<Column
          title={props.t('quotes.pagament_titol')}
          key="pagament"
          ellipsis={true}
          render={(text,record) =>(
            props.t(`quotes.tipus_pagament.${tipus_quota.pagament}`)
          )}
          />*/}

          <Column
          title={props.t('quotes.descomptes')}
          key="descompte"
          render={(text,record) =>(
            <span>{record.descompte_2on > 0 || record.descompte_3rs > 0 ? props.t('generic.si') : props.t('generic.no')}</span>
          )}
          />
          <Column
          title="ordre"
          key="ordre"
          render={(text,record) => (
            <span>{record.ordre}</span>
          )}
          />
        </LlistaEditableQuotesPreusCurs>

      </div>
    </>
  );

}

export default translate(Producte);
