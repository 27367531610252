import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Table, Form, Row, Col, Button, Alert, Switch, message } from 'antd';
import { Api } from '../../../../helpers';

import { AllHtmlEntities } from 'html-entities';
const entities = new AllHtmlEntities();

function ServeisTraspas({t, old_id_curs_escolar, new_id_curs_escolar, callback_ok, callback_ko}){

  const [transport, setTransport] = useState(true);
  const [extraescolars, setExtraescolars] = useState(true);
  const [menjador, setMenjador] = useState(true);
  const [acollida, setAcollida] = useState(true);

  const [guardant, setGuardant] = useState(false);



  const submitForm = async (valors) =>{
    let ok = true;

    setGuardant(true);
    if(transport){
      ok = await submitIndividual('/cursescolar/traspassar/transport');
    }

    if(extraescolars){
      ok = await submitIndividual('/cursescolar/traspassar/extraescolars');
    }

    if(menjador){
      ok = await submitIndividual('/cursescolar/traspassar/menjador');
    }

    if(acollida){
      ok = await submitIndividual('/cursescolar/traspassar/acollida');
    }
    setGuardant(false);
    if(ok){
      message.success(t('generic.OK_saved'), 5);
      callback_ok();
    }else{
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      callback_ko();
    }

  }


  const submitIndividual = async (url_api) => {

    let dades = {
      old_id_curs_escolar,
      new_id_curs_escolar
    }

    try{
      const resposta = await Api.myFetch(url_api, 'POST', JSON.stringify(dades));

      if(resposta.result.success !== 1){
        console.error('Error: submit ServeisTraspas: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
        return false;
      }
    }catch(error){
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: ServeisTraspas guardar: ', error);
      return false;
    }
    return true;
  }


    return(
      <div style={{padding:'10px 24px 35px 0px'}}>
        <Form onFinish={submitForm} style={{marginTop:'35px'}}
        layout="horizontal"
        labelCol={{span:0}}
        wrapperCol={{span:24}}
        colon={true}
        autoComplete="off"
        >
        <Alert
          style={{marginTop: 15, marginBottom: 15}}
          message={"Traspassar Serveis"}
          description={"Es procedirà a clonar tots els productes de servies. Els nous creats es podren reaprofitar o eliminar si no són necessaris."}
          type="info"
          showIcon
        />

        <Form.Item>
          <span className="ant-form-item-label">
            <label>{t('menu.Transport')}&nbsp;</label>
            <Switch
              checkedChildren={t('generic.si')}
              unCheckedChildren={t('generic.no')}
              onChange={setTransport} defaultChecked={transport}
            />
          </span>
        </Form.Item>
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{t('menu.Extraescolars')}&nbsp;</label>
            <Switch
              checkedChildren={t('generic.si')}
              unCheckedChildren={t('generic.no')}
              onChange={setExtraescolars} defaultChecked={extraescolars}
            />
          </span>
        </Form.Item>
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{t('menu.Menjador')}&nbsp;</label>
            <Switch
              checkedChildren={t('generic.si')}
              unCheckedChildren={t('generic.no')}
              onChange={setMenjador} defaultChecked={menjador}
            />
          </span>
        </Form.Item>
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{t('menu.Acollida')}&nbsp;</label>
            <Switch
              checkedChildren={t('generic.si')}
              unCheckedChildren={t('generic.no')}
              onChange={setAcollida} defaultChecked={acollida}
            />
          </span>
        </Form.Item>


          <Form.Item wrapperCol={{span: 24 }} name="botonets" style={{marginTop: 20}}>
            <Row type="flex" justify="center">
              <Col>
                <Button onClick={callback_ko}>{t('generic.cancel')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{t('generic.seguent')}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );

}
export default translate(ServeisTraspas);
