import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ParesBusExportaExcel(props){

  const [dades, setDades] = useState([]);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Rebuts';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'Rebuts';

  const [isLoading, setIsLoading] = useState(true);

  const buscarDades = async () => {
    setIsLoading(true);
    const pares = await Api.get('/llistat/pares/busnew');
    if(pares.result.success===1){
      switch (props.linia) {
        case 'reus':
          setDades(pares.data.reus);
          break;
        case 'tarragona':
          setDades(pares.data.tarragona);
          break;
        case 'zona_nord':
          setDades(pares.data.zona_nord);
          break;
        default:

      }
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="Estat" value={(col) => entities.decode(col.estat)}/>
            <ExcelColumn label="id_familia" value="id_familia"/>
            <ExcelColumn label="id_alumne" value="id_alumne"/>

            <ExcelColumn label="Nom" value={(col) => entities.decode(col.nom)}/>
            <ExcelColumn label="Cognom1" value={(col) => entities.decode(col.cognom1)}/>
            <ExcelColumn label="Cognom2" value={(col) => entities.decode(col.cognom2)}/>
            <ExcelColumn label="Curs" value={(col) => entities.decode(col.curs)}/>
            <ExcelColumn label="Classe" value={(col) => entities.decode(col.classe)}/>

            <ExcelColumn label="Responsable1 Nom" value={(col) => entities.decode(col.nom_pare1)}/>
            <ExcelColumn label="Responsable1 Cognom" value={(col) => entities.decode(col.cognom1_pare1)}/>
            <ExcelColumn label="Tel1" value="tel_pare1"/>
            <ExcelColumn label="Email1" value="email_pare1"/>

            <ExcelColumn label="Responsable2 Nom" value={(col) => entities.decode(col.nom_pare2)}/>
            <ExcelColumn label="Responsable2 Cognom" value={(col) => entities.decode(col.cognom1_pare2)}/>
            <ExcelColumn label="Tel2" value="tel_pare2"/>
            <ExcelColumn label="Email2" value="email_pare2"/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(ParesBusExportaExcel);
