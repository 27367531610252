import React, { useState, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Button, Card, message } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

function Extraescolar(props){
  //console.log(props)
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const producte = props.item;

  const [imatge, setImatge] = useState(props.item.url_image || "https://via.placeholder.com/443x249?text=NO+IMAGE+ASSIGNED!!");
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);

  const submitForm = async (valors) => {


    if(!validat){
      return false;
    }

    setGuardant(true);

    let dades = {
      id_alumne: props.id_alumne,
      id_pare: perfilUsuari.account.id,
      id_extraescolar: producte.id_extraescolar,
    }

    console.log(dades);

    try{
      const resposta = await Api.myFetch('/extraescolars/guardar', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Extraescolar: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Extraescolar guardar: ', error);
    }

  }


  return(
    <>
    <Row justify="center">
      <Card
        bordered={false}
        style={{ marginTop: '1.5rem', padding: 0}}
        cover={<img alt="Foto Extraescolar" src={imatge} onError={() => setImatge("https://via.placeholder.com/443x249?text=URL+IMAGE+NOT+FOUND!!")}/>}
      >
        <Card.Meta title={<span style={{fontSize:'16px'}}>{entities.decode(producte.nom[props.t('idioma')])}</span>} />
        <Card.Meta title={<div style={{textAlign:'right', fontSize: '14px'}}>{entities.decode(producte.horari[props.t('idioma')])}</div>}  description={entities.decode(producte.descripcio[props.t('idioma')])}/>

        <Row justify="center" style={{marginTop: '30px'}}>
          <b>{props.t('botiga.preu')+': '}</b>&nbsp;<span style={{color: 'red'}}>{producte.preu}€</span>
        </Row>
      </Card>
    </Row>

{!producte.comprat
  ?
    <>
      <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
        <AutoritzarContrasenya resposta={setValidat}/>
      </Row>
      <Row justify="center" style={{marginTop: '10px'}}>
        <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
          {props.t('botiga.pagar_autoritzar')}
        </Button>
      </Row>
    </>
  :
    <>
      <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
        <span style={{color: 'red'}}>*{props.t('generic.serveiJaContractat')}</span>
      </Row>
      <Row justify="center" style={{marginTop: '10px'}}>
        <Button type="primary" onClick={() =>props.tancarModal()}>
          {props.t('generic.back')}
        </Button>
      </Row>
    </>
  }
    </>
  );
}
export default translate(Extraescolar);
