import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined, AlertFilled, CloseOutlined, PauseOutlined, MinusOutlined, CheckOutlined } from '@ant-design/icons';

import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Select, Divider, Switch, Space, Table, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';
import RebutsLinies from './RebutsLinies';
import RebutsEntregatCompte from './RebutsEntregatCompte';
import TextAreaCount from '../../../TextAreaCount';
import TriaCursEscolar from '../../../TriaCursEscolar';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

const entities = new AllHtmlEntities();

function Rebuts(props){
  //console.log('Inici Props: ', props)
  moment.locale('es');

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(props.item.id_curs_escolar || perfilUsuari.curs_escolar_actual.id);

  const [id, setId] = useState(props.item.id);
  const [id_pressupost, setId_pressupost] = useState(props.item.id_pressupost);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [id_alumne, setId_alumne] = useState(props.item.id_alumne);
  const [concepte, setConcepte] = useState(entities.decode(props.item.concepte));
  const [total, setTotal] = useState(props.item.import);
  const [id_banc, setId_banc] = useState(props.item.id_banc);
  const [bic, setBic] = useState(props.item.bic);
  const [iban, setIban] = useState(props.item.iban);
  const [titular, setTitular] = useState(entities.decode(props.item.titular));
  const [mandat, setMandat] = useState(props.item.mandat);
  const [mandat_data, setMandat_data] = useState(props.item.mandat_data);
  const [tipus_pagament, setTipus_pagament] = useState(props.item.tipus_pagament);
  const [estat, setEstat] = useState(props.item.estat);
  const [pagament_unic_totes_quotes_any, setPagament_unic_totes_quotes_any] = useState(props.item.pagament_unic_totes_quotes_any);
  //const [fcrea, setFcrea ] = useState(props.item.fcrea);
  //const [fcrea, setFcrea ] = useState(props.item.creat);
  const [fcrea, setFcrea ] = useState(props.item.fcrea ? props.item.fcrea : moment.utc(new Date()));
  const [creat, setCreat ] = useState(props.item.creat ? props.item.creat : moment.utc(new Date()));
  const [alumne, setAlumne ] = useState(props.item.alumne);
  const [pressupost, setPressupost] = useState(props.item.pressupost);
  const [linies, setLinies] = useState(props.item.linies);

  const [familiesOptions, setFamiliesOptions] = useState([]);
  const [bancs, setBancs] = useState([]);
  const [bancsOptions, setBancsOptions] = useState([]);
  const [alumnesOptions, setAlumnesOptions] = useState([]);
  const [estatsOptions, setEstatsOptions] = useState([])
  const [pressupostosOptions, setPressupostosOptions] = useState([])
  const [loadingPressupost, setLoadingPressupost] = useState(true);

  const [guardant, setGuardant] = useState(false);
  const [errorId_Familia, setErrorId_Familia] = useState('');
  const [errorId_pressupost, setErrorId_pressupost] = useState('');
  const [errorId_pressupostText, setErrorId_pressupostText] = useState('');
  const [errorId_FamiliaText, setErrorId_FamiliaText] = useState('');
  const [errorId_banc, setErrorId_banc] = useState('');
  const [errorId_bancText, setErrorId_bancText] = useState('');
  const [errorId_Alumne, setErrorId_Alumne] = useState('');
  const [errorId_AlumneText, setErrorId_AlumneText] = useState('');
  const [errorTitular, setErrorTitular] = useState('');
  const [errorIban, setErrorIban] = useState('');
  const [errorMandat, setErrorMandat] = useState('');
  const [errorMandat_data, setErrorMandat_data] = useState('');
  const [errorConcepte, setErrorConcepte] = useState('');

  const [errorFcrea, setErrorFcrea] = useState('');
  const [errorTipusPagament, setErrorTipusPagament] = useState('');
  const [errorTipusPagamentText, setErrorTipusPagamentText] = useState('');

  const [nou, setNou] = useState(props.item.id===0);
/*
  useEffect(() => {
    let p = [];
    p.push(
      <Select.Option key={0} value={0}>
        None
      </Select.Option>
    );
    props.pressupostos.map((item) =>{
      if(item.value > 0){
        p.push(
          <Select.Option key={item.value} value={item.value}>
            {item.text}
          </Select.Option>
        );
      }
    });
    setPressupostosOptions(p);
  },[props.pressupostos]);
  */
  useEffect(() => {
    buscarDadesSelectPressuposts();
  },[cursEscolarTriat]);

  useEffect(() => {
    if(id_familia > 0){
      buscarDadesBancs();
      buscarDadesFills();
    }
    buscarDadesFamilia();
    muntarSelectEstat();
  },[]);

  useEffect(() => {
    buscarDadesBancs();
    buscarDadesFills();
    if(nou){
      buscarDadesUnitatFamiliar();
    }

  },[id_familia]);


  const buscarDadesSelectPressuposts = async () =>{
    setLoadingPressupost(true);
    //const c = await Api.get('/pressupost/'+perfilUsuari.curs_escolar_actual.id);
    const c = await Api.get('/pressupost/'+cursEscolarTriat);
    if(c.result.success===1){
      let pressus = [];
      const facturats = c.data.pressuposts.filter((element, index, arr) => element.facturat === true);
      const select = facturats.map((item)=>{
        return(
          <Select.Option key={item.id} value={item.id}>
            <span>{entities.decode(item.descripcio)}</span>
          </Select.Option>
        );
      });
      setPressupostosOptions(select);
      setLoadingPressupost(false);
    }
  }

  const buscarDadesFamilia = async () => {
    const fam = await Api.get('/families');
    if(fam.result.success===1){
      const llista_fam = fam.data.families.map((item) =>{

          if(item.estat === 'baixa'){
            return(
              <Select.Option key={item.id} value={item.id} style={{color:'red'}}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2) + ' (Baixa)'}
              </Select.Option>
            );

          }else{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );


          }

      });
      setFamiliesOptions(llista_fam);
    }
  }

  const buscarDadesBancs = async () => {
    if(id_familia > 0){
      const bancs = await Api.get(`/bancs/familia/${id_familia}`);
      if(bancs.result.success===1){
        setBancs(bancs.data.bancs);
        const llista_bancs = bancs.data.bancs.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {`${entities.decode(item.titular)} - ${props.t('banc.iban')}: ${item.iban} - ${props.t('banc.utilitzar_en.titol')}: ${props.t(`banc.utilitzar_en.${item.utilitzar_en}`)}`}

                </Select.Option>
              );
        });
        setBancsOptions(llista_bancs);
      }
    }
  }

  const buscarDadesFills = async () => {
    if(id_familia > 0){
      const fills = await Api.get(`/alumnes/tots/familia/${id_familia}`);
      if(fills.result.success===1){
        const llista_fills =fills.data.alumnes.map((item) =>{
              return(
                <Select.Option key={item.id} value={item.id}>
                  {entities.decode(item.nom) + ' ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
                </Select.Option>
              );
        });
        setAlumnesOptions(llista_fills);
      }
    }
  }

  const buscarDadesUnitatFamiliar = async () => {
    if(id_familia > 0){
      const uf = await Api.get(`/families/${id_familia}`);
      if(uf.result.success===1){
        console.log('UnitatFamiliar: ', uf.data);
        setPagament_unic_totes_quotes_any(uf.data.pagament_unic_totes_quotes_any);
      }
    }
  }

  const muntarSelectEstat = () =>{

    let estats = [
      'temporal',
      'revisio',
      'pendent',
      'enviat',
      'rebutjat',
      'cobrat'
    ];

    const llista_estats = estats.map((valor) =>{
      return(
        <Select.Option key={valor} value={valor}>
          <span><AlertFilled style={{color: `${props.t(`rebuts.estatColor.${valor}`)}`}}/>&nbsp;{props.t(`rebuts.estat.${valor}`)}</span>
        </Select.Option>
      );
    });
    setEstatsOptions(llista_estats);
  }


  const changeFcrea = (date, dateString) => {
    if(date){
      setFcrea(date.toISOString());
      setCreat(date.toISOString());
      setErrorFcrea('');
    }else{
      setFcrea(null);
    }
  }
  const changeMandat_data = (date, dateString) => {
    if(date){
      setMandat_data(date.toISOString());
      setErrorMandat_data('');
    }else{
      setMandat_data(null);
    }
  }

  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_Familia('');
      setErrorId_FamiliaText('');
      setId_alumne(0);
    }
  }

  const triaBanc = (value) =>{
    setId_banc(value);
    if(value>0){
      setErrorId_banc('');
      setErrorId_bancText('');

      const banc = bancs.find((item, index) => item.id === value);
      if(banc){
        setBic(banc.bic);
        setIban(banc.iban);
        setMandat(banc.mandat);
        setMandat_data(banc.mandat_data);
        setTitular(entities.decode(banc.titular));
      }
    }
  }
  const triaAlumne = (value) =>{
    setId_alumne(value);
    if(value>0){
      setErrorId_Alumne('');
      setErrorId_AlumneText('');
    }
  }

  const triaTipusPagament = (value) =>{
    setTipus_pagament(value);
    if(value.length>0){
      setErrorTipusPagament('');
      setErrorTipusPagamentText('');
    }
  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
  }

  const submitForm = async (values) => {

    let correcte = true;

    if(id_pressupost===0){
      setErrorId_pressupost('error');
      setErrorId_pressupostText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_familia===0){
      setErrorId_Familia('error');
      setErrorId_FamiliaText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_alumne===0){
      setErrorId_Alumne('error');
      setErrorId_AlumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(!concepte){
      setErrorConcepte('error');
      correcte = false;
    }
    if(!fcrea){
      setErrorFcrea('error');
      correcte = false;
    }
    if(tipus_pagament === null){
      setErrorTipusPagament('error');
      setErrorTipusPagamentText(props.t('generic.tria'));
      correcte = false;
    }
    if(tipus_pagament === 'BANC'){
      if(id_banc===0 && nou){
        setErrorId_banc('error');
        setErrorId_bancText(props.t('generic.tria'));
        correcte = false;
      }
      if(!titular){
        setErrorTitular('error');
        correcte = false;
      }
      if(!iban){
        setErrorIban('error');
        correcte = false;
      }
      if(!mandat){
        setErrorMandat('error');
        correcte = false;
      }
      if(!mandat_data){
        setErrorMandat_data('error');
        correcte = false;
      }

    }


    if(!correcte){
      return false;
    }
    setGuardant(true);
    let dades = {
      id_curs_escolar: cursEscolarTriat,
      id,
      estat,
      id_alumne,
      id_pressupost,
      import: total,
      concepte: entities.encode(concepte),
      tipus_pagament,
      id_banc,
      bic,
      iban,
      titular,
      mandat,
      mandat_data: moment.utc(mandat_data).toISOString(),
      fcrea: moment.utc(fcrea).toISOString()
    }

    let method = nou?'POST':'PUT';
    let url_api = nou? '/rebuts' : '/rebuts/'+id;

    try{
      console.log('dades a enviades: ', dades)
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));
      if(resposta.result.success === 1){
        if(nou){
          setId(resposta.data.id);
          setNou(false);
        }
        if(props.item.id === 0){
          props.callback_refrescar();
        }
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        //props.callback();
        props.item.id_curs_escolar = dades.id_curs_escolar;
        props.item.estat = dades.estat;
        props.item.id_alumne = dades.id_alumne;
        props.item.id_pressupost = dades.id_pressupost;
        props.item.import = dades.import;
        props.item.concepte = dades.concepte;
        props.item.tipus_pagament = dades.tipus_pagament;
        props.item.id_banc = dades.id_banc;
        props.item.bic = dades.bic;
        props.item.iban = dades.iban;
        props.item.titular = dades.titular;
        props.item.mandat = dades.mandat;
        props.item.mandat_data = dades.mandat_data;
        props.item.fcrea = dades.fcrea;
      }else{
        setGuardant(false);
        console.error('Error: submit RebutsEdit: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: RebutsEdit.: ', error);
    }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY HH:mm';

const onTotalChange = (total) =>{
  setTotal(total)
  props.item.import = total;
}

const actualitzaImportEntregatTaula = (importe) =>{
  props.item.import_entregat = importe;
}

  return(
    <Form onFinish={submitForm}
      layout="vertical"
      autoComplete="off"
    >
    <Row gutter={10}>
      <Col span={6}>
        <Form.Item label={props.t('botiga.titolEstat')} style={{marginBottom: 0}}>
          <Select defaultValue={estat} onChange={(value) => setEstat(value)}>
            {estatsOptions}
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label={props.t('menu.Pressupost')} style={{marginBottom: 0}} required={true} validateStatus={errorId_pressupost} help={errorId_pressupostText}>
          <Select
            loading={loadingPressupost}
            value={id_pressupost===0 ? '' : id_pressupost}
            onChange={(value) => setId_pressupost(value)}
          >
            {pressupostosOptions}
          </Select>
        </Form.Item>
      </Col>
      <Col>
        <Form.Item label={props.t('generic.created_at')} required={true} validateStatus={errorFcrea}>
          <DatePicker
            disabled={estat === 'cobrat' ? true : false}
            onChange={changeFcrea}
            //value={creat ? moment(new Date(creat), dateFormat) : moment(new Date(), dateFormat)}
            //value={fcrea}
            value={moment(creat).tz('Europe/Madrid')}
            showTime={{ format: 'HH:mm' }}
            format={dateFormat}
            locale={localeDatePicker}
            />
        </Form.Item>
      </Col>
      <Col style={{paddingTop: '30px'}}>
        <TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>
      </Col>
    </Row>
  <Row>
    <Col span={12} style={{paddingRight: '0.5rem'}}>
      <Form.Item label={props.t('alumnes.familia')} required={true} validateStatus={errorId_Familia} help={errorId_FamiliaText} style={{marginBottom: 0}}>
        <Select disabled={estat === 'cobrat' ? true : false}
          value={id_familia===0 ? '' : id_familia} onChange={(value) => triaFamilia(value)}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
        >
          {familiesOptions}
        </Select>
      </Form.Item>
  </Col>
  <Col span={12} style={{paddingLeft: '0.5rem'}}>
      {id_familia <=0 ? null :
          <Form.Item label={props.t('generic.alumne')} required={true} validateStatus={errorId_Alumne} help={errorId_AlumneText} style={{marginBottom: 0}}>
            <Select disabled={estat === 'cobrat' ? true : false}
              value={id_alumne===0 ? '' : id_alumne} onChange={(value) => triaAlumne(value)}
              showSearch
              filterOption={(input, option) =>
                HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
              }
            >
              {alumnesOptions}
            </Select>
          </Form.Item>
      }
    </Col>
  </Row>
  <Row>
    <Col span={12} style={{paddingLeft: '0.5rem'}}>
      <Form.Item label={props.t('families.tipus_pagament')} required={true} validateStatus={errorTipusPagament} help={errorTipusPagamentText} style={{marginBottom: 0}}>
        <Select
          defaultValue={tipus_pagament}
          onChange={triaTipusPagament}
          style={{ width: 160}}
          disabled={estat === 'cobrat' ? true : false}
          showSearch
          filterOption={(input, option) =>
            HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
          }
          >
          <Select.Option key="BANC" value="BANC">{props.t('families.pagament_banc')}</Select.Option>
          <Select.Option key="TRANSFERENCIA" value="TRANSFERENCIA">{props.t('families.pagament_transferencia')}</Select.Option>
          <Select.Option key="EFECTIU" value="EFECTIU">{props.t('families.pagament_efectiu')}</Select.Option>
          <Select.Option key="TARGETA" value="TARGETA">{props.t('families.pagament_targeta')}</Select.Option>
          <Select.Option key="XEC_GUARDERIA" value="XEC_GUARDERIA">{props.t('families.pagament_xec_guarderia')}</Select.Option>
        </Select>
      </Form.Item>
    </Col>
    <Col span={12} style={{paddingLeft: '0.5rem'}}>
    <Form.Item>
      <span className="ant-form-item-label">
        <label>{props.t('families.pagament_unic_totes_quotes_any')}&nbsp;</label>
        <Switch
         disabled={true}
         checkedChildren={<CheckOutlined />}
         unCheckedChildren={<CloseOutlined />}
         defaultChecked={pagament_unic_totes_quotes_any}
         checked={pagament_unic_totes_quotes_any}
        />
      </span>
    </Form.Item>
    </Col>
  </Row>
  {tipus_pagament !== 'BANC' ? null :
  <>
    <Form.Item label={props.t('banc.titol')} required={true} validateStatus={errorId_banc} help={errorId_bancText} style={{marginBottom: 0}}>
      <Select disabled={estat === 'cobrat' ? true : false}
        value={id_banc===0 ? '' : id_banc} onChange={(value) => triaBanc(value)}
      >
        {bancsOptions}
      </Select>
    </Form.Item>
    <Form.Item label={props.t('banc.titular')} validateStatus={errorTitular} required={true}>
        <Input name="titular" placeholder={props.t('banc.titular')} onChange={(e) => setTitular(e.target.value)} value={titular} disabled={true}/>
    </Form.Item>
    <Row>
      <Col span={6} style={{paddingRight: '0.5rem'}}>
    <Form.Item label={props.t('banc.bic')}>
        <Input name="bic" placeholder={props.t('banc.bic')} onChange={(e) => setBic(e.target.value)} value={bic} disabled={true}/>
    </Form.Item>
    </Col>
    <Col span={6} style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
    <Form.Item label={props.t('banc.iban')} validateStatus={errorIban} required={true}>
        <Input name="iban" placeholder={props.t('banc.iban')} onChange={(e) => setIban(e.target.value)} value={iban} disabled={true}/>
    </Form.Item>
    </Col>
    <Col span={6} style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
    <Form.Item label={props.t('banc.mandat')} validateStatus={errorMandat} required={true}>
        <Input name="mandat" placeholder={props.t('banc.mandat')} onChange={(e) => setMandat(e.target.value)} value={mandat} disabled={true}/>
    </Form.Item>
    </Col>

    <Col span={6} style={{paddingLeft: '0.5rem'}}>
    <Form.Item label={props.t('banc.mandat_data')} required={true} validateStatus={errorMandat_data}>
      <DatePicker
        disabled={true}
        onChange={changeMandat_data}
        value={moment(mandat_data).tz('Europe/Madrid')}
        showTime={{ format: 'HH:mm' }}
        format={dateFormat}
        locale={localeDatePicker}
        />
    </Form.Item>
    </Col>
    </Row>
  </>
  }

  <Divider/>

  <Form.Item label={props.t('rebuts.concepte')} validateStatus={errorConcepte} required={true}>
    <TextAreaCount autoSize={{ minRows: 2, maxRows: 25 }} maxLength={640} name="concepte" placeholder={props.t('rebuts.concepte')} onChange={setConcepte} defaultValue={concepte} disabled={estat === 'cobrat' ? true : false}/>
  </Form.Item>


  {nou ? null :
    <RebutsLinies id_rebut={id} linies={linies} setTotal={onTotalChange} estat={estat}/>
  }
  {nou ? null :
    <RebutsEntregatCompte id={id} rebut_total={total} callback_entregat={actualitzaImportEntregatTaula}/>
  }

    <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
      <Col>
        <Button onClick={props.callback}>{props.t('generic.back')}</Button>
      </Col>
      <Col style={{marginLeft:'10px'}}>
        <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
      </Col>
    </Row>
    </Form>
  );

}
export default translate(Rebuts);
