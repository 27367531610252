import React from 'react';
import { Typography } from 'antd';

function TitolPagina(props){


    return(
      <Typography.Title
        level={props.level || 2}
        style={props.style || {marginTop:'1rem', marginLeft:'3rem'}}>
        {props.txt}
      </Typography.Title>
    );
}
export default TitolPagina;
