
import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Select, Button } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { Api } from '../helpers';

const entities = new AllHtmlEntities();

function TriaCursos(props){
  const [triats, setTriats] = useState(props.defaultValue || []);
  const [cursos, setCursos] = useState([]);
  const [cursosIds, setCursosIds] = useState([]);
  const [botonsCicles, setBotonsCicles] = useState([]);
/*
  const [cicles, setCicles] = useState([]);
  const [etapes, setEtapes] = useState([]);
*/
  //const [isLoading, setIsLoading] = useState(true);


  const buscarDades = async () => {
  //  setIsLoading(true);

    const c = await Api.get('/cursos');
    if(c.result.success===1){

      const ids = [];
      const llista_cursos = c.data.cursos.map((item) =>{
            ids.push(item.id);
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setCursosIds(ids);
      setCursos(llista_cursos);
    }

    const ci = await Api.get('/cursos/agrupats/cicle');
    if(ci.result.success===1){
        const btn_cicles = ci.data.map((item) =>{
          return(
            <Button key={'btn_'+item.id_cicle} onClick={()=>handleChange(item.curs_ids)} size="small">{item.nom}</Button>
          );
        });
        setBotonsCicles(btn_cicles);
    }



    //setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);


  const handleChange = (value) => {
    //console.log(`selected ${value}`);
    //console.log(value);
    setTriats(value);
    if(props.onChange){
      props.onChange(value);
    }
  }
  const btn_tots = () => {
    setTriats(cursosIds);
    if(props.onChange){
      props.onChange(cursosIds);
    }
  }
  const btn_cap = () => {
    setTriats([]);
    if(props.onChange){
      props.onChange([]);
    }
  }



    return(
      <>
      <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={props.t('generic.tria_varis')}
          showArrow={true}
          //defaultValue={triats}
          onChange={handleChange}
          value={triats}
        >
          {cursos}
        </Select>
        <Button onClick={()=>btn_tots()} size="small">{props.t('generic.all')}</Button>
        <Button onClick={()=>btn_cap()} size="small">{props.t('generic.none')}</Button>
        {botonsCicles}
      </>
    );
}
export default translate(TriaCursos);
