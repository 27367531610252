import React, { useContext, useEffect, useState } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';
import { Row, Col, Button, Radio, List, Input, Alert, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../../context/menu-triat";
import { Api, InitDades } from '../../../../helpers';
import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
//import DocumentUpload from "../../../DocumentUpload";
import DocumentUploadDelete from "../../../DocumentUploadDelete";
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function Medic(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

  const id_alumne = props.id_alumne;
  const [categories, setCategories] = useState(props.categories);

  const [medic, setMedic] = useState([]);
  const [missatge, setMissatge] = useState([]);
  const [modificats, setModificats] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [loadings, setLoadings] = useState([]);
  const [pantallaPetita, setPantallaPetita] = useState(window.innerWidth <= 580);

  const modal = props.modal ? props.modal : false;

  useEffect(() => {
    buscarMedicMissatge();
  },[]);


  useEffect(() => {
    buscarMedic();
  },[id_alumne]);

  useEffect(() => {
  window.addEventListener("resize", resize);

  return () => window.removeEventListener("resize", resize);
  }, []);

  const resize = () =>{
    setPantallaPetita(window.innerWidth <= 580); //760
  }


  const buscarMedicMissatge = async() =>{
    const miss = await Api.get('/medic/missatge');
    if(miss.result.success ===1){
      setMissatge(miss.data.missatge);
    }
  }



  const buscarMedic = async()  =>{
    setIsLoading(true);
    //console.log("id_alumne", id_alumne)
    if(id_alumne>0){
      const m = await Api.get(`/medic/alumne/${id_alumne}`);
      if(m.result.success===1){
        //console.log(`/medic/alumne/${id_alumne}`, m.data.medic)
        setMedic(m.data.medic);

        categories.forEach((cat, i) => {
          const temp = m.data.medic.find(m => m.categoria.id_categoria == cat.id);
          if(temp){
            let modi = modificats;
            modi.push({id_medic: temp.id, modificat: false, id_alumne: id_alumne, id_categoria: cat.id, nom: cat.nom, text: entities.decode(temp.text), fitxers_desats: temp.fitxers});

            setModificats(modi);
          }else{
            let modi = modificats;
            modi.push({id_medic: 0, modificat: false, id_alumne: id_alumne, id_categoria: cat.id, nom: cat.nom, text: '', fitxers_desats: []});
            setModificats(modi);
          }

        });
        //console.log(modificats)

      }
    }
    setIsLoading(false);
  }


  const onChangeInput = (text, id_categoria) => {
    const nous = modificats.map(el => el.id_categoria == id_categoria ? {...el, modificat: true, text: text} : el);
    setModificats(nous);
    //console.log(nous);
  }

  const fileChange = (info) => {
    //console.log('fileChange: ', info)
    //Si es puja un fitxer per primer cop en una categoria, el registre encara no te id. Aquesta funció es crida un cop pujat el fitxer i ja tenim un id_medic. Es el que retorna `/medic/alumne/${id_alumne}/categoria/${item.id_categoria}`
    if(info.file.response.data.id_medic){
      const nous = modificats.map(el => el.id_categoria == info.file.response.data.id_categoria ? {...el, id_medic: info.file.response.data.id_medic, fitxers_desats: info.file.response.data.fitxers } : el);
      setModificats(nous);
    }
  }


  const enterLoading = (index) =>{
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  }

  const leaveLoading = index =>{
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  }


  const guardarText = async (id_categoria) =>{

    const registre = modificats.find((element, index) => element.id_categoria === id_categoria);

    if(!registre){
      return false;
    }

    enterLoading(id_categoria);

    let dades = {
      id_alumne,
      id_categoria: registre.id_categoria,
      text: entities.encode(registre.text)
    }

    try{
      const resposta = await Api.myFetch('/medic/alumne', 'POST', JSON.stringify(dades));

      if(resposta.result.success === 1){
        //console.log('resposta',resposta)
        leaveLoading(id_categoria);
        message.success(props.t('generic.OK_saved'), 5);
        //setMenuTriat("Dashboard");
      }else{
        leaveLoading(id_categoria);
        console.error('Error: submit FormFamilies: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      leaveLoading(id_categoria);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Familia guardar: ', error);
    }
  }






  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
      <div style={{marginTop:'0.5rem'}}>
      <Alert
      style={{marginBottom:'0.5rem'}}
      message={props.t('generic.info')}
      description={missatge[props.t('idioma')]}
      type="info"
      showIcon
    />


        <List
          bordered={true}
          itemLayout={pantallaPetita ? "horizontal" : "vertical"}
          dataSource={modificats}
          renderItem={item => {

            const llista_fitxers = item.fitxers_desats.map( (doc, index)=>{
            //return({uid: -1, status: 'done', url: doc.url, name: doc.nom, path: doc.url});
              return({uid: index, status: 'done', url: doc.url, name: doc.nom, path: doc.url, s3key: doc.s3key});
            })

            const input_old = (
              <Input onChange={(e) => onChangeInput(e.target.value, item.id_categoria)} defaultValue={item.text} placeholder={props.t('medic.text_lliure')}/>
            )
            const input =(
              <TextAreaCount
                marginTopCount='-0.1rem'
                autoSize={modal ? { minRows: 2, maxRows: 25 }: { minRows: 4, maxRows: 25 }}
                maxLength={500}
                placeholder={props.t('medic.text_lliure')}
                onChange={(valor) => onChangeInput(valor, item.id_categoria)}
                defaultValue={item.text}/>
            )
          //  console.log('item: ', item)
            return (

            <List.Item
              extra={
                <div style={{marginTop: 10, marginLeft: 15, marginBottom:10, marginRigth:10}}>
                <DocumentUploadDelete
                  padding={{padding:'10px'}}
                  fileList={llista_fitxers}
                  url_upload={`/medic/alumne/${id_alumne}/categoria/${item.id_categoria}`}
                  url_delete={item.id_medic > 0 ? `/medic/document/${item.id_medic}` : null}
                  changeCallback={fileChange}
                  titol={props.t('medic.pujar_doc1')}
                  subtitol={props.t('medic.pujar_doc2')}
                  />
                </div>
              }
              actions={[<Button type="primary" loading={loadings[item.id_categoria]} onClick={() => guardarText(item.id_categoria)}>{props.t('generic.save')}</Button>,]}
            >


              <List.Item.Meta
                title={<span>{entities.decode(item.nom[props.t('idioma')])}</span>}
                description={<span>{input}</span>}
              />
            </List.Item>


          )}}
        />
      </div>
  );
}
export default translate(Medic);
