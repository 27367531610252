import React, {useState, useEffect} from 'react';
import moment from 'moment';
import 'moment/locale/ca';

import { translate } from 'react-multi-lang';
import { Table, Col, Tag, Tooltip, Button, message, notification } from 'antd';
import { GoldOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../components/TitolPagina';
import LlistaEditable from '../../components/LlistaEditable';
import Edit from '../../components/Formularis/Botiga';
import { Api, InitDades } from '../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';

function Producte(props) {

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [refrescar, setRefrescar] = useState(0)

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);


  const buscarDades = async () => {
    const fam = await Api.get(`/botiga/categoria/${props.id_categoria}/${id_curs_escolar}`);
    if(fam.result.success===1){

      if(props.id_categoria == 1 || props.id_categoria == 2){
        const c = await Api.get('/cursos');
        if(c.result.success===1){
          const cursos = c.data.cursos.reduce((acumulat, item) => ({
            ...acumulat,
            [item.id]: item.nom,
          }),{});

          fam.data.productes.forEach((item, i) => {
            const tags = item.cursos.map((curs) => {
              return <Tag>{cursos[curs]}</Tag>;
            });
            item.cursos_tags = tags;
          });

        }
      }


      console.log('Productes productes: ', fam.data.productes);
      return fam.data.productes;
    }
  }
  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/botiga/producte/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  const setProducteVisible = async (id, visible) => {
    const dades = {
      visible
    }
    try{
      const fam = await Api.myFetch(`/botiga/visible/${id}`, 'PUT', JSON.stringify(dades));
      if(fam.result.success===1){
        setRefrescar(refrescar+1)
        return true
      }else{
        notification.error({
          message: 'Error',
          description: 'Visible no modificat',
          placement: 'bottomRight',
          duration: 20,
        });
      }
    }catch(error){
      message.error({content: "Error visible", duration:10});
      console.error('Error: Producte visible: ', error);
    }
    return false;
  }

  const setPropietatVisible = async (id, visible) => {
    const dades = {
      visible
    }
    try{
      const fam = await Api.myFetch(`/botiga/propietat/visible/${id}`, 'PUT', JSON.stringify(dades));
      if(fam.result.success===1){
        setRefrescar(refrescar+1)
        return true
      }else{
        notification.error({
          message: 'Error',
          description: 'Visible no modificat',
          placement: 'bottomRight',
          duration: 20,
        });
      }
    }catch(error){
      message.error({content: "Error visible", duration:10});
      console.error('Error: Talles visible: ', error);
    }
    return false;
  }


const columnesTaulaPropietats = [
    {
      title:"Id",
      key:"id_propietat",
      dataIndex: "id_propietat"
    },
    {
      title: props.t('generic.visible'),
      dataIndex: "visible",
      key: "visible",
      render: (text,record) => (
          record.visible
            ? <Button type="link" onClick={ e => setPropietatVisible(record.id_propietat, 0)}><Tooltip placement="top" title={props.t('generic.visible')}><EyeOutlined style={{color: 'green', fontSize: 24}}/></Tooltip></Button>
            : <Button type="link" onClick={ e => setPropietatVisible(record.id_propietat, 1)}><Tooltip placement="top" title={props.t('generic.no_visible')}><EyeInvisibleOutlined style={{color: 'red', fontSize: 24}}/></Tooltip></Button>
      )

    },
    {
      title: props.t('botiga_propietats.titol'),
      dataIndex: "text",
      key: "text",
      render: (text,record) => <span>{entities.decode(record.text[props.idioma])}</span>

    },
    { title: props.t('botiga_propietats.preu'), dataIndex: "preu", key: "preu",
      render: (text,record) =><span>{record.preu}€</span>}
    /*,{
      key: "actions",
      align: "right",
      width: "95px",
      render: (text,record) => (
        <Row type="flex" justify="end" id={"actions_"+record.id}>
          <Col span={12}>
            <Tooltip placement="top" title={props.t('generic.modify')}>
              <Button icon="edit" onClick={(e) => EditPropietat(record)} ghost="true" className="BotoTaula"/>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Tooltip placement="top" title={props.t('generic.delete')}>
              <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>submitDelete(record.id)}>
                <Button icon="delete" data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
              </Popconfirm>
            </Tooltip>
          </Col>
        </Row>
    )
  }*/
  ];


let itemBuit = InitDades.botigaProducte();
itemBuit.id_categoria = props.id_categoria;
switch (props.id_categoria) {
  case 1: //Roba
    itemBuit.id_tipus_propietats = 1; //Tria un
    break;
  case 2: //Excursions
    itemBuit.id_tipus_propietats = 0; //Sense propietats
    break;
  case 3: //Esdeveniments
    itemBuit.id_tipus_propietats = 3;//Unitats per propietat
    break;
  case 4: //Summer Camp
    itemBuit.id_tipus_propietats = 2;//Tria varies
    break;
  default:
  itemBuit.id_tipus_propietats = 0; //Sense propietats
  break;
}


//return <Col span={12} style={{margin: 0, padding: 0}}><Table style={{backgroundColor: '#eeeeee'}} columns={columnesTaulaPropietats} dataSource={row.propietats} pagination={false} size={'small'} bordered={false}/></Col>;
const subTaulaPropietats = row => {
  return <Col span={12} style={{margin: 0, padding: 0}}><Table rowKey="id_propietat" columns={columnesTaulaPropietats} dataSource={row.propietats} pagination={false} size={'small'} bordered={false} /></Col>;
};

  //console.log(props.id_categoria);
  return(
    <>
      <TitolPagina txt={entities.decode(props.titol[props.idioma])} level={4} style={{marginTop:'1rem', marginLeft:'0.5rem', paddingLeft:'0.5rem'}}/>
      <div style={{marginTop:'1rem', marginLeft:'0.5rem'}}>
        <LlistaEditable
          rowKey="id"
          id_curs_escolar={id_curs_escolar}
          expandedRowRender={props.id_categoria === 2 ? null : subTaulaPropietats}
          txt_creats={props.t('botiga.creados')}
          icon_creats={GoldOutlined}
          txt_nou={props.t('botiga.nuevo')}
          buscarDades={buscarDades}
          duplica={true}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={itemBuit}
          refrescar={refrescar}>
          <Column
          title="Id"
          key="id"
          sorter={ (a, b) =>(a.id - b.id)}
          defaultSortOrder={(props.id_categoria===2) ? "descend" : null}
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />

          <Column
          title={props.t('generic.visible')}
          key="visible"
          sorter={ (a, b) =>(a.visible - b.visible)}
          render={(text,record) =>(
              record.visible
                ? <Button type="link" onClick={ e => setProducteVisible(record.id, 0)}><Tooltip placement="top" title={props.t('generic.visible')}><EyeOutlined style={{color: 'green', fontSize: 24}}/></Tooltip></Button>
                : <Button type="link" onClick={ e => setProducteVisible(record.id, 1)}><Tooltip placement="top" title={props.t('generic.no_visible')}><EyeInvisibleOutlined style={{color: 'red', fontSize: 24}}/></Tooltip></Button>
          )}
          />
          { props.id_categoria !== 1 ? null :

            <Column
            title={props.t('botiga.ref')}
            key="ref"
            ellipsis={true}
            sorter={(a, b) =>  entities.decode(a.ref).localeCompare(entities.decode(b.ref))}
            render={(text,record) =>(
              entities.decode(record.ref)
            )}
            />
          }

          <Column
          title={''}
          ellipsis={true}
          key="url_image"
          render={(text,record) =>(
            !record.url_image
              ? null
              : <img height={50} src={record.url_image+"?"+Number(new Date(record.updated_at))} alt="url_image"/>
          )}
          />
          <Column
          title={props.t('botiga.nom')}
          ellipsis={true}
          key="nom"
          sorter={(a, b) =>  entities.decode(a.nom[props.idioma]).localeCompare(entities.decode(b.nom[props.idioma]))}
          render={(text,record) =>(
            entities.decode(record.nom[props.idioma])
          )}
          />
        { props.id_categoria > 2 ? null :

          <Column
          title={props.t('generic.curs')}
          key="cursos"
          ellipsis="true"
          render={(text,record) =>(
            <span>{record.cursos_tags}</span>
          )}
          />
        }
        { props.id_categoria !== 2 ? null :

          <Column
          title={props.t('botiga.preu')}
          key="preu"
          sorter={ (a, b) =>(a.preu - b.preu)}
          render={(text,record) =>(
            <span>{record.preu}€</span>
          )}
          />
        }
        { props.id_categoria !== 2 ? null :
          <Column
          title={props.t('botiga.descompte')}
          key="descompte"
          sorter={ (a, b) =>(a.descompte - b.descompte)}
          render={(text,record) =>(
            <span>{record.descompte}%</span>
          )}
          />
        }
      { [2,3].includes(props.id_categoria) === false ? null :
          <Column
          title={props.t('botiga.data')}
          key="data_hora_esdeveniment"
          sorter={ (a, b) => moment(a.data_hora_esdeveniment).diff(moment(b.data_hora_esdeveniment))}
          render={(text,record) =>(
            record.data_hora_esdeveniment ? moment(record.data_hora_esdeveniment).format(dateFormat) : ''
          )}
          />
        }
          <Column
          title={props.t('botiga.fini')}
          key="fini"
          sorter={ (a, b) => moment(a.fini).diff(moment(b.fini))}
          defaultSortOrder={(props.id_categoria===3 ) ? "descend" : null}
          render={(text,record) =>(
            record.fini ? moment(record.fini).format(dateFormat) : ''
          )}
          />

          <Column
          title={props.t('botiga.ffin')}
          key="ffin"
          sorter={ (a, b) => moment(a.ffin).diff(moment(b.ffin))}
          render={(text,record) =>(
              record.ffin ? moment(record.ffin).format(dateFormat) : ''
          )}
          />


        </LlistaEditable>
      </div>
    </>
  );

}

export default translate(Producte);
