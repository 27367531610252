import React, {useEffect, useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Select, Input, Space, Button, Row, Col } from 'antd';
import { SearchOutlined, AlertFilled } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import InformeIngressosExportaExcel from '../../../components/Formularis/perfils/admin/InformeIngressosExportaExcel';
import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function InformeIngressos(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [id_pressupost, setId_pressupost] = useState(0);
  const [selectPressupostos, setSelectPressupostos] = useState([]);
  const [pressupostosDesc, setPressupostosDesc] = useState([]);
  const [llista, setLlista] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPressupost, setLoadingPressupost] = useState(true);
  const [filtresClasses, setFiltresClasses] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [carpetaIsLoading, setCarpetaIsLoading] = useState(false);
  const [carpetaActual, setCarpetaActual] = useState(InitDades.carpeta());

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA
  //filtres i ordenacio
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const [estatsOptions, setEstatsOptions] = useState([]);

  useEffect(() => {
    buscarDadesPressupost();
  },[]);


  useEffect(() => {
    buscarDades();
  },[id_pressupost]);



  const tableHandleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  }
  const clearAllFilters = () => {
    setFilteredInfo({});
    setSortedInfo({});
  }

  useEffect(() => {
    muntarSelectEstat();
    muntarFiltreClasses();
  },[]);


  const muntarFiltreClasses = async() =>{
    const cla = await Api.get('/classes');
    if(cla.result.success!==1){return [];}
    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    setFiltresClasses(filtreCl);
  }



  const buscarDadesPressupost = async () =>{
    setLoadingPressupost(true);
    const c = await Api.get('/pressupost/'+perfilUsuari.curs_escolar_actual.id);
    if(c.result.success===1){

      const facturats = c.data.pressuposts.filter((element, index, arr) => element.facturat === true);
        console.log('des: ', facturats)
      let pressus = [];
      let ultim_pressu = 0;
      const select = facturats.map((item)=>{
        pressus[item.id] = item.descripcio;
        if(item.id > ultim_pressu) {
          ultim_pressu = item.id
        }
        return(
          <Select.Option key={item.id} value={item.id}>
            <span>{entities.decode(item.descripcio)}</span>
          </Select.Option>
        );
      });
      setSelectPressupostos(select);
      setId_pressupost(ultim_pressu);
      setLoadingPressupost(false);
      setPressupostosDesc(pressus);
    }
  }

  const buscarDades = async () => {
    setIsLoading(true);
    const e = await Api.get(`/llistat/ingresostipus/${id_pressupost}`);
    if(e.result.success===1){
      setLlista(e.data.ingresos);
    }
    setIsLoading(false);
  }


  const triaPressupostChange = (value) => {
    setId_pressupost(value);
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA




  const tradueixTipusPagament = (value) =>{
    switch (value) {
      case 'BANC':
        return props.t('families.pagament_banc');
        break;
      case 'TRANSFERENCIA':
        return props.t('families.pagament_transferencia');
        break;
      case 'EFECTIU':
        return props.t('families.pagament_efectiu');
        break;
      case 'TARGETA':
        return props.t('families.pagament_targeta');
        break;
      case 'XEC_GUARDERIA':
        return props.t('families.pagament_xec_guarderia');
        break;
      default:
        return '--';

    }
  }

  const filtreTipusPagament =[
    {text: <span>{props.t('families.pagament_banc')}</span>, value: 'BANC'},
    {text: <span>{props.t('families.pagament_transferencia')}</span>, value: 'TRANSFERENCIA'},
    {text: <span>{props.t('families.pagament_efectiu')}</span>, value: 'EFECTIU'},
    {text: <span>{props.t('families.pagament_targeta')}</span>, value: 'TARGETA'},
    {text: <span>{props.t('families.pagament_xec_guarderia')}</span>, value: 'XEC_GUARDERIA'},

  ];

  const muntarSelectEstat = () =>{

    let estats = [
      'revisio',
      'pendent',
      'enviat',
      'rebutjat',
      'cobrat'
    ];

    const llista_estats = estats.map((valor) =>{
      return(
        <Select.Option key={valor} value={valor}>
          <span><AlertFilled style={{color: `${props.t(`rebuts.estatColor.${valor}`)}`}}/>&nbsp;{props.t(`rebuts.estat.${valor}`)}</span>
        </Select.Option>
      );
    });
    setEstatsOptions(llista_estats);
  }

  const filtreEstat =[
    {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.revisio')}`}}/>&nbsp;{props.t('rebuts.estat.revisio')}</span>, value: 'revisio'},
    {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.pendent')}`}}/>&nbsp;{props.t('rebuts.estat.pendent')}</span>, value: 'pendent'},
    {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.enviat')}`}}/>&nbsp;{props.t('rebuts.estat.enviat')}</span>, value: 'enviat'},
    {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.rebutjat')}`}}/>&nbsp;{props.t('rebuts.estat.rebutjat')}</span>, value: 'rebutjat'},
    {text: <span><AlertFilled style={{color: `${props.t('rebuts.estatColor.cobrat')}`}}/>&nbsp;{props.t('rebuts.estat.cobrat')}</span>, value: 'cobrat'}
  ];


  const columnes_detall = [
      {
        title: 'id_rebut',
        dataIndex: "id_rebut",
        key: "id_rebut"
      },
      {
        title: 'id_linia',
        dataIndex: "id",
        key: "id"
      },
      {
        title: props.t('generic.concepte'),
        dataIndex: "concepte",
        key: "concepte",
        render: (text,record) => <span>{record.concepte_t}</span>
      },
      {
        title: props.t('generic.alumne'),
        key: "alumne",
        dataIndex: "alumne",
        render: (text,record) => (entities.decode(record.alumne))
      },
      {
        title: props.t('alumnes.classe'),
        key:"classe",
        dataIndex: 'classe',
        render: (text,record) =>(entities.decode(record.classe)),
      },
      {
        title: props.t('banc.titular'),
        key: "titular",
        dataIndex: "titular",
        render: (text,record) => (entities.decode(record.titular)),
      },
      {
        title: props.t('families.tipus_pagament'),
        key: "tipus_pagament",
        width: "175px",
        dataIndex: "tipus_pagament",
        render: (text,record) => (tradueixTipusPagament(record.tipus_pagament)),
      },
      {
        title: props.t('generic.preu'),
        dataIndex: "preu",
        key: "preu",
        render: (text,record) => <span>{record.preu}€</span>

      },
      {
        title: props.t('generic.unitats'),
        dataIndex: "unitats",
        key: "unitats"
      },
      {
        title: props.t('generic.descompte'),
        dataIndex: "descompte",
        key: "descompte",
        render: (text,record) => <span>{record.descompte}%</span>
      },
      {
        title: props.t('banc.iban_percentatge_curt'),
        dataIndex: "participacio",
        key: "participacio",
        render: (text,record) => <span>{record.participacio}%</span>
      },
      {
        title: props.t('generic.subtotal'),
        dataIndex: "subtotal",
        key: "subtotal",
        render: (text,record) => <span>{record.subtotal}€</span>

      },
      {
        title: props.t('botiga.titolEstat'),
        key:"estat",
        dataIndex: 'estat',
        render: (text,record) =>{
          return (<span><AlertFilled style={{color: `${props.t(`rebuts.estatColor.${record.estat}`)}`}}/>&nbsp;{props.t(`rebuts.estat.${record.estat}`)}</span>);
        },
      },

    ];

    const onExpandDetall = async (expanded, record) =>{
      if(expanded){
        setCarpetaActual(record)
        let expan = expandedRowKeys;
        expan.push(record.id);
        setExpandedRowKeys(expan);
      }else{
        const expan = expandedRowKeys.filter((element, index, arr) => element !== record.id);
        setExpandedRowKeys(expan);
      }

      await buscarDetall(record.id, record.tipus);

    }

    const buscarDetall = async(id_taula, tipus) =>{
      setCarpetaIsLoading(true);

      const detall = await Api.get(`/llistat/ingresostipus/detall/${id_pressupost}/${tipus}`);
      if(detall.result.success===1){
        const detall_t = detall.data.detall.map((item)=>{
          item.alumne_t = entities.decode(item.alumne);
          item.classe_t = entities.decode(item.classe);
          item.titular_t = entities.decode(item.titular);
          item.concepte_t = entities.decode(item.concepte);
          return item;
        });

        const llista_t = llista.map((item) =>{
          if(item.id === id_taula){
            item.detall = detall_t;
          }
          return item;
        });

        setLlista(llista_t);
      }
      setCarpetaIsLoading(false);
    }

    const subTaulaDetall = row => {
      return (
        <div>
        <Row style={{margin: '1rem'}}>
          <Col span={22}>
            <Table
              loading={carpetaIsLoading && carpetaActual.id == row.id}
              rowKey="id"
              columns={columnes_detall}
              dataSource={row.detall}
              pagination={false}
              size={'small'}
              bordered={false}/>
            </Col>
        </Row>
        </div>
      );
    };

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.InformeIngressos')}/>

      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      { !selectPressupostos ? null :
        <Select
            loading={loadingPressupost}
            style={{width: 300}}
            placeholder={props.t('generic.tria')}
            onChange={triaPressupostChange}
            defaultValue={""}
            value={id_pressupost}
          >
            {selectPressupostos}
        </Select>
        }
        <Table
          style={{marginTop: '0.5rem'}}
          rowKey={"id"}
          loading={isLoading}
          locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
          dataSource={llista}
          expandedRowRender={subTaulaDetall}
          defaultExpandAllRows={false}
          expandedRowKeys={expandedRowKeys}
          onExpand={(expanded, record) => onExpandDetall(expanded, record)}
          pagination={false}
          summary={(dadesMostrades) => {
            let sumaSubtotals = 0.0;
            dadesMostrades.forEach((item) => {
              sumaSubtotals += parseFloat(item.import);
            });
            return(
              <>
              <Table.Summary.Row>
                <Table.Summary.Cell><b>Total:</b></Table.Summary.Cell>
                <Table.Summary.Cell><b>{parseFloat(sumaSubtotals).toFixed(2)}€</b></Table.Summary.Cell>
              </Table.Summary.Row>
              <div style={{marginTop: '1rem'}}>
                <InformeIngressosExportaExcel dades={dadesMostrades} nom_fitxer={'InformeIngressos_'+pressupostosDesc[id_pressupost]} nom_fulla={pressupostosDesc[id_pressupost]} btn_txt="Export"/>
              </div>
              </>
            )
          }}
          >
          <Table.Column
          title={props.t('generic.concepte')}
          key="tipus"
          render={(text,record) => (
            <span>{props.t(`rebuts.tipus.${record.tipus}`)}</span>
          )}
          />

          <Table.Column
          title={props.t('quotes.import')}
          key="import"
          render={(text,record) =>(
            <span>{parseFloat(record.import).toFixed(2)}€</span>
          )}
          />

          <Table.Column
          title={props.t('families.pagament_banc')}
          key="banc"
          render={(text,record) =>(
            <span>{parseFloat(record.banc).toFixed(2)}€</span>
          )}
          />
          <Table.Column
          title={props.t('families.pagament_efectiu')}
          key="efectiu"
          render={(text,record) =>(
            <span>{parseFloat(record.efectiu).toFixed(2)}€</span>
          )}
          />
          <Table.Column
          title={props.t('families.pagament_transferencia')}
          key="transferencia"
          render={(text,record) =>(
            <span>{parseFloat(record.transferencia).toFixed(2)}€</span>
          )}
          />
          <Table.Column
          title={props.t('families.pagament_xec_guarderia')}
          key="xec_guarderia"
          render={(text,record) =>(
            <span>{parseFloat(record.xec_guarderia).toFixed(2)}€</span>
          )}
          />
          <Table.Column
          title={props.t('families.pagament_targeta')}
          key="targeta"
          render={(text,record) =>(
            <span>{parseFloat(record.targeta).toFixed(2)}€</span>
          )}
          />

//'BANC', 'EFECTIU', 'TRANSFERENCIA', 'XEC_GUARDERIA', 'TARGETA'

        </Table>
      </div>
    </ContainerPagina>
  );

}

export default translate(InformeIngressos);
