import React, {useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/Acollida';
import { Api, InitDades } from '../../../helpers';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function Acollida(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);
  const [refrescar, setRefrescar] = useState(0);


  const buscarDades = async () => {
    const a = await Api.get(`/acollida/curs/${cursEscolarTriat}`);
    if(a.result.success!==1){return [];}
    return a.data.acollides;
  }

  const marcarEliminat = async (id) => {
    const a = await Api.myFetch('/acollida/'+id, 'DELETE');
    if(a.result.success===1){

    }

  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('acollida.titol')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          id_curs_escolar={cursEscolarTriat}
          btn_extra={<TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>}
          txt_creats={props.t('acollida.creados')}
          txt_nou={props.t('acollida.nuevo')}
          buscarDades={buscarDades}
          refrescar={refrescar}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.acollida()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />
          <Column
          title={props.t('acollida.ordre')}
          align="center"
          key="ordre"
          render={(text,record) =>(
            record.ordre
          )}/>
          <Column
          title={props.t('acollida.tipus')}
          key="tipus"
          render={(text,record) =>(
            <span>{record.tipus === 'puntual' ? props.t('acollida.puntual') : props.t('acollida.mensual')}</span>
          )}/>
          <Column
          title={props.t('acollida.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom[props.t('idioma')])
          )}/>
          <Column
          title={props.t('acollida.descompte')}
          align="center"
          key="descompte"
          render={(text,record) =>(
              `${record.descompte}%`
          )}
          />

        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Acollida);
