import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { LeftOutlined } from '@ant-design/icons';
import { Popconfirm, Form, Row, Col, DatePicker, Switch, Button, Input, InputNumber, Select, Alert, message } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import TriaCursos from '../../../TriaCursos';

const entities = new AllHtmlEntities();


function Edit(props){


  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const id = props.item.id;
  const tipus_quota = props.item.tipus_quota;
  const [id_tipus_quota] = useState(props.item.id_tipus_quota);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [preu, setPreu] = useState(props.item.preu);
  const [descompte_2on, setDescompte_2on] = useState(props.item.descompte_2on);
  const [descompte_3rs, setDescompte_3rs] = useState(props.item.descompte_3rs);
  const [text_ca, setText_ca] = useState(entities.decode(props.item.text['ca']));
  const [text_en, setText_en] = useState(entities.decode(props.item.text['en']));
  const [text_es, setText_es] = useState(entities.decode(props.item.text['es']));

  const [cursos, setCursos] = useState(props.item.cursos || []);

  const [guardant, setGuardant] = useState(false);

  const [errorPreu, setErrorPreu] = useState('');
  const [errorTriaCursos, setErrorTriaCursos] = useState('');
  const [errorTriaCursosText, setErrorTriaCursosText] = useState('');

  const nou = (props.item.id===0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);    
  },[props.id_curs_escolar]);

  const triaCursosChange = (value) =>{
    setCursos(value);
    if(value.length>0){
      setErrorTriaCursos('');
      setErrorTriaCursosText('');
    }
  }

  const onPreuChange = (value) =>{
    setPreu(value);
    if(preu>0){
      setErrorPreu('');
    }
  }

  const submitForm = async (values) => {

      let correcte = true;

      /*if(preu<=0){
        setErrorPreu('error');
        correcte = false;
      }*/

      if(cursos.length === 0){
        setErrorTriaCursos('error');
        setErrorTriaCursosText(props.t('generic.tria_varis'));
        correcte = false;
      }

      if(!correcte){return false}

      setGuardant(true);


      let dades = {
        id_curs_escolar: id_curs_escolar,
        id,
        id_tipus_quota,
        ordre,
        preu,
        descompte_2on,
        descompte_3rs,
        text_ca,
        text_en,
        text_es,
        cursos,
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/quotes' : '/quotes/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormQuotesPreus: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: FormQuotesPreus guardar: ', error);
      }

  }


  return (
  <>
    <div style={{marginTop:'-2.5rem', marginLeft:'-1.2rem'}}>
      <LeftOutlined className="BotoBack" onClick={props.callback}/>
    </div>
    <div style={{padding:'10px 24px 35px 0px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:2}}
        wrapperCol={{span:22}}
        colon={true}
        autoComplete="off"
      >
      <Form.Item
        colon={true}
        label={props.t('acollida_propietats.ordre')}
      >
          <InputNumber name="ordre" onChange={(value)=>setOrdre(value)} defaultValue={ordre} min={0} step={1} />
      </Form.Item>

      <Form.Item
        label={props.t('quotes.text')+' CA'}
        labelCol={{span:2}}
        wrapperCol={{span:5}}
      >
          <Input name="text_ca" placeholder={props.t('quotes.text')} onChange={(e)=>setText_ca(e.target.value)} defaultValue={text_ca}/>
      </Form.Item>

      <Form.Item
        label={props.t('quotes.text')+' EN'}
        labelCol={{span:2}}
        wrapperCol={{span:5}}
      >
          <Input name="text_en" placeholder={props.t('quotes.text')} onChange={(e)=>setText_en(e.target.value)} defaultValue={text_en}/>
      </Form.Item>

      <Form.Item
        label={props.t('quotes.text')+' ES'}
        labelCol={{span:2}}
        wrapperCol={{span:5}}
      >
          <Input name="text_es" placeholder={props.t('quotes.text')} onChange={(e)=>setText_es(e.target.value)} defaultValue={text_es}/>
      </Form.Item>

      <Form.Item style={{marginBottom: 0}}
        validateStatus={errorTriaCursos}
        help={errorTriaCursosText}
        label={props.t('botiga.tria_curs')}
        required={true}
      >
        <TriaCursos defaultValue={cursos} onChange={triaCursosChange}/>
      </Form.Item>


      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

      <Col offset={2} span={22} style={{marginTop: '1rem', marginBottom:'1rem'}}>
        <Alert
          message={props.t('quotes.pagament_titol')}
          description={props.t(`quotes.tipus_pagament.${tipus_quota.pagament}`)}
          type="info"
          showIcon
        />
      </Col>

        <Col offset={2} span={3} key='preu'>
          <Form.Item style={{marginBottom: 10}}
            validateStatus={errorPreu}
            colon={true}
            label={props.t('quotes.import')}
            labelCol={{span:12}}
            wrapperCol={{span:12}}
            required={true}
          >
              <InputNumber name="preu" onChange={onPreuChange} defaultValue={preu}
              min={0.0} step={1.0}
              formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/€\s?|(,*)/g, '')}
              />
          </Form.Item>
        </Col>
        <Col span={6} key='descompte_2on'>
          <Form.Item style={{marginBottom: 10}}
            colon={true}
            label={props.t('quotes.descompte_2on')}
            labelCol={{span:19}}
            wrapperCol={{span:5}}
          >
              <InputNumber name="descompte_2on" onChange={setDescompte_2on} defaultValue={descompte_2on}
              min={-100} max={100} step={0.1}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
              />
          </Form.Item>
        </Col>
        <Col span={7} key='descompte_3rs'>
          <Form.Item style={{marginBottom: 10}}
            colon={true}
            label={props.t('quotes.descompte_3rs')}
            labelCol={{span:19}}
            wrapperCol={{span:5}}
          >
              <InputNumber name="descompte" onChange={setDescompte_3rs} defaultValue={descompte_3rs}
              min={-100} max={100} step={0.1}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
              />
          </Form.Item>
        </Col>
        </Row>



        <Row type="flex" justify="start" style={{marginTop: '2rem'}}>
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
          {preu<=0
            ?
            <Popconfirm placement="top" title={props.t('quotes.preuZero')} onConfirm={submitForm} okText={props.t('generic.si')} cancelText={props.t('generic.no')}>
              <Button type="primary" loading={guardant}>{props.t('generic.save')}</Button>
            </Popconfirm>
            :
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            }
          </Col>
        </Row>
      </Form>
    </div>
  </>
  );
}
export default translate(Edit);
