import React from 'react';
import { translate } from 'react-multi-lang';
import { Tabs } from 'antd';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import { AllHtmlEntities } from 'html-entities';

import Acollida from '../../../components/Formularis/perfils/profe/Acollida';

const entities = new AllHtmlEntities();

function AcollidaPestanyes(props){

const pestanyaDefecte = () =>{
  const d = new Date();
  const hora = d.getHours();
  let retornar = "1";
  if(hora > 12){ retornar = "2";}
  console.log(retornar)
  return retornar;

}


  return(
    <>
      <Tabs defaultActiveKey={pestanyaDefecte()} style={{padding: 10}}>
        <Tabs.TabPane tab='Morning Club' key="1">
          <Acollida tipus={'mati'}/>
        </Tabs.TabPane>
        <Tabs.TabPane tab='Afternoon club' key="2">
          <Acollida tipus={'tarda'}/>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default translate(AcollidaPestanyes);
