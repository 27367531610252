import React from 'react';
import { translate } from 'react-multi-lang';
import { Table, Divider } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/AcollidaPropietats';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function AcollidaPropietats(props) {


  const buscarDades = async () => {
    const trans = await Api.get(`/acollida/propietats/${props.id_acollida}`);
    if(trans.result.success!==1){return [];}
    return trans.data.propietats;
  }

  const eliminarDelTot = async (id) => {
    const a = await Api.myFetch('/acollida/propietat/'+id, 'DELETE');
    if(a.result.success===1){

    }

  }

  let itemBuit = InitDades.acollida_propietats();
  itemBuit.id_acollida = props.id_acollida;

  return(
    <div style={{marginTop:'2rem'}}>
      <Divider orientation="left">
          {props.t('acollida_propietats.titol')}
      </Divider>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          rowKey="id"
          txt_creats={props.t('acollida_propietats.creados')}
          txt_nou={props.t('acollida_propietats.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={eliminarDelTot}
          itemBuit={itemBuit}>
          <Column
          title={props.t('acollida_propietats.ordre')}
          key="ordre"
          render={(text,record) => (
            <span>{record.ordre}</span>
          )}
          />
          <Column
          title={props.t('acollida_propietats.text')}
          key="text"
          render={(text,record) =>(
            entities.decode(record.text[props.t('idioma')])
          )}/>
          <Column
          title={props.t('acollida_propietats.preu')}
          key="preu"
          render={(text,record) =>(
              `${record.preu}€`
          )}/>


        </LlistaEditable>
      </div>
    </div>
  );

}

export default translate(AcollidaPropietats);
