import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Checkbox, Space, Form, message } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

const buscaComprats = (propietats) => {
  let temp = [];
  propietats.map((p) =>{
    if(p.comprat){
      temp.push(p.id_propietat);
    }
  });
  return temp;
}

function SummerCamp(props){

  //console.log('Dins edita summer',props.item);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [producte, setProducte] = useState(props.item);
  const [imatge, setImatge] = useState(props.item.url_image || "https://via.placeholder.com/443x249?text=NO+IMAGE+ASSIGNED!!");
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [propietats, setPropietats] = useState([]);
  const [aplicarDescompteUnitats, setAplicarDescompteUnitats] = useState(false);
  const [total, setTotal] = useState(0);

  const [propietatsBotons, setPropietatsBotons] = useState([]);
  const [defaultValue, setDefaultValue] = useState(buscaComprats(props.item.propietats));





  const descompte_x_unitats_num = props.item.descompte_x_unitats_num;
  const descompte_x_unitats_percent = props.item.descompte_x_unitats_percent ? props.item.descompte_x_unitats_percent : 0;

  const hiHaDescompteUnitats = descompte_x_unitats_num ? descompte_x_unitats_num > 0 : false;


  useEffect(() => {
    generaBotons();
  },[props.item]);


  useEffect(() => {
    //console.log('canvi defaultValue', defaultValue)
    onChange(defaultValue); //per a que calculi el total
  },[defaultValue]);


  const submitForm = async (valors) => {

    if(!validat){
      return false;
    }

    if(total === 0 ){
      message.config({top: 50});
      message.error({content: 'Error: '+ props.t('SummerCamp.tria'), duration:3});
      return false;
    }

    setGuardant(true);

    let dades = {
      id_producte: producte.id_producte,
      id_pare: perfilUsuari.account.id,
      id_alumne: props.id_alumne,
      propietats,
    }

    console.log(dades);

    try{
      const resposta = await Api.myFetch('/botiga', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Roba: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Roba guardar: ', error);
    }

  }

  const onChange = (checkedValues) => {
    console.log('checked = ', checkedValues);
    setPropietats(checkedValues);

    const suma_triats = props.item.propietats.reduce((acumulat, p) => (
      checkedValues.includes(p.id_propietat) ? acumulat+p.preu : acumulat
    ),0);


    let subtotal = suma_triats.toFixed(2);
    if(hiHaDescompteUnitats){
      if(checkedValues.length >= descompte_x_unitats_num){
        setAplicarDescompteUnitats(true);
        if(descompte_x_unitats_percent>0){
          subtotal = (subtotal - (subtotal * descompte_x_unitats_percent / 100)).toFixed(2);
        }
      }else{
        setAplicarDescompteUnitats(false);
      }
    }
    setTotal(subtotal);

  };

const generaBotons = () => {

  //let temp_defaultValue = [];

  const propietats_botons = props.item.propietats.map((p) =>{

    const radioStyle = {
          width:'373px',
          marginTop: '8px',
        };
    const text = entities.decode(p.text[props.t('idioma')]);

    /*if(p.comprat){
      temp_defaultValue.push(p.id_propietat)
    }*/
    return(
      <Col span={24} key={p.id_propietat}>
        <Row justify="space-between">
          <Row justify="left">
            <Checkbox value={p.id_propietat} style={{marginRight: '5px'}}/>
            <>{text}</>
          </Row>
          <span style={{color:'red', marginLeft: '10px'}}>{p.preu}€</span>

        </Row>
      </Col>
    );
  });

  //setDefaultValue(temp_defaultValue);
  //onChange(temp_defaultValue); //per a que calculi el total
  setPropietatsBotons(propietats_botons);

}

  //var d = new Date(producte.data_hora_esdeveniment);



  return(
    <>
    <Row justify="center">
      <Typography.Title level={4}>{entities.decode(producte.nom[props.t('idioma')])}</Typography.Title>
    </Row>
    <Row justify="center">
      <Typography.Title level={4} style={{marginTop: 0}}>{entities.decode(producte.descripcio[props.t('idioma')])}</Typography.Title>
      <img alt="logo SummerCamp" src={imatge} width='100%' onError={() => setImatge("https://via.placeholder.com/443x249?text=URL+IMAGE+NOT+FOUND!!")}/>
    </Row>

      <Row justify="center">
        <Checkbox.Group style={{margin: '10px', width: '100%'}} onChange={onChange} defaultValue={defaultValue} disabled={producte.comprat}>
          <Row justify="space-between">
          {propietatsBotons}
          </Row>
        </Checkbox.Group>
      </Row>

    <Row justify="center">
      <Space direction="vertical" align="center">
        {!hiHaDescompteUnitats ? null : <span  style={{color: 'red'}}>*{props.t('SummerCamp.desc1')}{descompte_x_unitats_num}{props.t('SummerCamp.desc2')}{descompte_x_unitats_percent}%{props.t('SummerCamp.desc3')}</span>}
        <Space><b style={{fontSize: '18px'}}>Total:</b><span style={{color: 'red', fontSize: '18px'}}>{total}€</span></Space>
        {!aplicarDescompteUnitats ? null : <span  style={{color: 'red'}}>({props.t('SummerCamp.desc_inclos1')}{descompte_x_unitats_percent}%{props.t('SummerCamp.desc_inclos2')})</span>}
      </Space>
    </Row>


    {!producte.comprat
      ?
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <AutoritzarContrasenya resposta={setValidat}/>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
              {props.t('botiga.pagar_autoritzar')}
            </Button>
          </Row>
        </>
      :
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <span style={{color: 'red'}}>*{props.t('generic.serveiJaContractat')}</span>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() =>props.tancarModal()}>
              {props.t('generic.back')}
            </Button>
          </Row>
        </>
      }


    </>
  );
}
export default translate(SummerCamp);
