import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id] = useState(props.item.id);
  const [id_transport] = useState(props.item.id_transport);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [hora, setHora] = useState(props.item.hora);
  const [minut, setMinut] = useState(props.item.minut);

  const [guardant, setGuardant] = useState(false);

  const [errorOrdre, setErrorOrdre] = useState('');
  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');
  const [errorHora, setErrorHora] = useState('');


  const nou = (id===0);

  const ordreChange = (value) =>{
    setOrdre(value);
    if(Number.isInteger(value)){
      setErrorOrdre('');
    }
  }
  const nom_caChange = (e) =>{
    const n = e.target.value.trim();
    setNom_ca(n);
    if(n.length>0){
      setErrorNom_ca('');
    }
  }
  const nom_enChange = (e) =>{
    const n = e.target.value.trim();
    setNom_en(n);
    if(n.length>0){
      setErrorNom_en('');
    }
  }
  const nom_esChange = (e) =>{
    const n = e.target.value.trim();
    setNom_es(n);
    if(n.length>0){
      setErrorNom_es('');
    }
  }

  const horaChange = (value) =>{
    setHora(value);
    if(Number.isInteger(value) && Number.isInteger(minut)){
      setErrorHora('');
    }
  }
  const minutChange = (value) =>{
    setMinut(value);
    if(Number.isInteger(value) && Number.isInteger(hora)){
      setErrorHora('');
    }
  }


  const submitForm = async (valors) => {

      let correcte = true;

      if(!Number.isInteger(ordre)){
        setErrorOrdre('error');
        correcte = false;
      }
      if(nom_ca.length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(nom_en.length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(nom_es.length === 0){
        setErrorNom_es('error');
        correcte = false;
      }

      if(!Number.isInteger(hora) || !Number.isInteger(minut)){
        setErrorHora('error');
        correcte = false;
      }

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        id_transport,
        ordre,
        nom_ca: entities.encode(nom_ca),
        nom_en: entities.encode(nom_en),
        nom_es: entities.encode(nom_es),
        hora,
        minut
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/transport/parada' : '/transport/parada/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();

        }else{
          setGuardant(false);
          console.error('Error: submit Form TransportParades: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: TransportParades guardar: ', error);
      }

  }


return(
  <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="horizontal"
      labelCol={{span:2}}
      wrapperCol={{span:22}}
      colon={true}
      autoComplete="off"
    >
      <Form.Item
        validateStatus={errorOrdre}
        label={props.t('transport.ordre')}
        required={true}
      >
          <InputNumber name="ordre" onChange={ordreChange} defaultValue={ordre} min={0} step={1} />
      </Form.Item>
      <Form.Item label={props.t('transport.nom')} style={{marginBottom: 0}} layout="horizontal">

          <Form.Item
            style={{ display: 'inline-block', width: 'calc(33% - 8px)' }}
            validateStatus={errorNom_ca}
            label={'CA'}
            required={true}
          >
              <Input name="nom_ca" placeholder={props.t('transport.nom')} onChange={nom_caChange} defaultValue={nom_ca}/>
          </Form.Item>

          <Form.Item
            style={{ display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}
            validateStatus={errorNom_en}
            label={'EN'}
            required={true}
          >
              <Input name="nom_en" placeholder={props.t('transport.nom')} onChange={nom_enChange} defaultValue={nom_en}/>
          </Form.Item>

          <Form.Item
            style={{ display: 'inline-block', width: 'calc(33% - 8px)' }}
            validateStatus={errorNom_es}
            label={'ES'}
            required={true}
          >
              <Input name="nom_es" placeholder={props.t('transport.nom')} onChange={nom_esChange} defaultValue={nom_es}/>
          </Form.Item>

      </Form.Item>

      <Form.Item
        validateStatus={errorHora}
        label={props.t('parades.hora')}
        required={true}
      >
          <InputNumber name="hora" onChange={horaChange} defaultValue={hora} min={0} max={23} step={1} formatter={value => `${value}h`} parser={value => value.replace('h', '')}/>
          <span>&nbsp;:&nbsp;</span>
          <InputNumber name="minut" onChange={minutChange} defaultValue={minut} min={0} max={59} step={1} formatter={value => `${value}m`} parser={value => value.replace('m', '')}/>
      </Form.Item>



        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default translate(Edit);
