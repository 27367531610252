import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function RebutsExportaExcel(props){

  const [dades, setDades] = useState([]);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Rebuts';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'Rebuts';

  const [isLoading, setIsLoading] = useState(true);

  const buscarDades = async () => {
    setIsLoading(true);
    const alum = await Api.get('/llistat/alumnes/sanitat');
    console.log(alum)
    if(alum.result.success===1){
      console.log(alum.data.alumnes)

      setDades(alum.data.alumnes);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="Codi Centre" value="codi_centre"/>
            <ExcelColumn label="Cognom1" value={(col) => entities.decode(col.cognom1)}/>
            <ExcelColumn label="Cognom2" value={(col) => entities.decode(col.cognom2)}/>
            <ExcelColumn label="Nom" value={(col) => entities.decode(col.nom)}/>
            <ExcelColumn label="Data de naixement" value="naixement"/>
            <ExcelColumn label="Sexe" value="sexe"/>
            <ExcelColumn label="DNI" value="dni"/>
            <ExcelColumn label="NIE" value="nie"/>
            <ExcelColumn label="Passaport" value="passaport"/>
            <ExcelColumn label="Altres" value="altres"/>
            <ExcelColumn label="Id.Sanitari" value="id_sanitari"/>
            <ExcelColumn label="Número identificador sanitari" value="num_id_sanitari"/>
            <ExcelColumn label="Codi ensenyament" value="codi_ensenyament"/>
            <ExcelColumn label="Nivell" value="nivell"/>
            <ExcelColumn label="Curs" value={(col) => entities.decode(col.curs)}/>
            <ExcelColumn label="Classe" value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label="Adreça" value={(col) => entities.decode(col.adress)}/>
            <ExcelColumn label="Teléfon" value="tel"/>
            <ExcelColumn label="Codi Nacionalitat" value="codi_nacionalitat"/>
            <ExcelColumn label="Codi municipi residència" value="codi_municipi"/>
            <ExcelColumn label="Ciutat" value={(col) => entities.decode(col.ciutat)}/>
            <ExcelColumn label="Codi Postal" value={(col) => entities.decode(col.cp)}/>
            <ExcelColumn label="Data matrícula" value="fini_matricula"/>
            <ExcelColumn label="Data fi matrícula" value="ffin_matricula"/>
            <ExcelColumn label="Codi país naixement" value="codi_pais_naixement"/>
            <ExcelColumn label="id_familia" value="id_familia"/>
            <ExcelColumn label="id_alumne" value="id_alumne"/>

          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(RebutsExportaExcel);
