function user(){
let item_buit = {
    id: 0,
    perfil: 'Pare',
    id_pare: 0,
    id_familia: 0,
    id_profe: 0,
    name: null,
    username: null,
    password: null,
    language: 'es',
    obligar_modificar_password: 1,
    proteccio_dades_acceptat: null,
    recordar_omplir_medics: 1,
    permetre_publicitat: 0,
    permetre_publicitat_date: null,
    locked: 0
  }
  return item_buit;
}
function carpeta(){
  let item_buit = {
    id: 0,
    titol: null,
    subtitol: null,
    carpeta_personal: false,
    visible: false,
    permisos:{
      read: {
          alumnes: [],
          usuaris: [],
          classes: [],
          cursos: [],
          cicles: [],
          etapes: []
      },
      write: {
          alumnes: [],
          usuaris: [],
          classes: [],
          cursos: [],
          cicles: [],
          etapes: []
      }
    }
  }
  return item_buit;
}
function fitxer(){
  let item_buit = {
    id_fitxer: 0,
    id_carpeta: 0,
    titol: null,
    subtitol: null,
    visible: 1

  }
  return item_buit;
}

function professor(){
  let item_buit = {
    id: 0,
    users_id: 0,
    nom: null,
    cognom1: null,
    cognom2: null,
    tel1: null,
    tel2: null,
    email: null,
    dni: null,
    dni_image_1: null,
    dni_image_2: null,
    passaport: null,
    passaport_image_1: null,
    passaport_image_2: null,
    app_username_txt: null,
    app_locked: 0,
    obligar_modificar_password: 1,
    idioma: 'ca',
    observacions: null
  }
  return item_buit;
}
function professor_relacio(){
  let item_buit = {
    id: 0,
    id_profe: 0,
    id_carrec: 0,
    id_extraescolar: 0,
    id_assignatura: 0,
    ambit: null,
    id_ambit: 0,
    classes: [],
    id_anada: 0,
    id_tornada: 0
  }
  return item_buit;
}
function professor_carrec(){
  let item_buit = {
    id: 0,
    nom:{
      ca: null,
      en: null,
      es: null
    },
    ambit: null
  }
  return item_buit;
}

function familia(){
  let item_buit = {
    id: 0,
    cognom1: null,
    cognom2: null,
    tel1: null,
    tel2: null,
    tel3: null,
    adress: null,
    adress_aux: null,
    ciutat: null,
    ciutat_aux: null,
    cp: null,
    cp_aux: null,
    provincia: null,
    provincia_aux: null,
    pais: null,
    pais_aux: null,
    observacions: null,
    tipus_pagament: null,
    mode_separar_despeses: 0,
    pagament_unic_totes_quotes_any: 0,
    pare_treballador_centre: 0,
    descompte_quota: 0,
    descompte_transport: 0,
    descompte_menjador: 0,
    descompte_botiga: 0,
    descompte_extraescolars: 0,
    llibre_familia: null,
    estat: 'actiu'
  }
  return item_buit;
}

function familia_adress(){
  let item_buit = {
    id: 0,
    id_familia: 0,
    id_pare: 0,
    adress: null,
    ciutat: null,
    cp: null,
    provincia: null,
    pais: null,
    observacions: null
  }
  return item_buit;
}

function pare(){
  let item_buit = {
    id: 0,
    users_id: 0,
    id_familia: null,
    id_relacio_familiar: null,
    nom: null,
    cognom1: null,
    cognom2: null,
    tel1: null,
    tel2: null,
    email: null,
    dni: null,
    dni_image_1: null,
    dni_image_2: null,
    passaport: null,
    passaport_image_1: null,
    passaport_image_2: null,
    app_username_txt: null,
    app_locked: 0,
    obligar_modificar_password: 1,
    idioma: 'ca',
    naixement: null,
    naixement_ciutat: null,
    nacionalitat: null,
    sexe: null,
    professio: null,
    empresa: null,
    observacions: null,
    estat: 'actiu'
  }
  return item_buit;
}

function alumne(){
  let item_buit = {
      id: 0,
      nom: null,
      cognom1: null,
      cognom2: null,
      naixement: null,
      naixement_ciutat: null,
      nacionalitat: null,
      id_familia: 0,
      id_classe: 0,
      sexe: null,
      nss: null,
      dni: null,
      dni_image_1: null,
      dni_image_2: null,
      passaport: null,
      passaport_image_1: null,
      passaport_image_2: null,
      data_ingres: null,
      lloc_entre_germans: 0,
      num_germans: 0,
      llengua_materna: null,
      escoles_anteriors: null,
      observacions: null,
      estat: 'actiu'
  }
  return item_buit;
}

function alumnes_xec(){
  let item_buit = {
      id: 0,
      id_alumne: 0,
      id_familia: 0,
      import: 0,
      id_curs_escolar: 0
  }
  return item_buit;
}

function banc(){
  let item_buit = {
    id: 0,
    id_familia: 0,
    id_pare: 0,
    iban: '',
    iban_percentatge: 0,
    utilitzar_en: null,
    bic: null,
    pendent_validar: 0,
    mandat: '',
    mandat_data: null,
    mandat_url: null,
    mandat_ext: null,
    titular: null,
    observacions: null
  }
  return item_buit;
}


function tutor(){
  let item_buit = {
    id: 0,
    nom: null,
    cognom1: null,
    cognom2: null,
    email: null,
    tel1: null,
    tel2: null,
    observacions: null
  }
  return item_buit;
}

function classe(){
  let item_buit = {
    id: 0,
    ordre: 0,
    nom: null,
    ubicacio: null,
    id_curs: 0,
    h_ini: 8,
    m_ini: 45,
    h_fin: 16,
    m_fin: 0
  }
  return item_buit;
}

function curs(){
  let item_buit = {
    id: 0,
    nom: null,
    id_cicle: 0
  }
  return item_buit;
}

function cicle(){
  let item_buit = {
    id: 0,
    nom: null,
    id_etapa: 0
  }
  return item_buit;
}

function etapa(){
  let item_buit = {
    id: 0,
    nom: null
  }
  return item_buit;
}
function botigaProducte(){
  let item_buit = {
    id: 0,
    id_categoria: 0,
    ref: null,
    nom:{
      ca: null,
      en: null,
      es: null
    },
    descripcio: {
      ca: null,
      en: null,
      es: null
    },
    url_image: null,
    preu: 0,
    descompte: 0,
    descompte_x_unitats_num: 0,
    descompte_x_unitats_percent: 0,
    id_tipus_propietats: null,
    data_hora_esdeveniment: null,
    fini: null,
    ffin: null,
    updated_at: null,
    propietats: null,
    cursos: null,
    visible: 0,
    mode_test: 0

  }
  return item_buit;
}
function botigaPropietat(){
  let item_buit = {
    id: 0,
    visible: 1,
    id_producte: 0,
    id_talla: 0,
    ref: null,
    ordre: 1,
    preu: 0,
    text:{
      ca: null,
      en: null,
      es: null
    }
  }
  return item_buit;
}
function botigaTalles(){
  let item_buit = {
    id: 0,
    ordre: 0,
    text:{
      ca: null,
      en: null,
      es: null
    }
  }
  return item_buit;
}
function transport(){
  let item_buit = {
    id: 0,
    sentit: null,
    ordre: 0,
    nom: {
      ca: null,
      en: null,
      es: null
    },
    preu_mensual: 0,
    preu_puntual: 0,
    descompte_x_anada_i_tornada: 0
  }
  return item_buit;
}
function transport_parades(){
  let item_buit = {
    id: 0,
    id_transport: 0,
    ordre: 0,
    nom: {
      ca: null,
      en: null,
      es: null
    },
    hora: null,
    minut: null
  }
  return item_buit;
}
function menjador(){
  let item_buit = {
    id: 0,
    tipus: null,
    nom: {
      ca: null,
      en: null,
      es: null
    },
    descripcio: {
      ca: null,
      en: null,
      es: null
    },
    preu: 0,
    descompte: 0
  }
  return item_buit;
}

function extraescolars(){
  let item_buit = {
    id: 0,
    ordre: 0,
    nom: {
      ca: null,
      en: null,
      es: null
    },
    descripcio: {
      ca: null,
      en: null,
      es: null
    },
    url_image: null,
    horari: {
      ca: null,
      en: null,
      es: null
    },
    dilluns: 0,
    dimarts: 0,
    dimecres: 0,
    dijous: 0,
    divendres: 0,
    dissabte: 0,
    diumenge: 0,
    mesos_facturacio: [1,2,3,4,5,6,7,8,9,10,11,12],
    preu: 0,
    decompte: 0,
    fini: null,
    ffin: null,
    cursos: null
  }
  return item_buit;
}

function acollidaMinuts(){
  let item_buit = {
    id: 0,
    id_alumne: 0,
    id_pare: 0,
    tipus: null,
    puntual_data: null,
    h_ini: 0,
    m_ini: 0,
    h_fin: 0,
    m_fin: 0,
    id_curs_escolar: 0,
  }
  return item_buit;
}
function acollidaMinutsComanda(){
  let item_buit = {
    id: 0,
    id_alumne: 0,
    id_pare: 0,
    id_familia: 0,
    tipus: null,
    puntual_data: null,
    h_ini: 0,
    m_ini: 0,
    h_fin: 0,
    m_fin: 0,
    id_curs_escolar: 0,
    minuts_facturar: 0,
    puntual_facturat: null,
    preu: 0,
    subtotal: 0,
    descompte: 0,
    total: 0,
    id_classe: 0,
    id_curs: 0,
    creat: null
  }
  return item_buit;
}


function acollida(){
  let item_buit = {
    id: 0,
    tipus: null,
    ordre: 0,
    id_tipus_propietats: 1,
    nom: {
      ca: null,
      en: null,
      es: null
    },
    descompte: 0
  }
  return item_buit;
}
function acollida_propietats(){
  let item_buit = {
    id: 0,
    id_acollida: 0,
    ordre: 0,
    text: {
      ca: null,
      en: null,
      es: null
    },
    preu: null
  }
  return item_buit;
}
function curs_escolar(){
  let item_buit = {
    id: 0,
    fini: null,
    ffin: null,
    num_mesos: 10,
    nom: null,
    actual: 0,
  }
  return item_buit;
}

function quotes_tipus(){
  let item_buit = {
    id: 0,
    id_curs_escolar: 0,
    ordre: 0,
    nom: {
      ca: null,
      en: null,
      es: null
    },
  //  pagament: null, //  'unic','anual','mensual', 'triarMesos'
    tipus_ingres: '',
    comportament: 'normal', // 'normal', 'transport', 'menjador', 'reserva'
    mesos_facturacio: [], //per a pagament = 'triarMesos'
    proporcional: 0, //Si s'incorpora a meitat curs es cobrarà l'import total o proporcional al temps restant
  }
  return item_buit;
}
function quota(){
  let item_buit = {
    id: 0,
    id_tipus_quota: 0,
    ordre: 0,
    preu: 0,
    descompte_2on: 0, //segon nen/a
    descompte_3rs: 0, //tercer nen/a i endavant
    cursos: null,
    text: {
      ca: null,
      en: null,
      es: null
    },
  }
  return item_buit;
}
function comandesExtraescolars(){
  let item_buit = {
    id: 0,
    id_familia: 0,
    familia_nom: null,
    id_pare: 0,
    pare_nom: null,
    id_alumne: 0,
    alumne_nom: null,
    id_classe: 0,
    classe: null,
    id_curs: 0,
    curs: null,
    id_extraescolar: 0,
    nom: {
      ca: null,
      en: null,
      es: null
    },
    horari: {
      ca: null,
      en: null,
      es: null
    },
    subtotal: 0,
    descompte: 0,
    total: 0,
    facturat: false,
    date: null,
  }
  return item_buit;
}

function fac_pressupostos_linies(){
  let item_buit = {
    id: 0,
    id_pressupost: 0,
    tipus: '',
    id_alumne: 0,
    taula: 'manual',
    id_taula: 0,
    concepte: null,
    descompte: 0,
    preu: 0,
    unitats: 1,
    manual: 1,
  }
  return item_buit;
}

function comandes_roba(){
  let item_buit = {
    id: 0,
    estat: 'Pendent',
    id_pare: 0,
    id_alumne: 0,
    id_producte: 0,
    id_tipus_propietats: 0,
    nom_ca: null,
    nom_en: null,
    nom_es: null,
    nom: {
        ca: null,
        en: null,
        es: null,
    },
    subtotal: 0,
    descompte: 0,
    descompte_x_unitats: 0,
    total: 0,
    facturat: 0,
    observacions: null,
    fcrea: null,
    created_by: 0,
    preu: 0,
    unitats: 1,
    talla: '',
    id_classe: 0,
    classe: '',
    id_familia: 0,
    alumne_complet: null,
    alumne_nom: null,
    alumne_cognom1: null,
    alumne_cognom2: null,
    pare_nom: null,
    pare_cognom1: null,
    pare_cognom2: null,
    creat: null,
  }
  return item_buit;
}

function motiusAbsencia(){
  let item_buit = {
    id: 0,
    ordre: 0,
    nom:{
      ca: null,
      en: null,
      es: null
    }
  }
  return item_buit;
}

function rebut(){
  let item_buit = {
    id: 0,
    id_curs_escolar: 3,
    id_pressupost: 0,
    id_familia: 0,
    id_alumne: 0,
    alumne: null,
    id_classe: 0,
    classe: null,
    concepte: '',
    import: 0,
    id_banc: 0,
    bic: null,
    iban: null,
    titular: null,
    mandat: null,
    mandat_data: null,
    tipus_pagament: 'BANC',
    estat: 'temporal',
    pagament_unic_totes_quotes_any: 0,
    fcrea: null,
    pressupost: null,
    linies: []
  }
  return item_buit;
}
function rebut_linia(){
  let item_buit = {
    id_linia: 0,
    id_rebut: 0,
    tipus: 'altres',
    id_taula: 0,
    taula: null,
    concepte: null,
    preu: 0,
    unitats: 1,
    participacio: 100,
    subtotal: 0,
    descompte: 0,
    manual: 1
  }
  return item_buit;
}
function rebut_entregat(){
  let item_buit = {
    id: 0,
    id_rebut: 0,
    import: 0,
    concepte: null,
    tipus_pagament: 'EFECTIU',
    data_pagament: null
  }
  return item_buit;
}
function notificacions_tipus(){
  let item_buit = {
    id: 0,
    ordre: 0,
    perfil: null,
    nom:{
      ca: null,
      en: null,
      es: null
    },
    ids_carrecs: [],
    notificar_admin: true,
    notificar_pares: true
  }
  return item_buit;
}
function notificacions_emails(){
  let item_buit = {
    id: 0,
    nom: '',
    email: '',
    transport: false,
    menjador: false,
    acollida: false,
    botiga: false,
    extraescolars: false,
    notificar_admin: false
  }
  return item_buit;
}

function menjador_report_frases(){
  let item_buit = {
    id: 0,
    tipus: 'comportament',
    semafor: 'groc',
    text:{
      ca: null,
      en: null,
      es: null
    },
    ordre: 0,
    visible: 1
  }
  return item_buit;
}
function menjador_report(){
  let item_buit = {
    id: 0,
    id_curs_escolar: 2,
    id_frase: 0,
    id_alumne: 0,
    week: 0
  }
  return item_buit;
}

function tipus_ingressos_llista(){
  let tipus_temp = [
    "matricula",
    "quota_educativa",
    "menjador",
    "menjador_comandes",
    "transport",
    "transport_comandes",
    "ict",
    "mask",
    "extraescolars",
    "acollida",
    "botiga",
    "material_ey_pn",
    "material_n_r_primary",
    "intranet",
    "tapestry_eyfs",
    "altres",
    "xec_guarderia",
    "reserva",
    "excursions",
    "support_hours",
    "summer"
  ]
  return tipus_temp;
}
function imports_recurrents(){
  let item_buit = {
    id: 0,
    id_curs_escolar: 0,
    id_familia: 0,
    id_alumne: 0,
    mesos_facturacio: [1,2,3,4,5,6,7,8,9,10,11,12],
    tipus: 'altres',
    concepte: null,
    descompte: 0,
    preu: 0,
    unitats: 1,
    subtotal: 0,
    observacions: null
  }
  return item_buit;
}


export {
  user,
  carpeta,
  fitxer,
  professor,
  professor_relacio,
  professor_carrec,
  familia,
  familia_adress,
  pare,
  alumne,
  alumnes_xec,
  tutor,
  classe,
  curs,
  cicle,
  etapa,
  botigaProducte,
  botigaPropietat,
  botigaTalles,
  transport,
  transport_parades,
  menjador,
  extraescolars,
  acollidaMinuts,
  acollidaMinutsComanda,
  acollida,
  acollida_propietats,
  banc,
  curs_escolar,
  quotes_tipus,
  quota,
  comandesExtraescolars,
  fac_pressupostos_linies,
  comandes_roba,
  motiusAbsencia,
  rebut,
  rebut_linia,
  rebut_entregat,
  notificacions_tipus,
  notificacions_emails,
  menjador_report_frases,
  menjador_report,
  tipus_ingressos_llista,
  imports_recurrents,
};
