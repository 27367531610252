import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { setDefaultTranslations, setDefaultLanguage } from 'react-multi-lang'
import ca from './translations/ca.json'
import en from './translations/en.json'
import es from './translations/es.json'

setDefaultTranslations({ca, en, es})
setDefaultLanguage('ca')

global.app_id = process.env.REACT_APP_ID;
global.api_url = process.env.REACT_APP_API_URL;

if (process.env.NODE_ENV !== "production"){
  global.api_url = process.env.REACT_APP_API_URL_TEST;
}
//global.pixabay_api_key = process.env.REACT_APP_PIXABAY_API_KEY;


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
