import React, {useState} from 'react';
import moment from 'moment';
import 'moment/locale/ca';

import { translate } from 'react-multi-lang';
import { Table, Col, Input, Space, Button, Modal } from 'antd';
import { SearchOutlined, TeamOutlined, WarningOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';
import Moment from 'react-moment';

import Edit from './UsersEdit';
import LlistaEditableModalNouEdit from '../../../LlistaEditableModalNouEdit';
import { Api, InitDades } from '../../../../helpers';
const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';
const { Column } = Table;

function UsersTaula(props){

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  const buscarDades = async () => {
    const u = await Api.get(`/users/perfil/${props.perfil}`);
    if(u.result.success===1){
      return u.data.users;
    }
  }


  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

  const columnes = [
    {
      title:"Id",
      key:"id",
      sorter: (a, b) =>  a.id - b.id,
      render: (text,record) => (<span>{record.id}</span>)
    },{
      title: props.t('users.table_name'),
      key: "name",
      dataIndex: "name",
      render: (text,record) => (entities.decode(record.name)),
      sorter: (a, b) => { return entities.decode(a.name).localeCompare(entities.decode(b.name))},
      ...getColumnSearchProps('name',props.t('users.table_name'))
    },{
      title: props.t('users.table_user'),
      key: "username",
      dataIndex: "username",
      render: (text,record) => (entities.decode(record.username)),
      sorter: (a, b) => { return entities.decode(a.username).localeCompare(entities.decode(b.username))},
      ...getColumnSearchProps('username',props.t('users.table_user'))
    },{
      title: props.t('users.table_estado'),
      key: "locked",
      dataIndex: "locked",
      render: (text,record) => (
        record.locked===1
          ? <span style={{color:'red'}}>{props.t('users.bloqueado')}&nbsp;<WarningOutlined /></span>
          : <span style={{color:'green'}}>{props.t('users.activo')}</span>
      ),
      sorter: (a, b) =>  a.locked - b.locked,

    },{
      title: props.t('users.table_last'),
      key: "last_access",
      width: "175px",
      onFilter: (value, record) => (moment(record.last_access).format('DD/MM/YYYY HH:mm') === value),
      sorter: (a, b) => moment(a.last_access).diff(moment(b.last_access)),
      render: (text,record) =>(
          record.last_access ? moment(record.last_access).format(dateFormat) : ''
      )
    },{
      title: props.t('users.table_created'),
      key: "created_at",
      width: "175px",
      onFilter: (value, record) => (moment(record.created_at).format('DD/MM/YYYY HH:mm') === value),
      sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at)),
      render: (text,record) =>(
          record.created_at ? moment(record.created_at).format(dateFormat) : ''
      )
    }
  ];

  let itemBuit = InitDades.user();
  itemBuit.perfil = props.perfil;

  return(
    <>
      <LlistaEditableModalNouEdit
        rowKey="id"
        txt_creats={props.t('users.creados')}
        txt_nou={props.t('users.nuevo')}
        icon_creats={TeamOutlined}
        pagination={false}
        buscarDades={buscarDades}
        columns={columnes}
        itemBuit={itemBuit}
        edit={Edit}
        add={['Administrador', 'Auxiliar'].indexOf(props.perfil) == -1 ? null : Edit}
      />
    </>
  );

}
export default translate(UsersTaula);
