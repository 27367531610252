import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, Checkbox, Space, Select, Form, DatePicker, Alert, message } from 'antd';
import { MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

const radioStyle = {
      width:'100%',
      marginTop: '8px',
    };
function Transport(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [radios, setRadios] = useState();
  const [triat, setTriat] = useState(null);

  const [diesTriats, setDiesTriats] = useState([]);
  const [numDies, setNumDies] = useState(0);

  const ids_fills = props.ids_fills ? props.ids_fills : [props.id_alumne];

  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [comprat, setComprat] = useState(false);
  const [llistaCompratsPuntual, setLlistaCompratsPuntual] = useState([]);

  const [error, setError] = useState('');

  const [hiHaDescompte, setHiHaDescompte] = useState(false);
  const [descompte, setDescompte] = useState(0);
  const [total, setTotal] = useState(0);
  const [aplicarDescompte, setAplicarDescompte] = useState(false);
  const [numFills, setNumFills] = useState(1);


  useEffect(() => {
    buscarDades();
  },[]);

  useEffect(() => {
    calcularTotal();
  },[descompte, aplicarDescompte, triat, numFills, numDies]);

  useEffect(() => {
    if(hiHaDescompte){
      const d = (triat ? triat.descompte : 0);
      setDescompte(d);
      setAplicarDescompte(triat);
    }
  },[triat]);

  const buscarDades = async () => {
    setIsLoading(true);

    const tipus = props.puntual===false ? 'mensual' : 'puntual';
    const resp = await Api.get('/menjador/tipus/'+tipus);
    if(resp.result.success===1){
      console.log(resp.data)
      const ra = resp.data.menjadors.map((item) =>{

        if(item.descompte>0){
            setHiHaDescompte(true);
            setDescompte(item.descompte);
          }

          if(item.comprat.length>0){
            const registre = item.comprat.find((element, index) => element.id_alumne === props.id_alumne);
            console.log('ids_fills: ', ids_fills)
            if(registre){
              setTriat(item);
              setComprat(true)
            }

            if(item.tipus=='mensual'){
              let trobats = [];
              ids_fills.forEach((idfill, i) => {
                const trobat = item.comprat.find((element, index) => element.id_alumne === idfill);
                if(trobat){
                  trobats.push(trobat);
                }
              });
              if(trobats.length>0){
                setNumFills(trobats.length)
              }
            }else{
              const comprats_puntual = item.comprat.map((i) =>{
                var d = new Date(i.puntual_data);
                return(
                  <Row justify="space-between" key={i.puntual_data}>
                    <Col>{entities.decode(i.nom)}</Col>
                    <Col>{d.toLocaleDateString()}</Col>
                  </Row>
                );
              });
              setLlistaCompratsPuntual(comprats_puntual);
            }
          }

        return(
          <Radio.Button style={radioStyle} value={item} key={item.id}>
            <table width="100%">
              <tr>
                <td style={{maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                  {entities.decode(item.nom[props.t('idioma')])}
                </td>
                <td width="30" align="right">
                  {item.preu}€
                </td>
              </tr>
            </table>
          </Radio.Button>
        );
      });
      setRadios(ra);
    }
    setIsLoading(false);
  }

const calcularTotal = () => {
  if(props.puntual){
    calcularPuntual();
  }else{
    calcularMensual();
  }
}

const calcularPuntual = () =>{
  let subtotal = 0;
  if(triat){
    subtotal = subtotal + (triat.preu * numFills * numDies);
  }
  setTotal(subtotal.toFixed(2));
}
const calcularMensual = () =>{
  let subtotal = 0;
  if(triat){
    subtotal = subtotal + (triat.preu * numFills);
  }

  if(aplicarDescompte && descompte > 0 ){
    subtotal = (subtotal - (subtotal * descompte / 100));
  }
  setTotal(subtotal.toFixed(2));
}



  const submitForm = async (valors) => {


    if(!validat){
      return false;
    }

    if(!triat){
      message.config({top: 50});
      message.error({content: 'Error: '+ props.t('generic.tria'), duration:3});
      return false;
    }

    if(props.puntual === true){
      if(diesTriats.length <= 0){
        message.config({top: 50});
        message.error({content: 'Error: '+ props.t('generic.tria_dies'), duration:3});
        return false;
      }
    }

  setGuardant(true);
    let dades = {};

   if(props.puntual === true){
     dades = {
       id_menjador: triat.id,
       id_alumnes: numFills === 1 ? [props.id_alumne] : ids_fills,
       id_pare: perfilUsuari.account.id,
       tipus: 'puntual',
       puntual_dates: diesTriats.map((item) =>item.format(moment.HTML5_FMT.DATE)),
     }
   }else{
     dades = {
       id_menjador: triat.id,
       id_alumnes:  numFills === 1 ? [props.id_alumne] : ids_fills,
       id_pare: perfilUsuari.account.id,
       tipus: 'mensual'
     }
   }


  console.log(JSON.stringify(dades));

    try{
      const resposta = await Api.myFetch('/menjador/comanda', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Menjador: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Menjador guardar: ', error);
    }

  }


  const onChangeRadio = (item) => {
    setTriat(item);
  }

  const onChangeTotsFills = (tots) =>{
    setNumFills(tots ? ids_fills.length : 1);
  }

  const onDiaTriat = (date, dateString) =>{
    const trobat = diesTriats.filter((element, index, arr) => element.format(moment.HTML5_FMT.DATE) === date.format(moment.HTML5_FMT.DATE));
    if(trobat.length === 0){
      const dies = [...diesTriats, date];
      setDiesTriats(dies);
      setNumDies(dies.length);
    }

    //console.log(dateString)
  }
  const onEliminaDia = (item) => {


    const filtrat = diesTriats.filter((element, index, arr) => element !== item);
    setDiesTriats(filtrat);
    setNumDies(filtrat.length);
  }


  const disabledDate = (current) => {
    // Can not select days before today and today
    const temp = current && current < moment().startOf('day');
    console.log('disabledDate: ', current)
    return temp;
  }



  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';


  return(
    <>
    { !radios ? null :
    <>
      <Row justify="center" style={{marginTop:'10px'}}>
        <Typography.Title level={4}>{props.t('menu.Menjador')}</Typography.Title>
      </Row>

      <Row justify="left">
        <Form.Item required={true} validateStatus={error} style={{marginTop: 0, paddingRight: '20px', width: '100%'}}>
          <Radio.Group style={{margin: '10px', width: '100%'}} onChange={(e) => onChangeRadio(e.target.value)} optionType="button" buttonStyle="solid" value={triat}>
            {radios}
          </Radio.Group>
        </Form.Item>
      </Row>


    </>
    }


{props.puntual === false ? null :
  <>
    <Row justify="center" style={{marginTop:'10px'}}>
      <DatePicker
        style={{width: '200px'}}
        disabledDate={disabledDate}
        format={dateFormat}
        locale={localeDatePicker}
        placeholder={props.t('generic.tria_dies')}
        value={null}
        onChange={onDiaTriat}/>
    </Row>
    {!comprat ? null :
    <Row justify="center" style={{marginTop:'10px'}}>
      <Alert
        message={props.t('generic.serveiJaContractat_L1')}
        description={
          <>
            {llistaCompratsPuntual}
            {<span style={{color:'red'}}>*{props.t('generic.serveiJaContractat_L2')}</span>}
          </>
        }
        type="success"
        showIcon
      />
    </Row>
    }
    <Row justify="center" style={{marginTop:'10px'}}>
    <Col span={8}>
    {diesTriats.map((item) =>{
      return(
        <Row justify="space-between" key={item.format(dateFormat)}>
          <Col>{item.format(dateFormat)}</Col>
          <Col><DeleteOutlined onClick={()=>onEliminaDia(item)}/></Col>
        </Row>
      );
    })}
    </Col>
    </Row>
  </>
}
    <Row justify="center" style={{marginTop:'10px'}}>
      <Space direction="vertical" align="center">
        <Space><b style={{fontSize: '18px'}}>Total:</b><span style={{color: 'red', fontSize: '18px'}}>{total}€</span></Space>
        {!aplicarDescompte ? null : <span  style={{color: 'red'}}>({props.t('SummerCamp.desc_inclos1')}{descompte}%{props.t('SummerCamp.desc_inclos2')})</span>}
      </Space>
    </Row>


    {ids_fills.length <= 1 ? null :
      <Row justify="center" style={{marginTop:'10px'}}>
        <Checkbox onChange={(e) => onChangeTotsFills(e.target.checked)} checked={ids_fills.length===numFills} disabled={comprat && props.puntual===false}>{props.t('transport.tots_fills')}</Checkbox>
      </Row>
    }
    {!comprat || props.puntual===true
      ?
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <AutoritzarContrasenya resposta={setValidat}/>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
              {props.t('botiga.pagar_autoritzar')}
            </Button>
          </Row>
        </>
      :
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <span style={{color: 'red'}}>*{props.t('generic.serveiJaContractat')}</span>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() =>props.tancarModal()}>
              {props.t('generic.back')}
            </Button>
          </Row>
        </>
      }
    </>
  );
}
export default translate(Transport);
