import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import {Typography, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';
import sha512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

import { AllHtmlEntities } from 'html-entities';

import { Api, HtmlStrings } from '../../../../helpers';
import ImatgeDNI from '../../../ImatgeDNI';
import VisorImatges from "../../../visorImatges";
import BotoFamilia from '../../../PopUps/perfils/admin/BotoUnitatFamiliar';
import BotonsPares from '../../../PopUps/perfils/admin/BotonsPares';
import BotonsFills from '../../../PopUps/perfils/admin/BotonsFills';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id, setId] = useState(props.item.id);
  const [users_id, setUsers_id] = useState(props.item.users_id);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [id_relacio_familiar, setId_relacio_familiar] = useState(props.item.id_relacio_familiar);
  const [nom, setNom] = useState(entities.decode(props.item.nom));
  const [cognom1, setCognom1] = useState(entities.decode(props.item.cognom1));
  const [cognom2, setCognom2] = useState(entities.decode(props.item.cognom2));
  const [tel1, setTel1] = useState(props.item.tel1);
  const [tel2, setTel2] = useState(props.item.tel2);
  const [email, setEmail] = useState(props.item.email);
  const [dni, setDni] = useState(props.item.dni);
  const [dni_image_1, setDni_image_1] = useState();
  const [dni_image_2, setDni_image_2] = useState();
  const [passaport, setPassaport] = useState(props.item.passaport);
  const [passaport_image_1, setPassaport_image_1] = useState(props.item.passaport_image_1);
  const [passaport_image_2, setPassaport_image_2] = useState(props.item.passaport_image_2);
  const [app_username_txt, setApp_username_txt] = useState(props.item.app_username_txt);
  const [app_password, setApp_password] = useState(null);
  const [app_locked, setApp_locked] = useState(props.item.app_locked);
  const [obligar_modificar_password, setObligar_modificar_password] = useState(props.item.obligar_modificar_password);
  const [idioma, setIdioma] = useState(entities.decode(props.item.idioma));
  const [naixement, setNaixement] = useState(props.item.naixement);
  const [naixement_ciutat, setNaixement_ciutat] = useState(entities.decode(props.item.naixement_ciutat));
  const [nacionalitat, setNacionalitat] = useState(entities.decode(props.item.nacionalitat));
  const [sexe, setSexe] = useState(props.item.sexe);
  const [professio, setProfessio] = useState(entities.decode(props.item.professio));
  const [empresa, setEmpresa] = useState(entities.decode(props.item.empresa));
  const [observacions, setObservacions] = useState(entities.decode(props.item.observacions));
  const [estat, setEstat] = useState(props.item.estat);

/* Auxiliars */
  const [dni_image_1_dataUrl, setDni_image_1_dataUrl ] = useState(undefined);
  const [dni_image_2_dataUrl, setDni_image_2_dataUrl ] = useState(undefined);
  const [passaport_image_1_dataUrl, setPassaport_image_1_dataUrl ] = useState(undefined);
  const [passaport_image_2_dataUrl, setPassaport_image_2_dataUrl ] = useState(undefined);

  const [families, setFamilies] = useState([]);
  const [relacions_idiomes, setRelacions_idiomes] = useState([]);
  const [relacions, setRelacions] = useState([]);

  const [errorNom, setErrorNom] = useState('');
  const [errorCognom1, setErrorCognom1] = useState('');
  const [errorId_Familia, setErrorId_Familia] = useState('');
  const [errorId_FamiliaText, setErrorId_FamiliaText] = useState('');
  const [errorId_relacio_familiar, setErrorId_relacio_familiar] = useState('');
  const [errorId_relacio_familiarText, setErrorId_relacio_familiarText] = useState('');
  const [errorApp_username_txt, setErrorApp_username_txt] = useState('');
  const [errorApp_username_txtText, setErrorApp_username_txtText] = useState('');
  const [errorApp_password, setErrorApp_password] = useState('');


  const [visor, setVisor] = useState({visible: false});

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [nou, setNou] = useState(props.item.id===0);

  const contenidorRef = React.useRef();

  const buscarDades = async () => {
    setIsLoading(true);
    if(id > 0) {
      const pare = await Api.get('/pares/'+id);
      if(pare.result.success===1){
          setUsers_id(pare.data.users_id);
          setId_familia(pare.data.id_familia);
          setId_relacio_familiar(pare.data.id_relacio_familiar);
          setNom(entities.decode(pare.data.nom));
          setCognom1(entities.decode(pare.data.cognom1));
          setCognom2(entities.decode(pare.data.cognom2));
          setTel1(pare.data.tel1);
          setTel2(pare.data.tel2);
          setEmail(pare.data.email);
          setDni(pare.data.dni);
          setPassaport(pare.data.passaport);
          setApp_username_txt(pare.data.app_username_txt);
          setApp_locked(pare.data.app_locked);
          setObligar_modificar_password(pare.data.obligar_modificar_password);
          setIdioma(entities.decode(pare.data.idioma));
          setNaixement(pare.data.naixement);
          setNaixement_ciutat(entities.decode(pare.data.naixement_ciutat));
          setNacionalitat(entities.decode(pare.data.nacionalitat));
          setSexe(pare.data.sexe);
          setProfessio(entities.decode(pare.data.professio));
          setEmpresa(entities.decode(pare.data.empresa));
          setObservacions(entities.decode(pare.data.observacions));
          setEstat(pare.data.estat);
      }
      const dni_pare = await Api.get('/pares/dni/'+id);
      console.log(dni_pare);
      if(dni_pare.result.success===1){
        setDni_image_1(dni_pare.data.dni_image_1);
        setDni_image_2(dni_pare.data.dni_image_2);
        setPassaport_image_1(dni_pare.data.passaport_image_1);
        setPassaport_image_2(dni_pare.data.passaport_image_2);
      }
    }
    const fam = await Api.get('/families');
    if(fam.result.success===1){
      const llista_fam = fam.data.families.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.id + ' - ' + entities.decode(item.cognom1) + ' ' + entities.decode(item.cognom2)}
              </Select.Option>
            );
      });
      setFamilies(llista_fam);
    }
    const rel = await Api.get('/relacions');
    if(rel.result.success===1){
      setRelacions_idiomes(rel.data.relacions_familiars);
      muntarRelacionsIdioma(rel.data.relacions_familiars);
    }
    setIsLoading(false);
  }


  const muntarRelacionsIdioma = (relacions) => {
    if(relacions){
      const llista_rel = relacions.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.text[props.t('idioma')])}
              </Select.Option>
            );
      });
      setRelacions(llista_rel);
    }
  }

  useEffect(() => {
    buscarDades();
  },[]);

  useEffect(() => {
    muntarRelacionsIdioma(relacions_idiomes);
  },[props.t('idioma')]);




    const submitForm = async (valors) => {

      let correcte = true;
      if(!nom){
        setErrorNom('error');
        correcte = false;
      }
      if(!cognom1){
        setErrorCognom1('error');
        correcte = false;
      }
      if(!id_relacio_familiar){
        setErrorId_relacio_familiar('error');
        setErrorId_relacio_familiarText(props.t('generic.tria'));
        correcte = false;
      }
      if(!id_familia){
        setErrorId_Familia('error');
        setErrorId_FamiliaText(props.t('generic.tria'));
        correcte = false;
      }

      if(errorApp_username_txt === 'error'){
        correcte = false;
      }

      if(app_username_txt !== props.item.app_username_txt && !props.item.app_username_txt){
        //Si no tenia usuari i ara se li asigna un, se li ha de posar contrasenya
        if(!app_password){
          setErrorApp_password('error');
          correcte = false;
        }
      }

      if(!correcte){
        contenidorRef.current.scrollIntoView({
          behavior: "smooth",
        });
        return false;
      }

      setGuardant(true);

      let password = null;
      if(app_password){
         password = Base64.stringify(sha512(app_password));
      }

      let dades = {
        id,
        users_id,
        id_familia,
        id_relacio_familiar,
        nom: entities.encode(nom),
        cognom1: entities.encode(cognom1),
        cognom2: entities.encode(cognom2),
        tel1,
        tel2,
        email,
        dni,
        passaport,
        app_username_txt,
        app_password: password,
        app_locked,
        obligar_modificar_password,
        idioma,
        naixement,
        naixement_ciutat: entities.encode(naixement_ciutat),
        nacionalitat: entities.encode(nacionalitat),
        sexe,
        professio: entities.encode(professio),
        empresa: entities.encode(empresa),
        observacions: entities.encode(observacions),
        estat
      }

      let method = nou?'POST':'PUT';

      let url_api = nou? '/pares' : '/pares/'+id;

      //console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){

          if(nou){
            setNou(false);
            setId(resposta.data.id);
          }


          props.item.app_username_txt = app_username_txt;

          if(dni_image_1_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/pares/dni/'+resposta.data.id+'/1', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_1(resp.data.dni_image_1);
              }else{
                console.error('Error POST file: Pares dni_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(dni_image_2_dataUrl !== undefined){
            const blob = await (await fetch(dni_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/pares/dni/'+resposta.data.id+'/2', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setDni_image_2(resp.data.dni_image_2);
              }else{
                console.error('Error POST file: Pares dni_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_1_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_1_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/pares/dni/'+resposta.data.id+'/3', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_1(resp.data.passaport_image_1);
              }else{
                console.error('Error POST file: Pares passaport_image_1: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }
          if(passaport_image_2_dataUrl !== undefined){
            const blob = await (await fetch(passaport_image_2_dataUrl)).blob();
            let form_imatges = new FormData();
            form_imatges.append("imgFileField", blob, "image.jpg");
            let resp = await Api.myFetch('/pares/dni/'+resposta.data.id+'/4', 'POST', form_imatges);
              if(resp.result.success === 1){
                console.log(resp);
                setPassaport_image_2(resp.data.passaport_image_2);
              }else{
                console.error('Error POST file: Pares passaport_image_2: ',resp);
                message.config({top: 50});
                message.open({content: resposta.data.message, duration:5});
              }
          }


          setGuardant(false);
          //console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormPares: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Pares guardar: ', error);
      }

  }


  let posicio_botons = "start";
  /*if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }*/

  const onChangeNom = (value) =>{
    setNom(value);
    if(value.length>0){
      setErrorNom('');
    }
  }
  const onChangeCognom1 = (value) =>{
    setCognom1(value);
    if(value.length>0){
      setErrorCognom1('');
    }
  }
  const triaRelacioFamiliar = (value) =>{
    setId_relacio_familiar(value);
    if(value>0){
      setErrorId_relacio_familiar('');
      setErrorId_relacio_familiarText('');
    }
  }
  const triaFamilia = (value) =>{
    setId_familia(value);
    if(value>0){
      setErrorId_Familia('');
      setErrorId_FamiliaText('');
    }
  }

  const triaSexe = (value) =>{
    setSexe(value);
    /*if(value.length>0){
      setErrorSexe('');
      setErrorSexeText('');
    }*/
  }

  const changeNaixement = (date, dateString) => {
    if(date){
      //Trec l'hora
      setNaixement(date.format(moment.HTML5_FMT.DATE));
    }else{
      setNaixement(null);
    }
  }

  const changeApp_username_txt = async (valor) => {
    const username = HtmlStrings.filtrarNumText(valor);
    setApp_username_txt(username);

    if(username.length > 2){
      const disp = await Api.get(`/pares/username/disponible/${id}/${username}`);
      if(disp.result.success==1){
        if(disp.data.disponible){
          setErrorApp_username_txt('');
          setErrorApp_username_txtText('');
        }else{
          setErrorApp_username_txt('error');
          setErrorApp_username_txtText('No disponible');
        }
      }else{
        setErrorApp_username_txt('error');
        setErrorApp_username_txtText('Error comprobant disponibilitat');
      }
    }else{
      setErrorApp_username_txt('error');
      setErrorApp_username_txtText('Minim 3 lletres');
    }
  }

  const changeApp_password = (valor) =>{
    setApp_password(valor);

    if(valor.length > 0){
      setErrorApp_password('');
    }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';
  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <>
    {props.popup!==undefined ? null :
      <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
        <LeftOutlined className="BotoBack"  onClick={props.callback}/>
      </div>
    }
    <div style={{padding:'10px 24px 10px 0px'}} ref={contenidorRef}>
    {id_familia<=0 || props.popup!==undefined ? null :
      <>
        <BotoFamilia id_familia={id_familia}/>
        <BotonsPares id_familia={id_familia} id_pare_actual={id}/>
        <BotonsFills id_familia={id_familia}/>
        <Divider />
      </>
    }
      <Form onFinish={submitForm}
        layout="vertical"
        colon={true}
        autoComplete="new-password"
      >
      {nou == true ? null :
        <Row>
          <Typography.Title level={4}>{`ID ${props.t('generic.responsable')}: ${id}`}</Typography.Title>
        </Row>
      }
      {nou == true ? null :
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{props.t('alumnes.estat.titol')}&nbsp;&nbsp;</label>
            <Select defaultValue={estat} onChange={(value) => setEstat(value)} style={{ width: 100}}>
              <Select.Option value="actiu">{props.t('alumnes.estat.actiu')}</Select.Option>
              <Select.Option value="baixa">{props.t('alumnes.estat.baixa')}</Select.Option>
            </Select>
          </span>
        </Form.Item>
      }
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} lg={12} xl={8}>
        <Form.Item label={props.t('pares.familia')} required={true} validateStatus={errorId_Familia} help={errorId_FamiliaText}>
          <Select
            defaultValue={id_familia} onChange={(value) => triaFamilia(value)}
            showSearch
            filterOption={(input, option) =>
              HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
            }>
            {families}
          </Select>
        </Form.Item>
        <Form.Item label={props.t('pares.relacio_familiar')} required={true} validateStatus={errorId_relacio_familiar} help={errorId_relacio_familiarText}>
          <Select defaultValue={id_relacio_familiar} onChange={(value) => triaRelacioFamiliar(value)}>
            {relacions}
          </Select>
        </Form.Item>
        <Form.Item label={props.t('pares.nom')} validateStatus={errorNom} required={true}>
          <Input name="nom" placeholder={props.t('pares.nom')} onChange={(e) => onChangeNom(e.target.value)} defaultValue={nom}/>
        </Form.Item>
        <Form.Item label={props.t('pares.cognom1')} validateStatus={errorCognom1} required={true}>
          <Input name="cognom1" placeholder={props.t('pares.cognom1')} onChange={(e) => onChangeCognom1(e.target.value)} defaultValue={cognom1}/>
        </Form.Item>
        <Form.Item  label={props.t('pares.cognom2')}>
          <Input name="cognom2" placeholder={props.t('pares.cognom2')} onChange={(e) => setCognom2(e.target.value)} defaultValue={cognom2}/>
        </Form.Item>
        <Form.Item label={props.t('pares.sexe')}>
          <Select defaultValue={sexe} onChange={triaSexe} style={{ width: 100}}>
            <Select.Option value="DONA">{props.t('pares.sexe_dona')}</Select.Option>
            <Select.Option value="HOME">{props.t('pares.sexe_home')}</Select.Option>
            <Select.Option value="ALTRE">{props.t('pares.sexe_altres')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={props.t('alumnes.naixement')}>
          <DatePicker
            onChange={changeNaixement}
            value={naixement ? moment(new Date(naixement), dateFormat) : ''}
            format={dateFormat}
            locale={localeDatePicker}
            picker="date"
            />
        </Form.Item>
        <Form.Item label={props.t('alumnes.naixement_ciutat')}>
          <Input name="naixement_ciutat" placeholder={props.t('alumnes.naixement_ciutat')} onChange={(e) => setNaixement_ciutat(e.target.value)} defaultValue={naixement_ciutat}/>
        </Form.Item>
        <Form.Item label={props.t('alumnes.nacionalitat')}>
          <Input name="nacionalitat" placeholder={props.t('alumnes.nacionalitat')} onChange={(e) => setNacionalitat(e.target.value)} defaultValue={nacionalitat}/>
        </Form.Item>
        <Form.Item label={props.t('pares.professio')}>
          <Input name="professio" placeholder={props.t('pares.professio')} onChange={(e) => setProfessio(e.target.value)} defaultValue={professio}/>
        </Form.Item>
        <Form.Item label={props.t('pares.empresa')}>
          <Input name="empresa" placeholder={props.t('pares.empresa')} onChange={(e) => setEmpresa(e.target.value)} defaultValue={empresa}/>
        </Form.Item>


        <Divider plain>{props.t('generic.dades_contacte')}</Divider>
        <Form.Item label={props.t('pares.tel1')}>
          <Input name="tel1" placeholder={props.t('pares.tel1')} onChange={(e) => setTel1(e.target.value)} defaultValue={tel1}/>
        </Form.Item>
        <Form.Item label={props.t('pares.tel2')}>
          <Input name="tel2" placeholder={props.t('pares.tel2')} onChange={(e) => setTel2(e.target.value)} defaultValue={tel2}/>
        </Form.Item>
        <Form.Item label={props.t('pares.email')}>
          <Input name="email" placeholder={props.t('pares.email')} onChange={(e) => setEmail(e.target.value)} defaultValue={email}/>
        </Form.Item>
      <Divider plain>{props.t('generic.dades_acces_app')}</Divider>
        <Form.Item label={props.t('pares.app_username')} validateStatus={errorApp_username_txt} help={errorApp_username_txtText}>
          <Input name="app_username_txt" placeholder={props.t('pares.app_username')} onChange={(e) => changeApp_username_txt(e.target.value)} defaultValue={app_username_txt} value={app_username_txt} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item label={props.t('pares.app_password')} validateStatus={errorApp_password}>
          <Input.Password name="app_password" placeholder={props.t('users.form_edit_password')} onChange={(e) => changeApp_password(e.target.value)} defaultValue={app_password} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item>
          <Row justify="start" align="middle">
            <Col style={{marginRight: '0.5rem'}}><span style={{color:'black'}}>{props.t('pares.obligar_modificar_password')}</span></Col>
            <Col>
              <Switch
              checkedChildren={props.t('generic.si')}
              unCheckedChildren={props.t('generic.no')}
              onChange={setObligar_modificar_password} defaultChecked={obligar_modificar_password}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row justify="start" align="middle">
            <Col style={{marginRight: '0.5rem'}}><span style={{color:'black'}}>{props.t('pares.app_locked')}</span></Col>
            <Col>
              <Switch
              checkedChildren={props.t('generic.si')}
              unCheckedChildren={props.t('generic.no')}
              onChange={setApp_locked} defaultChecked={app_locked}
              />
            </Col>
          </Row>
        </Form.Item>
      <Divider plain />
        <Form.Item label={props.t('pares.observacions')}>
          <Input.TextArea  name="observacions" placeholder={props.t('pares.observacions')} onChange={(e) => setObservacions(e.target.value)} defaultValue={observacions}/>
        </Form.Item>
        <Form.Item label={props.t('generic.idioma')}>
          <Select defaultValue={idioma} onChange={setIdioma} style={{ width: 110}} size="small">
            <Select.Option value="es">Castellano</Select.Option>
            <Select.Option value="ca">Català</Select.Option>
            <Select.Option value="en">English</Select.Option>
          </Select>
        </Form.Item>

      </Col>

      <Col xs={24} lg={12} xl={16}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24}>
            <Form.Item
              label={props.t('pares.dni')}
              labelCol={{span:2}}
              wrapperCol={{span:10}}
            >
              <Input name="dni" placeholder={props.t('pares.dni')} onChange={(e) => setDni(e.target.value)} defaultValue={dni}/>
            </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
              <ImatgeDNI
                image={dni_image_1}
                title={props.t('pares.dni_image_1')}
                dataUrl={dni_image_1_dataUrl}
                setDataUrl={setDni_image_1_dataUrl}
                setVisor={setVisor}
              />
          </Col>
          <Col  xs={24} xl={12}>
              <ImatgeDNI
                image={dni_image_2}
                title={props.t('pares.dni_image_2')}
                dataUrl={dni_image_2_dataUrl}
                setDataUrl={setDni_image_2_dataUrl}
                setVisor={setVisor}
              />
          </Col>
          <Col span={24} style={{paddingTop: '3rem'}}>
              <Form.Item
                label={props.t('pares.passaport')}
                wrapperCol={{span:12}}
              >
                <Input name="passaport" placeholder={props.t('pares.passaport')} onChange={(e) => setPassaport(e.target.value)} defaultValue={passaport}/>
              </Form.Item>
          </Col>
          <Col xs={24} xl={12}>
            <ImatgeDNI
              image={passaport_image_1}
              title={props.t('pares.passaport_image_1')}
              dataUrl={passaport_image_1_dataUrl}
              setDataUrl={setPassaport_image_1_dataUrl}
              setVisor={setVisor}
            />
          </Col>
          <Col xs={24} xl={12}>
            <ImatgeDNI
              image={passaport_image_2}
              title={props.t('pares.passaport_image_2')}
              dataUrl={passaport_image_2_dataUrl}
              setDataUrl={setPassaport_image_2_dataUrl}
              setVisor={setVisor}
            />
          </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
            <Col>
              <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Col>
</Row>
      </Form>
      { !visor.visible
        ? null
        : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
      }
    </div>
  </>
  );
}
export default translate(Edit);
