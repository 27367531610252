import React, { useState, useContext } from 'react';
import { translate } from 'react-multi-lang';

import { SyncOutlined, LeftOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {Form, Row, Col, Button, Input, InputNumber, DatePicker, Switch, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';


import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

const entities = new AllHtmlEntities();


function Edit(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [id] = useState(props.item.id);
  const [fini, setFini ] = useState(props.item.fini ? props.item.fini : moment.utc(new Date()));
  const [ffin, setFfin ] = useState(props.item.ffin ? props.item.ffin : moment.utc(new Date()));
  const [num_mesos, setNum_mesos] = useState(props.item.num_mesos);
  const [nom, setNom] = useState(props.item.nom);
  const [actual, setActual] = useState(props.item.actual === 1);

  const [guardant, setGuardant] = useState(false);

  const [errorNum_mesos, setErrorNum_mesos] = useState('');
  const [errorNom, setErrorNom] = useState('');
  const [errorFini, setErrorFini] = useState('');
  const [errorFfin, setErrorFfin] = useState('');

  const num_mesosChange = (value) =>{
    setNum_mesos(value);
    if(Number.isInteger(value)){
      setErrorNum_mesos('');
    }
  }
  const nomChange = (e) =>{
    const n = e.target.value.trim();
    setNom(n);
    if(n.length>0){
      setErrorNom('');
    }
  }
  const changeFini = (date, dateString) => {
    if(date){
      setFini(date.toISOString());
      setErrorFini('');
    }else{
      setFini(null);
    }
  }
  const changeFfin = (date, dateString) => {
    if(date){
      setFfin(date.toISOString());
      setErrorFfin('');
    }else{
      setFfin(null);
    }
  }


  const submitFormCreaCurs = async (values) => {

      let correcte = true;

      if(!Number.isInteger(num_mesos) || num_mesos<1){
        setErrorNum_mesos('error');
        correcte = false;
      }
      if(nom.length === 0){
        setErrorNom('error');
        correcte = false;
      }
      if(!fini){
        setErrorFini('error');
        correcte = false;
      }
      if(!ffin){
        setErrorFfin('error');
        correcte = false;
      }


      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        num_mesos,
        nom: entities.encode(nom),
        fini: moment.utc(fini),
        ffin: moment.utc(ffin),
        actual: actual ? 1 : 0,
      }
      console.log(JSON.stringify(dades));


      let url_api = '/cursescolar/'+id;

      try{
        const resposta = await Api.myFetch(url_api, 'PUT', JSON.stringify(dades));

        if(resposta.result.success === 1){
          //console.log(resposta);

          if(actual){
            const resposta2 = await Api.myFetch('/cursescolar/actualitzar/classes/alumnes', 'POST', JSON.stringify({new_id_curs_escolar: id}));
            if(resposta2.result.success === 1){

              let curs_escolar_actual = {
                id,
                nom,
                mesos: num_mesos
              }
              setPerfilUsuari({...perfilUsuari, curs_escolar_actual})

            }else{
              console.error('Error: submit Form Actualitzar Classes: ',resposta);
              message.config({top: 50});
              message.open({content: resposta2.data.message, duration:10});
            }
          }

          setGuardant(false);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();

        }else{
          setGuardant(false);
          console.error('Error: submit Form CursEscolar: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: CursEscolar guardar: ', error);
      }

  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return (
    <>
    <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
      <LeftOutlined className="BotoBack" onClick={props.callback}/>
    </div>
    <div style={{padding:'10px 24px 35px 0px'}}>


      <div style={{paddingTop: '20px'}}>
        <Form onFinish={submitFormCreaCurs}
          layout="vertical"
          wrapperCol={{span:7}}
          autoComplete="off"
        >
        <Form.Item
          validateStatus={errorNom}
          label={props.t('generic.nom')}
          required={true}
        >
          <Input placeholder={props.t('generic.nom')} onChange={nomChange} defaultValue={nom}/>
        </Form.Item>
          <Form.Item label={props.t('generic.fini')} required={true} validateStatus={errorFini}>
            <DatePicker
              onChange={changeFini}
              value={moment(fini).tz('Europe/Madrid')}
              //showTime={{ format: 'HH:mm' }}
              format={dateFormat}
              locale={localeDatePicker}
              />
          </Form.Item>
          <Form.Item label={props.t('generic.ffin')} required={true} validateStatus={errorFfin}>
            <DatePicker
              onChange={changeFfin}
              value={moment(ffin).tz('Europe/Madrid')}
              //showTime={{ format: 'HH:mm' }}
              format={dateFormat}
              locale={localeDatePicker}
              />
          </Form.Item>
          <Form.Item>
            <span className="ant-form-item-label">
              <label>{props.t('generic.curs_escolar_actual')}&nbsp;</label>
              <Switch
              disabled={props.item.actual === 1}
               checkedChildren={<CheckOutlined />}
               unCheckedChildren={<CloseOutlined />}
               onChange={setActual} defaultChecked={actual}
              />
            </span>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
            <Row type="flex" justify="start">
              <Col>
                <Button onClick={props.callback}>{props.t('generic.back')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
              </Col>
            </Row>
          </Form.Item>
          </Form>
      </div>

    </div>
    </>
  );
}
export default translate(Edit);
