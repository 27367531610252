import React, { useState, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, ThunderboltOutlined } from '@ant-design/icons';

import {Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Upload, Modal, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { Api, HtmlStrings } from '../../../../helpers';
import ImageCropper from '../../../ImageCropper';
import VisorImatges from "../../../visorImatges";
import DocumentUpload from "../../../DocumentUpload";

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY';


function Edit(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [id, setId] = useState(props.item.id);
  const [id_familia, setId_familia] = useState(props.item.id_familia);
  const [banc_iban, setBanc_iban] = useState(props.item.iban);
  const [banc_bic, setBanc_bic] = useState(props.item.bic);
  const [banc_mandat_url, setBanc_mandat_url] = useState(props.item.url_mandat);
  const [banc_titular, setBanc_titular] = useState(entities.decode(props.item.titular));


  const [visor, setVisor] = useState({visible: false});

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nou, setNou] = useState(props.item.id===0);
  const [errorBanc_titular, setErrorBanc_titular] = useState('');
  const [errorBanc_iban, setErrorBanc_iban] = useState('');
  const [errorBanc_ibanText, setErrorBanc_ibanText] = useState('');



  const submitForm = async (valors) => {

      if(!nou){
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{

        let correcte = true;

        if(!banc_titular){
          setErrorBanc_titular('error');
          correcte = false;
        }

        if(banc_iban.length!==24)
        {
          setErrorBanc_iban('error');
          setErrorBanc_ibanText(props.t('banc.iban_long'));
          correcte = false;
        }

        if(!correcte){return false}

          setGuardant(true);

          let dades = {
            id_usuari: perfilUsuari.account.id,
            titular: entities.encode(banc_titular),
            iban: banc_iban,
            bic: banc_bic
          }

        try{
          const resposta = await Api.myFetch('/banc/responsable', 'POST', JSON.stringify(dades));
          if(resposta.result.success === 1){
            setNou(false);
            setId(resposta.data.id);
            setGuardant(false);
            console.log('resposta', resposta);
            message.success(props.t('generic.OK_saved'), 5);
            //props.callback();
          }else{
            setGuardant(false);
            console.error('Error: submit Bancs: ',resposta);
            message.config({top: 50});
            message.open({content: resposta.data.message, duration:10});
          }
        }catch(error){
          setGuardant(false);
          message.config({top: 50});
          message.open({content: "Error saving", duration:10});
          console.error('Error: Familia guardar: ', error);
        }
      }
  }


  const modificaIban = (e) =>{
    let temp = e.target.value;
    temp = temp.replace(/\s/g, '')
    setBanc_iban(temp);
    if(temp.length===24){
      setErrorBanc_iban('');
      setErrorBanc_ibanText('');
    }
  }


  let posicio_botons = "start";
  if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <div style={{marginTop: '0.5rem' ,padding:'10px 24px 10px 0px', height:'650px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:6}}
        wrapperCol={{span:18}}
        colon={true}
        autoComplete="off"
      >
    <Row>
      <Col span={24}>

        <Form.Item
          label={props.t('banc.titular')}
          validateStatus={errorBanc_titular}
          required={nou}>
          {nou
            ? <Input name="banc_titular" placeholder={props.t('banc.titular')} onChange={(e) => setBanc_titular(e.target.value.trim())} defaultValue={banc_titular}/>
            : <span style={{fontSize: '1rem'}}><b>{banc_titular}</b></span>
          }

        </Form.Item>
        <Form.Item
          style={{marginBottom: 0}}
          label={props.t('banc.iban')}
          validateStatus={errorBanc_iban}
          help={errorBanc_ibanText}
          required={nou}>
          {nou
            ? <Input maxLength={24} name="banc_iban" placeholder={props.t('banc.iban')} onChange={(e) => modificaIban(e)} defaultValue={banc_iban} value={banc_iban}/>
            : <span style={{fontSize: '1rem'}}><b>{banc_iban}</b></span>
          }

        </Form.Item>
        <Form.Item label={props.t('banc.bic')}>
          {nou
            ? <Input name="banc_bic" placeholder={props.t('banc.bic')} onChange={(e) => setBanc_bic(e.target.value.trim())} defaultValue={banc_bic}/>
            : <span style={{fontSize: '1rem'}}><b>{banc_bic}</b></span>
          }
        </Form.Item>

        {nou ? null :
        <Col span={24} style={{marginLeft: '25px', marginBottom: '25px', paddingLeft: '5px'}}>
          <DocumentUpload
            fileList={banc_mandat_url ? [{uid: -1, status: 'done', url: banc_mandat_url, name: 'Mandat Actual', path: banc_mandat_url}] : []}
            url={global.api_url+'/banc/mandat/'+id}
            url_delete={null}
            titol={props.t('banc.pujar_mandat1')}
            subtitol={props.t('banc.pujar_mandat2')}
            />
        </Col>
        }
      </Col>
    </Row>

        <Col span={24}>
          <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
            <Col>
              <Button onClick={props.callback}>{props.t('generic.back')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Col>

      </Form>
      { !visor.visible
        ? null
        : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
      }
    </div>
  );
}
export default translate(Edit);
