import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import NomDescripcioIdiomes from '../../../NomDescripcioIdiomes';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id] = useState(props.item.id);
  const [id_acollida] = useState(props.item.id_acollida);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [text_ca, setText_ca] = useState(entities.decode(props.item.text['ca']));
  const [text_en, setText_en] = useState(entities.decode(props.item.text['en']));
  const [text_es, setText_es] = useState(entities.decode(props.item.text['es']));
  const [preu, setPreu] = useState(props.item.preu);

  const [guardant, setGuardant] = useState(false);

  const [errorOrdre, setErrorOrdre] = useState('');
  const [errorText_ca, setErrorText_ca] = useState('');
  const [errorText_en, setErrorText_en] = useState('');
  const [errorText_es, setErrorText_es] = useState('');

  const nou = (id===0);

  const ordreChange = (value) =>{
    setOrdre(value);
    if(Number.isInteger(value)){
      setErrorOrdre('');
    }
  }
  const text_caChange = (e) =>{
    const n = e.target.value.trim();
    setText_ca(n);
    if(n.length>0){
      setErrorText_ca('');
    }
  }
  const text_enChange = (e) =>{
    const n = e.target.value.trim();
    setText_en(n);
    if(n.length>0){
      setErrorText_en('');
    }
  }
  const text_esChange = (e) =>{
    const n = e.target.value.trim();
    setText_es(n);
    if(n.length>0){
      setErrorText_es('');
    }
  }


  const submitForm = async (valors) => {

      let correcte = true;

      if(!Number.isInteger(ordre)){
        setErrorOrdre('error');
        correcte = false;
      }
      if(text_ca.length === 0){
        setErrorText_ca('error');
        correcte = false;
      }
      if(text_en.length === 0){
        setErrorText_en('error');
        correcte = false;
      }
      if(text_es.length === 0){
        setErrorText_es('error');
        correcte = false;
      }


      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        id_acollida,
        ordre,
        text_ca: entities.encode(text_ca),
        text_en: entities.encode(text_en),
        text_es: entities.encode(text_es),
        preu
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/acollida/propietat' : '/acollida/propietat/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();

        }else{
          setGuardant(false);
          console.error('Error: submit Form acollida_propietats: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: acollida_propietats guardar: ', error);
      }

  }


  return (
    <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="horizontal"
      labelCol={{span:2}}
      wrapperCol={{span:22}}
      colon={true}
      autoComplete="off"
    >
      <Form.Item
        validateStatus={errorOrdre}
        colon={true}
        label={props.t('acollida_propietats.ordre')}
        labelCol={{span:2}}
        wrapperCol={{span:3}}
        required={true}
      >
          <InputNumber name="ordre" onChange={ordreChange} defaultValue={ordre} min={0} step={1} />
      </Form.Item>

      <NomDescripcioIdiomes
        errorNom_ca = {errorText_ca}
        errorNom_en = {errorText_en}
        errorNom_es = {errorText_es}
        nomChange_ca = {text_caChange}
        nomChange_en = {text_enChange}
        nomChange_es = {text_esChange}
        nom_ca = {text_ca}
        nom_en = {text_en}
        nom_es = {text_es}
        visibleDescripcio = {false}
      />

          <Form.Item label={props.t('acollida_propietats.preu')}>
              <InputNumber name="preu" onChange={setPreu} defaultValue={preu}
              min={0.0} step={1.0}
              formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={value => value.replace(/€\s?|(,*)/g, '')}
              />
          </Form.Item>

        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export default translate(Edit);
