import React from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Card } from 'antd';

import ContainerPagina from '../../components/ContainerPagina';
import TitolPagina from '../../components/TitolPagina';
import AlumnesExportaExcel from '../../components/Formularis/perfils/admin/AlumnesExportaExcel';
import AlumnesExportaExcelSanitat from '../../components/Formularis/perfils/admin/AlumnesExportaExcelSanitat';
import AlumnesExportaExcelNacionalitat from '../../components/Formularis/perfils/admin/AlumnesExportaExcelNacionalitat';
import ParesBus from '../../components/Formularis/perfils/admin/ParesBusExportaExcel';
import EnquestaExportaExcel  from '../../components/Formularis/perfils/admin/EnquestaExportaExcel';
import AlumnesModelo233ExportaExcel from '../../components/Formularis/perfils/admin/AlumnesModelo233ExportaExcel';

import moment from 'moment';
import 'moment/locale/ca';


function LlistatsExcel(props){

  let marca_temps = moment().format('YYYYMMDD_HHmm');

  return (
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.LlistatsExcel')}/>
      <Row style={{ margin: '1rem' }} gutter={[10,10]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Card title="Llistat Alumnes" bordered={true} style={{ padding: '1rem' }}>
          <p>Llistat dels alumnes amb les dades de contacte dels pares. Inclou columna estat amb els valors actiu, reserva i baixa.</p>
          <AlumnesExportaExcel btn_txt="Llistats Alumnes" nom_fulla="Alumnes" nom_fitxer={'Alumnes'}/>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Card title="Llistat Alumnes Sanitat" bordered={true} style={{ padding: '1rem' }}>
          <p>Llistat dels alumnes amb les dades que demana sanitat</p>
          <AlumnesExportaExcelSanitat btn_txt="Llistats Alumnes Sanitat" nom_fulla="Alumnes Sanitat" nom_fitxer={'AlumnesSanitat'}/>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Card title="Llistat Alumnes Nacionalitat" bordered={true} style={{ padding: '1rem' }}>
          <p>Llistat simple dels alumnes amb les dades de nacionalitat i adreça</p>
          <AlumnesExportaExcelNacionalitat btn_txt="Llistats Alumnes Nacionalitat" nom_fulla="Alumnes Nacionalitat" nom_fitxer={'AlumnesNacionalitat'}/>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Card title="Llistat Pares Bus" bordered={true} style={{ padding: '1rem' }}>
          <p>Dades de contacte dels pares que usen el servei de Bus per els seus fills.</p>
          <ParesBus linia="reus" btn_txt="Bus Reus" nom_fulla="Bus Reus" nom_fitxer={'ParesBusReus'}/>
          <ParesBus linia="tarragona" btn_txt="Bus Tarragona" nom_fulla="Bus Tarragona" nom_fitxer={'ParesBusTarragona'}/>
          <ParesBus linia="zona_nord" btn_txt="Bus Zona Nord" nom_fulla="Bus Zona Nord" nom_fitxer={'ParesBusZonaNord'}/>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Card title="Llistat Enquesta" bordered={true} style={{ padding: '1rem' }}>
          <p>Llistat de l'enquesta de les extraescolars i transport</p>
          <EnquestaExportaExcel btn_txt="Enquesta" nom_fulla={`Enquesta_${moment().format('YYYYMMDD_HHmm')}`} nom_fitxer={`Enquesta_${moment().format('YYYYMMDD_HHmm')}`}/>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Card title="Modelo 233" bordered={true} style={{ padding: '1rem' }}>
          <p>Modelo 233. Declaración informativa por gastos en guarderías o centros de educación infantil autorizados.</p>
          <AlumnesModelo233ExportaExcel btn_txt="Modelo 233" nom_fulla={`Modelo233_${moment().format('YYYYMMDD_HHmm')}`} nom_fitxer={`Modelo233_${moment().format('YYYYMMDD_HHmm')}`}/>
          </Card>
        </Col>
      </Row>
    </ContainerPagina>
  )

}
export default translate(LlistatsExcel);
