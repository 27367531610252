import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/ca';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { translate } from 'react-multi-lang';
import { Table, Row, Col, Pagination, DatePicker, Input, Space, Button, Modal, Select, Form, Tooltip, Popconfirm } from 'antd';
import { SearchOutlined, GoldOutlined, CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../../components/TitolPagina';
import PassarLlista from '../../../components/Formularis/perfils/profe/PassarLlista';
import { Api, HtmlStrings } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';
const dateRangeFormat = 'DD/MM/YYYY';


function RollCall(props) {

  const [llista, setLlista] = useState([]);
  //const [llistaFiltrada, setLlistaFiltrada] = useState([]);
  //const [filtreCreated, setFiltreCreated] = useState([]);
  const [filtreTipus, setFiltreTipus] = useState([]);
  const [filtreAmbit, setFiltreAmbit] = useState([]);

  const [fini, setFini] = useState(moment());
  const [ffin, setFfin] = useState(moment());
  const [dataEnCurs, setDataEnCurs] = useState(moment());
  const [modalVisible, setModalVisible] = useState(false);
  const [refrescar, setRefrescar] = useState(0);
  const [tipus, setTipus] = useState('');
  const [id_taula, setId_taula] = useState(0);
  const [optionsAmbit, setOptionsAmbit] = useState([]);
  const [dataAmbit, setDataAmbit] = useState([]);
  const [id_ambit, setId_ambit] = useState(null);
  const [id_profe, setId_profe] = useState(0);
  const [optionsProfessors, setOptionsProfessors] = useState([]);
  const [isLoading, setIsLoading] = useState([]);


  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  useEffect(() => {
    if(modalVisible==true){
      console.log('id_profe', id_profe);
      console.log('tipus', tipus);
      console.log('id_taula', id_taula);
      console.log('dataAmbit', dataAmbit);
    }
  },[modalVisible]);


  useEffect(() => {
    buscarDades();
  },[refrescar, fini, ffin]);

  useEffect(() => {
    generarOptionsAmbit();
    if(id_taula > 0){
      const index = dataAmbit.findIndex(item => (item.id_taula == id_taula && item.tipus == tipus));
      if(index > -1){
        setId_ambit(index)
      }else{
        setId_ambit(null);
      }
    }else{
      setId_ambit(null);
    }
  },[dataAmbit]);

  useEffect(()=>{
    buscarProfessors();
  },[])

  useEffect(()=>{
    buscarRelacions();
  },[id_profe, tipus, id_taula])
/*
  useEffect(() => {
    filtrarPerDates();
  },[llista, fini, ffin]);
*/
  const buscarDades = async () => {
    setIsLoading(true);
    //const r = await Api.get('/rollcall');

    const data_inicial = fini ? fini.clone().format('YYYY-MM-DD') : null;
    const data_final = ffin ? ffin.clone().add(1, 'days').format('YYYY-MM-DD') : null;

    const r = await Api.get(`/rollcall/${data_inicial}/${data_final}`);

    if(r.result.success===1){
      setLlista(r.data.rollcalls);
      let llTipus = [];
      let llAmbits = [];
      r.data.rollcalls.map(item => {
        llTipus.push(item.tipus);
        llAmbits.push(item.ambit);
      });

      //Treure Duplicats
      llTipus = llTipus.filter((item, index, arr) => arr.indexOf(item) == index);
      llAmbits = llAmbits.filter((item, index, arr) => arr.indexOf(item) == index);

      //Ordenar
      llTipus.sort();
      llAmbits.sort();

      //Crear Filtres
      const llTipusFiltre = llTipus.map(item => {
        return {text: item, value: item}
      });
      setFiltreTipus(llTipusFiltre);

      const llAmbitsFiltre = llAmbits.map(item => {
        return {text: entities.decode(item), value: item}
      });
      setFiltreAmbit(llAmbitsFiltre);


      /*
      const llFiltrada = r.data.rollcalls.filter((item, index, array) => moment(item.date).isBetween(fini, ffin, 'day', '[]'));
      setLlistaFiltrada(llFiltrada);
      */
      /*
      const createdList = r.data.rollcalls.map((item) => {
        return moment(item.created_at).format('DD/MM/YYYY')
      });
      const createdListSenseDuplicats = createdList.filter((item, index, arr) => arr.indexOf(item) == index);
      const filtre = createdListSenseDuplicats.map(item => {
        return {text: item, value: item}
      });
      setFiltreCreated(filtre);
      */
    }
    setIsLoading(false);
  }

  /*const filtrarPerDates = () =>{

    if(!fini && !ffin){
      setLlistaFiltrada(llista);
    }else{
      const llFiltrada = llista.filter((item, index, array) => moment(item.date).isBetween(fini, ffin, 'day', '[]'));
      setLlistaFiltrada(llFiltrada);
    }

  }*/

  const netejarFiltres = () =>{
    setFini('');
    setFfin(null);
  }
  const avuiFiltre = () => {
    setFini(moment());
    setFfin(moment());
  }


  const onDateRangeChange = (dates, dateStrings) => {
    setFini(dates ? dates[0] : '');
    setFfin(dates ? dates[1] : '');
    /*
    const llFiltrada = llista.filter((item, index, array) => moment(item.date).isBetween(dates[0], dates[1], 'day', '[]'));
    setLlistaFiltrada(llFiltrada);
    */

  }
  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

// Editar Afegir a traves de modal
  const tancarModal = e =>{
    setRefrescar(refrescar+1);
    setModalVisible(false);
  }

  const afegirRoll = () =>{
    setId_ambit(null);
    setTipus('');
    setId_taula(0);
    setDataEnCurs(moment());
    setId_profe(0);
    setModalVisible(true);
  }

  const editarRoll = (item) =>{
    setTipus(item.tipus);
    setId_taula(item.id_taula);
    setDataEnCurs(moment(item.date));
    setId_profe(item.id_profe);
    setModalVisible(true);
  }
  const submitDelete = async (id) => {
    const r = await Api.myFetch(`/rollcall/${id}`, 'DELETE');
    if(r.result.success===1){
      setRefrescar(refrescar+1);
    }
  }

  const dataEnCursChange = (data, dataString) => {
    if(data){
      setDataEnCurs(data);
    }else{
      setDataEnCurs(null);
    }
  }

  const id_profeChange = (id) =>{
    setTipus('');
    setId_taula(0);
    setId_ambit(null);
    setId_profe(id);
  }
  const ambitChange = (triat) =>{
    setId_ambit(triat);
    setTipus(dataAmbit[triat].tipus);
    setId_taula(dataAmbit[triat].id_taula);
    console.log('ambitChange', triat)
  }

  const buscarProfessors = async () =>{
    const p = await Api.get('/professors');
    if(p.result.success===1){

      const profes = p.data.professors.map((item)=>{
        return(
          <Select.Option key={item.id} value={item.id}>
            {`${entities.decode(item.nom)} ${entities.decode(item.cognom1)}`}
          </Select.Option>
        );
      })
      setOptionsProfessors(profes);
    }
  }

  const buscarRelacions = async () =>{
    if (id_profe == 0) {
      setDataAmbit([]);
      setOptionsAmbit([]);
      return null;
    };

    const p = await Api.get(`/profe/classes/${id_profe}`);
    if(p.result.success===1){

      let ambit_data = [];
      //let valor = [];

      if(p.data.classes){
        const profes = p.data.classes.map((item)=>{
          const valor = {
            tipus: 'classe',
            id_taula: item.id,
            text: `${props.t('menu.Classes')}: ${entities.decode(item.nom)}`
          }
          ambit_data.push(valor);
        });
      }

      if(p.data.extraescolars){
        const profes = p.data.extraescolars.map((item)=>{
          const text = eval(`item.nom_${props.t('idioma')}`);
          const valor = {
            tipus: 'extraescolar',
            id_taula: item.id,
            text: `${props.t('menu.Extraescolars')}: ${entities.decode(text)}`,
          }
          ambit_data.push(valor);
        });
      }

      const anar = await Api.get('/transport/sentit/anada');
      if(anar.result.success===1){
        anar.data.transport.map((item) =>{
          const text = entities.decode(item.nom[props.t('idioma')]).replace('The British School Costa Daurada', 'School');
          const valor = {
            tipus: 'bus',
            id_taula: item.id,
            text: `${props.t('menu.Transport')}: ${text}`,
          }
          ambit_data.push(valor);
        });
      }
      const tornar = await Api.get('/transport/sentit/tornada');
      if(tornar.result.success===1){
        tornar.data.transport.map((item) =>{
          const text = entities.decode(item.nom[props.t('idioma')]).replace('The British School Costa Daurada', 'School');
          const valor = {
            tipus: 'bus',
            id_taula: item.id,
            text: `${props.t('menu.Transport')}: ${text}`,
          }
          ambit_data.push(valor);
        });
      }





      setDataAmbit(ambit_data);
    }else{
      setDataAmbit([]);
      setOptionsAmbit([]);
    }
  }

const generarOptionsAmbit = () =>{
  const ambit_options = dataAmbit.map((item, index)=>{
    return(
      <Select.Option key={index} value={index}>
        {item.text}
      </Select.Option>
    );
  });
  setOptionsAmbit(ambit_options);
}


//FI Editar Afegir a traves de modal

const columnesTaulaPropietats = [
    {
      title: '',
      dataIndex: "present",
      key: "present",
      render: (text,record) => <span>{record.present ? <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>}</span>

    },
    {
      title: props.t('alumnes.nom'),
      dataIndex: "nom",
      key: "nom",
      render: (text,record) => <span>{entities.decode(record.nom+' '+record.cognom1+' '+record.cognom2)}</span>

    },
    {
      title: 'Motiu',
      dataIndex: 'motiu',
      key: "motiu",
      render: (text,record) => <span>{entities.decode(record.motiu)}</span>

    },
    {
      title: 'Observacions',
      dataIndex: 'observacions',
      key: "observacions",
      render: (text,record) => <span>{entities.decode(record.observacions)}</span>

    },

  ];

const columnes =[
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title: props.t('generic.data'),
    key:"date",
    sorter: (a, b) => moment(a.date).diff(moment(b.date)),
    render: (text,record) =>(record.date ? moment(record.date).format('DD/MM/YYYY') : '')
  },{
    title:props.t('botiga.nom'),
    dataIndex: 'profe',
    key:"profe",
    render: (text,record) => (entities.decode(record.profe)),
    sorter: (a, b) => { return entities.decode(a.profe).localeCompare(entities.decode(b.profe))},
    ...getColumnSearchProps('profe',props.t('botiga.nom'))
  },{
    title: props.t('menjador.tipus'),
    key: "tipus",
    render: (text,record) =>(entities.decode(record.tipus)),
    filters: filtreTipus,
    onFilter: (value, record) => (record.tipus === value),
    sorter: (a, b) => { return entities.decode(a.tipus).localeCompare(entities.decode(b.tipus))},
  },{
    title: props.t('professors_relacions.ambit'),
    key: "ambit",
    ellipsis: true,
    render: (text,record) => (entities.decode(record.ambit)),
    filters: filtreAmbit,
    onFilter: (value, record) => (record.ambit === value),
    sorter: (a, b) => { return entities.decode(a.ambit).localeCompare(entities.decode(b.ambit))},
  },{
    title: props.t('generic.updated_at'),
    key: "updated_at",
    sorter: (a, b) => moment(a.date).diff(moment(b.date)),
    render: (text,record) =>(
      record.updated_at ? moment(record.updated_at).format(dateFormat) : ''
    )
  },{
    title: props.t('generic.created_at'),
    key: "created_at",
    //filters={filtreCreated}
    //onFilter={(value, record) => moment(record.created_at).format('DD/MM/YYYY') === value}
    sorter: (a, b) => moment(a.date).diff(moment(b.date)),
    render: (text,record) =>(
        record.created_at ? moment(record.created_at).format(dateFormat) : ''
    )
  },{
    title: props.t('generic.created_by'),
    key: "created_by_name",
    ellipsis: true,
    render: (text,record) => (entities.decode(record.created_by_name)),
    sorter: (a, b) => { return entities.decode(a.created_by_name).localeCompare(entities.decode(b.created_by_name))},
  },{
    key: "actions",
    align: "right",
    width: "75px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id}>
        <Col span={12 }>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EditOutlined />} onClick={(e) => editarRoll(record)} ghost="true" className="BotoTaula"/>
          </Tooltip>
        </Col>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.delete')}>
            <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText={props.t('generic.si')} cancelText={props.t('generic.no')} onConfirm={()=>submitDelete(record.id)}>
              <Button icon={<DeleteOutlined />} data-record={JSON.stringify(record)} ghost="true" className="BotoTaula"/>
            </Popconfirm>
          </Tooltip>
        </Col>
      </Row>
  )
  }
];



//return <Col span={12} style={{margin: 0, padding: 0}}><Table style={{backgroundColor: '#eeeeee'}} columns={columnesTaulaPropietats} dataSource={row.propietats} pagination={false} size={'small'} bordered={false}/></Col>;
const subTaulaPropietats = row => {
  return <Col span={12} style={{margin: 10, padding: 0}}><Table rowKey="id" columns={columnesTaulaPropietats} dataSource={row.alumnes} pagination={false} size={'small'} bordered={false} /></Col>;
};

  //console.log(props.id_categoria);
  return(
    <>
      <TitolPagina txt={props.t('professors_relacions.passarLlista')} level={4} style={{marginTop:'1rem', marginLeft:'0.5rem', paddingLeft:'0.5rem'}}/>
      <div style={{marginTop:'1rem', marginLeft:'1rem', marginRight: '1rem', padding: '1rem'}}>
      <Row align="middle" justify="space-between" style={{margin: '1rem'}} gutter={5}>
        <Col>
          <Space>
            <span><b>{props.t('generic.filtre')}:&nbsp;</b></span>
            <DatePicker.RangePicker
              value={[fini ? moment(fini, dateRangeFormat) : null, ffin ? moment(ffin, dateRangeFormat) : null]}
              format={dateRangeFormat}
              locale={localeDatePicker}
              onChange={onDateRangeChange}
              size="large"
            />

            <Button type="primary" onClick={avuiFiltre}>{props.t('generic.avui')}</Button>

            <Button type="primary" onClick={netejarFiltres}>{props.t('generic.tot')}</Button>
          </Space>
        </Col>
        <Col>
          <Button type="primary" onClick={afegirRoll}>{props.t('generic.add')}</Button>
        </Col>
      </Row>
        <Table
          loading={isLoading}
          pagination={{showSizeChanger: true, defaultPageSize: 15, pageSizeOptions:[10,15,20,25,50,100]}}
          rowKey="id"
          expandedRowRender={props.id_categoria === 2 ? null : subTaulaPropietats}
          dataSource={llista}
          locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
          columns={columnes}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {}, //onClick: (e) => showEdit(record), // click row
              onDoubleClick: (e) => editarRoll(record), // double click row
              onContextMenu: (e) => {e.preventDefault(); editarRoll(record);}, //event => {}, // right button click row
            };
          }}
        />
      </div>
      <Modal
          key="rollcall"
          title={`${id_taula ==0 ? props.t('generic.add') : props.t('generic.modify')} ${props.t('professors_relacions.passarLlista')}`}
          visible={modalVisible}
          footer={null}
          onCancel={tancarModal}
          width={900}
          style={{top:20}}
          destroyOnClose={true}
          forceRender={true}
        >

        <Form.Item label={props.t('generic.data')}>
          <DatePicker
            value={dataEnCurs ? moment(dataEnCurs, dateRangeFormat) : null}
            format={dateRangeFormat}
            locale={localeDatePicker}
            onChange={dataEnCursChange}
            size="large"/>
        </Form.Item>
        <Form.Item label={props.t('menu.Professors')}>
          <Select
            showSearch
            filterOption={(input, option) =>
              HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
            }
             defaultValue={id_profe == 0 ? '' : id_profe}
             onChange={(value) => id_profeChange(value)}
          >
            {optionsProfessors}
          </Select>
        </Form.Item>
      {optionsAmbit.length ==0 ? null :
        <Form.Item label={props.t('professors_relacions.ambit')}>
          <Select value={id_ambit === null ? '' : id_ambit} defaultActiveFirstOption={true} onChange={(value) => ambitChange(value)}>
            {optionsAmbit}
          </Select>
        </Form.Item>
      }

        { !dataEnCurs || id_profe == 0 || tipus == '' || id_taula == 0 ? null :
          <PassarLlista id_profe={id_profe} tipus={tipus} id_taula={id_taula} dataEnCurs={dataEnCurs.format('YYYY-MM-DD')} modal={true}/>
        }
      </Modal>
    </>
  );

}

export default translate(RollCall);
