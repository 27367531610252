import React, { useState, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Layout, Row, Col, BackTop, Drawer } from 'antd'; //, Drawer
import { BrowserView, MobileView } from 'react-device-detect';

import { MenuTriatContext } from "../context/menu-triat";
import { PerfilUsuariContext } from "../context/perfil-usuari";

import MenuSuperior from '../components/MenuSuperior';
import MenuSuperiorMobile from '../components/MenuSuperiorMobile';

// Perfil Administrador
import Dashboard from './Dashboard';

// Perfil Pares / Responsables
import MenuLateralPares from '../components/MenuLateralPares';
import ParesBotiga from './perfils/pares/Botiga';
import ParesPare from '../components/Formularis/perfils/pares/Pares';
import ParesAlumnes from './perfils/pares/Alumnes';
import ParesFamilia from '../components/Formularis/perfils/pares/UnitatFamiliar';
import ParesBancs from './perfils/pares/Banc';
import ParesMedic from './perfils/pares/Medic';
import ParesAutoritzats from './perfils/pares/Autoritzats';
import ParesDocuments from './perfils/pares/Documents';
import ParesExtraescolars from './perfils/pares/Extraescolars';
import ParesTransport from './perfils/pares/Transport';
import ParesMenjador from './perfils/pares/Menjador';
import ParesAcollida from './perfils/pares/Acollida';
import ParesRebuts from './perfils/pares/Rebuts';
import ParesNotificar from './perfils/pares/Notificacions';
import ParesMealTimeReport from './perfils/pares/MealTimeReport';

const { Header, Sider, Content } = Layout;

function Principal(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [collapsed, setCollapsed] = useState(false)

  const anarMenu = ({key}) =>{
    setMenuTriat(key);
  };

    let contingut = '';

    switch (menuTriat) {
      case 'Dashboard':
        contingut = <Dashboard/>;
        break;
      case 'Pares_Botiga':
        contingut = <ParesBotiga/>;
        break;
      case 'Pares_DadesPersonals':
        contingut = <ParesPare/>;
        break;
      case 'Pares_DadesFills':
        contingut = <ParesAlumnes/>;
        break;
      case 'Pares_DadesFamilia':
        contingut = <ParesFamilia/>;
        break;
      case 'Pares_ServeiMedic':
      case 'Pares_ServeiMedicArrel':
        contingut = <ParesMedic/>;
        break;
      case 'Pares_DadesAutoritzats':
        contingut = <ParesAutoritzats/>;
        break;
      case 'Pares_Documents':
        contingut = <ParesDocuments/>;
        break;
      case 'Pares_ElsRebuts':
        contingut = <ParesRebuts/>;
        break;
      case 'Pares_DadesFacturacio':
        contingut = <ParesBancs/>;
        break;
      case 'Pares_Transport':
        contingut = <ParesTransport/>;
        break;
      case 'Pares_Extraescolars':
        contingut = <ParesExtraescolars/>;
        break;
      case 'Pares_Menjador':
        contingut = <ParesMenjador/>;
        break;
      /*case 'Pares_Acollida':
        contingut = <ParesAcollida/>;
        break;*/
      case 'Pares_Notificar':
        contingut = <ParesNotificar/>;
        break;
      case 'Pares_MealTimeReport':
        contingut = <ParesMealTimeReport/>;
        break;

//logout
      case 'Logout':
        contingut = props.t('menu.cerrar_sesion');
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtRefreshToken');
        sessionStorage.removeItem('perfil');
        props.logout();
        break;
      default:
        contingut = props.t('menu.cerrar_sesion');
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtRefreshToken');
        sessionStorage.removeItem('perfil');
        props.logout();
    }

    let menuSegonsPerfil = <MenuLateralPares/>;
    let colorHeader = '#fdd998';

    const toggleCollapsed = () =>{
      setCollapsed(!collapsed);
    }

    return(
      <div>
        <Layout style={{backgroundColor: 'white', height: '100vh'}}>
          <Header className="menu_superior" style={{backgroundColor: colorHeader}}>
            <BrowserView>
              <MenuSuperior toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
            </BrowserView>
            <MobileView>
              <MenuSuperiorMobile toggleCollapsed={toggleCollapsed} collapsed={collapsed}/>
            </MobileView>
          </Header>
          <Layout>
            <BrowserView>
              <Sider trigger={null} width="240" breakpoint="lg" collapsible collapsed={collapsed} className="MenuLateral">
                {menuSegonsPerfil}
              </Sider>
            </BrowserView>
            <MobileView>
              <Drawer
              width="325"
              title={<span style={{ fontSize: '16px', color: 'black' }}>{props.t('login.User')}: {perfilUsuari.account.username}</span>}
              placement="left"
              closable={true}
              onClose={toggleCollapsed}
              visible={collapsed}
              >
                {menuSegonsPerfil}
              </Drawer>
            </MobileView>
            <Content id="contenidor" style={{backgroundColor: 'white', borderTop: '1px solid #F5F5F5'}}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                 <BackTop visibilityHeight={250} target={() => document.getElementById('contenidor')}/>
                {contingut}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }


export default translate(Principal);
