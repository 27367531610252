import React, {useContext, useState, useEffect} from 'react';
import { translate } from 'react-multi-lang';
import { Tabs, Input, Space, Button, Popover} from 'antd';
import { SearchOutlined, AlertFilled, EyeOutlined, BulbFilled } from '@ant-design/icons';

import Moment from 'react-moment';
import moment from 'moment';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';

import { AllHtmlEntities } from 'html-entities';
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import Edit from '../../../components/Formularis/perfils/profe/MealTimeReport';

import LlistaEditableModalNouEditComandes from '../../../components/LlistaEditableModalNouEditComandes';

import { Api, InitDades } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const entities = new AllHtmlEntities();
moment.locale('es');
const limitTextMostrat = 50;

function MealTimeReport(props){

  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  const [filtresClasses, setFiltresClasses] = useState([]);
  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA


  useEffect(() => {
    creaFiltreClasses(perfilUsuari.curs_escolar_actual.id);
  },[]);

  const creaFiltreClasses = async (curs) => {
    const cla = await Api.get(`/classes/all/${curs}`);
    if(cla.result.success!==1){return [];}

    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    setFiltresClasses(filtreCl);
  }

  const buscarDades = async () =>{
    const mtr = await Api.get(`/mtr/curs/${perfilUsuari.curs_escolar_actual.id}`);
    if(mtr.result.success!==1){return [];}
    return mtr.data.reports;
  }

  const eliminar = async (id) => {
    const del = await Api.myFetch(`/mtr/${id}`, 'DELETE');
    if(del.result.success===1){

    }
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA


  const filtreSemafor =[
    {text: <BulbFilled style={{color: `${props.t('menjador.report.semafor.verd')}`}}/>, value: 'verd'},
    {text: <BulbFilled style={{color: `${props.t('menjador.report.semafor.groc')}`}}/>, value: 'groc'},
    {text: <BulbFilled style={{color: `${props.t('menjador.report.semafor.vermell')}`}}/>, value: 'vermell'}
  ];

  const columns_puntual = [
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    defaultSortOrder: 'descend',
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title: props.t('menjador.report.semafor_titol'),
    key:"semafor",
    filters: filtreSemafor,
    defaultFilteredValue:['groc','vermell'],
    onFilter: (value, record) => record.semafor === value,
    sorter: (a, b) =>  a.semafor.localeCompare(b.semafor),
    render: (text,record) => (<BulbFilled style={{color: `${props.t(`menjador.report.semafor.${record.semafor}`)}`}}/>)
  },{
    title: props.t('menjador.report.tipus.titol'),
    key:"tipus",
    sorter: (a, b) =>  a.tipus.localeCompare(b.tipus),
    render: (text,record) => (<span>{props.t(`menjador.report.tipus.${record.tipus}`)}</span>),
    filters: [
      {text: props.t('menjador.report.tipus.comportament'), value: 'comportament'},
      {text: props.t('menjador.report.tipus.menjar'), value: 'menjar'},
    ],
    onFilter: (value, record) => record.tipus === value,
  },{
    title:"Week",
    key:"week",
    sorter: (a, b) =>  a.week - b.week,
    render: (text,record) => {

      const w = parseInt(record.week.toString().substring(5));
      const y = parseInt(record.week.toString().substring(0,4));
      const setmana = moment().year(y).isoWeek(w);

      return(
        <span>{setmana.startOf('week').format('DD/MM/YYYY')} to {setmana.endOf('week').format('DD/MM/YYYY')}</span>
      )
    }
  },{
    title: props.t('alumnes.nom'),
    key:"alumne",
    dataIndex: 'alumne',
    sorter: (a, b) => { return entities.decode(a.alumne).localeCompare(entities.decode(b.alumne))},
    ...getColumnSearchProps('alumne',props.t('alumnes.nom'))
  },{
    title: props.t('alumnes.classe'),
    key:"classe",
    dataIndex: 'classe',
    filters: filtresClasses,
    onFilter: (value, record) => record.id_classe === value,
    render: (text,record) =>(entities.decode(record.classe)),
    sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
  },{
    title: props.t('menjador.report.frases'),
    key:"frase",
    render:(text,record) => {
      const puntets = record.frase[props.t('idioma')].length > limitTextMostrat ? "..." : "";
      return (
      <Popover style={{width: '800px'}} content={entities.decode(record.frase[props.t('idioma')])} title={props.t('botiga_propietats.text')+' CA'}>
        {entities.decode(record.frase[props.t('idioma')]).slice(0,limitTextMostrat)+puntets}
      </Popover>
    )
      }
  },{
    title: props.t('generic.created_at'),
    key:"created_at",
    sorter: (a, b) => moment(a.created_at).diff(moment(b.created_at)),
    responsive:['md'],
    render:(text,record) => (record.created_at ? <Moment date={new Date(record.created_at)} format="DD/MM/YYYY"/> : "" )
  }];

  let itemBuit = InitDades.menjador_report();
  itemBuit.id_curs_escolar = perfilUsuari.curs_escolar_actual.id;

  return(
    <ContainerPagina xl={24}>
    <TitolPagina txt={props.t('menjador.report.titol')}/>
    <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
    <LlistaEditableModalNouEditComandes
      txt_creats={props.t('menjador.report.report')}
      txt_nou={props.t('generic.add')}
      buscarDades={buscarDades}
      edit={Edit}
      add={Edit}
      delete={eliminar}
      itemBuit={itemBuit}
      columns={columns_puntual}
      />
      </div>
      </ContainerPagina>
  );
}

export default translate(MealTimeReport);
