import React from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/Pares';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

class Pares extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: '',
    };
  }

  buscarDades = async () => {
    const fam = await Api.get('/pares');
    if(fam.result.success===1){
      return fam.data.pares;
    }
  }
  marcarEliminat = async (id) => {
    //const fam = await Api.myFetch('/pares/'+id, 'DELETE');
    const fam = await Api.myFetch(`/pares/baixa/${id}`, 'DELETE');
    if(fam.result.success===1){

    }
  }

  getColumnSearchProps = (dataIndex, nom) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${this.props.t('generic.cercar')} ${nom}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('generic.cercar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      //record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      record[dataIndex] ? this.arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(this.arreglarTxt4Search(value)) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text => (entities.decode(text)),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }
render(){

  const filtreEstat = [
    {text: this.props.t('alumnes.estat.actiu'), value: 'actiu'},
    {text: this.props.t('alumnes.estat.baixa'), value: 'baixa'}
  ];

  const columns = [
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title: this.props.t('pares.nom'),
    key:"nom",
    dataIndex: 'nom',
    render: (text,record) =>(entities.decode(record.nom)),
    sorter: (a, b) => { return entities.decode(a.nom).localeCompare(entities.decode(b.nom))},
    ...this.getColumnSearchProps('nom',this.props.t('pares.nom'))
  },{
    title: this.props.t('pares.cognom1'),
    key: "cognom1",
    dataIndex: 'cognom1',
    render: (text,record) =>(entities.decode(record.cognom1)),
    sorter: (a, b) => { return entities.decode(a.cognom1).localeCompare(entities.decode(b.cognom1))},
    ...this.getColumnSearchProps('cognom1',this.props.t('pares.cognom1'))
  },{
    title:this.props.t('pares.cognom2'),
    key:"cognom2",
    dataIndex: 'cognom2',
    render:(text,record) =>(entities.decode(record.cognom2)),
    sorter: (a, b) => { return entities.decode(a.cognom2).localeCompare(entities.decode(b.cognom2))},
    ...this.getColumnSearchProps('cognom2',this.props.t('pares.cognom2'))
  },{
    title:this.props.t('pares.tel1'),
    key:"tel1",
    responsive:['md'],
    render:(text,record) => (record.tel1 ? record.tel1 : '')
  },{
    title:this.props.t('pares.email'),
    key:"email",
    dataIndex: 'email',
    render:(text,record) =>(entities.decode(record.email)),
    sorter: (a, b) => { return entities.decode(a.email).localeCompare(entities.decode(b.email))},
    ...this.getColumnSearchProps('email',this.props.t('pares.email'))
  },{
    title: this.props.t('alumnes.estat.titol'),
    key:"estat",
    dataIndex: 'estat',
    width: '10px',
    filters: filtreEstat,
    defaultFilteredValue: ['actiu'],
    onFilter: (value, record) => record.estat === value,
    render: (text,record) =>(this.props.t(`alumnes.estat.${record.estat}`)),
    sorter: (a, b) => { return a.estat.localeCompare(b.estat)},
  }];


  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={this.props.t('menu.Pares')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          txt_creats={this.props.t('pares.creados')}
          txt_nou={this.props.t('pares.nuevo')}
          buscarDades={this.buscarDades}
          edit={Edit}
          delete={this.marcarEliminat}
          itemBuit={InitDades.pare()}
          columns={columns}
        />
      </div>
    </ContainerPagina>
  );
}
}

export default translate(Pares);
