import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';

import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, Form, Select, Table, message } from 'antd';
import { MinusOutlined, PlusOutlined, CarOutlined, ExperimentOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api, HtmlStrings } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';
import TextAreaCount from '../../../TextAreaCount';

const entities = new AllHtmlEntities();

function NotificacioEnviar(props){

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const id_profe = props.id_profe ? props.id_profe : perfilUsuari.account.id_profe;

  //const [id_alumne, setId_alumne] = useState(0);
  const [triats, setTriats] = useState([]);
//  const [id_classe, setId_classe] = useState(0);
  const [id_notificacions_tipus, setId_notificacions_tipus] = useState(0);
  const [missatge_txt, setMissatge_txt] = useState('');

  const [isLoadingClasse, setIsLoadingClasse] = useState(false);
  const [notificacions_tipusOptions, setNotficacions_tipusOptions] = useState([]);
  const [classes, setClasses] = useState([]);
  const [fillsOptions, setFillsOptions] = useState([]);
  const [llistaAlumnes, setLlistaAlumnes] = useState([]);
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);

  const [errorMissatge_txt, setErrorMissatge_txt] = useState('');
  const [errorId_notificacions_tipus, setErrorId_notificacions_tipus] = useState('');
  const [errorId_notificacions_tipusText, setErrorId_notificacions_tipusText] = useState('');
  const [errorId_alumne, setErrorId_alumne] = useState('');
  const [errorId_alumneText, setErrorId_alumneText] = useState('');


  useEffect(() => {
    buscarDadesTipus();
  },[props.t('idioma')]);

  useEffect(() => {
    //buscarClasses();
    muntarClassesProfe();
  },[]);
/*
  useEffect(() => {
    buscarDadesAlumnes();
  },[id_classe]);
*/

  const buscarDadesTipus = async () => {
    const n = await Api.get('/notificacions/enviar/tipus/Professor');
    if(n.result.success===1){
      const llista_n = n.data.notificacions_tipus.map((item) =>{
        return(
            <Select.Option key={item.id} value={item.id}>{entities.decode(item.nom[props.t('idioma')])}</Select.Option>
          );
      });
      setNotficacions_tipusOptions(llista_n);
    }
  }

/*
  const buscarDadesAlumnes = async () => {
    setIsLoadingClasse(true);
    if(id_classe > 0){
      const f = await Api.get(`/alumnes/classe/${id_classe}`);
      if(f.result.success===1){
        setLlistaAlumnes(f.data.alumnes);
      }else{
        setLlistaAlumnes([]);
      }
    }
    setIsLoadingClasse(false);
  }*/

  const buscarClasses = async () => {
    const clas = await Api.get('/classes');
    if(clas.result.success===1){
      const llista_clas = clas.data.classes.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setClasses(llista_clas);
    }
  }
  const muntarClassesProfe = async () => {

    if(perfilUsuari.dades_profe !== undefined){
      console.log(perfilUsuari.dades_profe)
      let llista_clas = [];
      if(perfilUsuari.dades_profe.classes){
        llista_clas = perfilUsuari.dades_profe.classes.map((item) =>{
              return(
                <Select.Option key={`classe_${item.id}`} value={`classe_${item.id}`}>
                  {entities.decode(item.nom)}
                </Select.Option>
              );
        });
      }

      if(perfilUsuari.dades_profe.extraescolars){
        perfilUsuari.dades_profe.extraescolars.map((item) =>{
              llista_clas.push(
                <Select.Option key={`extraescolar_${item.id}`} value={`extraescolar_${item.id}`}>
                  {<span><ExperimentOutlined />&nbsp;{entities.decode(eval("item.nom_"+props.t('idioma')))}</span>}
                </Select.Option>
              );
        });
      }

      if(perfilUsuari.dades_profe.hi_ha_transport){

        let transport = [];

        const anar = await Api.get('/transport/sentit/anada');
        if(anar.result.success===1){
          anar.data.transport.map((item) =>{
              if(item.id == perfilUsuari.dades_profe.transport_defecte.id_anada){
                transport.push(item);
              }
          });
        }
        const tornar = await Api.get('/transport/sentit/tornada');
        if(tornar.result.success===1){
          tornar.data.transport.map((item) =>{
              if(item.id == perfilUsuari.dades_profe.transport_defecte.id_tornada){
                transport.push(item);
              }
          });
        }
        if(anar.result.success===1){
          anar.data.transport.map((item) =>{
              if(item.id != perfilUsuari.dades_profe.transport_defecte.id_anada){
                transport.push(item);
              }
          });
        }
        if(tornar.result.success===1){
          tornar.data.transport.map((item) =>{
              if(item.id != perfilUsuari.dades_profe.transport_defecte.id_tornada){
                transport.push(item);
              }
          });
        }

        transport.map((item) =>{
          let nom = entities.decode(item.nom[props.t('idioma')]).replace('The British School Costa Daurada', 'School')
              llista_clas.push(
                <Select.Option key={`bus_${item.id}`} value={`bus_${item.id}`}>
                  {<span><CarOutlined />&nbsp;{nom}</span>}
                </Select.Option>
              );
        });

      }
      console.log('llista_clas: ', llista_clas)
      setClasses(llista_clas);

    }

  }

//NOU
const buscarDadesAlumnesTipusTaula = async (tipus, id_taula) =>{
  if(id_taula > 0) {
    const q = await Api.get(`/profe/alumnes/${tipus}/${id_taula}`);
    if(q.result.success===1 && q.data.alumnes){
      setLlistaAlumnes(q.data.alumnes);
    }else{
      setLlistaAlumnes([]);
    }
  }
}
//FI NOU


  const submitForm = async (valors) => {

    let correcte = true;

    if(!missatge_txt){
      setErrorMissatge_txt('error');
      correcte = false;
    }
    if(triats.length < 1){
      setErrorId_alumne('error');
      setErrorId_alumneText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_notificacions_tipus === 0){
      setErrorId_notificacions_tipus('error');
      setErrorId_notificacions_tipusText(props.t('generic.tria'));
      correcte = false;
    }


    if(!correcte){
      return false;
    }

    setGuardant(true);

    let dades = {
      id_usuari: perfilUsuari.account.id,
      ids_alumnes: triats,
      id_notificacions_tipus,
      missatge_txt: entities.encode(missatge_txt)
    }


    console.log(dades);

    try{
      const resposta = await Api.myFetch('/notificacions/profe/envia', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: enviant notificacio: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Enviant Notificacio: ', error);
    }

  }


  const triaClasse = (value) =>{
    const array_value = value.split('_');
    const tipus = array_value[0];
    const id_taula = array_value[array_value.length - 1];
    buscarDadesAlumnesTipusTaula(tipus, id_taula);
  }

  const id_notificacions_tipusChange = (value) =>{
    setId_notificacions_tipus(value);
    if(value > 0){
      setErrorId_notificacions_tipus('');
      setErrorId_notificacions_tipusText('');
    }
  }

  const arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

  return(
    <>
      <Form onFinish={submitForm} layout="vertical" autoComplete="off">

        <Form.Item label={props.t('alumnes.classe')}>
          <Select onChange={(value) => triaClasse(value)}
            showSearch
            filterOption={(input, option) =>
              HtmlStrings.normalize(option.children).includes(HtmlStrings.normalize(input))
            }
          >
            {classes}
          </Select>
        </Form.Item>
        <Form.Item
          validateStatus={errorId_alumne}
          help={errorId_alumneText}
          label={props.t('notificacions.alumne')}
          required={true}
          style={{marginBottom: 0}}

        >
        <div style={errorId_alumne ==='' ? null : {border: '1px solid red'}}>
          <Table
            style={{marginBottom: '1rem'}}
            size="small"
            loading={isLoadingClasse}
            rowKey="id"
            dataSource={llistaAlumnes}
            columns={[
              {
                title: props.t('menu.Alumnes'),
                key: "nom",
                dataIndex: "nom",
                render: (text,record) => (entities.decode(record.nom)+' '+entities.decode(record.cognom1)+' '+entities.decode(record.cognom2))
              }
            ]}
            pagination={false}
            rowSelection={{
            selectedRowKeys: triats,
            hideSelectAll: false,
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              if(selectedRowKeys.length > 0){
                setErrorId_alumne('');
                setErrorId_alumneText('');
              }
              setTriats(selectedRowKeys);
            }
          }}/>
        </div>
        </Form.Item>
        <Form.Item
          validateStatus={errorId_notificacions_tipus}
          help={errorId_notificacions_tipusText}
          label={props.t('notificacions.tipus')}
          required={true}
          style={{marginBottom: 0}}
        >
          <Select
            defaultValue={id_notificacions_tipus === 0 ? "" : id_notificacions_tipus}
            onChange={id_notificacions_tipusChange}>
            {notificacions_tipusOptions}
          </Select>
        </Form.Item>

        <Form.Item label={props.t('notificacions.missatge')} validateStatus={errorMissatge_txt} required={true}>
          <TextAreaCount autoSize={{ minRows: 3, maxRows: 25 }} maxLength={500} name="missatge_txt" defaultValue={missatge_txt} placeholder={props.t('notificacions.missatge')} onChange={setMissatge_txt}/>
        </Form.Item>

        <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
          <Col>
            <Button onClick={()=>props.tancarModal()}>{props.t('generic.cancel')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default translate(NotificacioEnviar);
