import React, { useState, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, InputNumber, message } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

function Producte(props){
  //console.log(props)
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [producte, setProducte] = useState(props.item);
  const [imatge, setImatge] = useState(props.item.url_image || "https://via.placeholder.com/443x249?text=NO+IMAGE+ASSIGNED!!");
  const [validat, setValidat] = useState(false);
  const [unitats, setUnitats] = useState(1);
  const [guardant, setGuardant] = useState(false);
  const [propietatTriada, setPropietatTriada] = useState(props.item.propietats[0].id_propietat);

  const talles = props.item.propietats.map((p) =>{

    const radioStyle = {
          width:'373px',
          marginTop: '8px',
        };
    return(
      <Radio.Button style={radioStyle} value={p.id_propietat}>
        <Row justify="space-between">
          <Col>{entities.decode(p.text[props.t('idioma')])}</Col>
          <Col>{p.preu}€</Col>
        </Row>
      </Radio.Button>
    );
  });


  const submitForm = async (valors) => {

    if(!validat){
      return false;
    }

    setGuardant(true);

    let dades = {
      id_pare: perfilUsuari.account.id,
      id_alumne: props.id_alumne,
      id_producte: producte.id_producte,
      id_propietat: propietatTriada,
      unitats,
    }

    //console.log(dades);

    try{
      const resposta = await Api.myFetch('/botiga', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Roba: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Roba guardar: ', error);
    }

  }



  const onChangeUnitats = (value) => {
    if(value<1){
      setUnitats(1);
    }else{
      setUnitats(value);
    }
  }


  return(
    <>
    <Row justify="center">
      <Typography.Title level={4}>{entities.decode(producte.nom[props.t('idioma')])}</Typography.Title>
      <img alt="Product image" src={imatge} width='100%' onError={() => setImatge("https://via.placeholder.com/443x249?text=URL+IMAGE+NOT+FOUND!!")}/>
    </Row>
    <Row justify="center" style={{margin: '10px'}}>
      <Radio.Group defaultValue={propietatTriada} onChange={(e) => setPropietatTriada(e.target.value)} size="small" optionType="button" buttonStyle="solid">
        {talles}
      </Radio.Group>
    </Row>
    <Row justify="center" style={{marginTop: '30px'}}>
      <Button type="primary" shape="circle" icon={<MinusOutlined />} style={{marginRight:'4px'}} onClick={() => onChangeUnitats(unitats-1)}/>
      <InputNumber min={1} value={unitats} onChange={onChangeUnitats} style={{width:'55px'}}/>
      <Button type="primary" shape="circle" icon={<PlusOutlined />} style={{marginLeft:'4px'}} onClick={() => onChangeUnitats(unitats+1)}/>
    </Row>
    <Row justify="center" style={{margin: '30px 10px 10px 10px'}}>
      <AutoritzarContrasenya resposta={setValidat}/>
    </Row>
    <Row justify="center" style={{marginTop: '10px'}}>
      <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
        {props.t('botiga.pagar_autoritzar')}
      </Button>
    </Row>
    </>
  );
}
export default translate(Producte);
