import React, { useContext, useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Button, List, Card, Select, Form, Table, Checkbox, Input, Space,Tooltip, Modal, message } from 'antd';
import { AlertFilled, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import { AllHtmlEntities } from 'html-entities';
import { MenuTriatContext } from "../../../../context/menu-triat";
import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
import AutoritzatsFitxa from './AutoritzatsFitxa';
import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();
moment.locale('es');

function Autoritzats(props){

  const [tipus, setTipus] = useState(props.tipus ? props.tipus : 'classe');
  const [id_taula, setId_taula] = useState(props.id_taula);
  const [alumnes, setAlumnes] = useState([]);

  const [taulaIsLoading, setTaulaIsLoading] = useState(false);
  const [autoritzatsIsLoading, setAutoritzatsIsLoading] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [alumneActual, setAlumneActual] = useState({id: 0});
  const [autoritzatActual, setAutoritzatActual] = useState();
  const [relacionsFamiliars, setRelacionsFamiliars] = useState([]);

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() =>{
    buscarRelacions();
  },[props.t('idioma')]);

  useEffect(() => {
    setTipus(props.tipus);
    setId_taula(props.id_taula);
    //console.log('porps dinsPassarLlista',props.tipus, props.id_taula)
  },[props.tipus, props.id_taula]);

    useEffect(() => {
    buscarAlumnes();
    //console.log('dinsPassarLlista',tipus, id_taula)
  },[tipus, id_taula]);

  const buscarAlumnes = async () =>{
    setTaulaIsLoading(true);
    if(id_taula > 0) {
      const q = await Api.get(`/profe/alumnes/${tipus}/${id_taula}`);
      if(q.result.success===1 && q.data.alumnes){
        const llista_alumnes = q.data.alumnes.map((item)=>{
          let alumne = {
            id: item.id,
            id_alumne: item.id,
            id_familia: item.id_familia,
            nom: item.nom,
            cognom1: item.cognom1,
            cognom2: item.cognom2 ? item.cognom2 : '',
            email: item.email,
            classe: item.classe
          }
          return alumne;
        })
        setAlumnes(llista_alumnes);
      }
    }
  setTaulaIsLoading(false);
  }

  const buscarRelacions = async () =>{
    let relacions = [];
    const q = await Api.get('/relacions');
    if(q.result.success ===1){
      q.data.relacions_familiars.map((item)=>{
        relacions[item.id] = item.text[props.t('idioma')];
      });
    }
    setRelacionsFamiliars(relacions);
  }

  const onExpandRow = async (expanded, record) =>{
    if(expanded){
      setAlumneActual(record)
      let expan = expandedRowKeys;
      expan.push(record.id);
      setExpandedRowKeys(expan);
    }else{
      const expan = expandedRowKeys.filter((element, index, arr) => element !== record.id);
      setExpandedRowKeys(expan);
    }
    //if(record.num_fitxers===0) return;
    await buscarAutoritzatsAlumne(record.id_familia);

  }


  const buscarAutoritzatsAlumne = async(id_familia) =>{
    setAutoritzatsIsLoading(true);
    let autoritzats = [];

    const pares = await Api.get(`/pares/familia/${id_familia}`);
    if(pares.result.success===1){
      pares.data.map((item)=>{
        autoritzats.push(item);
      });
    }
    const auto = await Api.get(`/admin/autoritzats/familia/${id_familia}`);
    if(auto.result.success===1){
      auto.data.map((item)=>{
        autoritzats.push(item);
      });
    }

    const llista_t = alumnes.map((item) =>{
      if(item.id_familia === id_familia){
        item.autoritzats = autoritzats;
      }
      return item;
    });

    setAlumnes(llista_t);
    setAutoritzatsIsLoading(false);
  }

  const obrirModalAutoritzats = (record) =>{
    setAutoritzatActual(record);
    setModalVisible(true);
  }


  const columnsAutoritzats = [
  {
    title: props.t('pares.relacio_familiar'),
    key:"id_relacio_familiar",
    dataIndex: 'id_relacio_familiar',
    render: (text,record) =>(entities.decode(relacionsFamiliars[record.id_relacio_familiar])   ),
  },{
    title: props.t('pares.nom'),
    key:"nom",
    dataIndex: 'nom',
    render: (text,record) =>(entities.decode(record.nom)+' '+entities.decode(record.cognom1)+' '+entities.decode(record.cognom2)),
  },{
    title:props.t('pares.tel1'),
    key:"tel1",
    responsive:['md'],
    render:(text,record) => (record.tel1 ? record.tel1 : '')
  },{
    title:props.t('pares.tel2'),
    key:"tel2",
    responsive:['md'],
    render:(text,record) => (record.tel2 ? record.tel2 : '')
  },{
    title: props.t('pares.email'),
    key:"email",
    dataIndex: 'email'
  },{
    title: props.t('pares.dni'),
    key:"dni",
    dataIndex: 'dni',
    width: '10px',
    render: (text,record) =>(entities.decode(record.dni))
  },{
    key: "actions",
    align: "right",
    width: "95px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id} key={"actions_"+record.id}>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EyeOutlined />} onClick={(e) => obrirModalAutoritzats(record)} ghost="true" className="BotoTaula"/>
          </Tooltip>
      </Row>
  )
  }
  ];

  const columns = [
    {
      title: props.t('generic.alumne'),
      dataIndex: 'nom',
      render: (text,record) =>(entities.decode(record.nom)+' '+entities.decode(record.cognom1)+' '+entities.decode(record.cognom2)),
    },
    {
      title: props.t('pares.email'),
      dataIndex: 'email',
      render: (text,record) =>{
        const mailto = "mailto:"+record.email;
        return <span><a href={mailto} target="_blank">{record.email}</a></span>;
        //return <span><Button type="text" onClick={(e)=> {window.location.href= `mailto:${record.email}`}}>{record.email}</Button></span>;
      },
    },
    {
      title: props.t('generic.classe'),
      dataIndex: 'classe',
      render: (text,record) =>(entities.decode(record.classe)),
    }
  ];

  const subTaulaAutoritzats = row => {
    return (
      <div>
      <Row style={{margin: '1rem'}}>
        <Col span={22}>
          <Table
            loading={autoritzatsIsLoading && alumneActual.id == row.id}
            rowKey="id"
            columns={columnsAutoritzats}
            dataSource={row.autoritzats}
            pagination={false}
            size={'small'}
            bordered={false}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => obrirModalAutoritzats(record),
                onDoubleClick: (e) => obrirModalAutoritzats(record),
              };
            }}
            />
          </Col>
      </Row>
      </div>
    );
  };
  return (
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('generic.dades_contacte')+' & '+props.t('menu.DadesAutoritzats')}/>
        <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={alumnes}
            expandedRowRender={subTaulaAutoritzats}
            defaultExpandAllRows={false}
            expandedRowKeys={expandedRowKeys}
            expandRowByClick={true}
            onExpand={(expanded, record) => onExpandRow(expanded, record)}
            pagination={false}
            size="small"
            loading={taulaIsLoading}

          />
        </div>

        <Modal
          key="detall_autoritzat"
          visible={modalVisible}
          footer={null}
          width={800}
          style={{top:10}}
          bodyStyle={{ overflow: "auto"}}
          destroyOnClose={true}
          forceRender={true}
          maskClosable={true}
          closable={true}
          onCancel={() => setModalVisible(false)}
        >
          <AutoritzatsFitxa dades={autoritzatActual}/>
        </Modal>
    </ContainerPagina>
  );
}
export default translate(Autoritzats);
