import React from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../components/ContainerPagina';
import TitolPagina from '../components/TitolPagina';
import LlistaEditable from '../components/LlistaEditable';
import Edit from '../components/Formularis/Etapes';
import { Api, InitDades } from '../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Etapes(props) {

  const buscarDades = async () => {
    const et = await Api.get('/etapes');
    if(et.result.success===1){
      return et.data.etapes;
    }

  }

  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/etapes/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  return(
    <ContainerPagina>
      <TitolPagina txt={props.t('menu.Etapes')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          txt_creats={props.t('etapes.creados')}
          txt_nou={props.t('etapes.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.cicle()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />

          <Column
          title={props.t('etapes.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom)
          )}
          />


        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Etapes);
