import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Table, Form, Row, Col, Button, Alert, Switch, message } from 'antd';
import { Api } from '../../../../helpers';

import { AllHtmlEntities } from 'html-entities';
const entities = new AllHtmlEntities();

function BotigaTraspas({t, old_id_curs_escolar, new_id_curs_escolar, callback_ok, callback_ko}){

  const [robaTriat, setRobaTriat] = useState(true);
  const [excursioTriat, setExcursioTriat] = useState(false);
  const [esdevenimentTriat, setEsdevenimentTriat] = useState(false);
  const [summerTriat, setSummerTriat] = useState(true);

  const [guardant, setGuardant] = useState(false);


  const submitForm = async (valors) => {

    let ids_categoria = [];
    if(robaTriat){ids_categoria.push(1)}
    if(excursioTriat){ids_categoria.push(2)}
    if(esdevenimentTriat){ids_categoria.push(3)}
    if(summerTriat){ids_categoria.push(4)}

    if( !ids_categoria ){return false};

    setGuardant(true);

    let dades = {
      ids_categoria,
      old_id_curs_escolar,
      new_id_curs_escolar
    }

    let method = 'POST';
    let url_api = '/cursescolar/traspassar/botiga';

    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

      if(resposta.result.success === 1){
        setGuardant(false);
        console.log('resposta', resposta);
        message.success(t('generic.OK_saved'), 5);
        callback_ok();
      }else{
        setGuardant(false);
        console.error('Error: submit FormQuotesTraspas: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
        callback_ko();
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: QuotesTraspas guardar: ', error);
      callback_ko();
    }
  }


    return(
      <div style={{padding:'10px 24px 35px 0px'}}>
        <Form onFinish={submitForm} style={{marginTop:'35px'}}
        layout="horizontal"
        labelCol={{span:0}}
        wrapperCol={{span:24}}
        colon={true}
        autoComplete="off"
        >
        <Alert
          style={{marginTop: 15, marginBottom: 15}}
          message={"Traspassar Botiga"}
          description={"Es procedirà a clonar tots els productes de la botiga de les categories indicades. Els productes resultants es podran reaprofitar o eliminar si no són necessaris."}
          type="info"
          showIcon
        />

        <Form.Item>
          <span className="ant-form-item-label">
            <label>{t('menu.ComandesRoba')}&nbsp;</label>
            <Switch
              checkedChildren={t('generic.si')}
              unCheckedChildren={t('generic.no')}
              onChange={setRobaTriat} defaultChecked={robaTriat}
            />
          </span>
        </Form.Item>
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{t('menu.ComandesExcursions')}&nbsp;</label>
            <Switch
              checkedChildren={t('generic.si')}
              unCheckedChildren={t('generic.no')}
              onChange={setExcursioTriat} defaultChecked={excursioTriat}
            />
          </span>
        </Form.Item>
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{t('menu.ComandesEsdeveniments')}&nbsp;</label>
            <Switch
              checkedChildren={t('generic.si')}
              unCheckedChildren={t('generic.no')}
              onChange={setEsdevenimentTriat} defaultChecked={esdevenimentTriat}
            />
          </span>
        </Form.Item>
        <Form.Item>
          <span className="ant-form-item-label">
            <label>{t('menu.ComandesSummerCamp')}&nbsp;</label>
            <Switch
              checkedChildren={t('generic.si')}
              unCheckedChildren={t('generic.no')}
              onChange={setSummerTriat} defaultChecked={summerTriat}
            />
          </span>
        </Form.Item>


          <Form.Item wrapperCol={{span: 24 }} name="botonets" style={{marginTop: 20}}>
            <Row type="flex" justify="center">
              <Col>
                <Button onClick={callback_ko}>{t('generic.cancel')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{t('generic.seguent')}</Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );

}
export default translate(BotigaTraspas);
