import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import {Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, message } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import TriaClasses from '../../../TriaClasses';


const entities = new AllHtmlEntities();

function Edit(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [id, setId] = useState(props.item.id);
  const [id_profe, setId_profe] = useState(props.id_profe);
  const [id_carrec, setId_carrec] = useState(props.item.id_carrec);
  const [id_extraescolar, setId_extraescolar] = useState(props.item.id_extraescolar);
  const [id_assignatura, setId_assignatura] = useState(props.item.id_assignatura);
  const [ambit, setAmbit] = useState(props.item.ambit);
  const [id_ambit, setId_ambit] = useState(props.item.id_ambit);
  const [classesTriades, setClassesTriades] = useState(props.item.classes || []);
  const [id_anada, setId_anada] = useState(props.item.id_anada);
  const [id_tornada, setId_tornada] = useState(props.item.id_tornada);


  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nou, setNou] = useState(props.item.id===0);

  const [carrecsOptions, setCarrecsOptions] = useState([]);
  const [carrecsLlistaIndexada, setCarrecsLlistaIndexada] = useState([]);
  const [classes, setClasses] = useState([]);
  const [cursos, setCursos] = useState([]);
  const [cicles, setCicles] = useState([]);
  const [etapes, setEtapes] = useState([]);
  const [extraescolars, setExtraescolars] = useState([]);
  const [assignatures, setAssignatures] = useState([]);
  const [transportAnada, setTransportAnada] = useState([]);
  const [transportTornada, setTransportTornada] = useState([]);

  const [errorSelectCarrec, setErrorSelectCarrec] = useState('');
  const [errorSelectCarrecText, setErrorSelectCarrecText] = useState('');
  const [errorSelectAmbit, setErrorSelectAmbit] = useState('');
  const [errorSelectAmbitText, setErrorSelectAmbitText] = useState('');
  const [errorSelectExtraescolars, setErrorSelectExtraescolars] = useState('');
  const [errorSelectExtraescolarstText, setErrorSelectExtraescolarsText] = useState('');
  const [errorSelectAssignatures, setErrorSelectAssignatures] = useState('');
  const [errorSelectAssignaturesText, setErrorSelectAssignaturesText] = useState('');
  const [errorTriaClasses, setErrorTriaClasses] = useState('');
  const [errorTriaClassesText, setErrorTriaClassesText] = useState('');
  const [errorTransportAnada, setErrorTransportAnada] = useState('');
  const [errorTransportAnadaText, setErrorTransportAnadaText] = useState('');
  const [errorTransportTornada, setErrorTransportTornada] = useState('');
  const [errorTransportTornadaText, setErrorTransportTornadaText] = useState('');


  useEffect(() => {
    setIsLoading(true);
    buscarCarrecs();
    buscarClasses();
    buscarCursos();
    buscarCicles();
    buscarEtapes();
    buscarExtraescolars();
    buscarAssignaures();
    buscarTransport();
    setIsLoading(false);
  },[]);


  const buscarCarrecs = async () => {
    const c = await Api.get('/professors/carrecs/existents');
    if(c.result.success===1){
      const llista_c = c.data.carrecs.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {item.nom[props.t('idioma')] + ' (' + item.ambit + ')'}
              </Select.Option>
            );
      });
      setCarrecsOptions(llista_c);

      const llistaIndexada = c.data.carrecs.reduce((acumulat, item) => ({
        ...acumulat,
        [item.id]: item,
      }),{});
      setCarrecsLlistaIndexada(llistaIndexada);
    }
  }

  const buscarClasses = async () =>{
    const clas = await Api.get('/classes');
    if(clas.result.success===1){
      const llista_clas = clas.data.classes.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setClasses(llista_clas);
    }
  }

  const buscarCursos = async () =>{
    const cur = await Api.get('/cursos');
    if(cur.result.success===1){
      const llista_cur = cur.data.cursos.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setCursos(llista_cur);
    }
  }

  const buscarCicles = async () =>{
    const ci = await Api.get('/cicles');
    if(ci.result.success===1){
      const llista_ci = ci.data.cicles.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setCicles(llista_ci);
    }
  }
  const buscarEtapes = async () =>{
    const et = await Api.get('/etapes');
    if(et.result.success===1){
      const llista_et = et.data.etapes.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setEtapes(llista_et);
    }
  }
  const buscarExtraescolars = async () =>{
    const ex = await Api.get('/extraescolars');
    if(ex.result.success===1){
      const llista_ex = ex.data.extraescolars.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                <span><b>{entities.decode(item.nom[props.t('idioma')])}</b>&nbsp;{'('+entities.decode(item.horari[props.t('idioma')])+')'}</span>
              </Select.Option>
            );
      });
      setExtraescolars(llista_ex);
    }
  }
  const buscarAssignaures = async () =>{
    const as = await Api.get('/assignatures');
    if(as.result.success===1){
      const llista_as = as.data.assignatures.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                <span>{entities.decode(item.nom[props.t('idioma')])}</span>
              </Select.Option>
            );
      });
      setAssignatures(llista_as);
    }
  }

  const buscarTransport = async () =>{
    const an = await Api.get(`/transport/sentit/anada/curs/${id_curs_escolar}`);
    if(an.result.success===1){
      const llista_an = an.data.transport.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                <span>{entities.decode(item.nom[props.t('idioma')])}</span>
              </Select.Option>
            );
      });
      setTransportAnada(llista_an);
    }
    const tr = await Api.get(`/transport/sentit/tornada/curs/${id_curs_escolar}`);
    if(tr.result.success===1){
      const llista_tr = tr.data.transport.map((item) =>{
            return(
              <Select.Option key={item.id} value={item.id}>
                <span>{entities.decode(item.nom[props.t('idioma')])}</span>
              </Select.Option>
            );
      });
      setTransportTornada(llista_tr);
    }
  }



  const submitForm = async (valors) => {

    let correcte = true;

    if(id_carrec===0){
      setErrorSelectCarrec('error');
      setErrorSelectCarrecText(props.t('generic.tria'));
      correcte = false;
    }

    if(id_ambit===0 && ambit !== 'extraescolars' && ambit !== 'assignatures' && ambit !== 'transport' && ambit !== 'acollida' && ambit !== 'menjador'){
      setErrorSelectAmbit('error');
      setErrorSelectAmbitText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_extraescolar===0 && ambit == 'extraescolars'){
      setErrorSelectExtraescolars('error');
      setErrorSelectExtraescolarsText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_assignatura===0 && ambit == 'assignatures'){
      setErrorSelectAssignatures('error');
      setErrorSelectAssignaturesText(props.t('generic.tria'));
      correcte = false;
    }

    if(classesTriades.length === 0 && ambit == 'assignatures'){
      setErrorTriaClasses('error');
      setErrorTriaClassesText(props.t('generic.tria_varis'));
      correcte = false;
    }
    if(id_anada===0 && ambit == 'transport'){
      setErrorTransportAnada('error');
      setErrorTransportAnadaText(props.t('generic.tria'));
      correcte = false;
    }
    if(id_tornada===0 && ambit == 'transport'){
      setErrorTransportTornada('error');
      setErrorTransportTornadaText(props.t('generic.tria'));
      correcte = false;
    }


    if(!correcte){return false}

    setGuardant(true);

    let dades = {
        id_curs_escolar,
        id,
        id_profe,
        id_carrec,
        id_extraescolar,
        id_assignatura,
        ambit,
        id_ambit,
        classes: classesTriades,
        id_anada,
        id_tornada
      }


      //console.log('dades: ', JSON.stringify(dades));

      let method = nou?'POST':'PUT';

      let url_api = nou? '/professors/relacions' : '/professors/relacions/'+id;


      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          //console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit professor_relacio: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: professor_relacio guardar: ', error);
      }

  }

  const carrecChange = (value) =>{
    setId_carrec(value);
    if(value!==0){
      setAmbit(carrecsLlistaIndexada[value].ambit);
      setId_assignatura(0);
      setId_extraescolar(0);
      setId_ambit(0);
      setErrorSelectCarrec('');
      setErrorSelectCarrecText('');
    }
  }
  const ambitChange = (value) =>{
    setId_ambit(value);
    if(value!==0){
      setErrorSelectAmbit('');
      setErrorSelectAmbitText('');
    }
  }
  const extraescolarsChange = (value) =>{
    setId_extraescolar(value);
    if(value!==0){
      setErrorSelectExtraescolars('');
      setErrorSelectExtraescolarsText('');
    }
  }
  const assignaturaChange = (value) =>{
    setId_assignatura(value);
    if(value!==0){
      setErrorSelectAssignatures('');
      setErrorSelectAssignaturesText('');
    }
  }
  const triaClassesChange = (value) =>{
    setClassesTriades(value);
    if(value.length>0){
      setErrorTriaClasses('');
      setErrorTriaClassesText('');
    }
  }
  const anadaChange = (value) =>{
    setId_anada(value);
    if(value!=0){
      setErrorTransportAnada('');
      setErrorTransportAnadaText('');
    }
  }
  const tornadaChange = (value) =>{
    setId_tornada(value);
    if(value!=0){
      setErrorTransportTornada('');
      setErrorTransportTornadaText('');
    }
  }


  let posicio_botons = "start";
  if(props.popup!==undefined){
    if(props.popup){
      posicio_botons = "space-between";
    }
  }



  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return(
    <div style={{padding:'10px 24px 10px 0px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:6}}
        wrapperCol={{span:18}}
        colon={true}
        autoComplete="off"
      >
    <Row>
      <Col span={24}>

        <Form.Item  style={{marginBottom: 0}} label={props.t('professors_relacions.carrec')} validateStatus={errorSelectCarrec} help={errorSelectCarrecText} required={true}>
          <Select defaultValue={id_carrec === 0 ? "" : id_carrec} onChange={carrecChange}>
            {carrecsOptions}
          </Select>
        </Form.Item>
        { ambit != 'assignatures' ? null :
          <Form.Item style={{marginBottom: 0}} label={props.t('menu.Assignatures')} validateStatus={errorSelectAssignatures} help={errorSelectAssignaturesText} required={true}>
            <Select defaultValue={id_assignatura === 0 ? "" : id_assignatura} defaultActiveFirstOption={true} onChange={assignaturaChange}>
              {assignatures}
            </Select>
          </Form.Item>
        }
        { ambit != 'assignatures' ? null :
          <Form.Item style={{marginBottom: 0}}
            validateStatus={errorTriaClasses}
            help={errorTriaClassesText}
            label={props.t('generic.tria_classe')}
            required={true}
          >
            <TriaClasses defaultValue={classesTriades} onChange={triaClassesChange}/>
          </Form.Item>
        }
        { ['classes'].indexOf(ambit) == -1 ? null :
          <Form.Item style={{marginBottom: 0}} label={props.t('alumnes.classe')} validateStatus={errorSelectAmbit} help={errorSelectAmbitText} required={true}>
            <Select defaultValue={id_ambit === 0 ? "" : id_ambit} defaultActiveFirstOption={true} onChange={ambitChange}>
              {classes}
            </Select>
          </Form.Item>
        }
        { ambit != 'cursos' ? null :
          <Form.Item style={{marginBottom: 0}} label={props.t('classes.curs')} validateStatus={errorSelectAmbit} help={errorSelectAmbitText} required={true}>
            <Select defaultValue={id_ambit === 0 ? "" : id_ambit} defaultActiveFirstOption={true} onChange={ambitChange}>
              {cursos}
            </Select>
          </Form.Item>
        }
        { ambit != 'cicles' ? null :
          <Form.Item style={{marginBottom: 0}} label={props.t('cursos.cicle')} validateStatus={errorSelectAmbit} help={errorSelectAmbitText} required={true}>
            <Select defaultValue={id_ambit === 0 ? "" : id_ambit} defaultActiveFirstOption={true} onChange={ambitChange}>
              {cicles}
            </Select>
          </Form.Item>
        }
        { ambit != 'etapes' ? null :
          <Form.Item style={{marginBottom: 0}} label={props.t('cicles.etapa')} validateStatus={errorSelectAmbit} help={errorSelectAmbitText} required={true}>
            <Select defaultValue={id_ambit === 0 ? "" : id_ambit} defaultActiveFirstOption={true} onChange={ambitChange}>
              {etapes}
            </Select>
          </Form.Item>
        }
        { ambit != 'extraescolars' ? null :
          <Form.Item style={{marginBottom: 0}} label={props.t('menu.Extraescolars')} validateStatus={errorSelectExtraescolars} help={errorSelectExtraescolarstText} required={true}>
            <Select defaultValue={id_extraescolar === 0 ? "" : id_extraescolar} defaultActiveFirstOption={true} onChange={extraescolarsChange}>
              {extraescolars}
            </Select>
          </Form.Item>
        }
        { ambit != 'transport' ? null :
          <Form.Item style={{marginBottom: 0}} label={props.t('transport.anada')} validateStatus={errorTransportAnada} help={errorTransportAnadaText} required={true}>
            <Select defaultValue={id_anada === 0 ? "" : id_anada} defaultActiveFirstOption={true} onChange={anadaChange}>
              {transportAnada}
            </Select>
          </Form.Item>
        }
        { ambit != 'transport' ? null :
          <Form.Item style={{marginBottom: 0}} label={props.t('transport.tornada')} validateStatus={errorTransportTornada} help={errorTransportTornadaText} required={true}>
            <Select defaultValue={id_tornada === 0 ? "" : id_tornada} defaultActiveFirstOption={true} onChange={tornadaChange}>
              {transportTornada}
            </Select>
          </Form.Item>
        }


      </Col>
    </Row>

        <Col span={24}>
          <Row type="flex" justify={posicio_botons} style={{marginTop: 25, marginBottom: 25}}>
            <Col>
              <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Col>

      </Form>
    </div>
  );
}
export default translate(Edit);
