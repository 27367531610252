import React, {useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined } from '@ant-design/icons';
import { Row, Col, Pagination, Input } from 'antd';
import axios from 'axios';

function Pixabay(props){

  const pixabay_api_key = process.env.REACT_APP_PIXABAY_API_KEY;
  const [txtCerca, setTxtCerca] = useState('');
  //const [imgTriada, setImgTriada] = useState('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [pictureList, setPictureList] = useState([]);
  const [totalHits, setTotalHits] = useState(0);

  /*const loadMorePictures = () => {
      setPage(page + 1);
  };*/
  function onChangePage(pageNumber){
    setPage(pageNumber);
  }
  function onChangeTxtCerca(text){
    console.log('onChangeTxtCerca: '+text);
    setTxtCerca(text);
    setPage(1);
  }

  function retornaImatge(url){
    props.returnImage(url);
    props.modalVisible();
  }

const buscarDades = (page) =>{
  setIsLoading(true);
  var url_pixa = "https://pixabay.com/api/?key="+pixabay_api_key+"&per_page=24&page="+page+"&lang=es";

  if(txtCerca!==''){
    url_pixa+="&q="+encodeURIComponent(txtCerca);
  }

  axios.get(url_pixa)
  .then(response => {
    console.log(response);
    setPictureList(response.data.hits);
    setTotalHits(response.data.totalHits);
    setIsLoading(false);
  })
  .catch(error => console.log(error));
}


  useEffect(() => {
    buscarDades(page);
  }, [page, txtCerca]);


  const llista_items = pictureList.map((pic) =>{
    return(
      <Col span={6}>
        <a onClick={() => retornaImatge(pic.largeImageURL)} target='_blank' rel="noopener noreferrer"><div style={{width:'150px',height:'100px',backgroundImage:`url(${pic.previewURL})`,backgroundRepeat:'no-repeat', backgroundSize:'cover',backgroundPosition:'center'}}/></a>
      </Col>
    );
  });


  return (
    <div>

      <Input.Search
        placeholder="input search text"
        onSearch={value => onChangeTxtCerca(value)}
        style={{ width:'345px', marginBottom: '15px' }}
      />
      <Row gutter={[16,16]}>
        {isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '2rem'}} /> : llista_items}
      </Row>

      {isLoading || llista_items.length === 0 ? null :
      <div align='center' style={{margin:'15px'}}>
        <Pagination current={page} defaultPageSize={24} total={totalHits} onChange={onChangePage} />
      </div>
      }

      {llista_items.length === 0 ? <span>No results</span> : null}

    </div>
  );
}
export default translate(Pixabay);
