import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, CloseOutlined, CheckOutlined, PercentageOutlined, DeleteOutlined, EditOutlined, LeftOutlined, FilePdfOutlined, FileImageOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Button, Input, InputNumber, Modal, Popover, Divider, Switch, Select, Table, Popconfirm, Tooltip, Alert, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../../context/menu-triat";
import { Api, InitDades } from '../../../../helpers';
import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
import FormAdress from './Adress';
import DocumentUpload from "../../../DocumentUpload";

const entities = new AllHtmlEntities();


function Edit(props){

  const buit = InitDades.familia();

  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);


  const id = perfilUsuari.account.id_familia;
  const [cognom1, setCognom1] = useState(buit.cognom1);
  const [cognom2, setCognom2] = useState(buit.cognom2);
  const [tel1, setTel1] = useState(buit.tel1);
  const [tel2, setTel2] = useState(buit.tel2);
  const [tel3, setTel3] = useState(buit.tel3);
  const [llibre_familia, setLlibre_familia] = useState(null);

  const [adresses, setAdresses] = useState([]);
  const [adressTriat, setAdressTriat] = useState([]);
  const [modalVisibleAdress, setModalVisibleAdress] = useState(false);
  const [actualizarAdress, setActualizarAdress] = useState(0);

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errorCognom1, setErrorCognom1] = useState('');
  const [errorCognom2, setErrorCognom2] = useState('');
  const [errorTel1, setErrorTel1] = useState('');

  let adressBuit = InitDades.familia_adress();
  adressBuit.id_familia = id;

  const contenidorRef = React.useRef();

  const buscarDades = async () => {
    console.log('dins_buscar', '/familia/'+id)
    if(id > 0){
      const ad = await Api.get('/familia/'+id);

      if(ad.result.success===1){
        console.log(ad.data)
        //setAdresses(ad.data.adress);
        setCognom1(entities.decode(ad.data.cognom1));
        setCognom2(entities.decode(ad.data.cognom2));
        setTel1(ad.data.tel1);
        setTel2(ad.data.tel2);
        setTel3(ad.data.tel3);


        const llista = ad.data.llibre_familia.map((doc) => {
         return({uid: -1, status: 'done', url: doc.url, name: doc.nom, path: doc.url});
        });
        if(llista){
          setLlibre_familia(llista);
        }

      }
    }
  }
  const buscarAdress = async () => {
    console.log('dins_buscar')
    if(id > 0){
      const ad = await Api.get('/familia/'+id);
      if(ad.result.success===1){
        setAdresses(ad.data.adress);
      }
    }
  }

  useEffect(() => {
    buscarDades();
  },[]);

  useEffect(() => {
    buscarAdress();
  },[actualizarAdress]);



    const submitForm = async (values) => {

      let correcte = true;

      if(!cognom1){
        setErrorCognom1('error');
        correcte = false;
      }
      if(!cognom2){
        setErrorCognom2('error');
        correcte = false;
      }
      if(!tel1){
        setErrorTel1('error');
        correcte = false;
      }


      if(!correcte){
        contenidorRef.current.scrollIntoView({
          behavior: "smooth",
        });
        return false;
      }

      setGuardant(true);

      let dades = {
        id_familia: id,
        cognom1: entities.encode(cognom1),
        cognom2: entities.encode(cognom2),
        tel1,
        tel2,
        tel3,
        adress: null
      }

      //console.log(JSON.stringify(dades));
      try{
        const resposta = await Api.myFetch('/familia', 'PUT', JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          //setMenuTriat("Dashboard");
        }else{
          setGuardant(false);
          console.error('Error: submit FormFamilies: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Familia guardar: ', error);
      }

  }


  const obrirModalAdress = item =>{
    setAdressTriat(item);
    setModalVisibleAdress(true);
  }
  const tancarModalAdress = e =>{
    setActualizarAdress(actualizarAdress+1);
    setModalVisibleAdress(false);
  }


  const columnsAdress = [
  {
    title: props.t('families.adress'),
    dataIndex: 'adress',
    key: 'adress',
    render:(text,record) => (
      <span>{entities.decode(record.adress)}</span>
    )
  },
  {
    title:  props.t('families.ciutat'),
    dataIndex: 'ciutat',
    key: 'ciutat',
    render:(text,record) => (
      <span>{entities.decode(record.ciutat)}</span>
    )
  },
  {
    title:  props.t('families.cp'),
    dataIndex: 'cp',
    key: 'cp',
    responsive: ['md']
  },
  {
    title:  props.t('families.provincia'),
    dataIndex: 'provincia',
    key: 'provincia',
    responsive: ['md'],
    render:(text,record) => (
      <span>{entities.decode(record.provincia)}</span>
    )
  },
  {
    key: "actions",
    align: "right",
    width: "95px",
    render: (text,record) => (
      <Tooltip placement="top" title={props.t('generic.modify')}>
        <Button icon={<EditOutlined />} onClick={(e) => obrirModalAdress(record)} ghost="true" className="BotoTaula"/>
      </Tooltip>
  )
}
];


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }
  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.DadesFamilia')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>

        <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
          <LeftOutlined className="BotoBack" onClick={(e) => setMenuTriat("Dashboard")}/>
        </div>
        <div style={{padding:'10px 24px 35px 0px'}} ref={contenidorRef}>

          <Form onFinish={submitForm}
            layout="vertical"
            autoComplete="off"
          >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} lg={12} xl={8}>
            <Form.Item label={props.t('families.cognom1')} validateStatus={errorCognom1} required={true}>
                <Input name="cognom1" placeholder={props.t('families.cognom1')} onChange={(e) => setCognom1(e.target.value)} defaultValue={cognom1} value={cognom1}/>
            </Form.Item>
            <Form.Item label={props.t('families.cognom2')} validateStatus={errorCognom2} required={true}>
              <Input name="cognom2" placeholder={props.t('families.cognom2')} onChange={(e) => setCognom2(e.target.value)} defaultValue={cognom2} value={cognom2}/>
            </Form.Item>
          <Divider plain>{props.t('generic.dades_contacte')}</Divider>
            <Form.Item label={props.t('families.tel1')} validateStatus={errorTel1} required={true}>
              <Input name="tel1" placeholder={props.t('families.tel1')} onChange={(e) => setTel1(e.target.value)} defaultValue={tel1} value={tel1}/>
            </Form.Item>
            <Form.Item label={props.t('families.tel2')}>
              <Input name="tel2" placeholder={props.t('families.tel2')} onChange={(e) => setTel2(e.target.value)} defaultValue={tel2} value={tel2}/>
            </Form.Item>
            <Form.Item label={props.t('families.tel3')}>
              <Input name="tel3" placeholder={props.t('families.tel3')} onChange={(e) => setTel3(e.target.value)} defaultValue={tel3} value={tel3}/>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 3, span: 16 }} name="botonets">
              <Row type="flex" justify="start">
                <Col>
                  <Button onClick={() => setMenuTriat('Dashboard')}>{props.t('generic.cancel')}</Button>
                </Col>
                <Col style={{marginLeft:'10px'}}>
                  <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12} xl={16}>
              <Divider plain>{props.t('families.adress')}</Divider>
                <Row type="flex" justify="end" style={{marginTop: '1rem'}}>
                  <Col>
                    <Button type="primary" onClick={() => obrirModalAdress(adressBuit)}>{props.t('generic.add')}</Button>
                  </Col>
                </Row>
                <Table
                  key='taula_adress'
                  dataSource={adresses}
                  columns={columnsAdress}
                  pagination={false}
                  size={'small'}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {},
                      onDoubleClick: (e) => obrirModalAdress(record),
                      onContextMenu: (e) => {e.preventDefault(); obrirModalAdress(record);},
                    };
                  }}
                />
            <Divider plain style={{marginTop: '1.5rem'}}>{props.t('families.llibre')}</Divider>
            <Col span={24} style={{marginLeft: '25px', marginBottom: '25px', paddingLeft: '5px'}}>
              <DocumentUpload
                fileList={llibre_familia}
                url={`${global.api_url}/familia/${id}/llibre`}
                url_delete={null}
                titol={props.t('families.pujar_llibre1')}
                subtitol={props.t('families.pujar_llibre2')}
                />
            </Col>
          </Col>
          </Row>
          </Form>
          <Modal
              key="adresses"
              title={props.t('families.adress')}
              visible={modalVisibleAdress}
              footer={null}
              onCancel={tancarModalAdress}
              width={550}
              style={{top:20}}
              //bodyStyle={{ height: "70vh", overflow: "auto" }}
              destroyOnClose={true}
              forceRender={true}
            >
              <FormAdress item={adressTriat} callback={tancarModalAdress} popup={true}/>
            </Modal>
        </div>
      </div>
    </ContainerPagina>
  );
}
export default translate(Edit);
