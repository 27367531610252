import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm, Modal, Form, Select, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, ClearOutlined, EditOutlined, DeleteOutlined, InboxOutlined, AlertFilled, AlertTwoTone } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/ComandesExcursions';
import ComandesExcursionsExportaExcel from '../../../components/Formularis/perfils/admin/ComandesExcursionsExportaExcel';
import { Api, InitDades } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();


function ComandesExcursions(props){
  moment.locale('es');
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  const [filtresClasses, setFiltresClasses] = useState([]);
  const [filtresExcursions, setFiltresExcursions] = useState([]);
  const [triats, setTriats] = useState([]);
  const [modificarEstatTriatsLoading, setModificarEstatTriatsLoading] = useState(false);
  const [modalEstatVisible, setModalEstatVisible] = useState(false);
  const [refrescar, setRefrescar] = useState(0);
  const [estatsOptions, setEstatsOptions] = useState([]);
  const [estatTriat, setEstatTriat] = useState('Pendent');
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);

    //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  useEffect(() => {
    buscarDadesFiltreExcursio();
    muntarSelectEstat();
  },[]);

  useEffect(() => {
    buscarDadesFiltreClasses();
  },[cursEscolarTriat]);



  const buscarDadesFiltreClasses = async () => {
    const cla = await Api.get(`/classes/all/${cursEscolarTriat}`);
    if(cla.result.success==1){
      const filtreCl = cla.data.classes.map((item)=>{
        return {text: item.nom, value: item.id}
      });
      setFiltresClasses(filtreCl);
    }
  }
  const buscarDadesFiltreExcursio = async () => {
    const cla = await Api.get(`/botiga/categoria/2/${cursEscolarTriat}`);
    if(cla.result.success==1){
      const filtreCl = cla.data.productes.map((item)=>{
        let nom = item.nom[props.t('idioma')];
        //let text = nom + " ("+ moment(item.data_hora_esdeveniment).tz('Europe/Madrid').format("DD/MM/YYYY HH:mm")+")";
        //nom = nom + " ".repeat(25-nom.length)+" : ";

        return {text: <span><b>{entities.decode(nom)} </b>(<Moment date={moment(item.data_hora_esdeveniment).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/>)</span>, value: item.id}
        //return {text: <span>{text}</span>, value: item.id}
      });
      setFiltresExcursions(filtreCl);
    }
  }


  const buscarDades = async () => {
    const com = await Api.get(`/comandes/botiga/${cursEscolarTriat}/2`);
    if(com.result.success===1){
      return com.data.comandes;
    }
  }


  const eliminarComanda = async (id) => {
  const c = await Api.myFetch(`/comandes/${id}`, 'DELETE');
    if(c.result.success===1){
      //setRefrescar(refrescar+1)
    }

  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }

  const modificarEstatTriats = async () =>{

    setModificarEstatTriatsLoading(true);

    let dades = {
      estat: estatTriat,
      ids_comandes: triats
    }

    try{
      //  console.log(JSON.stringify(dades))
      const resposta = await Api.myFetch(`/comandes/roba/estat/${estatTriat}`, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setTriats([]);
        setEstatTriat('Pendent');
        setModificarEstatTriatsLoading(false);
        setModalEstatVisible(false);
        setRefrescar(refrescar+1);
      }else{
        setModificarEstatTriatsLoading(false);
        console.error('Error: submit ComandesRoba estat: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
        return false;
      }

    }catch(error){
      setModificarEstatTriatsLoading(false);
      message.config({top: 50});
      message.open({content: "Error saving state", duration:10});
      console.error('Error: ComandesRoba guardar estat: ', error);
      return false;
    }

  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

  const muntarSelectEstat = () =>{

    let estats = [
      'Pendent',
      'SenseStock',
      'EnPreparacio',
      'Preparat',
      'Entregat',
      'Facturat',
      'Previsio',
      'Anulat'
    ];

    const llista_estats = estats.map((valor) =>{
      return(
        <Select.Option key={valor} value={valor}>
          <span><AlertFilled style={{color: `${props.t(`botiga.estatColor.${valor}`)}`}}/>&nbsp;{props.t(`botiga.estat.${valor}`)}</span>
        </Select.Option>
      );
    });
    setEstatsOptions(llista_estats);
  }


  /*const tableHandleChange = (pagination, filters, sorter, extra) => {
    console.log('tableHandleChange pagination: ', pagination);
    console.log('tableHandleChange filters: ', filters);
    console.log('tableHandleChange sorter: ', sorter);
    console.log('tableHandleChange EXTRA: ', extra);
  }*/

  const filtreEstat =[
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Pendent')}`}}/>&nbsp;{props.t('botiga.estat.Pendent')}</span>, value: 'Pendent'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.SenseStock')}`}}/>&nbsp;{props.t('botiga.estat.SenseStock')}</span>, value: 'SenseStock'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.EnPreparacio')}`}}/>&nbsp;{props.t('botiga.estat.EnPreparacio')}</span>, value: 'EnPreparacio'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Preparat')}`}}/>&nbsp;{props.t('botiga.estat.Preparat')}</span>, value: 'Preparat'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Entregat')}`}}/>&nbsp;{props.t('botiga.estat.Entregat')}</span>, value: 'Entregat'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Facturat')}`}}/>&nbsp;{props.t('botiga.estat.Facturat')}</span>, value: 'Facturat'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Previsio')}`}}/>&nbsp;{props.t('botiga.estat.Previsio')}</span>, value: 'Previsio'},
  {text: <span><AlertFilled style={{color: `${props.t('botiga.estatColor.Anulat')}`}}/>&nbsp;{props.t('botiga.estat.Anulat')}</span>, value: 'Anulat'}
  ];

const columns = [
{
  title:"Id",
  key:"id",
  sorter: (a, b) =>  a.id - b.id,
  render: (text,record) => (<span>{record.id}</span>)
},{
  title: props.t('botiga.titolEstat'),
  key:"estat",
  dataIndex: 'estat',
  filters: filtreEstat,
  defaultFilteredValue: ['Pendent','SenseStock','EnPreparacio','Preparat'],
  onFilter: (value, record) => record.estat === value,
  render: (text,record) =>{
    return (<span><AlertFilled style={{color: `${props.t(`botiga.estatColor.${record.estat}`)}`}}/>&nbsp;{props.t(`botiga.estat.${record.estat}`)}</span>);
  },
  sorter: (a, b) => { return a.estat.localeCompare(b.estat)}
},{
  title: props.t('botiga.producte'),
  key: "nom_ca",
  dataIndex: 'nom_ca',
  filters: filtresExcursions,
  onFilter: (value, record) => record.id_producte === value,
  render: (text,record) =>(entities.decode(record.nom_ca)),
  sorter: (a, b) => { return entities.decode(a.nom_ca).localeCompare(entities.decode(b.nom_ca))},
},{
  title: props.t('alumnes.classe'),
  key:"classe",
  dataIndex: 'classe',
  filters: filtresClasses.length > 0 ? filtresClasses : null,
  onFilter: (value, record) => {
    return record.id_classe === value;
  },
  render: (text,record) =>(entities.decode(record.classe)),
  sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
},{
  title: props.t('generic.alumne'),
  key:"alumne_complet",
  dataIndex: 'alumne_complet',
  render: (text,record) =>(entities.decode(record.alumne_complet)),
  sorter: (a, b) => { return entities.decode(a.alumne_complet).localeCompare(entities.decode(b.alumne_complet))},
  ...getColumnSearchProps('alumne_complet',props.t('generic.alumne'))
},{
  title:props.t('generic.total'),
  key:"total",
  dataIndex: 'total',
  render: (text,record) => (<span>{record.total}€</span>),
  sorter: (a, b) =>  a.total - b.total
},{
  title:props.t('generic.created_at'),
  key:"creat",
  sorter: (a, b) => moment(a.creat).diff(moment(b.creat)),
  //render:(text,record) => (record.creat ? <Moment date={moment(record.creat)} format="DD/MM/YYYY HH:mm"/> : "" )
  render:(text,record) => (record.creat ? <Moment date={moment(record.creat).tz('Europe/Madrid')} format="DD/MM/YYYY HH:mm"/> : "" )
}];

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.ComandesExcursions')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditableModalNouEdit
          scroll={{ x: 1500}}
          txt_creats={props.t('comandes.creados')}
          txt_nou={props.t('comandes.nuevo')}
          modal_width={900}
          buscarDades={buscarDades}
          dadesMostradesCallback={(dadesMostrades) => {
            return(
              <div style={{marginTop: '1rem'}}>
                <ComandesExcursionsExportaExcel dades={dadesMostrades} btn_txt="Export"/>
              </div>
            )
          }}
          refrescar={refrescar}
          edit={Edit}
          add={Edit}
          id_curs_escolar={cursEscolarTriat}
          btn_extra={
            <>
            <TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>
            <Button type="default" disabled={!triats.length > 0 } onClick={()=>setModalEstatVisible(true)}>{props.t('comandes.modificar_estat')}</Button>
            <Tooltip placement="top" title={props.t('comandes.triats_netejar')}>
               <Button type="default" disabled={!triats.length > 0 }  icon={<ClearOutlined/>} onClick={()=>setTriats([])}></Button>
            </Tooltip>
            </>
          }
          duplica={true}
          delete={eliminarComanda}
          itemBuit={InitDades.comandes_roba()}
          columns={columns}
          icon_creats={InboxOutlined}
          rowSelection={{
            selectedRowKeys: triats,
            hideSelectAll: false,
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              setTriats(selectedRowKeys);
          }
        }}
          />

      </div>

      <Modal
        title={props.t('comandes.modificar_estat')}
        destroyOnClose={true}
        visible={modalEstatVisible}
        onOk={modificarEstatTriats}
        confirmLoading={modificarEstatTriatsLoading}
        onCancel={()=>setModalEstatVisible(false)}
      >
        <p>{props.t('comandes.modificar_estat_comandes')}</p>
        <Form.Item label={props.t('botiga.titolEstat')}>
          <Select onChange={(value) => setEstatTriat(value)} defaultValue="Pendent">
            {estatsOptions}
          </Select>
        </Form.Item>
      </Modal>
    </ContainerPagina>
  );
}


export default translate(ComandesExcursions);
