import React, { useEffect, useState } from "react";
import { translate, setLanguage, getLanguage } from 'react-multi-lang';
import { Redirect, useParams, Link } from "react-router-dom";
import { Layout, Row, Col, Form, Input, Button, Typography, Affix, Select, Alert, message } from 'antd';
import sha512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

import { Api } from '../helpers';

const { Header, Footer, Content } = Layout;
const Option = Select.Option;

function LoginRecover(props){

let { idsesion } = useParams();

const [token, setToken] = useState();
const [username, setUsername] = useState();
const [guardant, setGuardant] = useState(false);
const [password1, setPassword1] = useState();
const [password2, setPassword2] = useState();
const [alerta, setAlerta] = useState({type:'', message:'ss', description:'ee'})

const [errorPassword1, setErrorPassword1] = useState('');
const [errorPassword2, setErrorPassword2] = useState('');
const [errorPassword2Text, setErrorPassword2Text] = useState('');

const logo=require('../images/logo_h_t_ajustat.png');

const changeLang = (lang) =>{
  setLanguage(lang);
  sessionStorage.setItem('Language',lang);
}

const onChangePassword1 = (valor) => {
  if(valor.length>0){
    setErrorPassword1('');
  }
  setPassword1(valor);
}

const onChangePassword2 = (valor) => {
  if(valor.length>0){
    setErrorPassword2('');
    setErrorPassword2Text('')
  }
  setPassword2(valor);
}

useEffect(() => {
  //console.log(props.match.params)
  sessionStorage.removeItem('jwtToken');
  sessionStorage.removeItem('jwtRefreshToken');
  sessionStorage.removeItem('perfil');
  sessionStorage.removeItem('config');
  //console.log(idsesion)

  if(idsesion){
    buscarToken(idsesion);
  }

},[idsesion]);


const buscarToken = async (idsesion) => {

  try{
    let res = await fetch(global.api_url+"/users/recover/"+idsesion, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
      },
    });
    let responseJson = await res.json();
    //console.log(responseJson)
    if(responseJson.result.success === 1){

      if(Api.tokenCaducat(responseJson.data.token)){
        setAlerta({type:'error', message: props.t('generic.nova_contrasenya'), description: 'Expired Link'});
      }else{
        setToken(responseJson.data.token)
        setUsername(responseJson.data.username)
      }
    }else{
      setAlerta({type:'error', message: props.t('generic.nova_contrasenya'), description: 'Expired Link'});
    }
  }catch(error){    
    message.config({top: 50});
    message.open({content: "Error idsesion", duration:10});
    console.error('Error: buscant idsesion: ', error);
    setAlerta({type:'error', message: props.t('generic.nova_contrasenya'), description: 'Expired Link'});

  }
}

const canviPassword = async (password) => {
  const password_hash = Base64.stringify(sha512(password));

  let dades = {
    password: password_hash
  }

  try{
  const user_id = Api.recoverUserIdFromToken(token);

    let res = await fetch(global.api_url+"/users/contrasenya/"+user_id, {
      method: 'PUT',
      headers:{
        'Content-Type': 'application/json; charset=ISO-8859-1',
        'Authorization': token
      },
      body: JSON.stringify(dades)
    });
    let responseJson = await res.json();
    if(responseJson.result.success === 1){

      return true;
    }else{

      return false;
    }
  }catch(error){
    setGuardant(false);
    message.config({top: 50});
    message.open({content: "Error saving", duration:10});
    console.error('Error: NewPassword guardar: ', error);
    return false;
  }
}



const submitForm = async (valors) => {

  let correcte = true;

  if(!password1){
    setErrorPassword1('error');
    correcte = false;
  }
  if(!password2){
    setErrorPassword2('error');
    correcte = false;
  }

  if(password1!==password2){
    setErrorPassword2('error');
    setErrorPassword2Text(props.t('generic.contrasenya_diferent'));
    correcte = false;
  }

  if(!correcte){
    return false;
  }

  setGuardant(true);

  const modificat = await canviPassword(password1);
  if(modificat){
    setAlerta({type:'success', message: props.t('generic.nova_contrasenya'), description: props.t('generic.OK_saved')});
  }else{
    setAlerta({type:'error', message: props.t('generic.nova_contrasenya'), description: props.t('generic.KO_saved')});
  }

  setGuardant(false);
}

let container;
return (
  <div ref={node => {container = node;}}>
    <Layout style={{backgroundColor: 'white', minHeight: '100vh'}}>
      <Header style={{backgroundColor: 'white', height:'15vh'}}></Header>
      <Content  style={{backgroundColor: 'white'}}>
        <Row type="flex" justify="center">
            <img src={logo} alt="Logo" width="300"/>
        </Row>



        {alerta.type === '' ?
          <Row type="flex" justify="center" style={{marginTop: `15px`}}>
            <Form onFinish={submitForm}
                layout="vertical"
                className="login-form"
                autoComplete="off"
              >
                <Form.Item label={props.t('generic.nova_contrasenya')} validateStatus={errorPassword1}>
                  <Input.Password name="qualsevol_cosa1" placeholder={props.t('generic.nova_contrasenya')} onChange={(e) => onChangePassword1(e.target.value)} defaultValue={password1} autoComplete="çlaskdfjçalkdfjç"/>
                </Form.Item>
                <Form.Item label={props.t('generic.repetir_contrasenya')} validateStatus={errorPassword2} help={errorPassword2Text}>
                  <Input.Password name="qualsevol_cosa2" placeholder={props.t('generic.repetir_contrasenya')} onChange={(e) => onChangePassword2(e.target.value)} defaultValue={password2} autoComplete="ñlaskdfjñalkdfj"/>
                </Form.Item>
                <Row type="flex" justify="center" style={{marginTop: 25, marginBottom: 25}}>
                  <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
                </Row>
            </Form>
        </Row>
        :
          <>
            <Row type="flex" justify="center" style={{marginTop: `20px`}}>
              <Alert {...alerta}  showIcon/>
            </Row>
            <Row type="flex" justify="center" style={{marginTop: `15px`}}>
              <Link to={"/"}><Button type="primary">{props.t('login.anar_login')}</Button></Link>
            </Row>
          </>
        }


      </Content>
      <Footer style={{ textAlign: 'center', backgroundColor: 'white', padding: 12}}>
        <Affix offsetBottom={10} target={() => container}>
          <Row type="flex">
            <Col xs={{span:24,order:2}} sm={{span:24, order:2}} md={{span:14, order:1}} lg={{span:8, order:1}}>Copyright © {new Date().getFullYear()} BlackData Software. All rights reserved.</Col>
            <Col xs={{span:24,order:1}} sm={{span:24, order:1}} md={{span:2, order:2, offset: 7}} lg={{span:2, order:2, offset: 12}}>
            <Select defaultValue={getLanguage()} onChange={changeLang} style={{ width: 120}}>
              <Option value="en">English</Option>
              <Option value="ca">Català</Option>
              <Option value="es">Castellano</Option>
            </Select>
            </Col>
          </Row>
        </Affix>
      </Footer>
      </Layout>
      </div>

);


}

export default translate(LoginRecover);
