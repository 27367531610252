import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Typography, Button, Card, Radio, Checkbox, Space, Select, Form, DatePicker, Alert, message } from 'antd';
import {SyncOutlined, MinusOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

const radioStyle = {
      width:'100%',
      marginTop: '8px',
    };
function Transport(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);

  const [acollides, setAcollides] = useState([]);
  const [triat, setTriat] = useState([]);
  const [diesTriats, setDiesTriats] = useState([]);
  const [numDies, setNumDies] = useState(0);

  const ids_fills = props.ids_fills ? props.ids_fills : [props.id_alumne];

  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [comprat, setComprat] = useState(false);
  const [llistaCompratsPuntual, setLlistaCompratsPuntual] = useState([]);

  const [error, setError] = useState('');

  const [total, setTotal] = useState(0);
  const [numFills, setNumFills] = useState(1);


  useEffect(() => {
    buscarDades();
  },[]);

  useEffect(() => {
    calcularTotal();
  },[triat, numFills, numDies]);


  const buscarDades = async () => {
    setIsLoading(true);
    const resp = await Api.get('/acollida/tipus/puntual');
    if(resp.result.success===1){


      const as = resp.data.acollides.map((acollida) =>{
        const comprats_puntual = acollida.comprat.map((i) =>{
          var d = new Date(i.puntual_data);
          return(
            <Row justify="space-between" key={i.puntual_data}>
              <Col>{entities.decode(i.nom)}</Col>
              <Col>{entities.decode(i.text[props.t('idioma')])}</Col>
              <Col>{d.toLocaleDateString()}</Col>
            </Row>
          );
        });
        if(comprats_puntual.length>0){
          setComprat(true)
        }
        setLlistaCompratsPuntual(comprats_puntual);
        acollida.propietats = acollida.propietats.map((item) =>{
          if(acollida.descompte>0){
            item.preu = (item.preu - (item.preu * acollida.descompte / 100));
          }

          return item;
        });
        return acollida;
      });
      //console.log(as);
      setAcollides(as);
    }
    setIsLoading(false);
  }

const calcularTotal = () => {

  let subtotal = 0;
  if(triat.length>0){

    triat.forEach((item, i) => {
      //console.log('calcularTotal', item)
      subtotal = subtotal + (item.preu * numFills * numDies);
    });
  }
  setTotal(subtotal.toFixed(2));
}


  const submitForm = async (valors) => {


    if(!validat){
      return false;
    }

    if(triat.length<=0){
      message.config({top: 50});
      message.error({content: 'Error: '+ props.t('generic.tria'), duration:3});
      return false;
    }


    if(diesTriats.length <= 0){
      message.config({top: 50});
      message.error({content: 'Error: '+ props.t('generic.tria_dies'), duration:3});
      return false;
    }


setGuardant(true);

  const dades = {
    id_alumnes: numFills === 1 ? [props.id_alumne] : ids_fills,
    id_pare: perfilUsuari.account.id,
    tipus: 'puntual',
    puntual_dates: diesTriats.map((item) =>item.format(moment.HTML5_FMT.DATE)),
    ids_propietats: triat.map((item) => item.id_propietat)
  }

  //console.log(JSON.stringify(dades));


    try{
      const resposta = await Api.myFetch('/acollida/comanda', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Acollida: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Acollida guardar: ', error);
    }

  }


  const onChangeProps = (checkedValues) => {
    //console.log('checked = ', checkedValues);
    setTriat(checkedValues);
  }

  const onChangeTotsFills = (tots) =>{
    setNumFills(tots ? ids_fills.length : 1);
  }

  const onDiaTriat = (date, dateString) =>{
    const trobat = diesTriats.filter((element, index, arr) => element.format(moment.HTML5_FMT.DATE) === date.format(moment.HTML5_FMT.DATE));
    if(trobat.length === 0){
      const dies = [...diesTriats, date];
      setDiesTriats(dies);
      setNumDies(dies.length);
    }
  }

  const onEliminaDia = (item) => {
    const filtrat = diesTriats.filter((element, index, arr) => element !== item);
    setDiesTriats(filtrat);
    setNumDies(filtrat.length);
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';

  if(isLoading || acollides.length===0){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {

  return(
    <>
    { acollides.map((acollida) =>{

        return(
          <>
          <Row justify="center" style={{marginTop:'10px'}}>
            <Typography.Title level={4}>{entities.decode(acollida.nom[props.t('idioma')])}</Typography.Title>
          </Row>

          <Row justify="left">
            <Form.Item required={true} validateStatus={error} style={{marginTop: 0, paddingRight: '20px', width: '100%'}}>
              <Checkbox.Group style={{margin: '10px', width: '100%'}} onChange={onChangeProps}>
                {
                  acollida.propietats.map((item) =>{

                    const text = entities.decode(item.text[props.t('idioma')]);

                    return(
                      <Col span={24} style={{paddingLeft:'10px',paddingRight:'10px'}}>
                        <Row justify="center">
                          <Col span={20}>
                            <Checkbox value={item} key={item.id_propietat}>{text}</Checkbox>
                          </Col>
                          <Col span={4}><span style={{color:'red', marginLeft: '10px'}}>{item.preu.toFixed(2)}€</span></Col>
                        </Row>
                      </Col>
                    )
                  })
                }
              </Checkbox.Group>
            </Form.Item>
          </Row>
        </>
      )
    })
  }



  <Row justify="center" style={{marginTop:'10px'}}>
    <DatePicker
      format={dateFormat}
      locale={localeDatePicker}
      placeholder={'Tria dia o dies'}
      value={null}
      onChange={onDiaTriat}/>
  </Row>
  {!comprat ? null :
  <Row justify="center" style={{marginTop:'10px'}}>
    <Alert
      message={props.t('generic.serveiJaContractat_L1')}
      description={
        <>
          {llistaCompratsPuntual}
          {<span style={{color:'red'}}>*{props.t('generic.serveiJaContractat_L2')}</span>}
        </>
      }
      type="success"
      showIcon
    />
  </Row>
  }
  <Row justify="center" style={{marginTop:'10px'}}>
  <Col span={8}>
  {diesTriats.map((item) =>{
    return(
      <Row justify="space-between" key={item.format(dateFormat)}>
        <Col>{item.format(dateFormat)}</Col>
        <Col><DeleteOutlined onClick={()=>onEliminaDia(item)}/></Col>
      </Row>
    );
  })}
  </Col>
  </Row>

    <Row justify="center" style={{marginTop:'10px'}}>
      <Space direction="vertical" align="center">
        <Space><b style={{fontSize: '18px'}}>Total:</b><span style={{color: 'red', fontSize: '18px'}}>{total}€</span></Space>
      </Space>
    </Row>


    {ids_fills.length <= 1 ? null :
      <Row justify="center" style={{marginTop:'10px'}}>
        <Checkbox onChange={(e) => onChangeTotsFills(e.target.checked)}>{props.t('transport.tots_fills')}</Checkbox>
      </Row>
    }
    <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
      <AutoritzarContrasenya resposta={setValidat}/>
    </Row>
    <Row justify="center" style={{marginTop: '10px'}}>
      <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
        {props.t('botiga.pagar_autoritzar')}
      </Button>
    </Row>
    </>
  );
}
}
export default translate(Transport);
