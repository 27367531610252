
import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Select, Button } from 'antd';
import { AllHtmlEntities } from 'html-entities';
import { Api } from '../helpers';
import { PerfilUsuariContext } from "../context/perfil-usuari";

const entities = new AllHtmlEntities();

function TriaClasses(props){
  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [triats, setTriats] = useState(props.defaultValue || []);
  const [classes, setClasses] = useState([]);
  const [classesIds, setClassesIds] = useState([]);
  const [botonsCicles, setBotonsCicles] = useState([]);

  const buscarDades = async () => {

    const c = await Api.get('/classes');
    if(c.result.success===1){
      const ids = [];
      const llista_classes = c.data.classes.map((item) =>{
            ids.push(item.id);
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.nom)}
              </Select.Option>
            );
      });
      setClassesIds(ids);
      setClasses(llista_classes);
    }

    const ci = await Api.get(`/classes/agrupats/cicle/${perfilUsuari.curs_escolar_actual.id}`);
    if(ci.result.success===1){
        const btn_cicles = ci.data.map((item) =>{
          return(
            <Button key={'btn_'+item.id_cicle} onClick={()=>handleChange(item.classes_ids)} size="small">{item.nom}</Button>
          );
        });
        setBotonsCicles(btn_cicles);
    }

  }

  useEffect(() => {
    buscarDades();
  },[]);


  const handleChange = (value) => {
    setTriats(value);
    if(props.onChange){
      props.onChange(value);
    }
  }
  const btn_tots = () => {
    setTriats(classesIds);
    if(props.onChange){
      props.onChange(classesIds);
    }
  }
  const btn_cap = () => {
    setTriats([]);
    if(props.onChange){
      props.onChange([]);
    }
  }


    return(
      <>
      <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={props.t('generic.tria_varis')}
          showArrow={true}
          //defaultValue={triats}
          onChange={handleChange}
          value={triats}
        >
          {classes}
        </Select>
        <Button onClick={()=>btn_tots()} size="small">{props.t('generic.all')}</Button>
        <Button onClick={()=>btn_cap()} size="small">{props.t('generic.none')}</Button>
        {botonsCicles}
      </>
    );
}
export default translate(TriaClasses);
