import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id] = useState(props.item.id);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));

  const modal = props.modal ? props.modal : false;

  const [guardant, setGuardant] = useState(false);

  const [errorOrdre, setErrorOrdre] = useState('');
  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');


  const nou = (id===0);

  const ordreChange = (value) =>{
    setOrdre(value);
    if(Number.isInteger(value)){
      setErrorOrdre('');
    }
  }
  const nom_caChange = (e) =>{
    const n = e.target.value.trim();
    setNom_ca(n);
    if(n.length>0){
      setErrorNom_ca('');
    }
  }
  const nom_enChange = (e) =>{
    const n = e.target.value.trim();
    setNom_en(n);
    if(n.length>0){
      setErrorNom_en('');
    }
  }
  const nom_esChange = (e) =>{
    const n = e.target.value.trim();
    setNom_es(n);
    if(n.length>0){
      setErrorNom_es('');
    }
  }


  const submitForm = async (values) => {

      let correcte = true;

      if(!Number.isInteger(ordre)){
        setErrorOrdre('error');
        correcte = false;
      }
      if(nom_ca.length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(nom_en.length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(nom_es.length === 0){
        setErrorNom_es('error');
        correcte = false;
      }


      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        ordre,
        nom_ca: entities.encode(nom_ca),
        nom_en: entities.encode(nom_en),
        nom_es: entities.encode(nom_es)
      }
      //console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/motius/absencia' : '/motius/absencia/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          //console.log(resposta);
          setGuardant(false);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();

        }else{
          setGuardant(false);
          console.error('Error: submit Form BotigaTalles: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: BotigaTalles guardar: ', error);
      }

  }


  return (
    <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="vertical"
      //labelCol={{span:2}}
      wrapperCol={modal ? {span:16} : {span:7}}
      //colon={true}
      autoComplete="off"
    >
      <Form.Item
        validateStatus={errorOrdre}
        label={props.t('transport.ordre')}
        required={true}
      >
          <InputNumber name="ordre" onChange={ordreChange} defaultValue={ordre} min={0} step={1} />
      </Form.Item>
      <Form.Item
        validateStatus={errorNom_ca}
        label={props.t('botiga_propietats.text')+' CA'}
        required={true}
      >
        <Input name="nom_ca" placeholder={props.t('botiga_propietats.text')} onChange={nom_caChange} defaultValue={nom_ca}/>
      </Form.Item>
      <Form.Item
        validateStatus={errorNom_en}
        label={props.t('botiga_propietats.text')+' EN'}
        required={true}
      >
        <Input name="nom_en" placeholder={props.t('botiga_propietats.text')} onChange={nom_enChange} defaultValue={nom_en}/>
      </Form.Item>
      <Form.Item
        validateStatus={errorNom_es}
        label={props.t('botiga_propietats.text')+' ES'}
        required={true}
      >
        <Input name="nom_es" placeholder={props.t('botiga_propietats.text')} onChange={nom_esChange} defaultValue={nom_es}/>
      </Form.Item>


      <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
        <Row type="flex" justify="start">
          <Col>
            <Button onClick={props.callback}>{props.t('generic.back')}</Button>
          </Col>
          <Col style={{marginLeft:'10px'}}>
            <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
          </Col>
        </Row>
      </Form.Item>
      </Form>
    </div>
  );
}
export default translate(Edit);
