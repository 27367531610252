import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Button, List, Card, Select, Form, Table, Checkbox, Input, Space,Tooltip, Modal, DatePicker, message } from 'antd';
import { SearchOutlined, AlertFilled, EyeOutlined } from '@ant-design/icons';

import Moment from 'react-moment';
import moment from 'moment';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';
import { MenuTriatContext } from "../../../../context/menu-triat";
import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
//import Edit from './AcollidaEditClasse';
//import Edit from './AcollidaEditFamilia';
import Edit from './AcollidaTriaClasseFamilia';
import LlistaEditableModalNouEditComandes from '../../../LlistaEditableModalNouEditComandes';
import { Api, InitDades } from '../../../../helpers';
import {PerfilUsuariContext} from "../../../../context/perfil-usuari";

const entities = new AllHtmlEntities();
moment.locale('es');
const dateFormat = 'DD/MM/YYYY';

function Acollida(props){
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  const [filtresClasses, setFiltresClasses] = useState([]);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);
  const [diaTriat, setDiaTriat] = useState(moment());
  const [refrescar, setRefrescar] = useState(0);
  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  useEffect(() => {
    creaFiltreClasses(cursEscolarTriat);
  },[cursEscolarTriat]);


  useEffect(() => {
    setRefrescar(refrescar+1);
  },[diaTriat]);

  const creaFiltreClasses = async (curs) => {
    const cla = await Api.get(`/classes/all/${curs}`);
    if(cla.result.success!==1){return [];}

    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    setFiltresClasses(filtreCl);
  }

  const onChangeDia = (date, dateString) => {

    setDiaTriat(date ? date : '');

};

  const buscarDades = async () => {
    let comandes = [];
    //const c = await Api.get('/acollidaminuts/comandes/avui');
    const dia = diaTriat ? diaTriat.clone().format('YYYY-MM-DD') : null;
    console.log(`/acollidaminuts/comandes/dia/${dia}`)
    const c = await Api.get(`/acollidaminuts/comandes/dia/${dia}`);
    if(c.result.success===1){
      console.log("buscarDades: ", c.data.comandes)
      console.log('props.tipus', props.tipus)
      c.data.comandes.map((item)=>{
        if(item.tipus === props.tipus){
          item.alumne_nom_t = entities.decode(item.alumne_nom);
          item.pare_nom_t = entities.decode(item.pare_nom);
          comandes.push(item);
        }
      });
    }
    return comandes;
  }

  const eliminar = async (id) => {
    const del = await Api.myFetch(`/acollidaminuts/comanda/${id}`, 'DELETE');
    if(del.result.success===1){

    }
  }

  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }


  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

  const columns_puntual = [
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title: props.t('alumnes.classe'),
    key:"classe",
    dataIndex: 'classe',
    filters: filtresClasses,
    onFilter: (value, record) => record.id_classe === value,
    render: (text,record) =>(entities.decode(record.classe)),
    sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
  },{
    title: props.t('alumnes.nom'),
    key:"alumne_nom_t",
    dataIndex: 'alumne_nom_t',
    sorter: (a, b) => { return a.alumne_nom_t.localeCompare(b.alumne_nom_t)},
    ...getColumnSearchProps('alumne_nom_t',props.t('alumnes.nom'))
  },{
    title: props.t('acollida.minuts'),
    key:"minuts_facturar",
    sorter: (a, b) =>  a.minuts_facturar - b.minuts_facturar,
    render:(text,record) => (<span>{record.minuts_facturar}</span>)
  },/*{
    title: props.t('generic.total'),
    key:"total",
    sorter: (a, b) =>  a.total - b.total,
    render:(text,record) => (<span>{record.total}€</span>)
  },*/{
    title: props.t('generic.data'),
    key:"puntual_data",
    sorter: (a, b) => moment(a.puntual_data).diff(moment(b.puntual_data)),
    responsive:['md'],
    render:(text,record) => (record.puntual_data ? <Moment date={new Date(record.puntual_data)} format="DD/MM/YYYY"/> : "" )
  }];

  let itemBuit = InitDades.acollidaMinutsComanda();
  itemBuit.tipus = props.tipus;

  return(
    <LlistaEditableModalNouEditComandes
      txt_creats={props.t('menu.Comandes')}
      txt_nou={props.t('generic.add')}
      btn_extra={<DatePicker value={diaTriat ? moment(diaTriat, dateFormat) : null} format={dateFormat} locale={localeDatePicker} onChange={onChangeDia} />}
      buscarDades={buscarDades}
      refrescar={refrescar}
      edit={Edit}
      add={Edit}
      delete={eliminar}
      itemBuit={itemBuit}
      columns={columns_puntual}
      />
  );
}

export default translate(Acollida);
