import React, {useState, useEffect} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Popover, Row, Space} from 'antd';
import { SyncOutlined, BulbFilled } from '@ant-design/icons';

import { AllHtmlEntities } from 'html-entities';
import { Api, HtmlStrings } from '../../../../helpers';
import Moment from 'react-moment';
import moment from 'moment';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';

const entities = new AllHtmlEntities();
moment.locale('es');
const limitTextMostrat = 50;

function MealTimeReport(props){

  const [reports, setReports] = useState([]);
  const [firstReport, setFirstReport] = useState({semafor: 'verd',
                                                  frase: {
                                                    ca: props.t('generic.empty'),
                                                    en: props.t('generic.empty'),
                                                    es: props.t('generic.empty'),
                                                  },
                                                  week: 0});
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    buscarDades();
  },[]);

  const buscarDades = async () => {
    setIsLoading(true);
    let llista_f = [];
    const a = await Api.get(`/mtr/alumne/${props.id_alumne}`);
    if(a.result.success===1){
      setReports(a.data.reports);
      setFirstReport(a.data.reports[0])
    }

    setIsLoading(false);
  }


  const columns = [
  {
    title: props.t('menjador.report.semafor_titol'),
    key:"semafor",
    sorter: (a, b) =>  a.semafor.localeCompare(b.semafor),
    render: (text,record) => (<BulbFilled style={{color: `${props.t(`menjador.report.semafor.${record.semafor}`)}`}}/>)
  },{
    title:"Week",
    key:"week",
    sorter: (a, b) =>  a.week - b.week,
    render: (text,record) => {

      const w = parseInt(record.week.toString().substring(5));
      const y = parseInt(record.week.toString().substring(0,4));
      const setmana = moment().year(y).isoWeek(w);

      return(
        <span>{setmana.startOf('week').format('DD/MM/YYYY')} to {setmana.endOf('week').format('DD/MM/YYYY')}</span>
      )
    }
  },{
    title: props.t('menjador.report.frases'),
    key:"frase",
    render:(text,record) => {
      const puntets = record.frase[props.t('idioma')].length > limitTextMostrat ? "..." : "";
      return (
      <Popover style={{width: '800px'}} content={entities.decode(record.frase[props.t('idioma')])} title={props.t('botiga_propietats.text')+' '+props.t('idioma').toString().toUpperCase()}>
        {entities.decode(record.frase[props.t('idioma')]).slice(0,limitTextMostrat)+puntets}
      </Popover>
    )
      }
  }];
  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {
    return (
      <>
        <Row justify="center">
          <Space align="center" direction="vertical">
            <BulbFilled style={{fontSize: '52px', color: `${props.t(`menjador.report.semafor.${firstReport.semafor}`)}`}}/>
            <span>{entities.decode(firstReport.frase[props.t('idioma')])}</span>
          </Space>
        </Row>
        <Table size="small" dataSource={reports} columns={columns} loading={isLoading} pagination={false} style={{marginTop: '2rem'}}/>
      </>
    )
  }
}
export default translate(MealTimeReport)
