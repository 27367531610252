import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined } from '@ant-design/icons';

import {Steps, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';

import { AllHtmlEntities } from 'html-entities';

//import SelectMesos from '../../../SelectMesos';
import { Api, HtmlStrings } from '../../../../helpers';

const entities = new AllHtmlEntities();


function FacturacioCrea(props){


  const [id, setId] = useState(0);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.curs_escolar || 0);
  const [descripcio, setDescripcio] = useState(null);
  const [observacions, setObservacions] = useState(null);
  const [mes_a_calcular, setMes_a_calcular] = useState(0);

  const [pas, setPas] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [passosVisibles, setPassosVisibles] = useState(false);
  const [selectCursosEscolars, setSelectCursosEscolars] = useState([]);

  const [errorId_curs_escolar, setErrorId_curs_escolar] = useState('');
  const [errorId_curs_escolarText, setErrorId_curs_escolarText] = useState('');
  const [errorMes, setErrorMes] = useState('');
  const [errorMesText, setErrorMesText] = useState('');
  const [errorDescripcio, setErrorDescripcio] = useState('');

  const contenidorRef = React.useRef();

  useEffect(() => {
    buscarDades();
  },[]);



  const buscarDades = async () =>{
    const cursos = await Api.get('/cursosescolars');
    if(cursos.result.success===1){
      const select = cursos.data.cursos.map((item)=>{
        return(
          <Select.Option key={item.id} value={item.id}>
            {entities.decode(item.nom)}
          </Select.Option>
        );
      });
      setSelectCursosEscolars(select);
    }
  }

  const triaCursEscolar = (value) =>{
    setId_curs_escolar(value);
    if(value>0){
      setErrorId_curs_escolar('');
      setErrorId_curs_escolarText('');
    }
  }
  const onChangeDescripcio = (value) =>{
    setDescripcio(value);
    if(value){
      setErrorDescripcio('');
    }
  }
  const triaMes = (value) =>{
    setMes_a_calcular(value);
    if(value>0){
      setErrorMes('');
      setErrorMesText('');
    }
  }


  const submitForm = async (values) =>{

      let correcte = true;

      if(id_curs_escolar<=0){
        correcte = false;
        setErrorId_curs_escolar('error');
        setErrorId_curs_escolarText(props.t('generic.tria'));
      }
      if(!descripcio){
        correcte = false;
        setErrorDescripcio('error');
      }

      if(mes_a_calcular===0){
        correcte = false;
        setErrorMes('error');
        setErrorMesText(props.t('generic.tria'));
      }

      if(!correcte){return false}

      setIsLoading(true)
      const dades = {
        id_curs_escolar,
        descripcio,
        observacions,
        mes_a_calcular
      }
      try{
        const resposta = await Api.myFetch('/pressupost', 'POST', JSON.stringify(dades));
        if(resposta.result.success === 1){
          setId(resposta.data.id);
          await pasosCrearPressupost(resposta.data.id);
          setIsLoading(false);
        }else{
          setIsLoading(false);
          console.error('Error: submit Form FacturacioCrea: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }

      }catch(error){
        setIsLoading(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: FacturacioCrea guardar: ', error);
      }

  }

  const pasosCrearPressupost = async(id_pressupost) =>{
    setPassosVisibles(true);

    contenidorRef.current.scrollIntoView({
      behavior: "smooth",
    });

    const dades = {
      id_pressupost
    }
    try{
      //PAS 0 - QUOTES
      let resposta = await Api.myFetch('/pressupost/quotes', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setPas(1);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioCrea Quotes: ',resposta);
        message.open({content: resposta.data.message, top: 50, duration:10});
        return false;
      }
    //PAS 1 - TRANSPORT - PUNTUAL
      resposta = await Api.myFetch('/pressupost/transport', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setPas(2);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioCrea Transport: ',resposta);
        message.open({content: resposta.data.message, top: 50, duration:10});
        return false;
      }
    //PAS 2 - MENJADOR - PUNTUAL
      resposta = await Api.myFetch('/pressupost/menjador', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setPas(3);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioCrea Menjador: ',resposta);
        message.open({content: resposta.data.message, top: 50, duration:10});
        return false;
      }
    //PAS 3 - ACOLLIDA
    //setPas(4); // Temporalment anulat acollida
  /*
      resposta = await Api.myFetch('/pressupost/acollida', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setPas(4);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioCrea Acollida: ',resposta);
        message.open({content: resposta.data.message, top: 50, duration:10});
        return false;
      }
  */


      //PAS 3 - EXTRAESCOLARS
      resposta = await Api.myFetch('/pressupost/extraescolars', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setPas(4);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioCrea Extraescolars: ',resposta);
        message.open({content: resposta.data.message, top: 50, duration:10});
        return false;
      }
      //PAS 4 - DESCOMPTES
      resposta = await Api.myFetch(`/pressupost/descomptes/${id_pressupost}`, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setPas(5);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioCrea Descomptes: ',resposta);
        message.open({content: resposta.data.message, top: 50, duration:10});
        return false;
      }


      //PAS 5 - RECURRENTS
      resposta = await Api.myFetch(`/pressupost/recurrents/inserta/${id_pressupost}`, 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setPas(6);
      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioCrea Recurrents: ',resposta);
        message.open({content: resposta.data.message, top: 50, duration:10});
        return false;
      }



      //PAS 6 - SUBTOTALS
      resposta = await Api.myFetch(`/pressupost/subtotals/${id_pressupost}`, 'PUT', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setPas(7);
        message.success(props.t('generic.OK_saved'), 5);

      }else{
        setIsLoading(false);
        console.error('Error: submit FacturacioCrea Subtotal: ',resposta);
        message.open({content: resposta.data.message, top: 50, duration:10});
        return false;
      }

    }catch(error){
      setIsLoading(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: FacturacioCrea guardar: ', error);
    }
  }


  let localeDatePicker;
  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      break;
    case 'es':
      localeDatePicker = locale_es;
      break;
    default:
      localeDatePicker = locale_ca;
  }

  const dateFormat = 'DD/MM/YYYY';

  return(
    <>
    <Form onFinish={submitForm}
      layout="vertical"
      colon={true}
      autoComplete="off"
    >
    <Row>
      <Col span={12}>
        <Form.Item label={props.t('generic.curs_escolar')} required={true} validateStatus={errorId_curs_escolar} help={errorId_curs_escolarText} style={{marginBottom: 0}}>
          <Select defaultValue={id_curs_escolar===0 ? '' : id_curs_escolar} onChange={(value) => triaCursEscolar(value)} disabled={pas>0}>
            {selectCursosEscolars}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={props.t('generic.mes')}
          required={true}
          validateStatus={errorMes}
          help={errorMesText}
          style={{marginBottom: 0}}
        >
          {/*<SelectMesos defaultValue={mes_a_calcular===0 ? '' : mes_a_calcular} onChange={(value) => triaMes(value)} disabled={pas>0}/>*/}
          <Select defaultValue={mes_a_calcular===0 ? '' : mes_a_calcular} onChange={(value) => triaMes(value)} disabled={pas>0}>
            <Select.Option key={1} value={1}>{props.t('mesos.1')}</Select.Option>
            <Select.Option key={2} value={2}>{props.t('mesos.2')}</Select.Option>
            <Select.Option key={3} value={3}>{props.t('mesos.3')}</Select.Option>
            <Select.Option key={4} value={4}>{props.t('mesos.4')}</Select.Option>
            <Select.Option key={5} value={5}>{props.t('mesos.5')}</Select.Option>
            <Select.Option key={6} value={6}>{props.t('mesos.6')}</Select.Option>
            <Select.Option key={7} value={7}>{props.t('mesos.7')}</Select.Option>
            <Select.Option key={8} value={8}>{props.t('mesos.8')}</Select.Option>
            <Select.Option key={9} value={9}>{props.t('mesos.9')}</Select.Option>
            <Select.Option key={10} value={10}>{props.t('mesos.10')}</Select.Option>
            <Select.Option key={11} value={11}>{props.t('mesos.11')}</Select.Option>
            <Select.Option key={12} value={12}>{props.t('mesos.12')}</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>

      <Form.Item label={props.t('extraescolars.descripcio')} required={true} validateStatus={errorDescripcio}>
        <Input name="descripcio" placeholder={props.t('extraescolars.descripcio')} onChange={(e) => onChangeDescripcio(e.target.value)} disabled={pas>0}/>
      </Form.Item>

      <Form.Item label={props.t('alumnes.observacions')}>
        <Input name="observacions" placeholder={props.t('alumnes.observacions')} onChange={(e) => setObservacions(e.target.value)} disabled={pas>0}/>
      </Form.Item>

  {!passosVisibles ? null :
    <Row justify="center" ref={contenidorRef}>
      <Col>
        <Steps direction="vertical" current={pas}>
          <Steps.Step title="Quotes" description="Quotes mensuals" />
          <Steps.Step title="Transport" description="Transport puntual" />
          <Steps.Step title="Menjador" description="Menjador puntual" />
          {/*<Steps.Step title="Acollida" description="Acollida mensual i puntual" />*/}
          <Steps.Step title="Extraescolars" description="Exatraescolars de cada alumne" />
          <Steps.Step title="Descomptes" description="Aplicant descomptes de cada alumne." />
          <Steps.Step title="Recurrents" description="Imports Puntuals / Recurrents" />
          <Steps.Step title="Subtotal" description="Calculant subtotals." />
          <Steps.Step title="Finalitzat" description="Procés finalitzat correctament." />
        </Steps>
      </Col>
    </Row>
  }

  <Row justify="center" style={{margin: '1rem'}}>
  { pas === 7 ?
    <Col>
      <Button onClick={()=>props.callback()}>{props.t('generic.back')}</Button>
    </Col>
    :
    <Col>
      <Button type="primary" htmlType="submit" loading={isLoading}>{props.t('facturacio.generar_pressupost')}</Button>
    </Col>
  }
  </Row>
</Form>
    </>
  );
}
export default translate(FacturacioCrea);
