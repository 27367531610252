import React, {useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Row, Col, Tooltip, Popconfirm } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import Moment from 'react-moment';
import moment from 'moment';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditableModalNouEdit from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/ComandesExtraescolarsEdit';
import Add from '../../../components/Formularis/perfils/admin/ComandesExtraescolarsAdd';
import ComandesExtraescolarsExportaExcel from '../../../components/Formularis/perfils/admin/ComandesExtraescolarsExportaExcel';
import { Api, InitDades } from '../../../helpers';
import {PerfilUsuariContext} from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();
moment.locale('es');

function ComandesExtraescolars(props){
  const [ perfilUsuari ] = useContext(PerfilUsuariContext);
  return (<ComandesExtraescolarsClass t={props.t} id_curs_escolar={perfilUsuari.curs_escolar_actual.id}/>);
}

class ComandesExtraescolarsClass extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      filtresClasses: [],
      searchText: '',
      searchedColumn: '',
      refrescar: 0,
      cursEscolarTriat: props.id_curs_escolar,
    };
  }


  async componentDidMount(){
    this.creaFiltreClasses(this.state.cursEscolarTriat);
  }

  creaFiltreClasses = async (curs) => {
    const cla = await Api.get(`/classes/all/${curs}`);
    if(cla.result.success!==1){return [];}

    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    this.setState({filtresClasses: filtreCl});
  }

  buscarDades = async () => {

    const c = await Api.get(`/extraescolars/comandes/all/${this.state.cursEscolarTriat}`);
    if(c.result.success===1){
      const com = c.data.comandes.map((item)=>{
        item.familia_nom_t = entities.decode(item.familia_nom);
        item.alumne_nom_t = entities.decode(item.alumne_nom);
        item.nom_t = entities.decode(item.nom[this.props.t('idioma')]);
        item.horari_t = entities.decode(item.horari[this.props.t('idioma')]);
        return item;
      });
      return com;
    }
  }

  eliminar = async (id) => {
    const del = await Api.myFetch(`/extraescolars/comanda/${id}`, 'DELETE');
    if(del.result.success===1){

    }
  }

  triaCursChange = (curs) => {
    this.creaFiltreClasses(curs);
    const refrescar = this.state.refrescar+1;
    this.setState({
        cursEscolarTriat: curs,
        refrescar: refrescar,
    });
  }

  getColumnSearchProps = (dataIndex, nom) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${this.props.t('generic.cercar')} ${nom}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('generic.cercar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      //record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      record[dataIndex] ? this.arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(this.arreglarTxt4Search(value)) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text => (entities.decode(text)),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

render(){

const { filtresClasses, refrescar, cursEscolarTriat } = this.state;

const columns = [
{
  title:"Id",
  key:"id",
  sorter: (a, b) =>  a.id - b.id,
  render: (text,record) => (<span>{record.id}</span>)
},{
  title: this.props.t('alumnes.classe'),
  key:"classe",
  dataIndex: 'classe',
  filters: filtresClasses,
  onFilter: (value, record) => record.id_classe === value,
  render: (text,record) =>(entities.decode(record.classe)),
  sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
},{
  title: this.props.t('alumnes.nom'),
  key:"alumne_nom_t",
  dataIndex: 'alumne_nom_t',
  sorter: (a, b) => { return a.alumne_nom_t.localeCompare(b.alumne_nom_t)},
  ...this.getColumnSearchProps('alumne_nom_t',this.props.t('alumnes.nom'))
},{
  title:this.props.t('menu.Extraescolars'),
  key:"nom_t",
  dataIndex: 'nom_t',
  sorter: (a, b) => { return a.nom_t.localeCompare(b.nom_t)},
  ...this.getColumnSearchProps('nom_t',this.props.t('menu.Extraescolars'))
},{
  title:this.props.t('extraescolars.horari'),
  key:"horari_t",
  dataIndex: 'horari_t',
  sorter: (a, b) => { return a.horari_t.localeCompare(b.horari_t)},
  ...this.getColumnSearchProps('horari_t',this.props.t('extraescolars.horari'))
},{
  title: this.props.t('generic.familia'),
  key:"familia_nom_t",
  dataIndex: 'familia_nom_t',
  sorter: (a, b) => { return a.familia_nom_t.localeCompare(b.familia_nom_t)},
  ...this.getColumnSearchProps('familia_nom_t',this.props.t('generic.familia'))
},{
  title:this.props.t('generic.total'),
  key:"total",
  sorter: (a, b) =>  a.total - b.total,
  render:(text,record) => (<span>{record.total}€</span>)
},{
  title:this.props.t('generic.created_at'),
  key:"date",
  sorter: (a, b) => moment(a.date).diff(moment(b.date)),
  responsive:['md'],
  render:(text,record) => (record.date ? <Moment date={moment(record.date)} format="DD/MM/YYYY"/> : "" )
}];

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={this.props.t('menu.Comandes')+' '+this.props.t('menu.Extraescolars')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditableModalNouEdit
          scroll={{ x: 1500}}
          txt_creats={this.props.t('menu.Comandes')}
          txt_nou={this.props.t('generic.add')}
          buscarDades={this.buscarDades}
          dadesMostradesCallback={(dadesMostrades) => {
            return(
              <div style={{marginTop: '1rem'}}>
                <ComandesExtraescolarsExportaExcel dades={dadesMostrades} btn_txt="Export"/>
              </div>
            )
          }}
          edit={Edit}
          add={Add}
          delete={this.eliminar}
          itemBuit={InitDades.comandesExtraescolars()}
          columns={columns}
          id_curs_escolar={cursEscolarTriat}
          refrescar={refrescar}
          btn_extra={<TriaCursEscolar onChange={(c) => this.triaCursChange(c)}/>}
          />

      </div>
    </ContainerPagina>
  );
}
}

export default translate(ComandesExtraescolars);
