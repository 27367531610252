import React, { useState, useContext } from 'react';
import { translate } from 'react-multi-lang';

import { SyncOutlined, LeftOutlined } from '@ant-design/icons';
import {Steps, Form, Row, Col, Button, Input, InputNumber, DatePicker, Alert, message } from 'antd';
import moment from 'moment';
import 'moment/locale/ca';
import 'moment/locale/es';

import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import locale_en from 'antd/es/date-picker/locale/en_GB';
import locale_es from 'antd/es/date-picker/locale/es_ES';


import { AllHtmlEntities } from 'html-entities';
import ClassesTraspas from './CursEscolarClassesTraspas';
import QuotesTraspas from './CursEscolarQuotesTraspas';
import BotigaTraspas from './CursEscolarBotigaTraspas';
import ServeisTraspas from './CursEscolarServeisTraspas';


import { Api, InitDades } from '../../../../helpers';
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

const entities = new AllHtmlEntities();

function Edit(props){

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [id] = useState(props.item.id);
  const [fini, setFini ] = useState(props.item.fini ? props.item.fini : moment.utc(new Date()));
  const [ffin, setFfin ] = useState(props.item.ffin ? props.item.ffin : moment.utc(new Date()));
  const [num_mesos, setNum_mesos] = useState(props.item.num_mesos);
  const [nom, setNom] = useState(props.item.nom);
  const [new_id_curs_escolar, setNew_id_curs_escolar] = useState(0);

  const modal = props.modal ? props.modal : false;

  const [enrereVisible, setEnrereVisible] = useState(true);
  const [pas, setPas] = useState(0);


  const [guardant, setGuardant] = useState(false);

  const [errorNum_mesos, setErrorNum_mesos] = useState('');
  const [errorNom, setErrorNom] = useState('');
  const [errorFini, setErrorFini] = useState('');
  const [errorFfin, setErrorFfin] = useState('');

  const num_mesosChange = (value) =>{
    setNum_mesos(value);
    if(Number.isInteger(value)){
      setErrorNum_mesos('');
    }
  }
  const nomChange = (e) =>{
    const n = e.target.value.trim();
    setNom(n);
    if(n.length>0){
      setErrorNom('');
    }
  }
  const changeFini = (date, dateString) => {
    if(date){
      setFini(date.toISOString());
      setErrorFini('');
    }else{
      setFini(null);
    }
  }
  const changeFfin = (date, dateString) => {
    if(date){
      setFfin(date.toISOString());
      setErrorFfin('');
    }else{
      setFfin(null);
    }
  }


  const submitFormCreaCurs = async (values) => {

    //  setPas(2)
//      return false;

      let correcte = true;

      if(!Number.isInteger(num_mesos) || num_mesos<1){
        setErrorNum_mesos('error');
        correcte = false;
      }
      if(nom.length === 0){
        setErrorNom('error');
        correcte = false;
      }
      if(!fini){
        setErrorFini('error');
        correcte = false;
      }
      if(!ffin){
        setErrorFfin('error');
        correcte = false;
      }

      if(new Date(ffin) <= new Date(fini)){
        setErrorFfin('error');
        correcte = false;
      }


      if(!correcte){
        console.log('ERROR submit')
        return false
      }

      setGuardant(true);

      let dades = {
        id,
        num_mesos,
        nom: entities.encode(nom),
        fini: moment.utc(fini),
        ffin: moment.utc(ffin),
        actual: 0
      }
      console.log(JSON.stringify(dades));

      let url_api = '/cursescolar';

      try{
        const resposta = await Api.myFetch(url_api, 'POST', JSON.stringify(dades));

        if(resposta.result.success === 1){
          if(resposta.data.id > 0){
            setNew_id_curs_escolar(resposta.data.id);
            message.success(props.t('generic.OK_saved'), 5);
            //props.callback();
            setPas(1);
            setEnrereVisible(false);
          }else{

            message.error('ERROR Creant curs escolar, id creat 0')
          }

          console.log(resposta);
          setGuardant(false);


        }else{
          setGuardant(false);
          console.error('Error: submit Form BotigaTalles: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: BotigaTalles guardar: ', error);
      }

  }

  let localeDatePicker;
  let alert_message = "Crear un curs nou";
  let alert_description =
    <ul>
      <li>Si en el nou curs escolar <b>s'ha de crear un curs nou</b> ho haurà de fer en l’apartat de Docència corresponent. Haurà de tenir en compte si el nou curs del següent any escolar pertany a un cicle o etapa ja creada; sinó fos així també s’hauria de crear el nou cicle o la nova etapa.</li>
      <li>Sinó es crea un nou curs això implica que els actuals alumnes de l’últim curs actual de l’escola han assolit l’últim curs i per tant <b>abandonaran</b> el centre escolar en el nou Curs Escolar que es crearà</li>
    </ul>;

  switch (props.t('idioma')) {
    case 'ca':
      localeDatePicker = locale_ca;
      break;
    case 'en':
      localeDatePicker = locale_en;
      alert_message = "Create a new grade";
      alert_description =
        <ul>
          <li>If a new grade <b>is to be created</b> in the new school year, it must be done in the Teaching section. It will have to consider if the new grade of the following school year belongs to a cycle or stage already created; otherwise the new cycle or stage should also be created.</li>
          <li>If a new grade is not created, this means that the current students of the last grade have reached the last school year and will therefore <b>leave the school</b> in the new grade that will be created.</li>
        </ul>;
      break;
    case 'es':
      localeDatePicker = locale_es;
      alert_message = "Crear un curso nuevo";
      alert_description =
        <ul>
          <li>Si en el nuevo curso escolar <b>se debe crear un curso</b> nuevo lo tendrá que hacer en el apartado de Docencia correspondiente. Deberá tener en cuenta si el nuevo curso del siguiente año escolar pertenece a un ciclo o etapa ya creada; si no fuera así se debería crear el nuevo ciclo o la nueva etapa.</li>
          <li>Si no se crea un nuevo curso esto implica que los actuales alumnos del último curso actual de la escuela han alcanzado el último curso y por tanto <b>abandonarán</b> el centro escolar en el nuevo Curso Escolar que se creará.</li>
        </ul>;
      break;
    default:
      localeDatePicker = locale_ca;
  }
  const dateFormat = 'DD/MM/YYYY';

  return (
    <>

  {enrereVisible && (
      <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
      <LeftOutlined className="BotoBack" onClick={props.callback}/>
    </div>
  )}
    <div style={{padding:'10px 24px 35px 0px'}}>

    <Steps direction="horizontal" current={pas}>
      <Steps.Step title="Crear curs escolar"/>
      <Steps.Step title="Classes"/>
      <Steps.Step title="Quotes"/>
      <Steps.Step title="Botiga"/>
      <Steps.Step title="Serveis"/>
      <Steps.Step title="Finalitzat"/>
    </Steps>

    {pas === 0 &&(
      <div style={{paddingTop: '20px'}}>

      <Alert
        style={{marginTop: 15, marginBottom: 15}}
        message={alert_message}
        description={alert_description}
        type="info"
        showIcon
      />


        <Form onFinish={submitFormCreaCurs}
          layout="vertical"
          wrapperCol={{span:7}}
          autoComplete="off"
        >
        <Form.Item
          validateStatus={errorNom}
          label={props.t('generic.nom')}
          required={true}
        >
          <Input placeholder={props.t('generic.nom')} onChange={nomChange} defaultValue={nom}/>
        </Form.Item>
          <Form.Item label={props.t('generic.fini')} required={true} validateStatus={errorFini}>
            <DatePicker
              onChange={changeFini}
              value={moment(fini).tz('Europe/Madrid')}
              //showTime={{ format: 'HH:mm' }}
              format={dateFormat}
              locale={localeDatePicker}
              />
          </Form.Item>
          <Form.Item label={props.t('generic.ffin')} required={true} validateStatus={errorFfin}>
            <DatePicker
              onChange={changeFfin}
              value={moment(ffin).tz('Europe/Madrid')}
              //showTime={{ format: 'HH:mm' }}
              format={dateFormat}
              locale={localeDatePicker}
              />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
            <Row type="flex" justify="start">
              <Col>
                <Button onClick={props.callback}>{props.t('generic.back')}</Button>
              </Col>
              <Col style={{marginLeft:'10px'}}>
                <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
              </Col>
            </Row>
          </Form.Item>
          </Form>
      </div>
    )}
    {pas === 1 &&(
      <>
        <ClassesTraspas
          old_id_curs_escolar={perfilUsuari.curs_escolar_actual.id}
          new_id_curs_escolar={new_id_curs_escolar}
          callback_ok={() => setPas(2)}
          callback_ko={null}
        />
      </>
    )}
    {pas === 2 &&(
      <>
        <QuotesTraspas
          old_id_curs_escolar={perfilUsuari.curs_escolar_actual.id}
          new_id_curs_escolar={new_id_curs_escolar}
          callback_ok={() => setPas(3)}
          callback_ko={() => setPas(3)}
        />
      </>
    )}
    {pas === 3 &&(
      <>
        <BotigaTraspas
          old_id_curs_escolar={perfilUsuari.curs_escolar_actual.id}
          new_id_curs_escolar={new_id_curs_escolar}
          callback_ok={() => setPas(4)}
          callback_ko={() => setPas(4)}
        />
      </>
    )}
    {pas === 4 &&(
      <>
        <ServeisTraspas
          old_id_curs_escolar={perfilUsuari.curs_escolar_actual.id}
          new_id_curs_escolar={new_id_curs_escolar}
          callback_ok={() => setPas(5)}
          callback_ko={() => setPas(5)}
        />
      </>
    )}
    </div>
    </>
  );
}
export default translate(Edit);
