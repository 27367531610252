import React, {useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Input, Button, Space, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/Extraescolars';
import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function Extraescolars(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);
  const [refrescar, setRefrescar] = useState(0);

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  const buscarDades = async () => {

    const c = await Api.get('/cursos');
    if(c.result.success!==1){return [];}

    const cursos = c.data.cursos.reduce((acumulat, item) => ({
      ...acumulat,
      [item.id]: item.nom,
    }),{});

    const e = await Api.get(`/extraescolars/curs/${cursEscolarTriat}`);
    if(e.result.success===1){
      const extra = e.data.extraescolars.map((item)=>{
        item.nom_t = entities.decode(item.nom[props.t('idioma')]);
        item.horari_t = entities.decode(item.horari[props.t('idioma')]);

        const tags = item.cursos.map((curs) => {
          return <Tag>{cursos[curs]}</Tag>;
        });
        item.cursos_tags = tags;
        return item;
      });
      return extra;
    }

  }

  const marcarEliminat = async (id) => {

    const fam = await Api.myFetch('/extraescolars/'+id, 'DELETE');
    if(fam.result.success===1){

    }

  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

  const columns = [
    {
      title:"Id",
      key:"id",
      sorter: (a, b) =>  a.id - b.id,
      render: (text,record) => (<span>{record.id}</span>)
    },{
      title: props.t('transport.ordre'),
      key:"ordre",
      sorter: (a, b) =>  a.ordre - b.ordre,
      render: (text,record) => (<span>{record.ordre}</span>)
    },{
      title: props.t('extraescolars.nom'),
      key:"nom_t",
      dataIndex: 'nom_t',
      sorter: (a, b) => { return a.nom_t.localeCompare(b.nom_t)},
      ...getColumnSearchProps('nom_t',props.t('extraescolars.nom'))
    },{
      title: props.t('extraescolars.horari'),
      key:"horari_t",
      dataIndex: 'horari_t',
      sorter: (a, b) => { return a.horari_t.localeCompare(b.horari_t)},
      ...getColumnSearchProps('horari_t',props.t('extraescolars.horari'))
    },{
      title: props.t('generic.preu'),
      key:"preu",
      sorter: (a, b) =>  a.preu - b.preu,
      render:(text,record) => (<span>{record.preu}€</span>)
    },{
      title: props.t('quotes.cursos'),
      ellipsis: true,
      key:"cursos",
      render:(text,record) =>(<span>{record.cursos_tags}</span>)
    }
  ];

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.Extraescolars')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          id_curs_escolar={cursEscolarTriat}
          btn_extra={<TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>}
          txt_creats={props.t('extraescolars.creados')}
          txt_nou={props.t('extraescolars.nuevo')}
          buscarDades={buscarDades}
          refrescar={refrescar}
          edit={Edit}
          delete={marcarEliminat}
          duplica={true}
          itemBuit={InitDades.extraescolars()}
          columns={columns}/>
          {/*<Column
          title="Id"
          key="id"
          sorter={(a, b) =>  (a.id - b.id)}
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />
          <Column
          title={props.t('transport.ordre')}
          key="ordre"
          sorter={(a, b) =>  (a.ordre - b.ordre)}
          render={(text,record) => (
            <span>{record.ordre}</span>
          )}
          />
          <Column
          title={props.t('extraescolars.nom')}
          key="nom_t"
          dataIndex="nom_t"
          sorter={(a, b) =>  (a.nom_t.localeCompare(b.nom_t))}
          />
          <Column
          title={props.t('extraescolars.horari')}
          key="horari"
          render={(text,record) =>(
            entities.decode(record.horari[props.t('idioma')])
          )}
          />


        </LlistaEditable>*/}
      </div>
    </ContainerPagina>
  );

}

export default translate(Extraescolars);
