import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Form, Input, Button, Typography, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { Api } from '../../../../helpers';



function DocumentsAfegir(props){

  const [guardant, setGuardant] = useState(false);
  const [titol, setTitol] = useState();
  const [subtitol, setSubtitol] = useState();
  const [fileList, setFileList] = useState([]);

  const submitForm = async (valors) => {

    let correcte = true;

    if(fileList.length<=0){
      message.config({top: 250});
      message.error({content: 'Error: '+ props.t('documents.tria'), duration:5});
      correcte = false;
    }


    if(!correcte){
      return false;
    }

    setGuardant(true);

    var formData = new FormData();
    formData.append("id_usuari", props.id_usuari);
    formData.append("id_carpeta", props.id_carpeta);
    formData.append("titol", titol ? titol : '');
    formData.append("subtitol", subtitol ? subtitol : '');
    formData.append("fitxer", fileList[0]);

    try{
      const resposta = await Api.enviarFormulariFitxer('/documents/fitxer', formData);
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: guardant DocumentsAfegir: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: DocumentsAfegir guardar: ', error);
    }

  }


  const uploadConfig = {
        onRemove: file => {
          setFileList([]);
        },
        beforeUpload: file => {
          setFileList([file]);
          return false;
        },
        fileList,
      };


  return(
    <>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={3}>{props.t('documents.add_document')}</Typography.Title>
    </div>
    <Form onFinish={submitForm}
        layout="vertical"
        colon={true}
        autoComplete="new-password"
      >
        <Form.Item label={props.t('documents.label_titol')}>
          <Input placeholder={props.t('documents.label_titol')} onChange={(e) => setTitol(e.target.value)} value={titol} autoComplete="çlayskdfjçalkdfjç"/>
        </Form.Item>
        <Form.Item label={props.t('documents.label_subtitol')}>
          <Input placeholder={props.t('documents.label_subtitol')} onChange={(e) => setSubtitol(e.target.value)} value={subtitol} autoComplete="ñlasbkdfjñalkdfj"/>
        </Form.Item>
        <Upload {...uploadConfig}>
          <Button icon={<UploadOutlined />}>{props.t('documents.tria')}</Button>
        </Upload>
        <Row type="flex" justify="center" style={{marginTop: 25, marginBottom: 25}}>
          <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
        </Row>
      </Form>
    </>
  );
}


export default translate(DocumentsAfegir);
