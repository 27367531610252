import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/ca';

import { translate } from 'react-multi-lang';
import { Switch, Modal, Table, Col, Tag, Row, Button, Alert, Form, Select, Tooltip, Popconfirm, Input, Space, message } from 'antd';
import { EuroCircleOutlined, CheckOutlined, SyncOutlined, EditOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../../components/TitolPagina';
//import TriarAlumnes from '../../../components/Formularis/perfils/admin/QuotesAlumnesTriar';
import TriarAlumnes from '../../../components/Formularis/perfils/admin/QuotesTriarAlumnes';
import Edit from '../../../components/Formularis/perfils/admin/QuotesPreus';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';

function QuotesAlumnesLlista(props) {

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const tipus_quota = props.tipus_quota;
  const [llista, setLlista] = useState([]);

  const [selectQuotes, setSelectQuotes] = useState([]);
  const [quotaTriada, setQuotaTriada] = useState(0);
  const [alumnesTriats, setAlumnesTriats] = useState([]);
  const [llistaTotsAlumnes, setLlistaTotsAlumnes] = useState([]);
  const [filtresClasses, setFiltresClasses] = useState([]);
  const [filtresQuotes, setFiltresQuotes] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSelect, setIsLoadingSelect] = useState(true);
  const [guardant, setGuardant] = useState(false);
  const [mostrarTriarAlumnes, setMostrarTriarAlumnes] = useState(false);
  const [mostratTotsAlumnes, setMostrarTotsAlumnes] = useState(false);
  const [editIndividual, setEditIndividual] = useState(false);

  const [errorQuotaTriada, setErrorQuotaTriada] = useState('');
  const [errorQuotaTriadaText, setErrorQuotaTriadaText] = useState('');

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);


  const buscarDades = async () => {
    setIsLoadingSelect(true);


    //const cla = await Api.get('/classes');
    const cla = await Api.get(`/classes/all/${id_curs_escolar}`);
    if(cla.result.success===1){
      const filtreCl = cla.data.classes.map((item)=>{
        return {text: item.nom, value: item.id}
      });
      setFiltresClasses(filtreCl);
    }


    let cursos = [];
    const c = await Api.get('/cursos');
    if(c.result.success===1){
      cursos = c.data.cursos.reduce((acumulat, item) => ({
        ...acumulat,
        [item.id]: item.nom,
      }),{});
    }


    const qq = await Api.get(`/quotes/${tipus_quota.id}/${id_curs_escolar}`);
    if(qq.result.success===1){
      let filtre_quotes = [];
      const llista_qq = qq.data.quotes.map((item) =>{
            filtre_quotes.push({text: entities.decode(item.text[props.t('idioma')]), value: item.id});
            const tags = item.cursos.map((curs) => {
              return <Tag key={curs}>{cursos[curs]}</Tag>;
            });
            return(
              <Select.Option key={item.id} value={item.id}>
              <Row justify="space-between">
                <Col>
                {entities.decode(item.text[props.t('idioma')])}&nbsp;&nbsp;&nbsp;
                {tags}
                </Col>
                <Col><b>{item.preu+'€'+ ' '}</b></Col>
              </Row>
              </Select.Option>
            );
      });
      setFiltresQuotes(filtre_quotes);
      setSelectQuotes(llista_qq);
    }
    setIsLoadingSelect(false);
  }

  const buscarDadesAlumnes = async () => {
    setIsLoading(true);

    let quotes_alumnes = [];
    const q = await Api.get(`/quotes/alumnes/tipus/${tipus_quota.id}/${id_curs_escolar}`);
    if(q.result.success===1){
      console.log(`/quotes/alumnes/tipus/${tipus_quota.id}/${id_curs_escolar}`, q.data.quotes_alumnes)
      setLlista(q.data.quotes_alumnes);
      quotes_alumnes = q.data.quotes_alumnes.reduce((acumulat, item) => ({
        ...acumulat,
        [item.id_alumne]: {id_quota: item.id_quota, quota_text: item.quota_text, quota_preu: item.quota_preu, id_eliminar: item.id},
      }),{});

    }


    let quotes_default = [];
    const qd = await Api.get(`/quotes/default/tipus/${tipus_quota.id}/${id_curs_escolar}`);
    if(qd.result.success===1){
      console.log(`/quotes/default/tipus/${tipus_quota.id}/${id_curs_escolar}`, qd.data.quotes_default)
      quotes_default = qd.data.quotes_default.reduce((acumulat, item) => ({
        ...acumulat,
        [item.id_curs]: {id_quota: item.id_quota, quota_text: item.quota_text, quota_preu: item.quota_preu},
      }),{});

      console.log('QuotesDefault: ', quotes_default)
    }


    //const alum = await Api.get('/alumnes');
    const alum = await Api.get(`/alumnes/gestio/quotes/${id_curs_escolar}`);
    if(alum.result.success===1){
      console.log(`/alumnes/gestio/quotes/${id_curs_escolar}`, alum.data.alumnes)
      let tots_amb_quota = [];
      alum.data.alumnes.map((item)=>{
        try {
        if(item.id_classe > 0){
          item.id_alumne = item.id;
          if(quotes_alumnes[item.id]!==undefined){
            item.quota_especifica = true;
            item.id_eliminar = quotes_alumnes[item.id].id_eliminar;
            item.id_quota = quotes_alumnes[item.id].id_quota;
            item.quota_text = quotes_alumnes[item.id].quota_text;
            item.quota_preu = quotes_alumnes[item.id].quota_preu;
            tots_amb_quota.push(item);

          }else{
            if(quotes_default[item.id_curs]!==undefined && quotes_default[item.id_curs].id_quota !== 0){
              item.quota_especifica = false;
              item.id_eliminar = 0;
              item.id_quota = quotes_default[item.id_curs].id_quota;
              item.quota_text = quotes_default[item.id_curs].quota_text;
              item.quota_preu = quotes_default[item.id_curs].quota_preu;
              tots_amb_quota.push(item);
            }
          }

        }

      } catch (e) {

      //console.log(e, item)

      }
      })

      console.log('setLlistaTotsAlumnes(tots_amb_quota)', tots_amb_quota)
      setLlistaTotsAlumnes(tots_amb_quota)
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDadesAlumnes();
    buscarDades();
  },[]);


  const marcarEliminat = async (id) => {
    const q = await Api.myFetch('/quotes/alumnes/'+id, 'DELETE');
    if(q.result.success===1){

    }
  }

  const showAdd = () =>{
    setAlumnesTriats([]);
    setMostrarTriarAlumnes(true);
  }

  const tancarTriaAlumnes = () =>{
    setMostrarTriarAlumnes(false);
    setEditIndividual(false);
  }

  const triaQuota = (value) =>{
    setQuotaTriada(value);
    if(value>0){
      setErrorQuotaTriada('');
      setErrorQuotaTriadaText('');
    }
  }

  const showEdit = (id) =>{
    setAlumnesTriats([id]);
    setEditIndividual(true);
    setMostrarTriarAlumnes(true);

  }
  const submitDelete = async (item) => {

    let id_eliminar = item.id;
    if(mostratTotsAlumnes) {
      id_eliminar = item.id_eliminar;
    }

    const del = await Api.myFetch(`/quotes/alumnes/${id_eliminar}`, 'DELETE');
    await buscarDadesAlumnes();

  }


const submitForm = async (values) => {
  let correcte = true;

  if(quotaTriada===0){
    setErrorQuotaTriada('error');
    setErrorQuotaTriadaText(props.t('generic.tria'));
    correcte = false;
  }
  if(alumnesTriats.length <=0){

    message.error(props.t('quotes.triaAlumnes'));
    correcte = false;
  }


  if(!correcte){
    return false;
  }

  setGuardant(true);

  let dades = {
    id_curs_escolar,
    id_tipus_quota: tipus_quota.id,
    id_quota: quotaTriada,
    alumnes: alumnesTriats
  }

  console.log(JSON.stringify(dades));
  //setGuardant(false);
  //return false;
  try{
    const resposta = await Api.myFetch('/quotes/alumnes', 'POST', JSON.stringify(dades));

    if(resposta.result.success === 1){
      setGuardant(false);
      console.log('resposta', resposta);
      message.success(props.t('generic.OK_saved'), 5);
      setMostrarTriarAlumnes(false);
      setAlumnesTriats([]);
      setEditIndividual(false);
      await buscarDadesAlumnes();

    }else{
      setGuardant(false);
      console.error('Error: submit Form QuotesAlumnesLlista: ',resposta);
      message.config({top: 50});
      message.open({content: resposta.data.message, duration:10});
    }
  }catch(error){
    setGuardant(false);
    message.config({top: 50});
    message.open({content: "Error saving", duration:10});
    console.error('Error: QuotesAlumnesLlista guardar: ', error);
  }
}


let itemBuit = InitDades.quota();
itemBuit.id_tipus_quota = tipus_quota.id;
itemBuit.tipus_quota = tipus_quota;

//CERCA
  const getColumnSearchProps = (dataIndex, nom) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            let search = searchInput;
            search[dataIndex] = node;
            setSearchInput(search);
          }}
          placeholder={`${props.t('generic.cercar')} ${nom}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          autoFocus="focus"
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {props.t('generic.cercar')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput[dataIndex].select(),100);
      }
    },
   render: text => (entities.decode(text)),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  const arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
      //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
      return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }
//FI CERCA

const columnes = [
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    render: (text,record) => (<span>{record.id}</span>)
  },{
    title:props.t('alumnes.nom'),
    key:"nom",
    dataIndex: 'nom',
    render:(text,record) =>(entities.decode(record.nom)),
    sorter: (a, b) => { return entities.decode(a.nom).localeCompare(entities.decode(b.nom))},
    ...getColumnSearchProps('nom',props.t('alumnes.nom'))
  },{
    title: props.t('alumnes.cognom1'),
    key: "cognom1",
    dataIndex: 'cognom1',
    render: (text,record) =>(entities.decode(record.cognom1)),
    sorter: (a, b) => { return entities.decode(a.cognom1).localeCompare(entities.decode(b.cognom1))},
    ...getColumnSearchProps('cognom1',props.t('alumnes.cognom1'))
  },{
    title: props.t('alumnes.cognom2'),
    key: "cognom2",
    dataIndex: 'cognom2',
    render: (text,record) =>(entities.decode(record.cognom2)),
    sorter: (a, b) => { return entities.decode(a.cognom2).localeCompare(entities.decode(b.cognom2))},
    ...getColumnSearchProps('cognom2',props.t('alumnes.cognom2'))
  },{
    title: "Classe",
    ellipsis: true,
    key: "classe",
    render: (text, record) =>(<span>{record.classe}</span>),
    filters: filtresClasses,
    onFilter: (value, record) => (record.id_classe === value)
  },{
    title: "Quota",
    ellipsis: true,
    key: "quota_text",
    render: (text,record) => (<span>{entities.decode(record.quota_text[props.idioma])}</span>),
    sorter: (a, b) => { return entities.decode(a.quota_text[props.t('idioma')]).localeCompare(entities.decode(b.quota_text[props.t('idioma')]))},
    filters: filtresQuotes,
    onFilter: (value, record) => (record.id_quota === value)
  },{
    title: props.t('quotes.import'),
    key: "preu",
    sorter: (a, b) =>  a.quota_preu - b.quota_preu,
    render: (text,record) =>(<span>{record.quota_preu}€</span>)
  },{
    key: "actions",
    align: "right",
    width: "95px",
    render: (text,record) => (
      <Row type="flex" justify="end" id={"actions_"+record.id}>
        <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.modify')}>
            <Button icon={<EditOutlined />} onClick={(e) => showEdit(record.id_alumne)} ghost="true" className="BotoTaula"/>
          </Tooltip>
        </Col>

        {(mostratTotsAlumnes && record.quota_especifica===false) ? null :
          <Col span={12}>
          <Tooltip placement="top" title={props.t('generic.delete')}>
            <Popconfirm placement="bottom" title={props.t('generic.confirm_delete')} okText="Si" cancelText="No" onConfirm={()=>submitDelete(record)}>
              <Button icon={<DeleteOutlined />} ghost="true" className="BotoTaula"/>
            </Popconfirm>
          </Tooltip>
        </Col>
      }
      </Row>
    )
  }

]

return(
    <>
      <TitolPagina txt={entities.decode(tipus_quota.nom[props.idioma])} level={4} style={{marginTop:'1rem', marginLeft:'0.5rem', paddingLeft:'0.5rem'}}/>
      <div style={{marginTop:'1rem', marginLeft:'0.5rem'}}>
        <div style={{textAlign:'right', marginBottom:'25px'}}>
          <Row type="flex" justify="space-between">
            <Col>
              <div>
                <EuroCircleOutlined style={{color:'#C2C2C2'}}/>
                <span style={{color:'#C2C2C2'}}>&nbsp;{typeof llista !== "undefined" ? llista.length : 0}&nbsp;{props.t('quotes.creados')}</span>
              </div>
            </Col>
            <Col>
              {props.t('quotes.mostratTotsAlumnes')}: <Switch onChange={setMostrarTotsAlumnes} />
            </Col>
            <Col>
              <Button type="primary" onClick={showAdd}>{props.t('quotes.assignarVarisAlumnes')}</Button>
            </Col>
          </Row>
        </div>
        <Table
          locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
          dataSource={mostratTotsAlumnes ? llistaTotsAlumnes : llista}
          pagination={false}
          rowKey="id"
          size="small"
          loading={isLoading}
          columns={columnes}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {}, //onClick: (e) => showEdit(record), // click row
              onDoubleClick: (e) => showEdit(record.id_alumne), // double click row
              onContextMenu: (e) => {e.preventDefault(); showEdit(record.id_alumne);}, //event => {}, // right button click row
            };
          }}
        />
      </div>

      <Modal
      key="triar_alumnes"
      visible={mostrarTriarAlumnes}
      footer={null}
      width={1000}
      style={{top:10}}
      bodyStyle={{ overflow: "auto"}}
      destroyOnClose={true}
      forceRender={true}
      maskClosable={false}
      closable={true}
      onCancel={tancarTriaAlumnes}
      >
      {editIndividual
        ?
        <TitolPagina txt={entities.decode(tipus_quota.nom[props.idioma])} level={4} style={{marginTop:'1rem', marginLeft:'0.5rem', paddingLeft:'0.5rem'}}/>
        :
        <Alert
          style={{margin: '1rem'}}
          message={entities.decode(tipus_quota.nom[props.idioma])}
          description={
            <ul>
            <li>{props.t('quotes.alumnesLlista1')}</li>
            <li>{props.t('quotes.alumnesLlista2')}</li>
            </ul>
          }
          type="info"
          showIcon
        />
      }
        <Form onFinish={submitForm}
          layout="horizontal"
          labelCol={{span:2}}
          wrapperCol={{span:22}}
          colon={true}
          autoComplete="off"
        >
          <Form.Item label={'Quota'} required={true} validateStatus={errorQuotaTriada} help={errorQuotaTriadaText}>
            <Select defaultValue={quotaTriada===0 ? '' : quotaTriada} onChange={(value) => triaQuota(value)} loading={isLoadingSelect}>
              {selectQuotes}
            </Select>
          </Form.Item>
          <Col span={24}>
              <Row type="flex" justify="start" style={{marginTop: 25, marginBottom: 25}}>
                <Col>
                  <Button onClick={tancarTriaAlumnes}>{props.t('generic.cancel')}</Button>
                </Col>
                <Col style={{marginLeft:'10px'}}>
                  <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
                </Col>
              </Row>
          </Col>
          </Form>

          {editIndividual ? null :
            <TriarAlumnes setTriats={setAlumnesTriats} llistaAlumnes={llistaTotsAlumnes} filtresQuotes={filtresQuotes} id_curs_escolar={id_curs_escolar}/>
          }

      </Modal>
    </>
  );

}

export default translate(QuotesAlumnesLlista);
