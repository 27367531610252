import React from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../components/ContainerPagina';
import TitolPagina from '../../components/TitolPagina';
import LlistaEditable from '../../components/LlistaEditable';
import Edit from '../../components/Formularis/Talles';
import { Api, InitDades } from '../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Talles(props) {

  const buscarDades = async () => {
    const tmp = await Api.get('/botiga/talles');
    if(tmp.result.success===1){
      return tmp.data.talles;
    }
  }
  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/botiga/talles/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  const modal = props.modal ? props.modal : false;

  return(
    <ContainerPagina xl={modal ? 24 : 16}>
      <TitolPagina txt={props.t('menu.BotigaTalles')} style={modal ? {marginTop:'0rem', marginLeft:'1rem'} : {marginTop:'1rem', marginLeft:'3rem'}}/>
      <div style={modal ? {marginTop:'0rem', marginLeft:'1rem'} : {marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          modal={modal}
          txt_creats={props.t('botiga_propietats.creados')}
          txt_nou={props.t('botiga_propietats.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          duplica={true}
          itemBuit={InitDades.botigaTalles()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />
          <Column
          title={props.t('botiga_propietats.ordre')}
          key="ordre"
          render={(text,record) =>(
            <span>{record.ordre}</span>
          )}
          />
          <Column
          title={props.t('botiga_propietats.text')+' CA'}
          key="text_ca"
          render={(text,record) =>(
            entities.decode(record.text['ca'])
          )}
          />
          <Column
          title={props.t('botiga_propietats.text')+' EN'}
          key="text_en"
          render={(text,record) =>(
            entities.decode(record.text['en'])
          )}
          />
          <Column
          title={props.t('botiga_propietats.text')+' ES'}
          key="text_es"
          render={(text,record) =>(
            entities.decode(record.text['es'])
          )}
          />
        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Talles);
