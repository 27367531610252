import React from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/Cursos';
import { Api, InitDades } from '../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

function Cursos(props) {


  const buscarDades = async () => {
    console.log('Cursos itemBuit: ', InitDades.curs());
    const cur = await Api.get('/cursos');
    if(cur.result.success!==1){return [];}

    const ci = await Api.get('/cicles');
    if(ci.result.success!==1){return [];}

    let llista_ci = [];
    llista_ci[0] = "";
    ci.data.cicles.forEach((item) =>{
        llista_ci[item.id] = item.nom;
    });
    const llista_cursos = cur.data.cursos.map((item) =>{
      item.cicle = llista_ci[item.id_cicle];
      return item;
    });

    return llista_cursos;

  }

  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/cursos/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  return(
    <ContainerPagina>
      <TitolPagina txt={props.t('menu.Cursos')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          txt_creats={props.t('cursos.creados')}
          txt_nou={props.t('cursos.nuevo')}
          buscarDades={buscarDades}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.curs()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />

          <Column
          title={props.t('cursos.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom)
          )}
          />
          <Column
          title={props.t('cursos.cicle')}
          key="cicle"
          render={(text,record) =>(
            record.cicle
          )}
          />


        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Cursos);
