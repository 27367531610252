import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function RebutsExportaExcel(props){

  const [dades, setDades] = useState([]);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Rebuts';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'Rebuts';

  const [isLoading, setIsLoading] = useState(true);

  const buscarDades = async () => {
    setIsLoading(true);
    const alum = await Api.get('/enquesta/respostes/llistat');
    if(alum.result.success===1){
      console.log(alum.data.enquestes)
      setDades(alum.data.enquestes);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);

  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  }

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="id" value="id"/>
            <ExcelColumn label="id_familia" value="id_familia"/>
            <ExcelColumn label="Familia" value={(col) => entities.decode(col.familia)}/>
            <ExcelColumn label="id_alumne" value="id_alumne"/>
            <ExcelColumn label="Alumne" value={(col) => entities.decode(col.alumne)}/>
            <ExcelColumn label="Curs Actual" value={(col) => entities.decode(col.curs_actual)}/>
            <ExcelColumn label="Curs Futur" value={(col) => entities.decode(col.curs_futur)}/>

<ExcelColumn label=" " value=" "/>

            <ExcelColumn label="Cross running" value={(col) => col.respostes.indexOf("cros") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Tennis / Padel" value={(col) => col.respostes.indexOf("tennis_padel") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Football" value={(col) => col.respostes.indexOf("futbol") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Basketball" value={(col) => col.respostes.indexOf("basquet") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Roller skating" value={(col) => col.respostes.indexOf("patinatge") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Yoga" value={(col) => col.respostes.indexOf("yoga") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Multisport" value={(col) => col.respostes.indexOf("multiesport") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Dance" value={(col) => col.respostes.indexOf("dance") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Swimming" value={(col) => col.respostes.indexOf("swimming") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Golf" value={(col) => col.respostes.indexOf("golf") > -1 ? 1 : 0 }/>

<ExcelColumn label=" " value=" "/>

            <ExcelColumn label="Robotics" value={(col) => col.respostes.indexOf("robotica") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Chess" value={(col) => col.respostes.indexOf("chess") > -1 ? 1 : 0 }/>

<ExcelColumn label=" " value=" "/>

            <ExcelColumn label="The Band" value={(col) => col.respostes.indexOf("the_band") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Musical Theater" value={(col) => col.respostes.indexOf("musical_theater") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Piano" value={(col) => col.respostes.indexOf("piano") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Let's get started" value={(col) => col.respostes.indexOf("lets_get_started") > -1 ? 1 : 0 }/>

<ExcelColumn label=" " value=" "/>

            <ExcelColumn label="German" value={(col) => col.respostes.indexOf("german") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Spanish" value={(col) => col.respostes.indexOf("castella") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Catalan" value={(col) => col.respostes.indexOf("catala") > -1 ? 1 : 0 }/>

<ExcelColumn label=" " value=" "/>

            <ExcelColumn label="Extraescolars sugerides" value={(col) => entities.decode(col.altres)}/>

<ExcelColumn label=" " value=" "/>

            <ExcelColumn label="Bus Tarragona" value={(col) => col.respostes.indexOf("linia_tarragona") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Bus Reus" value={(col) => col.respostes.indexOf("linia_reus") > -1 ? 1 : 0 }/>
            <ExcelColumn label="Bus Zona nord" value={(col) => col.respostes.indexOf("linia_zona_nord") > -1 ? 1 : 0 }/>

<ExcelColumn label=" " value=" "/>

            <ExcelColumn label="Parada sugerida" value={(col) => entities.decode(col.parada_sugerida)}/>

          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(RebutsExportaExcel);
