import React, {useState, useEffect} from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Card, Table, Button, Tooltip, List } from 'antd';
import { SyncOutlined, CheckOutlined, AppstoreOutlined, TableOutlined} from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../../components/TitolPagina';

import { Api } from '../../../helpers';

const entities = new AllHtmlEntities();

function MevesCompres(props) {

  const [ comprat, setComprat ] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mostrarTaula, setMostrarTaula ] = useState(true);
  const [refrescar, setRefrescar ] = useState(0);

  useEffect(() => {
    buscarDades();
  },[props.id_alumne, refrescar]);


  const buscarDades = async () =>{
    setIsLoading(true);
    const prod = await Api.get(`/botiga/comprat/${props.id_alumne}`);    
    if(prod.result.success===1){
      const com = prod.data.comprat.map((item)=>{
        const d = new Date(item.date);
        item.data_hora = d.toLocaleDateString()+' '+d.toLocaleTimeString();
        return item;
      });
      setComprat(com);
    }
    setIsLoading(false);
  }

  const columns = [
    {
      title: props.t('botiga.data'),
      dataIndex: 'data_hora',
      key: 'data_hora',
    },
    {
      title: props.t('botiga.titolEstat'),
      dataIndex: 'estat',
      render: (text,record) =>(props.t(`botiga.estat.${record.estat}`)),
      key: 'estat',
    },
    {
      title: props.t('botiga.producte'),
      dataIndex: 'producte',
      render: (text,record) =>(entities.decode(record.producte[props.t('idioma')])),
      key: 'address',
    },
    {
      title: props.t('botiga_propietats.titol'),
      dataIndex: 'talla',
      render: (text,record) => (entities.decode(record.talla[props.t('idioma')])),
      key: 'talla',
    },
    {
      title: props.t('botiga.preu'),
      dataIndex: 'preu_unitat',
      render: (text,record)=>(record.preu_unitat+'€'),
      key: 'preu_unitat',
    },
    {
      title: props.t('botiga.unitats'),
      dataIndex: 'unitats',
      key: 'unitats',
    },
    {
      title: props.t('botiga.descompte'),
      dataIndex: 'descompte',
      render: (text,record)=>(record.descompte+'%'),
      key: 'descompte',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (text,record)=>(record.total+'€'),
      key: 'total',
    },
    {
      title: props.t('botiga.comprador'),
      dataIndex: 'nom_pare',
      render: (text,record) =>(entities.decode(record.nom_pare)),
      key: 'nom_pare',
    },
  ];
  return(
    <>
    <Row justify="end">
      <Tooltip title="Cards">
        <Button type="text" icon={<AppstoreOutlined style={{fontSize:'1.5rem'}}/>} style={{marginRight:'0.5rem'}} onClick={()=>setMostrarTaula(false)}/>
      </Tooltip>
      <Tooltip title="Table">
        <Button type="text" shape="circle" icon={<TableOutlined style={{fontSize:'1.5rem'}}/>} style={{marginRight:'0.5rem'}} onClick={()=>setMostrarTaula(true)}/>
      </Tooltip>
      <Tooltip title="Refresh">
        <Button type="text" shape="circle" icon={<SyncOutlined style={{fontSize:'1.5rem'}}/>} onClick={()=>setRefrescar(refrescar+1)}/>
      </Tooltip>
    </Row>


      {mostrarTaula===true ?
        <Table size="small" dataSource={comprat} columns={columns} loading={isLoading}/>
      :
        <List
          loading={isLoading}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 4,
            xxl: 4,
          }}
          dataSource={comprat}
          renderItem={item => (
            <List.Item id={item.id_comanda}>
              <Card title={<Row justify="space-between">
                            <Col>{props.t(`botiga.estat.${item.estat}`)}</Col>
                            <Col><span style={{fontSize:'0.8rem', fontWeight:'nomal'}}>{item.data_hora}</span></Col>
                          </Row>}>
              <p>{props.t('botiga.producte')}: {entities.decode(item.producte[props.t('idioma')])}</p>
              <p>{props.t('botiga_propietats.talla')}: {entities.decode(item.talla[props.t('idioma')])}</p>
              <p>{props.t('botiga.preu')}: {item.preu_unitat}€</p>
              <p>{props.t('botiga.unitats')}: {item.unitats}</p>
              {item.descompte<=0 ? null :
                <p>{props.t('botiga.descompte')}: {item.descompte}</p>
              }
              <p>Total: <b>{item.total}€</b></p>
              <p>{props.t('botiga.comprador')}: {entities.decode(item.nom_pare)}</p>
              </Card>
            </List.Item>
          )}
        />
      }
    </>
  );

}

export default translate(MevesCompres);
