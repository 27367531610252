import React, {useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table, Tag, Input, Space, Button } from 'antd';
import { DollarCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditableModalNouEdit';
import Edit from '../../../components/Formularis/perfils/admin/FacturacioImportsRecurrentsEdit';
import { Api, InitDades } from '../../../helpers';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function FacturacioImportsRecurrents(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);
  const [refrescar, setRefrescar] = useState(0);

  //CERCA
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [searchInput, setSearchInput] = useState([]);
  //FI CERCA

  const buscarDades = async () => {
    const r = await Api.get(`/pressupost/recurrents/${cursEscolarTriat}`);
    if(r.result.success!==1){return [];}

    const recurrents = r.data.recurrents.map((item)=>{

      item.alumne_nom = entities.decode(item.nom)+' '+entities.decode(item.cognom1)+' '+entities.decode(item.cognom2);
      const tags = item.mesos_facturacio.map((mes) => {
        return <Tag>{props.t(`mesos.${mes}`)}</Tag>;
      });
      item.mesos_facturacio_tags = tags;
      return item;
    })

    return recurrents;
  }

  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/pressupost/recurrents/'+id, 'DELETE');
    if(fam.result.success===1){

    }

  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }

  //CERCA
    const getColumnSearchProps = (dataIndex, nom) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              let search = searchInput;
              search[dataIndex] = node;
              setSearchInput(search);
            }}
            placeholder={`${props.t('generic.cercar')} ${nom}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
            autoFocus="focus"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              {props.t('generic.cercar')}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(arreglarTxt4Search(value)) : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput[dataIndex].select(),100);
        }
      },
     render: text => (entities.decode(text)),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText('');
    };

    const arreglarTxt4Search = text =>{
        // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        //return text.trim().split(' ')[0].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
    }
  //FI CERCA

  let tipus_temp = InitDades.tipus_ingressos_llista();
  tipus_temp.sort();
  const filtreTipusIngres = tipus_temp.map((item)=>{
    return {text: <span>{props.t(`rebuts.tipus.${item}`)}</span>, value: item}
  })

  const filtreMesos = [
    { text: props.t('mesos.1'), value: 1 },
    { text: props.t('mesos.2'), value: 2 },
    { text: props.t('mesos.3'), value: 3 },
    { text: props.t('mesos.4'), value: 4 },
    { text: props.t('mesos.5'), value: 5 },
    { text: props.t('mesos.6'), value: 6 },
    { text: props.t('mesos.7'), value: 7 },
    { text: props.t('mesos.8'), value: 8 },
    { text: props.t('mesos.9'), value: 9 },
    { text: props.t('mesos.10'), value: 10 },
    { text: props.t('mesos.11'), value: 11 },
    { text: props.t('mesos.12'), value: 12 },
  ];


  const columns = [
  {
    title:"Id",
    key:"id",
    sorter: (a, b) =>  a.id - b.id,
    render: (text,record) => (<span>{record.id}</span>),
    width: 10
  },{
    title: props.t('generic.alumne'),
    key:"alumne_nom",
    dataIndex: 'alumne_nom',
    sorter: (a, b) => { return a.alumne_nom.localeCompare(b.alumne_nom)},
    ...getColumnSearchProps('alumne_nom',props.t('generic.alumne'))
  },{
    title:props.t('generic.tipus_ingres'),
    key:"tipus",
    dataIndex:"tipus",
    filters: filtreTipusIngres,
    onFilter: (value, record) => record.tipus === value,
    render: (text,record) => (<span>{props.t(`rebuts.tipus.${record.tipus}`)}</span>)
  },{
    title: props.t('generic.concepte'),
    key:"concepte",
    dataIndex: 'concepte',
    sorter: (a, b) => { return a.concepte.localeCompare(b.concepte)},
    ...getColumnSearchProps('concepte',props.t('generic.concepte'))
  },{
    title: "Mesos",
    ellipsis: true,
    key:"mesos_facturacio",
    dataIndex:"mesos_facturacio",
    filters: filtreMesos,
    onFilter: (value, record) => record.mesos_facturacio.includes(value),
    render:(text,record) =>(<span>{record.mesos_facturacio_tags}</span>)
  },{
    title:props.t('generic.total'),
    key:"subtotal",
    dataIndex: 'subtotal',
    render: (text,record) => (<span>{record.subtotal}€</span>),
    sorter: (a, b) =>  a.subtotal - b.subtotal
  }];

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.ImportsRecurrents')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          modal_width={1100}
          id_curs_escolar={cursEscolarTriat}
          btn_extra={<TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>}
          txt_creats={props.t('transport.creados')}
          icon_creats={DollarCircleOutlined}
          txt_nou={props.t('generic.add')}
          buscarDades={buscarDades}
          refrescar={refrescar}
          edit={Edit}
          add={Edit}
          duplica={true}
          delete={marcarEliminat}
          itemBuit={InitDades.imports_recurrents()}
          columns={columns}
          />
      </div>
    </ContainerPagina>
  );

}

export default translate(FacturacioImportsRecurrents);
