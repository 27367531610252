import React, { useContext, useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Button, List, Card, Select, Form, Table, Checkbox, Input, Space, message } from 'antd';
import { AlertFilled } from '@ant-design/icons';
import moment from 'moment';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';
import { AllHtmlEntities } from 'html-entities';
import { MenuTriatContext } from "../../../../context/menu-triat";
import { PerfilUsuariContext } from "../../../../context/perfil-usuari";
import ContainerPagina from '../../../ContainerPagina';
import TitolPagina from '../../../TitolPagina';
import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();
moment.locale('es');

function PassarLlista(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [nou, setNou] = useState(true);
  const [id, setId] = useState(0);
  const [dataRollCall, setDataRollCall] = useState(null);
  const [tipus, setTipus] = useState(props.tipus ? props.tipus : 'classe');
  const [id_taula, setId_taula] = useState(props.id_taula);
  const [alumnes, setAlumnes] = useState([]);
  const [optionsClasses, setOptionsClasses] = useState([]);
  const [optionsMotiusAbsencia, setOptionsMotiusAbsencia] = useState([]);
  //const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [checkAll, setCheckAll] = useState(true);
  const [canvisPendents, setCanvisPendents] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const modal = props.modal ? props.modal : false;
  const id_profe = props.id_profe ? props.id_profe : perfilUsuari.account.id_profe;

  const [dateEnCurs, setDateEnCurs] = useState(props.dataEnCurs ? moment(props.dataEnCurs) : moment(new Date()));

  useEffect(() => {
    setTipus(props.tipus);
    setId_taula(props.id_taula);
    //console.log('porps dinsPassarLlista',props.tipus, props.id_taula)
  },[props.tipus, props.id_taula]);

  useEffect(() => {
    setDateEnCurs(props.dataEnCurs ? moment(props.dataEnCurs) : moment(new Date()));
  },[props.dataEnCurs]);

  useEffect(() => {
    buscarMotiusAbsencia();
  },[]);



  useEffect(() => {
    buscarRollCall();
    //console.log('dinsPassarLlista',tipus, id_taula)
  },[tipus, id_taula]);

  const buscarRollCall = async () =>{
    setIsLoading(true);
    if(id_taula > 0) {
      const q = await Api.get(`/rollcall/${id_profe}/${tipus}/${id_taula}/${dateEnCurs.format('YYYY-MM-DD')}`);
      if(q.result.success===1 && q.data != null){
        setId(q.data.id);
        setDataRollCall(q.data.updated_at ? q.data.updated_at : q.data.created_at);
        setNou(false);
        const alum = q.data.alumnes.map((item)=>{
          item.modificat = false;
          return item;
        })
        setAlumnes(alum);
      }else{
        buscarAlumnes();
      }
    }
    setIsLoading(false);
  }


  const buscarAlumnes = async () =>{
    if(id_taula > 0) {
      const q = await Api.get(`/profe/alumnes/${tipus}/${id_taula}`);
      if(q.result.success===1 && q.data.alumnes){
        setNou(true);
        setId(0);

        let absencies_alumnes =[];
        const abs = await Api.get('/notificacions/absencia/avui');
        if(abs.result.success===1 && abs.data.notificacions_absencia){

          abs.data.notificacions_absencia.map(item=>{
            absencies_alumnes[item.id_alumne] = item;
          })

        }

        const nouRollCall = q.data.alumnes.map((item)=>{

          let alumne = {
            id: `NEW_${item.id}`,
            id_rollcall: 0,
            id_alumne: item.id,
            nom: item.nom,
            cognom1: item.cognom1,
            cognom2: item.cognom2 ? item.cognom2 : '',
            present: true,
            motiu: null,
            observacions: null,
            modificat: false
          }
          if(absencies_alumnes[item.id]){
            alumne.present = false;
            alumne.motiu = absencies_alumnes[item.id].motiu;
            alumne.observacions = absencies_alumnes[item.id].missatge_txt;
          }
          return alumne;
        })

        setAlumnes(nouRollCall);
      }
    }
  }




  const buscarMotiusAbsencia = async() =>{
/*
    const options = [];
    for (var i = 0; i < 10; i++) {
      const o = <Select.Option value={i}>{`Motiu ${i}`}</Select.Option>;
      options.push(o);
      console.log(o)
    }
*/
    const tmp = await Api.get('/motius/absencia');
    if(tmp.result.success===1){
      const options = tmp.data.motius.map((item)=>(<Select.Option value={item.id} key={item.id}>{entities.decode(item.nom[props.t('idioma')])}</Select.Option>));
      setOptionsMotiusAbsencia(options);
    }




  }

/*
  const onSelectChange = (xselectedRowKeys, xselectedRows) => {
      //console.log('selectedRowKeys changed: ', selected);
      console.log(`selectedRowKeys: ${xselectedRowKeys}`);
      console.log('selectedRows: ', xselectedRows);
      setSelectedRowKeys(xselectedRowKeys);
    };
  const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
  };
*/
  const checkChangeAll = (checked) => {
    setCheckAll(checked);
    const a = alumnes.map((item)=>{
        item.present = checked;
        setCanvisPendents(true);
      return item;
    });
    setAlumnes(a);
  }
  const checkChange = (id, checked) => {
    const a = alumnes.map((item)=>{
      if(item.id == id){
        item.present = checked;
        item.modificat = true;
        setCanvisPendents(true);
      }
      return item;
    });
    setAlumnes(a);
  }
  const observacionsChange = (id, value) => {
    const a = alumnes.map((item)=>{
      if(item.id == id){
        item.observacions = entities.encode(value);
        item.modificat = true;
        setCanvisPendents(true);
      }
      return item;
    });
    setAlumnes(a);
  }
  const motiuAbsenciaChange = (id, value) => {
    const a = alumnes.map((item)=>{
      if(item.id == id){
        item.motiu = value;
        item.modificat = true;
        setCanvisPendents(true);
      }
      return item;
    });
    setAlumnes(a);
  }




  const guardar = async () =>{

    setIsSaving(true);

    let dades = {
      date: moment(dateEnCurs).format(moment.HTML5_FMT.DATE),
      id_profe,
      tipus,
      id_taula,
      alumnes
    }
    /*
    {
        "id_profe": 1,
        "tipus": "classe",
        "alumnes": [
            {"id_alumne": 100, "present": true, "motiu": "", "observacions": ""},
            {"id_alumne": 99, "present": false, "motiu": "malaltia", "observacions": "Grip"}
        ]
    }
    */

    let method = nou?'POST':'PUT';
    let url_api = nou? '/rollcall' : `/rollcall/${id}/alumnes`;

    //console.log("submit:", JSON.stringify(dades));

    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

      if(resposta.result.success === 1){
        setIsSaving(false);
        //setHoraRollCall(moment(new Date(resposta.data.server_time)).format('HH:mm'));
        setDataRollCall(resposta.data.server_time);
        setId(resposta.data.id);
        setNou(false);
        //await enviarNotificacioAbsencia();
        message.success(props.t('generic.OK_saved'), 5);
      }else{
        setIsSaving(false);
        console.error('Error: submit PassarLLista: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setIsSaving(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: PassarLlista guardar: ', error);
    }
  }


  const enviarNotificacioAbsencia = async () => {

    let triats = [];
    alumnes.map((item) =>{
      if(item.present == false && !item.motiu){
        triats.push(item.id_alumne)
      }
    });


    triats.forEach(async(item, i) =>  {
      let dades = {
        id_usuari: perfilUsuari.account.id,
        ids_alumnes: item,
        id_notificacions_tipus: 5,
        missatge_txt: entities.encode(props.t('notificacions.absencia'))
      }

//      console.log(dades);

      try{
        const resposta = await Api.myFetch('/notificacions/profe/envia', 'POST', JSON.stringify(dades));
        if(resposta.result.success === 1){

        }else{
          console.error('Error: enviant notificacio: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Enviant Notificacio: ', error);
      }
    });


  }


  const columns = [
    {
      title: <Checkbox checked={checkAll} onChange={(e)=>checkChangeAll(e.target.checked)}/>,
      dataIndex: 'present',
      render: (text,record)=>(<Checkbox checked={record.present} onChange={(e)=>checkChange(record.id, e.target.checked)}/>)

    },
    {
      title: props.t('alumnes.nom'),
      dataIndex: 'nom',
      render: (text,record) =>(entities.decode(record.nom)+' '+entities.decode(record.cognom1)+' '+entities.decode(record.cognom2)),
    },
    {
      title: props.t('passar_llista.motiu'),
      dataIndex: 'motiu',
      render: (text,record) =>(
        <Select style={{ width: 300 }} defaultValue={record.motiu ? record.motiu : ''} onChange={(value) => motiuAbsenciaChange(record.id, value)} disabled={record.present}>
        {optionsMotiusAbsencia}
        </Select>
      ),
    },
    {
      title: props.t('passar_llista.observacions'),
      dataIndex: 'observacions',
      render: (text,record) =>(<Input onChange={(e) => observacionsChange(record.id, e.target.value)} value={entities.decode(record.observacions)} maxLength={256}  disabled={record.present}/>),
    },
  ];
  return (
    <ContainerPagina xl={24}>
      { modal == true ? null :
        <TitolPagina txt={props.t('professors_relacions.passarLlista')}/>
      }
      <div style={modal ? {marginTop:'1rem', marginLeft:'1rem'} : {marginTop:'1rem', marginLeft:'3rem'}}>
      { modal == true ? null :
        <Space>
          <Button type="primary" onClick={guardar} disabled={!canvisPendents && !nou} loading={isSaving}>{props.t('generic.save')}</Button>
          {nou ? <span style={{color: 'red'}}>*{props.t('passar_llista.pendent')}</span> : <span>&nbsp;{props.t('passar_llista.passat_a_les')+moment(new Date(dataRollCall)).format('HH:mm')}</span>}
        </Space>
      }
          <Table
            rowKey="id"
            //rowSelection={rowSelection}
            columns={columns}
            dataSource={alumnes}
            pagination={false}
            size="small"
            loading={isLoading}
          />
          <Button type="primary" onClick={guardar} disabled={(!canvisPendents && !nou && modal == false)} loading={isSaving}>{props.t('generic.save')}</Button>
        </div>
    </ContainerPagina>
  );
}
export default translate(PassarLlista);
