import React, {useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/Transport';
import { Api, InitDades } from '../../../helpers';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();

function Transport(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);
  const [refrescar, setRefrescar] = useState(0);


  const buscarDades = async () => {
    const trans = await Api.get(`/transport/curs/${cursEscolarTriat}`);
    if(trans.result.success!==1){return [];}
    return trans.data.transport;
  }

  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/transport/'+id, 'DELETE');
    if(fam.result.success===1){

    }

  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }

  return(
    <ContainerPagina xl={24}>
      <TitolPagina txt={props.t('menu.Transport')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          id_curs_escolar={cursEscolarTriat}
          btn_extra={<TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>}
          txt_creats={props.t('transport.creados')}
          icon_creats={CarOutlined}
          txt_nou={props.t('transport.nuevo')}
          buscarDades={buscarDades}
          refrescar={refrescar}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.transport()}>
          <Column
          title="Id"
          key="id"
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />
          <Column
          title={props.t('transport.ordre')}
          key="ordre"
          render={(text,record) => (
            <span>{record.ordre}</span>
          )}
          />
          <Column
          title={props.t('transport.sentit')}
          key="sentit"
          render={(text,record) =>(
            record.sentit === 'anada'? props.t('transport.anada') : props.t('transport.tornada')
          )}
          />
          <Column
          title={props.t('transport.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom[props.t('idioma')])
          )}/>
          <Column
          title={props.t('transport.preu_mensual')}
          align="center"
          key="preu_mensual"
          render={(text,record) =>(
            `${record.preu_mensual}€`
          )}/>
          <Column
          title={props.t('transport.preu_puntual')}
          align="center"
          key="preu_puntual"
          render={(text,record) =>(
            `${record.preu_puntual}€`
          )}/>
          <Column
          title={props.t('transport.descompte')}
          align="center"
          key="descompte"
          render={(text,record) =>(
              `${record.descompte_x_anada_i_tornada}%`
          )}
          />



        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Transport);
