import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, Button, Input, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../helpers';

const entities = new AllHtmlEntities();


function Edit(props){

  const id = props.item.id;
  const [nom, setNom] = useState(entities.decode(props.item.nom));

  const [guardant, setGuardant] = useState(false);

  const [errorNom, setErrorNom] = useState('');

  const nou = (props.item.id===0);


  const nomChange = (e) =>{
    const n = e.target.value.trim();
    setNom(n);
    if(n.length>0){
      setErrorNom('');
    }
  }

  const submitForm = async (values) => {      

      if(nom.length === 0){
        setErrorNom('error');
        return false;
      }

      setGuardant(true);

      let dades = {
        id,
        nom: entities.encode(nom),
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/etapes' : '/etapes/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          props.callback();
        }else{
          setGuardant(false);
          console.error('Error: submit FormEtapes: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Etapes guardar: ', error);
      }

  }



  return(
    <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm} style={{marginTop:'35px'}}
      layout="horizontal"
      labelCol={{span:2}}
      wrapperCol={{span:9}}
      colon={true}
      autoComplete="off"
    >

        <Form.Item
          validateStatus={errorNom}
          label={props.t('etapes.nom')}
          required={true}
        >
            <Input name="nom" placeholder={props.t('etapes.nom')} onChange={nomChange} defaultValue={nom} autoFocus="focus"/>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 2, span: 9 }} name="botonets">
          <Row type="flex" justify="start">
            <Col>
              <Button onClick={props.callback}>{props.t('generic.cancel')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
}
export default translate(Edit);
