import React, { useState, useEffect } from 'react';

import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { Form, Row, Col, DatePicker, Switch, Select, Button, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import PropietatsLLista from '../../../../pages/perfils/admin/AcollidaPropietats';
import NomDescripcioIdiomes from '../../../NomDescripcioIdiomes';

const entities = new AllHtmlEntities();
const dateFormat = 'DD/MM/YYYY HH:mm';

function Edit(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [id, setId] = useState(props.item.id);
  const [tipus, setTipus] = useState(props.item.tipus);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [id_tipus_propietats, setId_tipus_propietats] = useState(props.item.id_tipus_propietats);
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [descompte, setDescompte] = useState(props.item.descompte);

  const [tipusPropietats, setTipusPropietats] = useState([]);

  const [guardant, setGuardant] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');

  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');
  const [errorSelectTipus, setErrorSelectTipus] = useState('');
  const [errorSelectTextTipus, setErrorSelectTextTipus] = useState('');

  const nou = (id===0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  const buscarDades = async () => {
    setIsLoading(true);

    const tipus = await Api.get('/botiga/propietats/tipus');
    if(tipus.result.success===1){
      const llista_tipus = tipus.data.tipus_propietats.map((item) =>{
          if(item.id < 3){
            return(
              <Select.Option key={item.id} value={item.id}>
                {entities.decode(item.text[props.t('idioma')])}
              </Select.Option>
            );
          }else{
            return null;
          }
      });
      setTipusPropietats(llista_tipus);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    buscarDades();
  },[]);


  const nomChange_ca = (e) =>{
    const n = e.target.value.trim();
    setNom_ca(n);
    if(n.length>0){
      setErrorNom_ca('');
    }
  }
  const nomChange_en = (e) =>{
    const n = e.target.value.trim();
    setNom_en(n);
    if(n.length>0){
      setErrorNom_en('');
    }
  }
  const nomChange_es = (e) =>{
    const n = e.target.value.trim();
    setNom_es(n);
    if(n.length>0){
      setErrorNom_es('');
    }
  }

  const selectChange = (value) =>{
    console.log(value);
    setTipus(value);
    if(value!==null){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }
  const selectChangeTipus = (value) =>{
    console.log(value);
    setId_tipus_propietats(value);
    if(value!==null){
      setErrorSelectTipus('');
      setErrorSelectTextTipus('');
    }
  }

  const submitForm = async (values) => {

      let correcte = true;

      if(nom_ca.length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(nom_en.length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(nom_es.length === 0){
        setErrorNom_es('error');
        correcte = false;
      }

      if(tipus === null){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }
      if(id_tipus_propietats === null){
        setErrorSelectTipus('error');
        setErrorSelectTextTipus(props.t('generic.tria'));
        correcte = false;
      }

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id_curs_escolar,
        id,
        tipus,
        ordre,
        id_tipus_propietats,
        nom_ca: entities.encode(nom_ca),
        nom_en: entities.encode(nom_en),
        nom_es: entities.encode(nom_es),
        descompte
      }
      //console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/acollida' : '/acollida/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          if(nou){
            setId(resposta.data.id);
            //nou = false;
          }else{
            props.callback();
          }

        }else{
          setGuardant(false);
          console.error('Error: submit Form Acollida: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Acollida guardar: ', error);
      }

  }


  if(isLoading){
    return <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} />;
  } else {

    return(
    <>
      <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
        <LeftOutlined className="BotoBack" onClick={props.callback}/>
      </div>
      <div style={{padding:'10px 24px 35px 0px'}}>
      <Form onFinish={submitForm}
        layout="horizontal"
        labelCol={{span:2}}
        wrapperCol={{span:22}}
        colon={true}
        autoComplete="off"
      >
        <Form.Item label={props.t('transport.ordre')}
          required={true}
        >
            <InputNumber name="ordre" onChange={setOrdre} defaultValue={ordre}/>
        </Form.Item>

        <NomDescripcioIdiomes
          errorNom_ca = {errorNom_ca}
          errorNom_en = {errorNom_en}
          errorNom_es = {errorNom_es}
          nomChange_ca = {nomChange_ca}
          nomChange_en = {nomChange_en}
          nomChange_es = {nomChange_es}
          nom_ca = {nom_ca}
          nom_en = {nom_en}
          nom_es = {nom_es}
          visibleDescripcio = {false}
        />
          <Form.Item style={{marginBottom: 0}}
            validateStatus={errorSelect}
            help={errorSelectText}
            colon={true}
            label={props.t('acollida.tipus')}
            labelCol={{span:2}}
            wrapperCol={{span:5}}
            required={true}
          >
            <Select defaultValue={tipus == null ? "" : tipus} defaultActiveFirstOption={true} onChange={selectChange}>
              <Select.Option key='mensual' value='mensual'>
                {props.t('acollida.mensual')}
              </Select.Option>
              <Select.Option key='puntual' value='puntual'>
                {props.t('acollida.puntual')}
              </Select.Option>
            </Select>
          </Form.Item>


          <Form.Item label={props.t('acollida.descompte')}>
              <InputNumber name="descompte" onChange={setDescompte} defaultValue={descompte}
              min={-100} max={100} step={0.1}
              formatter={value => `${value}%`}
              parser={value => value.replace('%', '')}
              />
          </Form.Item>

          <Form.Item style={{marginBottom: 0, marginLeft: '-10px'}}
            validateStatus={errorSelectTipus}
            help={errorSelectTextTipus}
            label={props.t('acollida.tipus_propietats')}
            labelCol={{span:3}}
            wrapperCol={{span:5}}
            required={true}
          >
            <Select style={{marginLeft: '10px'}}defaultValue={id_tipus_propietats === 0 ? "" : id_tipus_propietats} defaultActiveFirstOption={true} onChange={selectChangeTipus}>
              {tipusPropietats}
            </Select>
          </Form.Item>

          <Row type="flex" justify="start">
            <Col>
              <Button onClick={props.callback}>{props.t('generic.back')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>
        </Form>

        { nou ? null :
          <PropietatsLLista id_acollida={id}/>
        }


      </div>
    </>
    );
  }
}
export default translate(Edit);
