import React, { useState } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Form, Input, Button, Typography, Upload, Space, Switch, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();

function DocumentsAfegir(props){
  //Afegir o Editar fitxer
console.log('DocumentsAfegir', props)
  const [id_fitxer, setId_fitxer] = useState(props.fitxer.id_fitxer);
  const id_carpeta = useState(props.fitxer.id_carpeta);
  const [titol, setTitol] = useState(entities.decode(props.fitxer.titol));
  const [subtitol, setSubtitol] = useState(entities.decode(props.fitxer.subtitol));
  const [visible, setVisible] = useState(props.fitxer.visible==1 ? true : false );

  const [fileList, setFileList] = useState([]);
  const [guardant, setGuardant] = useState(false);

  const [nou, setNou] = useState(props.fitxer.id_fitxer===0);

  const submitForm = async (valors) => {

    let correcte = true;

    if(nou && fileList.length<=0){
      message.config({top: 250});
      message.error({content: 'Error: '+ props.t('documents.tria'), duration:5});
      correcte = false;
    }


    if(!correcte){
      return false;
    }

    setGuardant(true);
    try{

      let resposta = null;

      if(nou){
        var formData = new FormData();
        formData.append("id_carpeta", id_carpeta);
        formData.append("visible", visible ? 1 : 0);
        formData.append("titol", titol ? entities.encode(titol) : '');
        formData.append("subtitol", subtitol ? entities.encode(subtitol) : '');
        formData.append("fitxer", fileList[0]);
        resposta = await Api.enviarFormulariFitxer('/documents/pujar/fitxer', formData);
        console.log('nou', resposta)

      }else{
        let dades = {
          titol,
          subtitol,
          visible
        }
        console.log('editar', JSON.stringify(dades))
        resposta = await Api.myFetch(`/documents/fitxer/${id_fitxer}`, 'PUT', JSON.stringify(dades));

      }

      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();
      }else{
        setGuardant(false);
        console.error('Error: guardant DocumentsAfegir: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: DocumentsAfegir guardar: ', error);
    }

  }


  const uploadConfig = {
        onRemove: file => {
          setFileList([]);
        },
        beforeUpload: file => {
          setFileList([file]);
          return false;
        },
        fileList,
      };


  return(
    <>
    <div style={{textAlign: 'center', marginBottom:'2rem'}}>
      <Typography.Title level={3}>{nou ? props.t('documents.add_document') : props.t('documents.fitxer')}</Typography.Title>
    </div>
    <Form onFinish={submitForm}
        layout="vertical"
        colon={true}
        autoComplete="new-password"
      >
        <Form.Item label={props.t('documents.label_titol')}>
          <Input placeholder={props.t('documents.label_titol')} onChange={(e) => setTitol(e.target.value)} value={titol} autoComplete="çlayskdfjçalkdfjç"/>
        </Form.Item>
        <Form.Item label={props.t('documents.label_subtitol')}>
          <Input placeholder={props.t('documents.label_subtitol')} onChange={(e) => setSubtitol(e.target.value)} value={subtitol} autoComplete="ñlasbkdfjñalkdfj"/>
        </Form.Item>

        { !nou ? null :
          <Upload {...uploadConfig}>
            <Button icon={<UploadOutlined />}>{props.t('documents.tria')}</Button>
          </Upload>
        }

        <Row style={{marginTop: 25, marginBottom: 25}}>
          <Space>
            <span>{props.t('generic.visible_pregunta')}</span>
            <Switch checked={visible} onChange={setVisible} checkedChildren={props.t('generic.si')} unCheckedChildren={props.t('generic.no')}/>
          </Space>
        </Row>
        <Row type="flex" justify="center" style={{marginTop: 25, marginBottom: 25}}>
          <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
        </Row>
      </Form>
    </>
  );
}


export default translate(DocumentsAfegir);
