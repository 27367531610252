import React, { useState, useEffect, useContext } from 'react';
import { translate } from 'react-multi-lang';
import { SyncOutlined, LeftOutlined } from '@ant-design/icons';
import { Tabs, Button, Radio } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import Producte from './Producte';
import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import MealTimeReportAlumne from '../../../components/Formularis/perfils/pares/MealTimeReport';
import { Api } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";
import { MenuTriatContext } from "../../../context/menu-triat";
const entities = new AllHtmlEntities();

function MealTimeReport(props){
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);

  const [fillActual, setFillActual] = useState();
  const [llistaFills, setLlistaFills] = useState([]);
  const [categories, setCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(true);


  const buscarDades = async () => {
    setIsLoading(true);
    let llista_f = [];
    const fills = await Api.get(`/alumnes/familia/${perfilUsuari.account.id_familia}`);
    if(fills.result.success===1){
      setFillActual(fills.data.alumnes[0].id);
      setLlistaFills(fills.data.alumnes);
    }
    setIsLoading(false);
  }



  useEffect(() => {
    buscarDades();
  },[]);


const backButton = (e) =>{
  setMenuTriat("Dashboard")
}


  return (
    <ContainerPagina xl={24}>

      <TitolPagina txt={props.t('menjador.report.titol')}/>
      <div style={{marginTop:'-2.8rem', marginLeft:'1rem'}}>
        <LeftOutlined className="BotoBack" onClick={(e) => backButton(e)}/>
      </div>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
      {
        isLoading ? <SyncOutlined spin title="Loading..." style={{fontSize: '3rem'}} /> :

          <Tabs defaultActiveKey={fillActual} onChange={setFillActual}>
            {
              llistaFills.map((item) =>{
                  return(
                    <Tabs.TabPane tab={entities.decode(item.nom)} key={item.id}>
                      <MealTimeReportAlumne id_alumne={item.id}/>
                    </Tabs.TabPane>
                  )
                })
            }
          </Tabs>
      }
      </div>
    </ContainerPagina>
  );
}

export default translate(MealTimeReport);
