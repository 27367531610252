import React, { useState, useContext, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Alert, Divider, Button, Typography, Modal, Space } from 'antd';
import {ContactsOutlined, TeamOutlined, UserOutlined, SkinOutlined, DatabaseOutlined, EuroCircleOutlined, InboxOutlined} from '@ant-design/icons';
import locale_ca from 'antd/es/date-picker/locale/ca_ES';

import { MenuTriatContext } from "../context/menu-triat";
import { PerfilUsuariContext } from "../context/perfil-usuari";
import { Api } from '../helpers';
import NewPassword from '../components/PantallesObligadesInici/NewPassword';
import BotoFamilia from '../components/PopUps/perfils/admin/BotoUnitatFamiliarAlertes';

//import {ReactComponent as Carrito} from '../icons/icones_app_BSCD-01.svg';
//import Bus from '../icons/bus.png';

function DashboardAdmin(props){

  const [menuTriat, setMenuTriat] = useContext(MenuTriatContext);
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);
  const [obligar_modificar_password, setObligar_modificar_password] = useState(false);
  const [llistaAlertes, setLlistaAlertes] = useState([]);
  const [refrescar, setRefrescar] = useState(0);

  if(perfilUsuari.account.username == 'xleon'){
    //setMenuTriat('QuotesPreus')
    //setMenuTriat('ComandesExtraescolars')
    //setMenuTriat('ComandesTransport')
    //setMenuTriat('ComandesMenjador')
    //setMenuTriat('ComandesAcollida')
    //setMenuTriat('Facturacio')
  //  setMenuTriat('Documents')
  //  setMenuTriat('ComandesRoba')
  }

  const buscarDades = async () => {
    const resp = await Api.get('/resposta/pendents/pantalla/'+perfilUsuari.account.id);
    if(resp.result.success===1){

      //console.log(resp.data.pantalles_inici)
      setObligar_modificar_password(resp.data.pantalles_inici.obligar_modificar_password);
    }
  }

  useEffect(() => {
    buscarDades();
  },[]);

  const refrescarAlertes = e =>{
    setRefrescar(refrescar+1)
  }

  const buscarAlertes = async () =>{
    setLlistaAlertes([]);
    const alertes = await Api.get('/alertes/'+perfilUsuari.account.perfil);
    console.log(alertes)
    if(alertes.result.success===1){
      const llista = alertes.data.alertes.map((item, i) =>{
        return(
          <Alert key={i} message={<Space align="baseline">{item.titol+": "+item.missatge}<BotoFamilia id_familia={item.id_familia} callback={()=>refrescarAlertes()}/></Space>} type="error" showIcon style={{margin:5}} closable={true}/>

        );
      });
      setLlistaAlertes(llista);
    }
  }

  useEffect(() => {
    buscarAlertes();
  },[refrescar]);

  const newPasswordOK = () =>{
    setObligar_modificar_password(false);
  }

const mides = {width: '100%',height: '100px'};
/*const color1 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#bbdefb', borderColor: '#bbdefb', color: 'rgba(0,0,0,0.87)'};
const color2 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#c5cae9', borderColor: '#c5cae9', color: 'rgba(0,0,0,0.87)'};
const color3 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#b2ebf2', borderColor: '#b2ebf2', color: 'rgba(0,0,0,0.87)'};
const color4 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#448aff', borderColor: '#448aff', color: 'rgba(0,0,0,0.87)'};*/
const color1 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#00e5ff', borderColor: '#00e5ff', color: 'rgba(0,0,0,0.87)'};
const color2 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#eeff41', borderColor: '#eeff41', color: 'rgba(0,0,0,0.87)'};
const color3 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#42a5f5', borderColor: '#42a5f5', color: 'rgba(0,0,0,0.87)'};
const color4 = {padding: 0, cursor: 'pointer', width: mides.width, height: mides.height, backgroundColor: '#448aff', borderColor: '#448aff', color: 'rgba(0,0,0,0.87)'};

//const span_botons = 'xs={24} sm={12} md={12} lg={8} xl={6}';
const span_botons = 'ant-col-xs-24 ant-col-sm-24 ant-col-md-12 ant-col-lg-8 ant-col-xl-6 ant-col-xxl-6';

return(
  <div>
  {llistaAlertes}
  <Modal
  key="NewPassword"
  visible={obligar_modificar_password}
  footer={null}
  width={550}
  style={{top:20}}
  bodyStyle={{ height: "350px"}}
  destroyOnClose={true}
  forceRender={true}
  maskClosable={false}
  closable={false}
  >
    <NewPassword callback={newPasswordOK}/>
  </Modal>

{/*<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} type="flex" justify="space-between">*/}
{/* xs={24} sm={12} md={12} lg={8} xl={6}*/}
      <Row type="flex" justify="start" style={{padding: '25px'}} gutter={[16,16]}>
        <Col className={span_botons}>
          <div style={color3} onClick={(e) => setMenuTriat("ComandesRoba")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Comandes')}&nbsp;&gt;&nbsp;{props.t('menu.ComandesRoba')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.ComandesRoba')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color3} onClick={(e) => setMenuTriat("ComandesTransport")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Comandes')}&nbsp;&gt;&nbsp;{props.t('menu.Transport')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.Transport')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color3} onClick={(e) => setMenuTriat("ComandesExtraescolars")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Comandes')}&nbsp;&gt;&nbsp;{props.t('menu.Extraescolars')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.Extraescolars')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color3} onClick={(e) => setMenuTriat("ComandesMenjador")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Comandes')}&nbsp;&gt;&nbsp;{props.t('menu.Menjador')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.Menjador')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color3} onClick={(e) => setMenuTriat("ComandesAcollida")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Comandes')}&nbsp;&gt;&nbsp;{props.t('menu.Acollida')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.Acollida')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        {/*<Col className={span_botons}>
          <div style={color3} onClick={(e) => setMenuTriat("ComandesSummerCamp")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Comandes')}&nbsp;&gt;&nbsp;{props.t('menu.ComandesSummerCamp')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.ComandesSummerCamp')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>*/}
        <Col className={span_botons}>
          <div style={color3} onClick={(e) => setMenuTriat("ComandesExcursions")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<InboxOutlined />&nbsp;{props.t('menu.Comandes')}&nbsp;&gt;&nbsp;{props.t('menu.ComandesExcursions')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.ComandesExcursions')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color1} onClick={(e) => setMenuTriat("UnitatFamiliar")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><ContactsOutlined/>&nbsp;{props.t('menu.Families')}&nbsp;&gt;&nbsp;<TeamOutlined />&nbsp;{props.t('menu.UnitatFamiliar')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.UnitatFamiliar')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color1} onClick={(e) => setMenuTriat("Pares")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><ContactsOutlined/>&nbsp;{props.t('menu.Families')}&nbsp;&gt;&nbsp;<UserOutlined />&nbsp;{props.t('menu.Pares')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.Pares')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color1} onClick={(e) => setMenuTriat("Alumnes")}>
            <p style={{padding: '5px 0 0 10px', color: 'white'}}><ContactsOutlined/>&nbsp;{props.t('menu.Families')}&nbsp;&gt;&nbsp;<SkinOutlined />&nbsp;{props.t('menu.Alumnes')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.Alumnes')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color2} onClick={(e) => setMenuTriat("ImportsRecurrents")}>
            <p style={{padding: '5px 0 0 10px', color: 'black'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<EuroCircleOutlined />&nbsp;{props.t('menu.Facturacio')}&nbsp;&gt;&nbsp;{props.t('menu.ImportsRecurrents_curt')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.ImportsRecurrents')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color2} onClick={(e) => setMenuTriat("Pressupost")}>
            <p style={{padding: '5px 0 0 10px', color: 'black'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<EuroCircleOutlined />&nbsp;{props.t('menu.Facturacio')}&nbsp;&gt;&nbsp;{props.t('menu.Pressupost')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.Pressupost')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        <Col className={span_botons}>
          <div style={color2} onClick={(e) => setMenuTriat("Rebuts")}>
            {/*<p style={{padding: '5px 0 0 10px', color: '#90a4ae'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<EuroCircleOutlined />&nbsp;{props.t('menu.Facturacio')}&nbsp;&gt;&nbsp;{props.t('menu.Rebuts')}</p>*/}
            <p style={{padding: '5px 0 0 10px', color: 'black'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<EuroCircleOutlined />&nbsp;{props.t('menu.Facturacio')}&nbsp;&gt;&nbsp;{props.t('menu.Rebuts')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.Rebuts')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>

        {(perfilUsuari.account.username == 'xleon') && (
        <Col className={span_botons}>
          <div style={color2} onClick={(e) => setMenuTriat("TipusQuotesPreus")}>
            {/*<p style={{padding: '5px 0 0 10px', color: '#90a4ae'}}><DatabaseOutlined/>&nbsp;{props.t('menu.Gestio')}&nbsp;&gt;&nbsp;<EuroCircleOutlined />&nbsp;{props.t('menu.Facturacio')}&nbsp;&gt;&nbsp;{props.t('menu.Rebuts')}</p>*/}
            <p style={{padding: '5px 0 0 10px', color: 'black'}}><DatabaseOutlined/>{props.t('menu.TipusQuotesPreus')}</p>
            <Row justify="center" align="middle">
              <Col><Typography.Title level={4}>{props.t('menu.TipusQuotesPreus')}</Typography.Title></Col>
            </Row>
          </div>
        </Col>
        )}



      </Row>


{/*</Row>*/}

  </div>
);
}

export default translate(DashboardAdmin);
