import React, {useState, useContext} from 'react';
import { translate } from 'react-multi-lang';
import { Table } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import ContainerPagina from '../../../components/ContainerPagina';
import TitolPagina from '../../../components/TitolPagina';
import TriaCursEscolar from '../../../components/TriaCursEscolar';
import LlistaEditable from '../../../components/LlistaEditable';
import Edit from '../../../components/Formularis/perfils/admin/Classes';
import { Api, InitDades } from '../../../helpers';
import { PerfilUsuariContext } from "../../../context/perfil-usuari";

const { Column } = Table;
const entities = new AllHtmlEntities();


function Classes(props) {

  const [perfilUsuari] = useContext(PerfilUsuariContext);
  const [cursEscolarTriat, setCursEscolarTriat] = useState(perfilUsuari.curs_escolar_actual.id);
  const [refrescar, setRefrescar] = useState(0);

  const buscarDades = async () => {
    //const cla = await Api.get('/classes');
    const cla = await Api.get(`/classes/all/${cursEscolarTriat}`);
    if(cla.result.success!==1){return [];}

    const cur = await Api.get('/cursos');
    if(cur.result.success!==1){return [];}

    let llista_cur = [];
    llista_cur[0] = "";
    cur.data.cursos.forEach((item) =>{
        llista_cur[item.id] = item.nom;
    });
    const llista_classes = cla.data.classes.map((item) =>{
      item.curs = llista_cur[item.id_curs];
      return item;
    });

    return llista_classes;

  }

  const marcarEliminat = async (id) => {
    const fam = await Api.myFetch('/classes/'+id, 'DELETE');
    if(fam.result.success===1){

    }
  }

  const triaCursChange = (curs) => {
    setCursEscolarTriat(curs);
    setRefrescar(refrescar+1);
  }

  return(
    <ContainerPagina>
      <TitolPagina txt={props.t('menu.Classes')}/>
      <div style={{marginTop:'1rem', marginLeft:'3rem'}}>
        <LlistaEditable
          id_curs_escolar={cursEscolarTriat}
          btn_extra={<TriaCursEscolar defaultValue={cursEscolarTriat} onChange={(c) => triaCursChange(c)}/>}
          txt_creats={props.t('classes.creados')}
          txt_nou={props.t('classes.nuevo')}
          buscarDades={buscarDades}
          refrescar={refrescar}
          edit={Edit}
          delete={marcarEliminat}
          itemBuit={InitDades.classe()}>
          <Column
          title="Id"
          key="id"
          sorter={(a, b) =>  (a.id - b.id)}
          render={(text,record) => (
            <span>{record.id}</span>
          )}
          />
          <Column
          title={props.t('transport.ordre')}
          key="ordre"
          sorter={(a, b) =>  (a.ordre - b.ordre)}
          render={(text,record) => (
            <span>{record.ordre}</span>
          )}
          />
          <Column
          title={props.t('classes.nom')}
          key="nom"
          render={(text,record) =>(
            entities.decode(record.nom)
          )}
          />
          <Column
          title={props.t('classes.curs')}
          key="curs"
          render={(text,record) =>(
            record.curs
          )}
          />
          <Column
          title={props.t('generic.hini')}
          key="h_ini"
          render={(text,record) =>(
            <span>{record.h_ini}:{String(record.m_ini).padStart(2, '0')}</span>
          )}
          />
          <Column
          title={props.t('generic.hfin')}
          key="h_fin"
          render={(text,record) =>(
            <span>{record.h_fin}:{String(record.m_fin).padStart(2, '0')}</span>
          )}
          />


        </LlistaEditable>
      </div>
    </ContainerPagina>
  );

}

export default translate(Classes);
