import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function FacturacioExportaExcel(props){

  const [dades, setDades] = useState([]);
  const [xls_columnes_txt, setXls_columnes_txt] = useState([]);
  const [xls_columnes_num, setXls_columnes_num] = useState([]);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : 'Pressupostos';
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : 'Pressupostos';


  useEffect(() => {
    convertirDades();
  },[props.dades]);


  const convertirDades = () =>{

    const xls_txt = [
      {label:'Curs', key: 'curs_nom_t'},
      {label:'Classe', key: 'classe_nom_t'},
      {label:'id_alumne', key: 'id_alumne'},
      {label:'Nom', key: 'nom_t'},
      {label:'Cognom1', key: 'cognom1_t'},
      {label:'Cognom2', key: 'cognom2_t'},
      {label:'Tipus Pagament', key: 'tipus_pagament'},
      {label:'TOTAL', key: 'total'},
    ];

    const columnes_txt = xls_txt.map((item) =>(<ExcelColumn key={item.key} label={item.label} value={item.key}/>));

    //Es defineixo aquí perque vull que surtin en aquest ordre, si en creen de nous no estaran ordenats.
    let xls_num = [];
    xls_num['Matr&iacute;cula'] = 'Matr&iacute;cula';
    xls_num['Quota educativa'] = 'Quota educativa';
    xls_num['Material EY &amp; PN'] = 'Material EY &amp; PN';
    xls_num['Material N, R &amp; Primary'] = 'Material N, R &amp; Primary';
    xls_num['Intranet'] = 'Intranet';
    xls_num['Tapestry EYFS'] = 'Tapestry EYFS';
    xls_num['ICT'] = 'ICT';
    xls_num['Mask'] = 'Mask';
    xls_num['Transport'] = 'Transport';
    xls_num['Menjador'] = 'Menjador';
    xls_num['Acollida'] = 'Acollida';

    const dades_tmp = props.dades.map((item) =>{
        item.detall.forEach((detall, i) => {
          let key = detall.concepte;
          if(detall.taula === 'transport_comandes'){
            key = 'Transport';
          }
          if(detall.taula === 'acollida_comandes'){
            key = 'Acollida';
          }

          if(item.[key]===undefined){
            item.[key] = 0;
          }
          item.[key] = item.[key] + detall.subtotal;
          xls_num[key] = key;
        });
      return item;
    })

    dades_tmp.sort((a, b) => {
      if (a.id_curs > b.id_curs) return 1;
  	  if (a.id_curs < b.id_curs) return -1;
  	  if (a.id_classe > b.id_classe) return 1;
  	  if (a.id_classe < b.id_classe) return -1;
      if (a.nom_t > b.nom_t) return 1;
      if (a.nom_t < b.nom_t) return -1;
      //return a.id_curs - b.id_curs;
    });

    let columnes_num = [];

    Object.keys(xls_num).forEach((item, index, arr) => {
      columnes_num.push(<ExcelColumn key={index} label={entities.decode(item)} value={item}/>);
    });

    setXls_columnes_txt(columnes_txt);
    setXls_columnes_num(columnes_num);
    setDades(dades_tmp);
  }

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>Generar Excel Pressupost</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            {xls_columnes_txt}
            {xls_columnes_num}
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(FacturacioExportaExcel);
