import React, { useState, useEffect } from 'react';

import { translate } from 'react-multi-lang';
import { Table, Col, Tag, Row, Button, Input, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SyncOutlined, SearchOutlined } from '@ant-design/icons';
import { AllHtmlEntities } from 'html-entities';

import TitolPagina from '../../../TitolPagina';
import { Api, InitDades } from '../../../../helpers';

const { Column } = Table;
const entities = new AllHtmlEntities();

class TriarAlumnes extends React.Component{

  constructor(props){
    super(props);
    this.state = {
    filtresClasses: [],
    alumnes: props.llistaAlumnes || [],
    triats: [],
    isLoading: true,
    searchText: '',
    searchedColumn: '',
    id_curs_escolar: props.id_curs_escolar
  };

  }


  async componentDidMount(){
    this.setState({isLoading: true});
    //const cla = await Api.get('/classes');
    const cla = await Api.get(`/classes/all/${this.state.id_curs_escolar}`);
    if(cla.result.success!==1){return [];}

    const classes = cla.data.classes.reduce((acumulat, item) => ({
      ...acumulat,
      [item.id]: item.nom,
    }),{});

    const filtreCl = cla.data.classes.map((item)=>{
      return {text: item.nom, value: item.id}
    });
    this.setState({filtresClasses: filtreCl});


    /*if(this.props.llistaAlumnes===null || this.props.llistaAlumnes===undefined || this.props.llistaAlumnes.length ===0){
      const alum = await Api.get('/alumnes');
      if(alum.result.success===1){
        this.setState({ alumnes: alum.data.alumnes })
      }
    }*/
    this.setState({isLoading: false});
  }

  getColumnSearchProps = (dataIndex, nom) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`${this.props.t('generic.cercar')} ${nom}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {this.props.t('generic.cercar')}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      //record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      record[dataIndex] ? this.arreglarTxt4Search(entities.decode(record[dataIndex].toString())).includes(this.arreglarTxt4Search(value)) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? entities.decode(text.toString()) : ''}
        />
      ) : (
        entities.decode(text)
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  arreglarTxt4Search = text =>{
      // Poso en minuscules, normalitzo caracters treure accents i ñ , elimina els caracters diacrítics raros d'un text (ES6)
        return text.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"");
  }

  render(){
    const { isLoading, filtresClasses, alumnes } = this.state;

    console.log(alumnes)

    const columns = [
    /*{
      title:"Id",
      key:"id",
      sorter: (a, b) =>  a.id - b.id,
      render: (text,record) => (<span>{record.id}</span>)
    },*/
    {
      title: this.props.t('alumnes.classe'),
      key:"classe",
      dataIndex: 'classe',
      filters: filtresClasses,
      onFilter: (value, record) => record.id_classe === value,
      render: (text,record) =>(entities.decode(record.classe)),
      sorter: (a, b) => { return entities.decode(a.classe).localeCompare(entities.decode(b.classe))},
    },{
      title: this.props.t('alumnes.nom'),
      key:"nom",
      dataIndex: 'nom',
      render: (text,record) =>(entities.decode(record.nom)),
      sorter: (a, b) => { return entities.decode(a.nom).localeCompare(entities.decode(b.nom))},
      ...this.getColumnSearchProps('nom',this.props.t('alumnes.nom'))
    },{
      title: this.props.t('alumnes.cognom1'),
      key: "cognom1",
      dataIndex: 'cognom1',
      render: (text,record) =>(entities.decode(record.cognom1)),
      sorter: (a, b) => { return entities.decode(a.cognom1).localeCompare(entities.decode(b.cognom1))},
      ...this.getColumnSearchProps('cognom1',this.props.t('alumnes.cognom1'))
    },{
      title: this.props.t('alumnes.cognom2'),
      key: "cognom2",
      dataIndex: 'cognom2',
      render: (text,record) =>(entities.decode(record.cognom2)),
      sorter: (a, b) => { return entities.decode(a.cognom2).localeCompare(entities.decode(b.cognom2))},
      ...this.getColumnSearchProps('cognom2',this.props.t('alumnes.cognom2'))
    },{
      title:'Quota',
      key:"text",
      dataIndex: 'quota_text',
      render:(text,record) =>{
        return entities.decode(record.quota_text[this.props.t('idioma')]);
      },
      sorter: (a, b) => { return entities.decode(a.quota_text[this.props.t('idioma')]).localeCompare(entities.decode(b.quota_text[this.props.t('idioma')]))},
      filters: this.props.filtresQuotes,
      onFilter: (value, record) => (record.id_quota === value)
    },
    {
      title:this.props.t('quotes.import'),
      key:"preu",
      sorter: (a, b) =>  a.id - b.id,
      render: (text,record) => (<span>{record.quota_preu}€</span>)
    }
    ];


    return(
      <>
      <Table
        locale={{filterConfirm: 'OK', filterReset: 'Reset', emptyText: 'No Data'}}
        size='small'
        columns={columns}
        dataSource={alumnes}
        pagination={false}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            this.props.setTriats(selectedRowKeys);
          }
        }}
        loading={isLoading}
        />
      </>
    );
  }



}

export default translate(TriarAlumnes);
