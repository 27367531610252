import React from 'react';
import { Row, Col } from 'antd';


function ContainerPagina(props) {
    return(
      <Row style={{marginRight:'1rem'}}>
        <Col xs={props.xs || 24} sm={props.sm || 24} md={props.md || 24} lg={props.lg || 24} xl={props.xl || 16}>
        {props.children || null}
        </Col>
      </Row>
    );
}
export default ContainerPagina;
