import sha512 from 'crypto-js/sha512';
import rabbit from 'crypto-js/rabbit';
import Base64 from 'crypto-js/enc-base64';
import encUtf8 from 'crypto-js/enc-utf8';

function compararContrasenya(password_nou, password_guardat_encriptat){
  //Compara un password a la vista amb un password encriptat.
  const pass_512 = sha512(password_nou);
  const password_hash = Base64.stringify(pass_512);
  const verificar_guardat = rabbit.decrypt(password_guardat_encriptat, process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
  return (password_hash===verificar_guardat);
}

function perGuardarDesHash(password_hash){
  //Encripta el password que ja te el hash fet per a poder-lo guardar al perfil
  const verificar_enc = rabbit.encrypt(password_hash, process.env.REACT_APP_SECRET_KEY).toString();
  return verificar_enc;
}
function perGuardar(password_nou){
  //Encripta el password a la vista per a poder-lo guardar al perfil
  const pass_512 = sha512(password_nou);
  const password_hash = Base64.stringify(pass_512);
  const verificar_enc = rabbit.encrypt(password_hash, process.env.REACT_APP_SECRET_KEY).toString();
  return verificar_enc;
}

export {
  compararContrasenya,
  perGuardarDesHash,
  perGuardar,
}
