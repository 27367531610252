import React, { useContext, useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Row, Col, Space, Typography } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import VisorImatges from "../../../visorImatges";
import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();
const { Text, Title, Link } = Typography;

function AutoritzatsFitxa(props){


const [dades, setDades] = useState(props.dades);
const [dni_image_1, setDni_image_1] = useState();
const [dni_image_2, setDni_image_2] = useState();
const [passaport_image_1, setPassaport_image_1] = useState();
const [passaport_image_2, setPassaport_image_2] = useState();

const [visor, setVisor] = useState({visible: false});

useEffect(() => {
  buscarDades();
},[dades]);


const buscarDades = async()=>{
  const dni_pare = await Api.get('/pares/dni/'+dades.id);
  console.log(dni_pare);
  if(dni_pare.result.success===1){
    setDni_image_1(dni_pare.data.dni_image_1);
    setDni_image_2(dni_pare.data.dni_image_2);
    setPassaport_image_1(dni_pare.data.passaport_image_1);
    setPassaport_image_2(dni_pare.data.passaport_image_2);
  }

}


return(
  <>
  <Space direction="vertical">
    <Space align="baseline"><Text style={{fontSize: '20px'}} strong={true}>{props.t('pares.nom')}:&nbsp;</Text><Text style={{fontSize: '20px'}}>{entities.decode(dades.nom)+' '+entities.decode(dades.cognom1)+' '+entities.decode(dades.cognom2)}</Text></Space>
    <Space align="baseline"><Text style={{fontSize: '20px'}} strong={true}>{props.t('pares.tel1')}:&nbsp;</Text><Link style={{fontSize: '20px'}} href={`tel:${dades.tel1}`} target="_blank">{dades.tel1}</Link></Space>
    <Space align="baseline"><Text style={{fontSize: '20px'}} strong={true}>{props.t('pares.tel2')}:&nbsp;</Text><Link style={{fontSize: '20px'}} href={`tel:${dades.tel2}`} target="_blank">{dades.tel2}</Link></Space>
    <Space align="baseline"><Text style={{fontSize: '20px'}} strong={true}>{props.t('pares.email')}:&nbsp;</Text><Link style={{fontSize: '20px'}} href={`mailto:${dades.email}`} target="_blank">{dades.email}</Link></Space>
    <Space align="baseline"><Text style={{fontSize: '20px'}} strong={true}>{props.t('pares.dni')}:&nbsp;</Text><Text style={{fontSize: '20px'}}>{dades.dni}</Text></Space>
    <Row  gutter={16}>
      {!dni_image_1 ? null :
      <Col span={12}>
        <a onClick={() => setVisor({visible:true, image:  dni_image_1, title: props.t('pares.dni_image_1')})}>
          <img
            src={dni_image_1}
            onError={() => setDni_image_1(`https://via.placeholder.com/443x249?text=+++${props.t('pares.dni_image_1')}+NOT+FOUND!!`)}
            style={{maxWidth:'100%', height: 'auto'}}
            alt={props.t('pares.dni_image_1')}
            title={props.t('pares.dni_image_1')}/>
        </a>
      </Col>
      }
      {!dni_image_2 ? null :
      <Col span={12}>
        <a onClick={() => setVisor({visible:true, image:  dni_image_2, title: props.t('pares.dni_image_2')})}>
          <img
            src={dni_image_2}
            onError={() => setDni_image_2(`https://via.placeholder.com/443x249?text=+++${props.t('pares.dni_image_2')}+NOT+FOUND!!`)}
            style={{maxWidth:'100%', height: 'auto'}}
            alt={props.t('pares.dni_image_2')}
            title={props.t('pares.dni_image_2')}/>
        </a>
      </Col>
      }
    </Row>
    <Space align="baseline"><Text style={{fontSize: '20px'}} strong={true}>{props.t('pares.passaport')}:&nbsp;</Text><Text style={{fontSize: '20px'}}>{dades.passaport}</Text></Space>
    <Row  gutter={16}>
      {!passaport_image_1 ? null :
      <Col span={12}>
        <a onClick={() => setVisor({visible:true, image:  passaport_image_1, title: props.t('pares.passaport_image_1')})}>
          <img
            src={passaport_image_1}
            onError={() => setPassaport_image_1(`https://via.placeholder.com/443x249?text=+++${props.t('pares.passaport_image_1')}+NOT+FOUND!!`)}
            style={{maxWidth:'100%', height: 'auto'}}
            alt={props.t('pares.passaport_image_1')}
            title={props.t('pares.passaport_image_1')}/>
        </a>
      </Col>
      }
      {!passaport_image_2 ? null :
      <Col span={12}>
        <a onClick={() => setVisor({visible:true, image:  passaport_image_2, title: props.t('pares.passaport_image_2')})}>
          <img
            src={passaport_image_2}
            onError={() => setPassaport_image_2(`https://via.placeholder.com/443x249?text=+++${props.t('pares.passaport_image_2')}+NOT+FOUND!!`)}
            style={{maxWidth:'100%', height: 'auto'}}
            alt={props.t('pares.passaport_image_2')}
            title={props.t('pares.passaport_image_2')}/>
        </a>
      </Col>
      }
    </Row>
  </Space>
  { !visor.visible
    ? null
    : <VisorImatges image={visor.image} title={visor.title} onClose={() => setVisor({visible:false})} clickOutsideToExit={true}/>
  }
  </>
)

}

export default translate(AutoritzatsFitxa);
