import React, { useState } from 'react';

const PerfilUsuariContext = React.createContext([{}, () => {}]);

const PerfilUsuariProvider = (props) => {


  /*const [state, setState] = useState({
    account: {
      id : 0,
      username :  null,
      name: null,
      perfil: null,
      language: "ca",
      id_familia: 0,
      pantalles_inici: null,
    }
  });*/
  let inici = {
    account: {
      id : 0,
      username :  null,
      name: null,
      perfil: null,
      language: "ca",
      id_familia: 0,
      pantalles_inici: null,
      id_profe: 0,
      obligar_modificar_password: 0,
    },
    verificar: null,
    dades_profe: null,
    nomes_bus: true,
    nomes_acollida: false,
    curs_escolar_actual: {
      id: 2,
      nom: "2021-22",
      mesos: 10
    }
  };
/*if (process.env.NODE_ENV !== "production"){
  inici = {
    account: {
      id : 7, //0
      username :  'test', //null
      name: 'Hector', //null,
      perfil: 'Administrador', //'Pare'
      language: "ca",
      id_familia: 1, //0,
      pantalles_inici: null,
    }
  };
}*/
const [state, setState] = useState(inici);



  return (
    <PerfilUsuariContext.Provider value={[state, setState]}>
      {props.children}
    </PerfilUsuariContext.Provider>
  );
}

export { PerfilUsuariContext, PerfilUsuariProvider };
