import rabbit from 'crypto-js/rabbit';
import encUtf8 from 'crypto-js/enc-utf8';
import jwt from 'jsonwebtoken';
import axios from 'axios';

/* MY FETCH */
async function myFetch(url, method, data){
  const url_completa = global.api_url+url;
  const token = rabbit.decrypt(sessionStorage.getItem('jwtToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
  let response;
  let resposta_txt;
  try{

//'Content-Type': 'application/json; charset=ISO-8859-1',
    response = await fetch(url_completa, {
      method,
      headers:{
        'Authorization': token
      },
      body: data!==undefined ? data : '',
    });
    //console.log("myFetch: ", response);
    //res = await response.json();
    resposta_txt = await response.text();
    let resposta = JSON.parse(resposta_txt);

    //console.log("OK myFetch: ", resposta);
    if(resposta.result.success===0){
      console.error("KO myFetch: ", resposta);
    }
    return resposta;
  }catch(error){
    //console.error('Api.myFetch: '+error);
    console.error('Api.myFetch',{data:{message: error},result: {success: 0}, response: resposta_txt});
    const e = {data:{message: 'Error en el envio de datos'}, result: {success: 0}, response: resposta_txt}
    return e;
  }
};

/* POST  */
async function enviarFormulariFitxer(url, formData){
  const url_completa = global.api_url+url;
  const token = rabbit.decrypt(sessionStorage.getItem('jwtToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);

  var config = {
    method: 'post',
    url: url_completa,
    headers: {
      'Authorization': token,
      'Content-Type': 'multipart/form-data'
    },
    data : formData
  };

  try{

    let response = await axios(config);
    let resposta = response.data;

    if(resposta.result.success===0){
      console.error("KO enviarFormulariFitxer: ", resposta);
    }
    return resposta;
  }catch(error){
    const e = {data:{message: error},result: {success: 0}}
    console.error(e);
    return e;
  }
};





/* GET */

async function get(url){
  const url_completa = global.api_url+url;
  let token = rabbit.decrypt(sessionStorage.getItem('jwtToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
  if(tokenCaducat(token)){
    token = await renovaToken();
    if(token===false){
      //CRIDAR FUNCIO LOGOUT!!!!
      const e = {data:{message: 'Expired token', code: '401'},result: {code: 401, success: 0}};
      console.error(e);
      return e;
    }
  }
  try{

    let response = await fetch(url_completa, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json; charset=ISO-8859-1',
        'Authorization': token
      },
    });

    //console.log(response);

    if(response.status===401){
       //Si el token ha caducat anem a buscar-ne un de nou usant el RefreshToken
      const refresh_token = rabbit.decrypt(sessionStorage.getItem('jwtRefreshToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
      let res = await fetch(global.api_url+"/admin/refresh", {
        method: 'GET',
        headers:{
          'Content-Type': 'application/json; charset=ISO-8859-1',
          'Authorization': refresh_token
        },
      });
      if(res.status===200){
        //Tenim token nou l'usem per intentar de nou el get (despres de guardar els nous tokens)
        let responseJson = await res.json();
        let token_enc = rabbit.encrypt(responseJson.data.token,process.env.REACT_APP_SECRET_KEY).toString();
        sessionStorage.setItem('jwtToken', token_enc);
        let refresh_token_enc = rabbit.encrypt(responseJson.data.refresh_token,process.env.REACT_APP_SECRET_KEY).toString();
        sessionStorage.setItem('jwtRefreshToken', refresh_token_enc);
        console.log('refrescat');
        let resp = await fetch(url_completa, {
          method: 'GET',
          headers:{
            'Content-Type': 'application/json; charset=ISO-8859-1',
            'Authorization': responseJson.data.token
          },
        });
        if(resp.status===200){
          let resposta = await resp.json();
          return resposta;
        }else{
          //Algo a anat malament al repetir el segon get tornem un success 0 per a que el programa no peti, però no hi ha dades.
          const e = {data:{message: resp},result: {success: 0}};
          console.error('Api get KO new Token',e);
          return e;
        }
      }else{
        //Ha fallat el anar a buscar el Token nou amb el RefreshToken, per tant retornem success 0 i hauriem de fer un LogOut
        const e = {data:{message: res},result: {success: 0}};
        console.error('Api get TokenRefresh KO: ',e);
        /// FER UN LOGOUT
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtRefreshToken');
        //
        return e;
      }

    }else{
      let resposta = await response.json();
      return resposta;
    }

    //console.log("OK get: ", resposta);

  }catch(error){
    //console.error('Api.get: '+error);
    const e = {data:{message: error},result: {success: 0}}
    console.error('Api get Exception: ',e);
    if (process.env.NODE_ENV !== "production"){
      console.error('Api get Exception url: ', url)
    }
    return e;
  }
};

/*
async function get_OLD(url){
  const url_completa = global.api_url+url;
  const token = rabbit.decrypt(sessionStorage.getItem('jwtToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
  try{

    let response = await fetch(url_completa, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json; charset=ISO-8859-1',
        'Authorization': token
      },
    });

    //console.log(response);

    if(response.status===401){
       //Si el token ha caducat anem a buscar-ne un de nou usant el RefreshToken
      const refresh_token = rabbit.decrypt(sessionStorage.getItem('jwtRefreshToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
      let res = await fetch(global.api_url+"/admin/refresh", {
        method: 'GET',
        headers:{
          'Content-Type': 'application/json; charset=ISO-8859-1',
          'Authorization': refresh_token
        },
      });
      if(res.status===200){
        //Tenim token nou l'usem per intentar de nou el get (despres de guardar els nous tokens)
        let responseJson = await res.json();
        let token_enc = rabbit.encrypt(responseJson.data.token,process.env.REACT_APP_SECRET_KEY).toString();
        sessionStorage.setItem('jwtToken', token_enc);
        let refresh_token_enc = rabbit.encrypt(responseJson.data.refresh_token,process.env.REACT_APP_SECRET_KEY).toString();
        sessionStorage.setItem('jwtRefreshToken', refresh_token_enc);
        console.log('refrescat');
        let resp = await fetch(url_completa, {
          method: 'GET',
          headers:{
            'Content-Type': 'application/json; charset=ISO-8859-1',
            'Authorization': responseJson.data.token
          },
        });
        if(resp.status===200){
          let resposta = await resp.json();
          return resposta;
        }else{
          //Algo a anat malament al repetir el segon get tornem un success 0 per a que el programa no peti, però no hi ha dades.
          const e = {data:{message: resp},result: {success: 0}};
          console.error('Api get KO new Token',e);
          return e;
        }
      }else{
        //Ha fallat el anar a buscar el Token nou amb el RefreshToken, per tant retornem success 0 i hauriem de fer un LogOut
        const e = {data:{message: res},result: {success: 0}};
        console.error('Api get TokenRefresh KO: ',e);
        /// FER UN LOGOUT
        sessionStorage.removeItem('jwtToken');
        sessionStorage.removeItem('jwtRefreshToken');
        //
        return e;
      }

    }else{
      let resposta = await response.json();
      return resposta;
    }

    //console.log("OK get: ", resposta);

  }catch(error){
    //console.error('Api.get: '+error);
    const e = {data:{message: error},result: {success: 0}}
    console.error('Api get Exception: ',e);
    return e;
  }
}
*/

function tokenCorrecte(){
  let token = rabbit.decrypt(sessionStorage.getItem('jwtToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
  return !tokenCaducat(token);
}

function refreshTokenCorrecte(){
  const refresh_token = rabbit.decrypt(sessionStorage.getItem('jwtRefreshToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);
  return !tokenCaducat(refresh_token);
}


function tokenCaducat(token){
  var decoded = jwt.decode(token);
  /*
  const exp = new Date(decoded.exp * 1000);
  //console.log('Token expires: ', decoded.exp, exp);
  const ara = new Date();
  return ara.getTime() > exp;
*/

  const exp = new Date(decoded.exp);
  const ara = new Date();
  const ara_int = Date.parse(ara)/1000;
  return ara_int>exp;
};

async function renovaToken(){
  const refresh_token = rabbit.decrypt(sessionStorage.getItem('jwtRefreshToken'),process.env.REACT_APP_SECRET_KEY).toString(encUtf8);

  if(tokenCaducat(refresh_token)){
    return false;
  }

  let res = await fetch(global.api_url+"/admin/refresh", {
    method: 'GET',
    headers:{
      'Content-Type': 'application/json; charset=ISO-8859-1',
      'Authorization': refresh_token
    },
  });
  if(res.status===200){
    //Tenim tokens nous els guardem abans de tornar-lo
    let responseJson = await res.json();
    let token_enc = rabbit.encrypt(responseJson.data.token,process.env.REACT_APP_SECRET_KEY).toString();
    sessionStorage.setItem('jwtToken', token_enc);
    let refresh_token_enc = rabbit.encrypt(responseJson.data.refresh_token,process.env.REACT_APP_SECRET_KEY).toString();
    sessionStorage.setItem('jwtRefreshToken', refresh_token_enc);
    console.log('Token Renovat');
    return responseJson.data.token;
  }else{
    console.log('Token NO Renovat');
    return false;
  }
};

function recoverUserIdFromToken(token){
  var decoded = jwt.decode(token);
  return decoded.sub;
};

export {
  myFetch,
  enviarFormulariFitxer,
  get,
  tokenCaducat,
  tokenCorrecte,
  refreshTokenCorrecte,
  recoverUserIdFromToken,
}
