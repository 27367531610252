import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
//import { Form } from '@ant-design/compatible';
//import '@ant-design/compatible/assets/index.css';
import { LeftOutlined } from '@ant-design/icons';
import { Form, Row, Col, Select, Button, Input, InputNumber, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import ParadesLlista from '../../../../pages/perfils/admin/TransportParades';

const entities = new AllHtmlEntities();


function Edit(props){

  const [id_curs_escolar, setId_curs_escolar] = useState(props.id_curs_escolar);
  const [id, setId] = useState(props.item.id);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [sentit, setSentit] = useState(props.item.sentit);
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [preu_mensual, setPreu_mensual] = useState(props.item.preu_mensual);
  const [preu_puntual, setPreu_puntual] = useState(props.item.preu_puntual);
  const [descompte_x_anada_i_tornada, setDescompte_x_anada_i_tornada] = useState(props.item.descompte_x_anada_i_tornada);

  const [guardant, setGuardant] = useState(false);

  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');

  const [errorSelect, setErrorSelect] = useState('');
  const [errorSelectText, setErrorSelectText] = useState('');

  const nou = (id===0);

  useEffect(() => {
    setId_curs_escolar(props.id_curs_escolar);
  },[props.id_curs_escolar]);

  const nom_caChange = (e) =>{
    const n = e.target.value.trim();
    setNom_ca(n);
    if(n.length>0){
      setErrorNom_ca('');
    }
  }
  const nom_enChange = (e) =>{
    const n = e.target.value.trim();
    setNom_en(n);
    if(n.length>0){
      setErrorNom_en('');
    }
  }
  const nom_esChange = (e) =>{
    const n = e.target.value.trim();
    setNom_es(n);
    if(n.length>0){
      setErrorNom_es('');
    }
  }

  const selectChange = (value) =>{
    console.log(value);
    setSentit(value);
    if(value!==null){
      setErrorSelect('');
      setErrorSelectText('');
    }
  }

  const submitForm = async (values) => {

      let correcte = true;

      if(nom_ca.length === 0){
        setErrorNom_ca('error');
        correcte = false;
      }
      if(nom_en.length === 0){
        setErrorNom_en('error');
        correcte = false;
      }
      if(nom_es.length === 0){
        setErrorNom_es('error');
        correcte = false;
      }

      if(sentit === null){
        setErrorSelect('error');
        setErrorSelectText(props.t('generic.tria'));
        correcte = false;
      }

      if(!correcte){return false}

      setGuardant(true);

      let dades = {
        id,
        id_curs_escolar,
        nom_ca: entities.encode(nom_ca),
        nom_en: entities.encode(nom_en),
        nom_es: entities.encode(nom_es),
        sentit,
        ordre,
        preu_mensual,
        preu_puntual,
        descompte_x_anada_i_tornada
      }
      console.log(JSON.stringify(dades));

      let method = nou?'POST':'PUT';
      let url_api = nou? '/transport' : '/transport/'+id;

      try{
        const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

        if(resposta.result.success === 1){
          setGuardant(false);
          console.log('resposta', resposta);
          message.success(props.t('generic.OK_saved'), 5);
          if(nou){
            setId(resposta.data.id);
            //nou = false;
          }else{
            props.callback();
          }

        }else{
          setGuardant(false);
          console.error('Error: submit FormTransport: ',resposta);
          message.config({top: 50});
          message.open({content: resposta.data.message, duration:10});
        }
      }catch(error){
        setGuardant(false);
        message.config({top: 50});
        message.open({content: "Error saving", duration:10});
        console.error('Error: Transport guardar: ', error);
      }

  }

  return (
  <>
    <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
      <LeftOutlined className="BotoBack" onClick={props.callback}/>
    </div>
    <div style={{padding:'10px 24px 35px 0px'}}>
    <Form onFinish={submitForm}
      layout="horizontal"
      labelCol={{span:3}}
      wrapperCol={{span:21}}
      colon={true}
      autoComplete="off"
    >
      <Form.Item
        label={props.t('transport.ordre')}
        required={true}
      >
          <InputNumber name="ordre" onChange={setOrdre} defaultValue={ordre}/>
      </Form.Item>
        <Form.Item label={props.t('transport.nom')} style={{marginBottom: 0}} layout="horizontal">

            <Form.Item
              style={{ display: 'inline-block', width: 'calc(33% - 8px)' }}
              validateStatus={errorNom_ca}
              label={'CA'}
              required={true}
            >
                <Input name="nom_ca" placeholder={props.t('transport.nom')} onChange={nom_caChange} defaultValue={nom_ca}/>
            </Form.Item>

            <Form.Item
              style={{ display: 'inline-block', width: 'calc(33% - 8px)', margin: '0 8px' }}
              validateStatus={errorNom_en}
              label={'EN'}
              required={true}
            >
                <Input name="nom_en" placeholder={props.t('transport.nom')} onChange={nom_enChange} defaultValue={nom_en}/>
            </Form.Item>

            <Form.Item
              style={{ display: 'inline-block', width: 'calc(33% - 8px)' }}
              validateStatus={errorNom_es}
              label={'ES'}
              required={true}
            >
                <Input name="nom_es" placeholder={props.t('transport.nom')} onChange={nom_esChange} defaultValue={nom_es}/>
            </Form.Item>

        </Form.Item>


        <Form.Item style={{marginBottom: 0}}
          validateStatus={errorSelect}
          help={errorSelectText}
          label={props.t('transport.sentit')}
          labelCol={{span:3}}
          wrapperCol={{span:3}}
          required={true}
        >
          <Select defaultValue={sentit == null ? "" : sentit} defaultActiveFirstOption={true} onChange={selectChange}>
            <Select.Option key='anada' value='anada'>
              {props.t('transport.anada')}
            </Select.Option>
            <Select.Option key='tornada' value='tornada'>
              {props.t('transport.tornada')}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          colon={true}
          label={props.t('transport.preu_puntual')}
          labelCol={{span:3}}
          wrapperCol={{span:5}}
        >
            <InputNumber name="preu_puntual" onChange={setPreu_puntual} defaultValue={preu_puntual}
            min={0.0} step={1.0}
            formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/€\s?|(,*)/g, '')}
            />
        </Form.Item>



        <Form style={{marginLeft:'1.8rem', marginBottom:'2rem'}}
          layout="inline"
          colon={true}
          autoComplete="off"
        >

            <Form.Item
              colon={true}
              label={props.t('transport.preu_mensual')}
            >
                <InputNumber name="preu_mensual" onChange={setPreu_mensual} defaultValue={preu_mensual}
                min={0.0} step={1.0}
                formatter={value => `${value}€`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/€\s?|(,*)/g, '')}
                />
          </Form.Item>

            <Form.Item
              colon={true}
              label={props.t('transport.descompte')}
            >
                <InputNumber name="descompte_x_anada_i_tornada" onChange={setDescompte_x_anada_i_tornada} defaultValue={descompte_x_anada_i_tornada}
                min={-100} max={100} step={0.1}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                />
            </Form.Item>
        </Form>


          <Row type="flex" justify="start">
            <Col>
              <Button onClick={props.callback}>{props.t('generic.back')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>

      </Form>

      { nou ? null :
        <ParadesLlista id_transport={id}/>
      }


    </div>
  </>
  );
}
export default translate(Edit);
