import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Button, Modal, Popover } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';
import FormFamilia from '../../../Formularis/perfils/admin/UnitatFamiliar';

const entities = new AllHtmlEntities();

function BotoUnitatFamiliar(props){

  const id_familia = props.id_familia ? props.id_familia : 0;
  const label = props.label ? props.label : props.t('menu.UnitatFamiliar');

  const [familia, setFamilia] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    buscarDades();
  },[]);


  const buscarDades = async () => {
    if(id_familia > 0){
      const fam = await Api.get('/families/'+id_familia);
      if(fam.result.success===1){
        setFamilia(fam.data);
        //console.log(fam.data)
      }
    }
  }


  const obrirModal = item =>{
    setModalVisible(true);
  }
  const tancarModal = e =>{
    setModalVisible(false);
  }

  return(
    <>
    {!familia ? null :

      <span style={{margin:'0.5rem'}}>
        <span style={{fontSize:'0.9rem', fontWeight:'bold'}}>{label} : </span><Button key={familia.id} type="dashed" onClick={(e) => obrirModal()} style={{marginLeft: 10}}>{entities.decode(familia.cognom1)+' '+entities.decode(familia.cognom2)}</Button>
      </span>

    }
    <Modal
        key="unitatFamiliar"
        title={props.t('menu.UnitatFamiliar')}
        visible={modalVisible}
        footer={null}
        onCancel={tancarModal}
        width={1350}
        style={{top:20}}
        bodyStyle={{ height: "90vh", overflow: "auto" }}
        destroyOnClose={true}
        forceRender={true}
      >
        <FormFamilia item={familia} callback={tancarModal} popup={true}/>
      </Modal>
    </>
  );
}
export default translate(BotoUnitatFamiliar);
