import React, { useState, useEffect } from 'react';
import { translate } from 'react-multi-lang';
import { Tooltip, Button, Form, Row, Col, Select, Input, InputNumber, Switch, Checkbox, Radio, Space, message } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import TriaTipusIngres from '../../../TriaTipusIngres';
import { AllHtmlEntities } from 'html-entities';

import { Api } from '../../../../helpers';

const entities = new AllHtmlEntities();

function TipusQuotesPreus(props) {

  const [id] = useState(props.item.id);
  const [id_curs_escolar, setId_curs_escolar] = useState(props.item.id_curs_escolar === 0 ? props.id_curs_escolar : props.item.id_curs_escolar);
  const [ordre, setOrdre] = useState(props.item.ordre);
  const [nom_ca, setNom_ca] = useState(entities.decode(props.item.nom['ca']));
  const [nom_en, setNom_en] = useState(entities.decode(props.item.nom['en']));
  const [nom_es, setNom_es] = useState(entities.decode(props.item.nom['es']));
  const [tipusIngres, setTipusIngres] = useState(props.item.tipus_ingres || '');
  //const [pagament, setPagament] = useState(props.item.pagament);
  const [comportament, setComportament] = useState(props.item.comportament);
  const [proporcional, setProporcional] = useState(props.item.proporcional == 1 ? true : false);

  const [mesos_facturacio, setMesos_facturacio] = useState(props.item.mesos_facturacio); //Només per a triarMesos antic trimestral


  const [guardant, setGuardant] = useState(false);

  const [errorNom_ca, setErrorNom_ca] = useState('');
  const [errorNom_en, setErrorNom_en] = useState('');
  const [errorNom_es, setErrorNom_es] = useState('');
  const [errorTipusIngres, setErrorTipusIngres] = useState('');
  const [errorTipusIngresText, setErrorTipusIngresText] = useState('');
/*
  const [errorSelectPagament, setErrorSelectPagament] = useState('');
  const [errorSelectPagamentText, setErrorSelectPagamentText] = useState('');
*/
  const [errorMesos_facturacio, setErrorMesos_facturacio] = useState('');
  const [errorMesos_facturacioText, setErrorMesos_facturacioText] = useState('');

  const nou = (id===0);


  useEffect(() => {
    if(id===0){
      setId_curs_escolar(props.id_curs_escolar);
    }
  },[props.id_curs_escolar, id]);




  const nom_caChange = (e) =>{
    const n = e.target.value.trim();
    setNom_ca(n);
    if(n.length>0){
      setErrorNom_ca('');
    }
  }
  const nom_enChange = (e) =>{
    const n = e.target.value.trim();
    setNom_en(n);
    if(n.length>0){
      setErrorNom_en('');
    }
  }
  const nom_esChange = (e) =>{
    const n = e.target.value.trim();
    setNom_es(n);
    if(n.length>0){
      setErrorNom_es('');
    }
  }

/*
  const selectPagamentChange = (value) =>{
    setPagament(value);
    if(value!==null){
      setErrorSelectPagament('');
      setErrorSelectPagamentText('');
    }
  }
*/

  const submitForm = async (values) => {

    let correcte = true;

    if(nom_ca.length === 0){
      setErrorNom_ca('error');
      correcte = false;
    }
    if(nom_en.length === 0){
      setErrorNom_en('error');
      correcte = false;
    }
    if(nom_es.length === 0){
      setErrorNom_es('error');
      correcte = false;
    }
    if(tipusIngres === ''){
      setErrorTipusIngres('error');
      setErrorTipusIngresText(props.t('generic.tria'));
      correcte = false;
    }

    if(!mesos_facturacio){
      setErrorMesos_facturacio('error');
      setErrorMesos_facturacioText(props.t('generic.tria_varis'));
      correcte = false;
    }

/*
    if(pagament === null){
      setErrorSelectPagament('error');
      setErrorSelectPagamentText(props.t('generic.tria'));
      correcte = false;
    }

    if(pagament === 'triarMesos'){
      if(!mesos_facturacio){
        setErrorMesos_facturacio('error');
        setErrorMesos_facturacioText(props.t('generic.tria_varis'));
        correcte = false;
      }
    }else{
      switch (pagament) {
        case 'unic':
          setMesos_facturacio([9]);
          break;
        case 'anual':
          setMesos_facturacio([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
          break;
        case 'mensual':
          setMesos_facturacio([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
          break;
        default:

      }
    }*/

    if(!correcte){return false}

    setGuardant(true);

    let dades = {
      id,
      id_curs_escolar,
      ordre: Number.parseInt(ordre) ?  Number.parseInt(ordre) : 0,
      nom_ca: entities.encode(nom_ca),
      nom_en: entities.encode(nom_en),
      nom_es: entities.encode(nom_es),
      pagament: 'triarMesos',
      tipus_ingres: tipusIngres,
      comportament,
      proporcional: proporcional === true ? 1 : 0,
      mesos_facturacio
    }
    console.log(JSON.stringify(dades));

    let method = nou?'POST':'PUT';
    let url_api = nou? '/quotes/tipus' : '/quotes/tipus/'+id;

    try{
      const resposta = await Api.myFetch(url_api, method, JSON.stringify(dades));

      if(resposta.result.success === 1){
        setGuardant(false);
        //console.log('resposta', resposta);
        message.success(props.t('generic.OK_saved'), 5);
        props.callback();

      }else{
        setGuardant(false);
        console.error('Error: submit Form TipusQuotesPreus: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: TipusQuotesPreus guardar: ', error);
    }

  }

  const optionsComportament = [
  { label: 'Normal', value: 'normal' },
  { label: 'Quota Educativa', value: 'educativa' },
  { label: 'Transport', value: 'transport' },
  { label: 'Menjador', value: 'menjador' },
  { label: 'Acollida', value: 'acollida' },
  { label: 'Reserva', value: 'reserva' },
];

  const optionsMesos = [
    { label: props.t('mesos.1'), value: 1 },
    { label: props.t('mesos.2'), value: 2 },
    { label: props.t('mesos.3'), value: 3 },
    { label: props.t('mesos.4'), value: 4 },
    { label: props.t('mesos.5'), value: 5 },
    { label: props.t('mesos.6'), value: 6 },
    { label: props.t('mesos.7'), value: 7 },
    { label: props.t('mesos.8'), value: 8 },
    { label: props.t('mesos.9'), value: 9 },
    { label: props.t('mesos.10'), value: 10 },
    { label: props.t('mesos.11'), value: 11 },
    { label: props.t('mesos.12'), value: 12 },
  ];

  const onChangeMesos = (checkedValues) => {
    console.log('mesos checkedValues: ', checkedValues);
    setMesos_facturacio(checkedValues);
    if(checkedValues){
      setErrorMesos_facturacio('');
      setErrorMesos_facturacioText('');
    }
  }

  return(
      <>
      <div style={{marginTop:'-2.8rem', marginLeft:'-2rem'}}>
        <Tooltip placement="right" title={props.t('generic.back')}>
            <LeftOutlined className="BotoBack" onClick={props.callback}/>
        </Tooltip>
      </div>
      <div style={{padding:'10px 24px 35px 0px'}}>
        <Form onFinish={submitForm}
          layout="horizontal"
          labelCol={{span:3}}
          wrapperCol={{span:21}}
          colon={true}
          autoComplete="off"
        >
          <Form.Item
            colon={true}
            label={props.t('acollida_propietats.ordre')}
          >
              <InputNumber name="ordre" onChange={(value)=>setOrdre(value)} value={ordre} min={0} step={1} />
          </Form.Item>

          <Form.Item
            validateStatus={errorNom_ca}
            label={props.t('quotes.nom')+' CA'}
            labelCol={{span:3}}
            wrapperCol={{span:5}}
            required={true}
          >
              <Input name="nom_ca" placeholder={props.t('quotes.nom')} onChange={nom_caChange} defaultValue={nom_ca}/>
          </Form.Item>

          <Form.Item
            validateStatus={errorNom_en}
            label={props.t('quotes.nom')+' EN'}
            labelCol={{span:3}}
            wrapperCol={{span:5}}
            required={true}
          >
              <Input name="nom_en" placeholder={props.t('quotes.nom')} onChange={nom_enChange} defaultValue={nom_en}/>
          </Form.Item>

          <Form.Item
            validateStatus={errorNom_es}
            label={props.t('quotes.nom')+' ES'}
            labelCol={{span:3}}
            wrapperCol={{span:5}}
            required={true}
          >
              <Input name="nom_es" placeholder={props.t('quotes.nom')} onChange={nom_esChange} defaultValue={nom_es}/>
          </Form.Item>

          {/*<Form.Item style={{marginBottom: 0}}
            validateStatus={errorSelectPagament}
            help={errorSelectPagamentText}
            colon={true}
            label={props.t('quotes.pagament_titol')}
            labelCol={{span:3}}
            wrapperCol={{span:5}}
            required={true}
          >
            <Select defaultValue={pagament == null ? "" : pagament} defaultActiveFirstOption={true} onChange={selectPagamentChange}>
              <Select.Option key='unic' value='unic'>
                {props.t('quotes.tipus_pagament.unic')}
              </Select.Option>
              <Select.Option key='anual' value='anual'>
                {props.t('quotes.tipus_pagament.anual')}
              </Select.Option>

              <Select.Option key='triarMesos' value='triarMesos'>
                {props.t('quotes.tipus_pagament.triarMesos')}
              </Select.Option>
              <Select.Option key='mensual' value='mensual'>
                {props.t('quotes.tipus_pagament.mensual')}
              </Select.Option>
            </Select>
          </Form.Item>*/}

          <Form.Item
            label={props.t('generic.tipus_ingres')}
            labelCol={{span:3}}
            wrapperCol={{span:5}}
            required={true}
            validateStatus={errorTipusIngres}
            help={errorTipusIngresText}
            style={{marginBottom: 0}}>
            <TriaTipusIngres defaultValue={tipusIngres} onChange={setTipusIngres} />
          </Form.Item>
          <Form.Item
            colon={true}
            label={props.t('quotes.comportament')}
            labelCol={{span:3}}
            wrapperCol={{span:19}}
          >
          <Space direction="vertical">
            <Radio.Group
              options={optionsComportament}
              onChange={(e) => setComportament(e.target.value)}
              //defaultValue={'normal'}
              value={comportament}
              optionType="button"
              buttonStyle="solid"
            />
            <span>(<b>Quota Educativa:</b> En la unitat familiar es pot marcar si ja ha estat pagada per tot el curs. <b>Transport:</b> lligat a que existeixi una comanda mensual. <b>Menjador:</b> lligat a que existeixi una comanda mensual. <b>Acollida:</b> lligat a que existeixi una comanda amb els minuts realitzats. <b>Reserva:</b> Única quota que s'aplicarà a l'alumne si está en estat de reserva.)</span>
            </Space>
          </Form.Item>

          <Form.Item label={props.t('generic.mesos_facturacio')+':'} required={true} validateStatus={errorMesos_facturacio} help={errorMesos_facturacioText}>
            <Checkbox.Group options={optionsMesos} defaultValue={mesos_facturacio} onChange={onChangeMesos} />
          </Form.Item>

          {/*['unic'].indexOf(pagament) === -1 ? null :
          <>
              <Form.Item label={props.t('generic.mesos_facturacio')+':'} required={true} validateStatus={errorMesos_facturacio} help={errorMesos_facturacioText}>
                <Radio.Group options={optionsMesos} defaultValue={mesos_facturacio[0]} onChange={(e)=>onChangeMesos([e.target.value])} />
              </Form.Item>
          </>
          */}


            <Form.Item
              help={props.t('quotes.proporcional_desc')}
              label={props.t('quotes.proporcional')}
              labelCol={{span:3}}
              wrapperCol={{span:21}}
            >
                  <Switch
                  checkedChildren={props.t('generic.si')}
                  unCheckedChildren={props.t('generic.no')}
                  onChange={setProporcional} defaultChecked={proporcional}
                  />
            </Form.Item>



          <Row type="flex" justify="start" style={{marginTop: '2rem'}}>
            <Col>
              <Button onClick={props.callback}>{props.t('generic.back')}</Button>
            </Col>
            <Col style={{marginLeft:'10px'}}>
              <Button type="primary" htmlType="submit" loading={guardant}>{props.t('generic.save')}</Button>
            </Col>
          </Row>

        </Form>
      </div>
      </>
  );
}
export default translate(TipusQuotesPreus);
