import React, { useState, useContext } from 'react';
import moment from 'moment-timezone';

import { translate } from 'react-multi-lang';
import { Row, Button, Card, message } from 'antd';
import { AllHtmlEntities } from 'html-entities';

import { PerfilUsuariContext } from "../../../../context/perfil-usuari";

import { Api } from '../../../../helpers';
import AutoritzarContrasenya from '../../../AutoritzarContrasenya';

const entities = new AllHtmlEntities();

function Excursio(props){
  //console.log(props)
  const [perfilUsuari, setPerfilUsuari] = useContext(PerfilUsuariContext);

  const [producte, setProducte] = useState(props.item);
  const [imatge, setImatge] = useState(props.item.url_image || "https://via.placeholder.com/443x249?text=NO+IMAGE+ASSIGNED!!");
  const [validat, setValidat] = useState(false);
  const [guardant, setGuardant] = useState(false);

  const submitForm = async (valors) => {


    if(!validat){
      return false;
    }

    setGuardant(true);

    let dades = {
      id_producte: producte.id_producte,
      id_pare: perfilUsuari.account.id,
      id_alumne: props.id_alumne,
    }

    //console.log(dades);

    try{
      const resposta = await Api.myFetch('/botiga', 'POST', JSON.stringify(dades));
      if(resposta.result.success === 1){
        setGuardant(false);
        message.success(props.t('generic.OK_saved'), 5);
        props.tancarModal();
      }else{
        setGuardant(false);
        console.error('Error: guardant Roba: ',resposta);
        message.config({top: 50});
        message.open({content: resposta.data.message, duration:10});
      }
    }catch(error){
      setGuardant(false);
      message.config({top: 50});
      message.open({content: "Error saving", duration:10});
      console.error('Error: Roba guardar: ', error);
    }

  }

  //var d = new Date(producte.data_hora_esdeveniment);
  const data_hora = moment(producte.data_hora_esdeveniment).tz('Europe/Madrid').format('DD/MM/YYYY HH:mm');

  return(
    <>
    <Row justify="center">
      <Card
        bordered={false}
        style={{ marginTop: '1.5rem', padding: 0}}
        cover={<img alt="Picture" src={imatge} onError={() => setImatge("https://via.placeholder.com/443x249?text=URL+IMAGE+NOT+FOUND!!")}/>}
      >
        <Card.Meta title={<div style={{textAlign:'left'}}>{data_hora}</div>}/>
        <Card.Meta title={entities.decode(producte.nom[props.t('idioma')])} description={entities.decode(producte.descripcio[props.t('idioma')])} />

        <Row justify="center" style={{marginTop: '30px'}}>
          <b>{props.t('botiga.preu')+': '}</b>&nbsp;<span style={{color: 'red'}}>{producte.preu}€</span>
        </Row>
      </Card>
    </Row>
    {!producte.comprat
      ?
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <AutoritzarContrasenya resposta={setValidat}/>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() => submitForm()} loading={guardant} disabled={!validat}>
              {props.t('botiga.pagar_autoritzar')}
            </Button>
          </Row>
        </>
      :
        <>
          <Row justify="center" style={{margin: '20px 0px 10px 0px'}}>
            <span style={{color: 'red'}}>*{props.t('generic.serveiJaContractat')}</span>
          </Row>
          <Row justify="center" style={{marginTop: '10px'}}>
            <Button type="primary" onClick={() =>props.tancarModal()}>
              {props.t('generic.back')}
            </Button>
          </Row>
        </>
      }
    </>
  );
}
export default translate(Excursio);
