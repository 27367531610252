import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { translate } from 'react-multi-lang';
import { SyncOutlined, PercentageOutlined, LeftOutlined, EditOutlined, DeleteOutlined, SearchOutlined, FileExcelOutlined } from '@ant-design/icons';
import {Table, Tooltip, Popconfirm, Modal, Form, Row, Col, Select, Button, Input, InputNumber, Divider, Switch, DatePicker, Space, message } from 'antd';

import { AllHtmlEntities } from 'html-entities';
import moment from 'moment';
import 'moment/locale/ca';

import { Api } from '../../../../helpers';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const entities = new AllHtmlEntities();

function ComandesExtraescolarsExportaExcel(props){

  const [dades, setDades] = useState(props.dades);
  const [dadesEmails, setDadesEmails] = useState([]);
  const nom_fulla = props.nom_fulla ? props.nom_fulla : props.t('menu.Extraescolars');
  const nom_fitxer = props.nom_fitxer ? props.nom_fitxer : props.t('menu.Extraescolars');

  useEffect(() => {
    setDades(props.dades)
  },[props.dades]);

  useEffect(() => {
    buscarEmailsAlumnes();
  },[]);

  const buscarEmailsAlumnes = async () =>{
    const alum = await Api.get('/llistat/emails/alumnes');
    if(alum.result.success===1){
      let alumnesEmails = [];
      alum.data.alumnes.map((item)=>{
        alumnesEmails[item.id_alumne] = {...item};
      });
      console.log('emails alumnes', alumnesEmails);
      setDadesEmails(alumnesEmails);
    }
  }



  console.log("dins exporta: ", props.dades)

  return (
      <ExcelFile
        filename={nom_fitxer}
        element={<Button type="primary" style={{backgroundColor: '#217346', borderColor: '#217346', marginBottom: '0.5rem', marginLeft: '0.5rem', marginRight: '0.5rem'}} shape="round" icon={<FileExcelOutlined />}>{props.btn_txt}</Button>}>
          <ExcelSheet data={dades} name={nom_fulla}>
            <ExcelColumn label="id_comanda" value="id"/>
            <ExcelColumn label={props.t('alumnes.classe')} value={(col) => entities.decode(col.classe)}/>
            <ExcelColumn label={'id_alumne'} value={"id_alumne"}/>
            <ExcelColumn label={props.t('generic.alumne')} value={"alumne_nom_t"}/>
            <ExcelColumn label={'id_extraescolar'} value={"id_extraescolar"}/>
            <ExcelColumn label={props.t('menu.Extraescolars')} value={"nom_t"}/>
            <ExcelColumn label={props.t('extraescolars.horari')} value={"horari_t"}/>
            <ExcelColumn label={'id_familia'} value={"id_familia"}/>
            <ExcelColumn label={props.t('generic.familia')} value={"familia_nom_t"}/>

            <ExcelColumn label={props.t('generic.responsable')+'1'} value={(col) => entities.decode(dadesEmails[col.id_alumne].nom_pare1)}/>
            <ExcelColumn label={props.t('pares.email')+'1'} value={(col) => dadesEmails[col.id_alumne].email_pare1}/>
            <ExcelColumn label={props.t('generic.responsable')+'2'} value={(col) => entities.decode(dadesEmails[col.id_alumne].nom_pare2)}/>
            <ExcelColumn label={props.t('pares.email')+'2'} value={(col) => dadesEmails[col.id_alumne].email_pare2}/>

            <ExcelColumn label={props.t('generic.total')} value={(col) => parseFloat(col.total)}/>
            <ExcelColumn label={props.t('generic.created_at')} value={(col) => moment(col.date).tz('Europe/Madrid').format('DD/MM/YYYY')}/>
          </ExcelSheet>
      </ExcelFile>
  );

}

export default translate(ComandesExtraescolarsExportaExcel);
